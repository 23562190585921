import { util } from "../../util";
import { dialog } from "../../dialog";

const xsolla = {
    /**
     * @function
     * @description Initialization of the entire flow, bind listeners to dispatch actions accordingly
    */
    init: () => {
        const xsollaEmail = document.querySelector(".e-xsolla__input");
        const xsollaCta = document.querySelector("[xsolla-cta='email']");

        if (window.xsollaFlow === "revamp") {
            const editEmailCta = document.querySelector(".e-xsolla__edit");
            const updatePaymentCta = document.querySelector(".e-xsollacard__edit");

            xsollaEmail.addEventListener("keyup", e => xsolla.enableCTAEmail(e));
            xsollaCta.addEventListener("click", e => xsolla.submitEmail(e, xsollaEmail));
            editEmailCta.addEventListener("click", xsolla.editEmail);
            updatePaymentCta.addEventListener("click", xsolla.updatePayment);
            document.addEventListener("xsolla:switchPayment", xsolla.switchPayment(xsollaEmail));
        }

        document.addEventListener("xsolla:postData", xsolla.validateFrame);
        document.addEventListener("xsolla:errorFrame", xsolla.errorFrame);
        document.addEventListener("xsolla:checkoutValidation:revamp", xsolla.revampValidation);
        document.addEventListener("xsolla:checkoutValidation:regular", xsolla.regularValidation);
        document.addEventListener("xsolla:accountValidation", xsolla.accountValidation);
        document.addEventListener("xsolla:submitOrder:revamp", xsolla.submitOrderRevamp);
        document.addEventListener("xsolla:submitOrder:regular", xsolla.submitOrderRegular);
    },

    /**
     * @function
     * @description Revamp flow: Enable or disabled CTA for email field
    */
    enableCTAEmail: () => {
        const xsollaCta = document.querySelector("[xsolla-cta='email']");
        const xsollaEmail = document.querySelector(".e-xsolla__input");

        setTimeout(() => {
            if (xsolla.checkEmail(xsollaEmail)) {
                xsollaCta.removeAttribute("disabled");
            } else {
                xsollaCta.setAttribute("disabled", true);
            }
        }, 500);

        if (window.xsollaFlow === "regular") {
            const loader = document.querySelector(".loading");
            const submitCta = document.querySelector("[xsolla-cta='email']");

            loader.style.display = "none";
            submitCta.classList.remove("checkout-disabled-white");
        }
    },

    /**
     * @function
     * @description Revamp flow: Check the validity of the email field
     * @param {HTMLElement} input - Email input HTML element
     * @returns {Boolean}
    */
    checkEmail: input => {
        const xsollaEmailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]{1,64}@(?=.{1,254}$)([a-zA-Z0-9-.]+\.[a-zA-Z]+)$/;

        return xsollaEmailRegex.test($.trim(input.value));
    },

    /**
     * @function
     * @description Revamp flow: Submit email field if valid, and call xsolla frame
     * @param {xsollaCta#event:click} event - Event passed to prevent
     * @param {HTMLElement} input - Email input HTML element
    */
    submitEmail: (event, input) => {
        event.preventDefault();

        const submitEmailCta = document.querySelector("[xsolla-cta='email']");

        if (!submitEmailCta.classList.contains("xsolla-submit") && xsolla.checkEmail(input)) {
            xsolla.fetchFrame(input.value);

            // Remove field error
            input.classList.remove("error");

            if (input.nextElementSibling !== null) {
                input.nextElementSibling.remove();
            }
        }
    },

    /**
     * @function
     * @description Common flow: fetch xsolla frame, update UI
     * @param {String} email - User email - mandatory
    */
    fetchFrame: async email => {
        const url = util.appendParamsToUrl(Urls.getXsollaIframeUrl, {
            email: email,
            paymentMethod: window.paymentMethod,
            format: "ajax"
        });

        const getResponse = await fetch(url, {
            method: "POST"
        });
        const response = await getResponse.json();

        if (!response.error) {
            $("#xsolla-error-payment").addClass("hide").empty();

            xsolla.lockEmail();
            xsolla.appendFrame(response.iframeURL);

            window.addEventListener("message", xsolla.xsollaFrameHandler);
        } else {
            xsolla.displayErrorModal();
        }
    },

    /**
     * @function
     * @description Common flow: When xsolla frame is display, listen for xsolla events to know if client validate or not their flow
     */
    xsollaFrameHandler: () => {
        let eventData = util.parseJSON(event.data);

        if (event !== null && eventData !== null && event.data !== undefined && (event.origin === "https://secure.xsolla.com" || event.origin === "https://sandbox-secure.xsolla.com")) {
            if (eventData.command && eventData.command !== undefined && eventData.command === "add_saved_account") {
                // Clear frame error
                $(".payment-method-container").removeClass("xsolla--is-error");

                const payload = {
                    ...eventData.data,
                    mail: $(".e-xsolla__input").val()
                };

                const postXsollaEvent = new CustomEvent("xsolla:postData", {
                    detail: payload
                });

                document.dispatchEvent(postXsollaEvent);

                return;
            } else if (eventData.command && eventData.command === "cancel_save_account" || eventData.command === "add_saved_account_error" || eventData.command === "close") {
                const eventFrameError = new CustomEvent("xsolla:errorFrame", {
                    detail: eventData.command
                });

                document.dispatchEvent(eventFrameError);
            }
        }
    },

    /**
     * @function
     * @description Common flow: If xsolla frame response has error, update UI with error
     * @param {Event} event - Event triggered, payload contain eventData from Xsolla
     * @listens xsolla:errorFrame
     */
    errorFrame: event => {
        const url = util.appendParamsToUrl(Urls.getXsollaTokenErrorMsg, {
            xsollaResponseEvent: event.detail
        });

        fetch(url, {
            method: "GET"
        })
            .then(response => response.text())
            .then(response => {
                if (response.length && window.xsollaFlow === "regular") {
                    $("#xsolla-error-payment").html(response).removeClass("hide");
                    xsolla.enableCTAEmail();
                    util.scrollBrowser(0);
                } else {
                    xsolla.unlockEmail();
                }

                xsolla.removeFrame();
            });
    },

    /**
     * @function
     * @description Common flow: Dispatch Event for splitting validation flow
     * @param {Event} event - Event triggered, payload contain payment method and email
     * @listens xsolla:postData
     */
    validateFrame: async event => {
        const xsollaData = await fetch(Urls.setXsollaData, {
            method: "POST",
            body: JSON.stringify(event.detail)
        });
        const response = await xsollaData.json();

        if (!response.error) {
            if (window.xsollaFlow === "revamp" || window.xsollaFlow === "regular") {
                const validationEvent = new CustomEvent("xsolla:checkoutValidation:" + window.xsollaFlow, {
                    detail: response
                });

                document.dispatchEvent(validationEvent, false);
            } else {
                const validationEvent = new CustomEvent("xsolla:accountValidation", {
                    detail: response
                });

                document.dispatchEvent(validationEvent, false);
            }
        } else {
            if (window.xsollaFlow === "revamp" || window.xsollaFlow === "regular") {
                xsolla.displayErrorModal();
            } else {
                document.querySelector(".payment-method-error-message").classList.remove("hide");
            }
        }
    },

    /**
     * @function
     * @description Revamp flow: Set "unlock" state for email field (button is enabled)
     */
    unlockEmail: () => {
        const $inputEmail = $(".e-xsolla__input");

        $(".c-xsolla__field").removeClass("c-xsolla__field--is-locked");
        $inputEmail.attr("disabled", false);
    },

    /**
     * @function
     * @description Revamp flow: Set "lock" state for email field (button is disabled)
     */
    lockEmail: () => {
        const $inputEmail = $(".e-xsolla__input");

        $(".c-xsolla__field").addClass("c-xsolla__field--is-locked");
        $inputEmail.attr("disabled", true);
    },

    /**
     * @function
     * @description Revamp flow: Remove frame and show payment method stored item
     * @param {Event} event - Event to get payment method payload
     * @listens xsolla:revampValidation
     */
    revampValidation: event => {
        xsolla.removeFrame();
        xsolla.fillXsollaCardItem(event.detail);
    },

    /**
     * @function
     * @description Regular flow: Remove frame and flag submit button
     * @listens xsolla:regularValidation

     */
    regularValidation: () => {
        const submitCta = document.querySelector(".checkout-last-step");

        xsolla.removeFrame();
        xsolla.enableCTAEmail();
        submitCta.classList.add("xsolla-submit");
    },

    /**
     * @function
     * @description Account flow: Xsolla Validation on "AddPayment" flow (account)
     * @param {Object} response - response returned by Urls.setXsollaData
     * @listens xsolla:accountValidation
     */
    accountValidation: response => {
        const xsollaAddPaymentError = document.querySelector(".payment-method-error-message");

        if (!response.error) {
            const creditCardFormUrl = document.querySelector("#CreditCardForm").action;
            const selectedPaymentMethodID = $("input[name=selectedPaymentMethodID]:checked").val();

            if (!selectedPaymentMethodID) {
                return;
            }

            // Clear form
            xsolla.removeFrame();
            document.querySelector("[data-component='paymentmethod']").innerHTML = "";

            // Hide Add credit card modal - waiting for response
            const dialogCard = document.querySelector(".dialog-card");
            const dialogOverlay = document.querySelector(".ui-widget-overlay.ui-front");

            dialogCard.classList.add("hide");
            dialogOverlay.classList.add("hide");

            fetch(creditCardFormUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                },
                body: new URLSearchParams({
                    selectedPaymentMethodID: selectedPaymentMethodID
                })
            })
                .then(confirmationResponse => confirmationResponse.json())
                .then(confirmationResponse => {
                    if (confirmationResponse.success) {
                        dialog.open({
                            html: $(".change-payment-confirmation").html(),
                            options: {
                                dialogClass: "change-payment-confirmation",
                                width: 980
                            }
                        });
                    } else {
                        dialogCard.classList.remove("hide");
                        dialogOverlay.classList.remove("hide");
                        xsollaAddPaymentError.classList.remove("hide");
                    }
                });
        } else {
            xsollaAddPaymentError.removeClass("hide");
        }
    },

    /**
     * @function
     * @description Revamp flow: On PaymentMethod selection, if we have a stored card, display it. If not, get back to step one with unlocked email field
     */
    switchPayment: () => {
        const $storeCardItem = $("[data-container='xsolla-stored']");
        const $xsollaPaymentBlock = $(".c-xsolla__payment");
        const storedCardType = $storeCardItem.data("payment-type");
        const xsollaEmail = document.querySelector(".e-xsolla__input");

        if (xsollaEmail.nextElementSibling !== null) {
            xsollaEmail.nextElementSibling.remove();
        }

        if (storedCardType.length && window.paymentMethod !== undefined && window.paymentMethod === storedCardType) {
            $storeCardItem.removeClass("hide");
            $xsollaPaymentBlock.addClass("hide");
        } else {
            $storeCardItem.addClass("hide");
            $xsollaPaymentBlock.removeClass("hide");
            xsolla.unlockEmail();
        }

        xsolla.removeFrame();
        xsolla.enableCTAEmail();
    },

    /**
     * @function
     * @description Common flow: Create xsolla frame and append it
     * @param {String} iframeURL - iframeURL
     */
    appendFrame: iframeURL => {
        let iframe = document.createElement("iframe");
        let offsetFrame = $(".payment-method-expanded").offset().top - $(".checkout-progress-indicator").innerHeight();

        iframe.src = iframeURL;
        iframe.className = ("xsollaFrame");
        $(".xsolla-container").append(iframe);
        $(".payment-method-container").addClass("xsolla--is-active");
        $(".xsolla-container").addClass("xsolla-container--is-active");

        const $subscriptionPaymentForm = $(".subscription-payment-form");

        if ($subscriptionPaymentForm.length > 0) {
            $subscriptionPaymentForm.addClass("xsolla-payment-form");
        }

        util.scrollBrowser(offsetFrame);
    },

    /**
     * @function
     * @description Common flow: Hide and remove xsolla frame
     */
    removeFrame: () => {
        $(".xsolla-container").removeClass("xsolla-container--is-active").empty();
        $(".payment-method-container").removeClass("xsolla--is-active xsolla--is-error");

        const $subscriptionPaymentForm = $(".subscription-payment-form");

        if ($subscriptionPaymentForm.length > 0 && $subscriptionPaymentForm.hasClass("xsolla-payment-form")) {
            $subscriptionPaymentForm.removeClass("xsolla-payment-form");
        }
    },

    /**
     * @function
     * @description When frame is validated on "revamp" flow: Hide payment block, and display credit card stored element
     * @param {Object} data - data Payment method values
     */
    fillXsollaCardItem: data => {
        const $storeCardItem = $("[data-container='xsolla-stored']");
        const $storeCardEmail = $storeCardItem.find("[data-card='xsolla-email']");
        const $storeCardNumber = $storeCardItem.find("[data-card='xsolla-number']");
        const $storeCardLogo = $storeCardItem.find("[data-card='xsolla-logo']");
        const $allLogos = $storeCardLogo.find(".svg-wrapper");
        const $currentLogo = data.xsollaPaymentType ? $(".e-xsollacard__" + data.xsollaPaymentType) : $(".e-xsollacard__generic");
        const paymentType = $(".c-payment__buttons").find("input[type='radio']:checked").val();

        $storeCardItem.data("payment-type", paymentType);
        $storeCardItem.removeClass("hide");
        $storeCardEmail.html(data.xsollaEmail);
        $storeCardNumber.html(data.maskedPaymentData);
        $allLogos.addClass("hide");

        if ($currentLogo) {
            $currentLogo.removeClass("hide");
        }

        document.querySelector(".c-xsolla__payment").classList.add("hide");
    },

    /**
     * @function
     * @description If user want to edit his email on "revamp" flow: Remove iframe, show payment block and unlock email field
     */
    editEmail: () => {
        xsolla.removeFrame();
        xsolla.unlockEmail();
        document.querySelector(".c-xsolla__payment").classList.remove("hide");
    },

    /**
     * @function
     * @description If user want to update his payment on "revamp" flow: Hide stored card item, and get back to step one with unlocked email field
     */
    updatePayment: () => {
        $(".c-xsolla__payment").removeClass("hide");
        $("[data-container='xsolla-stored']").addClass("hide");

        xsolla.unlockEmail();
    },

    /**
     * @function
     * @description Submit order on "revamp" flow: If iframe is not displayed, then we can submit the payment
     * @listens xsolla:submitOrder:revamp
     */
    submitOrderRevamp: () => {
        const frame = document.querySelector(".xsollaFrame");
        const submitCta = document.querySelector(".checkout-last-step");
        const paymentForm = document.querySelector(".checkout-billing");

        if (!frame) {
            submitCta.setAttribute("disabled", true);
            $(document).trigger("placeOrderEvent");
            paymentForm.submit();
        } else {
            $(".payment-method-container").addClass("xsolla--is-error");
            util.scrollBrowser($(".e-xsolla__frame-error").offset().top);
        }
    },

    /**
     * @function
     * @description Submit order on "regular" flow: if button is flagged submit the payment, if not "fetchFrame" is called
     * @listens xsolla:submitOrder:regular
     */
    submitOrderRegular: () => {
        const submitCta = document.querySelector("[xsolla-cta='email']");
        const loader = submitCta.nextElementSibling;
        const isFlagged = submitCta.classList.contains("xsolla-submit");
        const isFrameDisplayed = document.querySelector(".xsolla-container").classList.contains("xsolla-container--is-active");
        const paymentForm = document.querySelector(".checkout-billing");
        const email = document.querySelector(".xsolla-email input").value;

        if (window.xsollaFlow === "regular" && !isFlagged && !isFrameDisplayed) {
            xsolla.fetchFrame(email);
        } else if (window.xsollaFlow === "regular" && isFlagged) {
            paymentForm.submit();
        }

        // Disbale button
        submitCta.classList.add("checkout-disabled-white");
        loader.style.display = "block";
    },

    /**
     * @function
     * @description Commonn flow: Xsolla Validation for "AddPayment" flow (account), displayed as modal
     */
    displayErrorModal: () => {
        let options = {};
        const errorContent = $("#xsolla-error").html();

        if (errorContent.length) {
            if (window.xsollaFlow === "revamp") {
                options = {
                    dialogClass: "c-dialog__error",
                    modal: true
                };
            } else {
                options = {
                    dialogClass: "xsolla-error"
                };
            }

            dialog.open({
                html: $(errorContent),
                options: options,
                callback: () => {
                    $(document).find("body").addClass("xsolla-error-open");
                    util.scrollBrowser(0);

                    // Reinit paymentMethod and close dialog
                    $(document).on("click", ".btn-change-payment-method", e => {
                        e.preventDefault();

                        const closeButton = $(".ui-dialog-titlebar-close");

                        closeButton.trigger("click");
                        $(document).find("body").removeClass("xsolla-error-open");
                    });
                }
            });
        }
    }
};

export { xsolla };
