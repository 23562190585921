import { initProductTooltip } from "./tooltip";
import { util } from "./util";
import { updateCartWithDigitalTax } from "./pages/checkout/billing";

let $cache = {};
let walletTopupValue = null;
let walletTopupLabel = null;
let walletTopupPid = null;
let isThresholdSelected = false;
const moveСurrency = ["ca", "us", "br", "anz", "uk", "nl", "cn"];

/**
 * @function
 * @description initialize cache object
 */
const initCache = () => {
    $cache = {
        document: $(document),
        walletPaymentOption: $(".payment-option.wallet"),
        otherPaymentOption: $(".payment-option.other"),
        walletPayment: $(".wallet-payment-option"),
        otherPayment: $(".other-payment-option"),
        allOrderRecap: $(".order-recap-all"),
        walletOrderRecap: $(".order-recap-wallet"),
        commonOrderRecap: $(".order-recap-common"),
        walletHiddenRadio: $("#is-WALLET_PAYMENT"),
        CCRadio: $("#is-CREDIT_CARD"),
        addressActions: $(".address-actions"),
        triggerCurrencyChoiceModal: $("[data-currency-selected]"),
        upcCheckoutSubmit: $(".checkout-last-step"),
        opcWalletSection: $(".wallet-subscription"),
        walletBalance: $(".js-wallet-balance"),
        orderValue: $("[data-order-value]"),
        rewardsDisclaimer: $(".opc-rewards-disclaimer"),
        walletPaymentContainer: $(".opc-payment"),
        paymentMethodsSections: $(".pay-credit-card, .pay-saved-credit"),
        upcCheckoutLegalTerms: $(".js-checkout-conditions")
    };
};

/**
 * @function
 * @description fill the wallet topup details from order recap section
 * @param {string} value - string with the value to be used for calculations
 * @param {string} label - string with the formatted value to be displayed
 */
const fillWalletOrderRecap = (value, label) => {
    if (value && label) {
        const $walletOrderCredit = $(".wallet-order-credit");
        const $walletOrderCreditValue = $walletOrderCredit.length ? $walletOrderCredit.find(".value") : "";
        const $walletAddedBalance = $(".js-wallet-balance-added");
        const $walletBalanceAfterCheckout = $(".js-wallet-balance-after-checkout");
        const $walletTotalSection = $(".order-recap-wallet-table");
        const rewardsRemainingBalance = $walletTotalSection.length && $walletTotalSection.attr("data-remaining-rewards-balance") ? $walletTotalSection.attr("data-remaining-rewards-balance") : 0;
        const orderTotalValue = $cache.orderValue.length ? $cache.orderValue.attr("data-order-value") : 0;
        const orderTotalValueWithRewards = parseFloat(orderTotalValue) + parseFloat(rewardsRemainingBalance);
        const walletExistingBalance = $walletBalanceAfterCheckout.length ? $walletBalanceAfterCheckout.attr("data-wallet-current-balance") : 0;
        const walletCheckoutTopupWidget = document.querySelector("wallet-checkout-balance");
        const $walletCreditToBeAdded = $('input[name*="_walletCreditToBeAdded"]');
        const $miniSummaryWalletCredit = $(".wallet-credit-price");
        let newAddedBalance = parseFloat(value).toFixed(2);
        let balanceAfterCheckout = (orderTotalValue && walletExistingBalance) ? (parseFloat(walletExistingBalance) + parseFloat(value) - parseFloat(orderTotalValue)).toFixed(2) : 0;

        if (walletCheckoutTopupWidget !== null && orderTotalValueWithRewards) {
            walletCheckoutTopupWidget.setAttribute("totalcheckout", orderTotalValueWithRewards);
        }

        if ($walletCreditToBeAdded.length) {
            $walletCreditToBeAdded.val(value);
        }

        if ($walletOrderCredit.length) {
            $walletOrderCredit.removeClass("hide");
        }

        if ($walletOrderCreditValue.length) {
            $walletOrderCreditValue.html("+ " + label);
        }

        if ($walletAddedBalance.length && newAddedBalance && GeoRestrictedResources.STOREFRONT_CURRENCY_SYMBOL) {
            if (moveСurrency.includes(GeoRestrictedResources.GEORESTRICTED_CURRENTSITE)) {
                $walletAddedBalance.html(GeoRestrictedResources.STOREFRONT_CURRENCY_SYMBOL + " " + newAddedBalance);
            } else {
                $walletAddedBalance.html(newAddedBalance + " " + GeoRestrictedResources.STOREFRONT_CURRENCY_SYMBOL);
            }
        }

        if ($miniSummaryWalletCredit.length) {
            $miniSummaryWalletCredit.html(label);
        }

        if ($walletBalanceAfterCheckout.length && balanceAfterCheckout && GeoRestrictedResources.STOREFRONT_CURRENCY_SYMBOL) {
            if (moveСurrency.includes(GeoRestrictedResources.GEORESTRICTED_CURRENTSITE)) {
                $walletBalanceAfterCheckout.html(GeoRestrictedResources.STOREFRONT_CURRENCY_SYMBOL + " " + balanceAfterCheckout);
            } else {
                $walletBalanceAfterCheckout.html(balanceAfterCheckout + " " + GeoRestrictedResources.STOREFRONT_CURRENCY_SYMBOL);
            }
        }
    }
};

/**
 * @function
 * @description initialize the wallet topup widget from checkout
 */
const initWalletCheckoutTopupWidget = () => {
    const walletCheckoutTopupWidget = document.querySelector("wallet-checkout-balance");
    const orderTotalValue = $cache.orderValue.length ? $cache.orderValue.attr("data-order-value") : 0;
    const $walletTotalSection = $(".order-recap-wallet-table");
    const rewardsRemainingBalance = $walletTotalSection.length && $walletTotalSection.attr("data-remaining-rewards-balance") ? $walletTotalSection.attr("data-remaining-rewards-balance") : 0;
    const orderTotalValueWithRewards = parseFloat(orderTotalValue) + parseFloat(rewardsRemainingBalance);

    fillWalletOrderRecap(walletTopupValue, walletTopupLabel);

    if (walletCheckoutTopupWidget !== null) {
        if (orderTotalValue) {
            walletCheckoutTopupWidget.setAttribute("totalcheckout", orderTotalValueWithRewards);
        }

        walletCheckoutTopupWidget.addEventListener("onPriceSelected", e => {
            if (e.detail) {
                isThresholdSelected = true;

                if ($cache.walletPaymentOption && !$cache.walletPaymentOption.hasClass("disabled")) {
                    $cache.walletPaymentOption.attr("data-isthresholdselected", isThresholdSelected);
                }

                showPaymentMethodsWallet();

                if (e.detail.value && e.detail.label) {
                    walletTopupValue = e.detail.value;
                    walletTopupLabel = e.detail.label;
                    fillWalletOrderRecap(e.detail.value, e.detail.label);
                }

                if (e.detail.product_id) {
                    const $walletProductID = $('input[name*="_walletProductID"]');

                    if ($walletProductID.length) {
                        walletTopupPid = e.detail.product_id;
                        $walletProductID.val(e.detail.product_id);
                    }
                }

                // enable submit button
                $cache.upcCheckoutSubmit
                    .removeClass("disabled")
                    .attr("disabled", false);

                // enable legal terms
                $cache.upcCheckoutLegalTerms.removeClass("disabled");
            }
        }, false);
    }
};

/**
 * @function
 * @description Handles actions happening on clicking wallet payment option. It also triggers click event on desired
 * payment methods so hidden input fields would be populated.
 */
const handleWalletBrickClick = event => {
    if ($cache.walletPaymentOption.length && $cache.walletPaymentOption.hasClass("wallet-not-confirmed")) {
        return;
    }

    const $walletProductID = $('input[name*="_walletProductID"]');
    const $paymentSectionTitle = $cache.walletPaymentContainer.find(".payment-section-title.show-on-wallet-payment.hide-on-other-payment");

    if ($walletProductID.length && walletTopupPid) {
        $walletProductID.val(walletTopupPid);
    }

    if ($cache.walletPaymentOption && !$cache.walletPaymentOption.hasClass("disabled")) {
        updateCartWithDigitalTax("precalculateWalletRewards");

        $cache.walletPaymentOption.attr("data-isthresholdselected", isThresholdSelected);

        if (!$cache.opcWalletSection.length) {
            $cache.CCRadio.trigger("click");
        }

        // Component visuals
        if (event && event.type === "click") {
            $cache.otherPaymentOption.removeClass("selected");

            var isWalletSufficient = $(".payment-option.wallet").length && $(".payment-option.wallet").hasClass("selected") && ($(".payment-option.wallet").data("iswalletsufficient") || $(".opc-payment .order-subtotal").data("wallet-sufficient"));
            const $walletCheckoutConditionsCheckbox = $(".uplay-plus-wallet");

            if ($cache.walletPayment && $cache.otherPayment) {
                $cache.walletPayment.removeClass("hide");
                $cache.walletPaymentOption.addClass("selected");
            }

            // other payment is hidden until threshold is selected
            if ($cache.otherPayment.length && !isThresholdSelected && !$cache.otherPayment.hasClass("hide")) {
                $cache.otherPayment.addClass("hide");
            }

            if ($cache.walletPaymentOption.hasClass("pending-taxes")) {
                return;
            }

            if ($cache.walletPaymentOption.hasClass("selected") && (isWalletSufficient || isThresholdSelected)) {
                $cache.allOrderRecap.removeClass("hide");
                $cache.walletOrderRecap.removeClass("hide");
                $cache.commonOrderRecap.removeClass("hide");
            }

            if ($walletCheckoutConditionsCheckbox.length) {
                $walletCheckoutConditionsCheckbox.attr("disabled", false);
            }

            $cache.addressActions.removeClass("hide");

            if ((!$cache.walletPaymentOption.data("iswalletsufficient") && !$(".opc-payment .order-subtotal").data("wallet-sufficient")) || $cache.opcWalletSection.length) {
                if ($cache.opcWalletSection.length) {
                    $(".tos-conditions.show-on-wallet-payment").removeClass("hide");
                } else {
                    $(".show-on-wallet-payment").removeClass("hide");
                }
            } else {
                $cache.otherPayment.addClass("hide");
                $(".tos-container .show-on-wallet-payment").removeClass("hide");
            }

            $(".hide-on-wallet-payment").addClass("hide");

            initWalletCheckoutTopupWidget();

            // If we can pay with wallet && it's a subscription order
            if ($cache.upcCheckoutSubmit.hasClass("subscription-checkout-button")) {
                $cache.document.trigger("subscriptionCheckbox:reinit");
            }
        }

        if ($cache.rewardsDisclaimer.length) {
            $cache.rewardsDisclaimer.removeClass("hide");
        }

        $cache.walletPaymentContainer.addClass("wallet-payment");

        updateTopupPaymentMethods();
    }

    if ($paymentSectionTitle.length && !isWalletSufficient && !isThresholdSelected) {
        $paymentSectionTitle.addClass("hide");
    }

    if ($cache.walletBalance.length && isWalletSufficient) {
        $cache.walletBalance.toggleClass("hide", !$cache.walletPaymentOption.hasClass("selected"));
    }

    showPaymentMethodsWallet();
    updateTermsAndConditions("wallet");
};

/**
 * @function
 * @description Handles actions happening on clicking other payment option. It also triggers click event on desired
 * payment methods so hidden input fields would be populated.
 */
const handleOtherBrickClick = event => {
    if ($cache.otherPaymentOption && !$cache.otherPaymentOption.hasClass("disabled")) {
        updateCartWithDigitalTax("clickAtOtherBrick");

        if (!$cache.opcWalletSection.length) {
            $cache.CCRadio.trigger("click");
        }

        const $walletCreditToBeAdded = $('input[name*="_walletCreditToBeAdded"]');
        const $walletProductID = $('input[name*="_walletProductID"]');
        const $walletCheckoutConditionsCheckbox = $(".uplay-plus-wallet");

        if ($walletCreditToBeAdded.length) {
            $walletCreditToBeAdded.val("");
        }

        if ($walletProductID.length) {
            $walletProductID.val("");
        }

        // Component visuals
        if (event && event.type === "click") {
            $cache.walletPaymentOption.removeClass("selected");
            $cache.otherPaymentOption.addClass("selected");

            // shop payment sections for other payment method
            $cache.addressActions.removeClass("hide");
            $cache.otherPayment.removeClass("hide");
            $cache.allOrderRecap.removeClass("hide");
            $cache.commonOrderRecap.removeClass("hide");

            // hide wallet sections
            $cache.walletPayment.addClass("hide");
            $cache.walletOrderRecap.addClass("hide");

            $(".hide-on-other-payment").addClass("hide");
            $(".show-on-other-payment").removeClass("hide");

            if ($walletCheckoutConditionsCheckbox.length) {
                $walletCheckoutConditionsCheckbox.attr("disabled", true);
            }

            if ($cache.walletPaymentOption.data("iswalletsamecurrency") && $cache.otherPaymentOption.hasClass("selected") && $cache.addressActions && $cache.addressActions.hasClass("hide")) {
                $cache.addressActions.removeClass("hide");
            }

            if ($cache.upcCheckoutSubmit.hasClass("subscription-checkout-button")) {
                // Unchecked Wallet conditions
                $walletCheckoutConditionsCheckbox.attr("checked", false);
                $cache.document.trigger("subscriptionCheckbox:reinit");
            }

            // show legal terms
            if ($cache.upcCheckoutLegalTerms) {
                $cache.upcCheckoutLegalTerms.removeClass("hide");
            }
        }

        if ($(".payment-method-options").find(":checked").length && $(".payment-method-options").find(":checked").val() == "PayPal") {
            $("#is-PayPal").trigger("click");
        }

        if ($cache.rewardsDisclaimer.length) {
            $cache.rewardsDisclaimer.addClass("hide");
        }

        $cache.walletPaymentContainer.removeClass("wallet-payment");
    }

    if ($cache.walletBalance.length) {
        $cache.walletBalance.toggleClass("hide", !$cache.walletPaymentOption.hasClass("selected"));
    }

    updateTermsAndConditions("other");
};

/**
 * @function
 * @description Updates Terms and Conditions section
 */
async function updateTermsAndConditions(eventClick) {
    const $termsAndConditionsItems = $(".c-terms-conditions__item");

    if ($termsAndConditionsItems.length) {
        const url = util.appendParamsToUrl(Urls.walletPaymentAsset, {
            countryCode: GeoRestrictedResources.GEO_USER_COUNTRY_CODE
        });
        const response = await fetch(url);
        const html = await response.text();
        const $walletPaymentAsset = JSON.parse(html);

        if ($walletPaymentAsset) {
            const $walletPaymentDiv = document.createElement("div");

            $walletPaymentDiv.classList.add("c-terms-conditions__item");
            $walletPaymentDiv.innerHTML = $walletPaymentAsset;

            if (eventClick === "wallet") {
                const $termsAndConditionsWrapper = $(".c-terms-conditions");

                $termsAndConditionsWrapper[0].appendChild($walletPaymentDiv);
            } else if (eventClick === "other") {
                $termsAndConditionsItems.each((i, item) => {
                    const walletConditionsExists = item.outerText.indexOf($walletPaymentDiv.outerText.trim()) > -1;

                    if (walletConditionsExists) {
                        item.remove();
                    }
                });
            }
        }
    }
}

/**
 * @function
 * @description selects the default payment method if one of them is disabled
 */
const checkDisabledMethod = () => {
    if ($cache.walletPaymentOption.hasClass("disabled") && !$cache.otherPaymentOption.hasClass("selected")) {
        return;
    } else if ($cache.walletPaymentOption.hasClass("disabled")) {
        $cache.otherPaymentOption.addClass("selected");
    } else if ($cache.otherPaymentOption.hasClass("disabled")) {
        $cache.walletPaymentOption.addClass("selected");
    }
};

/**
 * @function
 * @description confirms the wallet
 */
const confirmWallet = () => {
    const isRedirectedToTopup = $cache.triggerCurrencyChoiceModal.hasClass("credit-wallet-redirection");

    if (isRedirectedToTopup) {
        window.top.location.href = Urls.topupPage;
    }

    if ($cache.walletPaymentOption.length) {
        $cache.walletPaymentOption.removeClass("wallet-not-confirmed");
        handleBrickSelectionOnLimitsUpdate();
    }
};

/**
 * @function
 * @description displays currency choice modal
 */
const initCurrencyChoiceModal = () => {
    $.ajax({
        url: util.ajaxUrl(Urls.getWallet)
    }).done(function (data) {
        if (data && data.status !== "active" && data.status !== "locked") {
            document.dispatchEvent(new Event("openCurrencyConfirmationModal"));

            // unselect the checkout wallet brick if user didn't confirmed his currency inside the popup
            document.querySelector("wallet-currency-confirmation-modal").addEventListener("onCloseModal", () => {
                const $checkoutWalletBrick = $(".payment-option.wallet");
                const $checkoutOtherBrick = $(".payment-option.other");
                const $otherPaymentSection = $(".other-payment-option, .form-row-button.address-actions");
                const $checkoutWalletAdvantages = $(".checkout-wallet-advantages");

                if ($checkoutOtherBrick.length && $checkoutOtherBrick.hasClass("selected")) {
                    $checkoutOtherBrick.removeClass("selected");
                }

                if ($checkoutWalletBrick.length && $checkoutWalletBrick.hasClass("selected")) {
                    $checkoutWalletBrick.removeClass("selected");
                }

                if ($checkoutWalletAdvantages.length) {
                    $checkoutWalletAdvantages.removeClass("hide");
                }

                if ($otherPaymentSection.length) {
                    $otherPaymentSection.addClass("hide");
                }
            }, true);
        } else {
            const isRedirectedToTopup = $cache.triggerCurrencyChoiceModal.hasClass("credit-wallet-redirection");

            if (isRedirectedToTopup) {
                window.top.location.href = Urls.topupPage;
            }
        }
    });
};

/**
 * @function
 * @description selects wallet backup payment credit card
 */
const selectWalletBackupPaymentCreditCard = () => {
    let $walletBackupCreditCard = $(".js-wallet-backup-creditcard-card");
    let $selectedCreditCard = $(".cc-saved.active");

    if (!$selectedCreditCard.length && $walletBackupCreditCard.length) {
        $walletBackupCreditCard.addClass("active").find("input").addClass("default-payment").trigger("click");
    }
};

/**
 * @function
 * @description disable delete payment method buttons from Uplay+ flow;
 */
const disableDeletePaymentMethodButtons = () => {
    let $creditCards = $(".saved-cards-container");
    let $walletCreditCard = $(".saved-cards-container-wallet");
    let $walletBackupCreditCard = $(".js-wallet-backup-creditcard-card");
    let $deleteButton = $(".delete-zuora-payment-method");

    if ($walletCreditCard.length || $walletBackupCreditCard.length) {
        if ($creditCards.length < 2) {
            let $creditCardDeleteButton = $creditCards.find($deleteButton);

            if ($creditCardDeleteButton.length) {
                $creditCardDeleteButton.addClass("hide");
            }
        } else if ($creditCards.length < 3) {
            let $walletCreditCardDeleteButton = $walletCreditCard.find($deleteButton);
            let $walletBackupCreditCardDeleteButton = $walletBackupCreditCard.find($deleteButton);

            if ($walletCreditCardDeleteButton.length) {
                $walletCreditCardDeleteButton.attr("disabled", true);
            }

            if ($walletBackupCreditCardDeleteButton.length && !$walletBackupCreditCard.hasClass("active")) {
                $walletBackupCreditCardDeleteButton.attr("disabled", true);
            }
        }
    }
};

/**
 * @function
 * @description handles showing/hiding elements from Uplay+ reactivation flow
 */
const handleReactivationModalOpen = () => {
    let $walletConditionsCheckbox = $(".terms-of-sale-wallet");
    let $walletCreditCard = $(".saved-cards-container-wallet");

    if ($walletConditionsCheckbox.length && !$walletCreditCard.length || !$walletCreditCard.hasClass("active")) {
        $walletConditionsCheckbox.addClass("hide");
    }

    selectWalletBackupPaymentCreditCard();
};

/**
 * @function
 * @description shows payment methods and order recap for Wallet
 */
const showPaymentMethodsWallet = () => {
    const isWalletSufficient = $cache.walletPaymentOption.length && $cache.walletPaymentOption.hasClass("selected") && ($cache.walletPaymentOption.data("iswalletsufficient") || $(".opc-payment .order-subtotal").data("wallet-sufficient"));
    const isWalletSameCurrency = $cache.walletPaymentOption.data("iswalletsamecurrency");
    const $checkoutConditions = $cache.addressActions.find(".js-checkout-conditions");
    const $paymentSectionTitleHide = $cache.walletPaymentContainer.find(".payment-section-title.show-on-wallet-payment.hide-on-other-payment.hide");

    if (!isWalletSufficient && isThresholdSelected) {
        if (isWalletSameCurrency || (!isWalletSameCurrency && isThresholdSelected)) {
            $cache.otherPayment.removeClass("hide");
        } else {
            $cache.otherPayment.addClass("hide");
        }

        $cache.allOrderRecap.removeClass("hide");
        $cache.walletOrderRecap.removeClass("hide");
        $cache.commonOrderRecap.removeClass("hide");
        $paymentSectionTitleHide.removeClass("hide");
        $cache.walletBalance.toggleClass("hide", !$cache.walletPaymentOption.hasClass("selected"));
        $checkoutConditions.removeClass("hide");
    } else if (isWalletSufficient) {
        $checkoutConditions.removeClass("hide");
    }
};

/**
 * @function
 * @description initialize specific events for Wallet feature
 */
const initEvents = () => {
    checkDisabledMethod();

    const walletCurrencyConfirmationModal = document.querySelector("wallet-currency-confirmation-modal");

    if (walletCurrencyConfirmationModal !== null) {
        walletCurrencyConfirmationModal.addEventListener("onConfirm", () => {
            if (SitePreferences.ENABLE_UBI_ADDRESS) {
                $.ajax({
                    // send primary billing address to ubiconnect service
                    url: Urls.sendPrimaryBillingAddress
                }).done(function () {
                    confirmWallet();
                });
            } else {
                confirmWallet();
            }
        }, false);
    }

    // Added this part since it collides Credit Card initialization events.
    $cache.document.ready(() => {
        if ($cache.walletPaymentOption.hasClass("selected")) {
            $cache.walletPaymentOption.trigger("click");
        } else if ($cache.otherPaymentOption.hasClass("selected")) {
            $cache.otherPaymentOption.trigger("click");
        }
    });

    walletTopupValue = null;
    walletTopupLabel = null;
    walletTopupPid = null;
};

/**
 * @function
 * @description Handle the brick selection when coupon code was added / removed and the limits are updated;
 */
const handleBrickSelectionOnLimitsUpdate = () => {
    fetch(Urls.getWalletRestrictionError)
        .then(response => {
            return response.text();
        }).then(html => {
            if (html) {
                const isLimitedWallet = html.includes("wallet-limit-error wallet-brick-error-message");
                const $walletPaymentOption = $(".payment-option.wallet");
                const $otherPaymentOption = $(".payment-option.other");

                if ($walletPaymentOption.length) {
                    const $walletPaymentTooltip = $walletPaymentOption.find(".tooltip-trigger");

                    if (isLimitedWallet) {
                        $walletPaymentOption.addClass("disabled");

                        if ($walletPaymentTooltip.length) {
                            $walletPaymentTooltip.removeClass("hide");
                        } else {
                            $walletPaymentOption
                                .find(".payment-option-balance")
                                .after($(html));
                            initProductTooltip();
                        }

                        if ($otherPaymentOption.length) {
                            $otherPaymentOption.trigger("click");
                        }
                    } else {
                        $walletPaymentOption
                            .removeClass("disabled")
                            .trigger("click");

                        if ($walletPaymentTooltip.length) {
                            $walletPaymentTooltip.addClass("hide");
                        }
                    }
                }
            }
        });
};

/**
 * @function
 * @description initialize specific events only once for Wallet feature
 */
const initEventsOnce = () => {
    if (initEventsOnce.initialized) {
        return;
    }

    initEventsOnce.initialized = true;

    $cache.walletPaymentOption.on("click", handleWalletBrickClick);
    $cache.otherPaymentOption.on("click", handleOtherBrickClick);
    $cache.triggerCurrencyChoiceModal.on("click", initCurrencyChoiceModal);
};

/**
 * @function
 * @description change the selected payment method if it is not available for wallet(topup/double order)
 */
const updateTopupPaymentMethods = () => {
    $cache.paymentMethodsSections.each((index, element) => {
        const $elem = $(element);
        const $selectedPaymentMethod = $elem.find(":checked");

        // the selection is not changed of the payment method is visible for wallet
        if (!$selectedPaymentMethod.length || !$selectedPaymentMethod.parents(".ehow").length) {
            return;
        }

        // select the first available payment method
        const $firstUncheckedInput = $elem.find(`input[name=${$selectedPaymentMethod.attr("name")}]:not(:checked)`);

        if ($firstUncheckedInput.length) {
            $firstUncheckedInput.first().prop("checked", true).trigger("change");
        }
    });
};

/**
 * @function
 * @description initialize Wallet
 */
const initWallet = () => {
    initCache();
    initEventsOnce();
    initEvents();
};

export { initWallet, selectWalletBackupPaymentCreditCard, disableDeletePaymentMethodButtons, handleReactivationModalOpen, updateTopupPaymentMethods };
