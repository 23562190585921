// Helpers
import utils from "../helpers/utils";
import initButtons from "../components/button";

// Coupon & promotions component

const couponActions = {
    /**
     * @function
     * @description Coupon component initialize events
     */
    init: () => {
        const couponForm = document.querySelector('[data-component="coupon-form"]');
        const couponInput = document.querySelector('[data-component="coupon-input"]');
        const removeCouponSubmit = document.querySelectorAll('[data-action="coupon-remove"]');

        couponForm?.addEventListener("submit", e => couponActions.submitCoupon(e));
        removeCouponSubmit?.forEach(currentCta => {
            currentCta.addEventListener("click", e => couponActions.removeCoupon(e));
        });

        // dispatch focus event
        couponInput?.addEventListener("focus", e => {
            const event = new CustomEvent("InputFocus:Coupon", {
                detail: e
            });

            document.dispatchEvent(event);
        });
    },

    /**
     * @function
     * @description Submit coupon code value, will dispatch event/payload to run cart refresh
     * @param {Event} e - submit event from apply button
     */
    submitCoupon: e => {
        e.preventDefault();

        const couponInput = document.querySelector('[data-component="coupon-input"]');
        const couponValue = couponInput.value;

        // Dispatch "click event"
        document.dispatchEvent(new Event("Button:CouponApply"));

        const url = utils.appendParamsToUrl(Urls.addSubCoupon, {
            couponCode: couponValue,
            format: "ajax"
        });

        fetch(url, {
            method: "GET"
        })
            .then(response => response.json())
            .then(response => {
                if (response.error) {
                    let event;

                    if (response.showApplyBestPromoPopup) {
                        fetch(utils.ajaxUrl(Urls.couponNotCombinable), {
                            method: "GET"
                        })
                            .then(response => response.text())
                            .then(response => {
                                event = new CustomEvent("Button:CouponNotCombinable", {
                                    detail: response
                                });

                                document.dispatchEvent(event);
                            });
                    } else if (typeof response.currentCouponCode === "undefined") {
                        event = new CustomEvent("Coupon:EmptyError", {
                            detail: response
                        });
                    } else {
                        event = new CustomEvent("Coupon:Error", {
                            detail: response
                        });
                    }

                    document.dispatchEvent(event);
                    couponActions.displayError(response.currentCouponCode, response.errorMessage);
                } else {
                    const result = response.coupons.filter(coupon => {
                        if (coupon.couponCode.toLowerCase() === response.currentCouponCode.toLowerCase()) {
                            return coupon;
                        }
                    });

                    // Pushing refresh object
                    const data = {
                        result,
                        refresh: response.refresh,
                        couponAdded: response.currentCouponCode
                    };

                    const event = new CustomEvent("Coupon:Add", {
                        detail: data
                    });

                    // If coupon is not combinable: We apply the current one, and display "notification" warning
                    // In order to do it, we need to pass context to the response
                    if (response.currentCouponCodeStatus === "CLUB_COUPON_COMBINE_ERROR") {
                        data.refresh.couponCombineWarning = response.errorMessage;
                    }

                    document.dispatchEvent(event);
                }
            });
    },

    /**
     * @function
     * @description Submit remove coupon, will dispatch event/payload to run cart refresh
     * @param {Event} e - Event click from removed button
     */
    removeCoupon: e => {
        const couponId = e.currentTarget.parentNode.dataset.couponId;
        const url = utils.appendParamsToUrl(Urls.removeSubCoupon, {
            couponCode: couponId,
            format: "ajax"
        });

        fetch(url, {
            method: "GET"
        })
            .then(response => response.json())
            .then(response => {
                if (response.success) {
                    const event = new CustomEvent("Coupon:Remove", {
                        detail: response
                    });

                    document.dispatchEvent(event);
                }
            });
    },

    /**
     * @function
     * @description Display the corresponding error, and update form state
     * @param {String} couponCode - Coupon code added
     * @param {String} errorMsg - Error message to display
     */
    displayError: (couponCode, errorMsg) => {
        const couponInput = document.querySelector('[data-component="coupon-input"]');
        const couponError = document.querySelector('[data-error="coupon-error"]');
        const couponNotification = document.querySelector('[data-component="notification"]');

        if (typeof couponCode === "undefined") {
            errorMsg = Resources.COUPON_CODE_MISSING;
        } else {
            errorMsg = errorMsg || String.format(Resources.ERROR_MESSAGE, couponCode);
        }

        if (!errorMsg) return;

        couponNotification?.remove();
        couponInput.classList.add("c-field__input--error");
        couponError.classList.remove("u-hidden");
        couponError.innerHTML = errorMsg;
    },

    /**
     * @function
     * @description Create "warning" notification when "non combinable" coupon is submitted
     *              Append it at coupon block level, and reinit buttons
     * @param {String} warningMsg - Message content displayed within notification
     */
    displayNotification: warningMsg => {
        let notification = document.createElement("div");
        const target = document.querySelector("[data-component='coupon-form']");

        notification.classList.add("c-notification", "c-notification--error");
        notification.setAttribute("data-component", "notification");
        notification.innerHTML = `
            <p class="c-notification__text">
                <i class="e-notification__icon"></i>
                ${warningMsg}
            </p>

            <div class="c-notification__action">
                <button type="button" data-component="button" data-name="DismissNotification" class="c-button--dismiss">
                    <i class="e-button__icon"></i>
                </button>
            </div>
        `;

        target.appendChild(notification);
        initButtons();
    }

};

export default couponActions;
