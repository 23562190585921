/**
 * @function
 * @description Initialize change language event
 */
const initializeEvents = () => {
    const $document = $(document);
    const $languageSelector = $(".language-selector");
    const $footerLanguageSelector = $(".footer-language-selector");
    const $linksSection = $(".footer-bottom .links-section");
    const $footerLanguageWrapper = $(".footer-language-wrapper");

    $languageSelector.on("change", e => {
        const $optionSelected = $(e.currentTarget).find("option:selected");
        const url = $optionSelected.length ? $optionSelected.data("href") : "";

        if (url) {
            window.location.href = url;
        }
    });

    if ($footerLanguageSelector.length > 0 && $linksSection.length > 0) {
        const $lastUl = $linksSection.find("ul:last");

        $footerLanguageWrapper.addClass("hide");

        if ($lastUl.length > 0) {
            setTimeout(() => {
                $footerLanguageSelector.css({
                    width: $lastUl.width() + "px"
                });

                $footerLanguageWrapper.removeClass("hide");
            }, 1000);
        }
    }

    $document.on("keyup", ".selectboxit.language-selector", e => {
        const $this = $(e.currentTarget);

        if (e.keyCode === 9) {
            $this.addClass("selectboxit-focused");
        }
    });

    $document.on("focusout", ".selectboxit.language-selector", e => {
        $(e.currentTarget).removeClass("selectboxit-focused");
    });
};

const initLanguageSelector = () => {
    initializeEvents();
};

export { initLanguageSelector };
