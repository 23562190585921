/**
 * Module provides API for Google Analytics Events
 * @module googleAnalytics
 *
 */

var $cache = $cache || {},
    data = '';

/**
 * initialize variables
 * @private
 */
function initializeCache() {
    $cache.document = $( document );
}

/**
 * Initialize events
 * @private
 */
function initializeEvents() {
    $(document).on('click', '.ga-event', function(e) {
        var button = $(this).data('gaButton'),
            action = $(this).data('gaAction'),
            label = $(this).data('gaLabel');
        sendEvent(button, action, label);
    });

    $(document).on('click', '.up-login', function(e) {
        sendEvent("Account", "Login");
    });
}

/**
 * Send events
 * @private
 */
function sendEvent(button, action, label, value) {
    try {
        if (typeof ga === 'function') {
            if (!label) {
                ga('send', 'event', button, action);
            } else {
                if (!value) {
                    ga('send', 'event', button, action, label);
                } else {
                    ga('send', 'event', button, action, label, value);
                }
            }
        }
    } catch (e) {
        // ERROR
    };
}

function initGoogleAnalytics() {
    initializeCache();
    initializeEvents();
}

export { initGoogleAnalytics };
