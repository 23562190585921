var threedsv2 = {
    // initialize cache object
    initializeThreeDSEvents: function () {
        if (SitePreferences.ENABLE_3DSV2) {
            // capture df reference id from WorldPay after submitting the card data and JWT
            window.addEventListener("message", function (event) {
                // we need to extract the host from https://secure-test.worldpay.com/shopper/3ds/ddc.html and to receive message just from this host
                var ThreeDsCollectionURL = SitePreferences.THREEDS_COLLECTION_URL;
                // the example of value to be extracted for test env https://secure-test.worldpay.com
                var ThreeDSCollectionURLArray = ThreeDsCollectionURL ? ThreeDsCollectionURL.split("/") : null;
                var ThreeDsHost = ThreeDSCollectionURLArray ? ThreeDSCollectionURLArray[0] + "//" + ThreeDSCollectionURLArray[2] : "";

                if (event.origin === ThreeDsHost) {
                    var data = JSON.parse(event.data);

                    if (data !== undefined && data.Status) {
                        // fill the dfReference for using into the initial XML request
                        $("input[name$='dfReferenceId']").val(data.SessionId);
                    }
                }
            }, false);
        }
    },
    /**
     * @function
     * @description Function used to collect the card data at BD side and to generate the ddc iframe for uplay site
     * @param {jQuery} ccData Credit Card data DOM element
     */
    checkfor3ds: function (data) {
        $.ajax({
            url: Urls.collectData,
            data: data,
            success: function (result) {
                $(".ddc-iframe").html(result);
            }
        });
    }
};

export { threedsv2 };
