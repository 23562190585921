import jRespond from "jrespond";

// set the breakpoints
var jRes = jRespond([
    {
        label: "mobilePortrait",
        enter: 0,
        exit: 479
    }, {
        label: "mobileLandscape",
        enter: 480,
        exit: 767
    }, {
        label: "tablet",
        enter: 768,
        exit: 959
    }, {
        label: "desktop",
        enter: 960,
        exit: 10000
    }
]);

// set desktop as default
jRes.isMobilePortrait = false;
jRes.isMobileLandscape = false;
jRes.isTablet = false;
jRes.isDesktop = false;

if (!(SitePreferences.DISABLE_RESPONSIVE_DESIGN)) {
    jRes.addFunc([{
        breakpoint: "mobilePortrait",
        enter: function () {
            jRes.isMobilePortrait = true;
        },
        exit: function () {
            jRes.isMobilePortrait = false;
        }
    }, {
        breakpoint: "mobileLandscape",
        enter: function () {
            jRes.isMobileLandscape = true;
        },
        exit: function () {
            jRes.isMobileLandscape = false;
        }
    }, {
        breakpoint: "tablet",
        enter: function () {
            jRes.isTablet = true;
        },
        exit: function () {
            jRes.isTablet = false;
        }
    }, {
        breakpoint: "desktop",
        enter: function () {
            jRes.isDesktop = true;
        },
        exit: function () {
            jRes.isDesktop = false;
        }
    }]);
}

export { jRes };
