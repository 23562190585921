function sticky_relocate() {
    var window_top = $(window).scrollTop(),
        div_top = $("#sticky-anchor").offset().top;

    if (window_top > div_top) {
        $("#sticky-filter").addClass("stick");
    } else {
        $("#sticky-filter").removeClass("stick");
    }
}

const data = {
    // the section expanded by the view more button
    viewMoreTogglePanel: "[data-view-more-toggle-panel]",
    // the section expanded by the refinement title
    refinementSectionTogglePanel: "[data-refinement-toggle-panel]"
};

let $cache = {};

/**
 * @function
 * @description initialize cache object
 */
const initCache = () => {
    $cache = {
        document: $(document)
    };
};

var refinements = {
    init: function (activeRefinementIndex) {
        initCache();

        var filters = $(".breadcrumb").find(".breadcrumb-refinement-value").length,
            spanSticky = $("#sticky-filter").find("span"),
            refinementsSection = $("#secondary"),
            isUPC = $(".app_uplay").length;

        if ($("#wrapper").find("#sticky-anchor").length) {
            $(window).scroll(sticky_relocate);
            sticky_relocate();
        }

        $("#sticky-filter").on("click", function (e) {
            e.stopPropagation();
            $(this).closest("#main").find("#secondary").toggleClass("show");
        });

        $(".refinement").each(function () {
            const $thisRef = $(this);

            if (!$(this).closest(".js_include_category_wrapper").length) {
                const $refinementGroup = $thisRef.find(data.viewMoreTogglePanel);
                let $viewMoreBtn = null;

                if ($refinementGroup.length) {
                    const moreItemsId = $refinementGroup.attr("id");

                    // get the button which controls the view more section
                    $viewMoreBtn = $thisRef.find(`[aria-controls=${moreItemsId}]`);
                }

                if ($thisRef.find("li.selected").length) {
                    const $discloseComponentPanel = $thisRef.closest("[data-disclosure-component-panel]");

                    if (!$discloseComponentPanel.hasClass("active")) {
                        const expandableSectionId = $discloseComponentPanel.attr("id");
                        // get the button which controls the refinement section
                        const $expandableSectionBtn = $("#" + expandableSectionId);

                        // expand the refinement group if a filter is checked
                        $expandableSectionBtn.trigger("click");
                    }

                    // expand the 'view more section' of the refinement group if it contains checked filters
                    if (!$refinementGroup.hasClass("active") && $refinementGroup.find("li.selected").length && $viewMoreBtn?.length && $thisRef.index() == activeRefinementIndex) {
                        $viewMoreBtn.trigger("click");
                    }
                }
            }
        });

        spanSticky.text(filters);

        $(".refinement").on("click", "ul a, .home-refinement-link", function () {
            var filtersValue = $(".breadcrumb").find(".breadcrumb-refinement-value").length + 1;

            spanSticky.text(filtersValue);
        });

        $cache.document.on("click", function (event) {
            if (!$(event.target).closest(refinementsSection).length && !$(event.target).is(refinementsSection)) {
                if (refinementsSection.hasClass("show")) {
                    refinementsSection.removeClass("show");
                }
            }
        });

        if (isUPC) {
            $(".refinement li").on("click", function (e) {
                $(e.target).trigger("upcFilter");
            });
            $(window).on("resize", function () {
                var $target = $("#main").find(".refinement.visible");

                if ($target.length) {
                    $target.find("ul").css("width", $target.width() * $target.find(".refinement-group").length);
                }
            });
        }

        $cache.document.on("disclosureComponents:toggle", (event, eventData) => {
            if (!eventData?.expanded || !eventData?.expandablePanel?.closest(".refinements")?.length) {
                return;
            }

            // move focus inside the selected element
            const $firstFocusableItem = eventData?.expandablePanel?.find(":focusable:first");

            if ($firstFocusableItem?.length) {
                $firstFocusableItem.trigger("focus");
            }
        });

        if (SitePreferences.SEARCH_ENGINE_ID === "algolia" && SitePreferences.ALGOLIA_ENABLED) {
            refinementsSection.off("click").on("click", ".refinement h3, .refinement .toggle", function () {
                var $refinementsElement = $(this);

                if ($refinementsElement.siblings("ul").children().length > 0 || $refinementsElement.siblings(".price-slider-refinement")) {
                    if ($refinementsElement.hasClass("is-open expanded")) {
                        $refinementsElement.siblings(".refinement-group").removeClass("view-all-refinements").hide();
                        $refinementsElement.removeClass("is-open expanded");
                    } else {
                        $refinementsElement.siblings(".refinement-group").show();
                        $refinementsElement.addClass("is-open expanded");
                    }
                } else {
                    const $refinementTitle = $refinementsElement.find(".toggle");

                    $refinementsElement.find("h3").children().remove();
                    $refinementsElement.find("h3").removeClass("toggle");
                    $refinementsElement.find("h3").addClass("unselectable");

                    if ($refinementTitle.length) {
                        $refinementTitle
                            .removeClass("toggle")
                            .addClass("unselectable")
                            .children()
                            .remove();
                    }
                }
            });
        }
    }
};

export { refinements };
