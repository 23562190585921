import {clickEvent} from "./adobe_analytics_events";
import {impressionEvent} from "./adobe_analytics_events";

const $document = $(document);

/**
 * @function
 * @description Initialize subscription notification events
 */
const initializeSubNotificationsEvents = () => {
    // not same tenant popin events
    $(document).on("click", "[id*='notsametenantpopin'] a, [id*='notsametenantpopin'] button, .c-changetenant button, .c-changetenant a", e => {
        const $target = $(e.target);
        let eventParams;
        // data attribute name containing all necessary event tracking parameters
        const trackingParams = "tenant-tracking-click-params";

        if (!$(`[data-${trackingParams}]`).length) {
            return;
        }

        // read data from correct modal, since on WEB we have 2 of them per page
        const actionData = $target.parents(".reveal").length ? $target.parents(".reveal").data(trackingParams) : $(`[data-${trackingParams}]`).data(trackingParams);
        const {tenantArticle, tenantClose, tenantFaq} = actionData;

        if ($target.hasClass("faq-link")) {
            eventParams = tenantFaq;
        } else if ($target.hasClass("article-link")) {
            eventParams = tenantArticle;
        } else if ($target.closest(".close-button").length || $target.closest(".ui-dialog-titlebar-close").length) {
            eventParams = tenantClose;
        }

        if (eventParams) {
            clickEvent(e, eventParams, null, null, false);
        }
    });

    // not same tenant modal/ dialog impression listener
    $document.on("open.zf.reveal dialogopen", e => {
        const $modal = $(e.target).parent();
        const eventParams = $modal.find("[data-tenant-tracking-impression-params]").data("tenant-tracking-impression-params");

        if (eventParams) {
            impressionEvent(e, eventParams);
        }
    });
};

export { initializeSubNotificationsEvents };
