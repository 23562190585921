import { jRes } from "./jRes";

/**
 * @function
 * @description Initializes the responsive menu of the Workshop
 * If the transition is made from desktop/tablet to mobile, the header elements (links to switch between games and workshop) are moved in the navigation
 * If the transition is made from mobile to desktop/tablet, the navigation elements (links to switch between games and workshop) are moved to header
 */
function initNCSAWshopMenu() {
    jRes.addFunc([{
        breakpoint: ["desktop", "tablet"],
        enter: function () {
            $(".header-top .dock-right").detach().insertAfter(".ncsa-categories .dock-left");
            $(".menu-category .switch-category").children().each(function () {
                $(this).appendTo(".ncsa-categories .dock-left");
            });
            $(".menu-category .switch-category").remove();
        }
    }, {
        breakpoint: ["mobileLandscape", "mobilePortrait"],
        enter: function () {
            $(".ncsa-categories .dock-right").detach().insertAfter(".primary-logo");

            $(".menu-category").append('<li class="switch-category"></li>');
            $(".ncsa-categories .dock-left").children().each(function () {
                $(this).appendTo(".switch-category");
            });
        }
    }]);
}

export { initNCSAWshopMenu };
