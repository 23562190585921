let $cache = {};

/**
 * @function
 * @description initialize cache object
 */
const initCache = () => {
    $cache = {
        document: $(document)
    };
};

/**
 * @function
 * @param {jQuery} target - event target element
 * @returns {Object} - contains information about slider and its elements as well as jquery objects of current slider's elements
 */
const getSettings = target => {
    const isSlick = target.parents().is(".slick-initialized.slick-slider");
    const isSplide = target.parents().is(".splide.c-slider.is-initialized.is-active");
    const parentSlider = target.parents(isSlick ? ".slick-initialized.slick-slider" : isSplide ? ".splide.c-slider.is-initialized.is-active" : "");
    const currentSlide = target.parents(isSlick ? ".slick-slide" : isSplide ? ".splide__slide.is-visible" : "");
    const nextArrow = parentSlider.find(isSlick ? ".slider-control-next" : isSplide ? ".splide__arrow--next" : "");
    const prevArrow = parentSlider.find(isSlick ? ".slider-control-prev" : isSplide ? ".splide__arrow--prev" : "");
    const nextSlide = currentSlide.next(isSlick ? ".slick-slide" : isSplide ? ".splide__slide" : "");
    const prevSlide = currentSlide.prev(isSlick ? ".slick-slide" : isSplide ? ".splide__slide" : "");
    const visibleSlideSelector = isSlick ? ".slick-slide.slick-active" : isSplide ? ".splide__slide.is-visible" : "";

    return {
        isSlick,
        isSplide,
        parentSlider,
        currentSlide,
        nextArrow,
        prevArrow,
        nextSlide,
        prevSlide,
        visibleSlideSelector
    };
};

/**
 *
 * @param {jQuery} - element to search preceding focusable element against. Finds focusable element in the same parent selector and in previous siblings
 * @returns {jQuery} - target to be focused
 */
const getPrecedingFocusableElement = element => {
    const sliderId = element.attr("aria-controls");
    // parent containers selection which contain focusable elements, excluding current slider track and its controls.
    // Can be slider's parent if it contains focusable elements placed before slides track and arrows
    const targetParent = $(element).parents(`:not(:has(>.splide__arrow[aria-controls=${sliderId}], >#${sliderId})):has(:focusable:not(.splide__track :focusable, .splide__arrows :focusable))`).first();
    // find last focusable element excluding slider's track and controls
    const target = targetParent.find(`:focusable:not(#${sliderId} :focusable, .splide__arrow[aria-controls=${sliderId}] :focusable, .splide__arrow[aria-controls=${sliderId}]:focusable, .thumb-link)`).last();

    return target;
};

/**
* @function
* @description function to handle slider events
*/
const eventListeners = () => {
    // Tabbing over .product-tile
    $cache.document.on("keydown", ".splide.c-slider .product-tile, .enhanced-slider-products .product-tile", e => {
        const currentTarget = $(e.currentTarget);
        const target = $(e.target);
        const {isSlick, isSplide, nextSlide, prevSlide, nextArrow, prevArrow} = getSettings(target);

        // return if slider is not initialized
        if (!isSlick && !isSplide) return;

        // tabbing products forward
        if (e.key === "Tab" && !e.shiftKey) {
            const nextFocusable = currentTarget.find(":focusable:visible:not(.thumb-link)").not(target);

            // let browser set focus by itself if any focusable left
            if (nextFocusable.length) return;

            // redirect the focus to next arrow in product sliders
            if (nextSlide.length && nextSlide.attr("aria-hidden") === "true") {
                e.preventDefault();

                if (nextArrow.length) {
                    nextArrow.trigger("focus");
                }

                return;
            } else if (!nextSlide.length) {
                if (isSplide) {
                    prevArrow.attr("tabindex", "-1");
                } else if (isSlick) {
                    nextArrow.attr("tabindex", "-1");
                }
            }
        }

        // tabbing products backwards
        if (e.key === "Tab" && e.shiftKey) {
            if (isSlick) {
                nextArrow.attr("tabindex", "0");
            }

            if (!prevSlide.length) return;
            e.preventDefault();

            // focus previous product-tile
            if (prevSlide.hasClass("slick-active") || prevSlide.hasClass("is-visible")) {
                prevSlide.find(".product-tile").trigger("focus");
            } else if (prevSlide.attr("aria-hidden") === "true") {
                setTimeout(() => {
                    prevArrow.attr("tabindex", "0").trigger("focus");
                });
            }
        }
    });

    // focus product-tiles after moving slide-track
    $cache.document.on("keyup", ".splide.c-slider .splide__arrow, .enhanced-slider-products .slider-control", e => {
        const target = $(e.currentTarget);
        const {isSlick, parentSlider, nextArrow, prevArrow, visibleSlideSelector} = getSettings(target);

        // focus closest product-tile after moving slides
        if (e.key === "Enter") {
            const focusDelay = isSlick ? 300 : 500;

            if (target.is(nextArrow)) {
                setTimeout(() => {
                    parentSlider.find(visibleSlideSelector).first().find(".product-tile").trigger("focus");
                }, focusDelay);
            } else if (target.is(prevArrow)) {
                setTimeout(() => {
                    parentSlider.find(visibleSlideSelector).last().find(".product-tile").trigger("focus");
                }, focusDelay);
            }
        }
    });

    // redirect focus to arrow (if present) when Tab-focused from outside the slider (Tabbing experience through entire page)
    $cache.document.on("focusin", ".splide.c-slider .splide__slide:not(.is-visible), .enhanced-slider-products .slick-slide[aria-hidden=true]", e => {
        e.preventDefault();

        const target = $(e.currentTarget);
        const {parentSlider, isSlick, isSplide} = getSettings(target);

        if (isSplide) {
            const activeArrow = target.parents(".splide").find(".splide__arrow").not("[disabled]").first();

            if (target.is("[aria-hidden=true]") && activeArrow.length) {
                activeArrow.trigger("focus");
            }
        } else if (isSlick) {
            parentSlider.find(".slider-control:not(.slick-disabled)").first().trigger("focus");
        }
    });

    // Tab-focus redirection from arrows -> to visible corresponding slides
    $cache.document.on("keydown", ".enhanced-slider-products .slider-control.slick-arrow:not([aria-hidden=true]), .splide.c-slider .splide__arrow", e => {
        const target = $(e.currentTarget);
        const {isSplide, nextArrow, prevArrow, parentSlider, visibleSlideSelector} = getSettings(target);
        const visibleSlides = parentSlider.find(visibleSlideSelector);

        // tab forward
        if (e.key === "Tab" && !e.shiftKey) {
            if (isSplide && target.hasClass("splide__arrow--next")) return;

            if (target.is(prevArrow)) {
                setTimeout(() => {
                    visibleSlides.first().find(".product-tile").trigger("focus");
                });
            }
        }

        // tab backwards
        if (e.key === "Tab" && e.shiftKey) {
            if (target.is(nextArrow)) {
                e.preventDefault();

                setTimeout(() => {
                    visibleSlides.last().find(".product-tile").trigger("focus");
                });
            }

            if (isSplide && target.hasClass("splide__arrow--prev")) {
                const precedingFocusable = getPrecedingFocusableElement(target);

                setTimeout(() => {
                    precedingFocusable.trigger("focus");
                });
            }
        }
    });
};

function initializeProductSliderFocus() {
    initCache();
    eventListeners();
}

export { initializeProductSliderFocus };
