import {impressionEvent} from "./adobe_analytics_events";

const $document = $(document);

/**
 * @function
 * @description tracks impression event upon displaying available plans
 */
const trackAvailablePlans = () => {
    const $subscriptionTiles = $(".ubisoft-pass-choice-wrapper.js-sub-tracking").find(".subscription-variation-item[data-subscriptionid]");
    let productIdsArray = [];

    $.map($subscriptionTiles, element => {
        productIdsArray.push(element.getAttribute("data-subscriptionid"));
    });

    const eventParams = {
        location: "available plans",
        category: "display",
        locationPosition: "pop in",
        action: "visible",
        productImpression: productIdsArray
    };

    impressionEvent(null, eventParams);
};

/**
 * @function
 * @description creates eventParams object from given container and tracks clickevent
 * @param {jQuery} $target - variation item or "choose this plan" container with selected plan data
 */
const trackPlanEvents = $target => {
    let isPremiumAnywhere;
    let action;
    let productId;
    let category;
    const $subscriptionCardCtnr = $target.closest(".subscription-variation-item");

    if ($target.hasClass("gamelist-anchor")) {
        isPremiumAnywhere = $subscriptionCardCtnr.data("subscriptionplan")?.toLowerCase().indexOf("premiumanywhere") > -1;
        action = `available games : ${$target.data("gameslist")}`;
        productId = $subscriptionCardCtnr.data("subscriptionid");
        category = "interaction";
    } else {
        isPremiumAnywhere = $target.data("subscriptionplan")?.toLowerCase().indexOf("premiumanywhere") > -1;
        action = "ubisoft plus : add to cart";
        productId = $target.data("subscriptionid");
        category = "action";
    }

    const offerName = isPremiumAnywhere ? "multi access" : "pc access";
    const productDiscountRate = $subscriptionCardCtnr.data("productdiscountrate") || "";
    const productDiscountName = $subscriptionCardCtnr.data("productdiscountname") || "";
    const productDiscount = $subscriptionCardCtnr.data("productdiscount") || "no";

    const eventParams = {
        productId: productId,
        productName: "ubisoft plus",
        productPlatform: offerName,
        productSalesType: "subscription",
        location: "available plans",
        category: category,
        action: action,
        productDiscountRate: productDiscountRate,
        productDiscountName: productDiscountName,
        productDiscount: productDiscount
    };

    if ($target.closest(".ubisoft-pass-choice-wrapper").hasClass("js-sub-tracking")) {
        eventParams.locationPosition = "pop in";
    }

    if (adobeAnalytics) {
        adobeAnalytics.track("clickevent", eventParams);
    }
};

/**
 * @function
 * @description Initialize subscription modal plan selection events
 */
const initializePlanSelectionEvents = () => {
    $document.off(".adobeAnalyticsEvent");
    // open modal impression event
    $document.on("open.zf.reveal.adobeAnalyticsEvent", "#ubisoft-pass-choice", e => {
        if ($(e.target).find(".ubisoft-pass-choice-wrapper").length) {
            trackAvailablePlans();
        }
    });

    if ($(".ubisoft-pass-choice-wrapper.js-sub-tracking").length) {
        trackAvailablePlans();
    }

    // "choose this plan" listener
    $document.on("click.adobeAnalyticsEvent", ".ubisoft-pass-choice-wrapper .select-plan-label", e => {
        trackPlanEvents($(e.currentTarget));
    });

    document.addEventListener("Button:GamingList", e => {
        const $target = $(e.detail.button);

        // do not track if the anchor is on the Plan Switch Card
        if ($target.closest(".switch-subscription-card").length === 0) {
            trackPlanEvents($target);
        }
    });
};

export { initializePlanSelectionEvents };
