import "slick-carousel";

var prevArrowHtml = '<button type="button" class="slider-control slider-control-prev"><span class="show-for-sr">' + Resources.CONTROLS_PREV + '</span><span class="slider-controls-icon"><svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 500 500"><path d="M386.233 0L92 244.83 377.28 500l21.965-24.572-256.78-229.661L407.327 25.38z" fill="#35c1cf"/></svg></span></button>';
var nextArrowHtml = '<button type="button" class="slider-control slider-control-next"><span class="show-for-sr">' + Resources.CONTROLS_NEXT + '</span><span class="slider-controls-icon"><svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 500 500"><path d="M113.094 500l294.233-244.83L122.047 0l-21.965 24.572 256.78 229.661L92 474.62z" fill="#35c1cf"/></svg></span></button>';

/**
* @function
* @description function to call for the Video Slider settings
* @param {object} $selector - object - jquery selector of the slider to get params from
* @return {Object} slickSettingsVideoSlider - object with slider settings
*/
function returnVideoSliderSettings($selector) {
    var slickSettingsVideoSlider = {
        dots: false,
        infinite: false,
        speed: 400,
        slidesToShow: 5,
        slidesToScroll: 5,
        autoplay: false,
        arrows: true,
        autoplaySpeed: ($selector && $selector.data("autoplay-time")) ? $selector.data("autoplay-time") : 5000,
        prevArrow: prevArrowHtml,
        nextArrow: nextArrowHtml,
        cssEase: "ease",
        responsive: [
            {
                breakpoint: 1920,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 5
                }
            },
            {
                breakpoint: 1469,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4
                }
            },
            {
                breakpoint: 959,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            }
        ]
    };

    return slickSettingsVideoSlider;
}

/**
* @function
* @description function to get Videos for each slider
*/
function getVideos() {
    $(".video-list-slider[data-playlist-id]").each(function () {
        var $this = $(this);

        $.ajax({
            url: Urls.getVideos,
            data: {
                playlistID: $this.data("playlist-id")
            }
        }).success(function (response) {
            if (response.success === false) {
                $this.remove();
            } else {
                var $sliderContainer = $this.find(".enhanced-slider-video");

                $sliderContainer
                    .removeClass("load-section")
                    .html(response)
                    .slick(returnVideoSliderSettings($sliderContainer));
            }
        });
    });
}

function initVideoSliders() {
    getVideos();
}

export { initVideoSliders };
