var $cache = $cache || {};
var fromClick;

$cache.document = $(document);

var isUPCGamingListPageURL = Resources.GAMING_LIST_PAGE_URL != "" ? window.location.href.indexOf(Resources.GAMING_LIST_PAGE_URL) > -1 : window.location.href.indexOf("uplay-plus") > -1;
const subscriptionCategId = $("#subscription-category-id").length ? $("#subscription-category-id").val() : "";
var isUPCUbiPassLandingPageURL = SitePreferences.SUBSCRIPTION_CATEGORY_ID === subscriptionCategId;

/**
 * Initialize events
 * @private
 */
function initializeEvents() {
    upcMenuTracking();
    upcSubscriptionButtonEvents();
    upcLandingScrollTracking();
    upcChooseYourPlan();
    upcEarlyRegistrationEvents();
    upcPdpClickEventsTracking();
    upcGamesListSubscribeEvents();
    upcGamingListButtons();
    upcSubscriptionPassPLPFilters();
    switchSubscriptionPlanEvents();
    cancelSwitchRequestEvents();

    if (pageContext.ns == "account") {
        upcSubscriptionMyAccountEvents();
        upcAddressPaymentEvents();
    }
}

// Check if the section is in the viewport and send an event
function checkScrollEvent(el, txt) {
    if (!el.data("tagging-triggered")) {
        var elementTop = el.position().top;
        var elementBottom = el.height();
        var viewportTop = $(document).scrollTop();
        var viewportBottom = elementTop + elementBottom;
        var inViewport = elementTop <= viewportTop && viewportBottom > viewportTop;

        if (inViewport) {
            el.data("tagging-triggered", true);

            var actionValue = txt + " - tab displayed - ";

            actionValue += (fromClick) ? "click" : "scroll";

            el.data("event", "scrollEvent");
            sendEvent("clickevent", actionValue, el);

            if (fromClick) {
                fromClick = null;
            }
        }
    }
}

function upcGamingListButtons() {
    // GAMING LIST PAGE - CLICK EVENTS - HERO SLIDERS BUTTONS
    if (isUPCGamingListPageURL && pageContext.ns == "ubisoftpasssubscription") {
        $cache.document.on("click", ".subscription-slider-cta, .hero-slider-see-more, .open-ubisoft-pass-choice", function () {
            if ($(this).parent().closest(".enhanced-slider-products").length > 0 || $(this).hasClass("tag-plp-pass-choice") || $(this).hasClass("tile-reactivation-button") || $(this).hasClass("sub-tag-get-ubisoft-pass")) {
                return;
            }

            var customerStatus = $cache.document.find(".customer_status").length > 0 ? $cache.document.find(".customer_status").data("subs-status") : "";
            var sliderNumber = $(this).parent().closest(".slide.slick-slide").data("slick-index");
            var actionValue;

            sliderNumber = sliderNumber + 1;

            if ($(this).hasClass("open-ubisoft-pass-choice") && sliderNumber != undefined) {
                if (customerStatus == "Inactive") {
                    actionValue = "hero banner - slider " + sliderNumber + " - get ubisoft plus - renew your plan";
                } else {
                    actionValue = "hero banner - slider " + sliderNumber + " - subscribe - ubisoft plus";
                }
            } else if (sliderNumber != undefined && $(this).hasClass("sub-tag-add-to-library")) {
                actionValue = "hero banner - slider " + sliderNumber + " - ubisoft plus - add to library";
            } else if (sliderNumber != undefined && $(this).hasClass("sub-tag-start-playing-now")) {
                actionValue = "hero banner - slider " + sliderNumber + " - ubisoft plus - start playing now";
            } else if (sliderNumber != undefined) {
                actionValue = "hero banner - slider " + sliderNumber + " - go to product - ubisoft plus";
            }

            if ($(this).hasClass("pass-disclaimer-inactive-button")) {
                actionValue = "ubisoft plus renew push - get ubisoft plus - choose your plan";
            }

            tc_events_100(this, "clickevent", {
                ACTION: actionValue
            });
        });
    }
}

function upcMenuTracking() {
    // Navigation - Menu Tracking - Click Event - UPLAY+ Tab
    $cache.document.on("click", "#sub-tag-nav-uplay", function () {
        tc_events_100(this, "clickevent", {
            ACTION: "header - menu - " + Resources.UBISOFT_PLUS_TITLE
        });
    });
}

function upcPdpClickEventsTracking() {
    // PDP - Click Event - 'Learn More' link
    $cache.document.on("click", "#tag-learn-more", function () {
        tc_events_100(this, "clickevent", {
            ACTION: "product info - go to product - ubisoft plus"
        });
    });

    // PDP - Click Event -'Start Playing Now' button
    $cache.document.on("click", "#tag-start-playing-now", function () {
        tc_events_100(this, "clickevent", {
            ACTION: "product info - play - ubisoft plus"
        });
    });

    // PDP - Click Event -'Play for free' button
    $cache.document.on("click", "#tag-play-for-free", function () {
        tc_events_100(this, "clickevent", {
            ACTION: "product info - uplay plus - play for free - " + $(this).data("product-name").toLowerCase()
        });
    });

    // PDP - Click Event -'Add to Library' button
    $cache.document.on("click", "#tag-add-to-library", function () {
        tc_events_100(this, "clickevent", {
            ACTION: "product info - uplay plus - add to library - " + $(this).data("product-name").toLowerCase()
        });
    });

    // PDP - Click Event -'Join UPLAY+' button
    $cache.document.on("click", ".tag-get-uplay-plus", function () {
        tc_events_100(this, "clickevent", {
            ACTION: "product info - subscribe - ubisoft plus"
        });
    });
}

function upcSubscriptionMyAccountEvents() {
    // My Account - Click Event - Cancel switch request button
    $cache.document.on("click", ".ubisoftplus-account .btn-cancel-change-plan", e => {
        tc_events_100(e.currentTarget, "clickevent", {
            ACTION: "my subscription : " + Resources.UBISOFT_PLUS_TITLE + " - navigation - cancel switch request"
        });
    });

    // My Account - Click Event - Cancel switch request banner
    $cache.document.on("click", ".ubisoftplus-account .cancel-downgrade-banner button", e => {
        tc_events_100(e.currentTarget, "clickevent", {
            ACTION: "my subscription : " + Resources.UBISOFT_PLUS_TITLE + " - navigation - your subscription has changed : cancel switch request"
        });
    });

    // My Account Page - Click Event - Reactivate Membership button
    $cache.document.on("click", "#account-subscription .tag-reactivate-membership", function () {
        if (wa_data) {
            wa_data.pageName = "select your plan";
            wa_data.siteSection = Resources.UBISOFT_PLUS_TITLE + " : reactivation";

            const productId = $(this).data("subscription-product-id");

            if (productId) {
                wa_data.productID = productId;
            }
        }
    });

    // My Account - Click Event - Reactivate subscription
    $cache.document.on("click", ".reuse-payment-methods-container .js-reactivate-subscription", e => {
        tc_events_100(e.currentTarget, "clickevent", {
            ACTION: Resources.UBISOFT_PLUS_TITLE + " : reactivation - navigation - pay and start your subscription"
        });
    });
}

function upcAddressPaymentEvents() {
    // My Account - Click Event - Payment adding success
    $cache.document.on("click", "#tag-payment-success", function () {
        var paymentType = $("#tag-payment-type-account").data("payment-type") ? $("#tag-payment-type-account").data("payment-type") : "";

        tc_events_100(this, "clickevent", {
            ACTION: "my account - " + Resources.UBISOFT_PLUS_TITLE + " - add payment method - " + paymentType + " - success"
        });
    });

    // My Account - Click Event - Address editing/adding success
    $cache.document.on("click", "#tag-address-success", function () {
        var addressType = $("#tag-address-type").data("address-type");

        if (addressType == "edit") {
            tc_events_100(this, "clickevent", {
                ACTION: "my account - " + Resources.UBISOFT_PLUS_TITLE + " - edit address - success"
            });
        } else {
            tc_events_100(this, "clickevent", {
                ACTION: "my account - " + Resources.UBISOFT_PLUS_TITLE + " - add address - success"
            });
        }
    });

    // My Account - Click Event - Address editing/adding error
    $cache.document.on("click", "#tag-address-error", function () {
        var addressType = $("#tag-address-type").data("address-type");

        if (addressType == "edit") {
            tc_events_100(this, "clickevent", {
                ACTION: "my account - " + Resources.UBISOFT_PLUS_TITLE + " - edit address - error"
            });
        } else {
            tc_events_100(this, "clickevent", {
                ACTION: "my account - " + Resources.UBISOFT_PLUS_TITLE + " - add address - error"
            });
        }
    });

    // My Account - Click Event - Manage my subscription
    $cache.document.on("click", ".manage-my-subscription", e => {
        let subscriptionType = $(e.target).data("subscription-type");

        if (subscriptionType === "uplayplus") {
            subscriptionType = Resources.UBISOFT_PLUS_TITLE;
        }

        tc_events_100(this, "clickevent", {
            ACTION: "my subscription : " + subscriptionType + " - interaction - manage my subscription"
        });
    });
}

function upcGamesListSubscribeEvents() {
    // Gaming List page - for active users - click event - how to play button
    $cache.document.on("click", "#sub-tag-tutorial-button", function () {
        tc_events_100(this, "clickevent", {
            ACTION: "hero banner - slider 1 - CTA - how to play"
        });
    });

    // Gaming List page - for active users - click event - got it button (on how to play pop-pup)
    $cache.document.on("click", "#tag-upc-got-it-button", function () {
        tc_events_100(this, "clickevent", {
            ACTION: "hero banner - slider 1 - CTA - how to play - got it"
        });
    });

    // Gaming List page - for active users - click event - close button (on how to play pop-pup)
    $cache.document.on("click", ".ui-dialog-titlebar-close", function () {
        var isHowToPlay = $(".ui-dialog-titlebar-close").parent().closest(".htp-modal").length > 0 ? true : false;
        var isGotItButton = $(this).hasClass("htp-got-it-button") ? true : false;

        if (isHowToPlay && !isGotItButton) {
            tc_events_100(this, "clickevent", {
                ACTION: "hero banner - slider 1 - CTA - how to play - close pop-in"
            });
        }

        if (isGotItButton) {
            $(this).removeClass("htp-got-it-button");
        }
    });
}

function upcLandingScrollTracking() {
    // UPLay+ Scroll Tracking
    if (pageContext && pageContext.ns === "subscription" || isUPCUbiPassLandingPageURL) {
        var sections = {
            ".ubiP-hero": "hero banner", // Hero Banner section
            "#whatisit": "what is uplay", // what is uplay plus section
            "#worldGames": "uplay plus library", // uplay plus library section
            "#cards": "program benefits", // program benefits section
            "#fan": "bottom page banner", // bottom page banner section
            ".uplay-plus_hero": "hero banner", // Hero Banner section
            ".about_uplay-plus": "what is uplay", // what is uplay plus section
            ".advantages_uplay-plus": "uplay plus library", // uplay plus library section
            ".signup-guide_uplay-plus": "program benefits", // program benefits section
            ".uplay-plus_faq": "bottom page banner" // bottom page banner section

        };

        // Handle the ScrollTo Buttons
        $("[data-scrollto]").on("click", function () {
            var element = $(this).data("scrollto");

            if (sections[element]) {
                fromClick = element;
            }
        });

        // Handle the Scroll Event
        $(window).on("scroll", function () {
            if (!fromClick) {
                for (var key in sections) {
                    if (Object.prototype.hasOwnProperty.call(sections, key)) {
                        if ($(key).length > 0) {
                            checkScrollEvent($(key), sections[key]);
                        }
                    }
                }
            } else {
                checkScrollEvent($(fromClick), sections[fromClick]);
            }
        });
    }
}

function upcChooseYourPlan() {
    // Choose your plan pop-in - Click Event - Close button
    $cache.document.on("click", ".ui-button.ui-widget.ui-state-default.ui-corner-all.ui-button-icon-only.ui-dialog-titlebar-close", function () {
        var monthly = $("#dialog-container").find(".monthly");
        var yearly = $("#dialog-container").find(".yearly");

        if (monthly.length > 0 || yearly.length > 0) {
            tc_events_100(this, "clickevent", {
                ACTION: "choose your plan - cta - close popin"
            });
        }
    });

    // Choose your plan pop-in - Click Event - list of games - choose a list
    $cache.document.on("click", ".available-games-title", e => {
        const $this = $(e.currentTarget);

        // not handle programatic click event
        if (!("originalEvent" in e)) {
            return;
        }

        let platformName = $this.data("gameslist") || "";

        if (platformName === "amazon") {
            platformName = "luna";
        }

        tc_events_100($this, "clickevent", {
            ACTION: "list of games - interaction - " + platformName.toLowerCase()
        });
    });
}

function upcEarlyRegistrationEvents() {
    if (pageContext && (pageContext.ns == "earlyregistration" || pageContext.ns === "subscription" || pageContext.ns === "ubisoftpasssubscription") || isUPCUbiPassLandingPageURL) {
        // Early Registration/UPlay+ Landing Page - Click Event - Sign Up button
        $cache.document.on("click", ".early-registration-button, .open-ubisoft-pass-choice, .btn-havelock-blue", function () {
            if ($(this).parent().closest(".enhanced-slider-products").length > 0) {
                return;
            }

            var currentSection = $(this).parent().closest("section").attr("id");
            var hasSlick = $(this).parent().closest(".slick-slide");
            var sliderNumber = (hasSlick.length) ? hasSlick.data("slick-index") + 1 : 1;
            var customerStatus = $cache.document.find(".customer_status").length > 0 ? $cache.document.find(".customer_status").data("subs-status") : "";
            var actionValueHero = "";
            var actionValueFan = "";

            if (customerStatus == "Inactive") {
                actionValueHero = "hero banner - slider " + sliderNumber + " - get " + Resources.UBISOFT_PLUS_TITLE + " - renew your plan";
                actionValueFan = "bottom page banner - get " + Resources.UBISOFT_PLUS_TITLE + " - renew your plan";
            } else if (customerStatus == "Active") {
                actionValueHero = "hero banner - slider " + sliderNumber + " - " + Resources.UBISOFT_PLUS_TITLE + " – manage my subscription";
                actionValueFan = "bottom page banner - get " + Resources.UBISOFT_PLUS_TITLE + " - " + Resources.UBISOFT_PLUS_TITLE + " – manage my subscription";
            } else {
                actionValueHero = "hero banner - slider " + sliderNumber + " - subscribe - " + Resources.UBISOFT_PLUS_TITLE;
                actionValueFan = "bottom page banner - subscribe - " + Resources.UBISOFT_PLUS_TITLE;
            }

            if (currentSection == "hero" || (!currentSection && $(this).parent().closest("section").hasClass("ubiP-hero")) || (!currentSection && $(this).parent().closest("section").hasClass("uplay-plus_hero"))) {
                tc_events_100(this, "clickevent", {
                    ACTION: actionValueHero
                });
            }

            if (currentSection == "fan" || (!currentSection && $(this).parent().closest("section").hasClass("signup-guide_uplay-plus"))) {
                tc_events_100(this, "clickevent", {
                    ACTION: actionValueFan
                });
            }
        });

        // Early Registration/UPlay+ Landing Page - Click Event - Watch Video button
        $cache.document.on("click", ".jsubiP-hero-watchVideo", function () {
            var currentSection = $(this).parent().closest("section").attr("id");

            if (currentSection == "hero" || (!currentSection && $(this).parent().closest("section").hasClass("ubiP-hero"))) {
                var hasSlick = $(this).parent().closest(".slick-slide");
                var sliderNumber = (hasSlick.length) ? hasSlick.data("slick-index") + 1 : 1;

                tc_events_100(this, "clickevent", {
                    ACTION: "hero banner - slider " + sliderNumber + " - uplay plus - watch video"
                });
            }

            if (currentSection == "fan") {
                tc_events_100(this, "clickevent", {
                    ACTION: "bottom page banner - uplay plus - watch video"
                });
            }
        });

        // Early Registration/UPlay+ Landing Page - Click Event - "What is it" section's items.
        $cache.document.on("click", ".ubiP-whatisit-item, .about_uplay-plus_boxes_item", function () {
            var currentSection = $(this).parent().closest("section").attr("id");

            if (currentSection == "whatisit" || (!currentSection && $(this).parent().closest("section").hasClass("about_uplay-plus"))) {
                var currentItemText;

                if ($(this).find("h4").length > 0) {
                    currentItemText = $(this).find("h4").data("title").toLowerCase();
                } else if ($(this).find("h3").length > 0) {
                    if ($(this).find("h3").is("[data-title]")) {
                        currentItemText = $(this).find("h3").data("title").toLowerCase();
                    } else {
                        currentItemText = $(this).find("h3").text().toLowerCase();
                    }
                } else {
                    currentItemText = "";
                }

                tc_events_100(this, "clickevent", {
                    ACTION: "what is uplay plus - tab - " + currentItemText
                });
            }
        });

        // Early Registration/UPlay+ Landing Page - Click Event - FAQ Button.
        $cache.document.on("click", ".button-faq", function () {
            tc_events_100(this, "clickevent", {
                ACTION: "faq - navigation - read faq"
            });
        });

        // Early Registration/UPlay+ Landing Page - Click Event - Discover all games.
        $cache.document.on("click", ".jsubiP-worldgames-cta, .js-ubiP-gamelist, .uplay-plus_cta_wrapper .btn", function () {
            if ($(this).parent().closest("section").hasClass("uplay-plus_hero")) {
                return;
            }

            tc_events_100(this, "clickevent", {
                ACTION: "uplay plus library - go to listing page - uplay plus"
            });
        });
    }
}

function upcSubscriptionPassPLPFilters() {
    var count;

    $cache.document.on("mouseover", function (e) {
        if ($(e.target).hasClass("filter-tag-commander-event")) {
            count = 0;
            $(e.target).on("click", function () {
                count ++ ;
                addFilterMetric(this, count);
            });
        }
    });

    function addFilterMetric(el, counter) {
        if ($(el).hasClass("see-more-btn-upc") || $(el).hasClass("sub-tag-add-to-library") || $(el).hasClass("sub-tag-start-playing-now") || $(el).hasClass("sub-tag-get-ubisoft-pass")) {
            return;
        }

        var hasClass = $(el).hasClass("home-refinement-link");
        var dataEvent = $(el).data("event") == "selectedSort" ? true : false;
        var text = "listing page - ";
        var type = "";
        var state = "";
        var parent = $(el).parent();
        var parentSelected = $(parent).hasClass("selected");

        // Product listing page - filter applied/removed
        if (hasClass && !dataEvent) {
            type = "filter ";

            if (parentSelected) {
                state = "removed - ";
            } else {
                state = "applied - ";
            }
        }

        // Product listing page - sort result by
        if (!hasClass && dataEvent) {
            type = "sort result by ";
        }

        if (counter == 1) {
            tc_events_100(this, "clickevent", {
                ACTION: text + type + state + $(el).data("tc100").toLowerCase()
            });
        }
    }
}

function upcSubscriptionButtonEvents() {
    // CLP Order Confirmation Uplay+ Product Tile buttons
    if (pageContext && pageContext.type == "orderconfirmation") {
        $cache.document.on("click", ".see-more-btn-upc, .sub-tag-add-to-library, .sub-tag-start-playing-now", function () {
            var btnParent = $(this).closest(".grid-tile");
            var productInfo = btnParent.data("tc100") ? btnParent.data("tc100") : "";
            var moduleName;
            var moduleNumber;
            var slotName = "slot";
            var slotNumber;
            var btnType;
            var btnName;
            var productName;
            var actionValue = "";

            if ($(this).hasClass("see-more-btn-upc") || $(this).hasClass("sub-tag-add-to-library") || $(this).hasClass("sub-tag-start-playing-now")) {
                slotNumber = btnParent.length ? btnParent.index() + 1 : "";
            }

            if ($(this).hasClass("see-more-btn-upc")) {
                moduleName = "product listing";
                moduleNumber = "1";
                btnType = "go to product";
                btnName = "see more";
                productName = productInfo ? productInfo.productName.toLowerCase() : "";
            } else if ($(this).hasClass("sub-tag-add-to-library")) {
                moduleName = "product listing";
                moduleNumber = "1";
                btnType = Resources.UBISOFT_PLUS_TITLE;
                btnName = "add to library";
                productName = productInfo ? productInfo.productName.toLowerCase() : "";
            } else if ($(this).hasClass("sub-tag-start-playing-now")) {
                moduleName = "product listing";
                moduleNumber = "1";
                btnType = Resources.UBISOFT_PLUS_TITLE;
                btnName = "start playing now";
                productName = productInfo ? productInfo.productName.toLowerCase() : "";
            }

            actionValue = moduleName + " " + moduleNumber + " - " + slotName + " " + slotNumber + " - " + btnType + " - " + btnName + " - " + productName;
            tc_events_100(this, "clickevent", {
                ACTION: actionValue
            });
        });
    }

    // GAMING LIST PAGE - CLICK EVENTS - SUBSCRIBE & NO SUBSCRIBE BUTTONS
    if (pageContext && pageContext.ns == "ubisoftpasssubscription") {
        $cache.document.on("click", ".see-more-btn-upc, .sub-tag-add-to-library, .sub-tag-start-playing-now, .sub-tag-get-ubisoft-pass", function () {
            var customerStatus = $cache.document.find(".customer_status").length > 0 ? $cache.document.find(".customer_status").data("subs-status") : "";
            var btnParent = $(this).closest(".grid-tile"),
                slotNumber = btnParent.length ? btnParent.index() + 1 : "",
                productInfo = btnParent.data("tc100") ? btnParent.data("tc100") : "",
                moduleName,
                moduleNumber,
                slotName = "slot",
                btnType,
                btnName,
                productName,
                carouselNumber,
                isListingProducts,
                actionValue = "",
                divider;

            productName = productInfo ? productInfo.productName.toLowerCase() : "";
            carouselNumber = $(this).parent().closest(".enhanced-slider-products").data("slot-id") ? $(this).parent().closest(".enhanced-slider-products").data("slot-id") : "";
            isListingProducts = $(this).parent().closest("#discover-products").length > 0 ? true : false;

            // SEE MORE & ADD TO LIBRARY & START PLAYING NOW && GET UBISOFT PASS
            if ($(this).hasClass("see-more-btn-upc")) {
                btnType = "go to product";
                btnName = "see more";
            } else if ($(this).hasClass("sub-tag-add-to-library")) {
                btnType = Resources.UBISOFT_PLUS_TITLE;
                btnName = "add to library";
            } else if ($(this).hasClass("sub-tag-start-playing-now")) {
                btnType = Resources.UBISOFT_PLUS_TITLE;
                btnName = "start playing now";
            } else if ($(this).hasClass("sub-tag-get-ubisoft-pass")) {
                btnType = "get " + Resources.UBISOFT_PLUS_TITLE;

                if (customerStatus == "Inactive") {
                    btnName = "renew your plan";
                } else {
                    btnName = "choose your plan";
                }
            }

            // PRODUCT LISTING
            if (carouselNumber != "" && carouselNumber.indexOf("games-lodge-slot" > -1)) {
                moduleNumber = carouselNumber.split("-")[3];
                moduleName = "product listing";
                slotNumber = btnParent.length ? btnParent.index() + 1 : "";
                actionValue = moduleName + " " + moduleNumber + " - " + slotName + " " + slotNumber + " - " + btnType + " - " + btnName + " - " + productName;

                if ($(this).hasClass("sub-tag-get-ubisoft-pass")) {
                    actionValue = moduleName + " " + moduleNumber + " - " + slotName + " " + slotNumber + " - " + btnType + " - " + btnName;
                }
            } else if (isListingProducts) {
                // DISCOVER PRODUCTS - CLP - GAMING LIST PAGE
                moduleName = "listing page - get uplay plus";

                if ($(this).hasClass("sub-tag-get-ubisoft-pass")) {
                    actionValue = moduleName + " - " + btnName;
                } else if ($(this).hasClass("see-more-btn-upc")) {
                    actionValue = "listing page - " + btnType + " - " + btnName + " - " + productName;
                } else {
                    actionValue = moduleName + " - " + btnName + " - " + productName;
                }
            }

            var tcValue;

            // DOUBLE & TRIPLE PUSHES FOR UPC - GAMING LIST PAGE
            if ($(this).closest(".pushes").hasClass("double")) {
                tcValue = $(this).parent().closest(".product-pricing.side-section").length > 0 ? $(this).parent().closest(".product-pricing.side-section").data("tc100") : "";
                productName = tcValue && tcValue.productName ? tcValue.productName.toLowerCase() : "";
                moduleName = "double";
                slotNumber = $(".pushes.double").index($(this).closest(".pushes")) + 1;
                divider = 2;
                moduleNumber = Math.ceil(slotNumber / divider);

                if ($(this).hasClass("sub-tag-get-ubisoft-pass")) {
                    actionValue = moduleName + " " + moduleNumber + " - " + slotName + " " + slotNumber + " - " + btnType + " - " + btnName;
                } else {
                    actionValue = moduleName + " " + moduleNumber + " - " + slotName + " " + slotNumber + " - " + btnType + " - " + btnName + " - " + productName;
                }
            } else if ($(this).closest(".pushes").hasClass("triple")) {
                tcValue = $(this).parent().closest(".product-tile.card").length > 0 ? $(this).parent().closest(".product-tile.card").data("tc-100") : "";
                productName = tcValue && tcValue.name ? tcValue.name.toLowerCase() : "";
                moduleName = "triple";
                slotNumber = $(".pushes.triple").index($(this).closest(".pushes")) + 1;
                divider = 3;
                moduleNumber = Math.ceil(slotNumber / divider);

                if ($(this).hasClass("sub-tag-get-ubisoft-pass")) {
                    actionValue = moduleName + " " + moduleNumber + " - " + slotName + " " + slotNumber + " - " + btnType + " - " + btnName;
                } else {
                    actionValue = moduleName + " " + moduleNumber + " - " + slotName + " " + slotNumber + " - " + btnType + " - " + btnName + " - " + productName;
                }
            }

            tc_events_100(this, "clickevent", {
                ACTION: actionValue
            });
        });

        $cache.document.on("click", ".tag-plp-pass-choice", function () {
            tc_events_100(this, "clickevent", {
                ACTION: "listing page - get uplay plus - choose your plan"
            });
        });

        $cache.document.on("click", ".select-subscription-plan-button", function () {
            var $this = $(this);

            tc_events_100($this, "clickevent", {
                ACTION: "page - order - subscribe to uplay plus",
                PRODUCTID: $this.attr("data-subscriptionid"),
                PRODUCTNAME: $this.attr("data-productname")
            });
        });
    }
}

/*
* Add tracking event handlers to the Switch subscription plan flow
*/
function switchSubscriptionPlanEvents() {
    if (pageContext && (pageContext.ns === "planselection" || pageContext.ns === "planreview" || pageContext.ns === "subscriptionconfirmation")) {
        // Switch Subscription Plan - Click Event - Cancel button
        $cache.document.on("click", ".selection-cancel", e => {
            tc_events_100(e.currentTarget, "clickevent", {
                ACTION: Resources.UBISOFT_PLUS_TITLE + " : switch plan - navigation - cancel"
            });
        });

        // Switch Subscription Plan - Click Event - Review change plan button
        $cache.document.on("click", ".review-changes", e => {
            tc_events_100(e.currentTarget, "clickevent", {
                ACTION: Resources.UBISOFT_PLUS_TITLE + " : switch plan - navigation - review changes"
            });
        });

        // Switch Subscription Plan - Click Event - Confirm plan change button
        $cache.document.on("click", ".confirm-plan-change", e => {
            tc_events_100(e.currentTarget, "clickevent", {
                ACTION: Resources.UBISOFT_PLUS_TITLE + " : switch plan - navigation - confirm"
            });
        });

        // Switch Subscription Plan - Click Event - Confirmation back to account button
        $cache.document.on("click", ".plan-switch-confirmation a", e => {
            tc_events_100(e.currentTarget, "clickevent", {
                ACTION: Resources.UBISOFT_PLUS_TITLE + " : switch plan - navigation - back to my account"
            });
        });
    }
}

/*
* Add tracking event handlers to the Cancel Switch Request flow
*/
function cancelSwitchRequestEvents() {
    if (pageContext && (pageContext.ns === "switchcancellation" || pageContext.ns === "planreview")) {
        // Cancel Switch Request - Click Event - Cancel button
        $cache.document.on("click", ".cancel-switch-cancellation", e => {
            tc_events_100(e.currentTarget, "clickevent", {
                ACTION: Resources.UBISOFT_PLUS_TITLE + " : cancel switch - navigation - go back"
            });
        });

        // Cancel Switch Request - Click Event - Re-Activate
        $cache.document.on("click", ".confirm-switch-cancellation", e => {
            tc_events_100(e.currentTarget, "clickevent", {
                ACTION: Resources.UBISOFT_PLUS_TITLE + " : cancel switch - navigation - yes cancel"
            });
        });

        // Cancel Switch Request - Click Event - Back to My Account button
        $cache.document.on("click", ".switch-cancellation-section a", e => {
            tc_events_100(e.currentTarget, "clickevent", {
                ACTION: Resources.UBISOFT_PLUS_TITLE + " : cancel switch - navigation - back to my account"
            });
        });
    }
}

/**
 * Sanitize and send events to Adobe
 */
function sendEvent(eventType, actionValue, _this) {
    var action;

    if (eventType === "lightcartevent" || eventType === "pageevent" || eventType === "cartevent") {
        action = removeSpecialCharacters(actionValue);
    } else {
        action = {
            ACTION: removeSpecialCharacters(actionValue)
        };
    }

    $cache.document.trigger("updateTcEventBuffer", {
        this: _this,
        eventType: eventType,
        action: action
    });
}

/**
 * @function removeSpecialCharacters Removes special characters from data layer
 * @param {Object} dataLayer - wa_data object used in TagCommander.
 * @return {String} dataLayer - datalayer
 */
function removeSpecialCharacters(dataLayer) {
    if (SitePreferences.TC_SPECIAL_CHARACTERS_REGEX && SitePreferences.TC_SPECIAL_CHARACTERS_REGEX != "") {
        var specialCharactersRegex = new RegExp(SitePreferences.TC_SPECIAL_CHARACTERS_REGEX, "g");

        if (typeof dataLayer !== "undefined" && typeof dataLayer === "object" && dataLayer !== null) {
            var value;

            Object.keys(dataLayer).forEach(function (property) {
                if (typeof dataLayer[property] === "object") {
                    removeSpecialCharacters(dataLayer[property]);
                } else if (typeof dataLayer[property] === "string") {
                    value = unescape(dataLayer[property]);

                    if (isNaN(value)) {
                        dataLayer[property] = value.replace(specialCharactersRegex, "").trim().toLowerCase();
                    }
                }
            });
        } else if (typeof dataLayer !== "undefined" && typeof dataLayer === "string") {
            value = unescape(dataLayer);

            if (isNaN(value)) {
                dataLayer = value.replace(specialCharactersRegex, "").trim().toLowerCase();
            }
        }
    }

    return dataLayer;
}

var upcSubsTagCommanderEvents = {
    init: function () {
        initializeEvents();
    }
};

module.exports = upcSubsTagCommanderEvents;
