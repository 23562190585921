/**
 * Module provides API for user login and logout
 * @module uplayconnect
 *
 * Public API:
 * app.components.uplayconnect.init()
 * app.components.uplayconnect.login()
 * app.components.uplayconnect.logout()
 */
import { getCookie } from "../../../app_web/cartridge/js/tls";
import { cookie } from "../../../app_web/cartridge/js/cookie";

var $cache = $cache || {},
    data = "",
    loginUrl = "",
    loginIframeUrl = "",
    registerUrl = "",
    loginData = {
        appId: "",
        lang: "",
        nextUrl: ""
    },
    registerData = {
        genomeId: "",
        nextUrl: ""
    };

// get truncate dwsid cookie value
const getDwCookie = document.cookie.match(new RegExp("(^| )" + "dwsid_s" + "=([^;]+)"));
const dwCookie = getDwCookie ? getDwCookie[2] : null;

/**
 * Dispatch logs for datadogs, only if DD_LOGS SDK is enabled
 * @private
 */
function dispatch_logs() {
    if (SitePreferences.ENABLE_DD_LOGS && window.DD_LOGS !== undefined && window.DD_LOGS.logger !== undefined) {
        window.DD_LOGS.logger.info("sfcc-sdk-getTicket", {
            dwsid: dwCookie
        });
    }
}

/**
 * initialize variables with cache and URL (login and register)
 * @private
 */
function initializeCache() {
    $cache.document = $(document);
    $cache.loginBtn = $(".wish-list, .up-login-redirect");
    $cache.wishListAddConfirmation = $(".added-to-your-wishlist");
    $cache.cartButton = $(".cart-button");
    $cache.logOut = $cache.document.find(".account-logout a");
    $cache.loadingscreen = $(".initial-loading-screen");
    $cache.redirectUrl = $cache.loadingscreen.length ? $cache.loadingscreen.attr("data-redirecturl") : null;

    data = window.uplayData;

    loginData.appId = data["app-id"];
    loginData.lang = data.locale;
    loginData.genomeId = data["genome-id"];
    loginData.nextUrl = data["login-next-url"];
    registerData.genomeId = data["genome-id"];
    registerData.nextUrl = data["register-next-url"];

    loginUrl = data["login-api-url"] + "?" + $.param(loginData);
    loginIframeUrl = data["login-api-url"] + "?" + $.param($.extend({}, loginData, {
        genomeId: data["iframe-genome-id"]
    }));
    registerUrl = data["register-api-url"] + "?" + $.param($.extend(loginData, registerData));
}

/**
 * Initialize events for login, logout and register buttons.
 * Use data attribute for specify popup type
 * @example <a href="" data-windowtype="popup">Login</a>
 * @private
 */
function initializeEvents() {
    $cache.document.find(".up-login").on("click", function (e) {
        if (uplayData.authenticated === "false") {
            e.preventDefault();
            openLoginPopup($(this).data("windowtype"));
        }
    });

    $cache.document.find(".up-register").on("click", function (e) {
        e.preventDefault();
        registerPopup($(this).data("windowtype"));
    });

    $cache.logOut.on("click", function (e) {
        e.preventDefault();
        logout();
    });

    $cache.loginBtn.on("click", function (e) {
        if (uplayData.authenticated === "false") {
            var $target = $(e.target);

            e.preventDefault();
            openLoginPopup($cache.document.find(".up-login").data("windowtype"), $target.attr("href"));
        }
    });

    $cache.cartButton.on("click", function (e) {
        if (uplayData.authenticated === "false") {
            e.preventDefault();
            openLoginPopup($cache.document.find(".up-login").data("windowtype"), $cache.loginBtn.attr("href"));
        }
    });

    $(document).on("click", ".footer-item .salesforce-link", function (e) {
        if (uplayData.authenticated === "false") {
            e.preventDefault();
            openLoginPopup($cache.document.find(".up-login").data("windowtype"), $(this).attr("href"));
        }
    });

    if ($(document).find(".trigger-login").length) {
        var targetURL = $(".trigger-login").attr("href");

        $("#main").html("").addClass("login-iframe");
        openLoginPopup("iframe", targetURL);
    }
}

/**
 * Initialize UPlay API
 */
function initializeAPI() {
    var minutes = parseInt(uplayData["ticket-validation-interval"], 10);

    if (uplayData["authenticated"] === "true") {
        var evt = document.createEvent("CustomEvent");

        Connect.sdk.subscribe(function (sdk) {
            dispatch_logs();

            sdk.getTicket().subscribe(function (getTicketResponse) {
                var tokenExpirationDate;
                var secondsUntilTokenExpiration;

                try {
                    if (getTicketResponse.status === "ok") {
                        tokenExpirationDate = new Date(getTicketResponse.payload.expiration);
                        secondsUntilTokenExpiration = Math.floor(Math.abs(tokenExpirationDate - new Date()) / 1000);
                        document.cookie = "TokenExpirationDate=" + tokenExpirationDate + "; max-age=" + secondsUntilTokenExpiration + "; path=/";

                        // set connect data service into localStorage
                        localStorage.setItem("connectData", JSON.stringify(getTicketResponse.payload));

                        // Dispatch event
                        evt.initCustomEvent("CONNECT_SDK_LOADED", false, false, getTicketResponse.payload);
                    } else {
                        evt.initCustomEvent("CONNECT_SDK_LOADED", false, false, {
                            status: getTicketResponse.status,
                            errorMessage: "no login data available"
                        });
                    }
                } catch (err) {
                    if (getTicketResponse.status === "ok") {
                        tokenExpirationDate = new Date(getTicketResponse.payload.expiration);
                        secondsUntilTokenExpiration = Math.floor(Math.abs(tokenExpirationDate - new Date()) / 1000);
                        document.cookie = "TokenExpirationDate=" + tokenExpirationDate + "; max-age=" + secondsUntilTokenExpiration + "; path=/";

                        // set connect data service into localStorage
                        localStorage.setItem("connectData", JSON.stringify(getTicketResponse.payload));

                        // Dispatch event
                        evt.initCustomEvent("CONNECT_SDK_LOADED", false, false, getTicketResponse.payload);
                    } else {
                        evt.initCustomEvent("CONNECT_SDK_LOADED", false, false, {
                            status: getTicketResponse.status,
                            errorMessage: "no login data available"
                        });
                    }
                }

                document.dispatchEvent(evt);
            });
        });

        setInterval(function () {
            var tokenExpirationDate = new Date(getCookie("TokenExpirationDate"));
            var now = new Date();

            // Substract from expiration time double the time of refresh interval (this way we make sure that we will do token refresh before it get expired)
            tokenExpirationDate.setMinutes(tokenExpirationDate.getMinutes() - (minutes * 2));

            if (tokenExpirationDate <= now) {
                Connect.init({
                    env: Resources.UPC_ENV,
                    appId: uplayData["app-id"],
                    genomeId: uplayData["genome-id"],
                    lang: uplayData["locale"],
                    nextUrl: uplayData["login-next-url"],
                    thirdPartyCookiesSupport: false
                });
            }
        }, minutes * 60 * 1000);
    } else {
        postLogin(loginData.appId, loginData.lang);
    }
}

/**
 * This function provide functionality for open popup or overlay
 * @param {string} windowType - window type can be 'popup' or 'overlay'
 * @param {string} url - URL to API (login or create account)
 * @todo implement functionality for overlay window type
 * @private
 */
function openForm(windowType, url) {
    if (windowType === "popup") {
        var popupWidth = 480,
            popupHeight = 800,
            left = Number((screen.width / 2) - (popupWidth / 2)),
            tops = Number((screen.height / 2) - (popupHeight / 2));

        window.open(url, "uplayconnect", "width=" + popupWidth + ", height=" + popupHeight + ", toolbar=no, status=no, top=" + tops + ", left=" + left);
    } else {
        $("<iframe/>", {
            src: url
        }).appendTo("#main");
        // open overlay with IFrame
    }
}

/**
 * Open popup for login, DO NOT USE DIRECTLY!!! it can cause bugs with redirects, use openLoginPopup interface function
 * @param {string} type - 'popup' or 'overlay'
 * @private
 */
function loginPopup(type) {
    if (type === "iframe") {
        openForm(type, loginIframeUrl);
    } else {
        openForm(type, loginUrl);
    }
}

/**
* Interface function for loginPopup
* @param {string} popupType - 'popup' or 'overlay'
* @param {string} afterLoginRedirectUrl - redirect url where user gets after login is done
*/

function openLoginPopup(popupType, afterLoginRedirectUrl) {
    if (afterLoginRedirectUrl) {
        window.redirect = afterLoginRedirectUrl;
    } else {
        window.redirect = false;
    }

    loginPopup(popupType);
}

/**
 * Open popup for register
 * @param {string} type - 'popup' or 'overlay'
 * @private
 */
function registerPopup(type) {
    openForm(type, registerUrl);
}

/**
 * getTicket API call, return response
 * @param {string} appId - application id
 * @param {string} locale - locale, example: en-US
 * @param {function} callback - function
 * @private
 */
function getTicket(sdk, callback) {
    dispatch_logs();

    sdk.getTicket().subscribe(function (getTicketResponse) {
        callback(getTicketResponse);
    });
}

/**
 * This function is implementation of UPlayConnect post login and should be called after login or register
 * @param {string} appId - application id
 * @param {string} locale - locale, example: en-US
 * @private
 */
function postLogin() {
    if (Resources && Resources.ENABLE_CI_REDIRECT && window.location.protocol == "http:") {
        location.href = location.href.replace(/^http:\/\//i, "https://");
    } else {
        Connect.sdk.subscribe(function (sdk) {
            getTicket(sdk, function (getTicketResponse) {
                if (getTicketResponse.status === "ok") {
                    var ticketData = getTicketResponse.payload;

                    jQuery.ajax({
                        type: "POST",
                        url: data["login-url"],
                        data: ticketData
                    }).done(function (result) {
                        result = JSON.parse(result);

                        if (result && result["status"] == "success") {
                            if ($cache.redirectUrl) {
                                if (location.hash !== "") {
                                    location = $cache.redirectUrl + location.hash;
                                } else {
                                    location = $cache.redirectUrl;
                                }
                            } else if (location.hash !== "") {
                                location.reload();
                            } else {
                                location = window.redirect || location;
                            }
                        }
                    });
                }
            });
        });
    }
}

/**
 * Logout user in UPlay and DW
 * @param {string} ticket - UPLay user ticket
 * @private
 */
function logout() {
    uplayconnect.init();

    if (SitePreferences.OWNED_PRODUCTS_FLOW) {
        clearOwnershipListSession();
    }

    Connect.sdk.subscribe(function (sdk) {
        getTicket(sdk, function (getTicketResponse) {
            var ticketResponse = getTicketResponse.payload;

            sdk.logout(ticketResponse.ticket, ticketResponse.sessionId).subscribe(function (logoutResponse) {
                if (logoutResponse.status == "ok") {
                    cookie.delete("TokenExpirationDate");
                    location = data["logout-url"];
                }
            });
        });
    });
}

/**
 * Clear customOwnershipList from session.custom at logout
 *
 */
function clearOwnershipListSession() {
    if (sessionStorage.getItem("isPending") != null) {
        sessionStorage.removeItem("isPending");
    }
}

var uplayconnect = {
    init: function () {
        var uplayConnectAPISettings = $.getJSON(Urls.uplayConnectAPISettings);

        uplayConnectAPISettings.then(function (data) {
            if (data) {
                Connect.init({
                    env: Resources.UPC_ENV,
                    appId: data["app-id"],
                    genomeId: data["genome-id"],
                    lang: data.locale,
                    nextUrl: data["login-next-url"],
                    thirdPartyCookiesSupport: false
                });

                window.uplayData = window.uplayData || {};
                $.extend(window.uplayData, data);
            }

            initializeCache();
            setTimeout(initializeAPI, 0);
            initializeEvents();
            document.dispatchEvent(new CustomEvent("uplayDataUpdated"));
        });

        app.components.uplayconnect = uplayconnect;
    },
    login: postLogin,
    logout: logout
};

export { uplayconnect };
