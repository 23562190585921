import "slick-carousel";
import debounce from "lodash/debounce";
import { symbols } from "../../symbols";
import { initProductTooltip } from "../../tooltip";
import { initializeProductSliderFocus } from "../../focus/productSliderFocus";

var _ = {
    debounce
};
var $cache = {};

var initCache = function () {
    $cache = {
        enhancedSliderProducts: $(".enhanced-slider-products")
    };
};

/**
* @function
* @description function to call for the Product Slider settings
* @param {object} $selector - object - jquery selector of the slider to get params from
* @return {Object} slickSettingsProductsSlider - object with slider settings
*/
function returnProductSliderSettings($selector) {
    const isWishlistPage = $(".wishlist-wrapper").length ? true : false;

    var slickSettingsProductsSlider = {
        rows: 0, // prevents the injection of 2 parent divs for each slide
        dots: false,
        infinite: false,
        speed: 400,
        slidesToShow: 5,
        slidesToScroll: 5,
        autoplay: false,
        arrows: true,
        autoplaySpeed: ($selector && $selector.data("autoplay-time")) ? $selector.data("autoplay-time") : 5000,
        prevArrow: symbols.transparentPrevArrow(),
        nextArrow: symbols.transparentNextArrow(),
        cssEase: "ease",
        responsive: [
            {
                breakpoint: 1920,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 5
                }
            },
            {
                breakpoint: 1469,
                settings: {
                    slidesToShow: isWishlistPage ? 5 : 4,
                    slidesToScroll: isWishlistPage ? 5 : 4
                }
            },
            {
                breakpoint: 959,
                settings: {
                    slidesToShow: isWishlistPage ? 5 : 3,
                    slidesToScroll: isWishlistPage ? 5 : 3
                }
            }
        ]
    };

    return slickSettingsProductsSlider;
}

/**
* @function
* @description function to call for the Product Slider settings
*/
function initProductSlider() {
    $cache.enhancedSliderProducts.each(function (index, element) {
        if ($(element) != null) return $(element).not(".slick-initialized").slick(returnProductSliderSettings($(element)));
    });

    $(window).on("resize", _.debounce(function () {
        initProductTooltip();
    }, 300));
}

function initializeProductSlider() {
    initCache();
    initProductSlider();
    initProductTooltip();
    initializeProductSliderFocus();
}

export { initializeProductSlider };
