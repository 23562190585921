import utils from "../helpers/utils";

/**
 * @function
 * @description Bind click on every existing button on pageload
 */
const initButtons = () => {
    // do not add event listeners on elements with "data-exclude" attribute
    const buttons = document.querySelectorAll("[data-component='button']:not([data-exclude])");
    const links = document.querySelectorAll("[data-component='link']:not([data-exclude])");

    Array.prototype.forEach.call(buttons, button => {
        button.addEventListener("click", e => {
            e.preventDefault();
            e.stopImmediatePropagation();

            if (button.dataset.ajaxurl) {
                buttonAjaxCall(e, button);
            } else {
                spreadButtonEvent(button);
            }
        }, false);
    });

    Array.prototype.forEach.call(links, link => {
        link.addEventListener("click", () => spreadLinkEvent(link));
    });
};

/**
 * @function
 * @description Dispatch Event Name:Action, with payload (params as JSON)
 * @param {string} button - Button element that has been triggered
 */
const spreadButtonEvent = button => {
    const buttonEventName = "Button:" + button.dataset?.name;
    const params = button.dataset?.params;
    const attributes = params ? JSON.parse(params) : {};

    const event = new CustomEvent(buttonEventName, {
        detail: {
            button,
            attributes
        }
    });

    document.dispatchEvent(event);
};

/**
 * @function
 * @description Dispatch Event Name:Action, with payload (params as JSON)
 * @param {string} link - Link element that has been triggered
 */
const spreadLinkEvent = link => {
    const linkEventName = "Link:" + link.dataset?.name;
    const params = link.dataset?.params;
    const attributes = params ? JSON.parse(params) : {};

    const event = new CustomEvent(linkEventName, {
        detail: attributes
    });

    document.dispatchEvent(event);
};

/**
 * @function
 * @description Dispatch Event Name:Action, with payload (eg: Cart:RemoveItem)
 * @param {string} button - Button element that has been triggered
 * @param {string} response - Ajax response - will be passed has payload throught event
 * @param {boolean} includebutton - If true, includes the button element as part of the payload
 */
const spreadEventAjax = async (button, response, includebutton) => {
    const buttonEventName = "Button:" + button.dataset.name;
    let event;

    if (includebutton) {
        event = new CustomEvent(buttonEventName, {
            detail: {
                button,
                response
            }
        });
    } else {
        event = new CustomEvent(buttonEventName, {
            detail: response
        });
    }

    document.dispatchEvent(event);
};

/**
 * @function
 * @description Build Ajax request, contain infos to build request
 * @param {Event} e - Triggered event
 * @param {string} button - Button element
 */
const buttonAjaxCall = async (e, button) => {
    e.preventDefault();

    // Get query parameters
    var url = utils.ajaxUrl(button.dataset.ajaxurl);
    const params = button.dataset?.params ? JSON.parse(button.dataset.params) : null;
    const method = button.dataset.method;
    const includebutton = button.dataset.includebutton || false;
    var options = {
        method: method,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded"
        }
    };

    if (params) {
        if (method === "GET") {
            url = utils.appendParamsToUrl(url, params);
        } else {
            options.body = new URLSearchParams(params);
        }
    }

    // Fetch response
    let response;
    const getResponse = await fetch(url, options);
    const contentType = getResponse.headers.get("content-type");

    // Check type
    if (contentType && contentType.indexOf("application/json") !== -1) {
        response = await getResponse.json();
    } else if (contentType && contentType.indexOf("text/html") !== -1) {
        response = await getResponse.text();
    }

    // spread it with response
    await spreadEventAjax(button, response, includebutton);
};

// Specific listener for Notification Component
document.addEventListener("Button:DismissNotification", e => {
    const dismissButton = e.detail.button;
    const notificationElt = dismissButton.closest("[data-component='notification']");

    notificationElt.remove();
});

export default initButtons;
