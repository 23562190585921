import debounce from "lodash/debounce";
import { symbols } from "./symbols";

const _ = {
    debounce
};

let $cache = {};

/**
 * @function
 * @description initialize cache object
 */
const initCache = () => {
    $cache = {
        window: $(window),
        menuWrapper: $("[data-priority-navigation]"),
        navBarAction: $("[data-nav-bar-action]"),
        moreItem: []
    };
};

/**
 * @function
 * @description Change the structure of the list: show as many list items as will fit horizontally and a dropdown list to show the rest
 */
const updateMenuStructure = () => {
    if (!$cache.menuWrapper.length || !$cache.menuWrapper.is(":visible")) {
        return;
    }

    // fix for the small screens to avoid exceeding maximum call stack
    if ($cache.window.width() < 768) {
        return;
    }

    $cache.menuWrapper.each((index, elem) => {
        const $elem = $(elem);
        const $menuList = $elem.find("> ul");

        if (!$menuList.length) {
            return;
        }

        const $navItems = $menuList.find("> li:not(.more)");

        let navWidth = 0;

        $navItems.each((i, el) => {
            navWidth += $(el).outerWidth(true);
        });

        $elem.addClass("redrawing");

        let spareSpace = 0;

        if ($elem.hasClass("menu-wrapper")) {
            spareSpace = $cache.window.outerWidth() - ($(".header-search").outerWidth() + $(".header-links").outerWidth());

            $(".navigation").css("width", spareSpace);
        } else {
            spareSpace = $elem.outerWidth(true);
        }

        // condition to not display "more" button when there is enough space for all items
        if (spareSpace >= navWidth) {
            // removing "more" button if it exists and no items to display as dpopdown list
            if ($cache.moreItem[index] && !$cache.moreItem[index].find("li").length) {
                $cache.moreItem[index].remove();
                $cache.moreItem[index] = "";

                $elem.removeClass("redrawing");

                return;
            }
        } else if (!$cache.moreItem[index]) {
            // add a 'more' dropdown item where to move list items when space runs out
            $cache.moreItem[index] = addDrodownList($menuList);
        }

        if (!$cache.moreItem[index]?.length) {
            return;
        }

        const $moreItemList = $cache.moreItem[index].find("ul");
        const moreElemWidth = $cache.moreItem[index].outerWidth(true);
        const availableSpace = $menuList.outerWidth(true) - moreElemWidth;

        if (navWidth > availableSpace) {
            const $lastItem = $navItems.last();

            // there's not enough space for all the items. Move the last one to the dropdown list
            moveToDropdownList($moreItemList, $lastItem);

            updateMenuStructure();
        } else {
            const $firstMoreElement = $moreItemList.find("li").first();
            let requiredSpace = $firstMoreElement.data("width");

            if ($navItems.length) {
                // when changing the breakpoint from mobile to tablet, the width of the items is changed
                requiredSpace = Math.max(requiredSpace, $cache.moreItem[index].prev().outerWidth(true));
            }

            if (navWidth + requiredSpace < availableSpace) {
                // there's room for more items. Move the first item from the dropdown list to the main one
                $firstMoreElement.insertBefore($cache.moreItem[index]);

                updateMenuStructure();
            }
        }
    });
};

/**
 * @function
 * @description Move list item to the dropdown list
 * @param {Object} $dropdownList - jQuery element of the dropdown list where the $listItem will be moved
 * @param {Object} $listItem - jQuery element of the list item to be moved
 */
const moveToDropdownList = ($dropdownList, $listItem) => {
    $listItem.attr("data-width", $listItem.outerWidth(true));

    // move the last list item under more
    $listItem.prependTo($dropdownList);
};

/**
 * @function
 * @description Initialize the events for the menu
 */
const initEvents = () => {
    // recalculate the available size for the menu and update the list structure
    $cache.window.on("resize stickyNavigationChange", _.debounce((scope, $updateCtaOpacity) => {
        updateMenuStructure();
        $cache.menuWrapper.removeClass("redrawing");
        $updateCtaOpacity ? $cache.navBarAction.css("opacity", 1) : $cache.navBarAction.css("opacity", 0);
    }, 500));

    // close the expandable list item when an anchor link is clicked
    $cache.menuWrapper.on("click", "[data-scope=disclosure-component] [data-scroll-to]", e => {
        const $parentDisclosureSection = $(e.currentTarget).closest("[data-scope=disclosure-component]");
        const $disclosureBtn = $parentDisclosureSection.find("[data-cta=disclosure-component]");

        if (!$disclosureBtn.length) {
            return;
        }

        $disclosureBtn.trigger("click");
    });
};

/**
 * @function
 * @description Append a dropdown list to the menu
 * @param {Object} $menuList - jQuery element of the list where the dropdown item is added
 * @return {Object} $dropdownList - jQuery element of the dropdown list
 */
const addDrodownList = $menuList => {
    const moreButtonSymbol = $menuList.hasClass("c-pdp-navigation__links") ? symbols.arrowUp() : symbols.moreIcon();
    const listId = "collapsible-menu-" + Math.floor((Math.random() * 100) + 1);

    const dropdownList = `<li class="more" data-scope="disclosure-component">
        <button class="menu__toggle-btn" data-cta="disclosure-component" aria-controls="${listId}">
            <span>${Resources.PLUS}</span>
            <span class="svg-wrapper more-icon-wrapper">${moreButtonSymbol}</span>
        </button>

        <ul id="${listId}" class="submenu"></ul>
    </li>`;

    $menuList.append(dropdownList);

    return $menuList.find(".more");
};

/**
 * @function
 * @description Initialize priority navigation - horizontal menu which shows as many links as space allows, hiding the rest under a dynamically created (and updated) "more" link.
 */
const initializePriorityNavigation = () => {
    initCache();

    if (!$cache.menuWrapper.length || !$cache.menuWrapper.is(":visible")) {
        return;
    }

    updateMenuStructure();
    $cache.menuWrapper.removeClass("redrawing");

    initEvents();
};

export { initializePriorityNavigation };
