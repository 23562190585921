import { debounce, cloneDeep } from "lodash";
import initButtons from "../components/button";
import initSelect from "../components/select";

const _ = {
    debounce,
    cloneDeep
};

/**
 * @function
 * @description contains all actions related to mobile layer for PLI update
 */
const mobileActions = {
    /**
     * @function
     * @description Display mobile block and overlay
     *              Block will be filled with createContent()
     * @param {Event} Event - Contain event to retrieve target
     */
    displayMobileBlock: e => {
        const actionMobileWrapper = document.querySelector('[data-component="actionsMobile"]');
        const pliComponent = e.currentTarget.closest("[data-component='productLineItem']");
        const overlay = document.querySelector("[data-ui='overlay']");

        // Display mobile block and overlay
        document.dispatchEvent(new Event("Overlay:Open"));
        actionMobileWrapper.classList.add("l-actionmobile--open");

        // Get actions Elements
        mobileActions.createContent(pliComponent);

        // Bind click for overlay close
        overlay.addEventListener("click", () => {
            document.dispatchEvent(new Event("Overlay:Close"));
            mobileActions.hideMobileBlock();
        });
    },

    /**
     * @function
     * @description Remove display class and empty content of mobile block
     */
    hideMobileBlock: () => {
        const actionMobileWrapper = document.querySelector('[data-component="actionsMobile"]');

        if (actionMobileWrapper?.classList.contains("l-actionmobile--open")) {
            document.dispatchEvent(new Event("Overlay:Close"));
            actionMobileWrapper.classList.remove("l-actionmobile--open");
            actionMobileWrapper.innerHTML = "";
        }
    },

    /**
     * @function
     * @description Create HTML structure of mobile block (fake button hover select)
     *              Purpose is to keep UX look and feel, with native select behavior
     * @param {HTMLElement} pliComponent - Current product to recreate update action
     */
    createContent: pliComponent => {
        // Get actions block
        const actionMobileWrapper = document.querySelector('[data-component="actionsMobile"]');
        const updateActions = pliComponent.querySelectorAll('[data-component*="action"]');

        // Create HTML hierarchie
        let list = document.createElement("ul");

        list.classList.add("c-actionmobile__list");
        actionMobileWrapper.appendChild(list);

        // Construct list of actions in mobile block
        for (var i = 0; i < updateActions.length; i++) {
            // Get Element to recreate select
            const select = updateActions[i].querySelector("[data-component='select']");
            const buttonValue = updateActions[i].querySelectorAll("span");

            if (select !== null) {
                // Clone select, and reinstance it
                const cloneSelect = select.cloneNode(true);

                initSelect(cloneSelect);
                cloneSelect.classList.remove("ts-hidden-accessible");

                // Create HTML hierarchie
                let item = document.createElement("li");
                let createFakeButton = document.createElement("button");

                // Set classes
                item.classList.add("c-actionmobile__item");
                createFakeButton.classList.add("c-actionmobile__button");

                // Append item, within Button + Select
                list.appendChild(item);
                item.appendChild(createFakeButton);
                item.appendChild(cloneSelect);

                // Fill button with right label
                for (var j = 0; j < buttonValue.length; j++) {
                    const cloneValue = _.cloneDeep(buttonValue[j].innerHTML);

                    createFakeButton.innerHTML += cloneValue;
                }
            }
        }

        // Get remove button
        const removeButton = pliComponent.querySelector('[data-component="pliActions"]');

        if (removeButton) {
            // Clone it
            const cloneButton = removeButton.cloneNode(true);

            // Append cloned remove button
            let itemButton = document.createElement("li");

            list.classList.add("c-actionmobile__list");
            list.appendChild(itemButton);
            itemButton.append(cloneButton);
        }

        initButtons();
    },

    /**
     * @function
     * @description Creates mobile summary content and appends it to the header
     */
    createMobileSummaryContent: () => {
        const matchDesktop = window.matchMedia("(min-width: 1025px)").matches;
        const summaryComponent = document.querySelector("[page-component='summary']");

        if (matchDesktop || !summaryComponent) {
            return;
        }

        const totalBlock = document.querySelector("[total-block-component]");
        const cartTotalLabel = totalBlock ? totalBlock.getAttribute("carttotallabel") : "";
        const cartTotalPrice = totalBlock ? totalBlock.getAttribute("carttotalprice") : "";
        const cartTaxesLabel = totalBlock ? totalBlock.getAttribute("carttotaltaxes") : "";
        const cartItemsNumber = totalBlock ? totalBlock.getAttribute("cartitemcounter") : "";

        // Create HTML hierarchy for the sticky summary
        const mobileSummaryElement = document.createElement("div");
        const mobileSummaryHtml = `
            <div class="c-mobilesummary__content">
                <div class="c-mobilesummary__title">${cartTotalLabel ? cartTotalLabel : ""}</div>
                <div class="c-mobilesummary__price">
                    <span mobile-summary-field="carttotalprice">${cartTotalPrice ? cartTotalPrice : ""}</span>
                    <span class="c-mobilesummary__taxes" mobile-summary-field="carttotaltaxes">${cartTaxesLabel ? cartTaxesLabel : ""}</span>
                </div>
                <div class="c-mobilesummary__items-number" mobile-summary-field="cartitemcounter">
                    ${cartItemsNumber ? cartItemsNumber : ""}
                </div>
            </div>
        `;

        mobileSummaryElement.classList.add("c-mobilesummary");
        mobileSummaryElement.setAttribute("mobile-summary-component", "");
        mobileSummaryElement.innerHTML = mobileSummaryHtml;

        const checkoutButton = summaryComponent ? summaryComponent.querySelector(".c-button--checkout") : null;

        // Clone checkout button
        if (checkoutButton) {
            const cloneButton = checkoutButton.cloneNode(true);

            mobileSummaryElement.appendChild(cloneButton);
        }

        // append section to the header
        const headerMain = document.querySelector(".header-main");

        if (headerMain) {
            headerMain.appendChild(mobileSummaryElement);
            initButtons();
        }
    },

    /**
     * @function
     * @description Hides sticky summary section
     */
    hideMobileSummary: () => {
        const mobileSummary = document.querySelector("[mobile-summary-component]");

        if (mobileSummary) {
            mobileSummary.parentNode.removeChild(mobileSummary);
        }
    },

    /**
     * @function
     * @description Updates mobile summary content
     */
    updateMobileSummary: () => {
        const mobileSummary = document.querySelector("[mobile-summary-component]");
        const summaryComponent = document.querySelector("[page-component='summary']");

        if (!mobileSummary || !summaryComponent) {
            return;
        }

        const mobileSummaryFields = mobileSummary.querySelectorAll("[mobile-summary-field]");

        // update mobile summary fields with new values
        mobileSummaryFields.forEach(field => {
            const dataAttribute = field.getAttribute("mobile-summary-field");
            const updatedField = document.querySelector(`[${dataAttribute}]`);

            if (updatedField) {
                const updatedValue = updatedField.getAttribute(dataAttribute);

                field.innerHTML = updatedValue ? updatedValue : "";
            }
        });

        const checkoutButtonUpdated = summaryComponent.querySelector(".check-ubisoft-login");
        const checkoutButton = mobileSummary.querySelector(".check-ubisoft-login");

        // updating checkout button if it was changed
        if (checkoutButton && checkoutButtonUpdated && !(checkoutButton === checkoutButtonUpdated)) {
            checkoutButton.parentNode.removeChild(checkoutButton);

            const cloneButton = checkoutButtonUpdated.cloneNode(true);

            mobileSummary.appendChild(cloneButton);
        }
    }
};

/**
 * @function
 * @description Resize event listener
 *              Empty and hide mobileBlack if resize reach desktop
 */
window.addEventListener("resize", _.debounce(() => {
    const matchDesktop = window.matchMedia("(min-width: 1025px)").matches;

    // Hide block & Empty mobile block
    if (matchDesktop) {
        mobileActions.hideMobileBlock();
        mobileActions.hideMobileSummary();
    }
}, 500));

export default mobileActions;
