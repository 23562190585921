const $document = $(document);
// stores temporary subOffer, modalLocation
const $cache = {};

/**
 * @function
 * @description returns dynamic (based on event type) data for account subscription banners to be tracked by adobeAnalytics
 * @returns {Object} Object.keys(getBannersData) will represent banner types ([data-bannertype])
 */
const getBannersData = () => {
    return {
        "pending-cancellation-banner": {
            impression: {
                locationDetail: " : cancel subscription confirmation"
            },
            click: {
                locationDetail: " : cancel subscription confirmation",
                action: "reactivate my subscription"
            }
        },
        "upgrade-banner": {
            impression: {
                locationDetail: " : switch plan : upgrade confirmation"
            },
            click: {
                locationDetail: " : switch plan : upgrade confirmation",
                action: "cancel change request"
            }
        },
        "cancel-downgrade-banner": {
            impression: {
                locationDetail: " : switch plan : downgrade confirmation"
            },
            click: {
                locationDetail: " : switch plan : downgrade confirmation",
                action: "cancel change request"
            }
        },
        cancelledbypaymentfailure: {
            impression: {
                locationDetail: " : error : subscription cancelled"
            },
            click: {
                locationDetail: " : error : subscription cancelled",
                action: "reactivate",
                closeAction: "close"
            }
        },
        firstpaymenttryfailed: {
            impression: {
                locationDetail: " : error : payment failure"
            },
            click: {
                locationDetail: " : error : payment failure",
                action: "manage payment"
            }
        },
        paymentmethodexpired: {
            impression: {
                locationDetail: " : error : payment expired"
            },
            click: {
                locationDetail: " : error : payment expired",
                action: "manage payment"
            }
        },
        paymentmethodexpiressoon: {
            impression: {
                locationDetail: " : error : payment expires soon"
            },
            click: {
                locationDetail: " : error : payment expires soon",
                action: "manage payment",
                closeAction: "close"
            }
        },
        "ft-autorenewal-banner": {
            impression: {
                locationDetail: " : free offer"
            }
        },
        "ft-no-autorenewal-banner": {
            impression: {
                locationDetail: " : free offer | no renewal"
            }
        }
    };
};

/**
 *
 * @param {location, action, category, locationDetail} - optional parameters, that may be included to event params Object
 * @returns {Object} - Object containing !empty variables to be tracked on my-account modals' events
 */
const modalTrack = ({location, action, category, locationDetail} = "") => {
    return ({
        click: {
            category: "interaction",
            locationPosition: "pop in"
        },
        impression: {
            locationPosition: "pop in",
            category: category || "display"
        },
        get clickParams() {
            return ({
                ...this.click,
                ...action && {
                    action: action
                },
                ...location && {
                    location: location
                },
                ...locationDetail && {
                    locationDetail: locationDetail
                }
            });
        },
        get impressionParams() {
            return ({
                ...this.impression,
                ...action && {
                    action: action
                },
                ...location && {
                    location: location
                },
                ...locationDetail && {
                    locationDetail: locationDetail
                }
            });
        }
    });
};

// English version of field names (keys represent field name split with form prefix, ex: dwfrm_profile_address_firstName.toLowerCase() -> firstName)
const FIELD_NAMES = {
    firstname: Resources.TR_FIELD_NAME_FIRST_NAME,
    lastname: Resources.TR_FIELD_NAME_LAST_NAME,
    address1: Resources.TR_FIELD_NAME_ADDRESS1,
    address2: Resources.TR_FIELD_NAME_ADDRESS2,
    postal: Resources.TR_FIELD_NAME_ZIPCODE,
    city: Resources.TR_FIELD_NAME_CITY,
    owner: Resources.TR_FIELD_NAME_CC_OWNER,
    cvncard: Resources.TR_FIELD_NAME_CVN,
    expiration_month: Resources.TR_FIELD_NAME_MONTH,
    expiration_year: Resources.TR_FIELD_NAME_YEAR,
    number: Resources.TR_FIELD_NAME_CC_NUMBER,
    country: Resources.TR_FIELD_NAME_COUNTRY,
    states_state: Resources.TR_FIELD_NAME_STATE,
    phone: Resources.TR_FIELD_NAME_PHONE
};

// English version of errors' description (keys represent validator methods)
const FORM_ERRORS = {
    required: Resources.TR_VALIDATE_REQUIRED,
    firstname: Resources.TR_VALIDATE_NAME,
    lastname: Resources.TR_VALIDATE_NAME,
    postal: Resources.TR_VALIDATE_FORMAT,
    states_list: Resources.TR_VALIDATE_REQUIRED,
    "js-address-validation": Resources.TR_VALIDATE_SPECIAL_CHARS,
    "cc-number": Resources.TR_VALIDATE_CC_NUMBER,
    "cc-owner": Resources.TR_VALIDATE_NAME,
    "cvn-input": Resources.TR_VALIDATE_CVN,
    "expiration-date-input": Resources.TR_VALIDATE_INVALID_DATE,
    phone: Resources.TR_VALIDATE_INVALID_PHONE
};

/**
 * @function
 * @description loops through current field's form errorlist and returns method that triggered error
 * @param {jQuery} $target - form field to be checked
 * @returns {String} - error code to be used as a key in FORM_ERRORS
 */
const getErrorCode = $target => {
    let validator = $target.form().data().validator;
    let errorCode;

    // solution to retrieve error's code
    validator.errorList.forEach(errorObj => {
        // proceed if current target has error obj
        if ($(errorObj["element"]).is($target)) {
            errorCode = errorObj["method"];
        }
    });

    return errorCode;
};

/**
 * @function
 * @description - evaluates form fields, checks if there are any errors upon validation and tracks them
 * @param {jQuery} $target - form field to be checked and tracked (e.g. input, select)
 * @param {String} dataLocation - location of the modal: "Your subscription", "Saved methods/address"
 * @param {String} subOffer - subscription offer ID
 */
const setErrorTrackingAction = ($target, dataLocation, subOffer) => {
    const isAddressForm = $target.parents("[data-track-address]").length;
    const namePrefix = isAddressForm ? "dwfrm_profile_address_" : "dwfrm_paymentinstruments_creditcards_newcreditcard_";
    let fieldName = $target.attr("name")?.replace(`${namePrefix}`, "");

    if (!fieldName) {
        return;
    }

    // on saved payment methods, CC number has "_abc123" in the end
    if (fieldName.indexOf("number_") > -1) {
        fieldName = "number";
    }

    let action;
    let location;
    const isValid = $target.valid();
    const alreadyTracked = $target.data("trackedError");
    let errorCode = getErrorCode($target);

    // generate <action : corrected> in case the field was corrected without triggering a new error
    if (isValid && (alreadyTracked && alreadyTracked !== errorCode)) {
        errorCode = $target.data("errorCode");
        action = errorCode ? `${FIELD_NAMES[fieldName.toLowerCase()]} | ${FORM_ERRORS[errorCode.toLowerCase()]} : corrected` : "";

        $target.removeData("errorCode trackedError");
    } else if (!isValid) {
        // proceed if error occured and wasn't tracked
        if (!alreadyTracked || (alreadyTracked && alreadyTracked !== errorCode)) {
            $target
                .data("trackedError", errorCode)
                .data("errorCode", errorCode);
        } else if (alreadyTracked && alreadyTracked === errorCode) {
            // return in case the error was tracked and not corrected to avoid duplicated events tracked
            $target.data("trackedError", errorCode);
            errorCode = "";

            return;
        }

        action = $target.data("trackedError") ? `${FIELD_NAMES[fieldName.toLowerCase()]} | ${FORM_ERRORS[errorCode.toLowerCase()]}` : "";
    }

    // return if error didn't occur
    if (isValid && $target.data("trackedError")) {
        $target.removeData("errorCode trackedError");

        return;
    }

    if (isAddressForm) {
        if ($target.parents("[data-track-address]").attr("data-track-address") === "add") {
            location = `${dataLocation} : add a new address pop in`;
        } else {
            location = `${dataLocation} : edit address pop in`;
        }
    } else {
        if ($target.parents("[data-track-payment]").attr("data-track-payment") === "edit") {
            location = `${dataLocation} : edit payment method`;
        } else {
            location = `${dataLocation} : add a new payment method pop in`;
        }
    }

    if (adobeAnalytics && action && pageContext.ns === "account") {
        adobeAnalytics.track("impressionevent", modalTrack({
            category: "error",
            location: location,
            action: action,
            locationDetail: (dataLocation == "your subscription" && subOffer) ? subOffer : ""
        }).impressionParams);
    }
};

/**
 * @function
 * @description logic for collecting data from a page in order to track with adobeAnalytics
 */
const bannersEventHandlers = {
    /**
     * @function
     * @param {Object} data to be processed by tracking event
     * @returns {Object} containing data necessary for firing event
     */
    getTrackingObj: data => {
        return {
            location: data?.location || "your subscription",
            category: data?.category || "action",
            ...(data?.productId) && {
                productId: data.productId
            },
            ...(data?.locationPosition) && {
                locationPosition: data.locationPosition
            },
            ...(data?.locationDetail) && {
                locationDetail: data.locationDetail
            },
            ...(data?.action) && {
                action: data.action
            },
            ...(data?.productImpression) && {
                productImpression: data.productImpression
            }
        };
    },

    /**
     * @function
     * @description handles firing product impression event with proper data
     */
    handleProductImpression: () => {
        const $banners = $("[data-bannertype]");

        if ($banners.length) {
            const bannersData = getBannersData();

            $banners.each((i, el) => {
                const $this = $(el);
                const bannerType = $this.data("bannertype");
                const productInfo = $this.data("params");
                const eventParams = bannersEventHandlers.getTrackingObj({
                    ...productInfo,
                    ...{
                        locationDetail: (productInfo?.subProduct + bannersData[`${bannerType}`]?.impression?.locationDetail) || "",
                        category: "display",
                        action: "visible",
                        locationPosition: "notification",
                        productImpression: [productInfo?.productID || ""]
                    }
                });

                adobeAnalytics.track("impressionevent", eventParams);
            });
        }
    },

    /**
     * @function
     * @description handles firing click event with proper data
     */
    handleClicks: event => {
        const $this = event?.detail?.button ? $(event.detail.button) : $(event.currentTarget);
        const bannersData = getBannersData();
        const bannerType = $this.parents("[data-bannertype]").data("bannertype");
        const productInfo = $this.parents("[data-bannertype]").data("params");
        const eventAction = event?.detail?.closeAction ? bannersData[`${bannerType}`]?.click?.closeAction : bannersData[`${bannerType}`]?.click?.action;
        const eventParams = bannersEventHandlers.getTrackingObj({
            ...productInfo,
            ...{
                locationDetail: (productInfo.subProduct + bannersData[`${bannerType}`]?.click?.locationDetail) || "",
                action: eventAction,
                locationPosition: "notification",
                productId: productInfo?.productID
            }
        });

        adobeAnalytics.track("clickevent", eventParams);
    }
};

/**
* Tracks the click event and triggers the Address CTA tracking event
* @param {jQuery.Element} elem - the element that triggered the event
*/
const triggerAddressCTATrackingEvent = elem => {
    const $this = $(elem);

    adobeAnalytics.track("clickevent", {
        location: "addresses",
        category: $this.data("track-action") === "edit account information" ? "action" : "interaction",
        action: $this.data("track-action")
    });
};

/**
* Triggers a subscription address CTA tracking event
* @param {HTMLElement} button - the button element that triggers the event
* @param {string} modalMode - the mode of the modal (add, edit, manage)
*/
const triggerSubscriptionAddressCTATrackingEvent = (button, modalMode) => {
    const $target = $(button);
    let modalLocation = $cache.modalLocation;
    let action;
    let location;

    if (!modalMode) {
        const $parentModal = $target.closest(".reveal");

        if ($parentModal.length > 0) {
            modalMode = $parentModal.attr("data-track-address");
        }
    }

    if (modalMode === "add") {
        location = `${modalLocation} : add a new address pop in`;
        action = $target.is("button[type='submit']") ? "new address : save" : "";
    } else if (modalMode === "edit") {
        location = `${modalLocation} : edit address pop in`;
        action = $target.is("button[type='submit']") ? "saved address : save" : "";
    } else if (modalMode === "manage") {
        const trigger = $target.data("trigger");

        if (trigger) {
            action = trigger === "saveAddress" ? "change billing address : save" :
                trigger === "editAddress" ? "change billing address : edit" :
                    trigger === "addAddress" ? "change billing address : add a new address" : "";
        }

        location = `${modalLocation} : change billing address pop in`;
    }

    if ($target.closest(".close-button").length || $target.closest(".ui-dialog-titlebar-close").length || $target.hasClass("swal2-close")) {
        action = "close pop in";
        $cache.modalLocation = "";
    }

    if (adobeAnalytics && action && location && pageContext.ns === "account") {
        const eventParams = modalTrack({
            action: action,
            location: location,
            locationDetail: modalLocation === "your subscription" ? $cache.subOffer : ""
        }).clickParams;

        adobeAnalytics.track("clickevent", eventParams);
    }
};

/**
 * @function
 * @description Initialize My Account Subscription events
 */
const initializeAccountSubscriptionEvents = () => {
    // account header "my wallet", "manage my account"
    $document.on("click", ".account-header a, .account-header button", e => {
        const $this = $(e.currentTarget);
        const action = $this.hasClass("wallet") ? "my wallet" :
            $this.hasClass("edit-account") ? "account management" : "";

        if (adobeAnalytics && pageContext.ns === "account" && action) {
            adobeAnalytics.track("clickevent", {
                location: "my account nav",
                category: "action",
                action: action
            });
        }
    });

    // account  navigation
    $document.on("click", ".account-navigation [data-account-nav-link]", e => {
        const $this = $(e.currentTarget);
        const action = $this.attr("data-account-nav-link") ? $this.attr("data-account-nav-link") : "";

        if (adobeAnalytics && pageContext.ns === "account" && action) {
            adobeAnalytics.track("clickevent", {
                location: "my account nav",
                category: "interaction",
                action: action
            });
        }
    });

    // account subscription banners tracking listener
    $document.on("click", "[data-bannertype] button, [data-bannertype] a", e => {
        bannersEventHandlers.handleClicks(e);
    });

    // "use my withdrawal rights" listener
    $document.on("click", ".withdrawal-cta a", e => {
        const $productInfo = $(e.currentTarget).parents(".withdrawal-cta").data("params");
        const eventParams = bannersEventHandlers.getTrackingObj({
            ...$productInfo.subProduct,
            ...{
                locationDetail: $productInfo.subProduct + " : withdrawal",
                action: "use my withdrawal rights"
            }
        });

        adobeAnalytics.track("clickevent", eventParams);
    });

    // my-account FAQ CTA listener
    $document.on("click", ".account-subscription-need-help a", e => {
        const eventParams = $(e.currentTarget).parents("[data-params]").data("params");

        if (eventParams) {
            adobeAnalytics.track("clickevent", eventParams);
        }
    });

    // my-account accordion toggle/UPC CTA  "manage subscription"
    $document.on("click", ".account-subscription-action.subscription-information-wrapper .account-subscription-accordion-button", e => {
        const $this = $(e.currentTarget);
        const eventParams = $this.data("params");

        if (eventParams && ($this.parents(".accordion-element").hasClass("active"))) {
            adobeAnalytics.track("clickevent", eventParams);
        }
    });

    // my-account subscription edit payment/billing
    $document.on("click", ".account-subscription-action-billing button, .account-subscription-action-payment button", e => {
        const params = $(e.currentTarget).data("params");
        const eventParams = params ? params : $(e.currentTarget).parent("[class*=account-subscription-action]").data("params");

        // cache subOffer for further popins usage
        $cache.subOffer = eventParams.locationDetail;

        if (eventParams) {
            adobeAnalytics.track("clickevent", eventParams);
        }
    });

    // my-account address add new/ edit saved events
    $document.on("click", "[data-track-address]", e => {
        const target = e.target;
        const modalMode = $(e.currentTarget).attr("data-track-address");

        triggerSubscriptionAddressCTATrackingEvent(target, modalMode);
    });

    $document.on("click", "[data-track-payment=manage]", e => {
        const $target = $(e.target);
        let action;

        if ($target.closest(".close-button").length || $target.closest(".ui-dialog-titlebar-close").length) {
            action = "close pop in";
            $cache.modalLocation = "";
        } else {
            action = $target.hasClass("edit-zuora-payment-method") ? "manage payment method : edit" :
                $target.hasClass("add-new-credit-card") ? "manage payment method : add a new payment method" :
                    $target.hasClass("js-update-default-payment-method") ? "manage payment method : save" :
                        $target.hasClass("delete-zuora-payment-method") ? "manage payment method : delete" : "";
        }

        if (adobeAnalytics && action && pageContext.ns === "account") {
            const eventParams = modalTrack({
                action: action,
                location: "your subscription : manage payment method pop in",
                locationDetail: $cache.subOffer
            }).clickParams;

            adobeAnalytics.track("clickevent", eventParams);
        }
    });

    // click events within payment methods modals (your subscription, saved payment)
    $document.on("click", "[data-track-payment=new], [data-track-payment=edit]", e => {
        const $modal = $(e.currentTarget);
        const $paymentForm = $modal.find("#CreditCardForm");
        const $target = $(e.target);
        const modalLocation = $cache.modalLocation;
        const modalMode = $modal.attr("data-track-payment");
        const subOffer = $cache.subOffer;
        let action;

        if ($paymentForm.length && ($target.hasClass("apply-button") || $target.is("#applyBtn"))) {
            let paymentMethod;

            if ($cache.modalLocation == "saved payment methods") {
                paymentMethod = "credit card";
            } else {
                const paymentMethodID = $paymentForm.find("input[name='selectedPaymentMethodID']:checked").val();

                if (!paymentMethodID) return;

                paymentMethod = paymentMethodID == "CREDIT_CARD" ? "credit card"
                    : paymentMethodID == "PayPal" ? "paypal" : "";
            }

            action = `${paymentMethod} : save`;
        } else if ($target.closest(".close-button").length || $target.closest(".ui-dialog-titlebar-close").length) {
            action = "close pop in";
            $cache.modalLocation = "";
        }

        if (adobeAnalytics && action && pageContext.ns === "account") {
            const eventParams = modalTrack({
                action: action,
                location: modalMode == "edit" ? `${modalLocation} : edit payment method` : `${modalLocation} : add a new payment method pop in`,
                locationDetail: modalLocation == "your subscription" ? subOffer : ""
            }).clickParams;

            adobeAnalytics.track("clickevent", eventParams);
        }
    });

    // my-account impression events for edit address/ payment pop in
    $document.on("open.zf.reveal dialog:dataAdded", "[data-track-address], [data-track-payment]", e => {
        const $openedModal = $(e.currentTarget);

        if ($(e.target).is("select")) {
            return;
        }

        const modalLocation = $openedModal.data("location");
        const locationDetail = $cache.subOffer;
        let location;

        if (modalLocation) {
            $cache.modalLocation = modalLocation; // "your subscription" || "addresses" || "saved payment methods"

            if ($openedModal.is("[data-track-address]")) {
                const modalMode = $openedModal.attr("data-track-address");

                location = modalMode === "add" ? `${modalLocation} : add a new address pop in` :
                    modalMode === "edit" ? `${modalLocation} : edit address pop in` :
                        modalMode === "manage" ? `${modalLocation} : change billing address pop in` : "";
            } else {
                if ($openedModal.attr("data-track-payment") === "manage") {
                    location = `${modalLocation} : manage payment method pop in`;
                } else if ($openedModal.attr("data-track-payment") === "edit") {
                    location = `${modalLocation} : edit payment method`;
                } else {
                    location = `${modalLocation} : add a new payment method pop in`;
                }
            }
        }

        if (adobeAnalytics && location && pageContext.ns === "account") {
            const eventParams = modalTrack({
                action: "visible",
                location: location,
                locationDetail: modalLocation == "your subscription" ? locationDetail : ""
            }).impressionParams;

            adobeAnalytics.track("impressionevent", eventParams);
        }
    });

    // my-account edit payment method error
    $document.on("paymentError", "[data-track-payment=manage]", () => {
        if (adobeAnalytics && pageContext.ns === "account") {
            adobeAnalytics.track("impressionevent", {
                location: "your subscription : manage payment method pop in",
                category: "error",
                locationPosition: "pop in",
                locationDetail: $cache.subOffer,
                action: "payment update failed"
            });
        }
    });

    // my-account impression events for add/edit address, new payment forms
    $document.on("change focusout", "#manage-address-form, #edit-address-form, #CreditCardForm", e => {
        let $target = $(e.target);

        if ($target.is("button:submit")) {
            const currentForm = $target.form();

            if (currentForm.validate().checkForm()) return;

            const errorList = currentForm.data().validator.errorList;

            errorList.forEach(errObj => {
                setErrorTrackingAction($(errObj.element), $cache.modalLocation, $cache.subOffer);
            });
        } else {
            const namePrefix = $(e.target).form().is("#CreditCardForm") ? "dwfrm_paymentinstruments_creditcards_newcreditcard_" : "dwfrm_profile_address_" ;

            $target = $target.closest(".form-row").find(`[name*=${namePrefix}]:not(span)`);
        }

        setErrorTrackingAction($target, $cache.modalLocation, $cache.subOffer);
    });

    // my-account accordion toggle "view invoices"
    $document.on("click", ".toggle-invoice-section", e => {
        const $this = $(e.currentTarget);
        const openParams = $this.data("open-params");
        const closeParams = $this.data("close-params");

        if ($this.closest(".accordion-element").hasClass("active") || $this.closest(".account-subscription-nextbilling-section").hasClass("active")) {
            if (openParams) {
                adobeAnalytics.track("clickevent", openParams);
            }
        } else {
            if (closeParams) {
                adobeAnalytics.track("clickevent", closeParams);
            }
        }
    });

    // my-account shipping/billing section
    $document.on("click", ".account-addresses [data-track-action], #personal-info [data-track-action]", e => {
        triggerAddressCTATrackingEvent(e.currentTarget);
    });

    document.addEventListener("Button:GetAddressModalEvent", e => {
        const button = e.detail.button;

        if (e.detail?.response?.isSubscription) {
            triggerSubscriptionAddressCTATrackingEvent(button);
        } else {
            triggerAddressCTATrackingEvent(button);
        }
    });

    document.addEventListener("AddressModal:CustomOpenEvent", e => {
        let modalLocation = "addresses";
        let location;
        const locationDetail = $cache.subOffer;
        const modalMode = e.detail?.action;

        if (e.detail?.isSubscription) {
            modalLocation = "your subscription";
        }

        $cache.modalLocation = modalLocation;
        location = modalMode === "add" ? `${modalLocation} : add a new address pop in` :
            modalMode === "edit" ? `${modalLocation} : edit address pop in` : "";

        if (adobeAnalytics && location && pageContext.ns === "account") {
            const eventParams = modalTrack({
                action: "visible",
                location: location,
                locationDetail: modalLocation === "your subscription" ? locationDetail : ""
            }).impressionParams;

            adobeAnalytics.track("impressionevent", eventParams);
        }
    });

    document.addEventListener("AddressModal:CustomCloseEvent", e => {
        triggerSubscriptionAddressCTATrackingEvent(e.detail.button, e.detail.action);
    });

    document.addEventListener("SubscriptionNotification:CustomCloseEvent", e => {
        bannersEventHandlers.handleClicks(e);
    });

    // saved payment method change
    $document.on("click", "[data-track-manage-saved-payment]", e => {
        const $this = $(e.currentTarget);

        adobeAnalytics.track("clickevent", {
            location: "saved payment methods",
            category: "interaction",
            action: $this.data("track-manage-saved-payment")
        });
    });

    // init handler for impression event
    bannersEventHandlers.handleProductImpression();

    // handle pageevents for saved addresses and saved payment instruments
    if ($("#personal-info").length || $("#payment-instruments").length) {
        const siteSubSection = $("[data-sub-section]").data("sub-section");

        if (siteSubSection) {
            adobeAnalytics.track("pageevent", {
                pageName: "main",
                pageUrl: window?.location?.href,
                siteSection: "my account",
                siteSubSection: siteSubSection,
                ubisoftId: window?.User?.ubisoftId
            });
        }
    }

    // handle pageevents Ubisoft+ Rocksmith+ subscription pages
    if ($("body").hasClass("app_uplay") && $("#account-subscription.account-subscription-wrapper").length && pageContext.ns === "account") {
        const params = $("#account-subscription.account-subscription-wrapper").data("params");

        if (adobeAnalytics && params) {
            const eventParams = {
                pageName: params.subProduct,
                pageUrl: window?.location?.href,
                siteSection: "my account",
                siteSubSection: "my subscriptions",
                installmentName: params.subProduct,
                brandName: params.subProduct === "rocksmith plus" ? "rocksmith" : params.subProduct === "r6sm" ? params.subProduct : "ubisoft plus",
                ubisoftId: window?.User?.ubisoftId,
                ...params.mdmBrandId && {
                    mdmBrandId: params.mdmBrandId
                }, // "934 || 2576"
                ...params.mdmInstallmentId && {
                    mdmInstallmentId: params.mdmInstallmentId
                } // "5257 || 6188"
            };

            adobeAnalytics.track("pageevent", eventParams);
        }
    }
};

export { initializeAccountSubscriptionEvents };
