let $cache = {};

const data = {
    disclosureComponentBtn: "[data-cta=disclosure-component]",
    disclosureComponentBtnActive: "[data-cta=disclosure-component][aria-expanded=true]",
    disclosureComponentContainer: "[data-scope=disclosure-component]",
    expandedClass: "active"
};

/**
 * @function
 * @description initialize cache object
 */
const initCache = () => {
    $cache = {
        document: $(document)
    };
};

/**
 * @function
 * @description hide the expandable section on focus out
 */
const hideonFocusout = e => {
    const $currentTarget = $(e.currentTarget).closest(data.disclosureComponentContainer + "." + data.expandedClass);

    if (!$currentTarget.length || (e.relatedTarget && $currentTarget.has(e.relatedTarget).length)) {
        return;
    }

    const $activeDisclosureComponent = $currentTarget.find(data.disclosureComponentBtnActive);

    if ($activeDisclosureComponent.length) {
        $activeDisclosureComponent.trigger("click");
    }
};

/**
 * @function
 * @description initialize events for disclosure components
 */
const initEvents = () => {
    // show/hide the expandable section for screen readers
    $cache.document.on("click", data.disclosureComponentBtn, e => {
        const $this = $(e.currentTarget);

        // get the section controlled by the button
        const expandableSectionId = $this.attr("aria-controls");
        const $expandableSection = $("#" + expandableSectionId);

        // get current visibility status
        const status = $expandableSection.hasClass(data.expandedClass) || $this.attr("aria-expanded") === "true";

        // update the status
        if ($expandableSection.length) {
            $this.attr("aria-expanded", !status);
            $expandableSection.toggleClass(data.expandedClass, !status);
            $this.closest(data.disclosureComponentContainer).toggleClass(data.expandedClass, !status);

            $cache.document.trigger("disclosureComponents:toggle", [{
                expandablePanel: $expandableSection,
                expanded: !status
            }]);
        }

        e.stopPropagation();
    });

    // close the expandable section on ESC, for the focused expandable section
    $cache.document.on("keydown", e => {
        if (e.key !== "Escape") {
            return;
        }

        let $activeDisclosureComponent = $(e.target).closest(data.disclosureComponentBtnActive);

        if (!$activeDisclosureComponent.length) {
            const $activeParent = $(e.target).closest("." + data.expandedClass);

            if ($activeParent.length) {
                const activeElemId = $activeParent.attr("id");

                $activeDisclosureComponent = $(`[aria-controls=${activeElemId}]`);
            }
        }

        if ($activeDisclosureComponent.length) {
            e.stopPropagation();
            $activeDisclosureComponent.trigger("click").trigger("focus");
        }
    });

    // close the expandable section on focusout
    $cache.document.on("focusout", data.disclosureComponentContainer + "." + data.expandedClass, hideonFocusout);

    // close the expandable section on focusout when the product tile is inside a slick slider
    // this is added because the slick slider is using shop propagation for all focusable elements
    $(".enhanced-slider-products").on("focusout", "*", hideonFocusout);
};

const initDisclosureComponents = () => {
    initCache();
    initEvents();
};

export { initDisclosureComponents };
