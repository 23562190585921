import "slick-carousel";
import { initFreeOffer } from "../freeoffer";
import { dialog } from "../dialog";

/**
 * @function
 * @description inits Age restriction popup (if there are restricted products in the basket)
 */
const initAgeRestrictionPopup = () => {
    const $restrictedProductCntr = $("#ageRestrictedProducts");

    if ($restrictedProductCntr.length > 0) {
        const $restrictedProducts = $restrictedProductCntr.find(".product-tile");
        const $restrictedSubscription = $restrictedProductCntr.find(".restricted-subscription-product");
        const dialogClassList = $restrictedSubscription.length > 0 ? "agerestrictedsub agerestrictedproducts checkout-restriction-popup" : "agerestrictedproducts checkout-restriction-popup";

        if ($restrictedProducts.length > 0) {
            $restrictedProducts.each((index, elem) => {
                initFreeOffer($(elem));
            });
        }

        if ($restrictedProducts.length > 0 || $restrictedSubscription.length > 0) {
            dialog.open({
                html: $restrictedProductCntr.html(),
                options: {
                    width: 600,
                    draggable: false,
                    dialogClass: dialogClassList,
                    modal: true,
                    closeText: "",
                    closeOnEscape: false
                },
                callback: () => {
                    const $restrictionSlider = $(".restriction-slider");
                    const $closeBtn = $(".remove-restricted-items");
                    const $restrictedProductWrapper = $(".restrictedproduct-wrapper");

                    if ($restrictionSlider.length > 0) {
                        $restrictionSlider.not(".slick-initialized").slick(returnRestrictedProductSliderSettings());
                    }

                    if ($restrictedProductWrapper.length > 0) {
                        $restrictedProductWrapper.removeClass("hide");
                    }

                    $closeBtn.on("click", () => {
                        dialog.close();
                        removeAgeRestrictedProduct();
                    });
                }
            });
        }
    }
};

/**
* @function
* @description Remove age-restricted product
*/
const removeAgeRestrictedProduct = () => {
    const $restrictedSubscription = $(".restricted-subscription-product");
    const items = $restrictedSubscription.length > 0 ? $restrictedSubscription : $(".restriction-slider .product-tile");
    const url = Urls.removeProducts;
    const returnUrl = sessionStorage.getItem("returnUrlAgeGateRejected");
    const productIds = [];

    items.each((index, item) => {
        let productId = $(item).data("itemid");

        if (productId) {
            productIds.push(productId);
        }
    });

    if (url && productIds.length > 0) {
        $.ajax({
            type: "POST",
            url: url,
            data: {
                productIds: productIds.join(",")
            },
            async: false,
            success: response => {
                if (response && response.success) {
                    if ($restrictedSubscription.length > 0 && returnUrl) {
                        sessionStorage.removeItem("returnUrlAgeGateRejected");
                        window.location.href = returnUrl;
                    } else {
                        window.location.href = Urls.cartShow;
                    }
                }
            }
        });
    }
};

/**
* @function
* @description function to call for the Restricted Product Slider settings
* @return {Object} slickSettingsVideoSlider - object with slider settings
*/
const returnRestrictedProductSliderSettings = () => {
    var restrictedProductSliderSettings = {
        rows: 0, // prevents the injection of 2 parent divs for each slide
        dots: false,
        infinite: false,
        speed: 400,
        slidesToShow: 3,
        slidesToScroll: 3,
        autoplay: false,
        arrows: false,
        cssEase: "ease",
        responsive: [
            {
                breakpoint: 1025,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    return restrictedProductSliderSettings;
};

export { initAgeRestrictionPopup };
