import "slick-carousel";
import { symbols } from "./symbols";

var $cache = {};

var initCache = function () {
    $cache = {
        enhancedSliderCategories: $(".enhanced-slider-categories")
    };
};

/**
* @function
* @description function to call for the Franchises Category Slider settings
* @param {object} $selector - object - jquery selector of the slider to get params from
* @return {Object} slickSettingsVideoSlider - object with slider settings
*/
function returnFranchisesCategorySliderSettings($selector) {
    var slickFranchisesCategorySlider = {
        dots: false,
        infinite: false,
        speed: 400,
        slidesToShow: 8,
        slidesToScroll: 8,
        autoplay: false,
        arrows: true,
        autoplaySpeed: ($selector && $selector.data("autoplay-time")) ? $selector.data("autoplay-time") : 5000,
        prevArrow: symbols.transparentPrevArrow(),
        nextArrow: symbols.transparentNextArrow(),
        cssEase: "ease",
        responsive: [
            {
                breakpoint: 3350,
                settings: {
                    slidesToShow: 8,
                    slidesToScroll: 8
                }
            },
            {
                breakpoint: 2799,
                settings: {
                    slidesToShow: 7,
                    slidesToScroll: 7
                }
            },
            {
                breakpoint: 2559,
                settings: {
                    slidesToShow: 6,
                    slidesToScroll: 6
                }
            },
            {
                breakpoint: 2179,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 5
                }
            },
            {
                breakpoint: 1660,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4
                }
            },
            {
                breakpoint: 1340,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            },
            {
                breakpoint: 959,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            }
        ]
    };

    return slickFranchisesCategorySlider;
}

/**
* @function
* @description function to call for the Franchises Category Slider settings
*/
function initFranchisesCategorySlider() {
    $cache.enhancedSliderCategories.each(function (index, element) {
        if ($(element) != null) return $(element).not(".slick-initialized").slick(returnFranchisesCategorySliderSettings($(element)));
    });
}

function initFranchiseSlider() {
    initCache();
    initFranchisesCategorySlider();
}

export { initFranchiseSlider };
