import { initMyBenefitsPageEvents } from "./mybenefits_events";
import ThenPromise from "promise";
import { cartTrackingEvents } from "./cart_events";
import { initializeIbexClickEvents } from "./ibex_events";
import { getCookie } from "../../../app_web/cartridge/js/tls";
import { adobeEvents, adobePageEvents } from "./adobe_analytics_events";
import { loadAnalyticsSdk } from "./analytics_sdk_loader";

var pdptabs = require("./pdptabs"),
    productdetail = require("./productdetail"),
    upc_subscription_events = require("./upc_subscription"),
    wallet_events = require("./wallet_events"),
    agegate_events = require("./agegate"),
    isUpc = $(".app_uplay").length,
    isShipping = $("fieldset.shipping-address").length,
    isBilling = $("fieldset.billing-address").length,
    isBillingError = $("[data-waerror]").length,
    isSummary = $("form.submit-order").length,
    isThankYou = $("div.order-confirmation-details").length,
    isAgeGate = $("#age-gate form").length,
    isBadAge = $("#bad-age").length,
    isWalletOrder = $("#check-wallet-order").length ? $("#check-wallet-order").data("is-wallet-order") : false,
    isSubscriptionProduct = $("#subscription-order").length ? $("#subscription-order").data("is-subscription-product") : false,
    subscriptionCategId = $("#subscription-category-id").length ? $("#subscription-category-id").val() : "",
    isMyBenefits = $("#menu-my-benefits").length,
    isLegacy = SitePreferences.IS_LEGACY,
    isProductListing = typeof wa_data !== undefined && typeof wa_data !== "undefined" && wa_data.siteSection === "product listing",
    isAlgolia = SitePreferences.ENABLE_ALGOLIA_TAG_COMMANDER && SitePreferences.ALGOLIA_ENABLED ? true : false,
    enabledSubscription = SitePreferences.ENABLE_SUBSCRIPTION ? true : false,
    enabledSubscriptionTagCommander = SitePreferences.ENABLE_SUBSCRIPTION_TAG_COMMANDER ? true : false,
    enabledUPCSubscriptionTagCommander = SitePreferences.ENABLE_SUBSCRIPTION && SitePreferences.ENABLE_UPC_SUBSCRIPTION_TAG_COMMANDER ? true : false,
    enabledRefundTagCommander = SitePreferences.REFUND_ENABLE && SitePreferences.ENABLE_REFUND_TAG_COMMANDER ? true : false,
    isGamingListPageURL = enabledSubscription && (enabledSubscriptionTagCommander || enabledUPCSubscriptionTagCommander) && Resources.GAMING_LIST_PAGE_URL != "" ? window.location.href.indexOf(Resources.GAMING_LIST_PAGE_URL) > -1 : window.location.href.indexOf("uplay-plus") > -1,
    isUbiPassLandingPageURL = enabledSubscription && (enabledSubscriptionTagCommander || enabledUPCSubscriptionTagCommander) && SitePreferences.SUBSCRIPTION_CATEGORY_ID === subscriptionCategId,
    isWISTagCommanderEnabled = SitePreferences.WIS_ENABLE && SitePreferences.ENABLE_WIS_TAG_COMMANDER ? true : false,
    isWishlistTagCommanderEnabled = SitePreferences.ENABLEWISHLIST && SitePreferences.ENABLE_WISHLIST_TAG_COMMANDER ? true : false,
    isWalletTagCommanderEnabled = SitePreferences.ENABLE_WALLET && SitePreferences.ENABLE_WALLET_TAG_COMMANDER ? true : false,
    isArrowTagCommanderEnabled = SitePreferences.ENABLE_ARROW && SitePreferences.ENABLE_ARROW_TAG_COMMANDER ? true : false,
    isIbexTagCommanderEnabled = SitePreferences.ENABLE_IBEX_TAG_COMMANDER,
    ConsentResources = window.ConsentResources || {},
    adobeEventsLoaded = false,
    adobePageEventsLoaded = false;
/**
/**
 * Module provides API for TagCommander
 * @module tagCommander
 *
 */
var $cache = $cache || {},
    targetEvent,
    clickAddToCart,
    dlcVariationClick,
    algoliaTileButton,
    algoliaSortBy,
    fromClick,
    getTargetObject,
    tripleTileSection,
    doubleTileSection,
    $tripleTileEventTarget,
    sliderProductTiles,
    isShippingSection = false,
    isBillingSection = false,
    productSlider,
    waDataObject = {
        siteCategory: "",
        siteType: "",
        siteName: "UplayStore",
        country: "",
        language: "",
        template: "",
        genomeID: "",
        doNotTrack: "",
        mdmBID: "",
        mdmIID: "",
        game: "",
        brand: "",
        pageName: "",
        siteSubSection: "",
        siteSection: "",
        errorType: "",
        hostDomain: window.hostDomain ? window.hostDomain : "",
        uplayID: (typeof $cache.uplayData !== "undefined" && $cache.uplayData === "object" && $cache.uplayData["user-id"]) ? $cache.uplayData["user-id"] : "",
        searchKeyword: "",
        searchResultsNumber: "",
        productType: "",
        product: {
            masterID: "",
            masterName: "",
            gameID: "",
            gameName: "",
            brandName: "",
            brandID: "",
            productType: "",
            productID: "",
            productName: "",
            editionName: "",
            releaseDate: "",
            platform: "",
            productFormat: "",
            productTypeDetail: "",
            availability: "",
            orderType: "",
            offerName: "",
            fullPrice: "",
            discountName: "",
            discountAmmount: "",
            discountRate: "",
            discountedPrice: "",
            mdmIID: "",
            mdmBID: "",
            salesType: "",
            unitSalePrice: "",
            salePrice: "",
            originalUnitSalePrice: ""
        },
        cart: {
            cartView: "",
            cartOpen: "",
            cartAdd: "",
            cartRemove: "",
            itemNumber: "",
            currency: "",
            cartID: "",
            orderAmountNotDiscounted: "",
            orderAmountDiscounted: "",
            orderTaxAmountNotDiscounted: "",
            orderTaxAmountDiscounted: "",
            promoCode: "",
            promoCodeName: "",
            promoCodeAmount: "",
            orderID: "",
            shippingAmount: "",
            shippingType: "",
            paymentMethod: "",
            uplayPointsCollected: "",
            uplayPointsAvailable: "",
            uplayPointSpent: "",
            content: ""
        }
    };

/**
 * initialize variables
 * @private
 */
function initializeCache() {
    $cache.document = $(document);
    // dialogGeorestricted selector is used only for WEB. georestricted id is used for both UPC and WEB
    $cache.dialogGeorestricted = $("#georestricted.dialog-georestricted");
    $cache.uplayData = typeof uplayData !== "undefined" && typeof uplayData === "object" ? uplayData : "";

    if (typeof wa_data !== "undefined") {
        $cache.wa_data = $.extend({}, removeSpecialCharacters(wa_data));
    } else {
        $cache.wa_data = $.extend({}, waDataObject);
    }

    var $categoryProduct = $(".category-product-slider");

    if ($categoryProduct.length > 0) {
        $categoryProduct.each(function (index) {
            $(this).data("order", index + 1);
        });
    }

    var $productCorner = $(".homepage-product-corner");

    if ($productCorner.length > 0) {
        $productCorner.each(function (index) {
            $(this).data("order", index + 1);
        });
    }

    $cache.revealToken = true;
    dlcVariationClick = "init";
    $cache.wisModal = $(".club-discount-modal");
    $cache.wishlistShareModal = $("#wishlist-share-popup");
    $cache.notificationsSwitchAll = $("#notifications-switch");
    $cache.orderRecapBlock = $(".order-recap-block");
}

/**
 * Triggers a tagcommander 'clickevent'
 * @param {Object} selector The html selector that triggered the event
 * @param {Object} data The type of action on the slider
 */
function submitEvent(selector, data) {
    var eventName = selector.data("event"),
        eventValue = selector.data("tc100"),
        gridUrl = selector.data("gridUrl"),
        mdmbid = selector.data("mdmbid"),
        mdmiid = selector.data("mdmiid"),
        searchkeyword = selector.attr("data-searchkeyword"),
        clickType = data ? data.clickType : "clicked";

    if (eventName != undefined && eventName != "") {
        events[eventName](selector[0], eventValue, gridUrl, clickType, mdmbid, mdmiid, searchkeyword);
    }
}

// Check if the section is in the viewport and send an event
function checkScrollEvent(el, txt) {
    if (el.length && !el.data("tagging-triggered")) {
        var elementTop = el.offset().top;
        var elementBottom = elementTop + el.outerHeight();
        var viewportTop = $(window).scrollTop();
        var viewportBottom = viewportTop + $(window).height();
        var inViewport = elementBottom > viewportTop && elementTop < viewportBottom;

        if (inViewport) {
            var actionValue = txt + " - tab displayed - ";

            actionValue += (fromClick) ? "click" : "scroll";

            el.data("event", "scrollEvent");
            sendEvent("clickevent", actionValue, el);
            el.data("tagging-triggered", true);

            if (fromClick) {
                fromClick = null;
            }
        }
    }
}

/**
 * @function
 * @description function to check if an element belongs to a product tile and if it does, find the specific thumb-link and redirect to its anchor (product URL)
 * @param {Object} $elem - The element that is used to check if it belongs to a product-tile
 */
const redirectToProductURL = $elem => {
    const $productTileCard = $elem.parent().closest("div.product-tile.card");

    if ($productTileCard.length > 0) {
        const $thumbLink = $productTileCard.find(".thumb-link").length > 0 ? $productTileCard.find(".thumb-link") : "";

        if ($thumbLink !== "") {
            const thumblinkHref = $thumbLink.attr("href");

            if ($thumbLink.length > 0 && thumblinkHref !== "") {
                window.location.href = thumblinkHref;
            }
        }
    }
};

/**
 * Initialize events
 * @private
 */
function initializeEvents() {
    pdptabs.init();
    productdetail.init();

    // INITIALIZE SUBSCRIPTION TAGGING PLAN EVENTS
    if (enabledSubscription && enabledSubscriptionTagCommander) {
        createSubscriptionClickEvents();
        createSubscriptionMyAccountEvents();
        createEarlyRegistrationEvents();
        switchSubscriptionPlanEvents();
        cancelSwitchRequestEvents();
    }

    if (isWishlistTagCommanderEnabled) {
        createWishListEvents();
    }

    // INITIALIZE REFUND TAGGING PLAN EVENTS
    if (enabledRefundTagCommander) {
        createRefundEvents();
    }

    // INITIALIZE ARROW REFUND TAGGING PLAN EVENTS
    if (isArrowTagCommanderEnabled) {
        createArrowRefundEvents();
    }

    // INITIALIZE WIS TAGGING PLAN EVENTS
    if (isWISTagCommanderEnabled) {
        createWISEvents();
    }

    // UPC SITE - SUBSCRIPTION TAGGING PLAN - CLICK EVENTS
    if (enabledUPCSubscriptionTagCommander) {
        upc_subscription_events.init();
    }

    // INITIALIZE WALLET TAGGING PLAN EVENTS
    if (isWalletTagCommanderEnabled) {
        wallet_events.init();
    }

    // INITIALIZE MY BENEFITS TAGGING PLAN EVENTS
    if (isMyBenefits) {
        initMyBenefitsPageEvents();
    }

    // INITIALIZE AGE GATE TAGGING PLAN EVENTS
    agegate_events.init();

    // INITIALIZE IBEX TAGGING PLAN EVENTS
    if (isIbexTagCommanderEnabled) {
        initializeIbexClickEvents();
    }

    // Register click events on pushes double tag-commander-event
    $(".pushes.double").off("click").on("click", ".tag-commander-event", function (e) {
        const $this = $(this);
        const $secondaryButtonWrapper = $this.find(".secondary-cta-wrap a");

        if ($secondaryButtonWrapper.length) {
            const tc100 = {
                type: $secondaryButtonWrapper.data("aa-category"),
                title: $secondaryButtonWrapper.data("alt-text"),
                action: $secondaryButtonWrapper.data("aa-action")
            };

            $this.data("tc100", tc100);
        } else {
            const $cachedCurrentTarget = $(e.currentTarget);

            if ($cachedCurrentTarget.hasClass("product-tile") && $cachedCurrentTarget.parents(".push.push-product.push-product-white").length) {
                const text = $cachedCurrentTarget.find(".button-wrapper .button span").text().trim();

                $cachedCurrentTarget.data("tc100").action = text;
            }

            $this.data("event", "slotAction");
            submitEvent($this);
        }
    });

    // Register click events
    $("div#main:not(.home-page-listing), div.header-main-wrap").on("click", ".tag-commander-event:not(.check-for-tagging-click)", function (e, data) {
        if (enabledSubscriptionTagCommander && wa_data.siteSection !== "homepage") {
            return;
        }

        if (enabledUPCSubscriptionTagCommander && pageContext && pageContext.ns == "ubisoftpasssubscription") {
            return;
        }

        // blocks triggering this event on dlc variations.
        if ($(this).hasClass("dlc-variation") || $(this).hasClass("age-restriction-go-back-home")) {
            return;
        }

        // blocks the clickable situations on double-push contents if price & button sections are clicked on them.
        if (!$(e.target).is("img") && ($(e.target).parent().closest(".product-tile").length == 0 || $(e.target).hasClass("double-push-pricing-section") || $(e.target).hasClass("asset-body") || $(e.target).parents().find("div.double-push-pricing-section").length > 0 || $(e.target).parent().hasClass("double-push-pricing-section"))) {
            return;
        }

        if (!$(e.target).is("img") && ($(e.target).parent().closest(".product-tile").length == 0 || $(e.target).parents().find(".side-section-wrapper").length > 0 || $(e.target).hasClass("side-section-wrapper"))) {
            return;
        }

        const $cachedCurrentTarget = $(e.currentTarget);

        if ($cachedCurrentTarget.hasClass("product-tile") && $cachedCurrentTarget.parents(".push.push-product.push-product-white").length) {
            return;
        }

        // Uplay homepage filtering & sorting loads products with ajax request which already have another #main
        // Having two #main causing tag commander events trigger twice, fixed by :not selector.
        targetEvent = e.target;
        submitEvent($(this), data);
    });

    $(".slick-track").on("click", ".slick-slide", function () {
        const $this = $(this);

        if ($this.find(".tag-commander-event, .homepage-custom-slide").length === 0) {
            const slideIndex = $this.data("slick-index");

            $this.data("event", "slideAction");
            $this.data("tc100", "slide " + (slideIndex + 1));
            submitEvent($(this));
        }
    });

    $cache.document.on("click", ".tag-commander-event.check-for-tagging-click", function () {
        return;
    });

    // Register click events for the fan corner
    $(".homepage-product-corner").on("click", ".card-overlay", function () {
        $(this).data("event", "fanCorner");
        submitEvent($(this));
    });

    // Register click events my account page no order button
    $(".no-orders-wrapper").on("click", "a", function () {
        $(this).data("event", "noOrders");
        submitEvent($(this));
    });

    // Register the "sort by" select event
    $("div#main").on("change", "#grid-sort-header", function () {
        submitEvent($(this).find(":selected"));
    });

    // Register cart quantity change event
    $("div#main").on("change", ".cart-qty, .js-item-qty", function () {
        submitEvent($(this));
    });

    // Global - 20% discount tracker
    $cache.document.on("click", "#headerNotice", function () {
        tc_events_100(this, "clickevent", {
            ACTION: "header - navigation - get 20% discount "
        });
    });

    // Checkout - shipping section - "save and continue" button click event
    $cache.document.on("click", ".shipping-save-continue-button, #opc-delivery .js-checkout-btn", function () {
        if (!isShippingSection) {
            isShippingSection = true;
        }

        $(this).data("event", "checkoutShippingBillingSectionsButtons");
        submitEvent($(this));
    });

    // Checkout - billing  section - "save and review order" button click event
    $cache.document.on("click", ".billing-save-continue-button", function () {
        if (!isBillingSection) {
            isBillingSection = true;
        }

        $(this).data("event", "checkoutShippingBillingSectionsButtons");
        submitEvent($(this));
    });

    // Checkout - shipping section - edit button - click event
    $cache.document.on("click", ".shipping-section-edit-button", function () {
        $(this).data("event", "editShippingSection");
        submitEvent($(this));
    });

    // Checkout - billing section - edit button - click event
    $cache.document.on("click", "#opc-payment .edit-block", function () {
        $(this).data("event", "editBillingSection");
        submitEvent($(this));
    });

    // Checkout - billing  section - "save and review order" button click event in adding new payment method.
    $cache.document.on("click", ".save-cc.save-payment-method", function () {
        $(this).data("event", "checkoutShippingBillingSectionsButtons");
        submitEvent($(this));
    });

    // Register try free demo click event
    $(".secondary-cta-wrap").on("click", "a", function () {
        $(this).data("event", "tryFreeDemo");
        submitEvent($(this));
    });

    // DLC Page - arrow element for the variations.
    $cache.document.on("click", ".dlc-next-arrow, .dlc-prev-arrow", function () {
        var $this = $(this);
        var $arrow;

        // take the other arrow, so we track the arrows just once
        if ($this.hasClass("slider-control-next")) {
            $arrow = $(".dlc-prev-arrow");
        } else {
            $arrow = $(".dlc-next-arrow");
        }

        if ($this.data("tagging-click") == true) {
            $this.data("tagging-click", false);
            $arrow.data("tagging-click", false);
            $this.data("event", "dlcArrowsPdp");
            submitEvent($this);
        }
    });

    // DLC Page - variation click event
    $cache.document.off("click.tc-change-dlc").on("click.tc-change-dlc", ".dlc-variation", function () {
        var $this = $(this);

        $this.data("event", "dlcVariationClick");

        // prevent trigging tracking event when a page is reloading
        if (dlcVariationClick != "init") {
            submitEvent($(this));
        } else {
            dlcVariationClick = "";
        }
    });

    // Register watch video iframe click event
    if ($("#video-container").length > 0) {
        var myConfObj = {
            iframeMouseOver: false
        };

        $(window).on("blur", function () {
            if (myConfObj.iframeMouseOver) {
                submitEvent($(".primary-video"));
            }
        });
        $(document).on("mouseover", "#video-container", function () {
            myConfObj.iframeMouseOver = true;
        });
        $(document).on("mouseout", "#video-container", function () {
            myConfObj.iframeMouseOver = false;
        });
    }

    // Register additional content tab arrow events
    $(".recommendations").on("click", ".slick-next", function () {
        $(this).data("event", "additionalContentTab");
        $(this).data("tc100", "right");
        submitEvent($(this));
    }).on("click", ".slick-prev", function () {
        $(this).data("event", "additionalContentTab");
        $(this).data("tc100", "left");
        submitEvent($(this));
    });

    // Register homepage slider events
    $(".homepage-slider-wrapper").on("click", ".slick-slide", function (e) {
        // Return if is coming from Uplay+ Cancelation Page
        if (pageContext && pageContext.ns === "subscriptioncancelation") {
            return;
        }

        if (enabledUPCSubscriptionTagCommander && pageContext && pageContext.ns == "ubisoftpasssubscription") {
            return;
        }

        var slideNumber = " - slider " + ($(this).data("slick-index") + 1);
        var dataTC = $(this).find(".homepage-custom-slide").length ? $(this).find(".homepage-custom-slide").data("tc_100") : "";
        var type = "";
        var action = "";
        var productName = "";
        var actionValue = "";
        var bannerOrderNo = "";

        if (dataTC) {
            action = dataTC.action;
            type = "go to " + dataTC.type;
            bannerOrderNo = dataTC.bannerOrderNo;
        }

        // Hero Banner Uplay+ Games list Buttons
        if (enabledSubscriptionTagCommander) {
            var uplayBtn = $(e.target).data("alt-text") || $(e.target).text();
            var customerStatus = $cache.document.find(".customer_status").length > 0 ? $cache.document.find(".customer_status").data("subs-status") : "";

            if (typeof uplayBtn == "string") {
                switch (uplayBtn.toLowerCase()) {
                    case Resources.BUTTON_JOIN_UPLAY_PLUS.toLowerCase():
                    case Resources.BUTTON_REACTIVATION.toLowerCase():
                        type = "get uplay plus";

                        if (customerStatus == "Inactive") {
                            action = "renew your plan";
                        } else {
                            action = "choose your plan";
                        }

                        break;

                    case Resources.BUTTON_DISCOVER_UPLAY_PLUS.toLowerCase():
                    case Resources.BUTTON_DISCOVER_THE_PASS.toLowerCase():
                        type = "go to product";
                        action = "uplay plus";
                        break;

                    case Resources.BUTTON_ADD_TO_LIBRARY.toLowerCase():
                        type = "uplay plus";
                        action = "add to library";
                        break;

                    case Resources.BUTTON_START_PLAYING_NOW.toLowerCase():
                        type = "uplay plus";
                        action = "start playing now";
                        break;

                    case Resources.BUTTON_SEE_MORE.toLowerCase():
                        type = "go to product";
                        action = "see more";
                        productName = $(e.target).closest(".slide-content").data("title").toLowerCase();
                        break;

                    default:
                        var haveDataCategoryAtributte = checkForDataCategoryAtributte(e);

                        // NSCA content assets from hero banner have some "data-aa-category" attribute
                        // and having this an tc_events_100 event is trigger by 3rd party and we don't need to trigger it anymore
                        // on EMEA we don't have "data-aa-category" attribute and we need to fill the info and trigger it
                        if (!haveDataCategoryAtributte) {
                            var slideContent = $(e.target).closest(".slide-content");
                            var titleAttribute = slideContent.length > 0 ? slideContent.data("title") : null;

                            // if uplayBtn is not empty then the button name was customized and this is a standard behavior
                            type = "go to product";
                            action = "get the game";
                            productName = titleAttribute ? titleAttribute.toLowerCase() : "";
                        }

                        break;
                }
            }
        }

        if (!type || !action) {
            return;
        }

        if (customerStatus == "Inactive") {
            actionValue = "hero banner " + bannerOrderNo + slideNumber + " - " + type + " - " + action;
        } else if (productName != "" && productName != undefined) {
            actionValue = "hero banner " + bannerOrderNo + slideNumber + " - " + type + " - " + action + " - " + productName;
        } else {
            actionValue = "hero banner " + bannerOrderNo + slideNumber + " - " + type + " - " + action;
        }

        sendEvent("clickevent", actionValue, $(this));
    });

    $(".category-product-slider").on("click", ".slick-slide, .enhanced-slider-products:not('.slick-initialized') .grid-tile", function (e) {
        // Return if is coming from Uplay+ Gaming List
        if ((enabledSubscriptionTagCommander || enabledUPCSubscriptionTagCommander) && isGamingListPageURL) {
            return;
        }

        if (enabledSubscriptionTagCommander && window.location.href.indexOf("order-success") > -1) {
            return;
        }

        $(this).data("event", "sliderAction");

        if (e.currentTarget !== e.target && $(this).find(".card-overlay").length > 0) {
            targetEvent = e.target;
        }

        // This variable checks the producttile sections except buttons to be clickable.
        getTargetObject = e.target;

        // This variable blocks triggering in global producttile click event (in .product-tile.card click event)
        sliderProductTiles = true;

        productSlider = true;
        submitEvent($(this));
    });

    // Filters - FILTER OPTIONS
    $(document).on("applyFilter", function (e, _this) {
        var dataEvent = $(_this).data("event");

        if (dataEvent == "orderReceipt" || $(_this).hasClass("address-delete")) {
            return;
        }

        if (!($(_this).closest(".accordion.filters-accordion").length > 0)) {
            return;
        }

        var clickState = $(_this).data("click-state");

        if (clickState) {
            $(_this).data("click-state", false);
            $(_this).data("event", "filterApplied");
            submitEvent($(_this));
        }
    });

    // Register franchise category slider tile click
    $(document).on("click", ".franchises-category-slider .category-tile", function () {
        var sliderNumber = $(this).closest(".slick-slide").data("slick-index") + 1;
        var brandName = $(this).data("brand");

        tc_events_100(this, "clickevent", {
            ACTION: "category slider - slot " + sliderNumber + " - go to listing page - " + brandName
        });
    });

    // Register franchise category slider CTA click
    $(document).on("click", ".franchises-category-slider .franchise-category-cta", function () {
        tc_events_100(this, "clickevent", {
            ACTION: "category slider - go to listing page - see all franchises"
        });
    });

    // CLP product show more and buy
    $(document).on("click", ".search-result-content .card-overlay a.button", function () {
        $(this).data("event", "clpEvent");
        targetEvent = "CLP";
        submitEvent($(this));
    });

    // Apply filters - for upc
    $(document).on("upcFilter", function (e) {
        var $target = $(e.target);
        var tc100 = $target && $target.data("tc100") ? $target.data("tc100").toLowerCase() : "";
        var filterAction = $target.data("event");

        var actionValue = "";

        if (filterAction == "filterApplied") {
            actionValue = "listing page - filter applied - " + tc100;
        } else if (filterAction == "filterRemoved") {
            actionValue = "listing page - filter removed - " + tc100;
        }

        tc_events_100(this, "clickevent", {
            ACTION: actionValue
        });
    });

    if (enabledSubscriptionTagCommander) {
        // Uplay+ Gaming List
        if (isGamingListPageURL) {
            // CLP Join Uplay+ Product Tile button
            $cache.document.on("click", ".search-result-content button.open-ubisoft-pass-choice:not(.customer_subscribed), .search-result-content button.add-to-library,    .search-result-content button.start-playing-now", function () {
                $(this).data("event", "clpEvent");
                targetEvent = "CLP";
                submitEvent($(this));
            });

            // CLP Doubles and Triples CTAs buttons
            $cache.document.on("click", ".double .button, .triple .button", function () {
                var clicked,
                    divider,
                    slotType;
                var customerStatus = $cache.document.find(".customer_status").length > 0 ? $cache.document.find(".customer_status").data("subs-status") : "";

                if ($(this).closest(".pushes").hasClass("triple")) {
                    slotType = "triple";
                    clicked = $(".pushes.triple").index($(this).closest(".pushes")) + 1;
                    divider = 3;
                } else if ($(this).closest(".pushes").hasClass("double")) {
                    slotType = "double";
                    clicked = $(".pushes.double").index($(this).closest(".pushes")) + 1;
                    divider = 2;
                }

                if (slotType && clicked) {
                    var assetOrder = clicked % divider != 0 ? clicked % divider : divider;
                    var slotOrder = Math.ceil(clicked / divider);
                    var type,
                        action,
                        name,
                        getName = true;

                    switch ($(this).data("alt-text")) {
                        case Resources.BUTTON_SEE_MORE:
                            type = "go to product";
                            action = "see more";
                            break;

                        case Resources.BUTTON_JOIN_UPLAY_PLUS:
                            type = "get uplay plus";

                            if (customerStatus == "Inactive") {
                                action = "renew your plan";
                            } else {
                                action = "choose your plan";
                            }

                            getName = false;
                            break;

                        case Resources.BUTTON_ADD_TO_LIBRARY:
                            type = "uplay plus";
                            action = "add to library";
                            break;

                        case Resources.BUTTON_START_PLAYING_NOW:
                            type = "uplay plus";
                            action = "start playing now";
                            break;

                        case Resources.BUTTON_REACTIVATION:
                            type = "get uplay plus";
                            action = "renew your plan";
                            getName = false;
                            break;

                        default:
                            break;
                    }

                    if (getName) {
                        var data = $(this).closest(".product-tile").data("tc100");

                        if (data && typeof data != "string") {
                            var product = ("name" in data) ? data.name : "";
                            var edition = ("edition" in data) ? data.edition : ("editionName" in data) ? data.editionName : "";

                            name = product + " - " + edition;
                        }
                    }

                    var actionValue = slotType + " " + slotOrder + " - slot " + assetOrder + " - " + type + " - " + action;

                    if (name) {
                        actionValue += " - " + name;
                    }

                    sendEvent("clickevent", actionValue, this);
                }
            });

            // CLP Uplay+ Products slide button
            $cache.document.on("click", ".category-product-slider .see-more-btn, .category-product-slider .open-ubisoft-pass-choice, .category-product-slider   .add-to-library, .category-product-slider .start-playing-now", function (e) {
                var btnParent = $(this).closest(".grid-tile");

                btnParent.data("event", "sliderAction");
                btnParent.data("tc100", $(this).data("alt-text"));

                targetEvent = e.target;
                sliderProductTiles = true;

                productSlider = true;
                submitEvent(btnParent);
            });

            // Uplay+ Games List 'How to Play' button
            $cache.document.on("click", ".tutorial-btn", function () {
                tc_events_100(this, "clickevent", {
                    ACTION: "hero banner - slider 1 - CTA - how to play"
                });
            });

            // Uplay+ Games List 'How to Play' modal's close button
            $cache.document.on("click", "#tutorial-modal .close-button", function () {
                tc_events_100(this, "clickevent", {
                    ACTION: "hero banner - slider 1 - CTA - how to play - close pop-in"
                });
            });

            // Uplay+ Games List 'How to Play' modal's 'Got It' button
            $cache.document.on("click", "#tutorial-modal .js-close-this-reveal", function () {
                tc_events_100(this, "clickevent", {
                    ACTION: "hero banner - slider 1 - CTA - how to play - get it"
                });
            });
        }

        // UPLay+ Scroll Tracking
        if (pageContext && (pageContext.ns === "subscription" || pageContext.ns === "earlyregistration")) {
            var sections = {
                ".ubiP-hero": "hero banner", // Hero Banner section
                "#whatisit": "what is uplay", // what is uplay plus section
                "#worldGames": "uplay plus library", // uplay plus library section
                "#cards": "program benefits", // program benefits section
                "#fan": "bottom page banner" // bottom page banner section
            };

            // Handle the ScrollTo Buttons
            $("[data-scrollto]").on("click", function () {
                var element = $(this).data("scrollto");

                if (sections[element]) {
                    fromClick = element;
                }
            });

            // Handle the Scroll Event
            $(window).on("scroll", function () {
                if (!fromClick) {
                    for (var key in sections) {
                        if (Object.prototype.hasOwnProperty.call(sections, key)) {
                            checkScrollEvent($(key), sections[key]);
                        }
                    }
                } else {
                    checkScrollEvent($(fromClick), sections[fromClick]);
                }
            });
        }

        // CLP Order Confirmation Uplay+ Product Tile buttons
        if (pageContext && pageContext.ns === "orderconfirmation") {
            $cache.document.on("click", ".category-product-slider .see-more-btn, .category-product-slider .add-to-library, .category-product-slider .start-playing-now", function (e) {
                var btnParent = $(this).closest(".grid-tile");

                btnParent.data("event", "sliderAction");
                btnParent.data("tc100", $(this).data("alt-text"));

                targetEvent = e.target;
                sliderProductTiles = true;

                productSlider = true;
                submitEvent(btnParent);
            });
        }
    }

    // ALGOLIA - ALL PRODUCTTILES ON ALGOLIA SEARCH LAYER
    $cache.document.on("click", ".algolia-producttile-card", function (event) {
        // Ignores - OUT OF STOCK BUTTONS
        if ($(event.target).is("span") && $(event.target).parent().hasClass("out-of-stock")) {
            event.preventDefault();

            return;
        }

        // If there is a click on white sections of producttiles and it doesn't have a button class name.
        if (!$(event.target).hasClass("button") && $(event.target).hasClass("card-details") || $(event.target).parent().closest(".card-details").length > 0) {
            var $productTileCard = $(event.target).parent().parent().closest("div.algolia-producttile-card");

            if ($productTileCard.length > 0) {
                var $seeMoreBtn = $productTileCard.find(".see-more-btn").length > 0 ? $productTileCard.find(".see-more-btn") : "";

                if ($seeMoreBtn != "") {
                    $seeMoreBtn.trigger("click");

                    var href = $seeMoreBtn.attr("href");

                    if ($seeMoreBtn.length > 0) {
                        window.location.href = href;
                    }
                }
            }

            return;
        }
    });

    $("#main").on("click", ".see-more-btn", function (event, stopPropagation) {
        const $this = $(this);
        let href = "";

        if (isProductListing) {
            triggerCLPTile($this, true);
        }

        if (stopPropagation) {
            event.stopPropagation();

            if ($this.hasClass("see-more-btn")) {
                href = $this.attr("href");
            } else {
                href = $this.find(".see-more-btn").attr("href");
            }

            if (href) {
                window.location.href = href;
            }
        }
    });

    // ALL PRODUCTTILE SECTIONS - PDP/CAROUSELS, CLP, TRIPLE SECTION. (without any button)
    $cache.document.on("click", "div.product-tile.card", function (event) {
        const $this = $(this);
        const $target = $(event.target);

        // Ignores - Add-to-cart pop up SEE MORE button for offer products
        if ($target.parent().closest(".product-added-popup-offer").length > 0) {
            return;
        }

        // Ignores - Add-to-cart SEE MORE button on Cartpage Einstein products
        if (pageContext.ns === "cartrevamp" && $target.parent().closest("[id^='cq_recomm_slot-']").length > 0) {
            return;
        }

        // Ignores - OUT OF STOCK BUTTONS
        if ($target.is("span") && $target.parent().hasClass("out-of-stock")) {
            return;
        }

        // Ignores - navigation section special offer tiles
        if ($target.parent().closest(".navigation-offer-section").length > 0) {
            return;
        }

        // detect and prevent execution if the add to cart button is pressed from push slots
        if (($target.is("button") || $target.is("a")) && $target.hasClass("product-price-shopnow")) {
            return;
        }

        // Blocks triggering for slider producttiles. They will be triggered in sliderAction.
        if (sliderProductTiles) {
            return;
        }

        // Avoid duplicate events on see more buttons on upc
        if ($target.hasClass("see-more-btn-upc") && enabledUPCSubscriptionTagCommander && pageContext && pageContext.ns == "ubisoftpasssubscription") {
            return;
        }

        const $seeMoreBtn = $this.find(".see-more-btn:visible");

        // HOMEPAGE - Triple producttiles
        if ($this.closest(".pushes").hasClass("double") || $this.closest(".pushes").hasClass("triple")) {
            if (!$target.hasClass("add-to-wishlist")) {
                tripleTileSection = (!$target.hasClass("button") && $target.parents(".activate-start-playing").length) ? true : false;
                $tripleTileEventTarget = $target;
                $this.data("event", "slotAction");
                submitEvent($this);

                if (isUpc) {
                    if (!$target.closest(".grid-actions").length > 0) {
                        redirectToProductURL($target);
                    }
                } else {
                    if ($target.hasClass("card-overlay") || !tripleTileSection) {
                        redirectToProductURL($target);
                    }
                }

                return;
            }
        }

        // If there is a click on white sections of producttiles and it doesn't have a button class name.
        if (!$target.hasClass("button") && $target.hasClass("card-details") || $target.closest(".card-details").length > 0 || $target.closest(".card-overlay").length > 0) {
            var $productTileCard = $target.closest("div.product-tile.card").not(".wishlist-product-tile, .indie-product-tile");

            if ($productTileCard.length > 0) {
                if ($seeMoreBtn.length) {
                    $seeMoreBtn.trigger("click", true);
                }
            }

            return;
        }

        // CLP - Producttiles
        if (event.currentTarget !== event.target && !$target.hasClass("button") && (pageContext.ns == "search" || pageContext.ns == "universalcat" || pageContext.ns == "storefront")) {
            $seeMoreBtn.trigger("click", true);

        // PDP/CAROUSELS - carousels producttiles
        } else if (event.currentTarget !== event.target && !$target.hasClass("button") && $(event.target).parent().closest("#pdp-tabs").length > 0) {
            $seeMoreBtn.trigger("click", true);

        // Ignores the click events of cross-sells producttiles of add to cart pop-up.
        } else if (event.currentTarget !== event.target && !$target.hasClass("button") && $(event.target).parent().closest(".product-added-popup-offer").length > 0) {
            return;
        }
    });

    // Einstein cross-sells producttiles
    $("div#main").on("click", "div.enhanced-slider-einstein a", function (event) {
        var $this = $(this);
        var dataInfo = $this.data("tc100");

        var einsteinContainer = $(event.target).closest(".enhanced-slider-einstein .slick-slide").length > 0 ? $(event.target).closest(".enhanced-slider-einstein .slick-slide") : $(event.target).closest(".enhanced-slider-einstein .grid-tile") ;
        var clickedSlot = $(event.target).closest(".slick-slide").length > 0 ? $(event.target).closest(".slick-slide") : $(event.target).closest(".grid-tile");

        if (!einsteinContainer.length && !clickedSlot.length && !dataInfo) {
            return;
        }

        var divider = einsteinContainer.length;
        var clicked = clickedSlot.data("slick-index") + 1 || clickedSlot.index();
        var slotOrder = (divider && clicked) ? Math.ceil(clicked / divider) : 1;
        var edition = dataInfo.editionName ? dataInfo.editionName.toLowerCase() : dataInfo.edition.toLowerCase();
        var clickedSlotRecommender = $this.closest("div.enhanced-slider-einstein");
        var recommenderName = clickedSlotRecommender && clickedSlotRecommender.data("recommender-name") ? clickedSlotRecommender.data("recommender-name").replace("-", " ") : "";
        var actionValue = "reco einstein - " + recommenderName + " - slot " + slotOrder + " - go to product - see more - " + dataInfo.productName.toLowerCase() + " - " + edition;

        sendEvent("clickevent", actionValue, $this);
    });

    // Register click event on Focus Banner CTA
    $cache.document.on("click", ".c-focus-banner__cta-inner", e => {
        const $this = $(e.currentTarget);
        const dataInfo = $this.data("tc100");
        let actionValue;

        if (dataInfo?.productName && dataInfo?.editionName) {
            actionValue = "focus banner - go to product - " + dataInfo.productName + " - " + dataInfo.editionName;
        } else if (dataInfo?.categoryName) {
            actionValue = "focus banner - go to listing page - " + dataInfo.categoryName;
        } else if (dataInfo?.customLink) {
            const customLinkRegEx = /^(?:https?:\/\/)?(?:www\.)?/i;

            if (dataInfo.customLink.match(customLinkRegEx)) {
                dataInfo.customLink = dataInfo.customLink.replace(customLinkRegEx, "");
            }

            actionValue = "focus banner - go to web page - " + dataInfo.customLink;
        }

        if (actionValue) {
            sendEvent("clickevent", actionValue, $this);
        }
    });

    // ADD-TO-CART - cross-sells producttiles sections except see more button.
    $cache.document.on("click", ".thumb-link", function (event) {
        // Ignores - OUT OF STOCK BUTTONS
        if ($(event.target).is("span") && $(event.target).parent().hasClass("out-of-stock")) {
            return;
        }

        // Clicks on producttile of algolia search layer
        if ($(this).hasClass("algolia-hit-producttile")) {
            const $suggestionHints = $("#suggest-hints");

            if ($suggestionHints.is(":visible")) {
                algoliaSearchEvent($(this), "might_be_interested_in");
            } else {
                algoliaSearchEvent($(this), "click_on_a_result");
            }
        }

        // Ignores - navigation section special offer tiles
        if ($(event.target).parent().closest(".navigation-offer-section").length > 0) {
            return;
        }

        if (!$(event.target).hasClass("button") && $(event.target).parent().closest(".product-added-popup-offer").length > 0) {
            $(this).data("event", "addToCart");
            targetEvent = "offerProductsForPopUp";
            submitEvent($(this));
        }
    });

    // Register click event on category triple push from homepage
    $(".category-asset-wrap").on("click", function () {
        var slotOrder = "";
        var assetOrder = "";
        var goTo = "";
        var name = "";
        var clicked = $(".pushes.triple").index($(this).closest(".pushes")) + 1;

        slotOrder = Math.ceil(clicked / 3);
        assetOrder = clicked % 3 != 0 ? clicked % 3 : 3;
        goTo = $(this).data("isbrand") ? "brand page" : "listing page";
        name = $(this).find(".category-wrap").attr("title");

        tc_events_100(this, "clickevent", {
            ACTION: "triple " + slotOrder + " - slot " + assetOrder + " - go to " + goTo + " - " + name.toLowerCase()
        });
    });

    // You may also like section on confirmation page
    $("#you-may-like-section-confirmation").on("click", ".card-overlay a.button", function () {
        $(this).data("event", "clpEvent");
        targetEvent = "Confirmation";
        submitEvent($(this));
    });

    $(".no-hits-slider").on("click", ".card-overlay a.button", function () {
        $(this).data("event", "clpEvent");
        targetEvent = "Cross sell";
        submitEvent($(this));
    });

    // Header search tracking event
    $(document).on("tagCommanderSearch", '.header-search-form input[name="q"]', function () {
        $(this).data("event", "searchValidation");
        submitEvent($(this));
    });

    // Content type selection tracking
    $(document).on("change", ".content-type-options input", function () {
        if (!$(this).parents().find("div.content-type-options").hasClass("clp-content-types") || $(this).hasClass("algolia-content-checkbox")) {
            return;
        }

        $(this).data("event", "filterContentTypeApplied");
        submitEvent($(this));
    });

    // REMOVE ALL FILTER OPTIONS
    $(document).on("click", "#clearAllFilters", function () {
        var clickState = $(this).data("click-state");

        if (clickState) {
            $(this).data("click-state", false);
            $(this).data("event", "filterRemoved");
            submitEvent($(this));
        }
    });

    // REMOVE A FILTER OPTION
    $(document).on("removeFilter", function (e, _this) {
        var clickState = $(_this).data("click-state");

        if (clickState) {
            $(_this).data("click-state", false);
            $(_this).data("event", "filterRemoved");
            submitEvent($(_this), "all filters");
        }
    });

    // Register custom event for apply promo code
    $(document).on("applyCouponCode", "#add-coupon, .js-add-promocode", function () {
        submitEvent($(this));
    });

    // Register custom event for checkout-points-message slot
    $(document).on("click", ".order-total-row.promo-code a", function () {
        tc_events_100(this, "clickevent", {
            ACTION: "checkout - navigation - get 20% discount"
        });
    });

    $(".language-selector").on("selectric-open selectric-select", function (e) {
        $(this).data("event", "languageSelection");
        targetEvent = e;
        submitEvent($(this));
    });

    // Sort by click tracking
    $(document).on("selectric-select", "#grid-sort-header", function () {
        $(this).data("event", "sortBy");
        submitEvent($(this));
    });

    // Register click events on error page
    $(document).on("click", ".go-home.tag-commander-event", function () {
        submitEvent($(this));
    });

    // Register click events on error page UPLAY
    $(document).on("click", "div.error-details a, .web-404-text a", function () {
        $(this).data("event", "goHome");
        submitEvent($(this));
    });

    // Add-to-cart pop up QTY decrement icon
    $(document).on("click", "#qty-decrement", function () {
        var parentDiv = $(this).parents("#product-added-popup");

        if (parentDiv.length > 0) {
            $(this).data("event", "clpEvent");
            targetEvent = "qtyButtonDecrement";
            submitEvent($(this));
        }
    });

    // Add-to-cart pop up QTY increment icon
    $(document).on("click", "#qty-increment", function () {
        var parentDiv = $(this).parents("#product-added-popup");

        if (parentDiv.length > 0) {
            $(this).data("event", "clpEvent");
            targetEvent = "qtyButtonIncrement";
            submitEvent($(this));
        }
    });

    // Add-to-cart pop up dropdown component to change the platform
    $(document).on("click", ".js-product-attribute-value", function () {
        var parentDiv = $(this).parents("#product-added-popup");

        if (parentDiv.length > 0) {
            $(this).data("event", "cartChangePlatform");
            targetEvent = "changePlatformPopUp";
            submitEvent($(this));
        }
    });

    // Add-to-cart pop up SEE MORE button for offer products
    $(document).on("click", ".only-on-list.show-for-large", function () {
        var parentDiv = $(".only-on-list.show-for-large").parents(".product-added-popup-offer");

        if (parentDiv.length > 0) {
            var _this = $(this).find("a");

            $(_this).data("event", "addToCart");
            targetEvent = "offerProductsForPopUp";
            submitEvent($(_this));
        }
    });

    // Register uplay media tab light box arrow events
    $(document).on("click", ".lb-prev, .lb-next", function () {
        var img = $(this).closest(".lb-container").find(".lb-image").attr("src"),
            currElm = parseInt($(".media-items").find('a[href="' + img + '"]').data("tc100"), 10),
            nextElm;

        if ($(this).hasClass("lb-prev")) {
            nextElm = $('a[data-tc100="' + (currElm - 1) + '"]');
        } else if ($(this).hasClass("lb-next")) {
            nextElm = $('a[data-tc100="' + (currElm + 1) + '"]');
        }

        nextElm.data("clickType", "arrow");
        submitEvent(nextElm);
    });

    // Register click events on my account
    $cache.document.on("click", ".my-account-menu a, .user-link .up-login, .user-link .login, .user-link .myaccount, .header-user-menu:not(.logout) .user-navigation-item:not(.logout)", function () {
        $(this).data("event", "accountClick");
        submitEvent($(this));
    });

    // Register click event on View Order Details CTA
    $cache.document.on("click", ".unlocked-accordion .accordion-toggle", event => {
        const $target = $(event.target);

        if ($target.parent(".founder-tooltip-content").length) {
            return;
        }

        event.preventDefault();

        const $this = $(event.currentTarget);

        if ($this.find(".account-subscription-billing-actions").length > 0) {
            return;
        }

        // Ignores - Product Listing and Search page filters
        if ($target.closest(".filters-accordion").length > 0) {
            return;
        }

        tc_events_100($this, "clickevent", {
            ACTION: "orders history - action - view more details"
        });
    });

    // Logout click event on account tooltip
    $cache.document.on("click", "#logout", function () {
        $(this).data("event", "accountClick");
        submitEvent($(this));
    });

    // Register click events on breadcrumb
    $cache.document.on("click", ".breadcrumb-element", function () {
        $(this).data("event", "breadcrumbClick");
        submitEvent($(this));
    });

    // Register click event on "home" link of breadcrumb
    $cache.document.on("click", ".breadcrumb-home-element", function () {
        $(this).data("event", "breadcrumbClick");
        submitEvent($(this));
    });

    // Triggers the click event for double navigation menu's (top navigation's) category items.
    // "data-cat-name" needs to be contributed from content asset body (via a href element)
    $cache.document.on("click", ".primary-header-menu a", function () {
        var $this = $(this);
        var catName = $this.data("cat-name") != undefined && $this.data("cat-name") != "" ? $this.data("cat-name").toLowerCase() : null;

        if (catName != null) {
            var actionValue = "header - top menu - " + catName;

            tc_events_100(this, "clickevent", {
                ACTION: actionValue
            });
        }
    });

    // Triggers the click events of special offer products (producttiles) from category navigation layer.
    $cache.document.on("click", ".tag-free-offer-producttiles", function (event, data) {
        var tcValue = data != null && data != undefined ? data.tc100 : null;
        var productName = tcValue != null && tcValue != undefined ? tcValue.productName.toLowerCase() : null;

        if (productName != null) {
            var actionValue = "header - menu - push product: go to product :" + productName;

            tc_events_100(this, "clickevent", {
                ACTION: actionValue
            });
        }
    });

    // Register click events on club reward banner only
    $(document).on("click", ".header-banner a, .header-banner-container a", function (e) {
        if (!$(e.target).hasClass("cta")) {
            $(this).data("event", "clubClick");
            submitEvent($(this));
        }
    });

    // Register cart event for remove from cart button
    $(document).on("click", ".cart-remove-btn:not(.check-for-tagging-click)", function () {
        $(this).data("event", "addRemoveCart");
        $(this).data("tc100", $(this).data("tc100cart"));
        submitEvent($(this));
    });

    // Register the search event
    $(document).on("submit", '[role="search"]', function () {
        var button = $('form[role="search"] button');

        button.data("event", "searchValidation");
        submitEvent(button);
    });

    // Register global layer changed event
    $cache.document.on("globalLayerChanged.tagcommander", function () {
        $(this).data("event", "globalLayerChanged");
        submitEvent($(this));
    });

    // Register click event on my account page for edit account information
    $("#personal-info").on("click", ".edit-btn", function () {
        wa_data.pageName = "edit account information";
        if (isGlobalLayerChanged()) $(document).trigger("globalLayerChanged");
    });

    // Register click event on my account page for create new address
    $("#personal-info").on("click", ".address-create", function () {
        wa_data.pageName = "create a new address";
        if (isGlobalLayerChanged()) $(document).trigger("globalLayerChanged");
    });

    // Register click event on my account page for edit address
    $("#personal-info").on("click", ".address-edit", function () {
        wa_data.pageName = "edit my address";
        if (isGlobalLayerChanged()) $(document).trigger("globalLayerChanged");
    });

    // Register click event on my account page for delete address
    $("#personal-info").on("click", ".address-delete", function () {
        wa_data.pageName = "delete my address";
        if (isGlobalLayerChanged()) $(document).trigger("globalLayerChanged");
    });

    // Register click event on my account page - order history for clicks on FAQ/Help
    $("#account-order-history").on("click", ".need-help-anchor", function () {
        $(this).data("event", "orderFAQ");
        submitEvent($(this));
    });

    // Register click event on my account page - order history for clicks on Download Invoices
    $("#account-order-history").on("click", ".download-invoice", function () {
        $(this).data("event", "orderReceipt");
        submitEvent($(this));
    });

    $cache.document.on("click", "#homepage-category-product-slider", function () {
        $(this).data("event", "discoverLinkEvent");
        submitEvent($(this));
    });

    // Register click event on my account page for track your order
    $cache.document.on("click", ".tracking-info a", function () {
        $(this).data("event", "orderTrack");
        submitEvent($(this));
    });

    $cache.document.on("click", ".age-restriction-go-back-home", function () {
        $(this).data("event", "ageGateGoBackHome");
        submitEvent($(this));
    });

    // Register click event from free offer popup CTAs
    $(document).on("click", ".freeoffer-buttons-wrapper .button-wrapper a", function () {
        if ($(this).attr("data-event") == "launchButtonConfirmationPage") {
            tc_events_100(this, "clickevent", {
                ACTION: "product info - interaction - Launch Uplay"
            });
        } else {
            tc_events_100(this, "clickevent", {
                ACTION: "product info - interaction - Download Uplay"
            });
        }
    });

    // Register pageevent an click event for platform selection on close beta
    $(document).on("platformSelection", function () {
        var $platforms = $(document).find(".closed-beta-platforms-list .platform-label");

        wa_data.registrationFlow = "platform selection";

        $platforms.each(function () {
            if ($(this).hasClass("platform-active")) {
                var inputValue = $(this).find("input").val();
                var platform = "";

                if (inputValue === "pcdl") {
                    platform = "PC";
                } else if (inputValue === "ps4") {
                    platform = "PS4";
                } else {
                    platform = "XONE";
                }

                wa_data.platformSelection = platform;
                tc_events_100(this, "clickevent", {
                    ACTION: "product info - registration - registration on " + platform
                });
            }
        });
        $(document).trigger("globalLayerChanged");
    });

    // // Register pageevent an click event for registration on close beta
    $(document).on("click", ".closed-beta-cta", function () {
        wa_data.registrationFlow = "validate your email";
        $(document).trigger("globalLayerChanged");

        if (document.cookie.indexOf("user_name") !== -1) {
            tc_events_100(this, "clickevent", {
                ACTION: "product info - registration - confirm email"
            });
        } else {
            tc_events_100(this, "clickevent", {
                ACTION: "product info - registration - login"
            });
        }
    });

    // Register pageevent for successful registration on close beta
    $(document).find("#closedBetaConfirmation").on("open.zf.reveal", function () {
        wa_data.registrationFlow = "registration successful";
        $(document).trigger("globalLayerChanged");
    });

    // Click on another edition
    $(document).on("click", ".pdp-main .editions-list a,.pdp-main .dlc-types-list a", function (e) {
        var selectedEditionInfo = $(e.target).data("tc100");

        if (selectedEditionInfo) {
            tc_events_100(this, "clickevent", {
                ACTION: selectedEditionInfo
            });
        } else if ($(e.target) && $(e.target).parent()) {
            // in case DLS product
            var selectedDLCEditionInfo = $(e.target).parent().data("tc100");

            if (selectedDLCEditionInfo) {
                tc_events_100(this, "clickevent", {
                    ACTION: selectedDLCEditionInfo
                });
            }
        }
    });

    // Click on another platform
    $(document).on("click", ".platforms-list a", function (e) {
        var selectedPlatformInfo = $(e.target).data("tc100");

        if (selectedPlatformInfo) {
            tc_events_100(this, "clickevent", {
                ACTION: selectedPlatformInfo
            });
        }
    });

    // Click on another platform
    $(document).on("click", ".versions-list a", function (e) {
        var selectedPlatformInfo = $(e.target).data("tc100");

        if (selectedPlatformInfo) {
            tc_events_100(this, "clickevent", {
                ACTION: selectedPlatformInfo
            });
        }
    });

    $(document).on("click", ".pdp-cta [data-open^=free-offers-overlay]", function () {
        tc_events_100(this, "clickevent", {
            ACTION: "product info - action - get the free game"
        });
    });

    $(document).on("click", ".start-playing-cta, start-playing-sticky-cta", function () {
        tc_events_100(this, "clickevent", {
            ACTION: "product info - ownership - start playing now"
        });
    });

    $(document).on("click", "#bought-game-overlay .close-free-offers-overlay", function () {
        if ($(this).attr("data-event") == "uplayDownloadButtonConfirmationPage") {
            tc_events_100(this, "clickevent", {
                ACTION: "product info - ownership - Download Uplay"
            });
        }
    });

    $(document).on("click", "#bought-game-overlay .close-bought-game-overlay", function () {
        if ($(this).attr("data-event") == "launchButtonConfirmationPage") {
            tc_events_100(this, "clickevent", {
                ACTION: "product info - ownership - launch Uplay"
            });
        }
    });

    $(document).on("start-playing-now", function (e, $button) {
        if ($button.attr("data-open") == "bought-game-overlay") {
            $cache.document.trigger("updateTcEventBuffer", {
                this: this,
                eventType: "clickevent",
                action: {
                    ACTION: "pop in - ownership - start playing now"
                }
            });
        }
    });

    $(document).on("click", ".order-confirmation-product .product-actions .button", function (e) {
        var isSubscriptionConfirmationPage = $cache.document.find("div.subscription-product-details").length > 0 ? true : false;

        if (pageContext.ns == "orderconfirmation" && isSubscriptionConfirmationPage) {
            return;
        }

        var actionValue = $.trim($(e.target).data("alt-text")).toLowerCase();

        if (actionValue) {
            tc_events_100(this, "clickevent", {
                ACTION: "thank you page - CTA - " + actionValue
            });
        }
    });

    // Event listener helps catching ajax response when a CRUD operation occurs for addresses
    $(document).ajaxComplete(function (event, xhr, settings) {
        var ajaxResult = {},
            action = {
                ACTION: "",
                SITESECTION: "my account",
                ERRORTYPE: ""
            },
            url = new URL(settings.url, this.location.href),
            responseJSON = xhr.responseJSON || null,
            responseSuccess = responseJSON ? responseJSON.success : null,
            responseError = responseJSON ? responseJSON.error : null,
            responseStatus = responseJSON ? responseJSON.status : null;

        if (isLegacy) {
            if (enabledUPCSubscriptionTagCommander) {
                return;
            }

            try {
                ajaxResult = JSON.parse(xhr.responseText);
            } catch (e) {
                // nothing to catch in case of ajax result is not related with page event
            }

            if (enabledUPCSubscriptionTagCommander && pageContext && pageContext.ns == "ubisoftpasssubscription") {
                return;
            }

            switch (ajaxResult.userAddress) {
                case "delete":
                    wa_data.pageName = "delete my address success";
                    break;
                case "add":
                    wa_data.pageName = "create a new address success";
                    break;
                case "edit":
                    wa_data.pageName = "edit my address success";
                    break;
            }
        } else { // if revamp
            // resets the variables for the click events of OPC ('save and continue' for shipping & billing sections)
            if (pageContext.ns != "opc") {
                isBillingSection = false;
                isShippingSection = false;
            }

            $cache.document.on("closed.zf.reveal", '[data-yeti-box="add-credit-card"], [data-yeti-box="manage-address"]', function () {
                wa_data.pageName = "dashboard";
            });

            if (isWalletOrder && isWalletTagCommanderEnabled) {
                var checkoutStep = $(".onepagecheckout").data("checkoutstep");

                if (checkoutStep == "summary") {
                    wa_data.pageName = "order confirmation";
                    wa_data.siteSection = "wallet - checkout pages";
                } else if (checkoutStep == "payment") {
                    wa_data.pageName = "billing";
                    wa_data.siteSection = "wallet - checkout pages";
                }
            }

            if (pageContext.ns == "orderconfirmation" && $cache.document.find(".header-underline.section-header").length > 0) {
                var confirmationPageSliderText = $cache.document.find(".header-underline.section-header").text();

                if (confirmationPageSliderText && confirmationPageSliderText.indexOf("Discover games compatibles") > -1) {
                    return;
                }
            }

            if (enabledSubscriptionTagCommander && (pageContext.ns == "product" || isGamingListPageURL || isUbiPassLandingPageURL)) {
                return;
            }

            // After product is added to cart reveal has been called more than once.
            // Use $cache.revealToken to trigger event only once.
            $cache.document.on("open.zf.reveal", '[data-yeti-box="product-added-popup"]', function () {
                if ($cache.revealToken) {
                    $cache.revealToken = false;
                    tc_events_100(this, "lightcartevent", {
                        ACTION: "added to cart - view the popup"
                    });
                }
            });

            // Release $cache.revealToken to trigger event
            $cache.document.on("closed.zf.reveal", '[data-yeti-box="product-added-popup"]', function () {
                $cache.revealToken = true;
            });

            if (window.pageContext.ns == "search" || window.pageContext.ns == "universalcat" || window.pageContext.ns == "storefront") {
                if (url.pathname.indexOf(Urls.addProduct) > -1 || url.pathname.indexOf(Urls.updateCartIcon) > -1 || url.pathname.indexOf(Urls.getAvailability) > -1) {
                    return;
                }
            }

            // Block unnecessary page event triggering.
            if (url.pathname.indexOf(Urls.tcBuildProductDataAjax) > -1 || url.pathname.indexOf(Urls.infoForTC) > -1 || url.pathname.indexOf(Urls.checkAgeRestrictionAjax) > -1) {
                return;
            }

            if ($(".is-subscription").length > 0 && $(".is-subscription").val() == "true" && pageContext.ns == "account") {
                return;
            }

            var $firstErrorField,
                errorFieldName;

            if (url.pathname.indexOf(Urls.myAccountAddAddress) > -1 || url.pathname.indexOf(Urls.getStatesList) > -1) {
                // if the event is a click event and ajax respone is null before the pop-up
                if (responseSuccess == null || url.pathname.indexOf(Urls.getStatesList) > -1) {
                    return;
                }

                if (responseSuccess) {
                    action.ACTION = "create new address success";
                } else if (responseError) {
                    action.ACTION = "create new address error";
                    action.ERRORTYPE = responseError;
                } else {
                    action.ACTION = "create new address";
                }
            } else if (url.pathname.indexOf(Urls.myAccountEditAddress) > -1) {
                // if the event is a click event and ajax respone is null before the pop-up
                if (responseSuccess == null) {
                    return;
                }

                if (responseSuccess) {
                    action.ACTION = "edit address success";
                } else if (responseError) {
                    action.ACTION = "edit address error";
                    action.ERRORTYPE = responseError;
                } else {
                    action.ACTION = "edit address";
                }
            } else if (url.pathname.indexOf(Urls.myAccountDeleteAddress) > -1) {
                if (responseStatus == "OK") {
                    action.ACTION = "delete address success";
                } else {
                    action.ACTION = "delete address error";
                    action.ERRORTYPE = responseError;
                }
            } else if (url.pathname.indexOf(Urls.myAccountDeleteCreditCard) > -1) {
                // if the event is a click event and ajax respone is null before the pop-up
                if (responseSuccess == null) {
                    return;
                }

                if (responseSuccess) {
                    action.ACTION = "delete credit card success";
                } else if (responseError) {
                    action.ACTION = "delete credit card error";
                    action.ERRORTYPE = responseError;
                } else {
                    action.ACTION = "delete credit card";
                }
            } else if (url.pathname.indexOf(Urls.myAccountAddCreditCard) > -1) {
                // if the event is a click event and ajax respone is null before the pop-up
                if (responseSuccess == null) {
                    return;
                }

                if (responseSuccess) {
                    action.ACTION = "add new credit card success";
                } else if (responseError) {
                    action.ACTION = "add new credit card error";
                    action.ERRORTYPE = responseError;
                }
            } else if (settings.url == Urls.newsletterSubscribe) {
                action.SITESECTION = "stay in the game";
                wa_data.siteSection = "stay in the game";

                if (responseJSON.ok) {
                    action.ACTION = "join now - successful";
                } else {
                    action.ACTION = "join now - error";
                    action.ERRORTYPE = responseJSON.error;
                }
            } else if (url.search.indexOf("shippingError") > -1) {
                $firstErrorField = $(".field-error").first().find(".error");

                if ($firstErrorField) {
                    errorFieldName = $firstErrorField.attr("id").split("_").pop().split("-")[0];

                    // split camel case name - ex. firstName
                    if (errorFieldName) {
                        errorFieldName = errorFieldName.replace(/([a-z](?=[A-Z]))/g, "$1 ").toLowerCase();
                    }

                    tc_events_100(this, "pageevent", {
                        ACTION: "shipping - error",
                        SITESECTION: "checkout pages",
                        ERRORTYPE: errorFieldName + " - " + $firstErrorField.text().toLowerCase()
                    });
                } else {
                    tc_events_100(this, "pageevent", {
                        ACTION: "shipping - error",
                        SITESECTION: "checkout pages",
                        ERRORTYPE: ""
                    });
                }
            } else if (url.search.indexOf("billing") > -1) {
                $firstErrorField = $(".field-error").first().find(".error");

                if ($firstErrorField.length > 0) {
                    if ($(".field-error").first().hasClass("cvn")) {
                        errorFieldName = "cvn";
                    } else {
                        errorFieldName = $firstErrorField.attr("id").split("_").pop().split("-")[0];

                        // split camel case name - ex. firstName
                        if (errorFieldName) {
                            errorFieldName = errorFieldName.replace(/([a-z](?=[A-Z]))/g, "$1 ").toLowerCase();
                        }
                    }

                    tc_events_100(this, "pageevent", {
                        ACTION: "billing - error",
                        SITESECTION: "checkout pages",
                        ERRORTYPE: errorFieldName + " - " + $firstErrorField.text().toLowerCase()
                    });
                } else {
                    tc_events_100(this, "pageevent", {
                        ACTION: "billing - error",
                        SITESECTION: "checkout pages",
                        ERRORTYPE: ""
                    });
                }
            } else if (!isShippingSection && (url.pathname.indexOf("OnePageCheckout-Return") > -1 || url.pathname.indexOf("Mercury-Payment") > -1 || url.pathname.indexOf("Address-RetrieveStates") > -1 || url.pathname.indexOf("ProductRestriction-CheckBasketJson") > -1)) {
                isShippingSection = false;

                return;
            } else if (isBillingSection && $(".ibex-total-value").length) {
                wa_data.siteSection = "rocksmith plus - checkout pages";
            }

            // stops to trigger unnecessary page events for the buttons of OPC ('save and continue' - billing sections).
            if (isBillingSection && $(".opc-submit:not(:disabled)").length < 1) {
                return;
            }

            // send data if there is an action related with my account page
            if (action.ACTION != "") {
                wa_data.pageName = action.ACTION;
                tc_events_100(this, "pageevent", action);

                return;
            }

            // block unnecessary page event triggering after ajax call
            if (url.pathname.indexOf(Urls.getAvailabilityData) > -1 || url.pathname.indexOf(Urls.getPricingHelperUrl) > -1
                || url.pathname.indexOf(Urls.getProductInfoJson) > -1 || url.pathname.indexOf(Urls.getLoyalty) > -1
                || url.pathname.indexOf(Urls.getProductUrl) > -1) {
                return;
            }
        }

        events.updateProductListMeasurement(responseJSON);
    });

    if (isLegacy) {
        // Register click event on my payment page for add card
        $("#payment-instruments").on("click", ".add-card", function () {
            wa_data.pageName = "add a new card";
            if (isGlobalLayerChanged()) $(document).trigger("globalLayerChanged");
        });

        // Register click event on my payment page for edit card
        $("#payment-instruments").on("click", ".payment-edit.modify-card-cta", function () {
            wa_data.pageName = "edit my card";
            if (isGlobalLayerChanged()) $(document).trigger("globalLayerChanged");
        });

        // Register click event on my payment page for delete card
        $("#payment-instruments").on("click", ".payment-delete", function () {
            wa_data.pageName = "delete my card";
            if (isGlobalLayerChanged()) $(document).trigger("globalLayerChanged");
        });

        /* eslint-disable no-undef */
        var cardOperation = typeof cardOperationResult !== "undefined" && typeof cardOperationResult === "string" ? cardOperationResult : "";

        if (cardOperation != "") {
            switch (cardOperation) {
                case "cardAdded":
                    wa_data.pageName = "add a new card success";
                    break;
                case "cardDeleted":
                    wa_data.pageName = "delete my card success";
                    break;
                case "cardEdited":
                    wa_data.pageName = "edit my card success";
                    break;
            }

            if (isGlobalLayerChanged()) $(document).trigger("globalLayerChanged");
        }
        /* eslint-enable no-undef */
    } else {
        // Register click event on my account page for add card
        $("#account-payment-information").on("click", ".add-card-wrapper", function () {
            tc_events_100(this, "pageevent", {
                ACTION: "add new credit card",
                SITESECTION: "my account",
                ERRORTYPE: ""
            });
            wa_data.pageName = "add new credit card";
        });

        // Register click event on my account page for add address
        $("#account-address").on("click", ".add-address-wrapper", function () {
            tc_events_100(this, "pageevent", {
                ACTION: "create new address",
                SITESECTION: "my account",
                ERRORTYPE: ""
            });
            wa_data.pageName = "create new address";
        });

        // Register click event on my account page for delete card
        $("#account-payment-information").on("click", "button.credit-card-delete", function () {
            tc_events_100(this, "pageevent", {
                ACTION: "delete credit card",
                SITESECTION: "my account",
                ERRORTYPE: ""
            });
            wa_data.pageName = "delete credit card";
        });

        // Register click event for "Join Now" button placed in the footer
        $cache.document.on("click", "button.joinnow", function () {
            tc_events_100(this, "pageevent", {
                ACTION: "join now",
                SITESECTION: "stay in the game",
                ERRORTYPE: ""
            });
        });
    }

    // Register click event on withdrawal page for submit button
    $("#dwfrm_withdrawal").on("click", ".sendBtn", function () {
        $(document).ajaxSuccess(function (event, xhr) {
            var withdrawalResult = JSON.parse(xhr.responseText);

            if (withdrawalResult.transactionSuccess == true) {
                wa_data.pageName = "withdrawal success";
            } else {
                wa_data.pageName = "withdrawal error";
                wa_data.errorType = withdrawalResult.message;
            }

            if (isGlobalLayerChanged()) $(document).trigger("globalLayerChanged");
        });
    });

    // Register click event on faq page for categories
    $(document).on("click", ".faq-nav-cat", function () {
        wa_data.pageName = $(this).data("category");
        if (isGlobalLayerChanged()) $(document).trigger("globalLayerChanged");
    });

    // Register click event on faq page for questions
    $(document).on("click", ".faq-question", function () {
        var faqName = $(".faq-nav-cat.active").data("category");
        var title = $(this).text().replace(/(\r\n|\n|\r)/gm, "").trim();

        if (wa_data.language == "en") {
            wa_data.pageName = faqName + " - " + title;
            if (isGlobalLayerChanged()) $(document).trigger("globalLayerChanged");
        } else {
            var data = {
                faqId: $(this).data("id")
            };

            $.ajax({
                type: "GET",
                url: Urls.tcFAQ,
                data: data,
                success: function (response) {
                    title = JSON.parse(response).title || "";
                    wa_data.pageName = faqName + " - " + title;
                    if (isGlobalLayerChanged()) $(document).trigger("globalLayerChanged");
                }
            });
        }
    });

    // Register click event on faq for Confirmation Page
    $(document).on("click", ".order-confirmation-help .button", function () {
        $(this).data("event", "faqButtonSearchPage");
        submitEvent($(this));
    });

    // Register click event on faq for Confirmation Page
    $(document).on("click", ".need-help .button", function () {
        $(this).data("event", "faqButtonSearchPage");
        submitEvent($(this));
    });

    // Register custom event on search suggestions
    $(document).on("suggestionClick", ".search-suggestion", function () {
        submitEvent($(this));
    });

    // Register click events on 'add a promo code' button for Cart Page
    $(document).on("click", ".js-add-promocode-btn", function () {
        tc_events_100(this, "clickevent", {
            ACTION: "cart - promo code - add a promo code"
        });
    });

    // Register click event on 'See more' button from Comparison Table(Product Page)
    $(document).on("click", ".see-more-button.tag-commander-event", function (event) {
        var compareInfo = $(event.target).data("tc100");

        if (compareInfo) {
            // split data('tc100') that has format: 'Assassin's Creed® Odyssey - Spartan Edition - Spartan Edition'
            var splittedCompareInfo = compareInfo.split(" - ");

            if (splittedCompareInfo.length > 1) {
                tc_events_100(this, "clickevent", {
                    ACTION: "compare edition - go to product - see more - " + splittedCompareInfo[0].trim() + " - " + splittedCompareInfo[1].trim()
                });
            }
        }
    });

    // Register click events on place order
    $(document).on("click", ".checkout-btn-section .continue-step", function () {
        $(this).data("event", "placeOrder");
        submitEvent($(this));
    });

    // Register click events on 'See and Edit Your Order In Your Account' button from confirmation order page
    $cache.document.on("click", ".order-confirmation-header .order-header-action a", function () {
        var confirmationPageSliderText = $cache.document.find(".header-underline.section-header").text();
        var actionValue;

        if (confirmationPageSliderText && confirmationPageSliderText.indexOf("Discover games compatibles") > -1) {
            actionValue = "thank you page - navigation - back to my account";
        } else {
            actionValue = "thank you page - navigation - go to your account";
        }

        if ($(this).hasClass("wallet-browse-catalog-button")) {
            return;
        }

        tc_events_100(this, "clickevent", {
            ACTION: actionValue
        });
    });

    // Catch right and left arrow clicks on PDP when reveal is open for media display.
    // Method also catches key events since it is originally bound in mediaSerection.js
    $cache.document.on("click", ".bm-icon-arrow-left, .bm-icon-arrow-right", function () {
        var currSrc = $("#media-images-reveal img").attr("src");
        var srcImg = $("#medias-section").find("img[src='" + currSrc + "']")[0];
        var evtVal = $(srcImg).attr("alt");
        var srcTrigger = $(srcImg).parents('[data-event="mediaTabAction"]')[0];

        events.mediaTabAction(srcTrigger, evtVal);
    });

    // Register click event on media's filter from PDP
    $cache.document.on("click", ".media-headers button.tag-commander-event", function (event) {
        var selectedMediaFilter = $(event.target).data("tc100");

        if (selectedMediaFilter) {
            tc_events_100(this, "clickevent", {
                ACTION: "media - media - filter - " + selectedMediaFilter
            });
        }
    });

    // Register click event on 'Save address' checkbox from checkout
    $cache.document.on("change", 'input[id*="addToAddressBook"]', function () {
        if ($(this).is(":checked")) {
            tc_events_100(this, "clickevent", {
                ACTION: "cart flow - checkbox - save this address"
            });
        } else {
            tc_events_100(this, "clickevent", {
                ACTION: "cart flow - checkbox - don't save this address"
            });
        }
    });

    // Register click event on 'Save credit card' checkbox from checkout
    $cache.document.on("change", 'input[id*="creditCard_saveCard"]', function () {
        if (!$(".subscription-payment-container").length) {
            if ($(this).is(":checked")) {
                tc_events_100(this, "clickevent", {
                    ACTION: "cart flow - checkbox - save this card"
                });
            } else {
                tc_events_100(this, "clickevent", {
                    ACTION: "cart flow - checkbox - don't save this card"
                });
            }
        }
    });

    // Register click events on checkout button from cart
    $cache.document.on("click", ".cart-section .checkout-btn", function () {
        tc_events_100(this, "clickevent", {
            ACTION: "cart - navigation - go to checkout"
        });
    });

    // Register click events on carousel arrows
    $cache.document.on("click", ".homepage-slider-wrapper button.slider-control", function () {
        if ($(this).hasClass("slider-control-next")) {
            tc_events_100(this, "clickevent", {
                ACTION: "carousel - interaction - next"
            });
        } else if ($(this).hasClass("slider-control-prev")) {
            tc_events_100(this, "clickevent", {
                ACTION: "carousel - interaction - previous"
            });
        }
    });

    // Register click events when user changes platform from Cart Page
    $(document).on("click", ".item-param-variation-list li", function (e) {
        var changePlatformInfo = $(e.target).data("tc100");

        if (changePlatformInfo) {
            // Check if user does not choose the same platform
            var oldAndNewPlatforms = changePlatformInfo.split("to");

            if (!(oldAndNewPlatforms.length > 1 && oldAndNewPlatforms[0].trim() === oldAndNewPlatforms[1].trim())) {
                tc_events_100(this, "clickevent", {
                    ACTION: "cart - change platform - select platform - " + changePlatformInfo
                });
            }
        }
    });

    // Register click events on 'continue shopping' button on empty cart
    $cache.document.on("click", ".cart-empty a", function () {
        tc_events_100(this, "clickevent", {
            ACTION: "empty cart - continue shopping - shop now"
        });
    });

    // ############## ALGOLIA CLICK EVENTS ##############

    $cache.document.on("click", "#algolia-price-slider-tag", function (e, data) {
        var $this = $(this);
        var selectedPriceValue = data !== null && data !== undefined ? data.selectedPriceValue : null;

        if (selectedPriceValue) {
            $this.attr("aria-valuenow", selectedPriceValue);

            algoliaSearchEvent($this, "using_filter");
        }
    });

    $cache.document.on("click", ".algolia-search-filter", function () {
        if (isUpc) {
            var $this = $(this).parent();

            algoliaSearchEvent($this, "using_filter");
        }
    });

    // Span element to trigger the search event when suggestions are shown on storefront.
    $cache.document.on("click", "#algolia-search-hints-tag", function () {
        var $algoliaSuggestionLayer = $(".container-fluid-algolia");

        if ($algoliaSuggestionLayer.hasClass("hide") || (isUpc && $algoliaSuggestionLayer.hasClass("visually-hidden"))) {
            algoliaSearchEvent($(this), "suggestions_shown");
        }

        algoliaSearchEvent($(this), "click_search_overlay");
    });

    // Algolia search layer close icon - click event
    $cache.document.on("click", ".c-algolia__close", function () {
        algoliaSearchEvent($(this), "close_search_layer");
    });

    // ALGOLIA - Search input event
    $cache.document.on("keyup", "#searchbox input", algoliaSearchDelay(function () {
        if (isAlgolia && $(this).val().length !== 0) {
            var $searchBox = $("#searchbox").data("is-result");

            if (!$searchBox) {
                algoliaSearchEvent($(this), "no_results");
            } else {
                // Search event - start typing
                algoliaSearchEvent($(this), "start_typing");
            }
        }
    }, 1000));

    // keep the current page name in the pageNameFrom attribute in order to be able to extract in at no results page
    $cache.document.on("click", "#searchbox input", function () {
        if (isAlgolia && $(this).val() === "" && wa_data.pageName !== "no results") {
            wa_data.pageNameFrom = wa_data.pageName;
        }
    });

    // Register click events on 'closing algolia search' button for WEB
    $cache.document.on("change", ".ais-SearchBox-input", function (e) {
        if (isAlgolia && !e.target.value) {
            var $searchBox = $(e.target).closest(".ais-SearchBox").find("input");

            $searchBox.data("algolia-search-open", false);
            $(this).data("event", "algoliaCloseSearch");

            submitEvent($(this));
        }
    });

    // Register click events on 'closing algolia search' button for UPC
    $cache.document.on("click", ".ais-SearchBox-reset", function (e, data) {
        if (isAlgolia) {
            var isSearchLayerClosed = data != null && data != undefined ? data.isSearchLayerClosed : null;
            var $searchBox = $(e.target).closest(".ais-SearchBox").find("input");

            // Algolia search input - clear icon
            if (!isSearchLayerClosed) {
                algoliaSearchEvent($(this), "clear_search_on_cross");
            }

            if ($searchBox.length > 0) {
                $searchBox.data("algolia-search-open", false);
            }

            $(this).data("event", "algoliaCloseSearch");

            submitEvent($(this));
        }
    });

    $cache.document.on("keyup autoCompletionUsed searchSuggestionUsed", "#searchbox input", algoliaSearchDelay(function (e, data) {
        if (isAlgolia && $(this).val().length != 0) {
            var isAutoCompletionUsed = data != null && data != undefined ? data.isAutoCompletionUsed : null;
            var autoCompletionValue = data != null && data != undefined ? data.autoCompletionValue : null;
            var isSearchSuggestionUsed = data != null && data != undefined ? data.isSearchSuggestionUsed : null;
            var searchSuggestionValue = data != null && data != undefined ? data.searchSuggestionValue : null;

            if (isAutoCompletionUsed) {
                algoliaSearchEvent($(this), "auto_completion_used", autoCompletionValue);
            } else if (isSearchSuggestionUsed) {
                algoliaSearchEvent($(this), "suggestions_used", null, searchSuggestionValue);
            }

            var $searchBox = $("#searchbox").data("is-result");

            if ($searchBox) {
                algoliaSearchEvent($(this), "result_offered", autoCompletionValue);
            }

            tc_events_100(this, "pageevent", {
                ACTION: window.wa_data.pageName,
                SITESECTION: window.wa_data.siteSection,
                SEARCHKEYWORD: window.wa_data.searchKeyword,
                SEARCHRESULTS: window.wa_data.searchResultsNumber
            });
        }
    }, 1000));

    // ALGOLIA - Filter item event
    $cache.document.on("click", '.refinement.accordion-element a:not(".selected")', function () {
        // checks if it is algolia filter section
        var listRefinement = $(this).parent().closest("div.list.refinement").length > 0;

        if (isAlgolia && listRefinement) {
            const $algoliaResults = $(this).parents().closest("#algolia-results");

            if ($algoliaResults.length) {
                algoliaSearchEvent($(this), "using_filter");
            }
        }
    });

    $cache.document.on("click", ".refinement.accordion-element a.selected", function () {
        var listRefinement = $(this).parent().closest("div.list.refinement").length > 0;

        if (isAlgolia && listRefinement) {
            $(this).data("event", "algoliaRemoveAppliedFilter");
            submitEvent($(this));
        }
    });

    // ALGOLIA - Filter item event for UPC
    $cache.document.on("click", '.refinement .refinement-group li:not(".selected")', function () {
        if (isAlgolia) {
            $(this).data("event", "algoliaAppliedFilter");
            submitEvent($(this));
        }
    });

    $cache.document.on("click", "#refinements-breadecrumb a.breadcrumbRefinement, .refinement .refinement-group li.selected", function (e, param) {
        if (!(param == "from-clear-all-filter") && isAlgolia) {
            $(this).data("event", "algoliaRemoveAppliedFilter");
            submitEvent($(this));
        }
    });

    // ALGOLIA - Displays list icon for listing the products on search layer
    $cache.document.on("click", "#algolia-display-list", function () {
        if (isAlgolia) {
            $(this).data("type", "list");
            $(this).data("event", "algoliaListType");
            submitEvent($(this));
        }
    });

    // ALGOLIA - Displays mosaic icon for listing the products on searc layer
    $cache.document.on("click", "#algolia-display-mosaic", function () {
        if (isAlgolia) {
            $(this).data("type", "mosaic");
            $(this).data("event", "algoliaListType");
            submitEvent($(this));
        }
    });

    // ALGOLIA - Selects/Unselects the checkbox icons of content type section on search layer
    $cache.document.on("click", 'input[class="algolia-content-checkbox"]', function () {
        if (isAlgolia) {
            $(this).data("event", "algoliaContentType");
            submitEvent($(this));
        }
    });

    // ALGOLIA - Selects/Unselects the checkbox icons of content type section on search layer for "all" checkbox
    $cache.document.on("click", ".check-all", function () {
        if (isAlgolia) {
            $(this).data("event", "algoliaContentType");
            submitEvent($(this));
        }
    });

    // ALGOLIA - Click event - when we remove an applied filter
    $("#refinements-breadecrumb").on("click", "a.breadcrumbRefinement", function (event, param) {
        if (!(param == "from-clear-all-filter") && isAlgolia) {
            $(this).data("event", "algoliaRemoveAppliedFilter");
            submitEvent($(this));
        }
    });

    // ALGOLIA - Triggers the page event to update, if there is no any search result.
    $cache.document.on("click", "#no-hits-search-term", function () {
        if (isAlgolia) {
            var algoliaSearchKeyword = $(this).text() ? $(this).text().trim() : "";
            var isNoHits = $("#algolia-nohits").is(":visible");

            if (isNoHits) {
                wa_data.pageName = "no results";
                wa_data.searchKeyword = algoliaSearchKeyword;
                wa_data.searchResultsNumber = "0";
            }
        }
    });

    // ALGOLIA - Triggers the page event to update, if there is a result.
    $cache.document.on("click", ".algolia-tag-hit-results", function () {
        if (isAlgolia) {
            if (algoliaSortBy) {
                algoliaSortBy = false;

                return;
            }

            var algoliaSearchKeyword = $(this).attr("data-search-keyword");
            var placeholder = Resources.ALGOLIA_SIMPLE_SEARCH_TEXT;
            var algoliaResultNumberLength = $(".stats-container").find("span").text().trim().length;
            var algoliaSearchResultNumber = algoliaResultNumberLength > 0 ? $(".stats-container").find("span").first().text().trim() : 0;

            if (algoliaSearchKeyword == placeholder || algoliaSearchKeyword == "") {
                return;
            }

            wa_data.pageName = "search result";
            wa_data.searchKeyword = algoliaSearchKeyword;
            wa_data.searchResultsNumber = algoliaSearchResultNumber;
        }
    });

    // ALGOLIA - Triggers if "clear all" link is clicked to remove all applied filters.
    $cache.document.on("click", ".clear-filters-algolia", function (event, param) {
        if (!(param == "from-close-search-box") && isAlgolia) {
            $(this).data("event", "algoliaRemoveAllFilters");
            submitEvent($(this));
        }
    });

    // ALGOLIA - "load more" button click event on algolia search layer
    $cache.document.on("click", "#show-more", function (event, param) {
        if (isAlgolia) {
            $(this).data("event", "algoliaLoadMoreResult");

            if (param && param == "autodisplay") {
                $(this).data("trigger", "autodisplay");
            }

            submitEvent($(this));
        }
    });

    // Triggers click events for see more buttons and producttile image clicks on algolia search layer of WEB. Triggers only see more button on algolia search layer of UPC .
    $cache.document.on("click", "a.algolia-hit-producttile, .algolia-upc-see-more", function () {
        if (isAlgolia) {
            var $this = $(this);

            if (isUpc) {
                const $suggestionHints = $("#suggest-hints");

                if ($suggestionHints.is(":visible")) {
                    algoliaSearchEvent($this, "might_be_interested_in");
                } else {
                    algoliaSearchEvent($this, "click_on_a_result");
                }
            }
        }
    });

    // ALGOLIA - tracks the buttons of producttile.
    $cache.document.on("click", ".algolia-producttile-card .button", function () {
        if (isAlgolia) {
            if ($(this).hasClass("see-more-btn")) {
                $(this).data("event", "algoliaProductListingSeeMore");
                submitEvent($(this));
            } else if ($(this).hasClass("add-to-cart")) {
                $(this).data("event", "addToCartCartEventPdp");
                submitEvent($(this));
            } else if ($(this).hasClass("buy-now-btn")) {
                algoliaTileButton = "algolia tile button";
                $(this).data("event", "productListingBuyNow");
                submitEvent($(this));
            }
        }
    });

    // ALGOLIA - tracks the options of algolia sort by dropdown.
    $cache.document.on("change", "#sort-by-container select", function () {
        if (isAlgolia) {
            algoliaSortBy = true;
            $(this).data("event", "algoliaSortBy");
            submitEvent($(this));
        }
    });

    // ALGOLIA - tracks the options of algolia sort for UPC
    $("#sort-by-container").on("click", "li", function () {
        if (isAlgolia && !$(this).hasClass("active")) {
            algoliaSortBy = true;
            $(this).data("event", "algoliaSortBy");
            submitEvent($(this));
        }
    });
    // ############## END ALGOLIA CLICK EVENTS ##############

    // Register click events on 'log in' button on cart enforced login
    $cache.document.on("click", ".enforced-login-cart .js-enforced-login-btn", function () {
        tc_events_100(this, "clickevent", {
            ACTION: "cart - account - login"
        });
    });

    // Register click events on 'log in' button on wishlist enforced login
    $cache.document.on("click", ".enforced-login-wishlist .js-enforced-login-btn", function () {
        tc_events_100(this, "clickevent", {
            ACTION: "wishlist - account - login"
        });
    });

    // Register click events for GFN (NVIDIA GeForce NOW) block on Order 'Thank You' page
    var $gfnBlock = $cache.document.find(".gfn-block");

    if ($gfnBlock && $gfnBlock.length) {
        // GFN arrow events
        var $arrowLink = $gfnBlock.find("a.accordion-title");

        if ($arrowLink && $arrowLink.length) {
            $arrowLink.on("click", function () {
                if ($arrowLink.attr("aria-expanded") === "true") {
                    tc_events_100(this, "clickevent", {
                        ACTION: "thank you page - interaction - geforce now : show details"
                    });
                } else {
                    tc_events_100(this, "clickevent", {
                        ACTION: "thank you page - interaction - geforce now : hide details"
                    });
                }
            });
        }

        // GFN button events
        var $buttonLink = $gfnBlock.find("a.button");

        if ($buttonLink && $buttonLink.length) {
            $buttonLink.on("click", function () {
                tc_events_100(this, "clickevent", {
                    ACTION: "thank you page - interaction - geforce now : play now"
                });
            });
        }
    }

    var $gfnBlockPopup = $cache.document.find("#gfn-block-content-popup");

    if ($gfnBlockPopup && $gfnBlockPopup.length) {
        $gfnBlockPopup.find("[data-close]").on("click", function () {
            tc_events_100(this, "clickevent", {
                ACTION: "thank you page - interaction - geforce now : close pop in"
            });
        });

        $gfnBlockPopup.find("a[target='_blank']").on("click", function () {
            tc_events_100(this, "clickevent", {
                ACTION: "thank you page - external - geforce now : continue"
            });
        });
    }

    // if the privacy policy is not accepted
    if (!checkPrivacyPolicy()) {
        let sCodeReady = false;

        window.addEventListener("message", () => {
            if (event.data === "S_CODE_READY") {
                sCodeReady = true;
            }

            const tcReady = (event.data === "S_CODE_READY" && checkPrivacyPolicy()) || (event.data === "PRIVACY_POLICY_ACCEPTED" && sCodeReady);

            // trigger the tc saved events when the privacy policy is accepted and the s code ready
            if (tcReady) {
                triggerEventsFromBuffer();
            }
        });

        // trigger the tc saved events before living the page if privacy policy is accepted
        // used when the privacy policy is accepted by clicking on a section of the page
        $cache.document.on("click.eventsBuffer", () => {
            if (window?.eventsBuffer?.length) {
                const $this = $(this);

                // timeout need for the s code to load
                setTimeout(function () {
                    if (checkPrivacyPolicy() && sCodeReady) {
                        triggerEventsFromBuffer();
                        $this.off("click.eventsBuffer");
                    }
                }, 1000);
            }
        });
    }

    // define event that will add events to buffer or trigger them
    $cache.document.off("updateTcEventBuffer.tagcommander").on("updateTcEventBuffer.tagcommander", function (eventName, eventInfo) {
        var privacyCookie = checkPrivacyPolicy();

        if (privacyCookie) {
            tc_events_100(eventInfo?.this || null, eventInfo?.eventType || "", eventInfo?.action || "");
        } else {
            if (!window?.eventsBuffer?.length) {
                initializeBuffer();
            }

            window.eventsBuffer.push(eventInfo);
        }
    });

    // country gate popup open
    $cache.document.on("countryGatePopup.tagcommander", () => {
        // save default values
        if (!$cache.default_wa_data) {
            $cache.default_wa_data = {
                siteSection: wa_data?.siteSection,
                pageName: wa_data?.pageName
            };
        }

        wa_data.siteSection = "country gate";
        wa_data.pageName = "are you in " + GeoRestrictedResources.GEO_USER_CURRENT_COUNTRY_NAME;
        $cache.document.trigger("globalLayerChanged");
    });

    // Check if georestriction popup is open.
    // Popup element loads before tagcommander is initialized.
    if ($cache.dialogGeorestricted.length > 0 && $cache.dialogGeorestricted.css("display") !== "none") {
        $cache.document.trigger("countryGatePopup");

        $cache.document.on("closed.zf.reveal.tagcommander", "#georestricted", setDefaultWaDataValues);

        // Register georedirect event
        $cache.dialogGeorestricted.off(".tagcommander")
            .on("click.tagcommander", ".close-reveal-modal, .geobacktostore, .geocontinue", e => {
                $cache.dialogGeorestricted.data("event", "geoRedirect");
                targetEvent = $(e.currentTarget);

                submitEvent($cache.dialogGeorestricted);
            });
    }

    $cache.document.trigger("tcEventsReady");
}

/**
 * Trigger events saved before the privacy policy was accepted
 */
function triggerEventsFromBuffer() {
    let event = {};

    while (window?.eventsBuffer?.length > 0) {
        event = window.eventsBuffer.shift();
        tc_events_100(event.this, event.eventType, event.action);
    }
}

/**
 * Check for "data-aa-category" attribute in element or parent nodes
 * @param {Object} e - Event data
 * @returns {Boolean} Returns boolean with the result of finding the attribute
 */
function checkForDataCategoryAtributte(e) {
    return $(e.target).closest("[data-aa-category]").length;
}

// delays the searching on algolia search input for "1 second rule"
function algoliaSearchDelay(callback, ms) {
    var timer = 0;

    return function () {
        var context = this,
            args = arguments;

        clearTimeout(timer);
        timer = setTimeout(function () {
            callback.apply(context, args);
        }, ms || 0);
    };
}

var events = {
    // ############## ALGOLIA TAGGING PLAN FUNCTIONS ##############

    // ALGOLIA - Triggers with Algolia, when users begin to type in the search bar
    algoliaInstantSearchOpening: function (_this) {
        var actionValue = "header - instant search - opening";

        sendEvent("clickevent", actionValue, _this);
    },

    // ALGOLIA - Triggers with Algolia, when users click on close icon in search input
    algoliaCloseSearch: function (_this) {
        var closeState = $(".ais-search-box--reset").data("close-state");

        if (closeState) {
            $(".ais-search-box--reset").data("close-state", false);

            var actionValue = "header - instant search - closing";

            sendEvent("clickevent", actionValue, _this);
        }
    },

    // ALGOLIA - Triggers with Algolia, when users apply a filter on search layer
    algoliaAppliedFilter: function (_this) {
        var actionValue = "";
        var filterFamily = "";
        var filterItem = "";
        var refineValue = "";

        if (isUpc) {
            filterFamily = $(_this).closest("div.refinement").find("h3") ? $(_this).closest("div.refinement").find("h3").text().trim().toLowerCase() : "";
            refineValue = $(_this).data("refine-value");
        } else {
            filterFamily = $(_this).closest("div.refinement.accordion-element").find("h3") ? $(_this).closest("div.refinement.accordion-element").find("h3").text().trim().toLowerCase() : "";
            refineValue = $(_this).data("refvalue");
        }

        try {
            var refineObject = JSON.parse(refineValue);

            if ("locale_1" in refineObject && refineObject.locale_1.value) {
                filterItem = refineObject.locale_1.value.trim().toLowerCase();
            }
        } catch (e) {
            // nothing to catch in case of applied filter exception
        }

        if (!filterItem) {
            if (typeof refineValue === "object" && "locale_1" in refineValue && refineValue.locale_1.value) {
                filterItem = refineValue.locale_1.value.trim().toLowerCase();
            } else if (typeof refineValue === "string") {
                filterItem = refineValue.trim().toLowerCase();
            }
        }

        actionValue = "listing page - filter applied - " + filterFamily + " - " + filterItem;

        sendEvent("clickevent", actionValue, _this);
    },

    // ALGOLIA - Triggers with Algolia, when users apply a filter on search layer
    algoliaListType: function (_this) {
        var type = $(_this).data("type");
        var actionValue = "listing page - display - " + type;

        sendEvent("clickevent", actionValue, _this);
    },

    // ALGOLIA - Triggers with Algolia, when users select/unselect a filter from content type checkboxes
    algoliaContentType: function (_this) {
        var refValue = $(_this).data("refine-value");
        var refNumber = $(_this).data("number");
        var actionValue = "";

        var refLabel = $('label[data-number="' + refNumber + '"]');
        var labelText = refLabel ? refLabel.data("label-value") : "";

        if (labelText) {
            refValue = labelText.toLowerCase();
        }

        if ($(_this).is(":checked")) {
            actionValue = "listing page - filter applied - content type - " + refValue;
        } else {
            actionValue = "listing page - filter removed - content type - " + refValue;
        }

        // "all" checkbox on algolia search layer.
        if ($(_this).is("label") && $(_this).hasClass("check-all")) {
            var $inputAll = $('input[data-refine-value="all"]');

            if ($inputAll.is(":checked")) {
                return;
            } else {
                actionValue = "listing page - filter applied - content type - all";
            }
        }

        sendEvent("clickevent", actionValue, _this);
    },

    // ALGOLIA - Triggers with Algolia, when users click a producttile on search layer
    algoliaClickProductTile: function (_this) {
        var pid = $(_this).data("id");
        var actionValue = "";
        var productName = "";
        var editionName = "";
        var data = "productID=" + pid;
        var jsonResponse = "";

        $.ajax({
            type: "GET",
            url: Urls.tcGetTcValue,
            data: data,
            async: false,
            success: function (response) {
                jsonResponse = JSON.parse(response);
                productName = jsonResponse && jsonResponse.productName ? jsonResponse.productName + " - " : "";
                editionName = jsonResponse && jsonResponse.edition ? jsonResponse.edition + " - " : "";
                actionValue = "internal search - go to product - view more - " + productName + editionName;
                sendEvent("clickevent", actionValue, _this);
            }
        });
    },

    // ALGOLIA - Triggers with Algolia, when users remove an applied filter.
    algoliaRemoveAppliedFilter: function (_this) {
        var parentFilter = "";
        var subFilter = "";

        if (isUpc) {
            parentFilter = $(_this).closest("div.refinement").find("h3") ? $(_this).closest("div.refinement").find("h3").text().trim().toLowerCase() : "";
            subFilter = $(_this).data("refine-value") ? $(_this).data("refine-value").trim().toLowerCase() : "";
        } else if ($(_this).data("tag-filter-value") && $(_this).data("tag-refinement-value")) {
            parentFilter = $(_this).data("tag-filter-value").toLowerCase();
            subFilter = $(_this).data("tag-refinement-value").toLowerCase();
        } else {
            parentFilter = $(_this).closest("div.refinement.accordion-element").find("h3") ? $(_this).closest("div.refinement.accordion-element").find("h3").text().trim().toLowerCase() : "";
            subFilter = $(_this).closest("li").data("refine-value-remove") ? $(_this).closest("li").data("refine-value-remove").trim().toLowerCase() : "";
        }

        var actionValue = "listing page - filter removed - " + parentFilter + " - " + subFilter;

        sendEvent("clickevent", actionValue, _this);
    },

    // ALGOLIA - Triggers with Algolia, when users remove all filters.
    algoliaRemoveAllFilters: function (_this) {
        var actionValue = "listing page - filter removed - all filters";

        sendEvent("clickevent", actionValue, _this);
    },

    // ALGOLIA - Triggers with Algolia, when users click on "load more" button on search layer.
    algoliaLoadMoreResult: function (_this) {
        const $algoliaResults = $("#algolia-results");

        if ($algoliaResults.length && !$algoliaResults.is(":visible")) {
            return;
        }

        var actionValue = "";
        var resultPageNumber = $(_this).data("result-page-number");
        var lastPageNumber = $(_this).data("last-page-number");
        var currentPage = resultPageNumber + 1;
        var isLastPage = (resultPageNumber + 1) == lastPageNumber ? true : false;
        var trigger = $(_this) && $(_this).data("trigger") == "autodisplay" ? "autodisplay" : "clicked";

        actionValue = "listing page - view more results - " + trigger + " - page - " + currentPage;

        if (isLastPage) {
            actionValue += " last";
        }

        sendEvent("clickevent", actionValue, _this);
    },

    // ALGOLIA - Triggers with Algolia, when users click "see more" button on producttile.
    algoliaProductListingSeeMore: function (_this, eventValue) {
        if (!eventValue) {
            try {
                eventValue = $(_this).closest(".card-overlay").data("tc100");
                eventValue = JSON.parse(eventValue.replace(/&quot;/g, '"'));
            } catch (e) {
                return;
            }
        }

        var actionValue = "";
        var productName = eventValue && eventValue.productName ? eventValue.productName.toLowerCase() : "";
        var editionName = eventValue && eventValue.edition ? eventValue.edition.toLowerCase() : "";
        var platformName = eventValue && eventValue.platform ? eventValue.platform.toLowerCase() : "";

        actionValue = "listing page - go to product - see more - ";

        if (productName != "") {
            actionValue += productName;
        }

        if (editionName != "") {
            actionValue += " - " + editionName;
        } else {
            actionValue += " - ";
        }

        if (platformName != "") {
            actionValue += " - " + platformName;
        }

        sendEvent("clickevent", actionValue, _this);
    },

    // ALGOLIA - Triggers with Algolia, when the users click to change the option of sort by component on algolia search layer.
    algoliaSortBy: function (_this) {
        var selectedSort = "";

        if (isUpc) {
            selectedSort = $(_this).text() ? $(_this).text().toLowerCase() : "";
        } else {
            var optionValue = $(_this).val();
            var selectedOptionText = $('select option[value="' + optionValue + '"]').text();

            selectedSort = selectedOptionText != "" ? selectedOptionText.trim().toLowerCase() : "";
        }

        var actionValue = "listing page - sort result by - " + selectedSort;

        sendEvent("clickevent", actionValue, _this);
    },

    // ############## END ALGOLIA TAGGING PLAN FUNCTIONS ##############

    // Try free demo
    tryFreeDemo: function (_this) {
        var $parent = $(_this).parent();
        var actionValue = "";

        if ($parent.hasClass("closed-beta") || $parent.hasClass("closed-alpha")) {
            actionValue = "product info - registration - " + _this.dataset.altText.toLowerCase();
        } else if ($parent.hasClass("demo")) {
            actionValue = "product info - CTA - try the free demo";
        } else if ($parent.hasClass("open-beta")) {
            actionValue = "product info - CTA - register to the beta";
        } else if ($parent.hasClass("free-weekends")) {
            actionValue = "product info - action - get the free weekend";
        }

        sendEvent("clickevent", actionValue, _this);
    },

    // Product listing page - filter applied
    filterApplied: function (_this, eventValue) {
        var $_this = $(_this);
        var type;

        if ($_this.hasClass("accordion-toggle")) {
            if (typeof eventValue === "string" && eventValue !== null && eventValue !== undefined) {
                eventValue = eventValue.toLowerCase();
            } else {
                eventValue = $.trim($_this.find("h3").text()).toLowerCase();
            }

            type = "filter applied - ";
        } else {
            var currentMenu = $.trim($_this.data("refvalue")).toLowerCase();

            if (!currentMenu) {
                var currentMenuText = $_this.text();

                currentMenu = $.trim(currentMenuText.substr(0, currentMenuText.indexOf("("))).toLowerCase();
            }

            eventValue = $.trim($_this.closest(".accordion-element").find(".accordion-toggle h3").data("refdefname")).toLowerCase() + " - " + currentMenu;

            if (!$_this.hasClass("selected")) {
                type = "filter applied - ";
            } else {
                type = "filter removed - ";
            }
        }

        var actionValue = "listing page - " + type + eventValue;

        sendEvent("clickevent", actionValue, _this);
    },

    filterContentTypeApplied: function (_this) {
        var $_this = $(_this);
        var action = "";

        if ($_this.is(":checked")) {
            action = "filter applied - content type - " + $_this.attr("id").split("_").pop();
        } else {
            action = "filter removed - content type - " + $_this.attr("id").split("_").pop();
        }

        var actionValue = "listing page - " + action;

        sendEvent("clickevent", actionValue, _this);
    },

    // CLP click events
    clpEvent: function (_this, eventValue) {
        var actionValue;
        var $_this = $(_this);
        var type,
            action,
            platform,
            edition;

        if (!eventValue) {
            eventValue = $_this.closest(".card-overlay").data("tc100");
        }

        platform = eventValue.platform ? eventValue.platform : "";
        edition = eventValue.edition ? eventValue.edition : "";
        action = eventValue.action;

        if ($_this.hasClass("see-more-btn")) {
            type = (enabledSubscriptionTagCommander) ? "go to product - see more" : "go to product - view more";
        } else {
            type = "add to cart";
        }

        if (targetEvent && targetEvent === "Confirmation" && $_this.data("alt-text") === Resources.BUTTON_SEE_MORE) {
            type = "see more";
        } else if (targetEvent && targetEvent === "Confirmation" && $_this.data("alt-text") === Resources.BUTTON_PRE_ORDER) {
            type = "preorder";
        } else if (targetEvent && targetEvent === "Cross sell") {
            type = "cross sell";
        } else if (!type) {
            type = "";
        }

        // Uplay+ Gamelist buttons
        var isUplayPlusBtn = false;
        var customerStatus = $cache.document.find(".customer_status").length > 0 ? $cache.document.find(".customer_status").data("subs-status") : "";

        if (enabledSubscriptionTagCommander) {
            switch ($_this.data("alt-text")) {
                case Resources.BUTTON_JOIN_UPLAY_PLUS:
                case Resources.BUTTON_REACTIVATION:
                    if (customerStatus == "Inactive") {
                        type = "get uplay plus - renew your plan";
                    } else {
                        type = "get uplay plus - choose your plan";
                    }

                    isUplayPlusBtn = true;
                    break;

                case Resources.BUTTON_ADD_TO_LIBRARY:
                    type = "uplay plus - add to library";
                    isUplayPlusBtn = true;
                    break;

                case Resources.BUTTON_START_PLAYING_NOW:
                    type = "uplay plus - start playing now";
                    isUplayPlusBtn = true;
                    break;

                default:
                    break;
            }
        }

        if (targetEvent && targetEvent === "CLP" && type != "go to product - view more" && type != "go to product - see more" && !isUplayPlusBtn) {
            actionValue = "listing page - " + type + " - " + eventValue.productName + " - " + platform + " - " + edition;
            targetEvent = false;
        } else if (targetEvent && targetEvent === "Confirmation") {
            if (type != "") {
                actionValue = type + " - " + eventValue.productName + " - " + edition;
            } else {
                actionValue = platform + " - " + eventValue.productName + " - " + edition;
            }
        } else {
            action = "listing page";

            if ($_this.closest("section").data("name") == "you may also like" || $_this.closest("section").data("name") == "additional content") {
                action = $_this.closest("section").data("name");
            }

            if (type != "get uplay plus - choose your plan" && type != "get uplay plus - renew your plan") {
                if (platform.length > 0) {
                    actionValue = action + " - " + type + " - " + eventValue.productName + " - " + platform + " - " + edition;
                } else if (edition != "" && edition != undefined) {
                    actionValue = action + " - " + type + " - " + eventValue.productName + " - " + edition;
                } else {
                    actionValue = action + " - " + type + " - " + eventValue.productName;
                }
            } else {
                actionValue = action + " - " + type;
            }
        }

        if (targetEvent === "qtyButtonIncrement") {
            actionValue = "added to cart - change quantity add to cart - " + platform + " - " + eventValue.productName + " - " + edition;
        } else if (targetEvent === "qtyButtonDecrement") {
            actionValue = "added to cart - change qantity remove from cart -" + platform + " - " + eventValue.productName + " - " + edition;
        } else if (targetEvent === "removeFromAddtoCartPopUp") {
            actionValue = "added to cart - remove from cart - " + platform + " - " + eventValue.productName + " - " + edition;
        }

        sendEvent("clickevent", actionValue, _this);
    },

    // Product listing page - filter removed
    filterRemoved: function (_this, eventValue) {
        if (typeof eventValue === "string" && eventValue !== null && eventValue !== undefined) {
            eventValue = eventValue.toLowerCase();
        } else {
            eventValue = $.trim($(_this).text()).toLowerCase();
        }

        if (!eventValue) {
            eventValue = $.trim($(_this).parent().text()).toLowerCase();
        }

        // get filter family
        var familyName = "";

        if ($(_this).data("refdefname")) {
            familyName = $(_this).data("refdefname") + " - ";
        }

        var actionValue = "listing page - filter removed - " + familyName + eventValue;

        sendEvent("clickevent", actionValue, _this);
    },

    // OPC - shipping section - edit button - click event action
    editShippingSection: function (_this, eventValue) {
        var actionValue = eventValue ? eventValue.toLowerCase() : "";

        isShippingSection = false;
        sendEvent("clickevent", actionValue, _this);
    },

    // OPC - billing section - edit button - click event action
    editBillingSection: function (_this, eventValue) {
        var actionValue = eventValue ? eventValue.toLowerCase() : "";

        sendEvent("clickevent", actionValue, _this);
    },

    // Product listing page - sort result by
    selectedSort: function (_this, eventValue) {
        if (typeof eventValue === "string" && eventValue !== null && eventValue !== undefined) {
            eventValue = eventValue.toLowerCase();
        } else {
            eventValue = $.trim($(_this).text()).toLowerCase();
        }

        var actionValue = "listing page - sort result by - " + eventValue;

        sendEvent("clickevent", actionValue, _this);
    },

    // Product listing page - go to product
    goToProduct: function (_this, eventValue) {
        if (enabledUPCSubscriptionTagCommander && (pageContext.type == "orderconfirmation" || pageContext.ns == "ubisoftpasssubscription") && $("div.confirmation-recommendation").find(".subscription-recommendation-carousel").length > 0) {
            return;
        }

        eventValue = eventValue.replace(/null/g, "");

        var actionValue = "listing page - go to product - view more - " + eventValue;

        sendEvent("clickevent", actionValue, _this);

        if (wa_data.searchKeyword || wa_data.searchResultsNumber) {
            top.prevData = Object.assign({}, wa_data);
        }

        $(window).on("openQuickView", function () {
            $("#QuickViewDialog").on("click", "button.add-to-cart", function () {
                $(window).off("openQuickView");

                if (typeof top.prevData !== "undefined" && (top.prevData.searchKeyword || top.prevData.searchResultsNumber)) {
                    wa_data = Object.assign({}, removeSpecialCharacters(top.prevData));
                }
            });

            $("#QuickViewDialog").dialog({
                beforeClose: function () {
                    $(window).off("openQuickView");

                    if (typeof top.prevData !== "undefined" && (top.prevData.searchKeyword || top.prevData.searchResultsNumber)) {
                        wa_data = Object.assign({}, removeSpecialCharacters(top.prevData));
                    }
                }
            });
        });
    },

    // Product details page - gallery actions
    galleryAction: function (_this, eventValue, gridUrl, clickType) {
        var order = $(_this).closest(".thumb").index() + 1;
        var actionValue = "product info - gallery - " + clickType + " - " + eventValue + " - " + order;

        sendEvent("clickevent", actionValue, _this);
    },

    // Product details page - video watch
    videoWatch: function (_this, eventValue) {
        var order = $(".product-thumbnails").find('[data-ga-label="' + $(_this).attr("src") + '"]').closest(".thumb").index() + 1;
        var actionValue = "product info - gallery - watch the video - " + order + " - " + eventValue;

        sendEvent("clickevent", actionValue, _this);
    },

    // Product details page - special offer
    specialOffer: function (_this, eventValue) {
        var actionValue = "product info - special offer - " + eventValue;

        sendEvent("clickevent", actionValue, _this);
    },

    // Product details page - share on social media
    socialShare: function (_this, eventValue) {
        var actionValue = "product info - share on social network - share on " + eventValue;

        sendEvent("clickevent", actionValue, _this);
    },

    // Product details page - product size select
    selectColor: function (_this, eventValue) {
        var actionValue = "product info - color - select color " + eventValue;

        sendEvent("clickevent", actionValue, _this);
    },

    // Product details page - product size select
    selectSize: function (_this, eventValue) {
        var actionValue = "product info - size - select size " + eventValue;

        sendEvent("clickevent", actionValue, _this);
    },

    // Product details page - size chart
    sizeChart: function (_this) {
        var actionValue = "product info - size chart - size chart";

        sendEvent("clickevent", actionValue, _this);
    },

    // Product details page - product tabs
    tabAction: function (_this, eventValue) {
        var actionValue = "product detail - tabs - " + eventValue;

        sendEvent("clickevent", actionValue, _this);
    },

    // Product details page - media tab - gallery actions
    mediaTabAction: function (_this, eventValue) {
        var actionValue = "";

        if (eventValue === "video - launch trailer") {
            actionValue = "product info - media - " + eventValue;
        } else {
            var mediaType = "picture",
                clickType = "clicked";

            if ($(_this).hasClass("video")) {
                mediaType = "video";
            }

            if ($(_this).data("clickType") == "arrow") {
                clickType = "arrow";
            }

            if (eventValue == undefined || eventValue == "") {
                actionValue = "product detail - media tab - " + clickType + " - " + mediaType;
            } else {
                var mediaIndex = $("[data-event='mediaTabAction']").index($(_this)) + 1;

                actionValue = "media - media - " + mediaType + " - " + mediaIndex + " - " + eventValue;
            }
        }

        sendEvent("clickevent", actionValue, _this);
    },

    // Product details page - summary tab - read more
    summaryReadMore: function (_this) {
        var actionValue = "product detail - summary tab - read more";

        sendEvent("clickevent", actionValue, _this);
    },

    // Product details page - summary tab - read more
    readMoreLinkSummary: function (_this) {
        var actionValue = "summmary - action - read more";

        sendEvent("clickevent", actionValue, _this);
    },

    // Product details page - additional content tab arrows
    additionalContentTab: function (_this, eventValue) {
        var actionValue = "product detail - additional content tab - " + eventValue + " arrow";

        sendEvent("clickevent", actionValue, _this);
    },

    // Product details page - go to product recommendation
    goToProductRecommendation: function (_this, eventValue) {
        if (enabledUPCSubscriptionTagCommander && (pageContext.type == "orderconfirmation" || pageContext.ns == "ubisoftpasssubscription") && $("div.confirmation-recommendation").find(".subscription-recommendation-carousel").length > 0) {
            return;
        }

        var recommendationFrom = "";

        eventValue = eventValue.replace(/null/g, "");

        if ($(_this).closest(".cross-sell").length > 0) {
            recommendationFrom = "you may also like";
        } else if ($(_this).closest(".up-sell").length > 0) {
            recommendationFrom = "additional content";
        }

        var actionValue = "product detail - go to product - " + recommendationFrom + " - " + eventValue;

        sendEvent("clickevent", actionValue, _this);
    },

    // Product details page - media filter tags
    mediaFilterTag: function (_this, eventValue) {
        var actionValue = "media - media - filter - " + eventValue;

        sendEvent("clickevent", actionValue, _this);
    },

    // Product listing page - display
    display: function (_this, eventValue) {
        var actionValue = "listing page - display - " + eventValue;

        sendEvent("clickevent", actionValue, _this);
    },

    // Category page - view all collection
    viewAllCollection: function (_this, eventValue) {
        var actionValue = "content slot - " + eventValue.slotID + " - go to listing page - " + eventValue.slotName;

        sendEvent("clickevent", actionValue, _this);
    },

    // Category page - watch slot video
    watchSlotVideo: function (_this, eventValue) {
        var actionValue = "content slot " + eventValue.slotID + " - video - watch the trailer - " + eventValue.videoName;

        sendEvent("clickevent", actionValue, _this);
    },

    // Category page - go to product rendered in slot
    goToSlotProduct: function (_this, eventValue) {
        var slotID = eventValue.slotID.replace(/null/g, "").toLowerCase();
        var action = eventValue.action.replace(/null/g, "").toLowerCase();
        var productName = eventValue.productName.replace(/null/g, "").toLowerCase();
        var actionValue = "content slot " + slotID + " - go to product - " + action + " - " + productName;

        sendEvent("clickevent", actionValue, _this);
    },

    // Category page - hero slider
    sliderAction: function (_this, eventValue) {
        if (!eventValue && pageContext.ns == "orderconfirmation" && $cache.document.find(".header-underline.section-header").length > 0) {
            var confirmationPageSliderText = $cache.document.find(".header-underline.section-header").text();

            if (confirmationPageSliderText && confirmationPageSliderText.indexOf("Discover games compatibles") > -1) {
                return;
            }
        }

        var type = "",
            name = "",
            ajaxResult,
            action = "",
            data = "",
            slideNumber = "",
            productNumber = "",
            productListing = "";

        if ($(_this).parent().children().length > 1) {
            if ($(_this).data("slick-index")) {
                slideNumber = " - slider " + ($(_this).data("slick-index") + 1);
            } else {
                slideNumber = " - slider " + ($(_this).index() + 1);
            }
        }

        if ($(_this).data("type") || $(_this).find('[data-type!=""][data-type]').length > 0) {
            type = $(_this).data("type") || $(_this).find('[data-type!=""][data-type]').data("type");

            var tcCid = $(_this).data("cid") || $(_this).find('[data-type!=""][data-type]').data("cid");
            var tcPid = $(_this).data("pid") || $(_this).find('[data-type!=""][data-type]').data("pid");
            var tcBid = $(_this).data("bid") || $(_this).find('[data-type!=""][data-type]').data("bid");

            if (type == "category") {
                type = "listing";
            }

            if (tcCid != undefined) {
                data = "cid=" + tcCid;
            }

            if (tcPid != undefined) {
                data = "pid=" + tcPid;
            }

            if (tcBid != undefined) {
                data = "bid=" + tcBid;
            }

            $.ajax({
                type: "GET",
                url: Urls.tcGetNameAjax,
                data: data,
                async: false,
                success: function (response) {
                    ajaxResult = response;
                }
            });
            name = ajaxResult.name != null ? ajaxResult.name.toLowerCase() : "";
            action = ajaxResult.action != null ? ajaxResult.action : "";
        } else if (targetEvent) {
            type = "product";
            data = $(targetEvent).data("tc100") ? $(targetEvent).data("tc100") : $(targetEvent).closest(".card-overlay").data("tc100");

            if (!data) {
                data = $(_this).find('[data-tc100!=""][data-tc100]').length ? $(_this).find('[data-tc100!=""][data-tc100]').data("tc100") : "";
            }

            if (typeof data != "string") {
                action = data.action;
                name = data.productName;
            }

            if (productSlider) {
                slideNumber = $(_this).data("slick-index") + 1;
                productNumber = data.productNumber ? data.productNumber : $(_this).index() + 1;
                productListing = $(_this).closest(".category-product-slider ").data("order");
            } else if (typeof data == "string") {
                // the value of hyper link of the hero slider
                action = data;
            } else {
                productNumber = " - product " + data.productNumber;
            }

            if ($(targetEvent).hasClass("shop-now")) {
                action = "add to cart - " + data.productName + " - " + data.edition;
                productNumber = "";
            }

            targetEvent = false;
        } else {
            var fallBackTcValues = $(_this).find('[data-tc100!=""][data-tc100]').length ? $(_this).find('[data-tc100!=""][data-tc100]').data("tc100") : "";

            data = fallBackTcValues;

            if (typeof fallBackTcValues === "object") {
                type = fallBackTcValues.type == "category" ? "listing" : "product";
                name = fallBackTcValues.name ? fallBackTcValues.name : (fallBackTcValues.productName ? fallBackTcValues.productName : "");
                action = fallBackTcValues.action ? fallBackTcValues.action : "buy now";
            }
        }

        // Check if is a Uplay+ Button
        var isUplayPlusBtn = false;
        var customerStatus = $cache.document.find(".customer_status").length > 0 ? $cache.document.find(".customer_status").data("subs-status") : "";

        if (enabledSubscriptionTagCommander && eventValue) {
            switch (eventValue) {
                case Resources.BUTTON_SEE_MORE:
                    type = "go to product";
                    action = "see more";
                    isUplayPlusBtn = true;
                    break;

                case Resources.BUTTON_JOIN_UPLAY_PLUS:
                case Resources.BUTTON_REACTIVATION:
                    type = "get uplay plus";

                    if (customerStatus == "Inactive") {
                        action = "renew your plan";
                    } else {
                        action = "choose your plan";
                    }

                    name = null;
                    isUplayPlusBtn = true;
                    break;

                case Resources.BUTTON_ADD_TO_LIBRARY:
                    type = "uplay plus";
                    action = "add to library";
                    isUplayPlusBtn = true;
                    break;

                case Resources.BUTTON_START_PLAYING_NOW:
                    type = "uplay plus";
                    action = "start playing now";
                    isUplayPlusBtn = true;
                    break;

                default:
                    break;
            }
        }

        var hrefObject,
            $productTileCard,
            actionValue,
            getSeeMore,
            $seeMoreBtn;

        sliderProductTiles = false;

        if (productSlider) {
            // PRsODUCTTILE - CARD SECTIONS - CLICKABLE
            if (!$(getTargetObject).hasClass("button") && ($(getTargetObject).hasClass("card-details") || $(getTargetObject).parent().closest(".card-details").length > 0)) {
                $productTileCard = $(getTargetObject).parent().parent().closest("div.product-tile.card");

                if ($productTileCard.length > 0) {
                    $seeMoreBtn = $productTileCard.find(".see-more-btn").length > 0 ? $productTileCard.find(".see-more-btn") : "";

                    if ($seeMoreBtn != "") {
                        if ($seeMoreBtn.length > 0) {
                            hrefObject = $seeMoreBtn;
                        }
                    }
                }

            // PRODUCTTILE - BLUE SECTIONS - CLICKABLE
            } else if (!$(getTargetObject).hasClass("button")) {
                getSeeMore = $(getTargetObject).parent().closest("div.product-tile.card").find(".see-more-btn");

                if (getSeeMore.length > 0) {
                    hrefObject = getSeeMore;
                }
            }

            var slotNumber = typeof productNumber != "undefined" ? productNumber : slideNumber;

            actionValue = "product listing " + productListing + " - slot " + slotNumber + " - go to " + type + " - " + action + " - " + name;

            if (isUplayPlusBtn) {
                actionValue = "product listing " + productListing + " - slot " + productNumber + " - " + type + " - " + action;

                if (name) {
                    actionValue += " - " + name;
                }
            }

            productSlider = false;
        } else if (data.productNumber) {
            // PRODUCTTILE - CARD SECTIONS - CLICKABLE
            if (!$(getTargetObject).hasClass("button") && ($(getTargetObject).hasClass("card-details") || $(getTargetObject).parent().closest(".card-details").length > 0)) {
                $productTileCard = $(getTargetObject).parent().parent().closest("div.product-tile.card");

                if ($productTileCard.length > 0) {
                    $seeMoreBtn = $productTileCard.find(".see-more-btn").length > 0 ? $productTileCard.find(".see-more-btn") : "";

                    if ($seeMoreBtn != "") {
                        if ($seeMoreBtn.length > 0) {
                            hrefObject = $seeMoreBtn;
                        }
                    }
                }

            // PRODUCTTILE - BLUE SECTIONS - CLICKABLE
            } else if (!$(getTargetObject).hasClass("button")) {
                getSeeMore = $(getTargetObject).parent().closest("div.product-tile.card").find(".see-more-btn");

                if (getSeeMore.length > 0) {
                    hrefObject = getSeeMore;
                }
            }

            actionValue = "hero banner" + slideNumber + productNumber + " - go to " + type + " - " + action;
        } else if ($(_this).find(".yt-video-popup").length > 0) {
            var trailerName = $(_this).find(".price-box-product-name").text().toLowerCase().trim();

            actionValue = "hero banner" + slideNumber + " - media - " + "watch the trailer - " + trailerName;
        } else if (typeof data == "string") {
            // hyper link of the slider
            var hyperLinkValue = action != undefined || action != "" ? " - " + action.toLowerCase() : "";

            actionValue = "hero banner" + slideNumber + " - product listing - go to listing page" + hyperLinkValue;
        } else {
            // PRODUCTTILE - CARD SECTIONS - CLICKABLE
            if (!$(getTargetObject).hasClass("button") && ($(getTargetObject).hasClass("card-details") || $(getTargetObject).parent().closest(".card-details").length > 0)) {
                $productTileCard = $(getTargetObject).parent().parent().closest("div.product-tile.card");

                if ($productTileCard.length > 0) {
                    $seeMoreBtn = $productTileCard.find(".see-more-btn").length > 0 ? $productTileCard.find(".see-more-btn") : "";

                    if ($seeMoreBtn != "") {
                        if ($seeMoreBtn.length > 0) {
                            hrefObject = $seeMoreBtn;
                        }
                    }
                }
            }

            var editionName = data.edition ? data.edition : "";

            actionValue = "hero banner" + slideNumber + " - product listing - go to listing page" + " - " + action + " - " + name + " - " + editionName;
        }

        sendEvent("clickevent", actionValue, _this);

        if (hrefObject && hrefObject.length > 0) {
            // Ignores - OUT OF STOCK BUTTONS
            if ($(getTargetObject).is("span") && $(getTargetObject).parent().hasClass("out-of-stock")) {
                return;
            }

            window.location.href = hrefObject.attr("href");
        }
    },

    // UPLAY Home page - slot actions
    slotAction: function (_this, eventValue) {
        if ((enabledUPCSubscriptionTagCommander || enabledSubscriptionTagCommander) && isGamingListPageURL) {
            return;
        }

        var clicked,
            divider,
            slotType,
            slotOrder,
            assetOrder,
            type,
            isTarget = false;

        if ($(_this).closest(".pushes").hasClass("triple")) {
            slotType = "triple";
            clicked = $(".pushes.triple").index($(_this).closest(".pushes")) + 1;
            divider = 3;
            type = " - go to " + eventValue.type;
        } else if ($(_this).closest(".pushes").hasClass("double")) {
            slotType = "double";
            clicked = $(".pushes.double").index($(_this).closest(".pushes")) + 1;
            divider = 2;

            if ($(_this).find(".custom-double-title").length > 0) {
                type = " - " + $(_this).find(".custom-double-title").html().split(" ")[0].toLowerCase();
            } else {
                type = " - go to " + eventValue.type;
            }
        }

        if (targetEvent && $(targetEvent).data("alt-text") != undefined) {
            isTarget = true;
        }

        if (slotType !== undefined) {
            assetOrder = clicked % divider != 0 ? clicked % divider : divider;
            slotOrder = Math.ceil(clicked / divider);

            var actionValue;

            if (isTarget) {
                var altText = $(targetEvent).data("alt-text").toLowerCase();

                if (altText === Resources.BUTTON_SEE_MORE.toLowerCase()) {
                    var actionValueName = eventValue && eventValue.title ? eventValue.title.toLowerCase() : (eventValue.name ? eventValue.name.toLowerCase() : "");
                    var actionValueCid = eventValue && eventValue.cid ? "other - " : "";

                    actionValue = slotType + " " + slotOrder + " - slot " + assetOrder + " - " + actionValueCid + "see more - " + actionValueName;
                } else if (altText === Resources.BUTTON_PRE_ORDER.toLowerCase() || altText === "preorder") {
                    var isProduct = eventValue && eventValue.cid ? "" : "go to product - ";

                    actionValue = slotType + " " + slotOrder + " - slot " + assetOrder + " - " + isProduct + "preorder - " + eventValue.name.toLowerCase();
                } else if (altText === "join now") {
                    var isBeta = $(_this).find(".custom-double-title").text().toLowerCase().indexOf("beta") > -1 ? "beta - " : "";
                    var isDemo = $(_this).find(".custom-double-title").text().toLowerCase().indexOf("demo") > -1 ? "demo - " : "";
                    var isContentType = "";

                    if (isBeta) {
                        isContentType = isBeta;
                    } else if (isDemo) {
                        isContentType = isDemo;
                    }

                    actionValue = slotType + " " + slotOrder + " - slot " + assetOrder + " - " + isContentType + "join now - champions";
                } else {
                    actionValue = slotType + " " + slotOrder + " - slot " + assetOrder + type + (eventValue.action ? " - " + eventValue.action : "") + (eventValue.name ? (" - " + eventValue.name.toLowerCase()) : "");
                }
            } else {
                var name = eventValue && eventValue.name ? eventValue.name.toLowerCase() : "";
                var action = eventValue && eventValue.action ? " - " + eventValue.action : "";
                var edition = eventValue && eventValue.edition ? (" - " + eventValue.edition) : "";

                if (!name.length && !edition.length) {
                    actionValue = slotType + " " + slotOrder + " - slot " + assetOrder + type + action;
                } else {
                    actionValue = slotType + " " + slotOrder + " - slot " + assetOrder + type + action + " - " + name + edition;
                }
            }

            sendEvent("clickevent", actionValue, _this);

            // if there is a product ID to redirect to the PDPs on double push content assets.
            if (doubleTileSection && !$(_this).find(".global-add-to-cart").length) {
                var productUrl = $(_this).data("product-url");

                if (!$(_this).hasClass("button") && (productUrl != "" || productUrl != undefined)) {
                    window.location.href = productUrl;
                }

                doubleTileSection = false;
            }

            if (tripleTileSection) {
                // Ignores - OUT OF STOCK BUTTONS
                if ($tripleTileEventTarget.is("span") || $tripleTileEventTarget.parent().hasClass("out-of-stock")) {
                    return;
                }

                var hrefObject = $(_this).find(".see-more-btn");

                if (hrefObject && hrefObject.length > 0) {
                    window.location.href = hrefObject.attr("href");
                }

                tripleTileSection = false;
            }
        }
    },

    fanCorner: function (_this, eventValue) {
        var type,
            productNumber,
            productName,
            action,
            slotOrder;

        slotOrder = $(_this).closest(".homepage-product-corner").data("order");
        type = " - go to product";
        productNumber = eventValue.productNumber ? eventValue.productNumber : "1";
        productNumber = " - slot " + productNumber;
        action = " - " + eventValue.action;
        productName = " - " + eventValue.productName;

        var actionValue = "one and double " + slotOrder + productNumber + type + action + productName;

        sendEvent("clickevent", actionValue, _this);
    },

    geoRedirect: function (_this) {
        if (targetEvent) {
            var actionValue = "country gate";
            var $targetEvent = $(targetEvent);

            if ($targetEvent.closest(".close-reveal-modal").length) {
                actionValue = actionValue + " - cross - stay on the wrong store";
            } else if ($targetEvent.hasClass("geobacktostore")) {
                actionValue = actionValue + " - selection - stay on the wrong store";
            } else if ($targetEvent.hasClass("geocontinue")) {
                actionValue = actionValue + " - selection - continue on the right store";
            }

            sendEvent("clickevent", actionValue, _this);
        }
    },

    globalClickEvent: function (_this, eventValue) {
        if (pageContext.ns == "earlyregistration") {
            return;
        }

        var actionValue;

        if (typeof eventValue === "string") {
            actionValue = $.trim(eventValue).toLowerCase();
        } else {
            actionValue = eventValue.location + " - " + eventValue.category + " - " + eventValue.action;
        }

        sendEvent("clickevent", actionValue, _this);
    },

    // Checkout - shipping & billing sections - "save and continue" & "save and review order" buttons
    checkoutShippingBillingSectionsButtons: function (_this, eventValue) {
        var actionValue = $.trim(eventValue).toLowerCase();

        sendEvent("clickevent", actionValue, _this);
    },

    // when we click on the next arrow of the variation carousels, triggers the click event only for next arrow.
    dlcArrowsPdp: function (_this) {
        var actionValue = "",
            dlcType = "";

        if ($(".dlc-types-list").find(".active").length > 1) {
            dlcType = $(".dlc-types-list").find(".active").eq(0).text().trim();
        } else {
            dlcType = $(".dlc-types-list").find(".active").text().trim();
        }

        actionValue = "product info - " + dlcType + " - arrow - see more dlcs";

        sendEvent("clickevent", actionValue, _this);
    },

    dlcVariationClick: function (_this, eventValue) {
        var isClickedType = $("li.dlc-types.active").data("type-select-first");
        var dlcType = $("li.dlc-types.active").data("tc-100").split("-")[3];

        if (isClickedType) {
            $("li.dlc-types.active").data("type-select-first", false);

            return;
        }

        var actionValue = "product info - " + dlcType + " - select " + dlcType + " - " + eventValue.productName;

        // DLC variation click event
        sendEvent("clickevent", actionValue, _this);

        var data = $(_this).data("variationid");

        $.ajax({
            type: "GET",
            url: Urls.tcGetProductData,
            data: "productID=" + data,
            async: false,
            success: function (response) {
                if (response != "" || response != null || response != undefined) {
                    var parseResponse = JSON.parse(response);

                    if (parseResponse != "" || parseResponse != null || parseResponse != undefined) {
                        window.wa_data = parseResponse;
                        dlcVariationClick = "dlc variation click";
                    }
                }
            }
        });
    },

    // Scroll event tracking
    scrollEvent: function (_this, eventValue) {
        var actionValue = "";
        var eventValueTextArray = eventValue != null || eventValue != undefined ? eventValue.split("-") : "";
        var firstText = eventValueTextArray != "" && typeof eventValueTextArray === "object" && eventValueTextArray.length > 0 ? eventValueTextArray[0].trim() : "";

        if (firstText != "") {
            actionValue = firstText + " - tab displayed - scroll";
        } else {
            actionValue = $.trim($(_this).text()).toLowerCase() + " - tab displayed - scroll";
        }

        sendEvent("clickevent", actionValue, _this);
    },

    shopNowEvent: function (_this, eventValue) {
        var type,
            productName,
            edition;

        productName = eventValue.productName.toLowerCase();
        edition = eventValue.edition.toLowerCase();

        var $platform = $(".platforms-list li.selected");

        if ($platform.length > 0) {
            type = $platform.find("a").text().toLowerCase();
        } else {
            type = "no platform selected";
        }

        var actionValue = "product tabs - add to cart - " + type + " - " + productName + " - " + edition;

        sendEvent("clickevent", actionValue, _this);
    },

    // Language selection
    languageSelection: function (_this) {
        var action;

        if (targetEvent && targetEvent.type == "selectric-open") {
            action = "language selection";
        } else if (targetEvent && targetEvent.type == "selectric-select") {
            action = "language selected - " + $.trim(_this.options[_this.selectedIndex].value).toLowerCase();
        }

        targetEvent = false;

        var actionValue = "header - language - " + action;

        sendEvent("clickevent", actionValue, _this);
    },

    // Sort by click tracking
    sortBy: function (_this) {
        var action = $.trim(_this.options[_this.selectedIndex].text).toLowerCase();
        var actionValue = "listing page - sort result by - " + action;

        sendEvent("clickevent", actionValue, _this);
    },

    // Add to cart event
    addToCart: function (_this, eventValue) {
        var platform = eventValue && eventValue.platform ? eventValue.platform.toLowerCase() : "";
        var productName = eventValue && eventValue.productName ? eventValue.productName.split("-")[0].trim().toLowerCase() : "";
        var edition = eventValue && eventValue.edition ? eventValue.edition.toLowerCase() : "";
        var action = $(_this).hasClass("preorder") ? "preorder" : "add to cart";
        var actionValue = "product info - " + action + " - " + platform + " - " + productName + " - " + edition;

        if (targetEvent === "offerProductsForPopUp") {
            actionValue = "added to cart - go to product - cross sell - " + productName + " - " + edition + " - " + platform;
        }

        sendEvent("clickevent", actionValue, _this);
    },

    // "FAQ Button" on confirmation page
    faqButtonConfirmationPage: function (_this) {
        var buttonTitle = $.trim($(_this).data("alt-text")).toLowerCase();
        var actionValue = "thank you page - navigation - " + buttonTitle;

        sendEvent("clickevent", actionValue, _this);
    },

    // "FAQ Button" on search no hit page
    faqButtonSearchPage: function (_this) {
        var actionValue = "";

        if (pageContext.ns == "search") {
            actionValue = "page - interaction - read faq";
        } else {
            actionValue = "thank you page - navigation - read faq";
        }

        sendEvent("clickevent", actionValue, _this);
    },

    // "UPlay Download Button" on confirmation page
    uplayDownloadButtonConfirmationPage: function (_this) {
        var actionValue = $.trim($(_this).data("alt-text")).toLowerCase();

        sendEvent("clickevent", actionValue, _this);
    },

    // "Launch Button" on confirmation page
    launchButtonConfirmationPage: function (_this) {
        var actionValue = $.trim($(_this).data("alt-text")).toLowerCase();

        sendEvent("clickevent", actionValue, _this);
    },

    // "Account Button" on confirmation page
    accountButtonConfirmationPage: function (_this) {
        var buttonTitle = $.trim($(_this).data("alt-text")).toLowerCase();
        var actionValue = "thank you page - navigation - " + buttonTitle;

        sendEvent("clickevent", actionValue, _this);
    },

    // Home page - content assets with no rendering template
    slotActionNoTemplate: function (_this, eventValue) {
        if (eventValue.type) {
            var type = eventValue.type,
                data = "",
                ajaxResult = "",
                clicked,
                divider,
                slotType,
                slotOrder,
                assetOrder;

            if (eventValue.type == "category") {
                type = "listing";
            }

            if (eventValue.cid != undefined) {
                data = "cid=" + eventValue.cid;
            }

            if (eventValue.pid != undefined) {
                data = "pid=" + eventValue.pid;
            }

            $.ajax({
                type: "GET",
                url: Urls.tcGetNameAjax,
                data: data,
                async: false,
                success: function (response) {
                    ajaxResult = response;
                }
            });

            var eventName = ajaxResult.name != null ? ajaxResult.name.toLowerCase() : "";

            if ($(_this).closest(".pushes").hasClass("triple")) {
                slotType = "triple";
                clicked = $(".pushes.triple").index($(_this).closest(".pushes")) + 1;
                divider = 3;
            } else if ($(_this).closest(".pushes").hasClass("double")) {
                slotType = "double";
                clicked = $(".pushes.double").index($(_this).closest(".pushes")) + 1;
                divider = 2;
            }

            if (slotType !== undefined) {
                assetOrder = clicked % divider != 0 ? clicked % divider : divider;
                slotOrder = Math.ceil(clicked / divider);

                var actionValue = slotType + " " + slotOrder + " - slot " + assetOrder + " - go to " + type + " - " + ajaxResult.action + " - " + eventName;

                sendEvent("clickevent", actionValue, _this);
            }
        }
    },

    // Light cart - view cart
    lightCartView: function (_this) {
        var actionValue = "light cart - go to cart - view cart";

        sendEvent("clickevent", actionValue, _this);
    },

    // Light cart - go to checkout
    lightCartCheckout: function (_this) {
        var actionValue = "light cart - go to checkout - go straight to checkout";

        sendEvent("clickevent", actionValue, _this);
    },

    // Cart page - change quantity of product
    quantityChange: function (_this, eventValue) {
        var action = "change quantity remove from cart",
            page = "cart";

        if ($(_this).val() > $(_this).data("current-value")) {
            action = "change quantity add to cart";
        }

        if (window.pageContext.ns == "opc") {
            page = "checkout";
        }

        var actionValue = page + " - " + action + "  - " + eventValue;

        sendEvent("clickevent", actionValue, _this);
    },

    // Cart page - remove from cart
    removeFromCart: function (_this, eventValue) {
        var actionValue;

        if ($(_this).data("location") && $(_this).data("location") === "lightcart") {
            actionValue = "light cart - remove from cart - " + eventValue;
        } else {
            actionValue = "cart - remove from cart - " + eventValue;
        }

        sendEvent("clickevent", actionValue, _this);
    },

    // Cart Page - edit product details
    editDetails: function (_this) {
        var actionValue = "cart - edit details - edit product details";

        sendEvent("clickevent", actionValue, _this);
    },

    // Cart page - add promo code
    addPromoCode: function (_this) {
        var page = "cart";

        if (window.pageContext.ns == "opc") {
            page = "checkout";
        }

        var actionValue = page + " - promo code - add promo code";

        sendEvent("clickevent", actionValue, _this);
    },

    // Cart page - apply promo code
    applyPromoCode: function (_this, eventValue) {
        var page = "cart";

        if (window.pageContext.ns == "opc") {
            page = "checkout";
        }

        var actionValue = page + " - promo code - " + eventValue;

        sendEvent("clickevent", actionValue, _this);
    },
    // Cart page - continu shopping
    continueShopping: function (_this, eventValue) {
        var actionValue = "cart - continue shopping - " + eventValue + " page - continue shopping";

        sendEvent("clickevent", actionValue, _this);
    },

    // Error pages - go to home page
    goHome: function (_this) {
        var actionValue = "error page - action - go home";

        sendEvent("clickevent", actionValue, _this);
    },

    // Logo click - go on home page
    logoClick: function (_this) {
        var actionValue = "header - navigation - logo";

        sendEvent("clickevent", actionValue, _this);
    },

    // My account click and Login click
    accountClick: function (_this, eventValue) {
        var action;

        if (typeof eventValue === "string" && eventValue !== null && eventValue !== undefined) {
            action = eventValue.toLowerCase();
        } else {
            action = $.trim($(_this).text()).toLowerCase();
        }

        var actionValue = "header - account - " + action;

        sendEvent("clickevent", actionValue, _this);
    },

    // Register click event on my account page for delete address
    deleteAddressClick: function (_this) {
        wa_data.pageName = "delete address";
        tc_events_100(this, "pageevent", {
            ACTION: "delete address",
            SITESECTION: "my account",
            ERRORTYPE: ""
        });
    },

    // Menu click - go to page
    menuClick: function (_this, eventValue) {
        var action = "",
            edition,
            game,
            brand,
            mainCat;

        if ($(".app_uplay").length > 0) {
            if ($(_this).hasClass("edition-link")) {
                if (typeof eventValue === "string" && eventValue !== null && eventValue !== undefined) {
                    edition = eventValue.toLowerCase();
                    game = $(".menu-category").find(".game-title-item.active .game-title-item-link").data("tc100").toLowerCase();
                    mainCat = $(_this).closest("li.has-subcategories").find("a:first").data("tc100").toLowerCase();
                    action = mainCat + " - " + game + " - " + edition;
                } else {
                    edition = $.trim($(_this).text()).toLowerCase();
                    game = $.trim($(".menu-category").find(".game-title-item.active .game-title-item-link").text()).toLowerCase();
                    brand = $.trim($(".menu-category").find(".franchise-item.active .franchise-item-link").text()).toLowerCase();
                    mainCat = $.trim($(_this).closest("li.has-subcategories").find("a:first").text()).toLowerCase();
                    action = mainCat + " - " + brand + " - " + game + " - " + edition;
                }
            } else if ($(_this).closest("ul").hasClass("menu-category")) {
                if (typeof eventValue === "string" && eventValue !== null && eventValue !== undefined) {
                    action = eventValue.toLowerCase();
                } else {
                    action = $.trim($(_this).text()).toLowerCase();
                }
            }
        } else {
            if (typeof eventValue === "string" && eventValue !== null && eventValue !== undefined) {
                if ($(_this).data("parent")) {
                    action += $(_this).data("parent").toLowerCase() + " - ";
                }

                action += eventValue.toLowerCase();
            } else {
                if ($(_this).data("parent")) {
                    action += $(_this).data("parent").toLowerCase();
                } else if ($(_this).closest(".level-4").length > 0) {
                    var $level3 = $(_this).closest(".level-4-wrapper").parent().children().first();

                    if ($level3.length) {
                        action += $level3.data("parent") ? $level3.data("parent").toLowerCase() + " - " + $.trim($level3.contents().not($level3.children()).text()).toLowerCase() + " - " + $(_this).find(".title").text().toLowerCase() + " - " + $(_this).find(".subtitle").text().toLowerCase() : "";
                    }
                } else if ($(_this).closest(".category-subtitle").length > 0) {
                    mainCat = $(_this).closest("li").find(".has-sub-menu").data("tc100");

                    var subTitle = $(_this).closest(".category-subtitle").data("tc100");

                    if (mainCat && subTitle) {
                        action = mainCat.toLowerCase() + " - " + subTitle.toLowerCase();
                    }
                }
            }
        }

        var actionValue = "header - menu - " + action;

        sendEvent("clickevent", actionValue, _this);
    },

    // Club reward - go to club
    clubClick: function (_this) {
        var offerString = $(_this).closest(".html-slot-container").data("tc100");
        var actionValue = "header - navigation - " + offerString;

        sendEvent("clickevent", actionValue, _this);
    },

    // Triggers add-to-cart main button and tab button on pdp.
    addToCartCartEventPdp: function (_this, eventValue) {
        if ($(_this).hasClass("giveaway")) {
            sendEvent("clickevent", "product info - action - get the giveaway", _this);

            return;
        }

        clickAddToCart = true;

        var actionValue = "";
        var productActionValue = "";
        var productName = eventValue && eventValue.productName ? eventValue.productName.split("-")[0].trim().toLowerCase() : "";
        var productType = eventValue && eventValue.productType ? eventValue.productType : "";
        var productSize = eventValue && eventValue.productSize ? eventValue.productSize : "no size selected";
        var productColor = eventValue && eventValue.productColor ? eventValue.productColor : "no color selected";
        var productPlatform = eventValue && eventValue.platform ? eventValue.platform.toLowerCase() : "no platform selected";
        var productEdition = eventValue && eventValue.edition ? eventValue.edition.toLowerCase() : "no edition selected";
        var action = $(_this).hasClass("preorder") ? "preorder" : "add to cart";

        if (productType != "") {
            if (productType == "games" || productType == "dlc") {
                productActionValue = productPlatform + " - " + productName + " - " + productEdition;
            } else if (productType == "apparel") {
                if (productSize == "no size selected" && productColor == "no color selected") {
                    productActionValue = "no size no color selected - " + productName;
                } else if (productSize != "no size selected") {
                    productActionValue = productSize + " - " + productName + " - " + productColor;
                } else if (productColor != "no color selected") {
                    productActionValue = productSize + " - " + productName + " - " + productColor;
                }
            } else if (productType == "collectible") {
                productActionValue = productName;
            }
        }

        // If add to cart button is clicked from tab section on pdp.
        if ($(_this).data("from") === "tab") {
            $(_this).data("from", "");
            actionValue = "product tabs - " + action + " - " + productActionValue;
            $(_this).data("from", "");

            sendEvent("clickevent", actionValue, _this);
        } else {
            actionValue = "product info - " + action + " - " + productActionValue;

            sendEvent("clickevent", actionValue, _this);
        }
    },

    // Add to cart - remove from cart actions
    addRemoveCart: function (_this, eventValue) {
        if ($(_this).hasClass("check-for-tagging-click")) {
            return;
        }

        var platform = eventValue && eventValue.platform ? eventValue.platform.toLowerCase() + " - " : "no platform selected - ";
        var edition = eventValue && eventValue.edition ? " - " + eventValue.edition.toLowerCase() : "";
        var action = $(_this).hasClass("preorder") ? "preorder" : "add to cart";
        var actionValue = "product info - " + action + " - " + platform + _this.title + edition;
        var productName = eventValue.productName ? eventValue.productName.split("-")[0].trim().toLowerCase() : "";

        if ($(_this).data("from") === "tab") {
            var type;

            if (platform.length > 0) {
                type = platform;
            } else {
                type = "no platform selected";
            }

            actionValue = "product tabs - " + action + " - " + type + " - " + productName + " - " + edition;
            sendEvent("clickevent", actionValue, _this);
            $(_this).data("from", "");

            return;
        }

        sendEvent("clickevent", actionValue, _this);
    },

    updateSubscriptionCustomerData: function () {
        return new ThenPromise((resolve, reject) => {
            $.ajax({
                url: Urls.tcGetUbiPassCustomerData,
                type: "GET",
                data: {},
                success: function (data) {
                    if (!$.isEmptyObject(data)) {
                        var customerUbiPassData = JSON.parse(data);

                        if (!$.isEmptyObject(customerUbiPassData)) {
                            wa_data.ubisoftPlus = customerUbiPassData.ubisoftPlus || "";
                            wa_data.subscriptionType = customerUbiPassData.subscriptiontype || "";
                            wa_data.subscriptionDate = customerUbiPassData.subscriptiondate || "";
                            wa_data.rocksmithPlus = customerUbiPassData.rocksmithPlus || "";
                            wa_data.rocksmithPlusSubscriptionType = customerUbiPassData.rocksmithPlusSubscriptionType || "";
                            wa_data.rocksmithPlusSubscriptionDate = customerUbiPassData.rocksmithPlusSubscriptionDate || "";

                            if (customerUbiPassData.subscriptionFrequency) {
                                wa_data.subscriptionFrequency = customerUbiPassData.subscriptionFrequency;
                            }
                        }
                    }

                    resolve();
                },
                error: function (error) {
                    reject(error);
                }
            });
        });
    },

    // Search results - internal search - search validation
    searchValidation: function (_this) {
        var actionValue = "internal search - search validation - " + $("input#q").val();

        sendEvent("clickevent", actionValue, _this);
    },

    // Search results - internal search - suggestion click
    searchSuggestion: function (_this, eventValue) {
        if (typeof eventValue !== "object") {
            var actionValue;

            eventValue = eventValue.replace(/null/g, "");
            actionValue = "internal search – go to product - view more - " + eventValue;
        } else {
            actionValue = "internal search - go to product - view more - " + eventValue.productName.toLowerCase() + " - " + eventValue.edition.toLowerCase();
        }

        sendEvent("clickevent", actionValue, _this);
    },

    // global layer update event
    globalLayerChanged: function (_this) {
        if (window.wa_data) {
            window.wa_data["ACTION"] = window.wa_data.pageName;
            window.wa_data["SITESECTION"] = window.wa_data.siteSection;
            window.wa_data["TEMPLATE"] = window.wa_data.template;
            window.wa_data["SUBSITESECTION"] = window.wa_data.siteSubSection || "";
            window.wa_data["COUNTRY"] = window.wa_data.country;
            window.wa_data["LANGUAGE"] = window.wa_data.language;
            window.wa_data["UPLAYID"] = window.wa_data.uplayID || "";
            window.wa_data["ERRORTYPE"] = window.wa_data.errorType || "";
            window.wa_data["MDMBID"] = window.wa_data.mdmBID;
            window.wa_data["MDMIID"] = window.wa_data.mdmIID;
            window.wa_data["GAME"] = window.wa_data.game;
            window.wa_data["BRAND"] = window.wa_data.brand;
            window.wa_data["FREEOFFEROP"] = window.wa_data.freeOfferOperation;
            window.wa_data["FREEOFFERGAME"] = window.wa_data.freeOfferGame;
            window.wa_data["REGISTRATIONFLOW"] = window.wa_data.registrationFlow;
            window.wa_data["PLATFORMSELECTION"] = window.wa_data.platformSelection;

            if (isAlgolia) {
                window.wa_data["TESTNAME"] = window.wa_data.testName;
                window.wa_data["TESTVERSION"] = window.wa_data.testVersion;
                window.wa_data["SEARCHKEYWORD"] = window.wa_data.searchKeyword;
                window.wa_data["SEARCHRESULTS"] = window.wa_data.searchResultsNumber;
            }

            wa_data = removeSpecialCharacters(wa_data);
            updateGlobalLayer();

            var actionValue = window.wa_data;

            if (clickAddToCart && (!isLegacy || isLegacy == undefined) && actionValue.siteSection == "quick view product page" || actionValue.siteSection == "master product page") {
                clickAddToCart = false;

                return;
            }

            // blocks twice triggering on dlc variatons.
            if (dlcVariationClick == "dlc variation click") {
                dlcVariationClick = "";

                return;
            }

            sendEvent("pageevent", actionValue, window.document);
        }
    },

    // My Account page - download invoice button click
    orderReceipt: function (_this) {
        var actionValue = "orders history - action - download invoices";

        sendEvent("clickevent", actionValue, _this);
    },

    // My Account page - customer support button click
    orderSupport: function (_this) {
        var actionValue = "my orders - other - contact customer support";

        sendEvent("clickevent", actionValue, _this);
    },

    // My Account page - view order details click
    orderDetails: function (_this) {
        var actionValue = "orders history - action - view more details";

        sendEvent("clickevent", actionValue, _this);
    },

    // My Account page - track order click
    orderTrack: function (_this) {
        var actionValue = "orders history - navigation - track your order";

        sendEvent("clickevent", actionValue, _this);
    },

    // My Account page - order faq click
    orderFAQ: function (_this) {
        var actionValue = "orders history - navigation - read faq";

        sendEvent("clickevent", actionValue, _this);
    },

    // My Account page - view more order click
    viewMoreOrder: function (_this) {
        var actionValue = "orders history - action - view more";

        sendEvent("clickevent", actionValue, _this);
    },

    // My Account page - no orders shop now click
    noOrders: function (_this) {
        var actionValue = "no orders - navigation - shop now";

        sendEvent("clickevent", actionValue, _this);
    },

    // Homepage - Discover link
    discoverLinkEvent: function (_this) {
        var discoverLink = $(_this).text();
        var actionValue = "";
        var hrefText = "";

        if (discoverLink && discoverLink.indexOf(":") > 0) {
            hrefText = discoverLink.split(":")[1].trim();
        }

        if (hrefText) {
            actionValue = "product listing 1 - title - go to listing page - " + hrefText;
        } else {
            actionValue = "product listing 1 - title - go to listing page - ";
        }

        sendEvent("clickevent", actionValue, _this);
    },

    // Update product list measurement
    updateProductListMeasurement: function (a) {
        var cartAction = a ? (a.ACTION || "") : "";

        if (cartAction.indexOf("add to cart") > -1) {
            cartTrackingEvents.updateCartData({
                cartAdd: 1
            });
        } else if (cartAction.indexOf("remove from cart") > -1) {
            cartTrackingEvents.updateCartData({
                cartRemove: 1
            });
        } else if (cartAction.indexOf("hpScroll") > -1) {
            // let wa_data update with scroll event on UPC HP
            var prodList = JSON.parse($("[data-wadata]").last().attr("data-wadata") || "{}");

            if (prodList && "game" in prodList) {
                wa_data.pageName = "all games";
                wa_data.game = prodList.game;
                wa_data.brand = prodList.brand;
                wa_data.mdmBID = prodList.mdmBID;
                wa_data.mdmIID = prodList.mdmIID;
                wa_data.productType = prodList.productType;
            }
        }

        var isAlgoliaUsed = isAlgolia && ($(".algolia-search-result").is(":visible") || $(".container-fluid-algolia").is(":visible") || $(".algolia-nohits").is(":visible"));

        // if the page is CLP and when the user clicks on buy now button of producttile, no needed to trigger the pageevent on revamp.
        if (window.pageContext.ns == "search" || window.pageContext.ns == "universalcat" || isAlgoliaUsed) {
            return;
        }

        if (isGlobalLayerChanged()) $(document).trigger("globalLayerChanged");
    },

    // on CLP, when we click on the shop now button of producttile button, triggers the cart event.
    productListingBuyNow: function (_this, eventValue) {
        var data = "pid=" + eventValue.pid + "&action=" + eventValue.action + "&location=" + eventValue.location + "&quantity=" + eventValue.quantity;

        if (window.pageContext.ns == "search" || window.pageContext.ns == "universalcat" || algoliaTileButton == "algolia tile button") {
            $.ajax({
                type: "GET",
                url: Urls.tcBuildProductDataAjax,
                data: data,
                async: false,
                success: function (response) {
                    tc_events_100(_this, "cartevent", response);
                }
            });

            if (algoliaTileButton == "algolia tile button") {
                algoliaTileButton = "";
            }
        }
    },

    cartChangePlatform: function (_this, eventValue) {
        var page = "cart";

        if (window.pageContext.ns == "opc") {
            page = "checkout";
        }

        var actionValue = page + " - change platform - select platform - " + eventValue;

        if (targetEvent === "changePlatformPopUp") {
            actionValue = "added to cart - change platform - select platform - " + eventValue;
        }

        sendEvent("clickevent", actionValue, _this);
    },

    goToCheckout: function (_this) {
        var actionValue = "cart - navigation - go to checkout";

        sendEvent("clickevent", actionValue, _this);
    },

    emptyCart: function (_this) {
        var actionValue = "empty cart - continue shopping - shop now";

        sendEvent("clickevent", actionValue, _this);
    },

    // place order event
    placeOrder: function (_this) {
        if (!isWalletOrder) {
            var actionValue = "checkout - navigation - place order";

            sendEvent("clickevent", actionValue, _this);
        }
    },

    compareSeeMore: function (_this, eventValue) {
        var actionValue = "compare edition - go to product - see more - " + eventValue;

        sendEvent("clickevent", actionValue, _this);
    },

    compareAddToCart: function (_this, eventValue) {
        if (typeof eventValue === "object") {
            var actionValue = "compare edition - add to cart - " + eventValue.platform + " - " + eventValue.name + " - " + eventValue.edition;
            var data = "pid=" + eventValue.pid + "&action=" + eventValue.action + "&location=" + eventValue.location + "&quantity=" + eventValue.quantity;

            $.ajax({
                type: "GET",
                url: Urls.tcBuildProductDataAjax,
                data: data,
                async: false,
                success: function (response) {
                    response.ACTION = actionValue;
                    sendEvent("cartevent", response, _this);
                }
            });
        }
    },

    // go back home page button on age gate restriction page - click event
    ageGateGoBackHome: function (_this, eventValue) {
        var actionValue = eventValue ? eventValue : "";

        sendEvent("clickevent", actionValue, _this);
    },

    // click on the breadcrumb
    breadcrumbClick: function (_this) {
        var $_this = $(_this);
        var actionValue = "";

        if ($_this.data("cat-name") === "home") {
            actionValue = "header - breadcrumb - home";
            sendEvent("clickevent", actionValue, _this);
        } else if ($_this.parents(".breadcrumb").length > 0) {
            var category = $.trim($_this.parents(".breadcrumb").find(".breadcrumb-element:first").data("cat-name")),
                subCategory = $.trim($_this.data("cat-name"));

            actionValue = "header - breadcrumb - home - " + category.toLowerCase() + " - " + subCategory.toLowerCase();
            sendEvent("clickevent", actionValue, _this);
        }
    },

    slideAction: function (_this, eventValue) {
        if ($(_this).closest(".homepage-slider-wrapper").length > 0) {
            const actionValue = "hero banner - " + eventValue + " -";

            sendEvent("clickevent", actionValue, _this);
        }
    }
};

/**
 * Loads the tracking script
 * @param {boolean} isUpc - Whether we are in the Connect context (running in an iframe)
 */
function addLibs(isUpc) {
    if ($("script[src*='tc_loader.js']").length === 0) {
        var scriptElement = document.createElement("script");

        scriptElement.id = "tc_UbisoftStore";
        scriptElement.type = "text/javascript";
        scriptElement.src = "//ubistatic2-a.ubisoft.com/worldwide_analytics/tagcommander/ubisoftstore/tc_loader.js";
        document.body.appendChild(scriptElement);
    }

    loadAnalyticsSdk(isUpc);
}

/**
 * Sanitize and send events to Adobe
 */
function sendEvent(eventType, actionValue, _this) {
    var action;

    if (eventType === "lightcartevent" || eventType === "pageevent" || eventType === "cartevent") {
        action = removeSpecialCharacters(actionValue);
    } else {
        action = {
            ACTION: removeSpecialCharacters(actionValue)
        };
    }

    $cache.document.trigger("updateTcEventBuffer", {
        this: _this,
        eventType: eventType,
        action: action
    });
}

/**
 * Assign values to the global layer object properties
 */
function updateGlobalLayer() {
    if (typeof wa_data !== "undefined" && typeof wa_data === "object" && window.wa_data) {
        Object.keys(waDataObject).forEach(function (property) {
            if (typeof property === "object") {
                Object.keys(property).forEach(function (subProperty) {
                    window.wa_data[property][subProperty] = wa_data[property] && wa_data[property][subProperty] ? wa_data[property][subProperty] : waDataObject[property][subProperty];
                });
            }

            window.wa_data[property] = wa_data[property] ? wa_data[property] : waDataObject[property];
        });
    }

    var isSubscriptionCheckout = $cache.document.find("div.subscription-product-details").length > 0;

    // Checks the basket if there is a subscription product, siteSection is filled on checkout page.
    // TODO The UPC part is treated by updateDataLayerWithPageParams function that recieves the variables from backend. Refactoring the code is needed to use the same approach for WEB also, and afterwards we will not need this ajax call.
    if (pageContext && pageContext.ns === "opc" && enabledSubscription && enabledSubscriptionTagCommander && isSubscriptionCheckout) {
        $.ajax({
            type: "GET",
            url: Urls.getSubscriptionProducts,
            success: function (response) {
                if (response) {
                    wa_data.siteSection = response;
                }
            }
        });
    }

    // fallback if wa_data.pageName is empty
    if (isShipping) {
        wa_data.pageName = "shipping";
        wa_data.siteSection = "checkout pages";
    } else if (isBilling) {
        wa_data.pageName = "billing";
        wa_data.siteSection = "checkout pages";
    } else if (isSummary) {
        wa_data.pageName = "order confirmation";

        if (isWalletTagCommanderEnabled && isWalletOrder) {
            wa_data.siteSection = "wallet - checkout pages";
        } else {
            wa_data.siteSection = "checkout pages";
        }
    } else if (isThankYou) {
        if (isWalletTagCommanderEnabled && isWalletOrder && pageContext && pageContext.ns == "orderconfirmation") {
            wa_data.siteSection = "wallet - checkout pages";
        } else {
            wa_data.pageName = "thank you page";
            wa_data.siteSection = wa_data.siteSection || "checkout pages";
        }
    } else if (isAgeGate) {
        wa_data.pageName = "what is your birth date";
        wa_data.siteSection = "age gate";
    } else if (isBadAge) {
        wa_data.pageName = "not allowed";
        wa_data.siteSection = "age gate";
    }

    // for UPC, error message can be contained where isBilling not occured. Therefore moved out of isBilling case.
    if (isBillingError) {
        wa_data.pageName = "billing error";
        wa_data.errorType = $("[data-waerror]").data("waerror");
    }

    if (enabledUPCSubscriptionTagCommander && isLegacy && pageContext.type == "orderconfirmation") {
        var isSubscriptionOrder = $cache.document.find(".subscription-order-confirmation-footer").length > 0 ? true : false;

        if (isSubscriptionOrder && !wa_data.siteSection) {
            wa_data.siteSection = Resources.UBISOFT_PLUS_TITLE + " - checkout pages";
        }
    } else if (enabledUPCSubscriptionTagCommander && isLegacy && pageContext.type == "Cart") {
        if (isWalletTagCommanderEnabled && isWalletOrder) {
            wa_data.siteSection = "wallet - cart";
        } else if (isSubscriptionProduct) {
            wa_data.siteSection = Resources.UBISOFT_PLUS_TITLE + " - cart";
        } else {
            wa_data.siteSection = "cart";
        }
    }

    if (enabledSubscription && enabledSubscriptionTagCommander) {
        // Uplay+ Product Page - Page Event
        if (pageContext && pageContext.ns === "subscription") {
            wa_data.pageName = Resources.UBISOFT_PLUS_TITLE;
            wa_data.siteSection = "product page";
            wa_data.game = Resources.UBISOFT_PLUS_TITLE;
            wa_data.brand = Resources.UBISOFT_PLUS_TITLE;
            wa_data.productType = "subscription";
        }

        // Manage my subscription page
        if (pageContext.ns == "account" && (window.location.href.indexOf("Account-Show") > -1 || window.location.href.indexOf("my-account") > -1)) {
            wa_data.pageName = "my subscription";
            wa_data.siteSection = "my account";
            wa_data.game = Resources.UBISOFT_PLUS_TITLE;
            wa_data.brand = Resources.UBISOFT_PLUS_TITLE;
            wa_data.productType = "subscription";
        }

        // Checks the thank you page if there is a subscription product, siteSection is filled on thank you page.
        if (window.location.href.indexOf("order-success") > -1) {
            var isSubProduct = $("#confirmationIsSubscription").val();

            if (isSubProduct == "true" && !wa_data.siteSection) {
                wa_data.siteSection = Resources.UBISOFT_PLUS_TITLE + " - checkout pages";
            }
        }

        if (isGamingListPageURL) {
            var customerStatus = $cache.document.find(".customer_status").length > 0 ? $cache.document.find(".customer_status").data("subs-status") : "";

            if (customerStatus == "Active") {
                wa_data.pageName = "welcome to " + Resources.UBISOFT_PLUS_TITLE;
                wa_data.siteSection = "product listing";
                wa_data.game = Resources.UBISOFT_PLUS_TITLE;
                wa_data.brand = Resources.UBISOFT_PLUS_TITLE;
                wa_data.productType = "subscription";
            } else {
                wa_data.pageName = "discover " + Resources.UBISOFT_PLUS_TITLE;
                wa_data.siteSection = "product listing";
                wa_data.game = Resources.UBISOFT_PLUS_TITLE;
                wa_data.brand = Resources.UBISOFT_PLUS_TITLE;
                wa_data.productType = "subscription";
            }
        }
    }

    // UPC - wa_data object - subscription values
    if (enabledUPCSubscriptionTagCommander && (pageContext.type != "Cart" && pageContext.ns != "checkout" && pageContext.type != "orderconfirmation")) {
        createUPCSubscriptionPageValues();
    }

    if (isWalletTagCommanderEnabled) {
        initializeWalletPageEvents();
    }

    updateDataLayerWithPageParams();
}

/**
 * Initialize wallet page events & wa_data parameters
 * @private
 */
function initializeWalletPageEvents() {
    if (isUpc && isWalletOrder && pageContext && (pageContext.ns == "cart" || pageContext.ns == "checkout")) {
        wa_data.siteSection = "wallet - checkout pages";
    } else if (pageContext && pageContext.ns == "wallettopuppage") {
        wa_data.pageName = "adds funds to your wallet";
        wa_data.siteSection = "wallet - checkout pages";
    } else if (pageContext && pageContext.ns == "walletmanagementpage") {
        wa_data.pageName = "my wallet";
        wa_data.siteSection = "wallet";
        wa_data.game = "multiple brands";
        wa_data.brand = "multiple brands";
        wa_data.mdmBID = "multiple brands";
        wa_data.mdmIID = "multiple brands";
    } else if (pageContext && (pageContext.ns == "opc" || pageContext.ns == "checkout") && isWalletOrder) {
        var checkoutStep = $(".onepagecheckout").data("checkoutstep");

        if (checkoutStep == "summary") {
            wa_data.pageName = "order confirmation";
            wa_data.siteSection = "wallet - checkout pages";
        } else if (checkoutStep == "payment") {
            wa_data.pageName = "billing";
            wa_data.siteSection = "wallet - checkout pages";
        }
    } else if (pageContext && pageContext.ns == "orderconfirmation" && isWalletOrder) {
        var walletAddedAmount = $("#check-wallet-added-amount").data("wallet-added-amount") ? $("#check-wallet-added-amount").data("wallet-added-amount") : "";

        wa_data.pageName = "thank you page";
        wa_data.siteSection = "wallet - checkout pages";
        wa_data.newWalletAmountAdded = walletAddedAmount;
        wa_data.newWalletAddedOrigin = "wallet checkout";
    } else if (pageContext && pageContext.ns == "walletterminationdetailspage") {
        wa_data.pageName = "termination page";
        wa_data.siteSection = "wallet";
    } else if (pageContext && pageContext.ns == "wallettransactionhistorypage") {
        wa_data.pageName = "last transactions";
        wa_data.siteSection = "wallet";
    }

    walletErrorPageTracking();
}

/**
 * Initialize tracking the wallet errors of checkout page for wallet orders.
 * @private
 */
function walletErrorPageTracking() {
    // CHECKOUT - ERROR MESSAGES TRACKING
    var errorMessage;
    var isPaidWithWallet = $cache.document.find("wallet-total").length;

    if (isUpc && pageContext && pageContext.ns == "orderconfirmation" && isPaidWithWallet) {
        wa_data.cart.paymentMethod = "wallet";
    } else if (isUpc && pageContext && pageContext.ns == "checkout" && isWalletOrder) {
        errorMessage = $("#error-message-type").length ? $("#error-message-type").data("error-id") : "";

        if (errorMessage) {
            errorMessage = errorMessage.replace(/-/g, "_").toUpperCase();

            wa_data.pageName = "billing - error";
            wa_data.siteSection = "wallet - checkout pages";
            wa_data.errorType = errorMessage;
        }
    } else if (pageContext && pageContext.ns == "opc" && isWalletOrder) {
        errorMessage = !$(".checkout-error-messages").hasClass("hide") ? $(".checkout-error-messages").data("error-id") : "";

        if (errorMessage) {
            errorMessage = errorMessage.replace(/-/g, "_").toUpperCase();

            wa_data.pageName = "billing - error";
            wa_data.siteSection = "wallet - checkout pages";
            wa_data.errorType = errorMessage;
        }
    }
}

/**
 * @function removeSpecialCharacters Removes special characters from data layer
 * @param {Object} dataLayer - wa_data object used in TagCommander.
 * @return {String} dataLayer - datalayer
 */
function removeSpecialCharacters(dataLayer) {
    if (SitePreferences.TC_SPECIAL_CHARACTERS_REGEX && SitePreferences.TC_SPECIAL_CHARACTERS_REGEX != "") {
        var specialCharactersRegex = new RegExp(SitePreferences.TC_SPECIAL_CHARACTERS_REGEX, "g");

        if (typeof dataLayer !== "undefined" && typeof dataLayer === "object" && dataLayer !== null) {
            var value;

            Object.keys(dataLayer).forEach(function (property) {
                if (typeof dataLayer[property] === "object") {
                    removeSpecialCharacters(dataLayer[property]);
                } else if (typeof dataLayer[property] === "string") {
                    value = unescape(dataLayer[property]);

                    if (isNaN(value)) {
                        dataLayer[property] = value.replace(specialCharactersRegex, "").trim().toLowerCase();
                    }
                }
            });
        } else if (typeof dataLayer !== "undefined" && typeof dataLayer === "string") {
            value = unescape(dataLayer);

            if (isNaN(value)) {
                dataLayer = value.replace(specialCharactersRegex, "").trim().toLowerCase();
            }
        }
    }

    return dataLayer;
}

/**
 * Triggered every time when global data layer changes for pageEvents
 */
function isGlobalLayerChanged() {
    var retVal = false;

    if (typeof wa_data !== "undefined" && typeof wa_data === "object" && window.wa_data) {
        Object.keys(waDataObject).forEach(function (property) {
            if (typeof property === "object") {
                Object.keys(property).forEach(function (subProperty) {
                    if ($cache.wa_data[property][subProperty] != wa_data[property][subProperty]) {
                        $cache.wa_data = Object.assign({}, removeSpecialCharacters(wa_data));
                        retVal = true;
                    }
                });
            }

            if ($cache.wa_data[property] != wa_data[property]) {
                $cache.wa_data = Object.assign({}, removeSpecialCharacters(wa_data));
                retVal = true;
            }
        });
    }

    return retVal;
}

function createSubscriptionClickEvents() {
    // Navigation Menu - Click Event - Uplay +
    $cache.document.on("click", "#sub-tag-nav-uplay", function () {
        tc_events_100(this, "clickevent", {
            ACTION: "header - menu - " + Resources.UBISOFT_PLUS_TITLE
        });
    });

    // PDP - Click Event - 'Learn More' link
    $cache.document.on("click", "#tag-learn-more", function () {
        tc_events_100(this, "clickevent", {
            ACTION: "product info - go to product - ubisoft plus"
        });
    });

    // PDP - Click Event -'Add to Library' button
    $cache.document.on("click", "#tag-add-to-library", function () {
        tc_events_100(this, "clickevent", {
            ACTION: "product info - uplay plus - add to library - " + $(this).data("product-name").toLowerCase()
        });
    });

    // PDP - Click Event -'Start Playing Now' button
    $cache.document.on("click", "#tag-start-playing-now", function () {
        tc_events_100(this, "clickevent", {
            ACTION: "product info - play - ubisoft plus"
        });
    });

    // PDP - Click Event -'Join UPLAY+' button
    $cache.document.on("click", ".tag-get-uplay-plus", function () {
        tc_events_100(this, "clickevent", {
            ACTION: "product info - subscribe - ubisoft plus"
        });
    });

    // 'Do you have UPLAYPC' popup events

    // close popup event
    $cache.document.on("click", ".close-button", function () {
        if ($(this).parent("#open-uplaypc").length > 0) {
            tc_events_100(this, "clickevent", {
                ACTION: "do you have Uplay - cta - close popin"
            });
        }
    });

    // launch UPLAYPC button event
    $cache.document.on("click", ".launch-uplay-pc-button", function () {
        if ($("#open-uplaypc").find(".launch-uplay-pc-button").length > 0) {
            tc_events_100(this, "clickevent", {
                ACTION: "do you have Uplay - cta - launch uplay pc"
            });
        }
    });

    // download UPLAYPC button event
    $cache.document.on("click", ".download-uplay-pc-button", function () {
        if ($("#open-uplaypc").find(".download-uplay-pc-button").length > 0) {
            tc_events_100(this, "clickevent", {
                ACTION: "do you have Uplay - cta - download uplay pc"
            });
        }
    });

    // Choose your plan pop-in - Click Event - Close button
    $cache.document.on("click", ".close-button", function () {
        if ($(this).parent("#ubisoft-pass-choice").length > 0) {
            tc_events_100(this, "clickevent", {
                ACTION: "choose your plan - cta - close popin"
            });
        }
    });
    // Filters - Clear All
    $(document).on("click", "#clearAllFilters", function () {
        tc_events_100(this, "clickevent", {
            ACTION: "listing page - filter removed - all filters"
        });
    });

    if (pageContext.ns === "orderconfirmation" || pageContext.ns === "subscriptionconfirmation") {
        // Confirmation Page - Click Event - Launch Uplay PC Button
        $cache.document.on("click", "#tag-sub-launch-link-confirmation", function () {
            tc_events_100(this, "clickevent", {
                ACTION: "thank you page - cta - launch Uplay"
            });
        });

        // Confirmation Page - Click Event - Download Uplay PC Button
        $cache.document.on("click", "#tag-sub-download-link-confirmation", function () {
            tc_events_100(this, "clickevent", {
                ACTION: "thank you page - cta - download Uplay"
            });
        });

        // Confirmation Page - Click Event - Browse All Games Button
        $cache.document.on("click", ".tag-subs-browse-all-games", function () {
            tc_events_100(this, "clickevent", {
                ACTION: "thank you page - navigation – start playing"
            });
        });

        // Confirmation Page - Click Event - Back to my account page
        $cache.document.on("click", ".to-account-cta", e => {
            tc_events_100(e.currentTarget, "clickevent", {
                ACTION: "thank you page - navigation - my account"
            });
        });
    }

    // Gaming List Page - Disclaimer Content Asset for inactive user - Click Event - Get Ubisoft Pass button
    $cache.document.on("click", ".pass-disclaimer-inactive-button", function () {
        tc_events_100(this, "clickevent", {
            ACTION: "uplay plus renew push - get uplay plus - choose your plan"
        });
    });

    // Choose your plan pop-in - Click Event - list of games - choose a list
    $cache.document.on("click", ".available-games-title", e => {
        // not handle programatic click event
        if (!("originalEvent" in e)) {
            return;
        }

        let platformName = $(e.currentTarget).data("gameslist") || "";

        if (platformName === "amazon") {
            platformName = "luna";
        }

        tc_events_100(e.currentTarget, "clickevent", {
            ACTION: "list of games - interaction - " + platformName.toLowerCase()
        });
    });
}

function createWishListEvents() {
    var $isWishlistModalClosed = false;
    var $isWishlistBackBtn = false;

    // Global Menu Tracking - WishList
    $cache.document.on("click", ".wishlist-header, .wishlist-header-icon", function () {
        tc_events_100(this, "clickevent", {
            ACTION: "header - navigation - wishlist"
        });
    });

    // Shop Now CTA tracking of the empty wishlist
    $cache.document.on("click", ".shop-now-cta-wrapper", function () {
        tc_events_100(this, "clickevent", {
            ACTION: "wishlist - navigation - shop now"
        });
    });

    // Trigger event on click of see my wishlist button
    $cache.document.on("click", ".see-my-wishlist", function () {
        tc_events_100(this, "clickevent", {
            ACTION: "wishlist popin – navigation – see my wishlist”"
        });
    });

    // Trigger event on closing of the notificatin popin
    $cache.document.on("click", ".close-wishlist-notification", function () {
        tc_events_100(this, "clickevent", {
            ACTION: "wishlist popin – action – close popin"
        });
    });

    // Tracking event when clicking the "Share wishlist" button on wishlist page.
    $cache.document.on("click", ".wishlist-share-button", function () {
        tc_events_100(this, "clickevent", {
            ACTION: "wishlist – share – share wishlist"
        });
        $isWishlistModalClosed = false;
        $isWishlistBackBtn = false;
    });

    // Tracking event when clicking the "Copy link" button from wishlist share pop-in.
    $cache.document.on("click", ".copy-wishlist-link", function () {
        tc_events_100(this, "clickevent", {
            ACTION: "wishlist – share – share on url link"
        });
    });

    // Tracking event when clicking on a specific sharing method from wishlist share pop-in.
    $cache.document.on("click", ".wishlist-share-method", function () {
        var $this = $(this);
        var $sharingMethodID = $this.attr("data-sharing-method-id");

        tc_events_100(this, "clickevent", {
            ACTION: "wishlist – share – share on " + $sharingMethodID
        });
    });

    // Tracking event when clicking the "Back" button from wishlist share pop-in.
    $cache.document.on("click", ".wishlist-back-button", function () {
        tc_events_100(this, "clickevent", {
            ACTION: "wishlist – share – back button"
        });
        $isWishlistBackBtn = true;
    });

    // Tracking event when clicking on "Close" button frow wishlist share pop-in.
    if ($cache.wishlistShareModal.length > 0) {
        $cache.wishlistShareModal.on("click", ".close-button", function () {
            if (window.pageContext.ns === "wishlist") {
                tc_events_100(this, "clickevent", {
                    ACTION: "wishlist – share – close popin"
                });
                $isWishlistModalClosed = true;
            }
        });
    }

    $cache.document.on("click", ".wishlist-share-popup > div > .ui-dialog-titlebar-close", function () {
        tc_events_100(this, "clickevent", {
            ACTION: "wishlist – share – close popin"
        });
    });

    // Tracking event when clicking outside of the wishlist share pop-in or pressing "Escape" button from the keyboard. These events close the wishlist share pop-in.
    $cache.document.on("closed.zf.reveal", "#wishlist-share-popup", function () {
        // This condition is used because sometimes when we click on the "Back" button from wihslist share pop-in, this entire event triggers before the main functionality of "Back" button
        if (!$isWishlistBackBtn) {
            $isWishlistBackBtn = this.ownerDocument.activeElement.className.indexOf("wishlist-back-button") > -1;
        }

        if (window.pageContext.ns === "wishlist" && !$isWishlistModalClosed && !$isWishlistBackBtn) {
            tc_events_100(this, "clickevent", {
                ACTION: "wishlist – share – close popin"
            });
        }
    });

    // Tracking event when activating or/and deactivating the email notification switcher.
    if ($cache.notificationsSwitchAll.length > 0) {
        $cache.document.on("change", $cache.notificationsSwitchAll, function () {
            var isActiveSwitcher = $cache.notificationsSwitchAll.prop("checked");

            if (isActiveSwitcher) {
                tc_events_100(this, "clickevent", {
                    ACTION: "wishlist – email notification – activate"
                });
            } else {
                tc_events_100(this, "clickevent", {
                    ACTION: "wishlist – email notification – deactivate"
                });
            }
        });
    }
}

function createSubscriptionMyAccountEvents() {
    if (pageContext.ns == "account") {
        // My Account Page - Click Event - Resume Membership button

        // My Account Page - Click Event - Reactivate Membership button
        $cache.document.on("click", "#account-ubisoftplus .tag-reactivate-membership", e => {
            if (wa_data) {
                wa_data.pageName = "select your plan";
                wa_data.siteSection = Resources.UBISOFT_PLUS_TITLE + " : reactivation";

                const productId = $(e.target).data("subscription-product-id");

                if (productId) {
                    wa_data.productID = productId;
                }
            }

            tc_events_100(e.currentTarget, "clickevent", {
                ACTION: "my subscription : " + Resources.UBISOFT_PLUS_TITLE + " - navigation - reactivate subscription"
            });
        });

        // My Account - Click Event - Reactivate subscription
        $cache.document.on("click", ".ubisoft-reactivate-subscription .js-reactivate-subscription", e => {
            tc_events_100(e.currentTarget, "clickevent", {
                ACTION: Resources.UBISOFT_PLUS_TITLE + " : reactivation - navigation - pay and start your subscription"
            });
        });

        // ################## PAYMENT & ADDRESS EVENTS ##################

        // My Account - Pop-up (Address) - Click Event - Add New Address link
        $cache.document.on("click", "#tag-add-new-payment", function () {
            tc_events_100(this, "clickevent", {
                ACTION: "my account - " + Resources.UBISOFT_PLUS_TITLE + " - add address - open"
            });
        });

        // My Account - Click Event - Address editing/adding success
        $cache.document.on("click", "#tag-address-success", function () {
            var addressType = $("#tag-address-type").data("address-type");

            if (addressType == "edit") {
                tc_events_100(this, "clickevent", {
                    ACTION: "my account - " + Resources.UBISOFT_PLUS_TITLE + " - edit address - success"
                });
            } else {
                tc_events_100(this, "clickevent", {
                    ACTION: "my account - " + Resources.UBISOFT_PLUS_TITLE + " - add address - success"
                });
            }
        });

        // My Account - Click Event - Address editing/adding error
        $cache.document.on("click", "#tag-address-error", function () {
            var addressType = $("#tag-address-type").data("address-type");

            if (addressType == "edit") {
                tc_events_100(this, "clickevent", {
                    ACTION: "my account - " + Resources.UBISOFT_PLUS_TITLE + " - edit address - error"
                });
            } else {
                tc_events_100(this, "clickevent", {
                    ACTION: "my account - " + Resources.UBISOFT_PLUS_TITLE + " - add address - error"
                });
            }
        });

        // My Account - Click Event - Payment adding success
        $cache.document.on("click", "#tag-payment-success", function () {
            var paymentType = $("#tag-payment-type-account").data("payment-type") ? $("#tag-payment-type-account").data("payment-type") : "";

            tc_events_100(this, "clickevent", {
                ACTION: "my account - " + Resources.UBISOFT_PLUS_TITLE + " - add payment method - " + paymentType + " - success"
            });
        });

        // My Account - Click Event - Cancel switch request banner
        $cache.document.on("click", "#account-ubisoftplus .cancel-downgrade-banner", e => {
            tc_events_100(e.currentTarget, "clickevent", {
                ACTION: "my subscription : " + Resources.UBISOFT_PLUS_TITLE + " - navigation - your subscription has changed : cancel switch request"
            });
        });
    }
}

/*
* Add tracking event handlers to the Switch subscription plan flow
*/
function switchSubscriptionPlanEvents() {
    if (pageContext && (pageContext.ns === "planselection" || pageContext.ns === "planreview" || pageContext.ns === "subscriptionconfirmation")) {
        // Switch Subscription Plan - Click Event - Cancel button
        $cache.document.on("click", ".selection-cancel", e => {
            tc_events_100(e.currentTarget, "clickevent", {
                ACTION: Resources.UBISOFT_PLUS_TITLE + " : switch plan - navigation - cancel"
            });
        });

        // Switch Subscription Plan - Click Event - Review change plan button
        $cache.document.on("click", ".review-changes", e => {
            tc_events_100(e.currentTarget, "clickevent", {
                ACTION: Resources.UBISOFT_PLUS_TITLE + " : switch plan - navigation - review changes"
            });
        });

        // Switch Subscription Plan - Click Event - Confirm plan change button
        $cache.document.on("click", ".confirm-plan-change", e => {
            tc_events_100(e.currentTarget, "clickevent", {
                ACTION: Resources.UBISOFT_PLUS_TITLE + " : switch plan - navigation - confirm"
            });
        });

        // Switch Subscription Plan - Click Event - Confirmation back to account button
        $cache.document.on("click", ".plan-switch-container .plan-selection-buttons a", e => {
            tc_events_100(e.currentTarget, "clickevent", {
                ACTION: Resources.UBISOFT_PLUS_TITLE + " : switch plan - navigation - back to my account"
            });
        });
    }
}

/*
* Add tracking event handlers to the Cancel Switch Request flow
*/
function cancelSwitchRequestEvents() {
    if (pageContext && (pageContext.ns === "switchcancellation" || pageContext.ns === "planreview")) {
        // Cancel Switch Request - Click Event - Cancel button
        $cache.document.on("click", ".cancel-switch-cancellation", e => {
            tc_events_100(e.currentTarget, "clickevent", {
                ACTION: Resources.UBISOFT_PLUS_TITLE + " : cancel switch - navigation - go back"
            });
        });

        // Cancel Switch Request - Click Event - Re-Activate
        $cache.document.on("click", ".confirm-switch-cancellation", e => {
            tc_events_100(e.currentTarget, "clickevent", {
                ACTION: Resources.UBISOFT_PLUS_TITLE + " : cancel switch - navigation - yes cancel"
            });
        });

        // Cancel Switch Request - Click Event - Back to My Account button
        $cache.document.on("click", ".switch-cancellation-section a", e => {
            tc_events_100(e.currentTarget, "clickevent", {
                ACTION: Resources.UBISOFT_PLUS_TITLE + " : cancel switch - navigation - back to my account"
            });
        });
    }
}

// Early Registration/UPlay+ Landing Page Events
function createEarlyRegistrationEvents() {
    if (pageContext && (pageContext.ns == "earlyregistration" || pageContext.ns == "subscription")) {
        // Early Registration/UPlay+ Landing Page - Click Event - Sign Up button
        $cache.document.on("click", ".early-registration-button, .open-ubisoft-pass-choice, .btn-havelock-blue", function () {
            var currentSection = $(this).parent().closest("section").attr("id");
            var hasSlick = $(this).parent().closest(".slick-slide");
            var sliderNumber = (hasSlick.length) ? hasSlick.data("slick-index") + 1 : 1;
            var customerStatus = $cache.document.find(".customer_status").length > 0 ? $cache.document.find(".customer_status").data("subs-status") : "";
            var actionValueHero = "";
            var actionValueFan = "";

            if (customerStatus == "Inactive") {
                actionValueHero = "hero banner - slider " + sliderNumber + " - get " + Resources.UBISOFT_PLUS_TITLE + " - renew your plan";
                actionValueFan = "bottom page banner - get " + Resources.UBISOFT_PLUS_TITLE + " - renew your plan";
            } else if (customerStatus == "Active") {
                actionValueHero = "hero banner - slider " + sliderNumber + " - " + Resources.UBISOFT_PLUS_TITLE + " – manage my subscription";
                actionValueFan = "bottom page banner - get " + Resources.UBISOFT_PLUS_TITLE + " - " + Resources.UBISOFT_PLUS_TITLE + " – manage my subscription";
            } else {
                actionValueHero = "hero banner - slider " + sliderNumber + " - subscribe - " + Resources.UBISOFT_PLUS_TITLE;
                actionValueFan = "bottom page banner - subscribe - " + Resources.UBISOFT_PLUS_TITLE;
            }

            if (currentSection == "hero" || (!currentSection && $(this).parent().closest("section").hasClass("ubiP-hero")) || (!currentSection && $(this).parent().closest("section").hasClass("uplay-plus_hero"))) {
                tc_events_100(this, "clickevent", {
                    ACTION: actionValueHero
                });
            }

            if (currentSection == "fan") {
                tc_events_100(this, "clickevent", {
                    ACTION: actionValueFan
                });
            }
        });

        // Early Registration/UPlay+ Landing Page - Click Event - Watch Video button
        $cache.document.on("click", ".jsubiP-hero-watchVideo", function () {
            var currentSection = $(this).parent().closest("section").attr("id");

            if (currentSection == "hero" || (!currentSection && $(this).parent().closest("section").hasClass("ubiP-hero"))) {
                var hasSlick = $(this).parent().closest(".slick-slide");
                var sliderNumber = (hasSlick.length) ? hasSlick.data("slick-index") + 1 : 1;

                tc_events_100(this, "clickevent", {
                    ACTION: "hero banner - slider " + sliderNumber + " - uplay plus - watch video"
                });
            }

            if (currentSection == "fan") {
                tc_events_100(this, "clickevent", {
                    ACTION: "bottom page banner - uplay plus - watch video"
                });
            }
        });

        // Early Registration/UPlay+ Landing Page - Click Event - "What is it" section's items.
        $cache.document.on("click", ".ubiP-whatisit-item", function () {
            var currentSection = $(this).parent().closest("section").attr("id");

            if (currentSection == "whatisit") {
                var currentItemText = $(this).find("h4").length > 0 ? $(this).find("h4").data("title").toLowerCase() : "";

                tc_events_100(this, "clickevent", {
                    ACTION: "what is uplay plus - tab - " + currentItemText
                });
            }
        });

        // Early Registration/UPlay+ Landing Page - Click Event - FAQ Button.
        $cache.document.on("click", ".button-faq", function () {
            tc_events_100(this, "clickevent", {
                ACTION: "faq - navigation - read faq"
            });
        });
    }
}

/**
 * @description Creates refund tag commander events
 */
function createRefundEvents() {
    $cache.document.on("click", "#refund-cta", function () {
        tc_events_100(this, "clickevent", {
            ACTION: "order history - navigation – ask for a refund"
        });
    });

    $cache.document.on("click", "#tc-ask-for-refund", function () {
        let reason = $('input[name="dwfrm_refund_subject"]:checked').val().toLowerCase();
        let action = "my account - navigation – ask for a refund – " + reason.split("/").pop();

        tc_events_100(this, "clickevent", {
            ACTION: action
        });
    });

    $cache.document.on("click", "#refund-retry-btn", function () {
        tc_events_100(this, "clickevent", {
            ACTION: "my account - navigation - ask for a refund - try again"
        });
    });

    $cache.document.on("click", "#tc-refund-error-playtime-refused", function () {
        tc_events_100(this, "pageevent", {
            ACTION: "ask for a refund error",
            SITESECTION: "my account",
            ERRORTYPE: "playtime is longer than 2h"
        });
    });

    $cache.document.on("click", "#tc-refund-error-playtime-service-fail", function () {
        tc_events_100(this, "pageevent", {
            ACTION: "ask for a refund error",
            SITESECTION: "my account",
            ERRORTYPE: "playtime service is failed"
        });
    });

    $cache.document.on("click", "#tc-refund-error-ownership-failed", function () {
        tc_events_100(this, "pageevent", {
            ACTION: "ask for a refund error",
            SITESECTION: "my account",
            ERRORTYPE: "ownership service is failed"
        });
    });

    $cache.document.ready(function () {
        if ($("#refund-page-content").length) {
            wa_data.pageName = "ask for a refund";
            wa_data.siteSection = "my account";
        }

        if ($("#refund-accepted-message").length) {
            var data = $("#product-to-refund").data("pid");

            $.ajax({
                type: "GET",
                url: Urls.tcGetProductData,
                data: "productID=" + data,
                async: false
            }).done(function (response) {
                if (response != "" || response != null || response != undefined) {
                    var parseResponse = JSON.parse(response);

                    if (parseResponse != "" || parseResponse != null || parseResponse != undefined) {
                        wa_data = parseResponse;
                    }
                }
            });
            wa_data.pageName = "ask for a refund success";
            wa_data.siteSection = "my account";
        }
    });
}

/**
 * @description Creates Arrow tag commander events
 */
function createArrowRefundEvents() {
    $cache.document.on("click", ".faq-item", function () {
        let $item = $(this).find(".faq-item-description");
        var currentItemText = $item.length > 0 ? $item.text().toLowerCase() : "";

        tc_events_100(this, "clickevent", {
            ACTION: "'refund checkout - navigation - faq :" + currentItemText
        });
    });
    $cache.document.on("click", "button[name='dwfrm_arrow_pay']", function () {
        tc_events_100(this, "clickevent", {
            ACTION: "refund checkout - navigation - go to billing"
        });
    });
    $cache.document.on("click", "button[name='dwfrm_arrow_submit']", function () {
        tc_events_100(this, "pageevent", {
            ACTION: "billing",
            SITESECTION: "refund checkout"
        });
    });

    $cache.document.ready(function () {
        let $refundProcessStep = $("#refund-process-step") ;

        if ($refundProcessStep.length) {
            var stepNumber = $refundProcessStep.data("refundprocessstep");

            // prep wallet data
            if (stepNumber == 3) {
                let $paymentMethod = $("#payment_method").find(".value");
                var refundMethod = $paymentMethod.length ? $paymentMethod.text() : "N/A";

                if (refundMethod.toLowerCase() == "wallet") {
                    let $totalRefunded = $("#total_refund").find(".value");
                    var refundAmount = $totalRefunded.length ? $totalRefunded.text() : "N/A";

                    wa_data.newWalletAmountAdded = refundAmount;
                    wa_data.newWalletAddedOrigin = "refund checkout";
                }
            }

            // load refund data
            $.ajax({
                type: "GET",
                url: Urls.tcGetRefundData,
                data: "step=" + stepNumber,
                async: false
            }).done(function (response) {
                if (response != "" && response != null && response != undefined) {
                    var parseResponse = JSON.parse(response);

                    if (parseResponse != "" && parseResponse != null && parseResponse != undefined) {
                        wa_data.refund = parseResponse;
                    }
                }
            });

            switch (stepNumber) {
                case 1:
                    wa_data.pageName = "product selection";
                    wa_data.siteSection = "refund checkout";
                    break;
                case 2:
                    wa_data.pageName = "billing";
                    wa_data.siteSection = "refund checkout";
                    break;
                case 3:
                    wa_data.pageName = "thank you page";
                    wa_data.siteSection = "refund checkout";
                    break;
                case 0:
                    wa_data.pageName = "thank you page";
                    wa_data.siteSection = "refund checkout";
                    break;
            }
        }
    });
}

/**
 * @description Creates WIS tag commander events
 */
function createWISEvents() {
    var $isWiSModalClosed = false;
    var $isWisApplied = false;
    var context = "cart";

    if ($cache.orderRecapBlock.length > 0) {
        context = $cache.orderRecapBlock.data("context");

        if ($cache.orderRecapBlock.data("hash")) {
            tc_events_100(this, "clickevent", {
                ACTION: context + " - win in shop - displayed"
            });
        }
    }

    // Tracking event when clicking the "Apply" button for WIS on cart.
    $cache.document.on("click", ".apply-wis", function () {
        tc_events_100(this, "clickevent", {
            ACTION: context + " - win in shop - apply"
        });
        $isWiSModalClosed = false;
    });

    // Tracking event when clicking on "Redeem units" button for WIS on cart.
    $cache.document.on("click", ".redeem-now-wis", function () {
        tc_events_100(this, "clickevent", {
            ACTION: context + " - win in shop - redeem now"
        });
        $isWisApplied = true;
    });

    // Tracking event when clicking on "Learn more about compatible products" link in WIS pop-in on cart.
    $cache.document.on("click", ".learn-more-wis", function () {
        tc_events_100(this, "clickevent", {
            ACTION: context + " - win in shop - learn more about compatible products"
        });
    });

    // Tracking event when clicking on "Accept terms" check-box for WIS pop-in on cart.
    $cache.document.on("click", ".accept-terms-wis", function () {
        tc_events_100(this, "clickevent", {
            ACTION: context + " - win in shop - terms of use accepted"
        });
    });

    // Tracking event when clicking on "OK" button in "non-combinable products" pop-in for WIS.
    $cache.document.on("click", ".apply-best-promo", function () {
        tc_events_100(this, "clickevent", {
            ACTION: context + " - win in shop - promotions not combinables"
        });
    });

    // Tracking event when clicking on "Close" button for the WIS dialog.
    if ($cache.wisModal.length > 0) {
        $cache.wisModal.on("click", ".close-button", function () {
            if (window.pageContext.ns === "cart" || window.pageContext.ns === "opc") {
                tc_events_100(this, "clickevent", {
                    ACTION: context + " - win in shop - close pop in"
                });
                $isWiSModalClosed = true;
            }
        });
    }

    // Tracking event when clicking outside of the WIS dialog or pressing "Escape" button from the keyboard. These events close the WIS pop-in.
    $cache.document.on("closed.zf.reveal", ".club-discount-modal", function () {
        if ((window.pageContext.ns === "cart" || window.pageContext.ns === "opc") && !$isWiSModalClosed && !$isWisApplied) {
            tc_events_100(this, "clickevent", {
                ACTION: context + " - win in shop - close pop in"
            });
        }

        $isWisApplied = false;
    });

    // Tracking event when opened "Promo not compatible" pop-in for WIS.
    $cache.document.on("open.zf.reveal", ".club-promo-nocompatible-modal", function () {
        tc_events_100(this, "clickevent", {
            ACTION: context + " - win in shop - promotion not compatible"
        });
    });

    $cache.document.on("click", ".js-remove-promocode", function () {
        if ($(this).data("coupon-type") == "wis") {
            tc_events_100(this, "clickevent", {
                ACTION: context + " - win in shop - remove win in shop"
            });
        } else {
            tc_events_100(this, "clickevent", {
                ACTION: context + " - promo code - remove a promo code"
            });
        }
    });

    $cache.document.on("click", ".js-open-not-comp", function () {
        tc_events_100(this, "clickevent", {
            ACTION: context + " - win in shop - promotion not compatible"
        });
    });
}

function createUPCSubscriptionPageValues() {
    if (pageContext.ns == "subscriptioncancelation") {
        wa_data.game = Resources.UBISOFT_PLUS_TITLE;
        wa_data.brand = Resources.UBISOFT_PLUS_TITLE;
        wa_data.productType = "subscription";
    }

    if (pageContext.ns == "account" && (window.location.href.indexOf("Subscription-Show") > -1 || window.location.href.indexOf("uplayplus-manage") > -1)) {
        wa_data.pageName = "my subscription";
        wa_data.siteSection = "my account";
        wa_data.game = "ubisoft plus";
        wa_data.brand = "ubisoft plus";
        wa_data.productType = "subscription";

        var dataUplayPlus = $("#tag-sub-uplay-plus").data("sub-uplay-plus");
        var dataUplayType = $("#tag-sub-type").data("sub-type");
        var dataUplayDate = $("#tag-sub-date").data("sub-date");

        wa_data.uplayPlus = dataUplayPlus != undefined ? dataUplayPlus : "never subscribers";
        wa_data.subscriptionType = dataUplayType != undefined ? dataUplayType : "";
        wa_data.subscriptionDate = dataUplayDate != undefined ? dataUplayDate : "";
    }

    if (isUbiPassLandingPageURL) {
        wa_data.pageName = "ubisoft plus";
        wa_data.siteSection = "product page";
        wa_data.game = "ubisoft plus";
        wa_data.brand = "ubisoft plus";
        wa_data.productType = "subscription";
    } else if (isGamingListPageURL) {
        var customerStatus = $cache.document.find(".customer_status").length > 0 ? $cache.document.find(".customer_status").data("subs-status") : "";

        if (customerStatus == "Active") {
            wa_data.pageName = "welcome to ubisoft plus";
            wa_data.siteSection = "product listing";
            wa_data.game = "ubisoft plus";
            wa_data.brand = "ubisoft plus";
            wa_data.productType = "subscription";
        } else {
            wa_data.pageName = "discover ubisoft plus";
            wa_data.siteSection = "product listing";
            wa_data.game = "ubisoft plus";
            wa_data.brand = "ubisoft plus";
            wa_data.productType = "subscription";
        }
    }
}

/**
 * Creates the action value with dynamic producttile values for click events only on CLPs.
 * @param {Object} $this The html selector that triggered the event
 * @param {Boolean} isSeeMoreButton in order to get dynamic values according to see more or producttile sections.
 */
function triggerCLPTile($this, isSeeMoreButton) {
    const $thumbLink = $this.find(".thumb-link");
    var tcValue = $thumbLink.length ? $thumbLink.data("tc100") : null;
    var actionValue = "listing page - go to product - see more";
    var productName;
    var editionName;
    var platformName;

    if (isSeeMoreButton) {
        const $parentTile = $this.parents().closest(".product-tile.card");
        const $parentThumbLink = $parentTile.find(".thumb-link");

        tcValue = $parentTile.length && $parentThumbLink.length ? $parentThumbLink.data("tc100") : null;
    }

    if (tcValue !== undefined && tcValue !== null) {
        productName = tcValue.productName ? tcValue.productName.toLowerCase() : "";
        editionName = tcValue.edition ? tcValue.edition.toLowerCase() : "";
        platformName = tcValue.platform ? tcValue.platform.toLowerCase() : "";
    }

    if (productName) {
        actionValue += " - " + productName;
    }

    if (editionName) {
        actionValue += " - " + editionName;
    }

    if (platformName) {
        actionValue += " - " + platformName;
    }

    $cache.document.trigger("updateTcEventBuffer", {
        this: $this,
        eventType: "clickevent",
        action: actionValue
    });
}

/**
 * @description Creates "trackingMethod" parameter and sets "event" value on data layer if it is wallet checkout, wallet thank you page or topup page.
 */
function setTrackingMethod() {
    if (isWalletTagCommanderEnabled && pageContext && (pageContext.ns == "wallettopuppage" || isWalletOrder)) {
        wa_data.trackingMethod = "event";
    }
}

/**
 * @description Triggers an event for page measurement after "trackingMethod" parameter on data layer if it is wallet checkout, wallet thank you page or topup page.
 */
function triggerWalletDataLayer() {
    if (isWalletTagCommanderEnabled && pageContext && (pageContext.ns == "wallettopuppage" || isWalletOrder)) {
        $cache.document.trigger("updateTcEventBuffer", {
            this: window.document,
            eventType: "pageevent",
            action: {}
        });
    }
}

/**
 * @description Creates tootip events
 */
function createTooltipEvents() {
    let isDesktop = window.matchMedia("(min-width: 1024px)").matches;

    $(document).on("tooltip", function (e, eventInfo) {
        if (e.target.parentElement.dataset["tooltipTarget"] == "uplay-legal-tooltip" || e.target.id == "uplay-legal-tooltip") {
            var actionValue;

            if (eventInfo == "opened") {
                if (!isDesktop) {
                    actionValue = "product info - get uplay plus - tooltip click open";
                } else {
                    actionValue = "product info - get uplay plus - tooltip open over";
                }

                sendEvent("clickevent", actionValue, this);
            } else if (eventInfo == "closed") {
                if (!isDesktop) {
                    actionValue = "product info - get uplay plus - tooltip click away";
                } else {
                    actionValue = "product info - get uplay plus - tooltip over away";
                }

                sendEvent("clickevent", actionValue, this);
            } else if (eventInfo == "closed-with-button") {
                actionValue = "product info - get uplay plus - tooltip click close";
                sendEvent("clickevent", actionValue, this);
            }
        }
    });
}

/**
 * Creates the action params with dynamic algolia values for search event of Algolia feature for adobe tracking
 * @param {Object} $this The html selector that triggered the event
 * @param {String} activity activity name to trigger search event
 * @param {String|null} autoCompletionValue auto completion value or null
 * @param {String|null} suggestionValue suggestion value or null
 */
function algoliaSearchEvent($this, activity, autoCompletionValue, suggestionValue) {
    if (!isAlgolia) {
        return;
    }

    const $searchBox = $("#searchbox");
    const searchInterface = "overlay";

    var searchStatus = "",
        totalResults = "",
        autocompletion = "",
        autoCompletionKeyword = "",
        suggestion = "",
        suggestionKeyword = "",
        searchFilter = "",
        searchBrand = "",
        searchGame = "",
        searchAbTesting = "",
        searchProductId = "",
        searchQueryId = "",
        searchQueryLength = "",
        clickedOnResults = "",
        searchKeyword = "",
        link = "",
        result = "",
        searchCategory = "",
        action = "";

    var productID,
        gameName,
        brandName,
        productPlatform,
        productName,
        productEdition;

    const queryID = $searchBox.length && $searchBox.data("query-id") ? $searchBox.data("query-id") : "";
    const query = $searchBox.length && $searchBox.data("query") ? $searchBox.data("query") : "";
    const queryLength = query && query.length ? query.length : "0";
    const indexName = $searchBox.length && $searchBox.data("index-name") ? $searchBox.data("index-name") : "";
    const isResult = $searchBox.length && $searchBox.data("is-result") ? $searchBox.data("is-result") : "";
    const abTestIndexName = $searchBox.length && $searchBox.data("ab-test-index-name") ? $searchBox.data("ab-test-index-name") : "";
    const resultsNumber = $searchBox.length && $searchBox.data("results-number") ? $searchBox.data("results-number") : "0";

    var suggestionsDisplayed = SitePreferences.ALGOLIA_QUERY_SUGGEST_ENABLE ? "yes" : "";

    switch (activity) {
        case "click_search_overlay":
            action = "header - search - start search";
            break;
        case "start_typing":
            action = "header - search - start typing";
            break;
        case "result_offered":
            searchStatus = "1";
            totalResults = resultsNumber;
            autocompletion = autoCompletionValue ? "yes" : "no";
            autoCompletionKeyword = autoCompletionValue ? autoCompletionValue : "";
            searchAbTesting = abTestIndexName;
            searchQueryId = queryID;
            searchQueryLength = queryLength;
            searchKeyword = query;
            result = isResult ? "yes" : "no";
            searchCategory = indexName;
            action = "page - search - results";
            break;
        case "no_results":
            searchStatus = "1";
            totalResults = "0";
            suggestion = suggestionsDisplayed;
            searchAbTesting = abTestIndexName;
            searchQueryId = queryID;
            searchQueryLength = queryLength;
            searchKeyword = query;
            result = isResult ? "yes" : "no";
            action = "page - search - no results";
            break;
        case "click_on_a_result":
            productID = $this.data("pid") !== undefined && $this.data("pid") !== null ? ";" + $this.data("pid") : "";
            gameName = $this.data("game-name") !== undefined && $this.data("game-name") !== null ? $this.data("game-name").toLowerCase() : "";
            brandName = $this.data("sub-brand") !== undefined && $this.data("sub-brand") !== null ? $this.data("sub-brand").toLowerCase() : "";

            productPlatform = $this.data("product-platform") !== undefined && $this.data("product-platform") !== null ? $this.data("product-platform").toLowerCase() : "";
            productName = $this.data("product-name") !== undefined && $this.data("product-name") !== null ? $this.data("product-name").toLowerCase() : "";
            productEdition = $this.data("product-edition") !== undefined && $this.data("product-edition") !== null ? $this.data("product-edition").toLowerCase() : "";

            if (productPlatform !== "") {
                link += productPlatform;
            }

            if (productName !== "") {
                if (link.length === 0) {
                    link += productName;
                } else {
                    link += " - " + productName;
                }
            }

            if (productEdition !== "") {
                if (link.length === 0) {
                    link += productEdition;
                } else {
                    link += " - " + productEdition;
                }
            }

            searchStatus = "1";
            searchBrand = brandName;
            searchGame = gameName;
            searchAbTesting = abTestIndexName;
            searchProductId = productID;
            searchQueryId = queryID;
            searchQueryLength = queryLength;
            clickedOnResults = true;
            searchKeyword = query;
            searchCategory = indexName;
            action = "page - search - results : click on result";
            break;
        case "clear_search_on_cross":
            searchStatus = "1";
            searchQueryId = queryID;
            searchQueryLength = queryLength;
            searchKeyword = query;
            result = isResult ? "yes" : "no";
            searchCategory = indexName;
            action = "header - search - clear search";
            break;
        case "close_search_layer":
            searchQueryId = queryID;
            searchQueryLength = queryLength;
            searchKeyword = query;
            result = isResult ? "yes" : "no";
            searchCategory = indexName;
            action = "page - search - close search";
            break;
        case "suggestions_shown":
            action = "page - search - suggestions shown";
            break;
        case "might_be_interested_in":
            productID = $this.data("pid") !== undefined ? ";" + $this.data("pid") : "";
            gameName = $this.data("game-name") !== undefined && $this.data("game-name") !== null ? $this.data("game-name").toLowerCase() : "";
            brandName = $this.data("sub-brand") !== undefined && $this.data("sub-brand") !== null ? $this.data("sub-brand").toLowerCase() : "";

            searchStatus = "1";
            suggestion = suggestionsDisplayed;
            searchBrand = brandName;
            searchGame = gameName;
            searchAbTesting = abTestIndexName;
            searchProductId = productID;
            searchQueryId = queryID;
            clickedOnResults = true;
            link = gameName;
            action = "page - search - results : click on interested in";
            break;
        case "auto_completion_used":
            searchStatus = "1";
            totalResults = resultsNumber;
            autocompletion = "yes";
            autoCompletionKeyword = autoCompletionValue;
            searchAbTesting = abTestIndexName;
            searchQueryId = queryID;
            searchQueryLength = queryLength;
            searchKeyword = query;
            result = isResult ? "yes" : "no";
            action = "page - search - results : click on auto completion";
            break;
        case "suggestions_used":
            searchStatus = "1";
            totalResults = resultsNumber;
            suggestion = suggestionsDisplayed;
            suggestionKeyword = suggestionValue;
            searchAbTesting = abTestIndexName;
            searchQueryId = queryID;
            searchQueryLength = queryLength;
            result = isResult ? "yes" : "no";
            action = "page - search - results : click on suggestion";
            break;
        case "using_filter":
            var filterValue;

            if (isUpc) {
                filterValue = $this.data("refine-value") ? $this.data("refine-value").toLowerCase() : "";
            } else {
                filterValue = $this.data("refvalue") ? $this.data("refvalue").toLowerCase() : "";
            }

            var isPriceSlider = $this.hasClass("algolia-price-slider-tag") && $this.attr("aria-valuenow") !== undefined;

            if (isPriceSlider) {
                filterValue = $this.attr("aria-valuenow");
            }

            searchFilter = filterValue;
            searchQueryId = queryID;
            searchQueryLength = queryLength;
            searchKeyword = query;
            searchCategory = indexName;
            action = "page - search - filter used";
            break;
    }

    tc_events_100($this, "searchevent", {
        SEARCHSTATUS: searchStatus,
        TOTALRESULTS: totalResults,
        AUTOCOMPLETION: autocompletion,
        AUTOCOMPLETIONKEYWORD: autoCompletionKeyword,
        SUGGESTION: suggestion,
        SUGGESTIONKEYWORD: suggestionKeyword,
        SEARCHFILTER: searchFilter,
        SEARCHBRAND: searchBrand,
        SEARCHGAME: searchGame,
        SEARCHABTESTING: searchAbTesting,
        SEARCHPRODUCTID: searchProductId,
        SEARCHQUERYID: searchQueryId,
        SEARCHQUERYLENGTH: searchQueryLength,
        CLICKEDONRESULTS: clickedOnResults,
        SEARCHKEYWORD: searchKeyword,
        LINK: link,
        RESULT: result,
        SEARCHINTERFACE: searchInterface,
        SEARCHCATEGORY: searchCategory,
        ACTION: action
    });

    if (activity === "clear_search_on_cross" || activity === "close_search_layer") {
        tc_events_100(this, "pageevent", {
            ACTION: wa_data.pageNameFrom ? wa_data.pageNameFrom : wa_data.pageName,
            SITESECTION: wa_data.siteSection
        });
    }
}

/**
 * Check if privacy policy was accepted or not
 */
function checkPrivacyPolicy() {
    var privacyCookie = getCookie("UBI_PRIVACY_POLICY_ACCEPTED");

    return privacyCookie === "true";
}

/**
 * Initialize events buffer if privacy policy was not yet accepted
 * This events stored here will be triggered when privacy policy will be accepted
 */
function initializeBuffer() {
    if (!checkPrivacyPolicy()) {
        window.eventsBuffer = window.eventsBuffer || [];
    }
}

/**
 * Update the value of wa_data with the default values of the page
 */
function setDefaultWaDataValues() {
    if (wa_data) {
        // reset to default page values
        wa_data.siteSection = $cache?.default_wa_data?.siteSection;
        wa_data.pageName = $cache?.default_wa_data?.pageName;

        $cache.document.trigger("globalLayerChanged");
    }
}

/**
 * Update the value of wa_data with back end data, stored in data-page-params attribute
 */
function updateDataLayerWithPageParams() {
    $cache.dataPageParams = $("[data-page-params]");

    if ($cache.dataPageParams.length && $cache.dataPageParams.filter(":not([data-analytics-page])").length) {
        var pageParams = JSON.parse($cache.dataPageParams.attr("data-page-params"));

        if (wa_data) {
            wa_data.siteSection = pageParams.siteSection;
            wa_data.pageName = pageParams.pageName;
        }
    }
}

/**
 * @function
 * @description replace tc_events_100 function with a fake one to stop sending tc events
 */
function initFakeTcEvents() {
    Object.defineProperty(window, "tc_events_100", {
        writable: false,
        configurable: false,
        value: function () {}
    });
}

/**
 * @function
 * @description Update the uplayID in the wa_data object to ensure the correct user id is used on cached pages
 */
const updateUplayId = () => {
    if (typeof wa_data !== "object") return;

    const isLoggedIn = window.User?.isLoggedIn;

    wa_data.uplayID = isLoggedIn ? window.User.ubisoftId : "";

    // visitorID is defined on UPC only
    if (wa_data.visitorID) {
        wa_data.visitorID = isLoggedIn ? window.User.ubisoftId : "";
    }
};

/**
 * Init Tag Commander
 * @param {Object} [options]
 * @param {boolean} [options.isUpc=false] - Whether we are in the Connect context (running in an iframe)
 */
function initTagCommander({ isUpc = false } = {}) {
    initializeBuffer();
    initializeCache();
    wa_data = removeSpecialCharacters(wa_data);
    updateUplayId();
    setTrackingMethod();
    updateGlobalLayer();
    addLibs(isUpc);
    cartTrackingEvents.updateCartData();

    if (enabledSubscription && (enabledSubscriptionTagCommander || enabledUPCSubscriptionTagCommander)) {
        events.updateSubscriptionCustomerData().then();
    }

    // Remove event handlers in the ".tagcommander" namespace
    $cache.document.off(".tagcommander");

    // Update the uplayData
    $cache.document.on("uplayDataUpdated.tagcommander", function () {
        if (uplayData != undefined && uplayData["authenticated"] === "true") {
            $cache.uplayData = uplayData;

            if (wa_data.uplayID == "") {
                wa_data.uplayID = uplayData["user-id"];
                $cache.document.trigger("globalLayerChanged");
            }
        }
    });

    var tagCommanderLoaded = setInterval(initializeTracking, 1000);

    // Check if the script is loaded before initializing the click events and calling the page events
    function initializeTracking() {
        if (typeof adobeAnalytics !== "undefined") {
            initFakeTcEvents();
            triggerWalletDataLayer();
            initializeEvents();
            clearInterval(tagCommanderLoaded);
        }
    }

    createTooltipEvents();

    $cache.document.on("updateDataLayer.tagcommander", updateDataLayerWithPageParams);
}

window.addEventListener("message", event => {
    if (event.data == "S_CODE_READY") {
        if (typeof adobeAnalytics !== "undefined" && !ConsentResources.analyticsOptOut) {
            if (!adobePageEventsLoaded) {
                adobePageEvents();

                // prevent multiple loading when overlay is enabled on frontsite
                adobePageEventsLoaded = true;
            }
        }
    }

    if (event.data == "PAGEVIEW_EVENT_FIRED") {
        if (typeof adobeAnalytics !== "undefined" && !ConsentResources.analyticsOptOut) {
            if (!adobeEventsLoaded) {
                adobeEvents();

                // prevent multiple loading when overlay is enabled on frontsite
                adobeEventsLoaded = true;
            }
        }
    }
});

export { initTagCommander, events as tagCommanderEvents};
