import mobileActions from "./mobileActions";

const editButtons = () => {
    const editButtons = document.querySelectorAll("[data-component='productLineItemEdit']");

    // Bindings
    editButtons.forEach.call(editButtons, currentElt => {
        currentElt.addEventListener("click", e => {
            mobileActions.displayMobileBlock(e);
        });
    });
};

export default editButtons;
