// file with js functions from product for page designer pdp

import "jquery-ui/ui/widgets/dialog";
import { util } from "../../util";
import {PDP_GLOBAL_EVENT_TYPE} from "../../../../../modules/constants";

const data = {
    freeOfferHandler: "[data-open^=free-offers-overlay]",
    freeOfferPopupIdPrefix: "free-offers-overlay-"
};

let $cache = {};
/**
 * @function
 * @description Initializes the cache object
 */
const initializeCache = () => {
    $cache = {
        document: $(document)
    };
};

/**
* @function
* @description opens the free offers popup
* @param {Object} $freeOfferPopup offers popin
*/
const openFreeOffersPopup = $freeOfferPopup => {
    if (!$freeOfferPopup.length) {
        return;
    }

    // open the modal window
    if ($freeOfferPopup.hasClass("ui-dialog-content")) {
        $freeOfferPopup.dialog("open");

        return;
    }

    // initialize and open the modal window
    $freeOfferPopup.dialog({
        resizable: false,
        modal: true,
        draggable: false,
        autoOpen: false,
        width: 900,
        dialogClass: "upc-activate-modal"
    }).dialog("open");
};

/**
* @function
* @description adds the free offer popup to the page
* @param {String} productID product ID
*/
const renderFreeOffersPopup = productID => {
    if (!productID) {
        return;
    }

    const freeOfferPopupId = "#" + data.freeOfferPopupIdPrefix + productID;
    let $freeOfferPopup = $(freeOfferPopupId);

    if ($freeOfferPopup.length) {
        // open the modal window
        openFreeOffersPopup($freeOfferPopup);
    } else {
        // Make an AJAX call to retrieve the contents of the modal window
        $.ajax({
            url: util.appendParamToURL(Urls.getFreeOfferPopup, "pid", productID),
            success: function (response) {
                if (!response) {
                    return;
                }

                // add the new popup to the page
                $("body").append(response);
                $freeOfferPopup = $(freeOfferPopupId);

                // open the modal window
                openFreeOffersPopup($freeOfferPopup);
            }
        });
    }
};

/**
 * @description Initialize event handlers on product detail page
 */
const initializeEvents = () => {
    $cache.document.on("click", data.freeOfferHandler, e => {
        const $this = $(e.currentTarget);

        e.preventDefault();

        if ($this.attr("href") === "#closed-beta") {
            $(".product-section-nav").find($("#closed-beta-section_link")).trigger("click");

            return false;
        }

        const dataOpenAttr = $this.attr("data-open");
        const freeOfferProductId = dataOpenAttr?.split(data.freeOfferPopupIdPrefix)[1] || "";

        if (!freeOfferProductId) {
            return;
        }

        renderFreeOffersPopup(freeOfferProductId);
    });

    // PDP_PLAY_ACTION custom event listener to handle the Connect launcher modal
    document.addEventListener(PDP_GLOBAL_EVENT_TYPE.PDP_PLAY_ACTION, event => {
        const { productId } = event.detail;

        if (!productId) {
            return;
        }

        renderFreeOffersPopup(productId);
    });
};

const initPdpFreeOffer = () => {
    initializeCache();
    initializeEvents();
};

export { initPdpFreeOffer };
