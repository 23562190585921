let $cache = {};

/**
 * @function
 * @description initialize cache object
 */
const initCache = () => {
    $cache = {
        document: $(document)
    };
};

/**
 * Initialize events for age gate
 * @private
 */
function initializeEvents() {
    // Register event for result of age gate validation
    $(document).on("ageGateConfirm", function (e, param) {
        if (param === "blocked") {
            // updateTcEventBuffer is not initialized yet and this is why I have added an event to be triggered after initialization
            $cache.document.on("tcEventsReady", function () {
                $cache.document.trigger("updateTcEventBuffer", {
                    this: this,
                    eventType: "clickevent",
                    action: {
                        ACTION: "product page - age gate - minor"
                    }
                });
            });
        } else {
            // updateTcEventBuffer is not initialized yet and this is why I have added an event to be triggered after initialization
            $cache.document.on("tcEventsReady", function () {
                $cache.document.trigger("updateTcEventBuffer", {
                    this: this,
                    eventType: "clickevent",
                    action: {
                        ACTION: "product page - age gate - adult"
                    }
                });
            });
        }
    });

    // Register click event for removing age-restricted products
    $("#ageRestrictedProducts button.remove-restricted-items").on("click", function () {
        const restrictedProducts = $("#ageRestrictedProducts").find(".product-tile a.thumb-link");

        restrictedProducts.each(function () {
            let data = $(this).data("tc100");

            if (data && typeof data !== "string") {
                var productName = ("productName" in data && data.productName) ? data.productName.toLowerCase() : "";
                var edition = ("edition" in data && data.edition) ? data.edition.toLowerCase() : "";
                let platform = ("platform" in data && data.platform) ? data.platform.toLowerCase() : "";
                let action = "cart - age gate remove from cart " + platform + " - " + productName + " - " + edition;

                tc_events_100(this, "clickevent", {
                    ACTION: action
                });
            }
        });

        tc_events_100(this, "clickevent", {
            ACTION: "cart - product have been removed popin - close"
        });
    });
}

module.exports = {
    init: function () {
        if (!Resources.ENABLE_AGE_RESTRICTION) {
            return;
        }

        const _this = this;

        initCache();

        // trigger on the product page with AGE statuses
        if (pageContext && pageContext.ns === "product" && wa_data && wa_data.ageStatus && wa_data.adultgame) {
            // updateTcEventBuffer is not initialized yet and this is why I have added an event to be triggered after initialization
            $cache.document.on("tcEventsReady", function () {
                $cache.document.trigger("updateTcEventBuffer", {
                    this: _this,
                    eventType: "pageevent",
                    action: {
                        ACTION: wa_data.pageName,
                        SITESECTION: wa_data.siteSection,
                        AGESTATUS: wa_data.ageStatus,
                        ADULTGAME: wa_data.adultgame
                    }
                });
            });
        }

        // trigger when age gate pop-in is displayed in a product page
        if (pageContext && (pageContext.ns === "cart" || pageContext.ns === "opc") && $("#ageRestrictedProducts").is(":visible")) {
            // updateTcEventBuffer is not initialized yet and this is why I have added an event to be triggered after initialization
            $cache.document.on("tcEventsReady", function () {
                $cache.document.trigger("updateTcEventBuffer", {
                    this: _this,
                    eventType: "clickevent",
                    action: {
                        ACTION: "cart - product have been removed popin - displayed"
                    }
                });
            });
        }

        // trigger when age-restricted products pop-in is displayed in a product page
        if ($(".age-gate-dialog").is(":visible")) {
            // updateTcEventBuffer is not initialized yet and this is why I have added an event to be triggered after initialization
            $cache.document.on("tcEventsReady", function () {
                $cache.document.trigger("updateTcEventBuffer", {
                    this: _this,
                    eventType: "clickevent",
                    action: {
                        ACTION: "product page - age gate - displayed"
                    }
                });
            });
        }

        initializeEvents();
    }
};
