import "jquery-validation";
import debounce from "lodash/debounce";
import includes from "lodash/includes";

var _ = {
    debounce,
    includes
};

var $form,
    $continue,
    $requiredInputs,
    validator;

var hasEmptyRequired = function () {
    // filter out only the visible fields
    var requiredValues = $requiredInputs.filter(":visible").map(function () {
        return $(this).val();
    });

    return _.includes(requiredValues, "");
};

var validateForm = function () {
    // only validate form when all required fields are filled to avoid
    // throwing errors on empty form
    if (!validator) {
        return;
    }

    if (!hasEmptyRequired()) {
        if (validator.form()) {
            $continue.removeAttr("disabled");
        }
    }
};

var validateEl = function () {
    if ($(this).val() === "" || ($(this).val() != null && $(this).val().toLowerCase() === "n/a" && $(this).closest(".form-row").is(":visible"))) {
        $continue.attr("disabled", "disabled");

        // Added changes to trigger states field validation to display error message if is not selected a correct value. This is due to the changes by adding
        // to the default value 'N/A' instead of empty, to be able to fix a issue when the XML is sent to Mercury.
        if ($(".app_uplay").length > 0 && $(this).attr("id").indexOf("states_state") != -1) {
            $(this).valid();
        }
    } else {
        // enable continue button on last required field that is valid
        // only validate single field
        if (validator.element(this) && !hasEmptyRequired() || !($(this).val() == null || $(this).val().toLowerCase() === "n/a") && $(this).closest(".form-row").is(":visible")) {
            // on shipping page, check if there are any shipping methods displayed, if not, then do not enabled checkout
            // this check is only if shipping methods div is on the page
            if ($("#shipping-method-list").length == 0 || ($("#shipping-method-list").length && $("#shipping-method-list").find("input").length != 0)) {
                $continue.removeAttr("disabled");
            }
        }
    }
};

function initFormPrepare(opts) {
    if (!opts.formSelector || !opts.continueSelector) {
        throw new Error("Missing form and continue action selectors.");
    }

    $form = $(opts.formSelector);
    $continue = $(opts.continueSelector);
    validator = $form.validate();
    $requiredInputs = $(".required", $form).find(":input");
    validateForm();
    // start listening
    $requiredInputs.on("change", validateEl);
    $requiredInputs.filter("input").on("keyup", _.debounce(validateEl, 200));
}

export { initFormPrepare, validateForm, validateEl };
