import { util } from "./util";

const $cache = {
    document: $(document),
    quickLinksWrapper: $(".quick-links"),
    navigationWrapper: $(".navigation-wrapper"),
    pencliLineWrapepr: $(".header-pencil-line")
};

const initQuickLinks = () => {
    const offsetTop = $cache.pencliLineWrapepr.outerHeight() || 0;

    $cache.document.on("focusin", ".quick-link", () => {
        const isPencilLineInView = util.elementInViewport($cache.pencliLineWrapepr.get(0));

        // to overlap navigation with its height and higher z-index
        $cache.quickLinksWrapper.css({
            "z-index": (parseInt($cache.navigationWrapper.css("z-index"), 10) + 1) || 1001,
            height: $cache.navigationWrapper.outerHeight() || "auto",
            top: isPencilLineInView ? offsetTop : "0"
        });

        if (!$cache.quickLinksWrapper.hasClass("active")) {
            $cache.quickLinksWrapper.addClass("active");
        }
    }).on("focusout", ".quick-link", () => {
        if ($cache.quickLinksWrapper.hasClass("active")) {
            $cache.quickLinksWrapper.removeClass("active").css("top", offsetTop);
        }
    }).on("click", ".quick-link", e => {
        e.preventDefault();

        const $anchorTargets = $($(e.currentTarget).attr("href")).find(":focusable:not([aria-disabled=true])");
        // fallback in case there is nothing to focus in #main besides breadcrumbs
        const $target = $anchorTargets.not(".breadcrumb :focusable").length ? $anchorTargets.not(".breadcrumb :focusable").first() : $anchorTargets.first();

        setTimeout(() => {
            $target.focus();
        }, 100);
    });

    if ($cache.pencliLineWrapepr.length) {
        $cache.document.on("scroll", () => {
            if (!$(".quick-link").is(":focus")) return;

            // proceed to remove a gap between quick links and top of the page during scrolling with links in focus
            let isPencilLineInView = util.elementInViewport($cache.pencliLineWrapepr.get(0));

            if (!isPencilLineInView) {
                $cache.quickLinksWrapper.css("top", 0);
            } else {
                $cache.quickLinksWrapper.css("top", offsetTop);
            }
        });
    }
};

export { initQuickLinks };
