import "jquery-validation";
import "jquery-ui/ui/widgets/dialog";
import findIndex from "lodash/findIndex";
import { util } from "./util";
import { dialog } from "./dialog";
import { jRes } from "./jRes";
import { product } from "./pages/product";

var _ = {
        findIndex
    },
    isCartPage = $(".pt_cart").length ? true : false;

var makeUrl = function (url, source, productListID) {
    if (source) {
        url = util.appendParamToURL(url, "source", source);
    }

    if (productListID) {
        url = util.appendParamToURL(url, "productlistid", productListID);
    }

    return url;
};

var removeParam = function (url) {
    if (url.indexOf("?") !== -1) {
        return url.substring(0, url.indexOf("?"));
    } else {
        return url;
    }
};

var eventListeners = function () {
    if (isCartPage) {
        $(window).on("openQuickView", function () {
            var $qvDialog = $("#QuickViewDialog");

            switch (jRes.getBreakpoint()) {
                case "desktop":
                    $qvDialog.dialog("option", "width", 900);
                    break;
                case "tablet":
                    $qvDialog.dialog("option", "width", 768);
                    break;
                case "mobileLandscape":
                    $qvDialog.dialog("option", "width", 450);
                    break;
                case "mobilePortrait":
                    $qvDialog.dialog("option", "width", 320);
                    break;
            }
        });

        jRes.addFunc([{
            breakpoint: "desktop",
            enter: function () {
                $("#QuickViewDialog").dialog("option", "width", 900);
            }
        }, {
            breakpoint: "tablet",
            enter: function () {
                $("#QuickViewDialog").dialog("option", "width", 768);
            }
        }, {
            breakpoint: "mobileLandscape",
            enter: function () {
                $("#QuickViewDialog").dialog("option", "width", 450);
            }
        }, {
            breakpoint: "mobilePortrait",
            enter: function () {
                $("#QuickViewDialog").dialog("option", "width", 320);
            }
        }]);
    } else {
        jRes.addFunc({
            breakpoint: ["mobileLandscape", "mobilePortrait", "tablet"],
            enter: function () {
                $("#QuickViewDialog").dialog("close");
            }
        });
    }
};

var quickview = {
    init: function () {
        eventListeners();

        if (!this.exists()) {
            this.$container = $("<div/>").attr("id", "QuickViewDialog").appendTo(document.body);
        }

        this.productLinks = $("#search-result-items .thumb-link").map(function (index, thumbLink) {
            return $(thumbLink).attr("href");
        });
    },

    setup: function (qvUrl) {
        var $btnNext = $(".quickview-next"),
            $btnPrev = $(".quickview-prev");

        product.initializeEvents();

        this.productLinkIndex = _.findIndex(this.productLinks, function (url) {
            return removeParam(url) === removeParam(qvUrl);
        });

        // hide the buttons on the compare page or when there are no other products
        if (this.productLinks.length <= 1 || $(".compareremovecell").length > 0) {
            $btnNext.hide();
            $btnPrev.hide();

            return;
        }

        if (this.productLinkIndex === this.productLinks.length - 1) {
            $btnNext.attr("disabled", "disabled");
        }

        if (this.productLinkIndex === 0) {
            $btnPrev.attr("disabled", "disabled");
        }

        $btnNext.on("click", function (e) {
            e.preventDefault();
            this.navigateQuickview(1);
        }.bind(this));
        $btnPrev.on("click", function (e) {
            e.preventDefault();
            this.navigateQuickview(-1);
        }.bind(this));
    },
    /**
     * @param {Number} step - How many products away from current product to navigate to. Negative number means navigate backward
     */
    navigateQuickview: function (step) {
        // default step to 0
        this.productLinkIndex += (step ? step : 0);

        var url = makeUrl(this.productLinks[this.productLinkIndex], "quickview");

        dialog.replace({
            url: url,
            callback: this.setup.bind(this, url)
        });
    },
    /**
     * @description show quick view dialog
     * @param {Object} options
     * @param {String} options.url - url of the product details
     * @param {String} options.source - source of the dialog to be appended to URL
     * @param {String} options.productlistid - to be appended to URL
     * @param {Function} options.callback - callback once the dialog is opened
     */
    show: function (options) {
        var url,
            dialogTitle;

        if (!this.exists()) {
            this.init();
        }

        url = makeUrl(options.url, options.source, options.productlistid);

        if (options.options.title.length > 0) {
            dialogTitle = options.options.title;
        } else {
            dialogTitle = Resources.QUICK_VIEW_POPUP;
        }

        dialog.open({
            target: this.$container,
            url: url,
            options: {
                width: options.options.width || 900,
                dialogClass: options.options.dialogClass || "quick-view-dialog",
                title: dialogTitle,
                closeText: Resources.CLOSE,
                position: options.options.position || {
                    my: "center",
                    at: "center",
                    of: window
                },
                open: function () {
                    this.setup(url);
                    $(window).trigger("openQuickView");

                    if (typeof options.callback === "function") { options.callback(); }

                    // trigger platform click when only one is present
                    if ($(".platforms-list a").length == 1) {
                        $(".platforms-list li:not(.active) a").trigger("click");
                    }

                    // if the age restriction form is shown, submit it in the same dialog window
                    var $ageForm = $("#dwfrm_productagelabel");

                    $ageForm.validate();

                    $("#dwfrm_productagelabel").on("submit", function (event) {
                        event.preventDefault();

                        var checkMounth = $("#dwfrm_productagelabel_month").val(),
                            checkDay = $("#dwfrm_productagelabel_day").val(),
                            checkYear = $("#dwfrm_productagelabel_year").val();

                        if (checkMounth && checkDay && checkYear) {
                            $.ajax({
                                url: Urls.productAgeFormAjax,
                                data: $ageForm.serialize(),
                                success: function (response) {
                                    $("#QuickViewDialog").html(response);

                                    if ($(".editions-list a").length > 0) {
                                        $(".editions-list li.active a").trigger("click");
                                    }
                                }
                            });
                        }
                    });
                }.bind(this)
            }
        });
    },

    exists: function () {
        return this.$container && (this.$container.length > 0);
    }
};

export { quickview };
