import ThenPromise from "promise";
import map from "lodash/map";
import { util } from "../../util";
import { dialog } from "../../dialog";
import { initAddToCartTile } from "./addToCartTile";
import { toggleWishlistAddedToCart } from "./wishlistAddToCart";
import cartActions from "GlobalJS/actions/cartActions";

var _ = {
    map
};

/**
 * @description Make the AJAX request to add an item to cart
 * @param {Element} form The form element that contains the item quantity and ID data
 * @returns {Promise}
 */
var addItemToCart = function (form) {
    const qty = form.find("input[name='Quantity']");
    const url = util.ajaxUrl(Urls.addProduct);
    const isAddFromWishlist = form.find(".add-to-cart").hasClass("wishlist-add-to-cart");
    const isAddToCartForm = form.find("#add-to-cart").length;
    const pid = isAddFromWishlist ? form.closest("#globalAddToCart").find("input[name='pid']").val() : isAddToCartForm ? form.find("#add-to-cart").data("pid") : form.find("input[name='pid']").val();
    const needRefresh = pageContext.ns === "cartrevamp";

    if (qty.length === 0 || isNaN(qty.val()) || parseInt(qty.val(), 10) <= 0) {
        qty.val("1");
    }

    return ThenPromise.resolve(
        fetch(url, {
            method: "POST",
            body: new URLSearchParams({
                pid: pid,
                refresh: needRefresh
            })
        })
            .then(response => response.json())
    );
};

var selectPlatformError = function (element) {
    var flagVariation = false;

    if (element.length > 0) {
        if (element.find(".selected").length == 0) {
            element.find(".select-msg").addClass("visible");
            flagVariation = true;
        }
    }

    return flagVariation;
};

var selectVariationError = function (element) {
    var flagVariation = false;

    if (element.length > 0) {
        if (element.find(".selectable").length == 0) {
            return true;
        }

        if (element.find(".selected").length == 0) {
            element.find(".select-msg").addClass("visible");
            flagVariation = true;
        }
    }

    return flagVariation;
};

function checkVariation() {
    var platformList = $(".platforms-list"),
        colorList = $(".Color-list"),
        sizeList = $(".Size-list");

    if (selectPlatformError(platformList)) {
        return false;
    }

    if (selectVariationError(sizeList)) {
        return false;
    }

    if (selectVariationError(colorList)) {
        return false;
    }

    return true;
}

/**
 * @description Handler to handle the add to cart event
 */
var addToCart = function (e) {
    e.preventDefault();

    if ($(this).hasClass("giveaway") || $(this).hasClass("intermediary-disabled")) {
        return;
    }

    if (!checkVariation()) {
        return;
    }

    // trigger a quantity check
    $('#pdpMain .pdpForm input[name="Quantity"]').trigger("change");

    var $form = $(this).closest("form");

    addItemToCart($form)
        .then(function (response) {
            // do not close quickview if adding individual item that is part of product set
            // @TODO should notify the user some other way that the add action has completed successfully
            if (!$(this).hasClass("sub-product-item")) {
                var isCartPage = $(".pt_cart").length ? true : false;

                if (isCartPage) {
                    location.reload();
                }

                dialog.close();
            }

            if ($(".pass-digital").data("digitalcheck") === false) {
                $(".error-digital").removeClass("hidden");
            }

            const pid = $form.find('input[name="pid"]').val();

            // display Wishlist "Added to cart" button and hide "Add to Cart" button
            toggleWishlistAddedToCart(true, pid);

            // After all checks, redirect user to cart page, set pid for overlay
            // or refresh cart page if we're on it
            if (response.data.refresh) {
                cartActions.getCartRefresh(response);
            } else {
                if (pageContext.ns !== "cartrevamp") {
                    localStorage.setItem("lastAddedProducts", response.data.pid);
                }

                window.location.replace(response.redirectUrl);
            }
        }.bind(this));
};

/**
 * @description Handler to handle the add all items to cart event
 */
var addAllToCart = function (e) {
    e.preventDefault();

    var $productForms = $("#product-set-list").find("form").toArray();

    ThenPromise.all(_.map($productForms, addItemToCart))
        .then(() => {
            dialog.close();
        });
};

/**
 * @function
 * @description Binds the click event to a given target for the add-to-cart handling
 */
function initAddToCart() {
    $(".add-to-cart[disabled]").attr("title", $(".availability-msg").text());
    $("#wishlist-items").on("click", ".shop-now", addToCart);
    $(document).on("click", ".dlc-selector-cta", addToCart);

    if ($(".app_uplay").length) {
        $(".app_uplay .add-to-cart:not(:disabled)").not(".add-to-cart-tile").on("click", addToCart);

        // init add to cart for recommendation hover button
        initAddToCartTile();
    } else {
        $(".product-detail").on("click", ".add-to-cart", addToCart);
    }

    $("#add-all-to-cart").on("click", addAllToCart);
}

export { initAddToCart };
