import "core-js/stable";
import "regenerator-runtime/runtime";
import "jquery-ui/ui/core";
import "jquery-ui/ui/widget";
import "selectboxit/src/javascripts/jquery.selectBoxIt";
import "jquery-validation";
import LazyLoad from "vanilla-lazyload/dist/lazyload";
import { util } from "./util";
import { dialog } from "./dialog";
import { jRes } from "./jRes";
import { initDataDogRum } from "UbiComponentsJS/datadogrum";
import { initResponsiveImage } from "./responsive-image";
import { initProductTooltip } from "./tooltip";
import { initTagCommander } from "TagcommanderJS/tagcommander";
import { initCQ } from "./cq";
import { searchsuggest } from "./searchsuggest";
import { searchsuggestbeta } from "./searchsuggest-beta";
import { initNCSAWshopMenu } from "./ncsa-wshop-menu";
import { uplaynavigation } from "./uplay-navigation";
import { geoRestrictions } from "./georestrictions";
import { uplayconnect } from "UplayConnectJS/upconnect";
import { initGoogleAnalytics } from "GoogleAnalyticsJS/googleanalytics";
import { initSubscriptionEvents } from "./subscriptionevents";
import { initNotification } from "./notification";
import { initEinsteinWatcher } from "./einsteinwatcher";
import { initJqueryExt } from "./jquery-ext";
import { initWallet } from "./wallet";
import { setTrackingCookies } from "./tracking";
import { refinements } from "./refinements";
import { initFooter } from "./footer";
import { initLanguageSelector } from "./languageselector";
import { initDisclosureComponents } from "./disclosureComponents";
import { initDateTransformation } from "./dateTransformation";
import { initKeyboardFocus } from "./keyboardFocus";
import { initHeaderPencil } from "./headerPencil";
import { initConnectProtocol } from "./connectprotocol/connectprotocol";
import { initQuickLinks } from "./quickLinks";

$.extend($.expr[":"], {
    containsExact: $.expr.createPseudo ?
        $.expr.createPseudo(function (text) {
            return function (elem) {
                return $.trim(elem.innerHTML.toLowerCase()) === text.toLowerCase();
            };
        }) :
        // support: jQuery <1.8
        function (elem, i, match) {
            return $.trim(elem.innerHTML.toLowerCase()) === match[3].toLowerCase();
        },

    containsExactCase: $.expr.createPseudo ?
        $.expr.createPseudo(function (text) {
            return function (elem) {
                return $.trim(elem.innerHTML) === text;
            };
        }) :
        // support: jQuery <1.8
        function (elem, i, match) {
            return $.trim(elem.innerHTML) === match[3];
        },

    containsRegex: $.expr.createPseudo ?
        $.expr.createPseudo(function (text) {
            var reg = /^\/((?:\\\/|[^/])+)\/([mig]{0,3})$/.exec(text);

            return function (elem) {
                return reg ? RegExp(reg[1], reg[2]).test($.trim(elem.innerHTML)) : false;
            };
        }) :
        // support: jQuery <1.8
        function (elem, i, match) {
            var reg = /^\/((?:\\\/|[^/])+)\/([mig]{0,3})$/.exec(match[3]);

            return reg ? RegExp(reg[1], reg[2]).test($.trim(elem.innerHTML)) : false;
        }

});

// if jQuery has not been loaded, load from google cdn
if (!window.jQuery) {
    var s = document.createElement("script");

    s.setAttribute("src", "https://ajax.googleapis.com/ajax/libs/jquery/2.2.4/jquery.min.js");
    s.setAttribute("type", "text/javascript");
    document.getElementsByTagName("head")[0].appendChild(s);
}

initJqueryExt();

function initializeEvents() {
    var controlKeys = ["8", "13", "46", "45", "36", "35", "38", "37", "40", "39"];

    util.initializeSelectboxitADAEvents();

    $("body")
        .on("keydown", "textarea[data-character-limit]", function (e) {
            var text = $.trim($(this).val()),
                charsLimit = $(this).data("character-limit"),
                charsUsed = text.length;

            if ((charsUsed >= charsLimit) && (controlKeys.indexOf(e.which.toString()) < 0)) {
                e.preventDefault();
            }
        })
        .on("change keyup mouseup", "textarea[data-character-limit]", function () {
            var text = $.trim($(this).val()),
                charsLimit = $(this).data("character-limit"),
                charsUsed = text.length,
                charsRemain = charsLimit - charsUsed;

            if (charsRemain < 0) {
                $(this).val(text.slice(0, charsRemain));
                charsRemain = 0;
            }

            $(this).next("div.char-count").find(".char-remain-count").html(charsRemain);
        });

    /**
     * initialize search suggestions, pending the value of the site preference(enhancedSearchSuggestions)
     * this will either init the legacy(false) or the beta versions(true) of the the search suggest feature.
     * */
    var $searchContainer = $("#navigation .header-search");

    if (SitePreferences.LISTING_SEARCHSUGGEST_LEGACY) {
        searchsuggest.init($searchContainer, Resources.SIMPLE_SEARCH);
    } else {
        searchsuggestbeta.init($searchContainer, Resources.SIMPLE_SEARCH);
    }

    // add generic toggle functionality
    $(".toggle").next(".toggle-content").hide();
    $(".toggle").not(".refinement .toggle").click(function () {
        $(this).toggleClass("expanded").next(".toggle-content").toggle();
    });

    util.closeThisReveal();

    $(".privacy-policy").on("click", function (e) {
        e.preventDefault();
        dialog.open({
            url: $(e.target).attr("href"),
            options: {
                height: 600
            }
        });
    });

    // main menu toggle
    $(".menu-toggle").on("click", function () {
        $("#wrapper").toggleClass("menu-active");
    });

    if ($(".ncsa-categories").length) {
        initNCSAWshopMenu();
    }

    if ($(".app_uplay").length && Resources.IS_UPC_STORE) {
        uplaynavigation.init();
    }

    if (jRes.isTablet) {
        var indexLastClicked;

        $(".menu-category > li").on("click", function (e) {
            var $target = $(this),
                indexTarget = $target.index();

            if (indexLastClicked != indexTarget && $target.find(".has-sub-menu").length) {
                e.preventDefault();
                indexLastClicked = indexTarget;
            }
        });
    }

    if (jRes.isMobilePortrait || jRes.isMobileLandscape) {
        $(".menu-category li .has-sub-menu").on("click", function (e) {
            e.preventDefault();

            var $parentLi = $(e.target).closest("li");

            $parentLi.siblings("li").removeClass("active").find(".menu-item-toggle").removeClass("fa-chevron-up active").addClass("fa-chevron-right");
            $parentLi.toggleClass("active");

            $("html, body").animate({
                scrollTop: $parentLi.offset().top
            }, 500);

            $(e.target).toggleClass("fa-chevron-right fa-chevron-up active");
        });
        $(".user-account").on("click", function (e) {
            e.preventDefault();
            $(this).parent(".user-info").toggleClass("active");
        });
    }

    dialog.autoPosition();

    if (jRes.isDesktop || jRes.isTablet) {
        $("footer").find("h4").syncHeight();
    }

    $("body").on("click", "[data-scroll-top]", e => {
        e.preventDefault();

        const targetID = $(e.currentTarget).attr("href");
        const elementToFocus = util.findFocusable($(targetID)).first();

        util.scrollBrowser(0, () => elementToFocus.focus());
    });

    $(document)
        .on("keyup paste change", ".cc-number", util.formatCardNumber)
        .on("focusin", ".cc-number", e => {
            const $elem = $(e.target);

            $elem.attr("maxlength", 19).attr("size", 19);
        });
}

/**
 * @private
 * @function
 * @description Adds class ('js') to html for css targeting and loads js specific styles.
 */
function initializeDom() {
    // add class to html for css targeting and site type
    $("html")
        .addClass("js")
        .addClass(Resources.IS_NCSA ? "is-ncsa" : "is-emea");

    if (SitePreferences.LISTING_INFINITE_SCROLL) {
        $("html").addClass("infinite-scroll");
    }

    // load js specific styles
    util.limitCharacters();
}

var app = {
    init: function () {
        // init Data Dog Rum plugin in order to track performances for pages
        initDataDogRum();

        if (document.cookie.length === 0) {
            $("<div/>").addClass("browser-compatibility-alert").append($("<p/>").addClass("browser-error").html(Resources.COOKIES_DISABLED)).appendTo("#browser-check");
        }

        initializeDom();
        initializeEvents();

        // init specific global components
        if (SitePreferences.UPLAY_CONNECT) {
            uplayconnect.init();
        }

        initResponsiveImage();

        // Init of lazyload plugin
        var lazyLoadInstance = new LazyLoad({
            elements_selector: ".lazy"
        });

        // Set it on window, to access it everywhere
        window.lazyLoadInstance = lazyLoadInstance;

        if (lazyLoadInstance) {
            lazyLoadInstance.update();
        }

        // Additional lazylaod check for few special cases like: filter apply and clothes merch size change
        $(window).on("ajaxComplete", function () {
            setTimeout(function () {
                if (lazyLoadInstance) {
                    lazyLoadInstance.update();
                }
            }, 150);
        });

        initProductTooltip();
        initCQ();
        initNotification();

        if (pageContext.ns !== "cartrevamp") {
            initEinsteinWatcher();
        }

        refinements.init();

        if (SitePreferences.ENABLE_WALLET) {
            initWallet();
        }

        if (Resources.SHOW_SB_ELEMENTS) {
            initSubscriptionEvents();
        }

        geoRestrictions.init();

        if (window.SitePreferences.ENABLE_TAG_COMMANDER) {
            $(document).ready(function () {
                initTagCommander({
                    isUpc: true
                });
            });
        }

        if (window.SitePreferences.ENABLE_GOOGLE_ANALYTICS) {
            initGoogleAnalytics();
        }

        initFooter();
        initLanguageSelector();
        initDisclosureComponents();
        initDateTransformation();
        initKeyboardFocus();
        initHeaderPencil();
        initConnectProtocol();
        initQuickLinks();
    }
};

// general extension functions
(function () {
    String.format = function () {
        var string = arguments[0];
        var i,
            len = arguments.length - 1;

        for (i = 0; i < len; i++) {
            var reg = new RegExp("\\{" + i + "\\}", "gm");

            string = string.replace(reg, arguments[i + 1]);
        }

        return string;
    };
})();

(function (app) { // NOSONAR
    app.components = app.components || {};
}(window.app = window.app || {}, jQuery));

const initShared = () => {
    setTrackingCookies();
    app.init();
};

export { initShared };
