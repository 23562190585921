import utils from "../helpers/utils";
import initButtons from "../components/button";
import sliderInit from "../components/slider";
import initSelect from "../components/select";
import accordion from "../components/accordion";
import modal from "../components/modal";
import toggleTopNotificationClasses from "../components/topNotifications";

import mobileActions from "./mobileActions";
import couponsActions from "./couponsActions";
import editButtons from "./editButtons";

import { refundPolicy, modalLoyaltyUnits } from "GlobalSVG/svg-icons.json";

const blockMapping = {
    lineItems: {
        update: "lineItemsUpdate",
        name: "productLineItems"
    },
    summary: {
        update: "summaryUpdate",
        name: "summary"
    },
    wishlist: {
        update: "wishlistUpdate",
        name: "wishlist"
    },
    indies: {
        update: "indiesUpdate",
        name: "indies"
    },
    topNotifications: {
        update: "topNotificationsUpdate",
        name: "topNotifications"
    },
    cartpage: {
        update: "pageUpdate",
        name: "cartPage"
    }
};

const cartActions = {
    /**
     * @function
     * @description helper function to refresh Cart Page block
     * @param {Event} content - HTML content to spread in page
     */
    refreshWrappers: content => {
        for (let key in blockMapping) {
            let value = blockMapping[key];

            // Check for DOM block and response block based on name
            let existingContent = document.querySelector(`[page-component="${value.name}"]`);
            let updatedContent = content.querySelector(`[page-component="${value.name}"]`);
            let newPageEvents = updatedContent?.dataset?.pageParams;

            if (value.update === "pageUpdate") {
                let isCurrentPageEmpty = existingContent?.classList?.contains("l-cart__inner--empty");
                let isRefreshPageEmpty = updatedContent?.classList?.contains("l-cart__inner--empty");

                // If we status change - switch from empty to non empty page or opposite
                if (isCurrentPageEmpty || isRefreshPageEmpty) {
                    document.location.reload(true);

                    return;
                }

                // Replace tracking data for pageEvents - Only run for main block
                existingContent.dataset.pageParams = newPageEvents;

                // Dispatch Events after Refresh for tracking
                const isShippingFree = updatedContent.querySelector(".c-shipping-message");

                if (isShippingFree) {
                    document.dispatchEvent(new CustomEvent("Cart:ShippingFree", {
                        detail: isShippingFree.dataset?.type
                    }));
                }

                document.dispatchEvent(new Event("Cart:Update"));
            } else if (existingContent && updatedContent) {
                // Replace HTML content
                existingContent.innerHTML = updatedContent.innerHTML;

                // Toggle classes for the top notification section
                if (value.update === "topNotificationsUpdate") {
                    toggleTopNotificationClasses(updatedContent, existingContent);
                }

                // Update sticky summary section with new values
                if (value.update === "summaryUpdate") {
                    mobileActions.updateMobileSummary();
                }
            }

            if (value.name === "indies") {
                const indiesWrapper = document.querySelector(".l-cart__indies");
                const indiesComponent = document.querySelector(".l-cart__indies-component");

                if (indiesWrapper && indiesComponent) {
                    if (indiesComponent.innerHTML.trim() && indiesWrapper.classList.contains("hide")) {
                        indiesWrapper.classList.remove("hide");
                    } else if (!indiesComponent.innerHTML.trim()) {
                        indiesWrapper.classList.add("hide");
                    }
                }
            }
        }
    },

    /**
     * @function
     * @description Get JSON response and pass it to getCartRefresh
     * @param {Event} payload - Event with detail
     */
    removePli: payload => {
        mobileActions.hideMobileBlock();
        modal.close();
        cartActions.getCartRefresh(payload.detail);
    },

    /**
     * @function
     * @description Get JSON response to call Cart-Refresh and replace html with response
     * @param {Object} response - Contain redirectUrl and params
     */
    getCartRefresh: response => {
        if (response?.success === true) {
            const url = response.redirectUrl;
            const parameters = response.data.refreshWrappers;
            const wishlistSlider = document.querySelector(".c-wishlist-slider");
            const couponCombineWarning = response.couponCombineWarning;

            if (wishlistSlider !== null) {
                parameters.wishlist = true;
            }

            // fetch Cart-Refresh to get HTML
            fetch(url + "?" + new URLSearchParams(parameters))
                .then(response => response.text())
                .then(response => {
                    if (response.length) {
                        // Convert the HTML string into a document object
                        const parser = new DOMParser();
                        const html = parser.parseFromString(response, "text/html");

                        // Spread HTML
                        cartActions.refreshWrappers(html);

                        // Refresh cart icon
                        document.dispatchEvent(new CustomEvent("CartIcon:Update"));

                        // Rebind and update
                        initButtons();
                        sliderInit();
                        initSelect();
                        editButtons();
                        accordion.init();
                        couponsActions.init();

                        if (couponCombineWarning !== undefined) {
                            document.dispatchEvent(new CustomEvent("Coupon:CombineWarning", {
                                detail: couponCombineWarning
                            }));
                        }

                        // Run lazyload from window instance
                        if (window.lazyLoadInstance) {
                            window.lazyLoadInstance.update();
                        }
                    }
                });
        }
    },

    updateQuantity: data => {
        const url = utils.ajaxUrl(Urls.updateQuantity);

        mobileActions.hideMobileBlock();

        fetch(url, {
            method: "POST",
            body: new URLSearchParams({
                uuid: data.params.uuid,
                quantity: data.params.qty,
                refresh: true
            })
        })
            .then(response => response.json())
            .then(response => cartActions.getCartRefresh(response));
    },

    triggerModalUnits: e => {
        const assetContent = e.detail;
        const modalOptions = {
            iconHtml: modalLoyaltyUnits,
            customClass: {
                popup: "c-modal--units"
            }
        };

        modal.open(assetContent, modalOptions, "Units");
    },

    triggerModalRefund: e => {
        const assetContent = e.detail;
        const modalOptions = {
            iconHtml: refundPolicy,
            customClass: {
                popup: "c-modal--refund",
                icon: "c-icon--color"
            }
        };

        modal.open(assetContent, modalOptions, "Refund");
    },

    triggerModalIndies: e => {
        const assetContent = e.detail;
        const modalOptions = {
            customClass: {
                popup: "c-modal--getIndies"
            }
        };

        modal.open(assetContent, modalOptions, "GetIndies");

        const modalIndiesContinue = $(".c-button--secondary.js-enforced-login-btn[data-name='ModalIndiesContinue']");

        if (modalIndiesContinue.length > 0) {
            const redirectAfterLogin = modalIndiesContinue.data("redirect-after-login");

            if (redirectAfterLogin !== undefined) {
                sessionStorage.setItem("redirectAfterLogin", redirectAfterLogin);
            }
        }
    },

    triggerModalRemoveIndies: e => {
        const assetContent = e.detail;
        const modalOptions = {
            customClass: {
                popup: "c-modal--removeIndies"
            }
        };

        modal.open(assetContent, modalOptions, "RemoveIndies");
    },

    triggerModalCouponCombinable: e => {
        const assetContent = e.detail;
        const modalOptions = {
            customClass: {
                popup: "c-modal--combinable"
            },
            closeButtonAttributes: {
                "data-component": "button",
                "data-name": "ApplyBestPromo",
                "data-ajaxurl": Urls.applyBestPromotion,
                "data-method": "GET"
            }
        };

        modal.open(assetContent, modalOptions, "CouponNotCombinable");
    },

    closeIndiesModal: () => {
        const indiesBlock = document.querySelector("[page-component='indies']");

        modal.close();

        // Close modal and scroll to indies block
        if (indiesBlock !== null) {
            let headerHeight = 0;
            let pencilLineHeight = 0;
            const isUPC = utils.checkUPC();
            const scrollValue = window.scrollY;
            const indiesBlockCoords = indiesBlock.getBoundingClientRect();
            const pencilLine = document.querySelector(".header-pencil-line");

            // calculate pencil line's height if it's hidden in order to put the correct header's height
            if (pencilLine && pencilLine.offsetHeight === 0) {
                const pencilSlotContainer = pencilLine.firstElementChild;

                if (pencilSlotContainer) {
                    pencilLineHeight = pencilSlotContainer.offsetHeight;
                }
            }

            // check current position of the indies block in order to properly calculate header's height
            if (indiesBlockCoords.top < 0) {
                // add pencilLineHeight for the scroll up, since it can be hidden and not counted in the header-main
                headerHeight = isUPC ? 0 : document.querySelector(".header-main").offsetHeight + pencilLineHeight;
            } else {
                headerHeight = isUPC ? 0 : document.querySelector(".navigation-wrapper").offsetHeight;
            }

            const indiesBlockTop = (indiesBlockCoords.top + scrollValue) - headerHeight;

            setTimeout(() => {
                window.scrollTo({
                    top: indiesBlockTop,
                    behavior: "smooth"
                });
            }, 500);
        }
    },

    closeCouponCombinableModal: response => {
        if (response.success === true) {
            modal.close();
            cartActions.getCartRefresh(response);
        }
    }
};

export default cartActions;
