import "jquery-ui/ui/widgets/dialog";
import { ajaxLoad } from "./ajax";
import { getCookie, setCookie } from "./tls";

/*
 * Function for GeoRedirect Restriction popup
 * If the customer visits a site outside it's region he will be prompted
 */
var geoRestrictions = {
    init: function () {
        var restrictCheckout = GeoRestrictedResources.GEORESTRICTED_REQUEST != null ? GeoRestrictedResources.GEORESTRICTED_REQUEST : false,
            container = $("#georestricted"),
            url = GeoRestrictedResources.georestrictionpopup;

        if (restrictCheckout && container.length) {
            // check the cookie
            var cookieName = "geopopup-" + GeoRestrictedResources.GEORESTRICTED_CURRENTSITE;
            var cookieValue = getCookie(cookieName);

            if (!cookieValue) {
                // open the popup
                this.openPopup(container, url);
                // set the cookie to true for this user session
                // the cookie will be active for 1 week
                setCookie(cookieName, true, 10080);
            }
        }
    },
    openPopup: function ($container, url) {
        // Make an AJAX call to retrieve the contents of the modal window
        // containing a message and a link to the locale store
        ajaxLoad({
            url: url,
            callback: function (response) {
                if (response === null || response === undefined) {
                    return;
                }

                $container.html(response);
            }.bind(this)
        });
        // open the modal window
        $container.dialog({
            modal: true,
            draggable: false,
            width: 540,
            height: 380,
            dialogClass: "dialog-georestricted",
            buttons: {},
            open: function () {

            }
        });
    }
};

// Function for GeoRedirect Restriction popup
export { geoRestrictions };
