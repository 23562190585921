import LazyLoad from "vanilla-lazyload/dist/lazyload";
import debounce from "lodash/debounce";
import { initAddToCart } from "./product/addToCart";
import { initResponsiveImage } from "../responsive-image";
import { util } from "../util";
import { symbols } from "../symbols";
import { dialog } from "../dialog";
import { submitEvent } from "TagcommanderJS/wishlist";
import { initializeWishListTrackingEvents } from "TagcommanderJS/wishlist";
import { cookie } from "../cookie";
import { initFreeOffer } from "../freeoffer";
import { focusTarget } from "../keyboardFocus";
import * as adobeHelper from "TagcommanderJS/adobe_analytics_events";
import { calculateDataOrderAttr } from "../tracking";

var _ = {
    debounce
};
var $cache = {};
let loadedBatches = 1;
let loadBatchInProgress = false;
// variable used to check if the pop-up was opened from edit section or from Wishlist Recommendation
let isPopupOpenedFromRec = false;
// variable used to check if wishlist and wishlist tag commander are enabled
var isWishlistTagCommanderEnabled = SitePreferences.ENABLEWISHLIST && SitePreferences.ENABLE_WISHLIST_TAG_COMMANDER ? true : false;

/**
 * @function
 * @description Initializes cache
 */
function initializeCache() {
    $cache.window = $(window);
    $cache.document = $(document);
    $cache.body = $("body");
    $cache.wishlistDialog = $cache.document.find("#wishlist-variations-popup");
    $cache.wishlistItems = $(".wishlist-items");
    $cache.loadMoreBtnWrapper = $cache.wishlistItems.find(".load-more-wrapper");
    $cache.loadMoreBtn = $cache.loadMoreBtnWrapper.find(".load-more-button");
    $cache.sliderContainer = $("#cross-sell-recommendations");
    $cache.firstTitlePart = $(".first-title-part");
    $cache.gridTiles = $cache.wishlistItems.find(".grid-tile");
    $cache.productNotificationsSwitch = $(".product-notifications-switch");
    $cache.notificationsSwitchAll = $("#notifications-switch");
    $cache.emailNotificationsAllIcon = $(".email-notifications-all").find(".svg-wrapper");
    $cache.scrollPlaceholder = $(".wishlist-scroll-placeholder");
    $cache.wishlistNotification = $(".wishlist-notification-wrapper");
    $cache.wishlistItemsList = $(".wishlist-items-list");
    $cache.wishlistShareButton = $(".wishlist-share-button");
    $cache.tileWishlistHeartButton = $cache.document.find(".product-tile .wishlist-heart-button");
    $cache.productTile = $cache.document.find(".product-tile");
}

/**
 * @function
 * @description Calculates the slot number for the wishlist tracking
 * @param {Object} element Target element
 * @return {Object} Returns calculated wishlist object with slot parameters
 */
function calculateWishListSlotNumber(element, currentAction) {
    let slotNumber = "";
    let eventElement = element.closest("[data-analytics-product-wishlist-click=adobeAnalytics]");
    const eventParams = eventElement.data("product-params");
    const eventAction = currentAction || eventParams.action || eventElement.data("action");

    if (eventParams.slotNumber === undefined || eventParams.slotNumber === null || eventParams.slotNumber === "") {
        slotNumber = element.closest(".c-tile--upc").data("slick-index");

        // calculating slotNumber for producttile when data-slick-index value is not found
        if (slotNumber === undefined) {
            let productIdList = [];
            const wishlistProductList = $(".wishlist-items-list .c-tile--upc .product-tile");

            if (wishlistProductList.length > 0) {
                wishlistProductList.toArray().forEach(function (w) {
                    productIdList.push($(w).data("itemid"));
                });

                const slotNumberIndex = productIdList.indexOf(eventParams.productId || eventParams.productID);

                slotNumber = slotNumberIndex !== -1 ? slotNumberIndex + 1 : undefined;
            }
        }
    } else {
        slotNumber = eventParams.slotNumber;
    }

    eventParams.slotNumber = slotNumber;
    eventParams.action = eventAction;

    return eventParams;
}

/**
 * @function
 * @description Toggles notifications for all products
 * @param {jQuery} $checkbox
 */
function toggleProductNotificationsAll($checkbox) {
    var status = $checkbox.is(":checked");

    $.ajax({
        url: util.appendParamsToUrl(Urls.wishlistUpdateBulkNotifStat, {
            status: status,
            format: "ajax"
        }),
        success: function (res) {
            if (res && res.success) {
                $cache.productNotificationsSwitch.each(function (i, elem) {
                    var $elem = $(elem);

                    if (status) {
                        $elem.addClass("notifications-enabled");
                        $elem.data("action", "product : deactivate alert");
                    } else {
                        $elem.removeClass("notifications-enabled");
                        $elem.data("action", "product : activate alert");
                    }

                    changeNotificationsIcon($elem);
                });

                setNotificationsSwitchAllState();
            }
        }
    });
}

/**
 * @function
 * @description Toggles notifications for certain product
 * @param {jQuery} $elem - notifications button
 */
function toggleProductNotifications($elem) {
    var $productNotificationsSwitch = $elem.parents(".product-tile").find(".product-notifications-switch"),
        pid = $productNotificationsSwitch.data("pid"),
        status = !$productNotificationsSwitch.hasClass("notifications-enabled");

    $.ajax({
        url: util.appendParamsToUrl(Urls.wishlistUpdateNotifStat, {
            pid: pid,
            status: status,
            format: "ajax"
        }),
        success: function (res) {
            if (res && res.success) {
                $productNotificationsSwitch.toggleClass("notifications-enabled");
                changeNotificationsIcon($productNotificationsSwitch);

                if (!$productNotificationsSwitch.hasClass("notifications-enabled")) {
                    $cache.notificationsSwitchAll.prop("checked", false);
                    $productNotificationsSwitch.data("action", "product : deactivate alert");
                } else {
                    $productNotificationsSwitch.data("action", "product : activate alert");
                }

                let action = $productNotificationsSwitch.data("action");
                let eParams = calculateWishListSlotNumber($productNotificationsSwitch, action);

                adobeHelper.getProductDataById(eParams, eParams.productId, null, "click");

                setNotificationsSwitchAllState();
            }
        }
    });
}

/**
 * @function
 * @description Changes notifications button icon
 * @param {jQuery} $elem - notifications button
 */
function changeNotificationsIcon($elem) {
    if ($elem.hasClass("notifications-enabled")) {
        $elem.find(".svg-wrapper").html(symbols.notifActive());
    } else {
        $elem.find(".svg-wrapper").html(symbols.notifInactive());
    }
}

/**
 * @function
 * @description Sets notification checkbox state
 * @param {Boolean} updateProductListItem - the flag which is setted up when we update one of the wishlist items notification toggle
 */
function setNotificationsSwitchAllState(updateProductListItem) {
    var allProductNotificationsEnabled = true;

    $cache.productNotificationsSwitch.each(function (i, elem) {
        var $elem = $(elem);

        if (!$elem.hasClass("notifications-enabled")) {
            allProductNotificationsEnabled = false;

            return false;
        }
    });

    if (allProductNotificationsEnabled) {
        // update the global notifications batch in case all products got notification enabled
        if (updateProductListItem) {
            $.ajax({
                url: util.appendParamsToUrl(Urls.wishlistUpdateBulkNotifStat, {
                    status: "true",
                    format: "ajax"
                })
            });
        }

        $cache.notificationsSwitchAll.prop("checked", true);
        $cache.emailNotificationsAllIcon.html(symbols.notifActive());
    } else {
        $cache.emailNotificationsAllIcon.html(symbols.notifInactive());
    }
}

/**
 * @function
 * @description Show the next batch of product tiles in Wishlist
 * @param {Object} $hiddenGridTiles Hidden product tiles
 * @param {Integer} batchSize Number of product tiles to show
 */
function loadNextBatch(batchSize) {
    if (batchSize && !loadBatchInProgress) {
        const wishlistIDAttribute = $(".wishlist-wrapper").attr("data-wishlist-id");
        const wishlistID = (wishlistIDAttribute !== undefined && wishlistIDAttribute !== "") ? wishlistIDAttribute : null;
        let url = Urls.getWishlistItems;

        if (url !== null) {
            url = util.appendParamsToUrl(url, {
                isAjax: true,
                start: batchSize * loadedBatches,
                size: batchSize
            });

            if (wishlistID) {
                url = util.appendParamToURL(url, "WishListID", wishlistID);
            }

            loadBatchInProgress = true;

            fetch(url)
                .then(response => {
                    return response.text();
                }).then(html => {
                    let loadedItems = 0;

                    if (html && html.includes("grid-tile")) {
                        loadedBatches++;

                        if (loadedBatches % 4 === 0) {
                            $cache.loadMoreBtnWrapper.removeClass("hide");
                        } else {
                            $cache.loadMoreBtnWrapper.addClass("hide");
                        }

                        loadedItems = $(html).filter(".grid-tile").length;

                        if ($cache.wishlistItemsList.length) {
                            $cache.wishlistItemsList.append(html);

                            let lazyLoadInstance = new LazyLoad({
                                elements_selector: ".lazy"
                            });

                            lazyLoadInstance.update();
                            initResponsiveImage();

                            // logic for free offer elements at template
                            $(".grid-tile").each(function () {
                                initFreeOffer($(this));
                            });
                        }

                        $cache.productNotificationsSwitch = $(".product-notifications-switch");
                        $cache.productNotificationsSwitch.off("click").on("click", function (e) {
                            e.stopPropagation();
                            e.preventDefault();
                            toggleProductNotifications($(this));
                        });

                        loadBatchInProgress = false;
                    }

                    const wishlistItemsNumber = parseInt($cache.loadMoreBtnWrapper.attr("data-wishlist-items-number"), 10);
                    const wishlistItemsRendered = $cache.wishlistItemsList.length ? $cache.wishlistItemsList.find(".grid-tile").length : 0;

                    // no more items to load
                    if (loadedItems < batchSize || wishlistItemsNumber === wishlistItemsRendered) {
                        $cache.loadMoreBtnWrapper.attr("data-load-next-items-count", 0).addClass("hide");
                    }
                });
        }
    }
}

/**
 * @function
 * @description Initialize Infinite Scroll functionality on Wihslist page
 */
function initWishlistInfiniteScroll() {
    if ($cache.loadMoreBtnWrapper.length && $cache.loadMoreBtnWrapper.attr("data-load-next-items-count") != undefined) {
        const batchSize = parseInt($cache.loadMoreBtnWrapper.attr("data-load-next-items-count"), 10);
        let isInViewport = false;

        if (batchSize && $cache.scrollPlaceholder.length) {
            $cache.window.on("scroll", _.debounce(() => {
                if (loadedBatches % 4 != 0 && $cache.loadMoreBtnWrapper.attr("data-load-next-items-count") !== "0") {
                    isInViewport = util.elementInViewport($cache.scrollPlaceholder.get(0), 50);

                    if (isInViewport) {
                        loadNextBatch(batchSize);
                    }
                }
            }, 200));

            $cache.loadMoreBtn.on("click", e => {
                e.stopPropagation();
                loadNextBatch(batchSize);
            });
        }
    }
}

/**
 * @function
 * @description Initialize specific events for Sharing popin on Wishlist page
 */
const initializeWishlistShare = () => {
    if ($cache.wishlistShareButton.length) {
        $cache.wishlistShareButton.on("click", e => {
            e.preventDefault();

            const url = Urls.getWishlistSharePopup;

            if (url !== null && SitePreferences.ENABLE_WISHLIST_SHARE) {
                fetch(url)
                    .then(response => {
                        return response.text();
                    }).then(html => {
                        if (html && html.includes("wishlist-share-popup-content")) {
                            dialog.open({
                                html: html,
                                options: {
                                    dialogClass: "wishlist-share-popup keyboard-focus keyboard-focus--yellow",
                                    width: 980
                                },
                                callback: function () {
                                    $("[data-dialog-close]").on("click", function () {
                                        dialog.close();

                                        focusTarget($cache.wishlistShareButton);
                                    });

                                    const eventParams = {
                                        location: "wishlist",
                                        locationDetail: "share my wishlist",
                                        category: "display",
                                        locationPosition: "pop in",
                                        action: "visible"
                                    };
                                    const sendEvent = adobeHelper.adobeAnalyticsToggle($cache.wishlistShareButton.closest("[data-check-analytics-toggle]"));

                                    if (sendEvent) {
                                        adobeHelper.impressionEvent(null, eventParams);
                                    }
                                }
                            });
                        }
                    });
            }
        });
    }

    $cache.document.on("click", ".copy-wishlist-link", e => {
        e.preventDefault();

        const $this = $(e.currentTarget);
        const $wishlistLinkInput = $(".wishlist-link-input");

        if ($wishlistLinkInput.length && $wishlistLinkInput.val() !== "") {
            $wishlistLinkInput.select();
            document.execCommand("copy");
            $this.find(".copy-link").toggleClass("hide");
            $this.find(".link-copied").toggleClass("hide");
        }
    });

    $cache.document.on("click", ".wishlist-share-method", e => {
        e.preventDefault();

        const $shareAction = $(e.currentTarget).attr("data-share-action");

        if ($shareAction !== undefined) {
            window.open($shareAction);
        }
    });
};

/**
 * @function
 * @description Initializes events specific to wishlist page
 */
function initWishlistPageEvents() {
    initWishlistInfiniteScroll();
    initializeWishlistEvents();
    initializeWishlistShare();

    if (isWishlistTagCommanderEnabled) {
        initializeWishListTrackingEvents();
    }

    // check for age restricted account
    var ageGateCookie = Resources.ENABLE_AGE_RESTRICTION ? cookie.get("age_classification") : null;
    var ageRestricted = ageGateCookie != null && ageGateCookie == "0";

    if (SitePreferences.ENABLEWISHLISTNOTIFICATIONS && !ageRestricted) {
        $cache.notificationsSwitchAll.on("change", function () {
            var $this = $(this);

            toggleProductNotificationsAll($this);
        });

        $cache.productNotificationsSwitch.on("click", function (e) {
            e.stopPropagation();
            e.preventDefault();

            var $this = $(this);

            toggleProductNotifications($this);
        });
    }

    // logic for free offer elements at template
    $(document).on("ajaxComplete", function () {
        $(".product-tile").each(function () {
            initFreeOffer($(this));
        });
    });

    $cache.document.on("click", ".edit-button", e => {
        e.preventDefault();

        const $this = $(e.currentTarget);
        const pid = $this.data("productid");

        if (pid) {
            isPopupOpenedFromRec = false;
            $("#wishlist-remove-product-id").val(pid);
            loadWishlistDialog(pid, $this, true);
        }
    });
}

/**
 * @function
 * @description Updates the wishlist counter
 * @param {jQuery} $target - sub-element of wishlist-button
 * @param {String} state - "added" | "removed" from Wishlist
 */
function updateWishlistCount(target, state) {
    if ($(target).hasClass("button-for-editing")) {
        if (pageContext.ns == "wishlist") {
            location.reload();
        }

        return;
    }

    $(target)
        .parents(".wishlist-button").find(".svg-wrapper")
        .html(state === "added" ? symbols.wishlistHeartFull() : symbols.wishlistHeart());
}

/**
 * Replace content from popin when the edition it's changed
 * @param {string} productID
 * @param {Object} $targetButton The element that holds target button object
 */
function updateDialogContent(productID, $targetButton) {
    if (productID && $targetButton.length) {
        $.ajax({
            url: util.appendParamsToUrl(Urls.wishlistVariationDialog, {
                pid: productID,
                format: "ajax"
            }),
            success: function (res) {
                const $dialogContainer = $("#dialog-container");

                if ($dialogContainer.length) {
                    $dialogContainer.html(res);
                }

                if ($targetButton.length) {
                    initializeWishlishPopinEvents($targetButton);
                }
            }
        });
    }
}

/**
 * @description Update product content with new variant from href, load new content to wishlist popin
 * @param {String} href - url of the new product variant
 * @param {Object} $targetButton The element that holds target button object
 **/
const updateContentWishlist = (href, $targetButton) => {
    if (href && $targetButton.length) {
        const params = {
            format: "wishlist"
        };

        $.ajax({
            url: util.appendParamsToUrl(href, params),
            success: function (res) {
                const $dialogContainer = $("#dialog-container");

                if ($dialogContainer.length) {
                    $dialogContainer.html(res);
                }

                initializeWishlishPopinEvents($targetButton);
            }
        });
    }
};

/**
 * Returns the section from where the add to wishlist button was clicked
 * @param {Object} $targetButton Clicked button
 * @param {String} $dataSource Section from where the add to wishlist button was clicked
 */
function getDataSource($targetButton) {
    var dataSource = "product info",
        clicked,
        divider,
        slotOrder;

    if (pageContext.type == "search") {
        dataSource = "listing page";
    }

    if ($targetButton.parents(".category-product-slider").length > 0) {
        dataSource = "product listing";
    } else if ($targetButton.parents(".cross-sell").length > 0) {
        dataSource = "you may also like";
    } else if ($targetButton.parents(".up-sell").length > 0) {
        dataSource = "additional content";
    } else if ($targetButton.parents(".homepage-product-corner").length > 0) {
        dataSource = "one and double";
    } else if ($targetButton.parents(".homepage-slider-wrapper").length > 0) {
        dataSource = "hero banner";
    } else if ($targetButton.closest(".pushes").hasClass("triple")) {
        dataSource = "triple";
    } else if ($targetButton.closest(".pushes").hasClass("double")) {
        dataSource = "double";
    }

    if ($($targetButton).closest(".pushes").hasClass("triple")) {
        clicked = $(".pushes.triple").index($($targetButton).closest(".pushes")) + 1;
        divider = 3;
        slotOrder = Math.ceil(clicked / divider);
        dataSource += " " + slotOrder + " - slot " + clicked;
    } else if ($($targetButton).closest(".pushes").hasClass("double")) {
        clicked = $(".pushes.double").index($($targetButton).closest(".pushes")) + 1;
        divider = 2;
        slotOrder = Math.ceil(clicked / divider);
        dataSource += " " + slotOrder + " - slot " + clicked;
    }

    return dataSource;
}

/**
 * Loads wishlist dialog using given product ID
 * @param {string} productID  Id for the product
 * @param {Object} $targetButton The element that holds target button object
 */
function loadWishlistDialog(productID, $targetButton, isEditWishlistDialog) {
    // identify source of the wishlist event
    var dataSource = $targetButton.parents(".wishlist-dialog-variations").length > 0 ? $(".add-variation-to-wishlist").data("source") : null;

    if (!dataSource) {
        dataSource = getDataSource($targetButton);
    }

    dialog.open({
        url: util.appendParamsToUrl(Urls.wishlistVariationDialog, {
            pid: productID,
            dataSource: dataSource,
            format: "ajax"
        }),
        options: {
            width: 950,
            draggable: false,
            dialogClass: "wishlist-dialog keyboard-focus keyboard-focus--yellow",
            modal: true
        },
        callback: function () {
            initializeWishlishPopinEvents($targetButton);
        }
    });

    const $wishlistDialog = $(".wishlist-dialog");

    if ($wishlistDialog.length > 0) {
        $wishlistDialog.on("dialogclose", e => {
            e.preventDefault();

            if (isEditWishlistDialog) {
                focusTarget($targetButton);
            } else {
                const $addToWishlistButtons = $(".pdp-add-to-wishlist");
                const $addToWishlistButtonVariation = $(".add-variation-to-wishlist");
                let nextFocusProductId = productID;

                if ($addToWishlistButtonVariation.length > 0 && $addToWishlistButtonVariation.attr("data-masterid")) {
                    nextFocusProductId = $addToWishlistButtonVariation.data("masterid");
                }

                focusTarget($addToWishlistButtons.filter(`[data-productid="${nextFocusProductId}"]`));
            }
        });
    }

    if ($cache.productTile.length) {
        $cache.productTile.on("focusout", e => {
            e.preventDefault();

            const $this = $(e.currentTarget);
            const $activeFocusTile = $this.parents(".tile-focus-active");

            if ($activeFocusTile.length > 0) {
                $activeFocusTile.removeClass("tile-focus-active");
            }
        });
    }

    var eventParams = {};
    var productIdList = [];

    productIdList.push(productID);

    if (isEditWishlistDialog) {
        eventParams = {
            location: "wishlist",
            locationDetail: "edit wishlist",
            category: "display",
            locationPosition: "pop in",
            action: "visible",
            productImpression: productIdList
        };
    } else {
        eventParams = {
            location: "wishlist",
            locationDetail: "wishlist addition",
            category: "display",
            locationPosition: "pop in",
            action: "visible",
            productImpression: productIdList
        };
    }

    adobeHelper.impressionEvent(null, eventParams);
}

/**
 * @function
 * @description Initialize wishlist event in the popin
 * @param {Object} $targetButton The element that holds target button object
**/
const initializeWishlishPopinEvents = $targetButton => {
    let $wishlistPopinTile = $(".wishlist-dialog").find(".grid-tile");

    if (($targetButton.hasClass("edit-button") || pageContext.ns === "wishlist") && !isPopupOpenedFromRec) {
        const $wishlistVariationsPopup = $(".wishlist-dialog-content-wrapper");
        const $updateWishlistButton = $(".update-wishlist");
        const $removeWishlistButton = $(".remove-from-wishlist");
        const $inputRemoveProductId = $("#wishlist-remove-product-id");

        if ($targetButton.parents(".wishlist-recommendations").length) {
            isPopupOpenedFromRec = true;
        } else {
            $wishlistVariationsPopup.addClass("for-editing");
            $wishlistVariationsPopup.find(".add-variation-to-wishlist").parent().addClass("hide");
            $wishlistVariationsPopup.find(".remove-from-wishlist, .update-wishlist").parent().removeClass("hide");
            $wishlistVariationsPopup.data("productid", $targetButton.data("productid"));

            if ($updateWishlistButton.length && $removeWishlistButton.length && $inputRemoveProductId.length && ($removeWishlistButton.data("pid") === $inputRemoveProductId.val())) {
                $updateWishlistButton.attr("disabled", "disabled");
            } else {
                $removeWishlistButton.attr("disabled", "disabled");
            }
        }
    }

    $(".add-variation-to-wishlist").off("click").on("click", e => {
        e.preventDefault();

        const $this = $(e.currentTarget);
        const pid = $this.data("productid");

        if (pid) {
            addToWishlist(pid, true, false, null);
        }
    });

    initResponsiveImage();
    initializeCache();

    if (isWishlistTagCommanderEnabled) {
        initializeWishListTrackingEvents();
    }

    if ($cache.wishlistDialog.find(".card-additional-details").find("div").length) {
        $cache.wishlistDialog.find(".product-tile").addClass("margin-top");
    }

    $(".ui-dialog-titlebar-close").on("click", () => {
        $cache.body.removeClass("dialog-opened");
    });

    if ($(".platforms-list .active.selected").length === 0) {
        $(".platform-variant-label")
            .first()
            .trigger("click");
    }

    if ($wishlistPopinTile.length) {
        initFreeOffer($wishlistPopinTile);
    }
};

/**
 * @description Trigger one of the available Wishlist notifications
 * @param {String} notificationID - ID of the notification to be triggered
 **/
const triggerWishlistNotification = notificationID => {
    if ($cache.wishlistNotification.length) {
        $cache.wishlistNotification
            .removeClass("product-already-added-notification product-already-bought-notification product-restricted-notification")
            .addClass("hide");

        if (typeof notificationID === "string") {
            $cache.wishlistNotification
                .addClass(notificationID)
                .removeClass("hide");
        } else {
            $cache.wishlistNotification.removeClass("hide");
        }

        const $focusedElem = $(":focus");

        if ($focusedElem.length > 0 && $focusedElem.data("productid")) {
            let productId = $focusedElem.data("productid");
            const $wishlistNotificationBtn = $(".wishlist-notification-btn");

            if ($focusedElem.hasClass("add-variation-to-wishlist") && $focusedElem.attr("data-masterid")) {
                productId = $focusedElem.data("masterid");
            }

            if ($wishlistNotificationBtn.length) {
                $wishlistNotificationBtn.attr("data-next-focus", ".add-to-wishlist[data-productid=" + productId + "]");

                $wishlistNotificationBtn.on("keydown", e => {
                    if (e.keyCode === 9) {
                        // prevent default behavior only on 'tab' keyboard key press
                        e.preventDefault();

                        if (e.shiftKey) {
                            const $closeWishlistBtn = $(".close-wishlist-notification");

                            if ($closeWishlistBtn.length > 0) {
                                $closeWishlistBtn.trigger("focus");
                            }
                        } else {
                            focusTarget($wishlistNotificationBtn.attr("data-next-focus"));
                        }
                    }
                });
            }

            $cache.wishlistNotification.focus();
        }

        $cache.tileWishlistHeartButton.on("focusout", e => {
            const $this = $(e.currentTarget);
            const $activeFocusTile = $this.parents(".tile-focus-active");

            if ($activeFocusTile.length > 0) {
                $activeFocusTile.removeClass("tile-focus-active");
            }
        });
    }
};

/**
 * @function
 * @description Ajax call to add MFE products to wishlist and resolve/reject the promise received from the MFE
 * @param {string} productId ID of the product
 * @param {"onWishlistAdd"|"onWishlistRemove"} eventName Name of the event
 * @param {function} onSuccess Function to call once the product has been added/removed successfully
 * @param {function} onError Function to call if there was an error
 */
function addMfeProductToWishlist(productId, eventName, onSuccess, onError) {
    const url = util.appendParamsToUrl(Urls.wishlistAjaxProductAdd, {
        pid: productId,
        format: "ajax",
        updatedpid: null,
        addedFromProductTile: false,
        enableNotifications: true
    });

    $.ajax({
        url: url,
        success: function (res) {
            if (res) {
                if (res.productAdded && res.productListCount) {
                    if (eventName === "onWishlistAdd") {
                        onSuccess(res);
                        triggerWishlistNotification();

                        const productIdList = [];

                        productIdList.push(productId);

                        const eventParams = {
                            location: "wishlist notification",
                            category: "display",
                            locationPosition: "notification",
                            action: "product : added to your wishlist",
                            productImpression: productIdList
                        };

                        adobeHelper.impressionEvent(null, eventParams);

                        if (pageContext.ns === "wishlist") {
                            location.reload();
                        }
                    } else {
                        // Product was added but request was to remove it, try removing it again
                        addMfeProductToWishlist(productId, eventName, onSuccess, onError);
                    }
                } else if (res.productRemoved) {
                    if (eventName === "onWishlistRemove") {
                        onSuccess(res);
                    } else {
                        // Product was removed but request was to add it, try adding it again
                        addMfeProductToWishlist(productId, eventName, onSuccess, onError);
                    }
                } else {
                    onError(res);

                    if (res.isBoughtGame) {
                        triggerWishlistNotification("product-already-bought-notification");
                    } else if (!res.productAdded && (res.reason != null || res.reason != undefined) && res.reason === "ageRestricted") {
                        triggerWishlistNotification("product-restricted-notification");
                    }
                }

                dialog.close();

                if ($cache.wishlistNotification.length) {
                    setTimeout(function () {
                        $cache.wishlistNotification.focus();
                    }, 0);
                }

                $cache.body.removeClass("dialog-opened");
            }
        }
    });
}

/**
 * @function
 * @description Initializes events after window loaded
 */
function initWishlistLoadEvents() {
    $cache.window.on("load", function () {
        if (util.getURLParamValue("addedwishlist") && util.getURLParamValue("addedwishlist") == "true") {
            window.history.pushState({}, document.title, util.removeParamFromURL(window.location.href, "addedwishlist"));
        }

        if (util.getURLParamValue("ownedwishlistproductid")) {
            loadWishlistDialog(util.getURLParamValue("ownedwishlistproductid"));
            window.history.pushState({}, document.title, util.removeParamFromURL(window.location.href, "ownedwishlistproductid"));
        }
    });
}

/**
 * @function
 * @description Handles wishlist events for master product
 * @param {Object} $button The element that holds the product details
 * @param {Object} $targetButton The element that holds target button object
 */
function handleMasterWishlist($button, $targetButton) {
    var masterID = $button.data("productid"),
        isProductAdded = $button.hasClass("product-added");

    if (isProductAdded && window.uplayData.authenticated === "true") {
        $.ajax({
            url: util.appendParamsToUrl(Urls.wishlistRemoveMasterProduct, {
                pid: masterID,
                format: "ajax"
            }),
            success: function (res) {
                if (res && res.productRemoved) {
                    $targetButton.removeClass("product-added").attr("title", Resources.ADD_TO_WISHLIST);
                    updateWishlistCount($targetButton, "removed");
                    $(".c-offerselector__comingsoon-text").removeClass("hide");
                }
            }
        });
    } else {
        loadWishlistDialog(masterID, $targetButton);
    }
}

/**
 * @function
 * @description Initializes events specific to product detail page
 */
function initializeWishlistEvents() {
    initializeCache();
    initWishlistLoadEvents();
    initWishlistButton();

    $cache.document.off("click.wishlistEvt");
    $cache.document.off("onWishlistAdd onWishlistRemove");

    $cache.document.on("onWishlistAdd onWishlistRemove", e => {
        const { productId, resolve, reject } = e.detail || {};

        if (!productId || !resolve || !reject) {
            return;
        }

        addMfeProductToWishlist(productId, e.type, resolve, reject);
    });

    $cache.document.on("click.wishlistEvt", ".edition-variant", e => {
        e.preventDefault();

        const $this = $(e.currentTarget);
        let pid = $this.data("variationid");

        if (pid) {
            updateDialogContent(pid, $this);
        }
    });

    $cache.document.on("click.wishlistEvt trigger-platform-select.wishlistEvt", ".platform-variant-wishlist", e => {
        e.preventDefault();

        const $this = $(e.currentTarget);
        let platformName = $(e.currentTarget).text();
        let platformUrl = $(".variation-select option:containsExact(" + platformName + ")");

        if (platformUrl.length && platformUrl.val()) {
            updateContentWishlist(platformUrl.val(), $this);
        }
    });

    $cache.document.on("click.wishlistEvt", ".add-to-wishlist", e => {
        e.preventDefault();

        let $this = $(e.currentTarget),
            productID = $this.data("productid"),
            inStock = $this.data("instock"),
            variantID = $this.attr("data-variant-product-id"),
            isProductAdded = $this.hasClass("product-added"),
            isMaster = $this.data("ismaster"),
            productAddedFromPdp = !$this.hasClass("pdp-add-to-wishlist");

        if ($this.parents(".category-product-slider").length || $this.parents(".recommendations.cross-sell.einstein-recommendations").length || $this.parents(".product-list-slider").length || $this.parents(".push-section").length) {
            e.stopPropagation();
            adobeHelper.trackProductsListWishlistAction($this);
        }

        if ($this.hasClass("algolia-wishlist") && isMaster === "") {
            let redirectLink = $this.attr("data-redirect-link");

            if (redirectLink) {
                window.location.href = redirectLink;

                return;
            }
        }

        if (((variantID && (!inStock || isProductAdded)) || isMaster) && !$this.hasClass("algolia-wishlist")) {
            handleMasterWishlist($this, $this);

            return;
        }

        var dataSource = getDataSource($this);

        addToWishlist(productID, false, productAddedFromPdp, null, $this, dataSource);
    });

    // update wishlist event
    $cache.document.on("click.wishlistEvt", ".update-wishlist", e => {
        let $this = $(e.currentTarget);
        let pid = $this.data("pid");
        let productToRemove = $("#wishlist-remove-product-id").val();

        if (pid && productToRemove) {
            addToWishlist(pid, true, false, productToRemove);
        }
    });

    // delete from wishlist event
    $cache.document.on("click.wishlistEvt", ".remove-from-wishlist", e => {
        e.preventDefault();

        let pid = $(e.currentTarget).data("pid");

        if (pid) {
            addToWishlist(pid, false, false, null);
        }
    });

    $cache.document.on("click.wishlistEvt", ".platform-variant", e => {
        e.preventDefault();

        const $this = $(e.currentTarget);

        if (!$this.hasClass("active selected")) {
            const platformName = $this.find(".platform-variant-label").text();

            if (platformName !== "") {
                const platformOption = $(".variation-select option:containsExact(" + platformName + ")");

                if (platformOption.length) {
                    const pid = platformOption.data("variationid");

                    if (pid) {
                        loadWishlistDialog(pid, $this);
                    }
                }
            }
        }
    });

    calculateDataOrderAttr();
}

/**
 * @function
 * @description Ajax call to add product in wishlist
 * @param {String} pid - Product ID
 * @param {Boolean} addedFromPopup - true or false if the product was added from popup
 * @param {Boolean} addedFromProductTile - true or false if the product was added from product tile
 * @param {String} updatedpid - Product ID to be updated
 * @param {Object} wishListButton - Add To Wishlist button
 * @param {String} dataSource - Page section where add to wishlist button was clicked
*/
function addToWishlist(pid, addedFromPopup, addedFromProductTile, updatedpid, wishListButton, dataSource) {
    if (pid) {
        let $addToWishlist = $(`.add-to-wishlist[data-productid="${pid}"]`);
        let $productTooltip = $addToWishlist.parent().find(".product-tooltip");

        $.ajax({
            url: util.appendParamsToUrl(Urls.wishlistAjaxProductAdd, {
                pid: pid,
                format: "ajax",
                addedFromPopup,
                addedFromProductTile,
                updatedpid
            }),
            success: function (res) {
                if (res && res.productAdded) {
                    $addToWishlist.addClass("product-added");
                    $(".c-offerselector__comingsoon-text").addClass("hide");

                    if ($addToWishlist.data("params")) {
                        const trackingParamsString = JSON.stringify($addToWishlist.data("params"));

                        $addToWishlist.attr("data-params", trackingParamsString.replace("add to", "remove from"));
                    }

                    if ($productTooltip.length) {
                        $productTooltip.html(Resources.REMOVE_FROM_WISHLIST);
                    }

                    updateWishlistCount($addToWishlist, "added");
                    triggerWishlistNotification();

                    if (!addedFromPopup) {
                        wishListButton.data("event", "addToWishList");
                        wishListButton.data("source", dataSource);
                        submitEvent(wishListButton);
                    }

                    const productIdList = [];

                    productIdList.push(pid);

                    const eventParams = {
                        location: "wishlist notification",
                        category: "display",
                        locationPosition: "notification",
                        action: "product : added to your wishlist",
                        productImpression: productIdList
                    };

                    adobeHelper.impressionEvent(null, eventParams);

                    if (pageContext.ns === "wishlist") {
                        location.reload();
                    }
                } else if (res && res.productRemoved) {
                    $addToWishlist.removeClass("product-added");
                    $(".c-offerselector__comingsoon-text").removeClass("hide");

                    if ($addToWishlist.data("params")) {
                        const trackingParamsString = JSON.stringify($addToWishlist.data("params"));

                        $addToWishlist.attr("data-params", trackingParamsString.replace("remove from", "add to"));
                    }

                    if ($productTooltip.length) {
                        $productTooltip.html(Resources.ADD_TO_WISHLIST);
                    }

                    dialog.close();
                    updateWishlistCount($addToWishlist, "removed");

                    if (!addedFromPopup && wishListButton) {
                        wishListButton.data("event", "editWishList");
                        wishListButton.data("action", "delete from wishlist");
                        wishListButton.data("source", dataSource);
                        submitEvent(wishListButton);
                    }

                    if (pageContext.ns === "wishlist") {
                        location.reload();
                    }
                } else if (res.productAlreadyAdded) {
                    triggerWishlistNotification("product-already-added-notification");
                } else if (res.isBoughtGame) {
                    triggerWishlistNotification("product-already-bought-notification");
                } else if (!res.productAdded && (res.reason != null || res.reason != undefined) && res.reason === "ageRestricted") {
                    triggerWishlistNotification("product-restricted-notification");
                }

                dialog.close();

                if ($cache.wishlistNotification.length) {
                    setTimeout(function () {
                        $cache.wishlistNotification.focus();
                    }, 0);
                }

                $cache.body.removeClass("dialog-opened");
            }
        });
    }
}

/**
 * loads asynchonously wishlist buttons on individual tiles
 * wishlist events will be attached only after ajax request success
 */
function initWishlistButton() {
    const $allTilesWithWishlistPlaceholder = $("[data-loadtilewishlistbutton]");

    if (!$allTilesWithWishlistPlaceholder || !$allTilesWithWishlistPlaceholder.length || !window.Urls.getWishListButtons) {
        return;
    }

    let newWishlistButtons = [];

    $allTilesWithWishlistPlaceholder.each(function () {
        newWishlistButtons.push(this.dataset?.wishlistproduct);
    });

    const url = `${window.Urls.getWishListButtons}?pids=${newWishlistButtons.join(",")}`;

    $.ajax({
        type: "GET",
        dataType: "html",
        url: url,
        success: function (data) {
            if (data) {
                const components = JSON.parse(data)?.components;

                $allTilesWithWishlistPlaceholder.each(function () {
                    const pid = this.dataset.wishlistproduct;

                    if (!pid) {
                        return;
                    }

                    this.innerHTML = components[pid];
                    this.removeAttribute("data-loadtilewishlistbutton");
                });
            }
        }
    });
}

/**
 * @function
 * @description Initializes wishlist
 */
function initWishlist() {
    initializeCache();
    initAddToCart();
    initWishlistPageEvents();
}

export { initWishlist, initializeWishlistEvents, addToWishlist };
