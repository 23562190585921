import "jquery-zoom";
import "jcarousel";
import { util } from "../../util";
import { dialog } from "../../dialog";
import { jRes } from "../../jRes";
import { uskVideo } from "../../usk18";

var zoomMediaQuery = matchMedia("(min-width: 960px)"),
    thumbMarginRight;
/**
 * @description Enables the zoom viewero n the product detail page
 * @param caller
 */
var loadZoom = function (caller) {
    var $imgZoom = $("#pdpMain .main-image"),
        $imageWrapper = $(".product-primary-image"),
        highResUrl,
        highResUrlLength,
        $zoomImgLast;

    if (!$imgZoom.length) {
        return false;
    }

    highResUrl = $imgZoom.attr("href");

    if (!highResUrl) {
        highResUrlLength = 0;
    } else {
        highResUrlLength = highResUrl.length;
    }

    if (caller === "video" || highResUrlLength === 0 || $imgZoom.length === 0 || dialog.isActive() || util.isMobile() || !zoomMediaQuery.matches) {
        // remove zoom
        $imgZoom.trigger("zoom.destroy");
        $imageWrapper.removeClass("zoom");

        return;
    }

    if (highResUrl && highResUrl !== "null" && highResUrl.indexOf("noimagelarge") === -1 && zoomMediaQuery.matches) {
        $imgZoom.zoom({
            url: highResUrl
        });
        $imageWrapper.addClass("zoom");
    }

    // fix for zoom plugin bug with multiple zoom images created
    $imgZoom.on("mouseout", function () {
        $zoomImgLast = $($(".zoomImg")[$(".zoomImg").length - 1]);
        $(".zoomImg").remove();
        $imgZoom.append($zoomImgLast);
    });
};

zoomMediaQuery.addListener(loadZoom);

var eventListeners = function () {
    var $thumbs = $("#thumbnails"),
        quickViewThumbs = $("#QuickViewDialog #thumbnails"),
        $carousel = quickViewThumbs.length ? quickViewThumbs : $("#thumbnails"),
        $prevButton = $carousel.find(".jcarousel-prev"),
        $nextButton = $carousel.find(".jcarousel-next"),
        $current = $("#thumbnails .thumb.selected"),
        currentIndex = $("#thumbnails .thumb").index($current),
        thumbsLength = $("#thumbnails .thumb").length,
        $next = false,
        $prev = false,
        thumbnailsWrapper = $(".product-thumbnails");

    $prevButton.on("click", function () {
        if (currentIndex === 0) {
            return;
        }

        $prev = $($("#thumbnails .thumb")[currentIndex - 1]);
        $prev.find(".productthumbnail").trigger("click", [{
            clickType: "arrow"
        }]);
        $current = $("#thumbnails .thumb.selected");
        currentIndex = $("#thumbnails .thumb").index($current);
    });

    $nextButton.on("click", function () {
        if (currentIndex >= thumbsLength - 1) {
            return;
        }

        $next = $($("#thumbnails .thumb")[currentIndex + 1]);
        $next.find(".productthumbnail").trigger("click", [{
            clickType: "arrow"
        }]);
        $current = $("#thumbnails .thumb.selected");
        currentIndex = $("#thumbnails .thumb").index($current);
    });
    // handle product thumbnail click event
    $("#thumbnails .play-button").on("click", function () {
        $(this).siblings(".productthumbnail").trigger("click");
    });

    $("#pdpMain").on("click", ".productthumbnail", function (ev) {
        var $selected;

        ev.preventDefault();
        // switch indicator
        $(this).closest(".product-thumbnails").find(".thumb.selected").removeClass("selected");
        $selected = $(this).closest(".thumb").addClass("selected");
        $current = $selected;
        currentIndex = $("#thumbnails .thumb").index($current);

        if ($(this).hasClass("video")) {
            setMainVideo($(this).data("lgimg"));
        } else {
            setMainImage($(this).data("lgimg"));
        }
    });
    // prevent default behavior of thumbnail link and add this Button
    $("#pdpMain").on("click", ".thumbnail-link, .unselectable a", function (e) {
        e.preventDefault();
    });

    $(".product-primary-image").on("click", "a.product-image", function (ev) {
        ev.preventDefault();
    });

    jRes.addFunc([{
        breakpoint: ["desktop", "tablet", "mobileLandscape"],
        enter: function () {
            thumbMarginRight = $("#QuickViewDialog").length ? 4 : 3;
            resizeMainCarouselThumbs($thumbs, thumbMarginRight);
        }
    }, {
        breakpoint: "mobilePortrait",
        enter: function () {
            thumbMarginRight = 3;
            resizeMainCarouselThumbs($thumbs, thumbMarginRight);
        }
    }]);

    thumbnailsWrapper.removeClass("hidden-slider");
};

/**
 * @description Sets the main image attributes and the href for the surrounding <a> tag
 * @param {Object} atts Object with url, alt, title and hires properties
 */
var setMainImage = function (atts) {
    var $primaryImage = $("#pdpMain .product-primary-image .primary-image");

    $("#pdpMain .primary-video").attr({
        src: atts.url
    });
    $("#pdpMain .primary-video").hide();

    if (SitePreferences.ENABLE_USK) {
        $("#pdpMain .age-question").hide();
        $("#pdpMain .age-answer").hide();
    }

    $primaryImage.show();
    $primaryImage.attr({
        src: atts.url,
        alt: atts.alt,
        title: atts.title
    });

    if (!dialog.isActive() && !util.isMobile()) {
        $("#pdpMain .main-image").attr("href", atts.hires);
    }

    loadZoom();
};

var setMainVideo = function (atts) {
    $("#pdpMain .product-primary-image .primary-image").hide();
    $("#pdpMain .primary-video").attr({
        src: atts.url
    });

    if (SitePreferences.ENABLE_USK) {
        var ageQuestion = $("#pdpMain .age-question"),
            ageAnswer = $("#pdpMain .age-answer"),
            video = $("#pdpMain .primary-video");

        uskVideo(ageQuestion, ageAnswer, video);
    } else {
        $("#pdpMain .primary-video").show();
    }

    loadZoom("video");
};

/**
 * @description Replaces the images in the image container, for eg. when a different color was clicked.
 */
var replaceImages = function () {
    var $newImages = $("#update-images"),
        $imageContainer = $("#pdpMain .product-image-container");

    if ($newImages.length === 0) { return; }

    $imageContainer.html($newImages.html());
    $newImages.remove();
    loadZoom();
};

var resizeMainCarouselThumbs = function ($thumbnails, thumbMarginRightSize) {
    $thumbnails.find("ul").width($thumbnails.find("ul>li").length * ($thumbnails.find("ul>li").outerWidth() + thumbMarginRightSize));
};

var initImageThumbsSlider = function () {
    var quickViewThumbs = $("#QuickViewDialog #thumbnails"),
        $carousel = quickViewThumbs.length ? quickViewThumbs : $("#thumbnails");

    if (jRes.isMobilePortrait) {
        thumbMarginRight = 3;
    } else {
        thumbMarginRight = $("#QuickViewDialog").length ? 4 : 3;
    }

    if (!$carousel || $carousel.length === 0 || $carousel.children().length === 0) {
        return;
    }

    resizeMainCarouselThumbs($carousel, thumbMarginRight);
    $carousel.jcarousel();

    if ($carousel.width() < $carousel.find("ul").width()) {
        $carousel.find(".jcarousel-prev, .jcarousel-next").show();
    }

    $carousel.find(".jcarousel-prev")
        .on("jcarouselcontrol:active", function () {
            $(this).removeClass("inactive");
        })
        .on("jcarouselcontrol:inactive", function () {
            $(this).addClass("inactive");
        })
        .jcarouselControl({
            target: "-=1"
        });

    $carousel.find(".jcarousel-next")
        .on("jcarouselcontrol:active", function () {
            $(this).removeClass("inactive");
        })
        .on("jcarouselcontrol:inactive", function () {
            $(this).addClass("inactive");
        })
        .jcarouselControl({
            target: "+=1"
        });

    return $carousel;
};

/* @module image
 * @description this module handles the primary image viewer on PDP
 **/

/**
 * @description by default, this function sets up zoom and event handler for thumbnail click
 **/
function initImage() {
    if (dialog.isActive() || util.isMobile()) {
        $("#pdpMain .main-image").removeAttr("href");
    }

    initImageThumbsSlider();
    loadZoom();
    eventListeners();

    $(document).ready(function () {
        // condition for setting the video as main image if the video is in first carousel position
        if ($("#thumbnails .video-selected").length) {
            setMainVideo($("#thumbnails .video-selected").data("lgimg"));
        }
    });
}

export { initImage, loadZoom, setMainImage, replaceImages, initImageThumbsSlider, eventListeners };
