import "jquery-ui/ui/widgets/datepicker";
import debounce from "lodash/debounce";
import "selectboxit/src/javascripts/jquery.selectBoxIt";
import { util } from "../util";
import { initAddToCartTile } from "../pages/product/addToCartTile";
import LazyLoad from "vanilla-lazyload/dist/lazyload";
import { symbols } from "../symbols";

var _ = {
        debounce
    },
    algoliaCurrencySymbol = GeoRestrictedResources.ALGOLIA_CURRENCY_SYMBOL,
    currencySymbol = algoliaCurrencySymbol ? algoliaCurrencySymbol : GeoRestrictedResources.STOREFRONT_CURRENCY_SYMBOL,
    $window = $(window),
    $document = $(document),
    viewMoreHit = false,
    viewMoreHitState = false,
    currentSite = GeoRestrictedResources.GEORESTRICTED_CURRENTSITE != "" && GeoRestrictedResources.GEORESTRICTED_CURRENTSITE != undefined ? GeoRestrictedResources.GEORESTRICTED_CURRENTSITE.toUpperCase() : "",
    currencyCodeSite = GeoRestrictedResources.STOREFRONT_CURRENCY_CODE,
    currencySymbolInFront = symbolInFrontForCurrencies();

/**
 * @description Function to point if currency symbol should be in front of the price or not for Algolia CLP.
 * @return {Boolean} - Returns boolean values to indicate if currency symbol should be in front of the price or not.
 **/
function symbolInFrontForCurrencies() {
    const algoliaSymbolInFrontForCurrencies = SitePreferences.ALGOLIA_SYMBOL_IN_FRONT_FOR_CURRENCIES;
    const isCurrencySymbolInFront = SitePreferences.ALGOLIA_CURRENCY_SYMBOL_IN_FRONT;
    let isMatchedCurrency = false;
    let algoliaSymbolInFrontForCurrenciesArr = algoliaSymbolInFrontForCurrencies ? algoliaSymbolInFrontForCurrencies.split(",") : null;

    if (algoliaSymbolInFrontForCurrenciesArr) {
        for (let i = 0; i < algoliaSymbolInFrontForCurrenciesArr.length; i++) {
            const ucCurrencyToRound = algoliaSymbolInFrontForCurrenciesArr[i].toUpperCase();

            if (ucCurrencyToRound === currencyCodeSite) {
                isMatchedCurrency = true;

                break;
            }
        }
    }

    if (isCurrencySymbolInFront && (!algoliaSymbolInFrontForCurrencies || isMatchedCurrency)) {
        return true;
    }

    return false;
}

/**
 * Escapes the html characters
 * @param {String} str - the search string to escape html characters from
 * @return {String} string with escaped characters
 */
const escapeHTML = str => {
    return str.replace(/[&<>"'/]/g, function (char) {
        switch (char) {
            case "&":
                return "&amp;";
            case "<":
                return "&lt;";
            case ">":
                return "&gt;";
            case '"':
                return "&quot;";
            case "\\":
                return "&#39;";
            case "/":
                return "&#x2F;";
            default:
                return char;
        }
    });
};

/**
 * @description Add DIS image
 * @param {String} imageLink - Image link with DIS
 * @param {String} viewMode - Device type (desktop, tablet, mobile)
 * @return {Element} - Returns DOM element to append
 **/
const getDISImage = (imageLink, viewMode) => {
    if (imageLink) {
        if (viewMode === "") {
            viewMode = "desktop";
        }

        let viewModeResourceWidth = Resources["ALGOLIA_PRODUCT_IMAGE_WIDTH_" + viewMode.toUpperCase()];
        let viewModeResourceHeight = Resources["ALGOLIA_PRODUCT_IMAGE_HEIGHT_" + viewMode.toUpperCase()];
        let imageDIS = imageLink.replace(/\d+(?=&sh)/g, viewModeResourceWidth).replace(/\d+(?=&sm)/g, viewModeResourceHeight);

        return imageDIS;
    }
};

/**
 * @description Returns the values of multi locale fields.
 * @param {Object} fieldName - multi locale field object.
 * @param {Boolean} isReleaseDate - identifies if the object is a release date.
 * @return {String} - currentLocaleValue
 **/
function getCurrentLocaleField(fieldName, isReleaseDate) {
    if (fieldName != undefined && typeof fieldName == "object") {
        var storefrontLocale = GeoRestrictedResources.STOREFRONT_CURRENT_LOCALE;
        var currentLocaleValue = fieldName[0][storefrontLocale];

        if (currentLocaleValue) {
            return isReleaseDate ? currentLocaleValue : currentLocaleValue.toLowerCase();
        }
    }

    return fieldName;
}

/**
 * Get the price for every locale if it exists
 * @return {price} - return price value
 */
const getAlgoliaPrice = priceLocale => {
    if (priceLocale === "" || typeof priceLocale === "undefined") { return; }

    let parsePriceLocale;

    try {
        parsePriceLocale = JSON.parse(priceLocale.replace(/(""")|({})/g, '""'));
    } catch {
        return;
    }

    let priceAdditionalLocale = [];
    let finalPriceAdditional;

    Object.keys(parsePriceLocale).forEach(key => {
        priceAdditionalLocale.push({
            locale: parsePriceLocale[key].locale.split("-")[1],
            localeDefault: parsePriceLocale[key].locale.replace("default-", ""),
            value: parsePriceLocale[key].value
        });
    });

    if (currentSite == "BR") {
        let currencyLocale = priceAdditionalLocale.filter(item => {
            return item.locale == currencyCodeSite;
        });

        if (currencyLocale.length > 0) {
            currencyLocale.forEach(item => {
                finalPriceAdditional = item.value;
            });
        }
    } else {
        let localePrice = priceAdditionalLocale.filter(item => {
            return item.localeDefault == GeoRestrictedResources.STOREFRONT_CURRENT_LOCALE;
        });

        if (localePrice.length > 0) {
            localePrice.forEach(item => {
                finalPriceAdditional = item.value;
            });
        }
    }

    if (typeof finalPriceAdditional !== "undefined" && finalPriceAdditional !== "") { return finalPriceAdditional; }
};

/**
 * @function
 * @description checked condition and add available badge according to priority
 * @param {Boolean} hit product to check
 * @return {String}
 */
function addAvailableBadge(hit) {
    var cardLabel = document.createElement("div");

    if (typeof hit.Free_offer !== "undefined" && hit.Free_offer.toLowerCase() === "giveaway") {
        cardLabel.classList.add("card-label", "giveaway");
        cardLabel.innerHTML = Resources.GIVEAWAY_BADGE;

        return cardLabel.outerHTML;
    } else if (typeof hit.Free_offer !== "undefined" && hit.Free_offer === "freeWithPurchase") {
        cardLabel.classList.add("card-label", "free-with-purchase");
        cardLabel.innerHTML = Resources.FREE_WITH_PURCHASE;

        return cardLabel.outerHTML;
    } else if (hit.promotion_percentage !== "") {
        cardLabel.classList.add("discount-percent", "card-label");
        cardLabel.innerHTML = "-" + hit.promotion_percentage + "%";

        return cardLabel.outerHTML;
    } else if (typeof hit.Exclusivity !== "undefined" && hit.Exclusivity.toLowerCase() === "yes") {
        cardLabel.classList.add("card-label", "exclusive");
        cardLabel.innerHTML = Resources.EXCLUSIVE_BADGE;

        return cardLabel.outerHTML;
    } else if (typeof hit.Novelty !== "undefined" && hit.Novelty.toLowerCase() === "yes") {
        cardLabel.classList.add("product-new", "card-label");
        cardLabel.innerHTML = Resources.NEW_BADGE;

        return cardLabel.outerHTML;
    } else if (hit.preorder === "true") {
        cardLabel.classList.add("card-label", "product-coming-soon");
        cardLabel.innerHTML = Resources.BUTTON_PRE_ORDER;

        return cardLabel.outerHTML;
    }

    return cardLabel.outerHTML;
}

/**
 * Get age group html content from asset
 * @input {HTMLElement} element - Age group dom element
 */
function getAgeGroupContent(element) {
    var ageGroup = element.data("age-group");

    if (ageGroup == "undefined" || ageGroup == undefined || ageGroup == "") {
        return "";
    }

    var url = util.appendParamsToUrl(Urls.pageInclude, {
        cid: ageGroup
    });

    $.ajax({
        dataType: "html",
        url: url
    }).done(function (response) {
        if (response === null || response === undefined) {
            return;
        }

        element.append(response);
        element.removeClass("render-age-rating");
    });

    return;
}

/**
 * Sort refinement values in alphabetical order
 * @input {Array} items - Refinement values
 * @return {Array} - Sorted refinement values
 */
function sortRefinementValues(items) {
    items.sort(function (refA, refB) {
        var labelA = refA.label.toUpperCase(),
            labelB = refB.label.toUpperCase();

        if (labelA < labelB) {
            return -1;
        } else if (labelA > labelB) {
            return 1;
        } else {
            return 0;
        }
    });

    return items;
}

/**
 * Add currency symbol to price refinement values
 * @input {Array} items - Refinement values
 * @return {Array} - Formatted refinement values
 */
function addPriceRangeCurrencySymbol(items) {
    for (var i in items) {
        if (items[i].label.indexOf("-") === 0) {
            items[i].label = items[i].label.replace("-", Resources.PRICE_RANGE_UNDER + " ") + currencySymbol;
        } else if (items[i].label.indexOf("-") >= 0) {
            items[i].label = items[i].label.replace("-", currencySymbol + "+");
        } else {
            items[i].label = items[i].label.replace(" – ", currencySymbol + "-") + currencySymbol;
        }
    }

    return items;
}

/**
 * Renders the hits in a defined html structure
 *
 * @param {Object} hitsRenderingOptions
 */
function uplaypcRenderTextHits(hitsRenderingOptions) {
    if (hitsRenderingOptions.widgetParams.containerNode.find(".search-results").length === 0) {
        var sectionTitle = '<h5 id="suggestion_title" class="c-algolia__hints-title">' + SitePreferences.ALGOLIA_SUGGEST_TITLE + "</h5>";
        var container = '<ul class="c-algolia__searched-list"></ul>';

        hitsRenderingOptions.widgetParams.containerNode.html(sectionTitle + container);
    }

    hitsRenderingOptions.widgetParams.containerNode.find(".c-algolia__searched-list").html(
        hitsRenderingOptions.hits.map(function (hit, position) {
            return `<li class="c-algolia__searched-item">
                <button class="c-algolia__searched-link"
                    data-action="suggestion-search"
                    data-query-text="${hit.query}"
                    data-position-number="${position + 1}"
                    data-search-suggestion>
                    ${hit.query}
                </button>
            </li>`;
        })
    );

    getAlgoliaSuggestionsTrackingData(hitsRenderingOptions, "texthints");
}

/**
 * Return the product tag: DLC, F2P
 *
 * @param {Object} hit algolia hit object
 * @return {String} the product tag wrapped by a span or empty
 */
function getProductTag(hit) {
    let productTag = "";
    let tagClass = "label";
    let isDLC = hit?.dlcType !== "" && ["seasonpass", "extensions", "skins", "currency"].indexOf(hit?.dlcType) !== -1;

    // free to play product
    if (hit?.Free_offer?.toLowerCase() === "freetoplay") {
        productTag = Resources.F2P;

    // DLC product
    } else if (isDLC) {
        productTag = Resources.DLC_TITLE;
    }

    return productTag ? `<span class="${tagClass}">${productTag}</span>` : "";
}

/**
 * Renders the productile based on the algolia search result
 *
 * @param {Object} hit algolia hit object
 * @param {Integer} position number of hit in query
 * @param {Object} results algolia query reslt object
 * @return {String} productbox html of producttile as a list item
 */
function renderProductTile(hit, position, results) {
    let isProductComingSoon = hit?.comingSoon?.toLowerCase() === "yes";
    let isProductFreePlay = hit?.Free_offer?.toLowerCase() === "freeplay";
    let isProductGiveAway = hit?.Free_offer?.toLowerCase() === "giveaway";
    let productLauncherID = hit?.productLauncherIDString || "";
    var productBox = '<li id="' + hit.id + '" data-object-id="' + hit.objectID + '" data-position-number="' + (position + 1) + '" data-index-id="' + results._rawResults[0].index + '" data-query-id="' + results._rawResults[0].queryID + '" class="grid-tile algolia-producttile-card">';
    var productBoxPrice = currencySymbolInFront ? currencySymbol + util.formatAlgoliaPrice(getAlgoliaPrice(hit.price_additional_locales)) : util.formatAlgoliaPrice(getAlgoliaPrice(hit.price_additional_locales)) + currencySymbol;
    let isProductFreeWithPurchase = hit?.Free_offer === "freeWithPurchase";
    let isPrepaidCard = hit?.isPrepaidCard?.toLowerCase() === "true";
    var link = hit.linkUpc;
    let priceNotAvailable = hit.availability === 0;

    // used for release date, edition
    const showProductDetails = !isProductComingSoon && !isProductFreePlay;

    productBox += '<div class="product-tile algolia-upc-see-more" tabindex="0" data-game-name="' + hit.Game + '" data-sub-brand="' + hit.sub_brand + '" data-pid="' + hit.id + '" data-product-launcherid="' + productLauncherID + '" data-product-name="' + hit.short_title + '" data-product-platform="' + hit.Platform + '" data-product-edition="' + hit.Edition + '" data-link="' + link + '" data-discount-rate="' + hit.promotion_percentage + '" data-available="' + hit.availability + '" data-preorder="' + hit.preorder + '" data-offer="' + hit.Free_offer + '" data-rating="' + hit.Rating + '">';

    // add image
    productBox += '<div class="link product-hit-link" data-pid="' + hit.id + '"><img class="image algolia-image-render lazy" src="' + hit.image_link + '" data-src="' + getDISImage(hit.image_link, "desktop") + '" alt="' + addTitle(hit) + '" />';
    productBox += '<div class="hover-card">';

    productBox += '<div class="wishlist-section"><div class="see-more has-tooltip">';

    if (Resources.ENABLE_WISHLIST && !isPrepaidCard) {
        productBox += '<div class="wishlist-button"><button tabindex="0" class="tooltip-trigger algolia-wishlist add-to-wishlist wishlist-heart-button" data-productid="' + hit.id + '" data-ismaster="' + hit.MasterID + '" data-redirect-link="' + link + '" aria-label="' + Resources.ADD_TO_WISHLIST + '">';
        productBox += '<span class="svg-wrapper wishlist-icon">' + symbols.wishlistHeart() + '</span></button><div class="tooltip-content product-tooltip" data-pid="' + hit.id + '">' + Resources.ADD_TO_WISHLIST + "</div>";
    }

    productBox += "</div></div></div>";

    productBox += "</div></div>";

    productBox += '<div class="card-additional-details card-labels-wrapper">';

    // add available badge in order of priority
    productBox += addAvailableBadge(hit);

    productBox += "</div>";
    // add title
    productBox += '<div class="title">';

    // added condition to check if the locale currency from response doesn't match with site currency - in this case update with default price from response
    if (getAlgoliaPrice(hit.price_additional_locales) === undefined) {
        productBoxPrice = currencySymbolInFront ? currencySymbol + util.formatAlgoliaPrice(hit.price) : util.formatAlgoliaPrice(hit.price) + currencySymbol;
    }

    productBox += '<div class="product-name">';

    // add dlc or F2P label if available
    const productTag = getProductTag(hit);

    if (productTag) {
        productBox += productTag;
    }

    productBox += addTitle(hit) + "</div>";

    const productEdition = showProductDetails ? addEdition(hit) : "";

    productBox += '<span class="edition">' + productEdition + "</span>";
    productBox += "</div>";

    // added condition to check if the locale currency from response doesn't match with site currency - in this case update with default price from response
    if (getAlgoliaPrice(hit.price_additional_locales) === undefined) {
        productBoxPrice = currencySymbolInFront ? currencySymbol + util.formatAlgoliaPrice(hit.price) : util.formatAlgoliaPrice(hit.price) + currencySymbol;
    }

    let customOOSMentionString = Resources.SOLD_OUT;

    if (SitePreferences.ALGOLIA_CUSTOM_OOS_MENTION && hit?.customOOSMentionString) {
        let localizedField = getCurrentLocaleField(hit.customOOSMentionString);

        // if customOOSMentionString for current locale is empty -> object will be returned and the text displayed needs to be generic - Resources.SOLD_OUT
        customOOSMentionString = typeof localizedField !== "object" ? localizedField : customOOSMentionString;
    }

    if (priceNotAvailable) {
        productBox += '<span class="standard-price">' + customOOSMentionString + "</span>";
    } else if (isProductComingSoon) {
        // if discount, add the discount info
        productBox += `<div class="price product-availability-label">${Resources.COMING_SOON_ALGOLIA}</div>`;
    } else if (isProductFreePlay) {
        productBox += `<div class="price product-availability-label">${Resources.FREE}</div>`;
    } else {
        productBox += '<div class="price">';

        if (hit.promotion_percentage != "" || isProductGiveAway || isProductFreeWithPurchase) {
            let productBoxPriceDefault = currencySymbolInFront ? currencySymbol + util.formatAlgoliaPrice(hit.default_price) : util.formatAlgoliaPrice(hit.default_price) + currencySymbol;
            let productBoxPriceSale = currencySymbolInFront ? currencySymbol + util.formatAlgoliaPrice(hit.price) : util.formatAlgoliaPrice(hit.price) + currencySymbol;

            productBox += '<span class="sale_price discount_price">' + productBoxPriceSale + "</span>";
            productBox += '<span class="standard-price">' + productBoxPriceDefault + "</span>";
        } else {
            if (getAlgoliaPrice(hit.minimum_price_additional_locales) !== undefined
                && getAlgoliaPrice(hit.minimum_price_additional_locales) !== "") {
                const productBoxMinPrice = currencySymbolInFront ? currencySymbol + util.formatAlgoliaPrice(getAlgoliaPrice(hit.minimum_price_additional_locales)) : util.formatAlgoliaPrice(getAlgoliaPrice(hit.minimum_price_additional_locales)) + currencySymbol;

                // display the minimum price according to specific locales
                productBox += '<span class="sale_price">' + productBoxMinPrice + "</span>";
            } else if (isPrepaidCard && hit.minimum_price) {
                // Minimum price come in different format in algoliaV1 and algoliaV2
                var min = hit.minimum_price[0] ? hit.minimum_price[0][currencyCodeSite] : hit.minimum_price[currencyCodeSite];
                var minimumPrice = currencySymbolInFront ? currencySymbol + util.formatAlgoliaPrice(min) : util.formatAlgoliaPrice(min) + currencySymbol;

                productBox += '<span class="price-label">' + Resources.PRODUCT_FROM + "</span>";
                productBox += '<span class="sale_price">' + minimumPrice + "</span>";
            } else {
                // display default price
                productBox += '<span class="sale_price">' + productBoxPrice + "</span>";
            }
        }

        // add price recurrency
        const priceRecurrency = getCurrentLocaleField(hit.ibexRecurrencyLabel);

        if (priceRecurrency && typeof priceRecurrency === "string") {
            productBox += `<span class="price__recurrency">${" / " + priceRecurrency}</span>`;
        }

        productBox += "</div>";
    }

    productBox += "</div>";

    productBox += "</li>";

    return productBox;
}

/**
 * Renders the hits as producttile in a defined html structure
 *
 * @param {Object} hitsRenderingOptions
 */
function uplaypcRenderProductHits(hitsRenderingOptions) {
    if (hitsRenderingOptions.widgetParams.containerNode.find(".search-results").length === 0) {
        var sectionTitle = '<h5 id="push_title" class="c-algolia__hints-title">' + SitePreferences.ALGOLIA_PUSH_TITLE + "</h5>";
        var container = '<ul class="c-algolia__maylike-list search-results"></ul>';

        hitsRenderingOptions.widgetParams.containerNode.html(sectionTitle + container);
    }

    if (hitsRenderingOptions.hits.length === 0) {
        hitsRenderingOptions.widgetParams.containerNode.html("");
    } else {
        hitsRenderingOptions.widgetParams.containerNode.find("ul").html(

            hitsRenderingOptions.hits.map(function (hit, position) {
                return renderProductTile(hit, position, hitsRenderingOptions.results);
            })
        );
    }

    getAlgoliaSuggestionsTrackingData(hitsRenderingOptions, "producthits");
}

/**
* @function
* @description Renders Focus banner content asset in the Algolia layer if it's id is received from the search
* @param {String} customBannerId - id of the content asset, which should be rendered
*/
const renderFocusBanner = customBannerId => {
    const $algoliaBannerContainer = $(".js-algolia-banner");

    if ($algoliaBannerContainer.length) {
        if (customBannerId) {
            const $focusBanner = $algoliaBannerContainer.find(".js-focus-banner");
            const isFocusBannerRendered = $focusBanner.length;
            const isFocusBannerChanged = isFocusBannerRendered && $focusBanner.attr("data-bannerID") !== customBannerId;

            if (!isFocusBannerRendered || isFocusBannerChanged) {
                const url = util.appendParamToURL(Urls.pageShow, "cid", customBannerId);

                fetch(url)
                    .then(response => {
                        if (response.ok) {
                            return response.text();
                        } else {
                            return false;
                        }
                    })
                    .then(html => {
                        if (html) {
                            $algoliaBannerContainer.html("");
                            $algoliaBannerContainer.append(html);

                            const $focusBannerInserted = $algoliaBannerContainer.find(".js-focus-banner");

                            if ($focusBannerInserted.length) {
                                $focusBannerInserted.attr("data-bannerID", customBannerId);
                            }

                            let lazyLoadInstance = new LazyLoad({
                                elements_selector: ".c-focus-banner__background-image.lazy"
                            });

                            if (lazyLoadInstance) {
                                lazyLoadInstance.update();
                            }
                        }
                    });
            }
        } else {
            $algoliaBannerContainer.html("");
        }
    }
};

/**
 * Renders the infinite hits in a defined html structure for uplay pc
 *
 * @param {Object} InfiniteHitsRenderingOptions
 */
function uplaypcRenderInfiniteHits(InfiniteHitsRenderingOptions) {
    if (InfiniteHitsRenderingOptions.widgetParams.containerNode.find(".search-results").length === 0) {
        let scrollTopButton = "";

        if (!$document.find("[data-scroll-top]").length) {
            scrollTopButton = `<div class="scroll-top show">
                <a href="#search-result-items" class="btn-transparent scroll-top__button" data-scroll-top aria-label="${Resources.BACK_TO_TOP}">
                    <i class="symbol-arrow-up" aria-hidden="true"></i>
                </a>
            </div>`;
        }

        InfiniteHitsRenderingOptions.widgetParams.containerNode
            .html(`<div class="search-results">
                    <span class="algolia-tag-hit-results tag-commander-event" data-search-keyword="" data-result-number=""></span>
                    <ul id="search-result-items"></ul>
                </div>
                <div class="load-more-wrapper">
                    <button class="load-more-button" id="show-more" aria-label="${Resources.SEARCH_LOADMORE_BUTTON_TITLE}"><span>${Resources.SEARCH_LOADMORE_BUTTON_TITLE}</span></button>
                </div>
                ${scrollTopButton}`);

        InfiniteHitsRenderingOptions.widgetParams.containerNode
            .find("#show-more")
            .on("click", function (event) {
                $(".load-image").addClass("infinite-scroll-loading");
                event.preventDefault();
                InfiniteHitsRenderingOptions.showMore();
            });
    }

    const noResults = InfiniteHitsRenderingOptions.hits.length === 0 && InfiniteHitsRenderingOptions.results !== undefined;
    const queryID = InfiniteHitsRenderingOptions && InfiniteHitsRenderingOptions.results ? InfiniteHitsRenderingOptions.results.queryID : "";
    const query = InfiniteHitsRenderingOptions && InfiniteHitsRenderingOptions.results ? InfiniteHitsRenderingOptions.results.query : "";
    const indexName = InfiniteHitsRenderingOptions && InfiniteHitsRenderingOptions.results ? InfiniteHitsRenderingOptions.results.index : "";
    const abTestIndexName = InfiniteHitsRenderingOptions && InfiniteHitsRenderingOptions.results && InfiniteHitsRenderingOptions.results.indexUsed !== undefined ? InfiniteHitsRenderingOptions.results.indexUsed : "";
    const resultsNumber = InfiniteHitsRenderingOptions && InfiniteHitsRenderingOptions.results ? InfiniteHitsRenderingOptions.results.nbHits : "";
    const $searchBox = $("#searchbox");
    const userData = InfiniteHitsRenderingOptions?.results?.userData || "";
    const customBannerId = userData[0]?.custom_banner_ID || "";

    $searchBox.data("query-id", queryID);
    $searchBox.data("query", query);
    $searchBox.data("index-name", indexName);
    $searchBox.data("is-result", !noResults);
    $searchBox.data("ab-test-index-name", abTestIndexName);
    $searchBox.data("results-number", resultsNumber);

    const $wrapper = $("#wrapper");

    $wrapper.toggleClass("pt_product-search-noresult", noResults);

    const $searchInput = $("#searchbox input");
    const $noHitsSearchTerm = $("#no-hits-search-term");

    if (noResults) {
        InfiniteHitsRenderingOptions.widgetParams.containerNode.html("");

        if ($noHitsSearchTerm.length) {
            $noHitsSearchTerm.html(escapeHTML($searchInput.val()) || "")
                .trigger("click");
        }
    } else {
        InfiniteHitsRenderingOptions.widgetParams.containerNode.find("ul").html(
            InfiniteHitsRenderingOptions.hits.map(function (hit, position) {
                var productBox = renderProductTile(hit, position, InfiniteHitsRenderingOptions.results);

                if (InfiniteHitsRenderingOptions.results.page + 1 >= InfiniteHitsRenderingOptions.results.nbPages) {
                    $(".load-more-button").hide();
                } else {
                    $(".load-more-button").show();
                }

                return productBox;
            })
        );

        // Ubisoft - load more button's attributes on search layer for tag commander
        if (InfiniteHitsRenderingOptions.results) {
            const $showMore = $("#show-more");

            if ($showMore.length) {
                const resultPageNumber = InfiniteHitsRenderingOptions.results.page + 1;
                const lastPageNumber = InfiniteHitsRenderingOptions.results.nbPages;

                $showMore.data("result-page-number", resultPageNumber)
                    .data("last-page-number", lastPageNumber);
            }

            if (InfiniteHitsRenderingOptions.results.query && $searchInput.length > 0 && !$searchInput.val()) {
                $searchInput.val(InfiniteHitsRenderingOptions.results.query);
            }
        }

        const $algoliaTagHitResults = $(".algolia-tag-hit-results");

        if ($algoliaTagHitResults.length) {
            const searchKeyword = escapeHTML($searchInput.val());
            const resultNumberLength = $(".stats-container").find("span").parent().clone().children().remove().end().text().trim().split(" ").length;
            const searchResultNumber = resultNumberLength > 0 ? $(".stats-container").find("span:first").text() : 0;

            $algoliaTagHitResults.attr("data-search-keyword", searchKeyword)
                .attr("data-result-number", searchResultNumber);
        }
    }

    getAlgoliaQueryTrackingData(InfiniteHitsRenderingOptions);

    renderFocusBanner(customBannerId);
}

/**
* @function
* @description This function is used to get the Algolia query tracking data and store it inside the localStorage
* @param {Object} InfiniteHitsRenderingOptions - The object of the Algolia query tracking data
*/
const getAlgoliaQueryTrackingData = InfiniteHitsRenderingOptions => {
    const algoliaObject = InfiniteHitsRenderingOptions;
    const algoliaResults = algoliaObject?.results;

    if (algoliaResults) {
        const searchKeyword = algoliaObject.results.query ?? "";
        const searchQueryLength = searchKeyword.length;
        const totalResults = algoliaObject.results.nbHits;
        const pagination = algoliaObject.results.page + 1;
        const searchQueryId = algoliaObject.results.queryID ?? "";
        const searchAbTesting = algoliaObject.results.indexUsed ?? algoliaObject.results.index ?? "";
        const hitProducts = algoliaObject.results.hits;

        let productList = [];

        for (let i = 0; i < hitProducts.length; i++) {
            productList.push(hitProducts[i].id);
        }

        const algoliaQueryData = [{
            searchKeyword: searchKeyword,
            searchQueryLength: searchQueryLength,
            totalResults: totalResults,
            pagination: pagination,
            searchQueryId: searchQueryId,
            searchAbTesting: searchAbTesting,
            productList: productList
        }];

        if (typeof(Storage) !== "undefined") {
            localStorage.setItem("algoliaQueryData", JSON.stringify(algoliaQueryData));
        }

        if (pagination > 1) {
            setTimeout(() => {
                document.dispatchEvent(new CustomEvent("Algolia:ShowResultList", {
                    detail: productList
                }));
            }, 1000);
        } else {
            debounceShowResultListEvent(productList);
        }
    }
};

// dispatch a custom event with the search results after a delay of 1 second to ensure that the event is only dispatched once and not too frequently.
const debounceShowResultListEvent = _.debounce((algoliaResults = []) => {
    document.dispatchEvent(new CustomEvent("Algolia:ShowResultList", {
        detail: algoliaResults
    }));
}, 1000);

/**
* @function
* @description This function is used to get the Algolia query suggestions tracking data and store it inside the localStorage
* @param {Object} hitsRenderingOptions - The object of the Algolia query tracking data
* @param {String} suggestionType - The type of the Algolia query tracking data - texthints or producthits
*/
const getAlgoliaSuggestionsTrackingData = (hitsRenderingOptions, suggestionType) => {
    const algoliaResults = {
        ...hitsRenderingOptions?.results
    };

    if (!Object.entries(algoliaResults).length) return;

    let algoliaSearchSuggestionData = JSON.parse(localStorage.getItem("algoliaSearchSuggestionData")) || {};

    localStorage.removeItem("algoliaSearchSuggestionData");

    switch (suggestionType) {
        case "texthints":
            algoliaSearchSuggestionData.textHints = {
                keys: (algoliaResults.hits?.map(item => item.query)) || [],
                queryId: algoliaResults.queryId ?? ""
            };

            break;

        case "producthits":
            algoliaSearchSuggestionData.productHits = {
                list: (algoliaResults.hits?.map(product => product.id)) || [],
                queryId: algoliaResults.queryId ?? ""
            };

            break;

        default: break;
    }

    if (typeof(Storage) !== "undefined") {
        localStorage.setItem("algoliaSearchSuggestionData", JSON.stringify(algoliaSearchSuggestionData));
    }
};

/**
* @function
* @description add product tile
* @param {elem} object checked
*/
function addTitle(hit) {
    var productTitle = hit.title;

    if (hit?.title_additional_locales) {
        const seaProductTitle = getCurrentLocaleField(hit.title_additional_locales, true);

        if (seaProductTitle && typeof seaProductTitle !== "object") {
            productTitle = seaProductTitle;
        }
    } else if (hit.short_title !== "" && getCurrentLocaleField(hit.product_type, false) === "games") {
        productTitle = hit.short_title;
    }

    return productTitle;
}

/**
* @function
* @description add product edition
* @param {Object} hit
*/
function addEdition(hit) {
    let editionLabel = (hit.Edition || hit.custom_label_1) || "";

    if (hit?.edition_additional_locales) {
        const seaEditionLabel = getCurrentLocaleField(hit.edition_additional_locales, true);

        if (seaEditionLabel && typeof seaEditionLabel !== "object") {
            editionLabel = seaEditionLabel;
        }
    }

    return editionLabel || "";
}

// infinite scroll implementation
/**
* @function
* @description check if the element is in view
* @param {elem} object checked
*/
function isElementInView(elem) {
    var $elem = $(elem);

    if ($elem.length == 0) {
        return false;
    }

    var docViewTop = $window.scrollTop(),
        docViewBottom = docViewTop + window.innerHeight,
        elemTop = $elem.offset().top,
        elemBottom = elemTop + $elem.height();

    return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop));
}

$document.on("scroll", function () {
    viewMoreHit = isElementInView("#show-more");

    if (!$(".container-fluid").hasClass("visually-hidden")) {
        if (viewMoreHit && !viewMoreHitState) {
            $("#show-more").trigger("click", ["autodisplay"]);
            viewMoreHitState = true;
        }
    }

    viewMoreHitState = viewMoreHit;
});

/**
 * Init add to cart tile for order and uplay plus pages
 */
(function initializeAddToCartTile() {
    if (typeof pageContext != "undefined" && pageContext.algolia == "allow" && SitePreferences.SEARCH_ENGINE_ID === "algolia" && SitePreferences.ALGOLIA_ENABLED) {
        initAddToCartTile();
    }
})();

/**
 * Get the refinaments for locales
 * @return {refinementLabel} - return price value
 */
const refinementLocale = refinementLabel => {
    let result = [];

    if (refinementLabel) {
        try {
            let parserefinementValue = JSON.parse(refinementLabel.replace(/"""/g, '""'));

            Object.keys(parserefinementValue).forEach(key => {
                result.push({
                    locale: (parserefinementValue[key].locale || "").replace("default-", ""),
                    value: parserefinementValue[key].value,
                    currency: (parserefinementValue[key].locale || "").split("-")[1]
                });
            });

            return result;
        } catch {
            return false;
        }
    }
};

/**
 * Create array with extra elements and View more button
 * @param {Array} - array with refinement elements
 */
const createExtraItemsArray = list => {
    let result = [];
    let $viewMore = $("<li><button class='btn-link view-more'>" + Resources.VIEW_MORE + "</button></li>");
    let $viewLess = $("<li><button class='btn-link view-less'>" + Resources.VIEW_LESS + "</button></li>");
    let $extraItemsLi = $("<li class='extra-items'></li>");
    let $extraItemsUl = $("<ul>", {
        class: "extra-items-list"
    });

    if (list && list.length > 8) {
        let firstPart = list.slice(0, 8);
        let listExtra = list.slice(8);

        $extraItemsUl.html(listExtra);
        $extraItemsLi.append($extraItemsUl);
        result = [...firstPart, $viewMore, $extraItemsLi, $viewLess];

        return result;
    }
};

/**
 * Renders the refinement (filter) in a defined html structure for UPC
 *
 * @param {Object} RefinementListRenderingOptions
 * @param {Boolean} isFirstRendering
 */
function listRefinementRender(RefinementListRenderingOptions, isFirstRendering) {
    var firstRefinement = $(".sidebar-sticky .refinement").has("h3").first(),
        filtersAlgolia = $("#refinements-breadecrumb").find(".breadcrumbRefinement").length,
        refinements = $(".js-algolia-primary"),
        spanStickyAlgolia = $("#sticky-filter-algolia").find("span");
    let storefrontLocale = GeoRestrictedResources.STOREFRONT_CURRENT_LOCALE;

    spanStickyAlgolia.text(filtersAlgolia);

    $(document).on("click", function (event) {
        const $target = $(event.target);

        if (!$target.closest(refinements).length && !$target.is(refinements)) {
            if (refinements.hasClass("show")) {
                refinements.removeClass("show");
            }
        }

        if ($target.hasClass("view-more")) {
            event.preventDefault();

            const $refinementWrapper = $target.closest("ul");

            if ($refinementWrapper) {
                $refinementWrapper.addClass("view-all-refinements");
            }
        } else if ($target.hasClass("view-less")) {
            event.preventDefault();

            const $refinementWrapper = $target.closest("ul");

            if ($refinementWrapper) {
                $refinementWrapper.removeClass("view-all-refinements");
            }
        }
    });

    if (firstRefinement.find("ul").children().length > 0) {
        firstRefinement.find("h3").addClass("is-open expanded");
        firstRefinement.find("ul").show();
        firstRefinement.find("h3").find("i").addClass("active");
    }

    if (isFirstRendering) {
        RefinementListRenderingOptions.widgetParams.containerNode
            .html('<div class="refinement"><div class="toggle is-open expanded">' + RefinementListRenderingOptions.widgetParams.templates.header + '<i class="symbol-arrow-down" aria-hidden="true"></i></div><ul class="refinement-group"></ul>');
    }

    RefinementListRenderingOptions.widgetParams.containerNode
        .find("li[data-refine-value]")
        .each(function () { $(this).off("click"); });

    if (RefinementListRenderingOptions.canRefine) {
        let atributeName = RefinementListRenderingOptions.widgetParams.attributeName;

        RefinementListRenderingOptions.items = sortRefinementValues(RefinementListRenderingOptions.items);

        if (atributeName === "price_range") {
            RefinementListRenderingOptions.items = addPriceRangeCurrencySymbol(RefinementListRenderingOptions.items);
        }

        var list = RefinementListRenderingOptions.items.map(function (item) {
            if (atributeName == "genre_additional_locales"
            || atributeName == "productTypeRefinement_additional_locales"
            || atributeName == "price_range_additional_locales") {
                let labelValue;

                if (storefrontLocale == "ia_BR") { storefrontLocale = "en_BR"; }

                if (storefrontLocale == "la_BR") { storefrontLocale = "es_BR"; }

                item.value = item.label;

                if (refinementLocale(item.label)) {
                    if (currentSite == "BR" && atributeName == "price_range_additional_locales") {
                        labelValue = refinementLocale(item.label).find(x => x.currency == currencyCodeSite);
                        labelValue ? item.label = labelValue.value : item.label = "";
                    } else {
                        labelValue = refinementLocale(item.label).find(x => x.locale == storefrontLocale);
                        labelValue ? item.label = labelValue.value : item.label = "";
                    }
                }
            }

            // display hide refinements
            RefinementListRenderingOptions.widgetParams.containerNode.removeClass("hide");

            let emptyLabel = item.label == "" ? true : false;
            let emptyClass = item.label == "" ? "empty-refinement-value" : "";

            let classes = `${item.isRefined ? "selected" : ""} ${emptyClass}`;

            let refineValueData = (atributeName == "productTypeRefinement_additional_locales" ||
                atributeName == "genre_additional_locales" ||
                atributeName == "price_range_additional_locales") ?
                `'${item.value}'` : `"${item.value}"`;

            return `<li data-isempty=${emptyLabel} data-refine-value=${refineValueData} data-label=${item.label} data-refine-count=${item.count} class=${classes}>
                    <a class="algolia-search-filter" href=${RefinementListRenderingOptions.createURL(item.value)} rel="nofollow">
                        <input type="checkbox" value=${refineValueData} id=${refineValueData} />
                        <label for=${refineValueData} class="refinement-label">
                            <span>${item.label} (${item.count})</span>
                        </label>
                    </a>
                </li>`;
        });

        if (list !== undefined) {
            let count = 0;

            list.forEach((elem, index) => {
                if ($(elem).data("isempty") === true) {
                    count++;
                    list.splice(index, 1);
                }
            });

            if (count == list.length) {
                list.push('<li class="empty-additional-locale-header"></li>');
            }

            if (list.length === 1) {
                list.push('<li class="single-refinement"></li>');
            } else if (list.length > 8) {
                list = createExtraItemsArray(list);
            }
        }

        RefinementListRenderingOptions.widgetParams.containerNode.find("ul").html(list);

        // hide the entire refinement if all items are missing or only single refinement is available
        $(".empty-additional-locale-header").closest(".listRefinement").addClass("hide");
        $(".single-refinement").closest(".listRefinement").addClass("hide");

        // hide element it the item it's empty
        $(".refinement-group").find("li.empty-refinement-value").addClass("hide");

        RefinementListRenderingOptions.widgetParams.containerNode
            .find("li[data-refine-value]")
            .each(function () {
                let inputElement = $(this).find("input");

                $(this).on("click", function (event) {
                    event.preventDefault();

                    if (atributeName == "genre_additional_locales"
                    || atributeName == "productTypeRefinement_additional_locales"
                    || atributeName == "price_range_additional_locales") {
                        RefinementListRenderingOptions.refine(JSON.stringify($(this).data("refine-value")));
                    } else {
                        RefinementListRenderingOptions.refine($(this).data("refine-value"));
                    }
                });

                if ($(this).hasClass("selected")) {
                    if (inputElement) {
                        inputElement.attr("checked", "checked");
                    }
                } else {
                    if (inputElement) {
                        inputElement.removeAttr("checked");
                    }
                }
            });
    } else {
        RefinementListRenderingOptions.widgetParams.containerNode.find("ul").html("");

        // hide empty refinements
        RefinementListRenderingOptions.widgetParams.containerNode.addClass("hide");
    }
}

/**
 * Renders the refinement (filter) in a defined html structure
 *
 * @param {Object} RefinementListRenderingOptions
 * @param {Boolean} isFirstRendering
 */
function boxRefinementRender(RefinementListRenderingOptions, isFirstRendering) {
    var firstRefinement = $(".sidebar-sticky .refinement").has("h3").first();

    if (firstRefinement.find("ul").children().length > 0) {
        firstRefinement.find("h3").addClass("is-open expanded");
        firstRefinement.find("ul").show();
        firstRefinement.find("h3").find("i").addClass("active");
    }

    if (isFirstRendering) {
        RefinementListRenderingOptions.widgetParams.containerNode
            .html('<div class="box refinement"><h3 class="toggle"><i class="fa"></i>' + RefinementListRenderingOptions.widgetParams.templates.header + "</h3><ul></ul>");
    }

    RefinementListRenderingOptions.widgetParams.containerNode
        .find("li[data-refine-value]")
        .each(function () { $(this).off("click"); });

    if (RefinementListRenderingOptions.canRefine) {
        RefinementListRenderingOptions.items = sortRefinementValues(RefinementListRenderingOptions.items);

        var list = RefinementListRenderingOptions.items.map(function (item) {
            return '<li data-refine-value="' + item.value + '" data-refine-count="' + item.count + '" class="' + (item.isRefined ? "selected" : "") + '">' +
                    '<input type="checkbox" value="' + item.value + '" />' +
                    '<a href="' + RefinementListRenderingOptions.createURL(item.value) + '" rel="nofollow">' +
                        item.label +
                    "</a>" +
                "</li>";
        });

        RefinementListRenderingOptions.widgetParams.containerNode.find("ul").html(list);
        RefinementListRenderingOptions.widgetParams.containerNode
            .find("li[data-refine-value]")
            .each(function () {
                $(this).on("click", function (event) {
                    event.stopPropagation();
                    event.preventDefault();

                    RefinementListRenderingOptions.refine($(this).data("refine-value"));
                });
            });
    } else {
        RefinementListRenderingOptions.widgetParams.containerNode.find("ul").html("");
    }
}

/**
 * Creates the data attributes for each refinement breadcrumb
 * @param {Object} refinement
 * @return {String} string with data attributes
 */
function createDataAttributes(refinement) {
    return Object.keys(refinement)
        .map(key => `data-${key}="${refinement[key]}"`)
        .join(" ");
}

/**
 * Renders the refinement breadcrumbs in a defined html structure
 *
 * @param {Object} RefinementListRenderingOptions
 * @param {Boolean} isFirstRendering
 */
function renderRefinementBreadcrumds(CurrentRefinedValuesRenderingOptions, isFirstRendering) {
    var containerNode = CurrentRefinedValuesRenderingOptions.widgetParams.containerNode;

    if (isFirstRendering) {
        containerNode.html("<ul id='refinements-breadecrumb'><li></li></ul><div id='cta-container2'></div>");
    }

    containerNode.find("#cta-container2 > a").off("click");
    containerNode.find("li > a").each(function () { $(this).off("click"); });

    if (CurrentRefinedValuesRenderingOptions.items && CurrentRefinedValuesRenderingOptions.items.length > 0) {
        $("#refinements-breadecrumb").empty();

        CurrentRefinedValuesRenderingOptions.items.forEach(function (attributeGroup) {
            attributeGroup.refinements.forEach(function (refinement) {
                let result = `<li><a class="breadcrumbRefinement" ${createDataAttributes(refinement)} rel="nofollow">
                                ${refinement.attribute.indexOf("price") > -1 ? Resources["SEARCH_REFINEMENT_PRICE_RANGE"] + " " + refinement.label : refinement.label}
                                <span class="clearRefinement"> </span>
                            </a></li>`;

                $("#refinements-breadecrumb").append(result);
            });
        });

        $("#refinements-breadecrumb li > a").each(function () {
            var $this = $(this);

            $this.on("click", function (event) {
                event.preventDefault();

                let item = $this.data();

                CurrentRefinedValuesRenderingOptions.refine(item);
            });
        });

        var filtersAlgolia = $("#refinements-breadecrumb").find(".breadcrumbRefinement").length,
            spanStickyAlgolia = $("#sticky-filter-algolia").find("span");

        spanStickyAlgolia.text(filtersAlgolia);
        $("#secondary").removeClass("show");
    } else {
        containerNode.find("#cta-container2").html("");
        containerNode.find("ul").html("");
    }
}

/**
 * Renders sorting selector in a defined html structure
 *
 * @param {Object} RefinementListRenderingOptions
 * @param {Boolean} isFirstRendering
 */
function uplaypcSortingSelectorRender(SortBySelectorRenderingOptions, isFirstRendering) {
    var $container = SortBySelectorRenderingOptions.widgetParams.containerNode;
    var $select = "";

    if (isFirstRendering) {
        $select = $("<select/>", {
            id: "sort-by-select"
        });

        $select.on("change", function () {
            SortBySelectorRenderingOptions.refine($select.val());
        });

        $select.appendTo($container);
        $container.children("label").attr("for", "sort-by-select");
    } else {
        $select = $("#sort-by-select");
        $select.empty();
    }

    SortBySelectorRenderingOptions.options.map(function (option) {
        var $option = $("<option/>", {
            value: option.value,
            lavel: option.label,
            text: option.label
        });

        $select.append($option);

        if (SortBySelectorRenderingOptions.currentRefinement === option.value) {
            $select.val(option.value);
        }
    });

    $select.selectBoxIt();
}

exports.uplaypcRenderInfiniteHits = uplaypcRenderInfiniteHits;
exports.listRefinementRender = listRefinementRender;
exports.boxRefinementRender = boxRefinementRender;
exports.renderRefinementBreadcrumds = renderRefinementBreadcrumds;
exports.uplaypcSortingSelectorRender = uplaypcSortingSelectorRender;
exports.getAgeGroupContent = getAgeGroupContent;
exports.uplaypcRenderTextHits = uplaypcRenderTextHits;
exports.uplaypcRenderProductHits = uplaypcRenderProductHits;
