import "slick-carousel";
import "jcarousel";
import "jquery-ui/ui/widgets/dialog";
import debounce from "lodash/debounce";
import { initSearch } from "./search";
import { initializeProductSlider } from "./product/productSlider";
import { symbols } from "../symbols";
import { initVIdeoDialog } from "../videodialog";

var _ = {
        debounce
    },
    isPause = false,
    tick;

const data = {
    // the wrapper container for double & triple push
    pushWrapper: "[data-push-wrapper]",
    // contains the slot title & subtitle
    slotHeader: "[data-slot-holder=header]",
    // contains the view more link
    slotFooter: "[data-slot-holder=footer]",
    // double/triple push elements - the attribute is to be added for the elements that needs grouping
    triplePush: "[data-triple-push]",
    doublePush: "[data-double-push]",
    // wrapper container for the push elements
    triplePushWrapper: "<div data-push-wrapper class='push-wrapper push-wrapper--triple'/>",
    doublePushWrapper: "<div data-push-wrapper class='push-wrapper push-wrapper--double'/>",
    // main container for the push section - contains title, subtitle, push elements, view more link
    pushDoubleSection: "<div class='push-section'></div>",
    pushTripleSection: "<div class='push-section push-section--triple'></div>"
};

let $cache = {};

/**
 * @function
 * @description initialize cache object
 */
const initCache = () => {
    $cache = {
        triplePush: $(data.triplePush),
        doublePush: $(data.doublePush)
    };
};

/**
 * @function
 * @description Move elements(title, subtitle, view more link) from double/triple pushes outside the element and wrap all in a container
 * @param {Boolean} isDoublePush - true for double push, false for triple
 */
const repositionPushElements = ($pushWrapper, isDoublePush) => {
    $pushWrapper.each((i, item) => {
        const $currentItem = $(item);
        const $headerItems = $currentItem.find(data.slotHeader);
        const $footerItems = $currentItem.find(data.slotFooter);

        $(item).wrap(isDoublePush ? data.pushDoubleSection : data.pushTripleSection);

        // moves the title and subtitle before the first triple element
        if ($headerItems.length) {
            $headerItems.insertBefore($(item));
        }

        // moves the view more link after the last triple element
        if ($footerItems.length) {
            $footerItems.insertAfter($(item));
        }
    });

    // reinitialize the cache
    initCache();
};

/**
 * @function
 * @description Wrap the double/triple push elements
 * @param {Object} $pushElements - jquery object containing the elements to be grouped
 */
const groupPushElements = $pushElements => {
    if (!$pushElements.length) {
        return;
    }

    const isDoublePush = $pushElements.filter(data.doublePush).length > 0;
    const wrapper = isDoublePush ? data.doublePushWrapper : data.triplePushWrapper;
    const groupCount = isDoublePush ? 2 : 3;
    const holder = [];
    const $pushElementsToWrap = $pushElements.filter((index, element) => $(element).parents(data.pushWrapper).length === 0);

    if (!$pushElementsToWrap.length) {
        return;
    }

    $pushElementsToWrap.each((i, item) => {
        holder.push(item);

        if (holder.length === groupCount) {
            $(holder).wrapAll(wrapper);

            holder.length = 0;
        }
    });

    $(holder).wrapAll(wrapper);

    repositionPushElements($pushElementsToWrap.parents(data.pushWrapper), isDoublePush);
};

/**
 * @function
 * @description function to create progress bar slider
 */
function progressBar() {
    var percentTime;
    var $document = $(document);
    var $slider = $(".homepage-slider");
    var slides = $(".homepage-slider .slick-dots li").length;
    var sliderTime = ($slider && $slider.data("autoplay-time")) ? $slider.data("autoplay-time") : 6000;
    var time = (sliderTime / 1000) + 0.6; // duration of autoplaySpeed in seconds
    var progressBarIndex = 0;

    $document.find(".homepage-slider .slick-slide")
        .mouseenter(function () {isPause = true;})
        .mouseleave(function () {
            if (!$(this).find(".homepage-slider .slick-arrow:hover").length) {
                isPause = false;
            } else {
                isPause = true;
            }
        });

    $document.find(".homepage-slider .slick-arrow")
        .mouseenter(function () {isPause = true;})
        .mouseleave(function () {
            if (!$(this).find(".homepage-slider .slick-slide:hover").length) {
                isPause = false;
            }
        });

    $(".progressBar").each(function (index) {
        var $progress = "<div class='inProgress inProgress" + index + "'></div>";

        $(this).html($progress);
    });

    function startProgressbar() {
        resetProgressbar();
        percentTime = 0;
        tick = setInterval(interval, 10);
    }

    function interval() {
        if (($(".homepage-slider .slick-track div[data-slick-index='" + progressBarIndex + "']").attr("aria-hidden")) === "true") {
            progressBarIndex = $(".homepage-slider .slick-track div[aria-hidden='false']").data("slickIndex");
            startProgressbar();
        } else {
            if (isPause === false) {
                percentTime += 1 / (time);
                $(".inProgress" + progressBarIndex).css({
                    width: percentTime + "%"
                });

                if (percentTime >= 100) {
                    progressBarIndex++;

                    if (progressBarIndex > slides) {
                        progressBarIndex = 0;
                    }

                    startProgressbar();
                    $slider.slick("slickNext");
                }
            }
        }
    }

    function resetProgressbar() {
        $(".inProgress").css({
            width: 0 + "%"
        });
        clearInterval(tick);
    }

    startProgressbar();

    $(".pager__item").off("click").on("click", function () {
        clearInterval(tick);
        startProgressbar();
    });
}

/**
 * @function
 * @description function to open the video for double push
 */
const initDoublePushVideo = () => {
    let $pushProductDarkVideoContainer = $(".push-product-dark-video");
    let $html = $("html");
    let $revealOverlay = $pushProductDarkVideoContainer.find(".reveal-overlay");
    let $closeBtn = $pushProductDarkVideoContainer.find(".close-reveal-modal");
    let $ytPlayerFrames = $("#ytplayerframe");

    const stopVideo = () => {
        if ($ytPlayerFrames.length) {
            $ytPlayerFrames.each((index, element) => {
                const $element = $(element);
                const $video = $element.attr("src");

                if ($video) {
                    $element.attr("src", "");
                    $element.attr("src", $video);
                }
            });
        }
    };

    if ($pushProductDarkVideoContainer.length) {
        let $videoOverlay = $closeBtn.closest(".reveal-overlay");

        if ($closeBtn.length && $videoOverlay.length) {
            $closeBtn.on("click", e => {
                e.preventDefault();

                $html.removeClass("video-overlay-active");
                $videoOverlay.hide();
                stopVideo();
            });
        }
    }

    $(".double-push-video-btn").on("click", e => {
        e.preventDefault();

        let $doublePushTile = $(e.target).closest(".push-product-dark-video");
        let $revealClose = $closeBtn.find(".reveal-close");

        if ($revealClose.length) {
            $revealClose.show();
        }

        if ($doublePushTile.length) {
            let $videoOverlay = $doublePushTile.find(".reveal-overlay");

            if ($videoOverlay.length) {
                $videoOverlay.show();
                $html.addClass("video-overlay-active");
            }
        }
    });

    if ($revealOverlay.length) {
        $revealOverlay.on("click", e => {
            e.preventDefault();
            $(e.target).hide();
            $html.removeClass("video-overlay-active");
            stopVideo();
        });
    }
};

function initStorefront() {
    initCache();
    initSearch();

    // update the HTML structure of the triple push
    groupPushElements($cache.triplePush);

    // update the HTML structure of the double push
    groupPushElements($cache.doublePush);

    var videoID = $("#vidplayer"),
        player,
        YTdeferred = $.Deferred();

    function onPlayerStateChange(event) {
        switch (event.data) {
            case 0:
                $(".homepage-slider").slick("slickPlay");
                break;
            case 1:
                $(".homepage-slider").slick("slickPause");
                break;
        }
    }

    function stopVideo() {
        $(".slick-dots button").on("click", function () {
            if (player.getPlayerState() == 1) {
                player.stopVideo();
                $(".homepage-slider").slick("slickPlay");
            }
        });
    }

    if ($(videoID).length > 0) {
        $.getScript("https://www.youtube.com/iframe_api");

        window.onYouTubeIframeAPIReady = function () {
            YTdeferred.resolve(window.YT);
        };

        YTdeferred.done(function (YT) {
            player = new YT.Player("vidplayer", {
                height: "90%",
                width: "100%",
                host: "https://www.youtube-nocookie.com",
                videoId: videoID.attr("data-attribute"),
                playerVars: {
                    rel: 0,
                    origin: window.location.host
                },
                events: {
                    onReady: stopVideo,
                    onStateChange: onPlayerStateChange
                }
            });
        });
    }

    $(".homepage-slider").each(function (elem) {
        $(this).not(".slick-initialized").slick({
            dots: true,
            prevArrow: symbols.transparentPrevArrow(),
            nextArrow: symbols.transparentNextArrow(),
            infinite: true,
            speed: 300,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: false,
            autoplaySpeed: $(elem) && $(elem).data("autoplay-time") ? $(elem).data("autoplay-time") : 6000,
            pauseOnHover: false,
            customPaging: function (i) {
                return "<a class='pager__item'><span class='progressBar' data-slick-index='" + i + "'></span></a>";
            }
        });
        progressBar();
    });

    var slides = $(".homepage-slider").find(".slide");
    var video = $(".homepage-slider").find(".video-content-slider");

    if (video.length != 0) {
        video.height(slides.height());

        $(window).on("resize", _.debounce(function () {
            video.height(slides.height());
        }, 100));
    }

    $("#vertical-carousel")
        .jcarousel({
            vertical: true
        })
        .jcarouselAutoscroll({
            interval: 5000
        });

    $("#vertical-carousel .jcarousel-prev")
        .on("jcarouselcontrol:active", function () {
            $(this).removeClass("inactive");
        })
        .on("jcarouselcontrol:inactive", function () {
            $(this).addClass("inactive");
        })
        .jcarouselControl({
            target: "-=1"
        });

    $("#vertical-carousel .jcarousel-next")
        .on("jcarouselcontrol:active", function () {
            $(this).removeClass("inactive");
        })
        .on("jcarouselcontrol:inactive", function () {
            $(this).addClass("inactive");
        })
        .jcarouselControl({
            target: "+=1"
        });

    initVIdeoDialog();

    $ (".pushes").find(".caption").syncHeight();

    if ($(".slider-step-list").length) {
        var $sliderSteps = $(".slider-step-list li");

        $sliderSteps.each(function () {
            var $target = $(this),
                targetText = $target.text();

            if (targetText.length > 25) {
                $target.text(targetText.substring(0, 24) + "...");
            }
        });

        $sliderSteps.on("click", function () {
            var $that = $(this);

            $that.parent().prev().slick("slickGoTo", $that.index());
        });
    }

    // Initialize progrss bar on slick refresh
    $(".homepage-slider").on("slickRefresh", () => {
        progressBar();
    });

    initializeProductSlider();

    initDoublePushVideo();
}

export { initStorefront };
