/**
 * @function
 * @description Show / Hide Add to Cart / Added to cart buttons that are Wishlist specific
 * @param {Boolean} state Based on this we decide if we hide or show "Added to Cart" button
 * @param {String} pid Product ID to be used on buttons
 */
const toggleWishlistAddedToCart = (state, pid) => {
    if (typeof state === "boolean" && pid !== null) {
        const $wishlistAddToCart = $(".wishlist-add-to-cart[data-pid=" + pid + "]").parents(".button-wrapper");
        const $wishlistAddedToCart = $(".wishlist-added-to-cart[data-pid=" + pid + "]");

        if ($wishlistAddToCart.length) {
            $wishlistAddToCart.toggleClass("hide").attr("disabled", state);
        }

        if ($wishlistAddedToCart.length) {
            $wishlistAddedToCart.toggleClass("hide");
        }
    }
};

export { toggleWishlistAddedToCart };
