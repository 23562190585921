import { initShared } from "./shared.js";
import { initJqueryExt } from "./jquery-ext";
import LazyLoad from "vanilla-lazyload/dist/lazyload";
import { initStorefront } from "./pages/storefront";
import { initSearchPlaceholder } from "./searchplaceholder";
import { initAlgolia } from "./initAlgolia";
import { initFranchiseSlider } from "./franchiseSlider";
import { initVideoSliders } from "./pages/product/videoSlider";
import { initRating } from "./rating";
import { updateHomepageTrackingParams, initDoubleBannerAdobeTracking, initTripleBannerAdobeTracking } from "./adobetracking";
import { calculateDataOrderAttr } from "./tracking";
import { initPushTiles } from "./initPushTiles";

initJqueryExt();

const initHomepage = () => {
    initShared();
    initSearchPlaceholder();
    initAlgolia();
    initStorefront();
    initFranchiseSlider();
    initVideoSliders();
    initRating();

    if ($("select").length > 0) {
        $("select").selectBoxIt();
    }

    $(document).on("ajaxComplete", function () {
        // Init of lazyload plugin
        var lazyLoadInstance = new LazyLoad({
            elements_selector: ".lazy"
        });

        if (lazyLoadInstance) {
            lazyLoadInstance.update();
        }

        setTimeout(function () {
            if (lazyLoadInstance) {
                lazyLoadInstance.update();
            }
        }, 150);
    });

    const hasNativeSupport = ((window || {}).CSS || {}).supports && window.CSS.supports("(--a: 0)");

    if (!hasNativeSupport) {
        import("css-vars-ponyfill").then(module => {
            const cssVars = module.default;

            cssVars();
        });
    }

    updateHomepageTrackingParams();
    calculateDataOrderAttr();
    initDoubleBannerAdobeTracking();
    initTripleBannerAdobeTracking();
    initPushTiles();
};

export { initHomepage };
