if (!window.modalInstance) {
    window.modalInstance = require("sweetalert2");
}

const Swal = window.modalInstance;

import initButtons from "./button";
import accordion from "./accordion";

// modal component
const modal = {
    open: (content, params, modalName) => {
        let safeParams;
        const defaultParams = {
            customClass: {
                container: "l-modal keyboard-focus keyboard-focus--yellow",
                popup: "c-modal",
                htmlContainer: "c-modal__content"
            },
            showCloseButton: true,
            showConfirmButton: false,
            allowOutsideClick: false
        };

        // Merge parameters if necessary
        if (params !== undefined && params !== null) {
            safeParams = {
                ...defaultParams,
                ...params,
                customClass: {
                    ...defaultParams.customClass,
                    ...params?.customClass
                }
            };
        } else {
            console.warn("missing modal specific parameters");
            safeParams = defaultParams;
        }

        // Attach content - template
        safeParams.html = content;

        // Attach open callback
        // Expose instance on window, for manual change if necessary
        safeParams.didOpen = () => {
            const eventName = "Modal:Open" + modalName;
            const event = new Event(eventName);

            if (params.closeButtonAttributes) {
                // Get the close button element and add the necessary attributes on it
                const closeButton = document.querySelector(".swal2-close");

                if (closeButton) {
                    Object.keys(params.closeButtonAttributes).forEach(attribute => {
                        closeButton.setAttribute(attribute, params.closeButtonAttributes[attribute]);
                    });
                }
            }

            initButtons();
            accordion.init();
            document.dispatchEvent(event);
        };

        // Attach close callback
        // Dispatch Event to remove & resfresh Cart page
        safeParams.didClose = () => {
            const eventName = "Modal:Close" + modalName;
            const event = new Event(eventName);

            document.dispatchEvent(event);
        };

        // Init Instance with merged parameters
        Swal.fire(safeParams);
    },

    close: () => {
        if (window.modalInstance !== undefined) {
            window.modalInstance.close();
        } else {
            console.warn("modal instance is not available");
        }
    }
};

export default modal;
