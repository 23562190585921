import { Splide } from "@splidejs/splide";
import { initResponsiveImage } from "./../helpers/responsive-image";

const data = {
    root: "[data-component='slider']",
    einstein: "[id^='cq_recomm_slot-']"
};

/**
 * @function
 * @description initialize Sliders and observer
 */
const sliderInit = () => {
    initSlider();
    EinsteinObserver();
};

/**
 * @function
 * @description Will run at pageInit, and can be call for specific slider
 * @param {HTMLElement} [slider] - Slider element - Optional
 */
const initSlider = slider => {
    if (slider !== undefined && slider !== null) {
        const params = getSliderParams(slider);

        mountSlider(slider, params);
    } else {
        const sliders = document.querySelectorAll(data.root);

        Array.prototype.forEach.call(sliders, currentSlider => {
            const params = getSliderParams(currentSlider);

            mountSlider(currentSlider, params);
        });
    }
};

/**
 * @function
 * @description Initializes event listeners for a slider to handle custom events when the slider moves.
 * @param {Object} sliderInstance - The instance of the Splide slider component.
 * @fires CustomEvent#componentName:ArrowClicked - Dispatched when the slider moves, indicating the direction of the move.
 * @fires CustomEvent#componentName:MoreProducts - Dispatched when the slider has finished moving.
 */
const initSliderEvents = sliderInstance => {
    sliderInstance.on("move", (newIndex, prevIndex) => {
        const component = sliderInstance?.root;
        const componentName = component?.dataset?.name;

        if (!componentName) {
            return;
        }

        document.dispatchEvent(new CustomEvent(`${componentName}:ArrowClicked`, {
            detail: {
                component,
                isNextArrow: newIndex > prevIndex
            }
        }));
    });

    sliderInstance.on("moved", () => {
        const component = sliderInstance?.root;
        const componentName = component?.dataset?.name;

        if (!componentName) {
            return;
        }

        document.dispatchEvent(new CustomEvent(`${componentName}:MoreProducts`, {
            detail: component
        }));
    });
};

/**
 * @function
 * @description Will start an observer on Einstein container
 * @param {HTMLElement} [slider] - Slider element - Optional
 */
const EinsteinObserver = () => {
    let observer;
    const einteinSlider = document.querySelectorAll(data.einstein);

    // Run throught mutationList
    const callback = mutationsList => {
        mutationsList.forEach(mutation => {
            if (mutation.type === "childList") {
                // Get Splide container
                const slider = mutation.target.querySelector(data.root);

                // Init slider and force lazyload
                if (slider !== undefined && slider !== null) {
                    initSlider(slider);
                    initResponsiveImage();
                    window.lazyLoadInstance.update();
                }

                const recommendationSlider = mutation.target.querySelector("[data-name='RecommendationSlider']");

                // to trigger IntersectionObserver in the adobe_analytics_events
                document.dispatchEvent(new CustomEvent("Einstein:Mutated", {
                    detail: recommendationSlider
                }));
            }
        });
    };

    // Loop through every Einstein container, and start Observer
    einteinSlider.forEach(slider => {
        observer = new MutationObserver(callback);

        observer.observe(slider, {
            childList: true
        });
    });
};

/**
 * @function
 * @description Will start an observer on Einstein container
 * @param {HTMLElement} slider - Slider element
 * @param {JSON} params - JSON options for Splide
 */
const mountSlider = (slider, params) => {
    const isSliderInitialized = slider.classList.contains("is-initialized");

    if (slider.querySelector(".splide__track") && slider.querySelector(".splide__list") && !isSliderInitialized) {
        // initialize Splide Instance
        const sliderInstance = new Splide(slider, params);

        sliderInstance.mount();
        initSliderEvents(sliderInstance);
    }
};

/**
 * @function
 * @description Will create options for Splide Instance
 * @param {HTMLElement} slider - Slider element
 * @return {JSON} - JSON options for Splide
 */
const getSliderParams = slider => {
    const defaultParams = {
        type: "slide",
        drag: false,
        pagination: false,
        perPage: 5,
        perMove: 5,
        gap: "4px",
        rewind: false,
        updateOnMove: true,
        arrowPath: "M39.8631 20C39.8631 8.97329 30.9618 0.0195312 19.9999 0.0195312C9.038 0.0195312 0.136719 8.97329 0.136719 20C0.136719 31.0267 9.03792 39.9805 19.9999 39.9805C30.9619 39.9805 39.8631 31.0267 39.8631 20ZM38.4815 20C38.4815 30.2756 30.2153 38.5907 19.9999 38.5907C9.78472 38.5907 1.51832 30.2757 1.51832 20C1.51832 9.72433 9.78472 1.40921 20.0001 1.40921C30.2153 1.40921 38.4817 9.72441 38.4817 20H38.4815ZM13.655 29.167C16.3161 26.9411 18.9427 24.7426 21.5676 22.5475C22.4134 21.8406 23.248 21.1247 24.0972 20.4248C24.2664 20.2875 24.245 20.2138 24.0972 20.0844C23.1284 19.2188 22.1653 18.3465 21.1999 17.4752C20.2202 16.5938 19.2399 15.7127 18.2591 14.832C16.8524 13.5659 15.446 12.2991 14.0401 11.0315C13.9707 10.9691 13.8958 10.9141 13.8111 10.8449C14.1649 10.6217 14.5297 10.4165 14.9039 10.23C15.0568 10.15 15.1561 10.1733 15.2745 10.2817C15.8874 10.8393 16.5043 11.3923 17.1201 11.9459C18.4919 13.1798 19.864 14.4137 21.2366 15.6476C22.5587 16.8384 23.8803 18.0296 25.2014 19.2212C25.551 19.5365 25.8971 19.8553 26.2535 20.1628C26.3832 20.2762 26.3663 20.3386 26.2439 20.4401C24.4146 21.9637 22.5872 23.4893 20.7618 25.0171C19.0366 26.4597 17.312 27.9031 15.588 29.3472C15.5158 29.4074 15.4414 29.4647 15.3686 29.5248C14.9266 29.8872 14.9254 29.8896 14.437 29.619C14.1856 29.4817 13.9369 29.3314 13.655 29.167H13.655Z",
        breakpoints: {
            1024: {
                destroy: true
            }
        }
    };

    let params;
    const customParam = slider.getAttribute("data-params");

    // Merge parameters if necessary
    if (customParam !== undefined && customParam !== null) {
        params = Object.assign(defaultParams, JSON.parse(customParam));
    } else {
        params = defaultParams;
    }

    return params;
};

export default sliderInit;
