/**
 * Expand component
 *
 * Toggle expand system
 *
 * @module components/expand
 * @see components/expand
 */

/**
 * Component DOM selectors an CSS modifiers
 * @const {object}
 * @default
 */
const data = {
    root: '[data-component="expand"]',
    trigger: '[data-action="expandToggle"]',
    cssModifier: "c-expand--open"
};

const expand = {
    /**
     * @function
     * @description get all component instances and bind events
     */
    init: () => {
        expand.bindComponentEvents();
    },

    /**
     * @function
     * @description bind event on component
     */
    bindComponentEvents: () => {
        $(document).on("click", data.trigger, e => {
            e.preventDefault();

            const self = $(e.currentTarget);
            const component = self.closest(data.root);

            expand.toggleExpand(component);
            expand.toggleText(self);
        });
    },

    /**
     * @function
     * @description toggle the Expand
     * @param {jQuery} component DOM node
     */
    toggleExpand: component => {
        component.toggleClass(data.cssModifier);
    },

    /**
     * @function
     * @description toggle the text button with data attribute eg: (view more / view less)
     * @param {jQuery} button DOM node
     */
    toggleText: button => {
        const currentText = button.find(".c-expand__button--inner").text();
        const switchText = button.data("toggle-text");
        const switchTitle = switchText;

        if (switchText.length) {
            button.find(".c-expand__button--inner").text(switchText);
            button.data("toggle-text", currentText);
            button.attr("title", switchTitle);
        }
    }
};

export { expand };
