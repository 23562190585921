import { cookie } from "./cookie";
import { focusTarget } from "./keyboardFocus";

var $cache = {};

// initialize cache object
function initializeCache() {
    $cache = {
        body: $("body"),
        wishlistNotification: $(".wishlist-notification-wrapper"),
        closeWishlistNotification: $(".close-wishlist-notification"),
        wishlistNotificationBtn: $(".wishlist-notification-btn")
    };
}

/**
 * @function
 * @description Sets focus on Wishlist trigger
 */
const setFocusOnWishlistTrigger = () => {
    if ($cache.wishlistNotificationBtn.length) {
        const nextFocus = $cache.wishlistNotificationBtn.attr("data-next-focus");

        if (nextFocus) {
            focusTarget(nextFocus);
        }
    }
};

// initialize customer notifications events
function initializeEvents() {
    // close event for Wishlist notification
    $cache.closeWishlistNotification.on("click", () => {
        if ($cache.wishlistNotification.length) {
            $cache.wishlistNotification
                .removeClass("product-already-added-notification product-already-bought-notification product-restricted-notification")
                .addClass("hide");
        }

        setFocusOnWishlistTrigger();
    });

    $cache.closeWishlistNotification.on("keydown", e => {
        if (e.keyCode === 9 && e.shiftKey) {
            e.preventDefault();

            setFocusOnWishlistTrigger();
        }
    });
}

/**
 * @function
 * @description Toggle the wishlist restriction class based on account type. The class is added for minor accounts
 */
const toggleAgeRestrictionClass = () => {
    if ($cache.wishlistNotification.length) {
        // check for age restricted account
        const ageGateCookie = Resources.ENABLE_AGE_RESTRICTION ? cookie.get("age_classification") : null;

        // add the wishlist restricted class for minor accounts, remove it for major accounts
        $cache.wishlistNotification.toggleClass("wishlist-notification-restricted", ageGateCookie === "0");
    }
};

function initNotification() {
    initializeCache();
    toggleAgeRestrictionClass();
    initializeEvents();
}

export { initNotification };
