import { initPrivacyPopin } from "./initPrivacyPopin";
import { uskVideo } from "./usk18";

const initVIdeoDialog = () => {
    $(".youtube-content, .twitch-content").on("click", function () {
        let ytVideo = null,
            twVideo = null,
            ytVideoSrc = null,
            twVideoSrc = null;

        const ConsentResources = window.ConsentResources || {};

        if (ConsentResources.videoConsent || !SitePreferences.ENABLE_COMPLIANCE_V2) {
            // init YT video in case consent was accepted
            const $videoSection = $(this).find(".section-video");

            if ($videoSection.length) {
                $videoSection.dialog({
                    width: 640,
                    height: 480,
                    dialogClass: "dialog-video",
                    modal: true,
                    open: function () {
                        var $that = $(this),
                            ageQuestion = $that.find(".age-question"),
                            ageAnswer = $that.find(".age-answer"),
                            twVideoDialog = $(".dialog-video .twVideo");

                        ytVideo = $(".dialog-video").find("#ytplayer");
                        twVideo = $(".dialog-video .twVideo").find("iframe");

                        if (ytVideo.length) {
                            ytVideoSrc = ytVideo.data("url");

                            if (SitePreferences.ENABLE_USK) {
                                uskVideo(ageQuestion, ageAnswer, ytVideo);
                            } else {
                                ytVideo.attr("src", ytVideoSrc + "&autoplay=1");
                            }
                        }

                        if (twVideo.length) {
                            twVideoSrc = twVideo.attr("src");

                            if (SitePreferences.ENABLE_USK) {
                                uskVideo(ageQuestion, ageAnswer, twVideoDialog);
                            } else {
                                twVideo.attr("src", twVideoSrc.replace("!autoplay", "autoplay"));
                            }
                        }

                        $(".ui-widget-overlay").on("click", function () {
                            $that.dialog("close");
                        });
                    },
                    close: function () {
                        if (ytVideo.length) {
                            ytVideo.attr("src", ytVideoSrc);
                        }

                        if (twVideo.length) {
                            twVideo.attr("src", twVideoSrc);
                        }

                        $(this).dialog("destroy");
                    }
                });
            }
        } else {
            // display consent popin in case video was not accepted
            initPrivacyPopin();
        }
    });
};

export { initVIdeoDialog };
