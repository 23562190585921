const $document = $(document);

/**
 * @function
 * @description Initialize ibex click events
 */
const initializeIbexClickEvents = () => {
    ibexPdpEvents();
    ibexConfirmationEvents();
    ibexCancellationEvents();
    ibexSwitchEvents();
    ibexCancelSwitchEvents();
    ibexReactivationEvents();
    ibexReactivationPaymentEvents();
    ibexMyAccountEvents();
    ibexMyAccountPaymentEvents();
};

/**
 * @function
 * @description Initialize ibex PDP click events
 */
const ibexPdpEvents = () => {
    $document.on("click", ".ibex-subscribe", (e, wasTriggered) => {
        let actionValue = "";
        let productNameValue = "";
        let origin = "";
        const $this = $(e.currentTarget);
        const selectedSubProductID = $this.data("subscriptionid");
        const selectedSubPlatform = $this.data("subscription-platform");
        const selectedSubType = $this.data("subscription-type");

        if (wasTriggered) {
            origin = "product tabs";
        } else {
            origin = "product info";
        }

        actionValue = origin + " - subscribe now - " + selectedSubPlatform + " - rocksmith plus subscription - " + selectedSubType;
        productNameValue = selectedSubPlatform + " - rocksmith plus subscription - " + selectedSubType;

        tc_events_100($this, "clickevent", {
            ACTION: actionValue,
            PRODUCTID: selectedSubProductID,
            PRODUCTNAME: productNameValue
        });
    });
};

/**
 * @function
 * @description Initialize ibex confirmation click events
 */
const ibexConfirmationEvents = () => {
    $document.on("click", ".ibex-confirmation-manage-account", e => {
        tc_events_100(e.currentTarget, "clickevent", {
            ACTION: "thank you page - navigation - my account"
        });
    });
};

/**
 * @function
 * @description Initialize ibex cancellation click events
 */
const ibexCancellationEvents = () => {
    $document.on("click", ".ibex-cancellation-back-button", e => {
        tc_events_100(e.currentTarget, "clickevent", {
            ACTION: "rocksmith plus : cancel subscription - navigation - go back"
        });
    });

    if (pageContext && pageContext.ns === "ibexcancellation") {
        $(document).on("cancelSubscriptionCTATracking", e => {
            tc_events_100(e.currentTarget, "clickevent", {
                ACTION: "rocksmith plus : cancel subscription - navigation - cancel"
            });
        });
    }

    $document.on("click", ".ibex-cancellation-plan-button", e => {
        tc_events_100(e.currentTarget, "clickevent", {
            ACTION: "rocksmith plus : cancel subscription - navigation - choose my new plan"
        });
    });

    $document.on("click", ".ibex-cancellation-confirmation-cta", e => {
        tc_events_100(e.currentTarget, "clickevent", {
            ACTION: "rocksmith plus : cancel subscription - navigation - go to my account"
        });
    });
};

/**
 * @function
 * @description Initialize ibex switch click events
 */
const ibexSwitchEvents = () => {
    $document.on("click", ".ibex-switch-play-cta", e => {
        tc_events_100(e.currentTarget, "clickevent", {
            ACTION: "rocksmith plus : switch plan - navigation - ubisoft connect : play on"
        });
    });
};

/**
 * @function
 * @description Initialize ibex cancel switch click events
 */
const ibexCancelSwitchEvents = () => {
    $document.on("click", ".ibex-cancel-switch-account", e => {
        tc_events_100(e.currentTarget, "clickevent", {
            ACTION: "rocksmith plus : cancel switch - navigation - go back"
        });
    });

    $document.on("click", ".cancel-subscription-action", e => {
        tc_events_100(e.currentTarget, "clickevent", {
            ACTION: "rocksmith plus : cancel switch - navigation - yes cancel"
        });
    });

    $document.on("click", ".ibex-cancel-switch-confirmation-cta", e => {
        tc_events_100(e.currentTarget, "clickevent", {
            ACTION: "rocksmith plus : cancel switch - navigation - back to my account"
        });
    });
};

/**
 * @function
 * @description Initialize ibex reactivation click events
 */
const ibexReactivationEvents = () => {
    $document.on("click", ".ibex-reactivation-cancel", e => {
        tc_events_100(e.currentTarget, "clickevent", {
            ACTION: "rocksmith plus : reactivation - navigation - cancel"
        });
    });

    $document.on("click", ".ibex-reactivation-confirm", e => {
        tc_events_100(e.currentTarget, "clickevent", {
            ACTION: "rocksmith plus : reactivation - navigation - validate new plan"
        });
    });

    $document.on("click", ".ibex-reactivation-manage-account", e => {
        tc_events_100(e.currentTarget, "clickevent", {
            ACTION: "rocksmith plus : reactivation - navigation - manage my account"
        });
    });

    $document.on("click", ".ibex-reactivation-start-playing", e => {
        tc_events_100(e.currentTarget, "clickevent", {
            ACTION: "rocksmith plus : reactivation - navigation - ubisoft connect : play on"
        });
    });
};

/**
 * @function
 * @description Initialize ibex reactivation - payment click events
 */
const ibexReactivationPaymentEvents = () => {
    $document.on("click", ".add-new-credit-card", e => {
        if (pageContext && pageContext.ns === "ibexreview") {
            if ($(e.currentTarget).hasClass("ibex-reactivation-new-payment")) {
                tc_events_100(e.currentTarget, "clickevent", {
                    ACTION: "rocksmith plus : reactivation – select payment method - add new payment method"
                });
            } else {
                tc_events_100(e.currentTarget, "clickevent", {
                    ACTION: "rocksmith plus : reactivation - select payment method - change payment method : add payment method"
                });
            }
        }
    });

    $document.on("click", ".edit-subscription-information", e => {
        if (pageContext && pageContext.ns === "ibexreview") {
            if ($(e.currentTarget).hasClass("ibex-reactivation-new-payment")) {
                tc_events_100(e.currentTarget, "clickevent", {
                    ACTION: "rocksmith plus : reactivation – select payment method - add new payment method"
                });
            }
        }
    });

    $document.on("click", ".js-change-payment-method", e => {
        if (pageContext && pageContext.ns === "ibexreview") {
            if ($(e.currentTarget).hasClass("ibex-reactivation-change-payment")) {
                tc_events_100(e.currentTarget, "clickevent", {
                    ACTION: "rocksmith plus : reactivation - select payment method - change payment method"
                });
            }
        }
    });

    $document.on("click", ".js-subscription-payment-method, .reactivation-default-payment-update", e => {
        if (pageContext && pageContext.ns === "ibexreview") {
            const $CCRadioBtn = $("#is-CREDIT_CARD");
            const $PayPalRadioBtn = $("#is-PayPal");
            const $newPaymentCTA = $(".ibex-reactivation-new-payment");

            if ($CCRadioBtn.length && $CCRadioBtn.is(":checked")) {
                if ($newPaymentCTA.length) {
                    tc_events_100(e.currentTarget, "clickevent", {
                        ACTION: "rocksmith plus : reactivation - add payment method - credit card : validate"
                    });
                } else {
                    tc_events_100(e.currentTarget, "clickevent", {
                        ACTION: "rocksmith plus : reactivation - select payment method - change payment method : credit card : save"
                    });
                }
            }

            if ($PayPalRadioBtn.length && $PayPalRadioBtn.is(":checked")) {
                if ($newPaymentCTA.length) {
                    tc_events_100(e.currentTarget, "clickevent", {
                        ACTION: "rocksmith plus : reactivation - add payment method - paypal : validate"
                    });
                } else {
                    tc_events_100(e.currentTarget, "clickevent", {
                        ACTION: "rocksmith plus : reactivation - select payment method - change payment method : paypal : save"
                    });
                }
            }
        }
    });
};

/**
 * @function
 * @description Initialize ibex - My account - payment click events
 */
const ibexMyAccountPaymentEvents = () => {
    $document.on("click", ".ibex-update-payment", e => {
        const $CCRadioBtn = $(".creditcard-payment");
        const $PayPalRadioBtn = $(".paypal-payment");
        const $this = $(e.currentTarget);

        if (pageContext && pageContext.ns == "account") {
            if ($CCRadioBtn.length && $CCRadioBtn.is(":checked")) {
                tc_events_100($this, "clickevent", {
                    ACTION: "my subscription : rocksmith plus - payment method - change payment method : credit card : save"
                });
            }

            if ($PayPalRadioBtn.length && $PayPalRadioBtn.is(":checked")) {
                tc_events_100($this, "clickevent", {
                    ACTION: "my subscription : rocksmith plus - payment method - change payment method : paypal : save"
                });
            }
        }
    });
};

/**
 * @function
 * @description Initialize ibex my account click events
 */
const ibexMyAccountEvents = () => {
    $document.on("click", "#account-ibex #tag-view-billing .view-details, .ibex-manage-subscription", e => {
        tc_events_100(e.currentTarget, "clickevent", {
            ACTION: "my subscription : rocksmith plus - interaction - manage my subscription"
        });
    });

    $document.on("click", ".ibex-account-save-billing", e => {
        tc_events_100(e.currentTarget, "clickevent", {
            ACTION: "my subscription : rocksmith plus - billing adress - change billing address : save"
        });
    });

    $document.on("click", "#change-billing-address-ibex .close-button, .change-subscription-information-ibex .ui-dialog-titlebar ", e => {
        tc_events_100(e.currentTarget, "clickevent", {
            ACTION: "my subscription : rocksmith plus - billing adress - change billing address : close"
        });
    });
};

export { initializeIbexClickEvents };
