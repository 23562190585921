import ThenPromise from "promise";
import algoliasearch from "algoliasearch";
import * as algoliaTemplateHelper from "./renderingtemplates/algoliatemplatehelper";
import { initProductTooltip } from "./tooltip";
import { util } from "./util";
import { initializeWishlistEvents } from "./pages/wishlist";
import { getCookie, setCookie } from "./tls";

var storefrontLocale = GeoRestrictedResources.STOREFRONT_CURRENT_LOCALE,
    indexes = getLocaleIndexes(),
    algoliaCurrencySymbol = GeoRestrictedResources.ALGOLIA_CURRENCY_SYMBOL,
    currencySymbol = algoliaCurrencySymbol ? algoliaCurrencySymbol : GeoRestrictedResources.STOREFRONT_CURRENCY_SYMBOL,
    storefrontCurrencyCode = GeoRestrictedResources.STOREFRONT_CURRENCY_CODE,
    refinements = SitePreferences.ALGOLIA_REFINEMENTS,
    search = {},
    site = Constants.SITE_ID.split("_")[1],
    isWeb = site === "ubisoft",
    algoliaClient = {},
    searchSuggest = {},
    algoliaUserTokenCookieName = "algoliaUserToken",
    tokenLength = Math.round(Math.random() * (64 - 16) + 16), // generate token length for algoliaUserToken cookie (between 16 to 64 characters)
    skipRefinementIndexes = []; // list of indexes where the filters will not be applied;

let $cache = {};

/**
 * @function
 * @description Initializes the cache object
 */
const initializeCache = () => {
    $cache = {
        document: $(document),
        body: $("body"),
        mainContainer: $("#main"),
        algoliaContainer: $("[data-algolia-container]"),
        algoliaSearchContainer: $("[data-algolia-search]"),
        homepageSlider: $(".homepage-slider-hero"),
        searchHints: $("#algolia-hints"),
        searchHintsBtn: $("[aria-controls=algolia-hints]"),
        searchHintsTag: $("#algolia-search-hints-tag"),
        searchResults: $(".l-algolia__results"),
        timeout: null
    };

    getElementsToObscure();
};

/**
 * @function
 * @description Get the elements which will be obscured when algolia container is opened
 */
const getElementsToObscure = () => {
    // accessible siblings of the search container
    $cache.obscuredElements = $cache.algoliaSearchContainer.siblings(":not([aria-hidden=true])");

    // accessible siblings of the search parents
    $cache.obscuredElements = $cache.obscuredElements.add($cache.algoliaSearchContainer.parentsUntil(".top-banner").siblings(":not([aria-hidden=true])"));

    // accessible siblings of the algolia container
    $cache.obscuredElements = $cache.obscuredElements.add($cache.algoliaContainer.siblings(":not([aria-hidden=true]):not(.top-banner)"));
};

/**
 * @function
 * @description Function to close and open the algolia container
 * @param {Boolean} open - if true the algolia container will be visible, if false, hidden
 */
const toggleAlgoliaContainer = (open = false) => {
    const $algoliaInput = $cache.algoliaSearchContainer.find(".ais-SearchBox-input");
    const $algoliaReset = $cache.algoliaSearchContainer.find(".ais-SearchBox-reset");
    const inputHasValue = $algoliaInput?.val()?.length > 0 && $algoliaReset.attr("data-active") !== "true";

    $cache.mainContainer.toggleClass("visually-hidden", open);
    $cache.body.toggleClass("algolia-wrapper", open);

    // update algolia elements
    $cache.algoliaContainer.toggleClass("visually-hidden", !open)
        .attr("aria-hidden", !open);

    if (open) {
        // trap focus inside algolia sections - search and results
        trapFocus();
    } else {
        if ($algoliaInput.length && $algoliaInput.not(":focus")) {
            $algoliaInput.focus();
        }

        // release the focus trapped for algolia sections in trapFocus function
        releaseFocus();
    }

    if (SitePreferences.ALGOLIA_QUERY_SUGGEST_ENABLE) {
        // hide the hints button if there are search results. Suggest container is not reachable in this case
        $cache.searchHintsBtn.toggleClass("hide", inputHasValue);

        if (!open) {
            // update the expanded attribute when the suggest is closed without clicking the search hints button
            $cache.searchHintsBtn.attr("aria-expanded", false);
        }
    }
};

/**
 * @function
 * @description Function to update the focusable elements from the algolia containers
 */
const updateFocusableElements = () => {
    $cache.focusableResults = util.findFocusable($cache.algoliaContainer);
    $cache.focusableSearch = util.findFocusable($cache.algoliaSearchContainer);
};

/**
 * @function
 * @description Function to release the trapped focus inside algolia containers
 */
const releaseFocus = () => {
    $cache.algoliaContainer.off("keydown.trapFocus");
    $cache.algoliaSearchContainer.off("keydown.trapFocus");
    $cache.obscuredElements.removeAttr("aria-hidden");
};

/**
 * @function
 * @description Function to trap focus inside algolia containers
 */
const trapFocus = () => {
    updateFocusableElements();

    $cache.algoliaContainer.off("keydown.trapFocus")
        .on("keydown.trapFocus", event => {
            setFocus(event, $cache.focusableResults, $cache.focusableSearch);
        });

    $cache.algoliaSearchContainer.off("keydown.trapFocus")
        .on("keydown.trapFocus", event => {
            setFocus(event, $cache.focusableSearch, $cache.focusableResults);
        });

    // only the search and the algolia results containers should be accessible
    $cache.obscuredElements.attr("aria-hidden", true);
};

/**
 * @function
 * @description Function to change the focus from one algolia container to another
 */
const setFocus = (event, $sourceFocusableResults, $targetFocusableResults) => {
    if (event.target === $sourceFocusableResults.eq(-1)[0] && event.keyCode === $.ui.keyCode.TAB && !event.shiftKey) {
        // when last focusable element is reached, jump to the first focusable item from the other algolia container
        event.preventDefault();
        $targetFocusableResults.eq(0).focus();
    } else if (event.target === $sourceFocusableResults.eq(0)[0] && event.shiftKey && event.keyCode == 9) {
        // when first focusable element is reached, jump to the last focusable item from the other algolia container
        event.preventDefault();

        $targetFocusableResults.eq(-1).focus();
    }
};

/**
 * @function
 * @description Function to display the search suggest section
 */
const showSearchSuggest = () => {
    const $algoliaInput = $cache.algoliaSearchContainer.find(".ais-SearchBox-input");

    if (!$algoliaInput.length || $algoliaInput.val()) {
        return;
    }

    $cache.searchHintsTag.trigger("click");
    $cache.searchHints.removeClass("hide");
    $cache.searchResults.addClass("hide");

    toggleAlgoliaContainer(true);

    if ($cache.searchHintsBtn.attr("aria-expanded") === "false") {
        document.dispatchEvent(new CustomEvent("Algolia:ShowSuggestions"));
    }

    $cache.searchHintsBtn.attr("aria-expanded", true);
};

/**
 * @function
 * @description Function to close the algolia search
 */
const closeAlgoliaSearch = () => {
    // Trigger clear input and filters
    $(".algolia-clear-all-filter").trigger("click", ["from-close-search-box, from-clear-all-filter"]);
    $(".ais-SearchBox-reset").trigger("click", [{
        isSearchLayerClosed: true
    }]);

    $cache.searchHints.removeClass("hide");
    $cache.searchResults.addClass("hide");

    // Update items visibility
    toggleAlgoliaContainer();
};

function initInstantSearchObject() {
    algoliaClient = algoliasearch(SitePreferences.ALGOLIA_API_ID, SitePreferences.ALGOLIA_API_KEY);

    const searchClient = {
        search(requests) {
            var newRequests = requests.map(request => {
                if (!request.params.query || request.params.query.length === 0 || request.params.query.trim() == "") {
                    request.params.analytics = false;
                }

                // clear filters for indexes added to skipRefinementIndexes
                if (skipRefinementIndexes.length && skipRefinementIndexes.indexOf(request.indexName) !== -1) {
                    request.params.facetFilters = [];
                    request.params.numericFilters = [];
                }

                return request;
            });

            return algoliaClient.search(newRequests);
        }
    };

    search = instantsearch({
        appId: SitePreferences.ALGOLIA_API_ID,
        apiKey: SitePreferences.ALGOLIA_API_KEY,
        indexName: indexes.defaultIndex,
        searchClient,
        insightsClient: window.aa,

        /**
         * A hook that is called each time a search needs to be done
         * @input {Object} helper - Object, which allows to make a search and access query string
         */
        searchFunction: function (helper) {
            const lastSearch = sessionStorage.getItem("algoliaSearchValue");

            if (lastSearch) {
                helper.state.query = lastSearch;
            }

            if (helper.state.query === "" || helper.state.query === "undefined") {
                return;
            }

            if (!checkAndReturnPrivacyToken()) {
                delete helper.state?.userToken;
            }

            helper.search();
        }
    });

    search.addWidgets([
        instantsearch.widgets.configure({
            hitsPerPage: SitePreferences.ALGOLIA_HITS_PER_PAGE,
            clickAnalytics: true,
            ruleContexts: ["upc"],
            enableRules: true,
            getRankingInfo: true
        })
    ]).on("error", ({error}) => {
        console.log(`[Algolia Error]: ${error?.name} \n${error?.message}`);
    });

    const algoliaUserToken = checkAndReturnPrivacyToken();

    // add user token to algolia if cookies are accepted & exist an user token
    if (algoliaUserToken) {
        search.addWidgets([
            instantsearch.widgets.configure({
                userToken: algoliaUserToken
            })
        ]);
    }
}

/**
 * @private
 * @function
 * @description Initializes Search Hints client
 */
const initializeSuggestions = () => {
    const suggestSearchClient = {
        search(requests) {
            var newRequests = requests.map(request => {
                if (!request.params.query || request.params.query.length === 0 || request.params.query.trim() == "") {
                    // no analytics requested
                    request.params.analytics = false;
                }

                return request;
            });

            return algoliaClient.search(newRequests);
        }
    };

    searchSuggest = instantsearch({
        appId: SitePreferences.ALGOLIA_API_ID,
        apiKey: SitePreferences.ALGOLIA_API_KEY,
        indexName: SitePreferences.ALGOLIA_PUSH_DESKTOP_INDEX,
        searchClient: suggestSearchClient
    });

    searchSuggest.addWidgets([
        instantsearch.widgets.configure({
            ruleContexts: ["upc"],
            hitsPerPage: 3,
            page: 0
        })
    ]).on("error", ({error}) => {
        $cache.searchHints.addClass("hide");
        console.log(`[Algolia Error - suggestion]: ${error?.name} \n${error?.message}`);
    });
};

/**
 * Initialize search suggestions
 * @param {Object} searchModule - Search module object
 * @return {Object} Search module object
 */
const initializeSuggestIndex = searchModule => {
    // separated hits area
    var customSuggestHits = instantsearch.connectors.connectHits(algoliaTemplateHelper[site + "RenderTextHits"]);
    var $containerNode = $("#suggest-hints");
    var desktopSuggestIndex = getQuerySuggestionLocaleIndexes(SitePreferences.ALGOLIA_SUGGEST_DESKTOP_INDEX);

    if ($containerNode.length) {
        skipRefinementIndexes.push(desktopSuggestIndex);

        searchModule.addWidget(
            instantsearch.widgets.index({
                indexName: desktopSuggestIndex
            }).addWidgets([
                customSuggestHits({
                    containerNode: $containerNode,
                    escapeHits: false
                }),
                instantsearch.widgets.configure({
                    ruleContexts: ["upc"],
                    hitsPerPage: 4,
                    page: 0
                })
            ])
        );
    }

    return searchModule;
};

/**
 * Initialize producttile push suggestion hits area
 * @param {Object} searchHitsModule - Search module object
 * @return {Object} Search module object
 */
const initializePushHit = searchHitsModule => {
    // map `renderProductHits` function to the hits logic
    var customPushHits = instantsearch.connectors.connectHits(algoliaTemplateHelper[site + "RenderProductHits"]);
    var $containerNode = $("#push-hints");
    const $slotProductSuggestions = $(".search-product-suggestions");

    if ($containerNode.length && !$slotProductSuggestions.length) {
        searchHitsModule.addWidget(
            customPushHits({
                containerNode: $containerNode,
                escapeHits: false
            })
        );
    }

    if ($slotProductSuggestions.length) {
        $containerNode.addClass("hide");
    }

    return searchHitsModule;
};

/**
 * Fomate indexes for widget
 * @input {Object} indexes - Object to formate
 * @return {Object} - formatted object
 */
function getIndexes(idxs) {
    var result = [];

    for (var i in idxs) {
        if (i === "defaultIndex") {
            continue;
        }

        result.push({
            value: idxs[i],
            label: Resources["SEARCH_" + i.toUpperCase()]
        });
    }

    return result;
}

/**
 * Initialize search refinements
 * @input {Object} searchModule - Search module object
 * @input {Array} refinements - Refinements array (from site pref)
 * @input {String} type - Refinement type
 * @return {Object} Search module object
 */
function initializeRefinements(searchModule, refinementsArr, type) {
    if (!refinementsArr) {
        return searchModule;
    }

    var customRefinementList = instantsearch.connectors.connectRefinementList(algoliaTemplateHelper[type + "RefinementRender"]);

    for (var i in refinementsArr) {
        if (refinementsArr[i] == null) {
            return;
        }

        var attributeName = setMultiLocaleFields(refinementsArr[i]);

        searchModule.addWidget(
            customRefinementList({
                containerNode: $("#" + refinementsArr[i]),
                attributeName: attributeName,
                attribute: attributeName,
                limit: SitePreferences.REFINEMENT_LIMIT, // @TODO check the limit size
                templates: {
                    header: Resources["SEARCH_REFINEMENT_" + refinementsArr[i].toUpperCase()]
                }
            })
        );
    }

    return searchModule;
}

/**
 * Initialize search sorting selector for ubisoft
 * @input {Object} searchModule - Search module object
 * @input {Array} indexesArray - Indexes array (from site pref)
 * @return {Object} Search module object
 */
function ubisoftSortingSelectorInitialization(searchModule, indexesArray) {
    searchModule.addWidget(
        instantsearch.widgets.sortBySelector({
            container: "#sort-by-container",
            indices: getIndexes(indexesArray)
        })
    );

    return searchModule;
}

/**
 * Initialize search sorting selector for uplay
 * @input {Object} searchModule - Search module object
 * @input {Array} indexesArray - Indexes array (from site pref)
 * @return {Object} Search module object
 */
function uplaypcSortingSelectorInitialization(searchModule, indexesArray) {
    var uplaySortBySelector = instantsearch.connectors.connectSortBy(algoliaTemplateHelper.uplaypcSortingSelectorRender);

    search.addWidget(
        uplaySortBySelector({
            containerNode: $("#sort-by-container"),
            indices: getIndexes(indexesArray),
            items: getIndexes(indexesArray)
        })
    );

    return searchModule;
}

/**
 * Initialize search statistics
 * @input {Object} searchModule - Search module object
 * @return {Object} Search module object
 */
function initializeSearchStats(searchModule) {
    searchModule.addWidget(
        instantsearch.widgets.stats({
            container: ".stats-container",
            templates: {
                text: function body(data) {
                    var count = "";

                    if (data.hasManyResults) {
                        count += "<span>" + data.nbHits + "</span>" + " " + "<span>" + Resources.SEARCH_RESULTS_TEXT_ALGOLIA + "</span>";
                    } else if (data.hasOneResult) {
                        count += "<span>1</span>" + " " + "<span>" + Resources.SEARCH_RESULT_TEXT_ALGOLIA + "</span>";
                    } else {
                        count += Resources.SEARCH_NO_RESULT_TEXT_ALGOLIA;
                    }

                    return count;
                }
            }
        })
    );

    return searchModule;
}

/**
 * Initialize search hits
 * @input {Object} searchModule - Search module object
 * @return {Object} Search module object
 */
function initializeSearchHit(searchModule) {
    // connect `renderInfiniteHits` to InfiniteHits logic
    // TODO: Improve hits reload connected with DIS
    var customInfiniteHits = instantsearch.connectors.connectInfiniteHits(algoliaTemplateHelper[site + "RenderInfiniteHits"]);

    searchModule.addWidget(
        customInfiniteHits({
            containerNode: $("#hits"),
            escapeHits: false
        })
    );

    // Algolia Search layer - Producttiles - analysis for click events from algolia search layer to algolia dashboard.
    $(document).on("click", ".algolia-producttile-card", function () {
        var objectID = $(this).data("object-id") ? $(this).data("object-id") : "";
        var indexID = $(this).data("index-id") ? $(this).data("index-id") : "";
        var queryID = $(this).data("query-id") ? $(this).data("query-id") : "";
        var position = $(this).data("position-number") ? $(this).data("position-number") : "";
        var eventName = "click_hit";
        var sessionObject;

        if (objectID && indexID && queryID && position) {
            sessionObject = {
                objectID: objectID,
                indexID: indexID,
                queryID: queryID,
                position: position
            };

            sessionStorage.setItem("algoliaAnalytics", JSON.stringify(sessionObject));

            initSearchInsights();

            window.aa("clickedObjectIDsAfterSearch", {
                eventName: eventName,
                index: indexID,
                queryID: queryID,
                objectIDs: [objectID],
                positions: [position]
            });
        }
    });

    return searchModule;
}

/**
 * Initialize refinement breadcrumbs
 * @input {Object} searchModule - Search module object
 * @return {Object} Search module object
 */
function initializeRefinementBreadcrumbs(searchModule) {
    var customCurrentRefinedValues = instantsearch.connectors.connectCurrentRefinements(algoliaTemplateHelper.renderRefinementBreadcrumds);

    searchModule.addWidget(
        customCurrentRefinedValues({
            containerNode: $("#custom-crv-container")
        })
    );

    return searchModule;
}

/**
 * Initialize Algolia search input
 * @input {Object} searchRefinementModule - Search module object
 * @return {Object} Search module object
 */
function initializeSearchBox(searchRefinementModule) {
    let $algoliaInputWrapper = $("#searchbox");

    const searchBox = instantsearch.widgets.searchBox({
        container: "#searchbox",
        showLoadingIndicator: false,
        placeholder: $algoliaInputWrapper.length ? $algoliaInputWrapper.data("input-placeholder") : ""
    });

    searchRefinementModule.addWidget(searchBox);

    return searchRefinementModule;
}

/**
 * Initialize clear refinements widget
 * @input {Object} searchModule - Search module object
 * @return {Object} Search module object
 */
function initializeClearRefinements(searchRefinementModule) {
    const clearRefinements = instantsearch.widgets.clearRefinements({
        container: "#upc-clear-refinements",
        templates: {
            resetLabel: $("#upc-clear-refinements").data("title")
        }
    });

    searchRefinementModule.addWidget(clearRefinements);

    return searchRefinementModule;
}

/**
 * Initialize Algolia price range slider widget
 * @input {Object} searchRefinementModule - Search module object
 * @return {Object} Search module object
 */
function initializePriceRangeSlider(searchRefinementModule) {
    var localePrice = "price." + storefrontCurrencyCode;

    var priceRange = instantsearch.widgets.rangeSlider({
        container: "#upc-price-range-slider",
        attribute: localePrice,
        templates: {
            header: "Price"
        },
        tooltips: {
            format: function (rawValue) {
                if (algoliaCurrencySymbol) {
                    return currencySymbol + Math.round(rawValue).toLocaleString();
                } else {
                    return Math.round(rawValue).toLocaleString() + currencySymbol;
                }
            }
        }
    });

    searchRefinementModule.addWidget(priceRange);

    return searchRefinementModule;
}

/**
 * Handle return to search result page
 * @input {HTMLElement} searchInput - Search input dom element
 */
function handleReturnToSearchResults(searchInput) {
    var searchPhrase = searchInput.val();

    if (searchPhrase !== "") {
        $("#algolia-search-phrase").text(searchPhrase);
        $(".search-container").removeClass("visually-hidden");
        $cache.mainContainer.addClass("visually-hidden");
    }
}

/**
 * @private
 * @function
 * @description Initializes algolia dom
 */
function initializeDom() {
    search = initializeSearchHit(search);
    search = initializeRefinements(search, refinements.list, "list");
    // search = initializeRefinements(search, refinements.box, 'box');
    search = initializeSearchStats(search);

    if (isWeb) {
        search = initializeRefinementBreadcrumbs(search);
        search = ubisoftSortingSelectorInitialization(search, indexes);
    } else {
        if (SitePreferences.ENABLE_ALGOLIA_PRICE_RANGE_SLIDER) {
            search = initializePriceRangeSlider(search);
        }

        search = initializeClearRefinements(search);
        search = initializeRefinementBreadcrumbs(search);
        search = uplaypcSortingSelectorInitialization(search, indexes);
    }

    if (SitePreferences.ALGOLIA_AUTOCOMPLETE_ENABLE) {
        search = initializeAutoComplete(search);
    }
}

/**
 * @private
 * @function
 * @description Removes all selected filters after closing the search layer. Closes expanded refinements except first one.
 */
function removeAllSelectedFilters() {
    $("div.listRefinement").find("li[data-refine-value]").each(function () {
        if ($(this).hasClass("selected")) {
            $(this).trigger("click", ["from-clear-all-filter"]);
        }
    });
}

/**
 * @private
 * @function
 * @description Initializes algolia events
 */
function initializeEvents() {
    // ALGOLIA - CLEAR ALL FILTER - Click Event
    $(".algolia-clear-all-filter button").on("click", function (event) {
        event.preventDefault();
        removeAllSelectedFilters();
    });

    $cache.document.on("keyup trigger-algolia-search", "#searchbox input", function () {
        // event.key === "Shift" added because the event is triggered twice for "SHIFT + TAB"
        if (event.keyCode === $.ui.keyCode.TAB || event.key === "Shift" || event.shiftKey) {
            return;
        }

        const inputValue = $(this).val();

        $cache.searchHints.toggleClass("hide", inputValue !== "");
        $cache.searchResults.toggleClass("hide", inputValue === "");

        if (inputValue === "") {
            removeAllSelectedFilters();
            toggleAlgoliaContainer();

            $cache.homepageSlider.slick("refresh").trigger("slickRefresh");
        } else {
            startAlgoliaSearch();
            toggleAlgoliaContainer(true);

            $("#algolia-search-phrase, #no-hits-search-term").text($("#searchbox input").val());
            $(".ais-SearchBox-reset").data("close-state", true);
        }

        refreshSlider();
    });

    $cache.document.on("focusin", "#searchbox input", function () {
        const $searchBoxSubmit = $("#searchbox .ais-SearchBox-submit");

        if ($searchBoxSubmit.length) {
            $searchBoxSubmit.attr("disabled", "true");
        }
    });

    if (SitePreferences.ALGOLIA_QUERY_SUGGEST_ENABLE) {
        $cache.document.on("click", "[aria-controls=algolia-hints]", () => {
            const isExpanded = $cache.searchHintsBtn.attr("aria-expanded") === "true";

            if (isExpanded) {
                // hide search hints
                closeAlgoliaSearch();

                $cache.searchHintsBtn.focus();
            } else {
                // show the search hints section
                showSearchSuggest();
            }
        });

        $cache.document.on("click", "#searchbox input", showSearchSuggest);
    }

    $cache.document.on("keydown", event => {
        // close the expandable section on ESC
        if (event.key === "Escape" && !$cache.algoliaContainer.hasClass("visually-hidden")) {
            closeAlgoliaSearch();
        }
    });

    // Handle click on popular proposal, and query value
    $cache.document.on("click", "[data-action='suggestion-search']", function (e) {
        e.preventDefault();

        var value = $(this).data("query-text");
        var $searchInputBox = $("#searchbox input");

        // Update field
        $searchInputBox.val(value).trigger("trigger-algolia-search");

        if (this.hasAttribute("data-auto-completion")) {
            $searchInputBox.trigger("autoCompletionUsed", [{
                isAutoCompletionUsed: true,
                autoCompletionValue: value
            }]);
        } else if (this.hasAttribute("data-search-suggestion")) {
            $searchInputBox.trigger("searchSuggestionUsed", [{
                isSearchSuggestionUsed: true,
                searchSuggestionValue: value
            }]);
        }

        // Run query
        search.helper.setQuery($searchInputBox.val()).search();
    });

    $(".c-algolia__close").on("click", closeAlgoliaSearch);

    $cache.document.on("click", ".ais-SearchBox-reset", function () {
        removeAllSelectedFilters();

        // attribute added because the input is cleared with a delay, affecting the input value conditions  in toggleAlgoliaContainer
        $(this).attr("data-active", true);
        toggleAlgoliaContainer();

        $("#wrapper").removeClass("pt_product-search-noresult");

        // Refresh sliders
        refreshSlider();

        $(".ais-SearchBox-reset").removeAttr("data-active");
    });

    search.on("render", function () {
        $(".search-results").find(".algolia-image-render").each(function () {
            $(".search-results li .title").syncHeight();
        });

        $(".search-results").find(".render-age-rating").each(function () {
            algoliaTemplateHelper.getAgeGroupContent($(this));
        });

        $("div.rheostat-handle").on("click", function (e) {
            e.preventDefault();

            const selectedPriceValue = $(this).attr("aria-valuenow");

            if (selectedPriceValue) {
                $("#algolia-price-slider-tag").trigger("click", [{
                    selectedPriceValue: selectedPriceValue
                }]);
            }

            tc_events_100(this, "clickevent", {
                ACTION: "listing page - filter applied - gauge price"
            });
        });

        initProductTooltip();

        const lastSearch = sessionStorage.getItem("algoliaSearchValue");
        const $searchInputBox = $("#searchbox input");

        if (lastSearch && $searchInputBox.val() !== "") {
            $searchInputBox.trigger("trigger-algolia-search");
            sessionStorage.setItem("algoliaSearchValue", "");
        }

        updateFocusableElements();

        // trigger events for algolia click events when the elements are re-rendered
        document.dispatchEvent(new CustomEvent("Algolia:SearchPageEvents"));
    });

    if (SitePreferences.ALGOLIA_QUERY_SUGGEST_ENABLE) {
        searchSuggest.on("render", () => {
            initProductTooltip();
        });
    }

    // PDP - Add to cart button - analysis for click events between algolia search layer & PDPs.
    $cache.document.on("click", ".add-to-cart", function () {
        if (pageContext.ns == "product") {
            var algoliaParams = sessionStorage.getItem("algoliaAnalytics");
            var algoliaAnalytics = algoliaParams != "" ? JSON.parse(algoliaParams) : "";
            var eventName = "click_pdp_add_to_cart";

            if (algoliaAnalytics) {
                initSearchInsights();
                sessionStorage.setItem("algoliaAnalytics", "");

                window.aa("convertedObjectIDsAfterSearch", {
                    index: algoliaAnalytics.indexID,
                    eventName: eventName,
                    queryID: algoliaAnalytics.queryID,
                    objectIDs: [algoliaAnalytics.objectID]
                });
            }
        }
    });

    // redirect the user from product tile to PDP
    $cache.document.on("click keyup", ".algolia-producttile-card .product-tile", e => {
        if ((e.type === "click" || e.key === "Enter") && (e.target.tagName !== "svg") && (e.target.tagName !== "path") && (e.target.tagName !== "BUTTON")) {
            const link = $(e.currentTarget).data("link");

            if (link) {
                window.location.href = link;
            }
        }
    });

    // correct the focus on back way - include the button to be focused inside the hover card of previous product tile
    $cache.document.on("keydown", ".algolia-producttile-card .product-tile, .no-results-recommandations .product-tile", e => {
        if (e.key === "Tab" && e.shiftKey) {
            const $this = $(e.target);

            if ($this.hasClass("product-tile")) {
                let $prevTile = $this.parents(".grid-tile").prev();
                const $prevSlide = $this.parents(".slick-slide").prev(".slick-slide");
                const $prevTilebutton = $prevSlide.length ? $prevSlide.find(".hover-card .button") : $prevTile.find(".hover-card button.algolia-wishlist");

                if ($prevTilebutton.length) {
                    // remove yellow focus before focusing the button to avoid flickering of yellow background of tile
                    let $keyBoardFocus = $this.closest(".keyboard-focus.keyboard-focus--yellow");

                    $keyBoardFocus.removeClass("keyboard-focus keyboard-focus--yellow");

                    setTimeout(() => {
                        $keyBoardFocus.addClass("keyboard-focus keyboard-focus--yellow");
                        $prevTilebutton.trigger("focus");
                    }, 50);
                }
            }
        }
    });
}

/**
 * @function
 * @description Initializes algolia search
 */
const startAlgoliaSearch = () => {
    let $searchInput = $("#searchbox input");
    let searchStatus;

    if ($("#searchbox input").length > 0) {
        searchStatus = $("#searchbox input").data("search-status");
    }

    if (searchStatus) {
        let searchInitialized = new ThenPromise(resolve => {
            resolve(search.start());
        });

        searchInitialized.then(() => {
            handleReturnToSearchResults($searchInput);
        });

        $searchInput.data("search-status", false);
    }
};

/**
 * @function
 * @description Initializes algolia search suggestions
 */
const startQuerySuggestions = () => {
    searchSuggest.start();
};

/**
 * @function
 * @description Function to check if we have "enhanced-slider-products" slider and refresh slick for it
 */
const refreshSlider = () => {
    const $enhancedSliderProducts = $(".enhanced-slider-products");

    if ($enhancedSliderProducts.length && $enhancedSliderProducts.hasClass("slick-initialized")) {
        $enhancedSliderProducts.slick("refresh");
    }
};

/**
 * @private
 * @function
 * @description Initializes search insights - API client to send click events to Algolia.
 */
function initSearchInsights() {
    const ConsentResources = window.ConsentResources || {};
    // parameter used to disable algolia cookie in case user is opt out
    let userHasOptedOut = !ConsentResources.consent;

    window.aa("init", {
        appId: SitePreferences.ALGOLIA_APP_ID,
        apiKey: SitePreferences.ALGOLIA_API_KEY,
        userHasOptedOut: userHasOptedOut
    });
}

/**
 * @private
 * @function setMultiLocaleFields
 * @description Sets multi locale name properly to take the facets according to the locales on necessary refinements.
 * @input {String} attributeName refinemement name
 * @return {String} attributeName
 */
function setMultiLocaleFields(attributeName) {
    var additionalTextFields = ["Genre", "product_type", "edition_additional_locales"];

    if (SitePreferences.ALGOLIA_VERSION_TOGGLE === "v2") {
        if (additionalTextFields.indexOf(attributeName) !== -1) {
            return attributeName;
        }

        additionalTextFields.unshift("longDescription");
    } else {
        additionalTextFields.unshift("html_description");
    }

    var additionalPriceFields = ["minimum_price", "price", "default_price", "price_range"];

    // Non-price related fields
    if (additionalTextFields.indexOf(attributeName) > -1) {
        attributeName += "." + storefrontLocale;
    }

    // Price related fields.
    if (additionalPriceFields.indexOf(attributeName) > -1) {
        attributeName += "." + storefrontCurrencyCode;
    }

    return attributeName;
}

function initAlgoliaSearch() {
    // when checkout popup is displayed, ignores algolia events & dom elements on UPC.
    if ($(document).find("#searchbox").length == 0) {
        return;
    }

    initializeCache();

    if (!$cache.algoliaContainer.length) {
        return;
    }

    try {
        initInstantSearchObject();
    } catch (er) {
        console.log(`[Algolia error]: initInstantSearchObject failed \n${er.name} - ${er.message}`);

        // return if instantsearch initialization fails
        return;
    }

    initializeUserToken();

    search = initializeSearchBox(search);

    new ThenPromise(resolve => {
        resolve(search.start());
    });

    initializeDom();

    if (SitePreferences.ALGOLIA_QUERY_SUGGEST_ENABLE) {
        try {
            initializeSuggestions();
            searchSuggest = initializePushHit(searchSuggest);
            searchSuggest = initializeSuggestIndex(searchSuggest);
            startQuerySuggestions();
        } catch (error) {
            $cache.searchHints.addClass("hide");
            console.log(`[Algolia Error] initializeSuggestions failed - ${error.name} - ${error.message}`);
        }
    }

    initializeEvents();
    initializeWishlistEvents();
}

/**
 * Initialize AutoComplete widget
 * @param {Object} searchModule - Search module object
 * @return {Object} Search module object
 */
const initializeAutoComplete = searchModule => {
    var customAutoComplete = instantsearch.connectors.connectHits(renderAutoComplete);
    var $containerNode = $("#algolia-autocomplete");
    var indexName = getQuerySuggestionLocaleIndexes(SitePreferences.ALGOLIA_AUTOCOMPLETE_INDEX);

    if ($containerNode.length && indexName.length) {
        skipRefinementIndexes.push(indexName);

        searchModule.addWidget(
            instantsearch.widgets.index({
                indexName: indexName
            }).addWidgets([
                customAutoComplete({
                    containerNode: $containerNode
                }),
                instantsearch.widgets.configure({
                    ruleContexts: ["upc"],
                    hitsPerPage: 5,
                    page: 0
                })
            ])
        );
    }

    return searchModule;
};

/**
 * Autocomplete results rendering function
 * @param {Object} autoCompleteRenderingOptions - widget options
 */
const renderAutoComplete = autoCompleteRenderingOptions => {
    const $algoliaAutocompleteContainer = autoCompleteRenderingOptions.widgetParams.containerNode;
    const autoCompleteHTML = getAutoCompleteHTML(autoCompleteRenderingOptions);

    if (autoCompleteHTML) {
        if ($algoliaAutocompleteContainer.find(".search-results").length === 0) {
            const sectionTitle = '<div class="c-algolia__suggestions-title">' + SitePreferences.ALGOLIA_AUTOCOMPLETE_TITLE + "</div>";
            const container = `<ul class="c-algolia__suggestions-list"
                style="--button-bg-color: ${SitePreferences.ALGOLIA_AUTOCOMPLETE_BG_COLOR};
                    --button-text-color: ${SitePreferences.ALGOLIA_AUTOCOMPLETE_TEXT_COLOR};
                    --button-hover-bg-color: ${SitePreferences.ALGOLIA_AUTOCOMPLETE_BG_COLOR_HOVER};
                    --button-hover-text-color: ${SitePreferences.ALGOLIA_AUTOCOMPLETE_TEXT_COLOR_HOVER};">
            </ul>`;

            $algoliaAutocompleteContainer.html(sectionTitle + container);
        }

        $algoliaAutocompleteContainer.find(".c-algolia__suggestions-list").html(autoCompleteHTML);
    }

    $algoliaAutocompleteContainer.toggleClass("hide", !autoCompleteHTML);
};

/**
 * HTML list structure of the autocomplete options
 * @param {Object} autoCompleteRenderingOptions - widget options
 * @return {String} the html structure of the autocomplete options
 */
const getAutoCompleteHTML = autoCompleteRenderingOptions => {
    let autoCompleteHTML = "";

    if (autoCompleteRenderingOptions && autoCompleteRenderingOptions.hits.length) {
        let renderLastHit = false;

        autoCompleteHTML = autoCompleteRenderingOptions.hits.map((hit, index) => {
            // exclude the search term from the hits
            if (hit.query === $.trim(autoCompleteRenderingOptions.results.query)) {
                renderLastHit = true;

                return "";
            }

            // exclude the last item if all previous hits are rendered
            if (index === autoCompleteRenderingOptions.results.hitsPerPage - 1 && !renderLastHit) {
                return "";
            }

            const queryText = instantsearch.highlight({
                attribute: "query",
                hit,
                highlightedTagName: "span"
            });

            return `<li class="c-algolia__suggestions-item">
                        <button class="btn btn-purple"
                            data-action="suggestion-search"
                            data-auto-completion
                            data-query-text="${hit.query}"
                            data-query-id="${hit.__queryID}"
                            data-position-number="${index}">
                            ${queryText}
                        </button>
                    </li>`;
        }).join("");
    }

    return autoCompleteHTML;
};

/**
* Checks the privacy policy and returns a user token cookie if applicable.
* If the privacy policy is accepted and no user token cookie exists, a new token is generated and stored.
* @returns {string} userTokenCookie - The user token cookie, either retrieved or newly generated.
*/
function checkAndReturnPrivacyToken() {
    const privacyPolicy = checkPrivacyPolicy();
    let userTokenCookie = privacyPolicy ? getCookie(algoliaUserTokenCookieName) : "";
    let generatedToken;

    if (privacyPolicy && userTokenCookie === "") {
        generatedToken = generateRandomToken(tokenLength);

        setCookie(algoliaUserTokenCookieName, generatedToken, 5256000);
        userTokenCookie = generatedToken;
    }

    return userTokenCookie;
}

/**
* The function performs the following actions:
* - Retrieves the current privacy policy status and user token from cookies.
* - Configures the Algolia search widget with the user token if privacy policy allows.
* - Listens for window messages to update the user token configuration when privacy policy settings change.
*/
function initializeUserToken() {
    const userTokenCookie = checkAndReturnPrivacyToken();

    if (userTokenCookie) {
        configureUserTokenWidget(userTokenCookie);
    }

    window.addEventListener("message", event => {
        if (event.data === "PRIVACY_POLICY_ANALYTICS_ON") {
            configureUserTokenWidget(checkAndReturnPrivacyToken());
        }

        if (event.data === "PRIVACY_POLICY_ANALYTICS_OFF") {
            configureUserTokenWidget(userTokenCookie, true);
        }
    });
}

/**
* Configures the user token widget for Algolia search by either adding or removing it based on the specified parameters.
* @param {string} token - The user token to be configured for the Algolia search
* @param {boolean} [removeToken=false] - A flag indicating whether to remove cookie and widget
* @returns {void}
*/
function configureUserTokenWidget(token, removeToken = false) {
    if (search && typeof search.addWidgets === "function") {
        if (removeToken) {
            setCookie(algoliaUserTokenCookieName, "", -1);
            search.addWidgets([
                instantsearch.widgets.configure({
                    userToken: ""
                })
            ]);
        } else {
            search.addWidgets([
                instantsearch.widgets.configure({
                    userToken: token
                })
            ]);
        }
    }
}

/**
 * Generate a random token with the length given in parameter
 * @input {Number} tokenLengthLimit - desired length of the token
 * @return {String} Token
*/
function generateRandomToken(tokenLengthLimit) {
    var result = [];
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (var i = 0; i < tokenLengthLimit; i++) {
        result.push(characters.charAt(Math.floor(Math.random() * characters.length)));
    }

    return result.join("");
}

/**
 * Check if privacy policy was accepted or not
 */
function checkPrivacyPolicy() {
    return getCookie("UBI_PRIVACY_AA_OPTOUT") === "false";
}

/**
 * Return algolia indexes name with current locale
 * @return {Object} indexes object
 */
function getLocaleIndexes() {
    let algoliaVersion = SitePreferences.ALGOLIA_VERSION_TOGGLE;

    try {
        if (SitePreferences.ALGOLIA_VERSION_TOGGLE === "v2") {
            let originalIndexes = JSON.parse(SitePreferences.ALGOLIA_INDEXES_V2);
            let originalLocale = Resources.ORIGINAL_LOCALE;
            let localeIndexes = originalIndexes[originalLocale] || originalIndexes["default"] || { };

            return localeIndexes;
        }

        return JSON.parse(SitePreferences.ALGOLIA_INDEXES);
    } catch (e) {
        console.log(`[Algolia error] in Algolia Indexes ${algoliaVersion === "v2" ? algoliaVersion : ""}: \n${e.name} \n${e.message}`);

        return {};
    }
}

/**
 * Return query suggestion indexes from site preference for the current locale
 * @input {Number} sitePreference - desired site preference to get the value of index from
 * @return {String} index value
 */
function getQuerySuggestionLocaleIndexes(sitePreference) {
    try {
        var originalIndexes = JSON.parse(sitePreference);
        var originalLocale = Resources.ORIGINAL_LOCALE;
        var localeIndexes = originalIndexes[originalLocale] || originalIndexes["default"] || { };

        return localeIndexes;
    } catch (ex) {
        return "";
    }
}

export { initAlgoliaSearch };
