import {clickEvent, impressionEvent} from "TagcommanderJS/adobe_analytics_events";
import {isProductOwnership} from "./tagcommander_helper";

const parseAlgoliaQueryData = (str = "algoliaQueryData") => {
    try {
        return JSON.parse(localStorage.getItem(str));
    } catch {
        return null;
    }
};

/**
 * Checks if the visible (rendered by algolia .algolia-search-result) results have not changed
 * @param {Array} oldItems - the old visible results
 * @param {Array} newItems - the new visible results
 * @returns {boolean} - true if the visible results have not changed, false otherwise
 */
const visibileResultsUnchanged = (oldItems, newItems) => {
    return oldItems.length === newItems.length && oldItems.every((item, i) => item === newItems[i]);
};

/**
 * @function
 * @description Initialize Algolia tracking
 */
const initEvents = () => {
    const $cache = {
        suggestHints: $("#suggest-hints"),
        pushHints: $("#push-hints"),
        displayedResults: []
    };

    document.addEventListener("Algolia:ShowResultList", e => {
        const algoliaQueryDataObject = parseAlgoliaQueryData();
        const hitsList = e.detail || [];

        const sameResults = visibileResultsUnchanged($cache.displayedResults, hitsList);

        if (algoliaQueryDataObject) {
            const searchKeyword = algoliaQueryDataObject[0].searchKeyword;
            const searchQueryLength = algoliaQueryDataObject[0].searchQueryLength;
            const totalResults = algoliaQueryDataObject[0].totalResults;
            const pagination = algoliaQueryDataObject[0].pagination;
            const searchQueryId = algoliaQueryDataObject[0].searchQueryId;
            const searchAbTesting = algoliaQueryDataObject[0].searchAbTesting;
            const productList = algoliaQueryDataObject[0].productList;

            $cache.displayedResults = productList;

            let eventParams = {};

            if (totalResults === 0) {
                eventParams = {
                    location: "results list",
                    category: "display",
                    action: "no results",
                    totalResults: totalResults,
                    searchKeyword: searchKeyword,
                    pagination: 1,
                    searchQueryId: searchQueryId,
                    searchQueryLength: searchQueryLength,
                    searchOrigin: "store navbar",
                    searchAbTesting: searchAbTesting
                };
            } else if (!sameResults) {
                // proceed if the results are different from the previous ones
                // (example: user applied filter/used sorting and it affected result hits or their order)
                eventParams = {
                    location: "results list",
                    category: pagination === 1 ? "display" : "scroll",
                    action: "results",
                    totalResults: totalResults,
                    searchKeyword: searchKeyword,
                    pagination: pagination,
                    searchQueryId: searchQueryId,
                    searchQueryLength: searchQueryLength,
                    searchOrigin: "store navbar",
                    searchAbTesting: searchAbTesting,
                    productImpression: productList
                };
            }

            if (Object.keys(eventParams).length && !(pagination > 1 && productList.length === 0)) {
                impressionEvent(null, eventParams);
            }

            // search impression event for auto completion keywords shown
            if ($("#algolia-autocomplete").is(":visible") && $("#algolia-autocomplete [data-auto-completion]").length) {
                eventParams = {
                    location: "search result auto completion",
                    category: "display",
                    action: "visible",
                    searchOrigin: "store navbar",
                    totalResults: totalResults,
                    searchKeyword: searchKeyword,
                    searchQueryId: searchQueryId,
                    searchQueryLength: searchQueryLength
                };

                impressionEvent(null, eventParams);
            }
        }
    });

    const $document = $(document);
    const isUpc = $(".app_uplay").length;

    $document.on("click", ".c-algolia__close", e => {
        const algoliaQueryDataObject = parseAlgoliaQueryData();

        if (algoliaQueryDataObject) {
            const eventParams = {
                location: "results list",
                category: "interaction",
                action: "results list : close",
                totalResults: algoliaQueryDataObject[0].totalResults,
                searchKeyword: algoliaQueryDataObject[0].searchKeyword,
                pagination: algoliaQueryDataObject[0].pagination,
                searchQueryId: algoliaQueryDataObject[0].searchQueryId,
                searchQueryLength: algoliaQueryDataObject[0].searchQueryLength,
                searchOrigin: "store navbar",
                searchAbTesting: algoliaQueryDataObject[0].searchAbTesting
            };

            clickEvent(e, eventParams, null, null, false);
        }

        $cache.displayedResults = [];
    });

    $document.on("click", ".ais-SearchBox-input", e => {
        if (e.target.value !== "") {
            return;
        }

        const algoliaQueryDataObject = parseAlgoliaQueryData();
        const eventParams = {
            location: "search field",
            category: "interaction",
            action: "search bar : selected",
            searchOrigin: "store navbar"
        };

        if (algoliaQueryDataObject) {
            eventParams.searchAbTesting = algoliaQueryDataObject[0]?.searchAbTesting || "";
        }

        clickEvent(e, eventParams, null, null, false);
    });

    $document.on("keydown", ".ais-SearchBox-input", e => {
        if (e.target.value !== "") {
            return;
        }

        const algoliaQueryDataObject = parseAlgoliaQueryData();
        const eventParams = {
            location: "search field",
            category: "interaction",
            action: "search bar : start typing",
            searchOrigin: "store navbar"
        };

        if (algoliaQueryDataObject) {
            eventParams.searchAbTesting = algoliaQueryDataObject[0]?.searchAbTesting || "";
        }

        clickEvent(e, eventParams, null, null, false);
    });

    $document.on("click", ".ais-SearchBox-form .ais-SearchBox-reset", e => {
        const algoliaQueryDataObject = parseAlgoliaQueryData();

        if (algoliaQueryDataObject) {
            const eventParams = {
                location: "search field",
                category: "interaction",
                action: "search bar : close",
                totalResults: algoliaQueryDataObject[0].totalResults,
                searchKeyword: algoliaQueryDataObject[0].searchKeyword,
                pagination: algoliaQueryDataObject[0].pagination,
                searchQueryId: algoliaQueryDataObject[0].searchQueryId,
                searchQueryLength: algoliaQueryDataObject[0].searchKeyword.length,
                searchOrigin: "store navbar",
                searchAbTesting: algoliaQueryDataObject[0].searchAbTesting
            };

            clickEvent(e, eventParams, null, null, false);
        }

        $cache.displayedResults = [];
    });

    $document.on("click", ".algolia-producttile-card", e => {
        const $currentTarget = $(e.currentTarget);
        const $this = isUpc ? $currentTarget.find(".algolia-upc-see-more") : $currentTarget.find(".algolia-hit-producttile");
        const $target = $(e.target);
        const isSuggestion = $this.parents().hasClass("c-algolia__maylike-list");
        const isWishlistEvent = $target.hasClass("algolia-wishlist") || $target.parents(".algolia-wishlist").length > 0;
        const algoliaQueryDataObject = isSuggestion ? parseAlgoliaQueryData("algoliaSearchSuggestionData") : parseAlgoliaQueryData();

        if (algoliaQueryDataObject && $this.length > 0) {
            const productFreeOffer = $this.data("offer") ? $this.data("offer").toLowerCase() : "";
            let productDiscountString = "";

            if (productFreeOffer === "giveaway") {
                productDiscountString = "giveaway";
            } else if (productFreeOffer === "freeWithPurchase") {
                productDiscountString = "free with purchase";
            } else if ($this.data("discount-rate") !== "") {
                productDiscountString = "standard discount";
            }

            const productId = $this.data("pid");
            const slotNumber = $currentTarget.data("position-number");
            const productDiscountRate = $this.data("discount-rate") !== "" ? $this.data("discount-rate") / 100 : "";
            const productName = $this.data("product-name") || $this.data("game-name");
            const productEdition = $this.data("product-edition");
            const productPlatform = $this.data("product-platform");
            const productLauncherID = $this.data("product-launcherid");
            const productSalesType = $this.data("preorder") === true ? "preorder" : $this.data("available") === 1 ? "order" : "not available";
            const productDiscountName = productDiscountString;
            const productDiscount = productDiscountString !== "" ? "yes" : "no";
            const productDemo = productFreeOffer === "demo" ? "yes" : "no";
            const productFreeWeekEnd = productFreeOffer === "free-weekends" ? "yes" : "no";
            const isWishlist = User.wishlist && User.wishlist.indexOf(productId) > -1 ? "yes" : "no";
            const productOwnership = User.ownership ? isProductOwnership(User.ownership, productLauncherID) ? "yes" : "no" : "no";
            const adultGame = $this.data("rating") && SitePreferences.AGE_RESTRICTED_RATINGS?.indexOf($this.data("rating")) > -1 ? "yes" : "no";
            const eventParams = {
                productId: productId,
                productDiscountRate: productDiscountRate,
                productName: productName,
                productEdition: productEdition,
                productPlatform: productPlatform,
                productSalesType: productSalesType,
                adultGame: adultGame,
                isWishlist: isWishlist,
                productDiscountName: productDiscountName,
                productDiscount: productDiscount,
                productOwnership: productOwnership,
                productDemo: productDemo,
                productFreeWeekEnd: productFreeWeekEnd,
                location: isSuggestion ? "search suggestion" : "results list",
                category: isWishlistEvent ? "wishlist" : "action",
                slotNumber: slotNumber,
                action: isWishlistEvent ? "product : add to wishlist" : "product : see more",
                isProductClicked: isWishlistEvent ? undefined : 1,
                isWishlistAdd: isWishlistEvent ? 1 : undefined,
                ...!isSuggestion && {
                    locationPosition: "1", // in Algolia there is no other section above the product list
                    totalResults: algoliaQueryDataObject[0]?.totalResults,
                    searchKeyword: algoliaQueryDataObject[0]?.searchKeyword,
                    pagination: algoliaQueryDataObject[0]?.pagination,
                    searchQueryLength: algoliaQueryDataObject[0]?.searchQueryLength,
                    searchAbTesting: algoliaQueryDataObject[0]?.searchAbTesting
                },
                searchQueryId: isSuggestion ? algoliaQueryDataObject?.productHits?.queryId : algoliaQueryDataObject[0]?.searchQueryId,
                searchOrigin: "store navbar"
            };

            clickEvent(e, eventParams, null, null, true);
        }
    });

    document.addEventListener("Algolia:SearchPageEvents", () => {
        const algoliaQueryDataObject = parseAlgoliaQueryData();

        if (algoliaQueryDataObject) {
            const clickEventParams = {
                totalResults: algoliaQueryDataObject[0].totalResults,
                searchKeyword: algoliaQueryDataObject[0].searchKeyword,
                pagination: algoliaQueryDataObject[0].pagination,
                searchQueryId: algoliaQueryDataObject[0].searchQueryId,
                searchQueryLength: algoliaQueryDataObject[0].searchKeyword.length
            };

            if (isUpc) {
                clickEventParams.searchAbTesting = algoliaQueryDataObject[0].searchAbTesting;

                // algolia filter show/hide toggle click event
                $(".algolia-wrapper div.toggle").off("click").on("click", function (e) {
                    const isFilterActive = !$(this).hasClass("expanded");
                    const filterCategory = $(this).closest(".listRefinement").data("refdefname") || "";

                    clickEventParams.location = "filters";
                    clickEventParams.category = "filter";
                    clickEventParams.searchOrigin = "store navbar";
                    clickEventParams.action = filterCategory + " : " + (isFilterActive ? "see filters" : "hide filters");

                    clickEvent(e, clickEventParams);
                });

                // algolia sorting
                $("#sort-by-selectSelectBoxItOptions li").off("click mousedown").on("click mousedown", function (e) {
                    const sruleIndex = $(this).data("id");

                    // algolia sorting rule index map
                    const sruleIndexMap = {
                        0: "best sellers",
                        1: "price low to high",
                        2: "price high to low",
                        3: "name a to z",
                        4: "release date"
                    };

                    const $sortingName = sruleIndexMap[sruleIndex];

                    clickEventParams.location = "sorting";
                    clickEventParams.category = "sorting";
                    clickEventParams.searchOrigin = "store navbar";
                    clickEventParams.action = $sortingName + " : sorting used";

                    clickEvent(e, clickEventParams);
                });

                // algolia search checkbox filters click event
                $(".algolia-wrapper .refinement-group a").off("click").on("click", function (e) {
                    const isFilterActive = !$(this).find("input").prop("checked");
                    const filterCategory = $(this).closest(".listRefinement").data("refdefname") || "";
                    const filterName = $(this).closest("li").data("refine-value") || "";

                    clickEventParams.location = "filters";
                    clickEventParams.category = "filter";
                    clickEventParams.searchOrigin = "store navbar";
                    clickEventParams.action = filterCategory + " | " + filterName + " : " + (isFilterActive ? "filter used" : "filter removed");

                    clickEvent(e, clickEventParams);
                });

                // algolia remove filter click event
                $(".algolia-wrapper .clearRefinement").off("click").on("click", function (e) {
                    const filterCategory = $(this).closest("a").data("attribute") || "";
                    const filterName = $(this).closest("a").data("value") || "";

                    clickEventParams.location = "filters";
                    clickEventParams.category = "filter";
                    clickEventParams.searchOrigin = "store navbar";
                    clickEventParams.action = filterCategory + " | " + filterName + " : " + "filter removed";

                    clickEvent(e, clickEventParams);
                });

                // algolia search filter view more button click event
                $(".algolia-wrapper .view-more").off("click").on("click", function (e) {
                    const filterCategory = $(this).closest(".listRefinement").data("refdefname") || "";

                    clickEventParams.location = "filters";
                    clickEventParams.category = "filter";
                    clickEventParams.searchOrigin = "store navbar";
                    clickEventParams.action = filterCategory + " : see more";

                    clickEvent(e, clickEventParams);
                });

                // algolia search clear all filters
                $("#upc-clear-refinements button").off("mousedown").on("mousedown", function (e) {
                    clickEventParams.location = "filters";
                    clickEventParams.category = "filter";
                    clickEventParams.action = "all filters removed";
                    clickEventParams.searchOrigin = "store navbar";

                    clickEvent(e, clickEventParams);
                });
            } else {
                // algolia search checkbox filters click event
                $("#algolia-results .filters-accordion a").off("click").on("click", function (e) {
                    const isFilterActive = !$(this).hasClass("selected");
                    const filterCategory = $(this).closest(".accordion-element").data("refdefname") || "";
                    const filterName = $(this).data("refvalue") || "";

                    clickEventParams.location = "filters";
                    clickEventParams.action = filterCategory + " | " + filterName + " : " + (isFilterActive ? "filter used" : "filter removed");
                    clickEventParams.category = "filter";
                    clickEventParams.searchOrigin = "store navbar";
                    clickEventParams.action = filterCategory + " | " + filterName + " : " + (isFilterActive ? "filter used" : "filter removed");

                    clickEvent(e, clickEventParams);
                });

                // algolia remove filter click event
                $(".refinements-inner span.remove-filter").off("click").on("click", function (e) {
                    const filterCategory = $(this).closest("a").data("attribute") || "";
                    const filterName = $(this).closest("a").data("value") || "";

                    clickEventParams.location = "filters";
                    clickEventParams.category = "filter";
                    clickEventParams.searchOrigin = "store navbar";
                    clickEventParams.action = filterCategory + " | " + filterName + " : " + "filter removed";

                    clickEvent(e, clickEventParams);
                });

                // algolia search filter view more button click event
                $("#algolia-results .view-more").off("click").on("click", function (e) {
                    const filterCategory = $(this).closest(".accordion-element").data("refdefname") || "";

                    clickEventParams.location = "filters";
                    clickEventParams.category = "filter";
                    clickEventParams.searchOrigin = "store navbar";
                    clickEventParams.action = filterCategory + " : see more";

                    clickEvent(e, clickEventParams);
                });

                // algolia sorting
                $("#sort-by-container li").on("click", function (e) {
                    const sruleIndex = $(this).data("index");

                    // algolia sorting rule index map
                    const sruleIndexMap = {
                        0: "price low to high",
                        1: "price high to low",
                        2: "name a to z",
                        3: "best sellers"
                    };

                    const $sortingName = sruleIndexMap[sruleIndex];

                    clickEventParams.location = "sorting";
                    clickEventParams.category = "sorting";
                    clickEventParams.searchOrigin = "store navbar";
                    clickEventParams.action = $sortingName + " : sorting used";

                    clickEvent(e, clickEventParams);
                });

                // algolia filter show/hide toggle click event
                $("#algolia-results .accordion-toggle").off("mousedown").on("mousedown", function (e) {
                    const isFilterActive = !$(this).closest(".accordion-element").hasClass("active");
                    const filterCategory = $(this).closest(".accordion-element").data("refdefname") || "";

                    clickEventParams.location = "filters";
                    clickEventParams.category = "filter";
                    clickEventParams.searchOrigin = "store navbar";
                    clickEventParams.action = filterCategory + " : " + (isFilterActive ? "see filters" : "hide filters");

                    clickEvent(e, clickEventParams);
                });

                // algolia search clear all filters click event
                $("#web-clear-refinements button").off("mousedown").on("mousedown", function (e) {
                    clickEventParams.location = "filters";
                    clickEventParams.category = "filter";
                    clickEventParams.searchOrigin = "store navbar";
                    clickEventParams.action = "all filters removed";

                    clickEvent(e, clickEventParams);
                });
            }
        }
    });

    document.addEventListener("Algolia:ShowSuggestions", e => {
        e.stopPropagation();

        let hintsDisplayed = $cache.suggestHints.is(":visible") && $cache.suggestHints.find("[data-search-suggestion]").length;
        let pushHintsDisplayed = $cache.pushHints.is(":visible") && $cache.pushHints.find(".algolia-producttile-card").length;
        const algoliaSearchSuggestionData = parseAlgoliaQueryData("algoliaSearchSuggestionData");
        let eventParams;

        if (algoliaSearchSuggestionData) {
            // search impression event for auto completion keywords shown
            if (hintsDisplayed) {
                eventParams = {
                    location: "search auto completion",
                    category: "display",
                    action: "visible",
                    searchOrigin: "store navbar",
                    ...algoliaSearchSuggestionData?.textHints?.queryId && {
                        searchQueryId: algoliaSearchSuggestionData?.textHints?.queryId
                    }
                };

                impressionEvent(null, eventParams);
            }

            // search impression event for suggested products shown
            if (pushHintsDisplayed) {
                eventParams = {
                    location: "search suggestion",
                    category: "display",
                    action: "visible",
                    searchOrigin: "store navbar",
                    productImpression: algoliaSearchSuggestionData?.productHits?.list || "",
                    ...algoliaSearchSuggestionData?.productHits?.queryId && {
                        searchQueryId: algoliaSearchSuggestionData?.productHits?.queryId
                    }
                };

                impressionEvent(null, eventParams);
            }
        }
    });

    // search click on an auto completion keyword
    $(document).on("click", "#suggest-hints [data-search-suggestion]", e => {
        const $this = $(e.currentTarget);
        const keyword = $this.data("query-text");
        const eventParams = {
            location: "search auto completion",
            category: "interaction",
            slotNumber: $this.data("position-number"),
            action: `auto completion : ${keyword}`,
            searchQueryId: keyword,
            searchOrigin: "store navbar"
        };

        clickEvent(e, eventParams, null, null, false);
    });

    // search click on suggestions ("bubbles")
    $(document).on("click", "#algolia-autocomplete [data-auto-completion]", e => {
        const $this = $(e.currentTarget);
        const keyword = $this.data("query-text");
        const algoliaQueryDataObject = parseAlgoliaQueryData();
        const eventParams = {
            location: "search result auto completion",
            category: "interaction",
            slotNumber: $this.data("position-number"),
            action: `auto completion : ${keyword}`,
            totalResults: `${algoliaQueryDataObject?.[0]?.totalResults || 0}`,
            searchKeyword: keyword,
            searchQueryId: $this.data("query-id"),
            searchQueryLength: keyword.length,
            searchOrigin: "store navbar"
        };

        clickEvent(e, eventParams, null, null, false);
    });
};

const initializeAlgoliaEvents = () => {
    if (typeof(Storage) !== "undefined") {
        initEvents();
    }
};

export { initializeAlgoliaEvents };
