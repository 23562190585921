const $document = $(document);

/**
 * @function
 * @description Initializes Adobe Analytics click events
 * @param {String} eventAction - the action of the click event
 */
const initAdobeClickEvent = eventAction => {
    adobeAnalytics.track("clickevent", {
        action: eventAction
    });
};

/**
 * @function
 * @description Initialize subscription checkout click events
 */
const initializeSubsCheckoutEvents = () => {
    $document.on("click", ".c-expand__button", () => {
        initAdobeClickEvent("checkout - interaction - show plan details");
    });

    $document.on("click", ".l-checkout .c-navigation__step", e => {
        if ($(e.currentTarget).children("[data-step-id='1']").length) {
            initAdobeClickEvent("checkout - navigation - go to plan selection");
        }
    });

    $document.on("click", ".e-checkout__changeplan", () => {
        initAdobeClickEvent("checkout - navigation - go to plan selection");
    });

    $document.on("click", ".c-btn__billingstep", () => {
        initAdobeClickEvent("checkout - navigation - go to order confirmation");
    });

    $document.on("change", ".save-card-wrapper input", e => {
        const $this = $(e.currentTarget);

        if ($this.parent(".subscription-payment-container")) {
            if ($this.prop("checked")) {
                initAdobeClickEvent("cart flow - checkbox - save this card");
            } else {
                initAdobeClickEvent("cart flow - checkbox - don't save this card");
            }
        }
    });

    $document.on("click", ".e-remove-bonus", e => {
        const productName = $(e.currentTarget).data("product-name");

        initAdobeClickEvent("order confirmation - remove from cart - " + productName);
    });

    $document.on("click", ".subscription-checkout-button", () => {
        $document.trigger("placeOrderEvent");
    });

    $document.on("placeOrderEvent", () => {
        initAdobeClickEvent("checkout - navigation - place order");
    });

    $document.on("click", "#tag-upc-confirmation-browse-button, .ibex-start-playing, .c-anywherebenefits__connect", () => {
        initAdobeClickEvent("thank you page - navigation – start playing");
    });

    $document.on("click", ".c-anywherebenefits__stadia", () => {
        initAdobeClickEvent("thank you page - navigation - stadia start playing");
    });

    $document.on("click", ".c-anywherebenefits__amazon-luna", () => {
        initAdobeClickEvent("thank you page - navigation - luna start playing");
    });
};

export { initializeSubsCheckoutEvents };
