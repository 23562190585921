// Utils

const utils = {
    /**
     * @function
     * @description Check context to know if we are on UPC - return boolean value
     */
    checkUPC: () => {
        return document.querySelector("body").classList.contains("app_uplay");
    },

    /**
     * @function
     * @description appends the parameter with the given name and value to the given url and returns the changed url
     * @param {String} url the url to which the parameter will be added
     * @param {String} name the name of the parameter
     * @param {String} value the value of the parameter
     */
    appendParamToURL: (url, name, value) => {
        // quit if the param already exists
        if (url.indexOf(name + "=") !== -1) {
            return url;
        }

        var separator = url.indexOf("?") !== -1 ? "&" : "?";

        return url + separator + name + "=" + encodeURIComponent(value);
    },

    /**
     * @function
     * @description appends the parameters to the given url and returns the changed url
     * @param {String} url the url to which the parameters will be added
     * @param {Object} params
     */
    appendParamsToUrl: function (url, params) {
        let _url = url;

        for (let key in params) {
            _url = utils.appendParamToURL(_url, key, params[key]);
        }

        return _url;
    },

    /**
     * @function
     * @description Appends the parameter 'format=ajax' to a given path
     * @param {String} path the relative path
     */
    ajaxUrl: path => {
        return utils.appendParamToURL(path, "format", "ajax");
    },

    /**
     * @function
     * @description Outputs JSON object or null
     * @param {String} string JSON string
     * @returns {(Object|Null)} Parsed JSON object or null in case of the exception during parsing
     */
    parseJSON: string => {
        try {
            return JSON.parse(string);
        } catch (e) {
            return null;
        }
    }
};

module.exports = utils;
