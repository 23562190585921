let $cache = {};

/**
 * @function
 * @description Initializes the cache object
 */
const initializeCache = () => {
    $cache = {
        utcDate: $("[data-utc-date-format]")
    };
};

/**
 * Populate the date formatted in current user's timezone from UTC
 */
const initDateTransformation = () => {
    initializeCache();

    $cache.utcDate.each((index, element) => {
        const $element = $(element);
        const utcDate = $element.attr("data-utc-date-format");

        if (!utcDate) {
            return;
        }

        const formattedDate = new Date(utcDate);

        if (formattedDate.getDate()) {
            $element.html(formattedDate.toLocaleDateString());
        } else {
            $element.html(utcDate);
        }
    });
};

export { initDateTransformation };
