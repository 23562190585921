let $cache = {};

/**
 * @function
 * @description Initialize cache object
 */
const initCache = () => {
    $cache = {
        adHocOfferContainer: $(".js-adhoc-offer"),
        preOrderOfferContainer: $(".js-preorder-offer"),
        postLaunchOfferContainer: $(".js-postlaunch-offer")
    };
};

/**
 * @function
 * @description Сhecks if the current date is before the release date
 * @returns {Boolean} Returns true if the release date exists and the current date is before the release date, false otherwise
 */
const isValidPreOrderOffer = () => {
    let releaseDate = "";

    if ($cache.preOrderOfferContainer.length) {
        releaseDate = $cache.preOrderOfferContainer.data("releasedate");
    }

    if (!releaseDate && $cache.postLaunchOfferContainer.length) {
        releaseDate = $cache.postLaunchOfferContainer.data("releasedate");
    }

    if (releaseDate) {
        return new Date() < new Date(releaseDate);
    }

    return false;
};

/**
 * @function
 * @description Сhecks if the current date is between adHocStartDate and adHocEndDate
 * @param {String} adHocStartDate - Ad-hoc offer start date
 * @param {String} adHocEndDate - Ad-hoc offer end date
 * @returns {Boolean} Returns true if the current date is between adHocStartDate and adHocEndDate and both exists, false otherwise
 */
const isValidAdHocOffer = (adHocStartDate, adHocEndDate) => {
    if (adHocStartDate && adHocEndDate) {
        const currentDate = new Date();

        return new Date(adHocStartDate) < currentDate && new Date(adHocEndDate) > currentDate;
    }

    return false;
};

/**
 * @function
 * @description Show/Hide the preorder and post-launch offers
 */
const tooglePrePostLaunchOffersVisibility = () => {
    if ($cache.preOrderOfferContainer.length || $cache.postLaunchOfferContainer.length) {
        const isValidPreOrder = isValidPreOrderOffer();

        if ($cache.preOrderOfferContainer.length) {
            $cache.preOrderOfferContainer.toggleClass("hide", !isValidPreOrder);
        }

        if ($cache.postLaunchOfferContainer.length) {
            $cache.postLaunchOfferContainer.toggleClass("hide", isValidPreOrder);
        }
    }
};

/**
 * @function
 * @description Show/Hide the ad-hoc offers
 */
const toogleAdHocOfferVisibility = () => {
    if ($cache.adHocOfferContainer.length) {
        const startDate = $cache.adHocOfferContainer.data("startdate");
        const endDate = $cache.adHocOfferContainer.data("enddate");

        $cache.adHocOfferContainer.toggleClass("hide", !isValidAdHocOffer(startDate, endDate));
    }
};

/**
 * @function
 * @description Initialize offer presentation
 */
const initOfferPresentation = () => {
    initCache();
    tooglePrePostLaunchOffersVisibility();
    toogleAdHocOfferVisibility();
};

export { initOfferPresentation };
