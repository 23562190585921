/**
 * Initialize Adobe Analytics
 * @param {boolean} isUpc - Whether we are in the Connect context (running in an iframe)
 */
const initAdobeAnalytics = isUpc => {
    // Make sure wa_data is defined and adobeAnalytics is properly loaded
    if (wa_data && adobeAnalytics && typeof adobeAnalytics.init === "function") {
        if (isUpc) {
            adobeAnalytics.init({
                ...wa_data,
                // We need this parameter when running in an iframe, otherwise the privacy banner won't be displayed
                enablePrivacy: "override-iframe"
            });
        } else {
            adobeAnalytics.init(wa_data);
        }
    }
};

/**
 * Load Analytics SDK file
 * @param {boolean} isUpc - Whether we are in the Connect context (running in an iframe)
 */
const loadAnalyticsSdk = isUpc => {
    // Only load the SDK if it is not already present in the page
    if ($("script[src*='analyticssdk.js']").length === 0) {
        const analyticsSdkScript = document.createElement("script");

        analyticsSdkScript.id = "analytics_sdk";
        analyticsSdkScript.type = "text/javascript";
        analyticsSdkScript.src = SitePreferences.ADOBE_SDK_URL;
        analyticsSdkScript.async = true;
        document.getElementsByTagName("head")[0].appendChild(analyticsSdkScript);
    }

    window.addEventListener("message", event => {
        if (event.data === "ANALYTICS_SDK_LOADED") {
            initAdobeAnalytics(isUpc);
        }
    });
};

export { loadAnalyticsSdk };
