import initButtons from "GlobalJS/components/button";
import { util } from "./util";
import { dialog } from "./dialog";
import { threedsv2 } from "./threeds2";
import { handleReactivationModalOpen } from "./wallet";
import { initProductTooltip } from "./tooltip";
import { initGameListEvents } from "./storeavailablegames";
import { subscriptions } from "./subscriptions";
import { initVIdeoDialog } from "./videodialog";
import { initIbexPDP } from "./pages/product/ibexsubscription";
import { SUBSCRIPTION_EVENT_TYPE } from "../../../modules/constants";

let $cache = {},
    $document = $(document);

/**
 * @function
 * @description initialize cache object
 */
const initializeCache = () => {
    $cache = {
        openUbisoftPassChoice: $(".open-ubisoft-pass-choice"),
        subscriptionModal: $(".ubisoft-pass-choice"),
        openTutorialModal: $(".tutorial-btn"),
        html: $("html"),
        document: $(document),
        subscriptionswitchForm: $("#pdp-subscriptionswitch-form")
    };
};

/**
 * @function
 * @description Auto selects plan variation if there is only one available or preselects Multi Access (premiumAnywhere) subscription if was triggered from cloud page
 * @param {object} $parent - jquery parent selector of the subscription variation item
*/
const autoSelectSubscriptionVariation = $parent => {
    const $variationItem = $parent.find(".subscription-variation-item");

    if ($variationItem.length === 1 && !$variationItem.hasClass("subscription-variation-section-selected")) {
        $(".subscription-variation-item").trigger("click");
    } else {
        const isCloudPage = $("#discoverCloudGamingPage").length > 0;
        let qsParams = util.getQueryStringParams(window.location.search);
        let preselectPremiumAnywhereSub = "fromCloudPage" in qsParams ? qsParams["fromCloudPage"] : false;
        let $premiumAnywhereSub = $('.subscription-variation-item[data-subscriptionplan="premiumAnywhere"]');

        if ((isCloudPage && $premiumAnywhereSub.length > 0) || (preselectPremiumAnywhereSub && $premiumAnywhereSub.length > 0)) {
            $premiumAnywhereSub.trigger("click");
        }
    }
};

/**
 * @function
 * @description Removes class for full width modal
*/
const removeFullWidthClass = () => {
    const $wrapperClass = pageContext.ns === "subscription_planselection" ? $(".reuse-payment-modal") : $(".ubisoft-pass-choice");

    if ($wrapperClass.hasClass("ubisoft-pass-choice-modal")) {
        $wrapperClass.removeClass("ubisoft-pass-choice-modal").position({
            my: "center",
            at: "center",
            of: window,
            collision: "fit"
        });
    }
};

/**
 * @function
 * @description Callback handler: Opens the checkout dialog after closing current dialog
 */
const openCheckoutCallback = () => {
    $cache.document.on("click", ".js-open-checkout-dialog", e => {
        e.preventDefault();

        window.location.href = Urls.subscriptionBillingStep;
    });
};

/**
 * @function
 * @description initialize Ubisoft Pass Choice of plan modal events
 */
const initializeModalEvents = () => {
    const $variationCard = $(".ubisoft-pass-choice-card");

    initProductTooltip();

    // add "subscription-variation-section-selected" class to the selected plan, "unselected-variation" to the other variation and enable the CTA after a plan was selected
    $(".subscription-variation-item").off("click.variationItem").on("click.variationItem", e => {
        const $this = $(e.currentTarget);
        const $target = $(e.target);
        const masterId = $this.data("masterid");
        const subscriptionType = $this.data("subscriptiontype");
        const subscriptionId = $this.data("subscriptionid");
        const subscriptionPlan = $this.data("subscriptionplan");
        const subscriptionName = $this.data("productname");
        const variationSelected = "subscription-variation-section-selected";
        const $variationItem = $(".subscription-variation-item");
        const $selectPlanLabel = $this.closest(".ubisoft-pass-choice-wrapper").find(".select-plan-label");

        if ($target.is(".c-tooltip") || $target.parents(".c-tooltip").length > 0) {
            return;
        }

        if ($selectPlanLabel.length > 0 && !$selectPlanLabel.hasClass("select-plan-landing")) {
            if (masterId && subscriptionType && subscriptionId) {
                // clean 'data cache'
                $selectPlanLabel.removeData(["masterid", "subscriptionid", "subscriptionplan", "productname"]);

                $selectPlanLabel.attr({
                    "data-masterid": masterId,
                    "data-subscriptionid": subscriptionId,
                    "data-subscriptionplan": subscriptionPlan,
                    "data-productname": subscriptionName
                });
            }

            if ($selectPlanLabel.attr("disabled") === "disabled") {
                $selectPlanLabel.removeAttr("disabled");
            }
        }

        if (subscriptionPlan) {
            $variationItem.removeClass(variationSelected)
                .filter(`.subscription-variation-item-${subscriptionPlan}`)
                .addClass(variationSelected);
        }

        //  toggle .unselected-variation in case of multiple subscription variations
        if ($variationItem.length > 1) {
            $variationItem.each((i, el) => {
                $(el).toggleClass("unselected-variation", !($(el).hasClass(variationSelected)));
            });
        }

        // scroll to  "choose this plan" CTA if it is out of viewport
        let $scrollTarget = !$selectPlanLabel.hasClass("select-plan-landing")
            ? $selectPlanLabel
            : $(".subscription-variation-section-selected").find(".select-plan-label");

        if ($scrollTarget.length) {
            let isTargetInView = util.elementInViewport($scrollTarget.get(0), -50);

            // stop animation on user's manual actions
            $("html, body").on("mousedown wheel mousewheel keyup touchmove", () => {
                $("html, body").stop();
                isTargetInView = false;
            });

            if (!isTargetInView) {
                $("html, body").animate({
                    scrollTop: ($scrollTarget.offset().top - window.innerHeight / 2)
                }, 500, () => {
                    $("html, body").off("mousedown wheel mousewheel keyup touchmove");
                });
            }
        }
    });

    $(".cc-saved").on("click", ".field-wrapper label", e => {
        const $this = $(e.currentTarget);

        if (!$this.hasClass("disabled")) {
            $(".cc-saved").removeClass("active");
            $this.parents(".cc-saved").addClass("active");
        }
    });

    if ($(".ubisoft-pass-choice-modal").length < 1 && $variationCard.length > 0) {
        autoSelectSubscriptionVariation($variationCard);
    }
};

/**
 * Triggers a page reload to update the subscription customer status if he finished the checkout
 */
$document.on("click", ".checkout-dialog .ui-dialog-titlebar-close", () => {
    if ($(".open-ubisoft-pass-choice").length) {
        let newWindowLocation = util.removeParamFromURL(window.location.href, "PayerID");

        newWindowLocation = util.removeParamFromURL(newWindowLocation, "token");
        window.location = newWindowLocation;
    }

    if ($(".ibex-purchase-area").length || $(".ibex-plan-title").length) {
        location.reload();
    }
});

/**
 * Enable reactivate subscription button when all checkboxes are checked
 */
$document.on("change", "#checkout-conditions input[type='checkbox']", e => {
    e.preventDefault();

    util.enableSubsReactivationButton($(".btn-reactivate-subscription"));
});

/**
 * Enable back the reactivate subscription button
 */
$document.on("click", "#reactivate-tos, #tos_uplay_plus, #wallet_terms_of_sale", () => {
    let $submitBtn = $(".js-reactivate-subscription");
    let $reactivateTosError = $(".reactivate-tos-error");
    let isWalletCreditCard = $(".saved-cards-container-wallet").hasClass("active");
    let $reactivateTos = isWalletCreditCard ? $("#reactivate-tos, #tos_uplay_plus, #wallet_terms_of_sale") : $("#reactivate-tos, #tos_uplay_plus");

    if ($submitBtn.length && $reactivateTos.length) {
        subscriptions.toggleStepBtn($submitBtn, $reactivateTos, $reactivateTosError);
    }
});

/**
 * Opens the popin to update the payment used for subscription
 */
$document.on("click", ".js-reactivate-subscription", async e => {
    e.preventDefault();

    const $this = $(e.currentTarget);
    let isWalletCreditCard = $(".saved-cards-container-wallet").hasClass("active");
    let $reactivateTos = isWalletCreditCard ? $("#reactivate-tos, #tos_uplay_plus, #wallet_terms_of_sale") : $("#reactivate-tos, #tos_uplay_plus, #tos");
    let disabledFlag = false;

    $reactivateTos.each((i, el) => {
        if (!$(el).prop("checked")) {
            disabledFlag = true;
        }
    });

    if ($reactivateTos.length && disabledFlag) {
        if ($(".reactivate-tos-error").length === 0) {
            $("#checkout-conditions")
                .after('<span class="error reactivate-tos-error">' + Resources.VALIDATE_TOS + "</span>");
        }

        $this.attr("disabled", true);
    } else {
        let $selectedPaymentMethod = $(".payment-method-item.selected");

        if ($selectedPaymentMethod) {
            const savedPaymentData = await util.getFetchResponse(Urls.checkSavedPaymentMethod, {
                paymentID: $selectedPaymentMethod.attr("data-payment-id"),
                paymentValue: $selectedPaymentMethod.val(),
                backupPaymentID: $selectedPaymentMethod.data("backup-payment-id") ? $selectedPaymentMethod.data("backup-payment-id") : ""
            }, {
                response: "JSON"
            });

            if (savedPaymentData && savedPaymentData.success) {
                if (SitePreferences.ENABLE_3DSV2) {
                    checkfor3ds();
                    // add new event listener to redirect to worldpay result url into uplay iframe
                    window.addEventListener("message", ev => {
                        try {
                            var data = JSON.parse(ev.data || "{}");

                            if (data.message === "3DS-authentication-complete-reactivation") {
                                $.ajax({
                                    url: data.redirectUrl
                                }).done(response => {
                                    if (typeof response == "object" && !response.isSuccess) {
                                        location.reload();
                                    } else {
                                        const $ubisoftPassChoiceWrapper = $(".ubisoft-pass-choice-wrapper");

                                        if ($ubisoftPassChoiceWrapper.length) {
                                            $ubisoftPassChoiceWrapper.html(response);
                                        }

                                        subscriptions.adjustReactivationModalPosition();

                                        if ($("iframe.collectDataForm").length > 0) {
                                            $("iframe.collectDataForm").removeClass("hide");
                                        }

                                        $(".ui-dialog-titlebar-close, [data-dialog-close]").on("click", () => {
                                            location.reload();
                                        });
                                    }

                                    $(".ubisoft-pass-choice").removeClass("load-section");
                                });
                            }
                        } catch (ex) {
                            // nothing to catch in case of non JSON message
                        }
                    }, false);
                }

                $(".ubisoft-pass-choice").addClass("load-section");
                $this.attr("disabled", true);
                $(".open-ubisoft-pass-choice").attr("disabled", true);

                var taxamoZuoraEnable = SitePreferences.ENABLE_TAX_SUBS;

                setTimeout(async () => {
                    if (taxamoZuoraEnable) {
                        let calculateTaxByBasket = await util.getFetchResponse(Urls.calculateTaxByBasket,
                            {
                                paymentID: $(".payment-method-item.selected").attr("data-payment-id"),
                                reactivateSubscription: "true"
                            }, {
                                response: "JSON"
                            }
                        );

                        if (calculateTaxByBasket) {
                            if (!calculateTaxByBasket.success) {
                                dialog.close();
                                window.location = window.location.pathname;
                            } else {
                                handleSubscriptionReactivation($this, calculateTaxByBasket);
                            }
                        }
                    } else {
                        handleSubscriptionReactivation($this, null);
                    }
                }, SitePreferences.ENABLE_3DSV2 ? 4000 : 0);
            } else {
                $this.addClass("has-tooltip").prop("disabled", true);
                $this.parent().addClass("has-tooltip");
            }
        }
    }
});

/**
 * @function
 * @description handles the subscription reactivation
 * @param {Object} $this - jQuery object
 * @param {Object|null} resp - response from Taxamo-CalculateTaxByBasketAjax call or null if Taxamo is not enabled
 */
const handleSubscriptionReactivation = async ($this, resp) => {
    const taxamoZuoraEnable = SitePreferences.ENABLE_TAX_SUBS;
    const isAccountPage = $("#account-subscription").length > 0;
    const $selectedPaymentMethod = $(".payment-method-item.selected");
    let data = {
        paymentID: $(".payment-method-item.selected").attr("data-payment-id"),
        paymentType: $(".payment-method-item.selected").val(),
        defaultPaymentType: $(".js-account-subscription-payment-method").attr("data-default-payment-method-type"),
        reactivateSubscription: "true",
        dfReferenceID: $("input[name$='dfReferenceId']").val(),
        isAccountPage: isAccountPage,
        backupPaymentID: $selectedPaymentMethod.data("backup-payment-id") ? $selectedPaymentMethod.data("backup-payment-id") : ""
    };

    if (taxamoZuoraEnable) {
        data.taxamoResponse = JSON.stringify(resp);
    }

    let activateSubscriptionData = await util.getFetchResponse(Urls.activateSubscription, data, {
        response: "dynamic"
    });

    if (activateSubscriptionData) {
        if (typeof activateSubscriptionData == "object") {
            if (!activateSubscriptionData.isSuccess) {
                dialog.close();
                window.location = window.location.pathname;
            } else if (activateSubscriptionData.redirectUrl) {
                window.location = activateSubscriptionData.redirectUrl;
            }
        } else {
            const $modalContainer = $(".reuse-payment-methods-container");

            if ($modalContainer.length) {
                $modalContainer.html(activateSubscriptionData);

                const $subscriptionErrorWrapper = $(".c-subscription-error_wrapper");

                if ($subscriptionErrorWrapper.length > 0) {
                    const $dialogParent = $subscriptionErrorWrapper.parents(".reuse-payment-modal");

                    if ($dialogParent.length > 0) {
                        $dialogParent.addClass("c-dialog__error subscription-error").css("width", "800px").position({
                            my: "center",
                            at: "center",
                            of: window
                        });
                    }
                }
            }

            subscriptions.adjustReactivationModalPosition();

            if ($("iframe.collectDataForm").length > 0) {
                $("iframe.collectDataForm").removeClass("hide");
            }

            $cache.document.on("click", "[data-dialog-close]", () => {
                dialog.close();
                $cache.html.removeClass("dialog-opened");
            });
        }

        $(".ubisoft-pass-choice").removeClass("load-section");
        $this.attr("disabled", false);
    }
};

/**
 * @function
 * @description trigger a cancellation modal window based on ESS call response
 * @param {Object} data Object describing what modal window to trigger
 */
const openSubscriptionCancellationModal = data => {
    if (data?.isSuccess) {
        window.location.href = util.appendParamToURL(Urls.showUbiSubscriptionCancellationSuccess, "subscription", data.subscriptionName);
    } else if (data?.status === "alreadyScheduled") {
        if (data?.endDateText != "") {
            $(".subscription-alreadyrequested .message").html(data.endDateText);
        }

        dialog.open({
            html: $(".subscription-alreadyrequested").html(),
            options: {
                dialogClass: "subscription-update-membership-popin",
                width: 980
            }
        });
    } else if (data?.status === "ServiceError") {
        dialog.open({
            html: $(".subscription-service-error").html(),
            options: {
                dialogClass: "subscription-update-membership-popin",
                width: 980
            },
            callback: () => {
                $("[data-dialog-close]").on("click", () => {
                    dialog.close();
                });
            }
        });
    } else if (!data?.isSuccess && data?.notYetInvoiced) {
        dialog.open({
            html: $(".cancelation-request-error").html(),
            options: {
                dialogClass: "cancelation-request-error",
                width: 980
            }
        });
    }
};

/**
 * @function
 * @description init Ubisoft Pass Choice modal handling
 */
const initializeEvents = () => {
    if (SitePreferences.ENABLE_3DSV2) {
        threedsv2.initializeThreeDSEvents();
    }

    subscriptions.createLegalTooltipArrow();

    // Listener for opening checkout in callback dialog
    $cache.document.on("openCheckout:geoRedirect", openCheckoutCallback);

    $cache.document.on("click", ".open-ubisoft-pass-choice", e => {
        var $this = $(e.currentTarget);
        const subscriptionType = $this.data("subscription-type");

        e.preventDefault();

        if ($this.hasClass("intermediary-disabled")) {
            return;
        }

        if (subscriptionType === "ibex") {
            if (Resources.ENABLE_IBEX_REACTIVATION_FLOW_BY_PDP) {
                window.location.href = Urls.ibexReactivationPDP;
            } else {
                window.location.href = Urls.redirectToIBEXLandingPage;
            }
        } else {
            const params = {
                returnFromCheckout: location.href
            };

            if ($this.hasClass("tag-reactivate-membership")) {
                params["isReactivationFlow"] = true;
            }

            if ($("#discoverCloudGamingPage").length > 0) {
                params["fromCloudPage"] = true;
            }

            window.location.href = util.appendParamsToUrl(Urls.subscriptionLink, params);
        }
    });

    $(".edit-billing-address").on("click", e => {
        e.preventDefault();

        const $this = $(e.currentTarget);
        const subscriptionType = $this.data("subscription-type");
        const billingAddress = $this.data("address") ? JSON.stringify($this.data("address")) : "";
        const isRestrictedCountry = JSON.stringify($this.data("restricted-country"));
        let url = Urls.changeBillingAddress;

        if (subscriptionType.length > 0) {
            url = util.appendParamsToUrl(Urls.changeBillingAddress,
                {
                    subscriptionType: subscriptionType,
                    selectedAddress: billingAddress,
                    isRestrictedCountry: isRestrictedCountry
                }

            );
        }

        dialog.open({
            url: url,
            options: {
                dialogClass: "pt_checkout ubisoft-pass-choice change-subscription-information keyboard-focus--yellow change-subscription-information-" + subscriptionType,
                width: 980
            },
            callback: () => {
                initializeModalEvents();

                $("#dialog-container").siblings().find(".ui-dialog-titlebar-close").attr("tabindex", "0");
                $("#dialog-container input").on("keypress", ev => {
                    if (ev.which === 13) {
                        $(ev.currentTarget).trigger("click");
                    }
                });

                $(".ui-dialog").attr("data-track-address", "manage").data("location", "your subscription").trigger("dialog:dataAdded");

                initButtons();

                document.dispatchEvent(new CustomEvent("SavedAddress:GetInitialInputID"));
            }
        });
    });

    $(".js-change-billing-information").click(e => {
        e.preventDefault();

        const dialogOpenedClass = "dialog-opened";
        const $changeBillingInfoPopin = $(".change-billing-information-popin");

        if ($changeBillingInfoPopin.length) {
            dialog.open({
                html: $changeBillingInfoPopin.html(),
                options: {
                    dialogClass: "c-changetenant",
                    draggable: false,
                    close: () => {
                        $cache.html.removeClass(dialogOpenedClass);
                    }
                },
                callback: () => {
                    $cache.html.addClass(dialogOpenedClass);

                    initializeModalEvents();
                    $("#dialog-container").siblings().find(".ui-dialog-titlebar-close").attr("tabindex", "0");
                }
            });
        }
    });

    $cache.openTutorialModal.click(e => {
        e.preventDefault();
        dialog.open({
            url: Urls.tutorialTemplate,
            options: {
                dialogClass: "htp-modal",
                width: 980,
                close: () => {
                    $cache.html.removeClass("dialog-opened");
                    $(".htp-modal").removeClass("bigger-than-viewport");
                }
            },
            callback: () => {
                const $htpModal = $(".htp-modal");

                $cache.html.addClass("dialog-opened");

                if ($htpModal.height() > window.innerHeight) {
                    $htpModal.addClass("bigger-than-viewport");
                }
            }
        });
    });

    $cache.document.on("click", ".js-close-this-reveal", e => {
        dialog.close();
        $cache.html.removeClass("dialog-opened");

        if ($(e.currentTarget).hasClass("btn-not-compatible")) {
            location.reload();
        }
    });

    $cache.document.on("click", e => {
        if (!$(e.target).parents(".htp-modal").length) {
            if ($(".htp-modal").css("display") === "block") {
                dialog.close();
                $cache.html.removeClass("dialog-opened");
            }
        }
    });

    $cache.document.on("click", ".add-new-credit-card", () => {
        if (window.location.href.indexOf("uplayplus") > -1 || window.location.href.indexOf("uplay-plus") > -1) {
            const customerStatus = $cache.document.find(".customer_status").length > 0 ? $cache.document.find(".customer_status").data("subs-status") : "";

            if (customerStatus === "Inactive" && typeof(Storage) !== "undefined") {
                sessionStorage.setItem("customerStatus", "Inactive");
            }
        }
    });

    if (!$(".customer_status").attr("data-is-authenticated")) {
        localStorage.removeItem("greetings-reveal-displayed");
    }

    $(".js-btn-cancel-membership").on("click", e => {
        e.preventDefault();

        document.dispatchEvent(new CustomEvent("Subscription:SubmitCancellationForm", {
            detail: e.currentTarget
        }));
    });

    document.addEventListener("Subscription:SubmitCancellationForm", async e => {
        const $this = $(e.detail);
        const subscriptionType = $this.data("subscription-type");
        const form = $this.closest("form");
        const url = subscriptionType ? util.appendParamToURL(Urls.cancelMembership, "subscriptionType", subscriptionType) : Urls.cancelMembership;

        $cache.document.trigger("cancelSubscriptionCTATracking", [$this]);

        // Prevent multiple click by disabled button
        $this.attr("disabled", "disabled");

        if (Urls.cancelMembership) {
            const cancelMembershipData = await util.getFetchResponse(url, form.serialize(), {
                method: "POST",
                response: "JSON"
            });

            if (cancelMembershipData) {
                if (subscriptionType === "ibex") {
                    subscriptions.showIbexCancellationSuccess(cancelMembershipData);
                } else {
                    openSubscriptionCancellationModal(cancelMembershipData);
                }
            }
        }
    });

    $cache.document.on("click", ".discover-products", e => {
        e.preventDefault();

        if ($("#discover-products").length) {
            util.scrollBrowser($("#discover-products").offset().top);
        }
    });

    // click event to display gamelist info
    $cache.document.on("click", ".gamelist-anchor", e => {
        const platform = $(e.currentTarget).data("gameslist");

        if (platform) {
            const $gamelistElements = $(`.${platform}-gamelist`);
            const $storeAvailableGames = $(".store-available-games");

            if ($storeAvailableGames.length && $gamelistElements.length) {
                $gamelistElements.removeClass("hide");
                $storeAvailableGames.addClass("show").data("gamelistOpen", platform);
            }
        }
    });

    // click event to close gamelist info
    $cache.document.on("click", ".close-button-gamelist", () => {
        const $storeAvailableGames = $(".store-available-games");
        const gamelistOpen = $storeAvailableGames.data("gamelistOpen");

        if (gamelistOpen) {
            const $gamelistElementsDisplayed = $(`.${gamelistOpen}-gamelist`);

            if ($gamelistElementsDisplayed.length) {
                $storeAvailableGames.removeClass("show");
                $gamelistElementsDisplayed.addClass("hide");
            }
        }
    });

    $cache.document.on("click", ".select-subscription-plan-button, .choose-this-plan-button", e => {
        e.preventDefault();

        const $this = $(e.currentTarget);
        const subscriptionId = $this.data("subscriptionid");

        if (!subscriptionId) {
            return;
        }

        handleSubscriptionCartAction(subscriptionId);
    });

    // Handle subscription cart action on custom event
    $cache.document.on(SUBSCRIPTION_EVENT_TYPE.CART_ACTION, event => {
        if (!event.detail.pid) {
            return;
        }

        handleSubscriptionCartAction(event.detail.pid);
    });

    // Handle the subscription switch form validation on custom event
    $cache.document.on(SUBSCRIPTION_EVENT_TYPE.SWITCH_ACTION, event => {
        if (!event.detail.pid) {
            return;
        }

        handleSubscriptionSwitchSubmit(event.detail.pid);
    });

    // handle click event for game list button
    $cache.document.on("click", ".game-list-btn", async () => {
        const gamingList = await util.getFetchResponse(Urls.gamingListPopin, undefined, {
            response: "JSON"
        });

        if (gamingList) {
            // TODO: add content of the response to the pop-in
        }
    });
};

/**
 * @function handleSubscriptionCartAction
 * @param  {string} subscriptionId Selected subscription id to handle
 * @return {void} Handles the subscription flow depending on the User status
 */
const handleSubscriptionCartAction = async subscriptionId => {
    // Set return URL in case of child user
    sessionStorage.setItem("returnUrlAgeGateRejected", window.location.href);

    const addProductResponse = await util.getFetchResponse(util.ajaxUrl(Urls.addProduct), {
        format: "ajax",
        Quantity: 1,
        showPopup: true,
        pid: subscriptionId
    }, {
        method: "POST"
    });

    if (!addProductResponse || !addProductResponse.length) {
        return;
    }

    const subscriptionStatus = $(".customer_status").attr("data-subs-status") || $("input[name='subscriptionStatus']").val();

    // if Uplayplus simplified reactivation flow is enabled than skip the Reuse payment step
    if (!Resources.ENABLE_NEW_UBISOFTPLUS_REACTIVATION_FLOW && subscriptionStatus == "Inactive") {
        const subscriptionReusePayment = await util.getFetchResponse(Urls.subscriptionReusePayment, undefined, {
            response: "dynamic"
        });

        if (!subscriptionReusePayment) {
            dialog.close();
            window.location.assign(Urls.subscriptionBillingStep);

            return;
        }

        if (typeof subscriptionReusePayment === "object" && subscriptionReusePayment.redirectUrl) {
            window.location.assign(subscriptionReusePayment.redirectUrl);

            return;
        }

        dialog.open({
            html: subscriptionReusePayment,
            options: {
                dialogClass: "ubisoft-pass-choice custom-scrollbar-dialog ubisoft-pass-choice-modal ubisoft-pass-choice-cardreuse-payment-modal keyboard-focus keyboard-focus--yellow",
                width: 940,
                draggable: false,
                close: () => {
                    $cache.html.removeClass("dialog-opened");
                }
            },
            callback: () => {
                $cache.html.addClass("dialog-opened");
                initializeModalEvents();
                // Trigger checkout opening
                $cache.document.trigger("openCheckout:geoRedirect");
            }
        });

        removeFullWidthClass();
        initializeModalEvents();
        handleReactivationModalOpen();
        initProductTooltip();

        if ($("#checkout-conditions").find("input[type='checkbox']").length === 0) {
            $(".btn-reactivate-subscription").prop("disabled", false);
        }

        return;
    }

    if (typeof(Storage) !== "undefined") {
        sessionStorage.setItem("customerStatus", "UbisoftPass");
    }

    dialog.close();
    window.location.assign(Urls.subscriptionBillingStep);
};

/**
 * @function handleSubscriptionSwitchSubmit
 * @param  {type} pid Subscription id to inject into the subscriptionProductID form input field
 * @return {void} Submit the subscription switch form
 */
const handleSubscriptionSwitchSubmit = pid => {
    const subscriptionSwitchForm = document.getElementById("pdp-subscription-switch");
    const formActionURL = subscriptionSwitchForm?.attributes?.action?.value;

    if (!subscriptionSwitchForm || !formActionURL) {
        return;
    }

    if (!formActionURL) {
        return;
    }

    const urlParameters = new URLSearchParams(formActionURL);
    const subscriptionIdKey = "subscriptionProductID";

    if (urlParameters.get(subscriptionIdKey) === pid) {
        // Submit form
        subscriptionSwitchForm.submit();

        return;
    }

    urlParameters.set(subscriptionIdKey, pid);

    subscriptionSwitchForm.setAttribute("action", decodeURIComponent(urlParameters.toString()));

    // Submit form
    subscriptionSwitchForm.submit();
};

/**
 * @function
 * @description collect payment data and generate the ddc iframe
 */
const checkfor3ds = () => {
    let data = {};

    data.BINNumber = $(".payment-method-item.selected").attr("data-bin-number");
    data.PaymentToken = $(".payment-method-item.selected").attr("data-payment-token");
    threedsv2.checkfor3ds(data);
};

const initSubscriptionEvents = () => {
    initializeCache();
    initializeEvents();
    initializeModalEvents();

    if (pageContext && (pageContext.ns === "subscription" || pageContext.ns === "ubisoftpasssubscription" || pageContext.ns === "universalcatubisoftplus")) {
        initVIdeoDialog();
        initIbexPDP();
        initButtons();
        initGameListEvents();
    }
};

export { initSubscriptionEvents };
