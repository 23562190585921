import "jquery-ui/ui/widgets/tabs";
import { util } from "../../util";
import { ajaxLoad } from "../../ajax";

var $cache = {};

var initCache = function () {
    $cache.tabsContainer = $(".product-detail-tabs");
    $cache.productThumb = $(".product-tab-switch");
    $cache.tabsContentSection = $(".content-wrapper");
    $cache.mediaSectionThumb = $(".medias-section-thumb");
    $cache.mediaSectionAccordionThumb = $(".media-section-accordion-thumb");
    $cache.summarySectionThumb = $(".summary-section-thumb");
    $cache.summarySectionAccordionThumb = $(".summary-section-accordion-thumb");
};

var enableTabs = function () {
    if ($cache.tabsContainer.hasClass("ui-accordion")) {
        $cache.tabsContainer.accordion("destroy");
    }

    $cache.tabsContainer.tabs();
    openDefaultTab($cache.tabsContainer.find(".ui-tabs-anchor"), "tab");
};

var eventListeners = function () {
    $("body").on("click", ".increment-quantity", incrementQuantity);
    $("body").on("click", ".decrement-quantity", decrementQuantity);

    $cache.mediaSectionThumb.on("click", function () {
        setTimeout(function () {
            $(window).trigger("openMediaSection");
        }, 200);
    });

    $cache.mediaSectionAccordionThumb.on("click", function () {
        setTimeout(function () {
            $(window).trigger("openMediaSection");
        }, 400);
    });

    $cache.summarySectionThumb.on("click", function () {
        $(window).trigger("openSummarySection");
    });

    $cache.summarySectionAccordionThumb.on("click", function () {
        $(window).trigger("openSummarySection");
    });

    if ($(".app_uplay").length) {
        // Cache selectors
        var lastId,
            topMenu = $(".product-section-nav"),
            topMenuHeight = topMenu.outerHeight() + 15,
            // All list items
            menuItems = topMenu.find("a:not(.button)"),
            // Anchors corresponding to menu items
            scrollItems = menuItems.map(function () {
                var item = $($(this).attr("href"));

                if (item.length) {
                    return item;
                }
            });

        // Bind click handler to menu items
        // so we can get a fancy scroll animation
        menuItems.click(function (e) {
            var href = $(this).attr("href"),
                offsetTop = href === "#" ? 0 : $(href).offset().top - topMenuHeight + 1;

            e.preventDefault();

            $("html, body").stop().animate({
                scrollTop: offsetTop
            }, 300);
        });

        // Bind to scroll
        $(window).scroll(function () {
            var stickyNav = $(".product-section-nav");
            var stickyNavWrapper = stickyNav.parent();
            var windowScrollTop = $(window).scrollTop();

            if (stickyNav !== "undefined" && stickyNav.length) {
                if (windowScrollTop > stickyNavWrapper.offset().top) {
                    stickyNavWrapper.addClass("product-section-sticky");
                } else {
                    stickyNavWrapper.removeClass("product-section-sticky");
                }
            }

            // Get container scroll position
            var fromTop = $(this).scrollTop() + topMenuHeight;

            // Get id of current scroll item
            var cur = scrollItems.map(function () {
                /**
                 * A variable to store current element distance from top of window
                 * @var {number} currentElementPosition
                 */
                var currentElementPosition = $(this).offset().top;
                /**
                 * Reduce currentElementPosition to meet the requiremnts (Change active link after 90 percent of scroll)
                 * @var {number} currentElementPosition
                 */
                var neededElementHeight = parseInt(currentElementPosition * 0.9, 10);

                if (neededElementHeight < fromTop)
                    return this;
            });

            // Get the id of the current element
            cur = cur[cur.length - 1];

            var id = cur && cur.length ? cur[0].id : "";

            if (lastId !== id) {
                lastId = id;
                // Set/remove active class
                menuItems.removeClass("active");
                $("#" + id + "_link").addClass("active");
            }
        });
    }
};

/*
* setTabsPriority functions is setting priority of the PDP tab to be selected by default on the page
* The tab with lowest priority set in tabPriorityList will be selected by default
*/
var setTabsPriority = function () {
    var tabPriorityList = [
        {
            selector: ".summary-section-thumb, .summary-section-accordion-thumb",
            priority: Resources.IS_NCSA ? 1 : 3
        }, {
            selector: ".compare-section-thumb, .compare-section-accordion-thumb",
            priority: 2
        }, {
            selector: ".medias-section-thumb, .media-section-accordion-thumb",
            priority: 6
        }, {
            selector: ".content-section-thumb, .content-section-accordion-thumb",
            priority: Resources.IS_NCSA ? 3 : 1
        }, {
            selector: ".system-requirements-section-thumb, .requirements-section-accordion-thumb",
            priority: 4
        }, {
            selector: ".key-features-section-thumb, .key-features-section-accordion-thumb",
            priority: 5
        }
    ];

    $.each(tabPriorityList, function (key, tab) {
        $(tab.selector).attr("data-tab-priority", tab.priority);
    });
};

/*
* openDefaultTab will activate PDP default tab based on "data-tab-priority" thumb attribute
* set in the setTabsPriority function
*/
var openDefaultTab = function ($thumbs, tabsType) {
    var $activeTabThumb,
        largestTabPriority,
        currentTabPriority;

    $thumbs.each(function (key, thumb) {
        if (!largestTabPriority) {
            largestTabPriority = $(thumb).attr("data-tab-priority");
            $activeTabThumb = $(thumb);
        } else {
            currentTabPriority = $(thumb).attr("data-tab-priority");

            if (largestTabPriority > currentTabPriority) {
                $activeTabThumb = $(thumb);
                largestTabPriority = currentTabPriority;
            }
        }
    });

    switch (tabsType) {
        case "tab":
            if ($activeTabThumb && !$($activeTabThumb.attr("href")).is(":visible")) {
                $activeTabThumb.click();
            }

            break;
        case "accordion":
            if ($activeTabThumb && !$($activeTabThumb.next()).is(":visible")) {
                $activeTabThumb.click();
            }

            break;
    }
};

var incrementQuantity = function () {
    var value = parseInt($(".quantity input").val(), 10);

    if (!value) {
        $(".quantity input").val(1);
    } else {
        $(".quantity input").val(value + 1);
    }
};

var decrementQuantity = function () {
    var value = parseInt($(".quantity input").val(), 10);

    if (!value) {
        $(".quantity input").val(1);
    } else {
        if (value > 1) {
            $(".quantity input").val(value - 1);
        }
    }
};

var initDOM = function () {
    setTabsPriority();
    enableTabs();
};

var pdpNavInit = function () {
    initCache();
    initDOM();
    eventListeners();
};

/**
 * @description loads product's navigation
 **/
function initProductNav() {
    var $pidInput = $('.pdpForm input[name="pid"]').last(),
        $navContainer = $("#product-nav-container");

    pdpNavInit();

    // if no hash exists, or no pid exists, or nav container does not exist, return
    if (window.location.hash.length <= 1 || $pidInput.length === 0 || $navContainer.length === 0) {
        return;
    }

    var pid = $pidInput.val(),
        hash = window.location.hash.substr(1),
        url = util.appendParamToURL(Urls.productNav + "?" + hash, "pid", pid);

    ajaxLoad({
        url: url,
        target: $navContainer
    });
}

export { initProductNav };
