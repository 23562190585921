const EBANX = window.EBANX || {};
const ebanx = {
    init: function () {
        /**
         * @function
         * @description Initialization EBANX module
         */
        if (!$.isEmptyObject(EBANX)) {
            EBANX.config.setMode(SitePreferences.EBANX_MODE);
            EBANX.config.setPublishableKey(SitePreferences.EBANX_PUBLIC_INTEGRATION_KEY);
            EBANX.config.setCountry(GeoRestrictedResources.GEO_USER_COUNTRY_CODE ? GeoRestrictedResources.GEO_USER_COUNTRY_CODE.toLowerCase() : "");
        }
    },

    /**
     * @function
     * @description Returns EBANX credit card data
     */
    getEbanxCCData: function () {
        let ebanxCCData = null;
        const $paymentMethodOptions = $(".payment-method-options");
        const $selectedPaymentMethod = $paymentMethodOptions ? $paymentMethodOptions.find(":checked") : null;
        const $paymentMethodContainer = $(".payment-method-container");

        if ($selectedPaymentMethod && $paymentMethodContainer) {
            const $creditCardNumberFormField = $paymentMethodContainer.find(".cc-number");
            const creditCardNumber = $creditCardNumberFormField.val() ? $creditCardNumberFormField.val().replace(/ /g, "") : "";
            const holderName = $paymentMethodContainer.find(".cc-owner").val();
            const expirationMonth = $paymentMethodContainer.find('[name$="_month"]').val();
            const expirationYear = $paymentMethodContainer.find('[name$="_year"]').val();
            const cardCvv = $paymentMethodContainer.find('input[name*="_cvn"]').val();

            if (creditCardNumber && holderName && expirationMonth && expirationYear && cardCvv) {
                ebanxCCData = {
                    card_number: creditCardNumber,
                    card_name: holderName,
                    card_due_date: expirationMonth + "/" + expirationYear,
                    card_cvv: cardCvv
                };
            }
        }

        return ebanxCCData;
    },

    /**
     * @function
     * @description Masks EBANX credit card number and set it to payment form
     */
    processEbanxCreditCard: function (token) {
        const $creditCard = $(".payment-method-container .credit-details");

        if (token && $creditCard.length) {
            $creditCard.find('input[name$="creditCard_paymentToken"]').val(token);

            const $creditCardNumberFormField = $creditCard.find(".cc-number");
            let creditCardNumber = $creditCardNumberFormField.val() ? $creditCardNumberFormField.val().replace(/ /g, "") : "";

            const $binNumber = $creditCard.find('[name$="_BINNumber"]');

            if ($binNumber.length) {
                $binNumber.val(creditCardNumber.substring(0, 6));
            }

            if (creditCardNumber) {
                let maskedCreditCardNumber = "************" + creditCardNumber.substr(-4);

                $creditCardNumberFormField.val(maskedCreditCardNumber);
            }
        }
    }
};

export { ebanx };
