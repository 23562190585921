module.exports.global = {
    stateAsCountry: ["MP"],
    forceVATDisplay: ["AT", "DE"]
};

module.exports.subscription = {
    displayName: {
        uplayplus: "Ubisoft+",
        ibex: "Rocksmith+"
    },
    recurrencyMapping: {
        monthly: 1,
        quarterly: 3,
        biannually: 6,
        yearly: 12
    },
    ratePlanZoneMapping: {
        ca_ubisoft: "NCSA_CA",
        ca_uplaypc: "NCSA_CA",
        us_ubisoft: "NCSA_US",
        us_uplaypc: "NCSA_US",
        fr_ubisoft: "EMEA",
        fr_uplaypc: "EMEA",
        br_ubisoft: "LATAM_B2B",
        br_uplaypc: "LATAM_B2B",
        sea_ubisoft: "SEA_B2B",
        sea_uplaypc: "SEA_B2B",
        jp_ubisoft: "SEA_B2B",
        jp_uplaypc: "SEA_B2B",
        kr_ubisoft: "SEA_B2B",
        kr_uplaypc: "SEA_B2B",
        uk_ubisoft: "EMEA",
        uk_uplaypc: "EMEA",
        anz_ubisoft: "EMEA",
        anz_uplaypc: "EMEA",
        at_ubisoft: "EMEA",
        at_uplaypc: "EMEA",
        cn_ubisoft: "EMEA",
        cn_uplaypc: "EMEA",
        de_ubisoft: "EMEA",
        de_uplaypc: "EMEA",
        es_ubisoft: "EMEA",
        es_uplaypc: "EMEA",
        eu_ubisoft: "EMEA",
        eu_uplaypc: "EMEA",
        ie_ubisoft: "EMEA",
        ie_uplaypc: "EMEA",
        it_ubisoft: "EMEA",
        it_uplaypc: "EMEA",
        nl_ubisoft: "EMEA",
        nl_uplaypc: "EMEA",
        ru_ubisoft: "EMEA",
        ru_uplaypc: "EMEA",
        tr_ubisoft: "EMEA",
        tr_uplaypc: "EMEA"
    },
    eligibilityConditionsHref: {
        uplayplus: "https://www.ubisoft.com/help/article/000065337",
        ibex: "https://support.ubisoft.com/article/000097081"
    },
    anchorToIbexPlans: "#plans",
    offerMapping: {
        basic: "classics",
        premium: "pcaccess",
        premiumanywhere: "premium"
    }
};

module.exports.PDP_GLOBAL_EVENT_TYPE = {
    PDP_BUYSUB_MODAL_OPEN: "PDP_BUYSUB_MODAL_OPEN",
    PDP_PLAY_ACTION: "PDP_PLAY_ACTION",
    PDP_FREE_OFFER_LOGIN_REDIRECT: "PDP_FREE_OFFER_LOGIN_REDIRECT",
    PDP_SUBSCRIPTION_PLAY_ACTION: "PDP_SUBSCRIPTION_PLAY_ACTION"
};

module.exports.SUBSCRIPTION_EVENT_TYPE = {
    CART_ACTION: "PDP_SUBSCRIPTION_CART_ACTION",
    SWITCH_ACTION: "PDP_SUBSCRIPTION_SWITCH_ACTION"
};

module.exports.SUBSCRIPTION_TYPE = {
    UPLAYPLUS: "uplayplus",
    IBEX: "ibex"
};

module.exports.store = {};
