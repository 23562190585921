/**
 * @function
 * @description Based on product launcher id return the ownership from customer profile ownership list
 * @param {Object} ownershipObj - Customer Ownership Object
 * @param {String} productLauncherID - Product Launcher ID
 * @return {Boolean} - Identifies if customer has ownership or not
 */
const isProductOwnership = (ownershipObj, productLauncherID) => {
    if (!ownershipObj || !productLauncherID || !window.SitePreferences) {
        return false;
    }

    try {
        const applicableProductTypes = ["game", "addon", "package", "bundle"];
        const applicableOwnershipTypes = ["owned", "pre-ordered"];
        const productTypeMapping = JSON.parse(window.SitePreferences.OWNERSHIP_PRODUCT_TYPE_MAPPING);
        const ownershipMapping = JSON.parse(window.SitePreferences.OWNERSHIP_MAPPING);
        const ownershipValue = ownershipObj.find(own => own.id == productLauncherID);
        let applicableProductType = false;
        let applicableOwnershipType = false;

        if (ownershipValue) {
            applicableProductType = applicableProductTypes.indexOf(productTypeMapping[ownershipValue.t]) > -1;
            applicableOwnershipType = applicableOwnershipTypes.indexOf(ownershipMapping[ownershipValue.own]) > -1;
        }

        return applicableProductType && applicableOwnershipType;
    } catch (err) {
        return false;
    }
};

export {isProductOwnership};
