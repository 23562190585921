import "selectboxit/src/javascripts/jquery.selectBoxIt";
import "jquery-validation";
import "../../../static/default/lib/jquery-custom-scrollbar";
import { progressShow } from "../../progress";
import { util } from "../../util";
import { ajaxGetJson, ajaxLoad } from "../../ajax";
import { dialog } from "../../dialog";
import { initFormPrepare } from "./formPrepare";
import { checkBalance } from "../../giftcard";
import { threedsv2 } from "../../threeds2";
import { submitAccertifyData } from "../../accertify";
import { ebanx } from "../../ebanx";
import { initWallet } from "../../wallet";
import { expand } from "../../components/expand";
import { savedAddress } from "../../components/savedAddress";
import { initProductTooltip } from "../../tooltip";
import { xsolla } from "./xsolla";
import { initAgeRestrictionPopup } from "../../components/ageRestrictionPopup";

var $cache = {};
let tosCheckboxVisible;
const isCheckoutRevampContext = $(".l-checkout").length;

var initCache = function () {
    $cache = {
        body: $("body"),
        checkoutForm: $(".checkout-billing"),
        addGiftCert: $("#add-giftcert"),
        giftCertCode: $('input[name$="_giftCertCode"]'),
        addCoupon: $("#add-coupon"),
        couponCode: $('input[name$="_couponCode"]'),
        selectPaymentMethod: $(".payment-method-options"),
        selectedPaymentMethod: $(".payment-method-options").find(":checked").val(),
        savedCC: $(".pay-saved-credit"),
        upcCheckoutSubmit: $(".checkout-last-step"),
        submit: $('button[name$="billing_save"]'),
        otherSelectedPaymentMethod: $(".other-payment-option"),
        otherSelectedPaymentMethodID: $(".other-payment-option").find('input[name$="_selectedPaymentMethodID"]'),
        selectedWalletCompletePaymentID: $(".other-payment-option").find('input[name$="selectedWalletCompletePaymentID"]'),
        selectedPaymentMethodID: $('input[name$="_selectedPaymentMethodID"]'),
        otherSelectedPaymentMethodWalletBackupID: $(".other-payment-option").find($('input[name$="walletBackupPaymentMethods_selectedPaymentMethodID"]')),
        tosCheckboxes: $("#checkout-conditions input[type='checkbox']"),
        dataConsentCheckbox: $(".data-consent input[type='checkbox']"),
        paymentMethodContainer: $(".payment-method-container"),
        xsollaError: $("#xsolla-error-payment"),
        upcCheckoutLegalTerms: $(".js-checkout-conditions")
    };
};

var initEvents = function () {
    $(document).on("change", ".input-radio", function () {
        $(".cvn .input-text").removeClass("required");
        $(".cvn span.error").hide();

        if ($(".button-fancy-large").hasClass("checkout-disabled")) {
            $(".button-fancy-large").removeAttr("disabled").removeClass("checkout-disabled");
        }

        if ($("#boursoramaCardError").length) {
            $("#boursoramaCardError").remove();
        }
    });

    // trigger mps error popup
    if ($("#mps-error").length) {
        dialog.open({
            html: $("#mps-error"),
            options: {
                dialogClass: "mps-error"
            }
        });

        $(".btn-change-payment-method").on("click", function (e) {
            e.preventDefault();
            dialog.close();
        });

        $(".btn-try-again").on("click", function (e) {
            e.preventDefault();
            dialog.close();
            $("#payment_checkout_uplay").trigger("submit");
        });
    }

    // trigger subscription error popup
    if ($("#subscription-error").length) {
        let options = {};

        options = {
            dialogClass: "c-dialog__error subscription-error",
            modal: true
        };

        dialog.open({
            html: $("#subscription-error"),
            options: options,
            callback: function () {
                $cache.body.addClass("l-body__overflowlock");

                $(document).on("click", "[data-dialog-close]", () => {
                    dialog.close();

                    $cache.body.removeClass("l-body__overflowlock");
                });
            }
        });
    }

    // trigger taxation error
    if ($("#taxation-error").length) {
        dialog.open({
            html: $("#taxation-error"),
            options: {
                dialogClass: "taxation-error"
            }
        });

        $(".btn-try-again").on("click", function (e) {
            e.preventDefault();
            dialog.close();
            $("#payment_checkout_uplay").trigger("submit");
        });
    }

    if (SitePreferences.OCP_ENABLE) {
        $(document).on("click", ".pay-saved-credit .creditcard-payment, .paypal-account", function () {
            var $this = $(this);

            setTimeout(function () {
                var $selectedPaymentMethodID = $('input[name$="_selectedPaymentMethodID"]');
                var $paymentInstrumentID = $("#paypalInstrumentID");
                var paymentMethodID = $this.val();
                var $walletTopupCode = $cache.otherSelectedPaymentMethod.find('input[name$="walletTopupPaymentCode"]');

                $selectedPaymentMethodID.val(paymentMethodID);

                if ($walletTopupCode.length) {
                    $walletTopupCode.val(paymentMethodID);
                }

                $paymentInstrumentID.val($this.attr("data-pmid"));
            }, 200);
        }).on("ready", function () {
            var $selectedPaymentMethodID = $('input[name$="_selectedPaymentMethodID"]');
            var $paymentInstrumentID = $("#paypalInstrumentID");
            var $paymentInstrument = $(".pay-saved-credit .creditcard-payment:checked");
            var paymentMethodID = $paymentInstrument.val();

            if ($paymentInstrument.length) {
                $selectedPaymentMethodID.val(paymentMethodID);
                $paymentInstrumentID.val($paymentInstrument.attr("data-pmid"));
            }
        });
    }

    // Only for subscription order
    if ($cache.upcCheckoutSubmit.hasClass("subscription-checkout-button")) {
        // Disable button on page ready
        // Enable it after checking all ToS
        // Run on page ready, wallet event and click on ToS
        const $container = $("#checkout-conditions");

        $(document)
            .on("ready subscriptionCheckbox:reinit", () => {
                tosCheckboxVisible = util.tosCheckboxVisibleCount($container);
                toggleSubmitButton();
            })
            .on("change", $("#checkout-conditions input[type='checkbox']").selector, () => {
                tosCheckboxVisible = util.tosCheckboxVisibleCount($container);
                toggleSubmitButton();
            })
            .on("change", $cache.dataConsentCheckbox.selector, updateConsentValue);
    }

    // Event listener for dialogclose
    $cache.body.on("dialogclose", ".ui-dialog", () => {
        if ($cache.body.hasClass("l-body__overflowlock")) {
            $cache.body.removeClass("l-body__overflowlock");
        }
    });

    // Remove Bonus Item from bundle
    $("[data-component='remove-bonus-item']").on("click", e => {
        e.preventDefault();

        const pid = $(e.currentTarget).data("pid");

        if (pid.length) {
            const url = util.appendParamsToUrl(Urls.removeBonusItem, {
                pid: pid,
                format: "ajax"
            });

            fetch(url, {
                method: "POST"
            })
                .then(response => response.json())
                .then(response => {
                    if (!response.error) {
                        const $checkoutDetails = $(".l-checkout__details");

                        // Remove from summary and plan details
                        $(".c-wrapper--bonus").remove();
                        $(".c-summary__item-bonus:not(.c-summary__custom-plan)").remove();

                        if ($checkoutDetails.length > 0) {
                            $checkoutDetails.removeClass("l-checkout__details--bonus");
                        }
                    }
                });
        }
    });

    // trigger age restriction popup if items are detected by the feature
    if (Resources.ENABLE_AGE_GROUP) {
        initAgeRestrictionPopup();
    }
};

/**
 * @function
 * @description Fills the Credit Card form with the passed data-parameter and clears the former cvn input
 * @param {Object} data The Credit Card data (holder, type, masked number, expiration month/year)
 */
function setCCFields(data) {
    $cache.selectPaymentMethod = $(".payment-method-options");
    $cache.selectedPaymentMethod = $cache.selectPaymentMethod.find(":checked").val();

    var $creditCard = $('[data-method="' + $cache.selectedPaymentMethod + '"]');

    $creditCard.find('input[name$="creditCard_owner"]').val(data.holder).trigger("change");
    $creditCard.find('select[name$="_type"]').val(data.type).trigger("change");
    $creditCard.find('input[name*="_creditCard_number"]').val(data.maskedNumber).trigger("change");
    $creditCard.find('[name$="_month"]').val(data.expirationMonth).trigger("change");
    $creditCard.find('[name$="_year"]').val(data.expirationYear).trigger("change");

    if (!data.showCvvField) {
        $creditCard.find('input[name*="_cvn"]').removeClass("required");
        $creditCard.find("div.cvn").hide();
    } else {
        $creditCard.find('input[name*="_cvn"]').val("").trigger("change");
    }

    if (SitePreferences.ENABLE_3DSV2) {
        // we need the credit card type for the case of stored credit card selection to identify the right payment instrument at profile
        $("input[name$='creditCard_type']").val(data.type);
    }
}

/**
 * @function
 * @description Updates the credit card form with the attributes of a given card
 * @param {String} cardID the credit card ID of a given card
 */
function populateCreditCardForm(cardID) {
    // load card details
    var url = util.appendParamToURL(Urls.billingSelectCC, "creditCardUUID", cardID);

    ajaxGetJson({
        url: url,
        callback: function (data) {
            if (!data) {
                return false;
            }

            setCCFields(data);
        }
    });
}

/**
 * @function
 * @description Updates the credit card form with the attributes of a given card
 * @param {String} cardID the credit card ID of a given card
 */
function populateCheckoutCreditCardForm(cardID, cvn) {
    // load card details
    var url = util.appendParamsToUrl(Urls.billingSelectSavedCC, {
        creditCardUUID: cardID,
        cvn: cvn
    });

    ajaxGetJson({
        url: url,
        callback: function (data) {
            if (!data) {
                return false;
            }

            setCCFields(data);
        }
    });
}

/**
 * @function
 * @description show/hide fields for Brazil(CPF, installment)
 * @param {String} payMethodID,CountryCode
 */
function fieldForBrazil(paymentMethodID, countryCode) {
    if (countryCode == "BR") {
        if (paymentMethodID == "BOLETO-SSL") {
            $("#Payment_Brazil").show();

            var $fieldsSection = $("#Payment_Brazil");

            $fieldsSection.find('input[name$="creditCard_cpf"]').addClass("required");
            $fieldsSection.find('input[name$="creditCard_installments"]').addClass("required");
        } else if (paymentMethodID == "CREDIT_CARD") {
            $("#Payment_Brazil").show();
            $("#Payment_Brazil").children().show();

            var $creditCardSection = $("#Payment_Brazil");

            $creditCardSection.find('input[name$="creditCard_cpf"]').addClass("required");
            $creditCardSection.find('input[name$="creditCard_installments"]').addClass("required");
        } else {
            $("#Payment_Brazil").hide();
        }
    } else {
        $("#Payment_Brazil").hide();
    }
}

/**
 * Sets the default selected country based on the geolocation country
 */
$(document).ready(function () {
    var geolocationCountry = $(".geolocation_country").attr("data-country");
    var checkoutBillingStepBtn = $("button[name='dwfrm_billing_save']");

    if ($(".minilineitem-product").length > 3 && $.isFunction($(".checkout-minilineitems").customScrollbar)) {
        $(".checkout-minilineitems").customScrollbar();
    }

    // if the customer has saved addresses to his account, it will avoid to set the geolocation country for the address form
    if ($("#dwfrm_billing_addressList").length || $("#dwfrm_singleshipping_addressList").length) {
        return;
    }

    if (geolocationCountry && geolocationCountry != null && geolocationCountry != "null") {
        geolocationCountry = geolocationCountry.toLowerCase();

        const $countryList = $("select[id$='_addressFields_country']");

        if ($countryList.length) {
            $countryList.find("option[value='" + geolocationCountry + "']").attr("selected", "selected");
            $countryList.selectBoxIt();
            $countryList.data("selectBox-selectBoxIt").refresh();
        }
    }

    checkoutBillingStepBtn.addClass("checkout-disabled-white").next().show();
    window.setTimeout(setState, 3500);
});

/**
 * Sets the state field from billing form, if the billingAddressObj exist
 */
function setState() {
    var $statesList = $('select[id$="_addressFields_states_state"]');
    var checkoutBillingStepBtn = $("button[name='dwfrm_billing_save']");

    if ($("#billingAddressObj").length > 0 && $("#billingAddressObj").val() != "" && $statesList.length) {
        var $addressObj = JSON.parse($("#billingAddressObj").val());

        if ($addressObj.states_state) {
            $statesList.val($addressObj.states_state);
            $statesList.selectBoxIt();
            $statesList.trigger("change");
        }
    }

    checkoutBillingStepBtn.removeClass("checkout-disabled-white").next().hide();
}

/**
 * @function
 * @description Uses to validate credit card number
 * @param {String} number the Credit Card number that should be validated
 */
function validateCreditCard(number) {
    if (number != "") {
        number = number.replace(/\s/g, "");

        var visa = number.match(/^4[0-9]{12}(?:[0-9]{3})?$/);
        var mastercard = number.match(/^(?:5[1-5][0-9]{2}|222[1-9]|22[3-9][0-9]|2[3-6][0-9]{2}|27[01][0-9]|2720)[0-9]{12}$|^3[47][0-9]{13}$/);
        var amex = number.match(/^3[47][0-9]{13}$/);
        var discover = number.match(/^6(?:011|5[0-9]{2})[0-9]{12}$/);
        var matched;

        // Create matched object
        if (visa) {
            matched = {
                name: "Visa"
            };
        } else if (amex) {
            matched = {
                name: "Amex"
            };
        } else if (mastercard) {
            matched = {
                name: "MasterCard"
            };
        } else if (discover) {
            matched = {
                name: "Discover"
            };
        }

        if (matched) {
            $('select[name$="_type"] option[value=' + matched.name + "]").prop("selected", "selected");
            $("input[name$='creditCard_type']").val(matched.name);

            $(".credit-type span")
                .removeClass()
                .addClass(matched.name.toLowerCase());

            return;
        }
    }
}

/**
 * @function
 * @description Changes the payment method form depending on the passed paymentMethodID
 * @param {String} paymentMethodID the ID of the payment method, to which the payment method form should be changed to
 * @param {Boolean} displayNotification the flag to used to display notification regarding saved CC without token
 * @param {String} retrieveMail Empty string by default - Email value for xsolla email entered by client, get it back during switch method to prefill the field
 * @param {String} selectionIdentifier optional - selection value of the payment to be used in the wallet double order usecase
 */
function updatePaymentMethod(paymentMethodID, displayNotification, retrieveMail, selectionIdentifier) {
    let updatePaymentMethodUrl;

    if (isCheckoutRevampContext) {
        // Radio: Update checked attribute
        $(".c-btn__payment").find("input").removeAttr("checked");
        $("input[data-id='" + paymentMethodID + "']").attr("checked", true);
    }

    if (paymentMethodID.indexOf("EBANX-CC") > -1 || paymentMethodID.indexOf("EBANX-DC") > -1) {
        updatePaymentMethodUrl = Urls.loadEbanxPaymentMethods;
    } else if (paymentMethodID.indexOf("XSOLLA-CC") > -1 || paymentMethodID.indexOf("XSOLLA-PP") > -1) {
        updatePaymentMethodUrl = Urls.loadXsollaPaymentMethods;

        // Enable submit button and hide loader
        $cache.submit.removeClass("checkout-disabled-white").next().hide();

        // Remove class flag for xsolla frame
        $(".payment-method-container").removeClass("xsolla--is-active");

        // Hide xsolla error
        if ($cache.xsollaError.length) {
            $cache.xsollaError.addClass("hide").empty();
        }
    } else {
        updatePaymentMethodUrl = Urls.loadPaymentMethods;
    }

    var isWalletSelected = $(".payment-option.wallet").hasClass("selected");

    $.ajax({
        url: updatePaymentMethodUrl,
        type: "Post",
        data: {
            selectedPaymentMethodId: paymentMethodID,
            isWalletPaymentSelected: isWalletSelected
        },
        success: function (response) {
            initCache();

            if (!response.includes("isWalletPaymentActive")) {
                $(".payment-method-container").html(response);

                $(".cc-name").val("");
                $(".cc-owner").val("");
                $(".cc-number").val("");
                $(".cc-number").attr("maxlength", 16);
                $('.credit-back input[name*="_creditCard_cvn"]').val("");

                $('input[name*="_creditCard_number"]').on("change", function () {
                    validateCreditCard($(this).val());
                });

                util.removeCreditCardExpPastYear();

                if (paymentMethodID.indexOf("XSOLLA") > -1) {
                    // Set email value if existing
                    let xsollaEmail = document.querySelector(".e-xsolla__input");

                    if (retrieveMail !== undefined && retrieveMail !== null) {
                        xsollaEmail.value = retrieveMail;
                    }

                    // Revamp flow: On Payment Method change, Dispatch event (used to now if a payment has been stored)
                    if (window.xsollaFlow === "revamp" && window.paymentMethod !== undefined && window.paymentMethod !== paymentMethodID) {
                        const xsollaPaymentSelection = new CustomEvent("xsolla:switchPayment", {
                            detail: xsollaEmail
                        });

                        document.dispatchEvent(xsollaPaymentSelection);
                    }

                    // store PaymentMethod and "flow"
                    window.paymentMethod = paymentMethodID;
                    window.xsollaFlow = isCheckoutRevampContext ? "revamp" : "regular";

                    // xsolla flow init
                    xsolla.init();
                }

                changeCheckoutSubmitState();

                // select credit card from list
                $(".creditCardList").on("change", function () {
                    var cardUUID = $(this).val();

                    if (!cardUUID) {
                        return;
                    }

                    // remove server side error
                    $(".required.error").removeClass("error");
                    $(".error-message").remove();
                    $cache.selectedPaymentMethod = $(".payment-method-options").find(":checked").val();

                    if ($cache.selectedPaymentMethod == "WorldPay") {
                        if (cardUUID != "") {
                            $(".card-block").show();
                            // $(".save-card-block").hide();
                        } else {
                            $(".card-block").hide();
                            $(".save-card-block").show();
                        }
                    }

                    populateCreditCardForm(cardUUID);

                    var $creditCardSection = $('[data-method="WorldPay"]');

                    $creditCardSection.find('[name$="_cards"]').val("");

                    var $addressForm = $(".address");
                    var addressCountryCode = $('select[id$="_country"]', $addressForm).val();

                    if (paymentMethodID == "WorldPay" && addressCountryCode == "BR") {
                        $("#Payment_Brazil").show();
                        $creditCardSection.find('input[name$="creditCard_cpf"]').addClass("required");
                        $creditCardSection.find('input[name$="creditCard_installments"]').addClass("required");
                    } else if (paymentMethodID == "CREDIT_CARD" && addressCountryCode == "BR") {
                        $creditCardSection = $('[data-method="CREDIT_CARD"]');
                        $creditCardSection.find('input[name$="creditCard_cpf"]').addClass("required");
                        $creditCardSection.find('input[name$="creditCard_installments"]').addClass("required");
                    }
                });

                if (paymentMethodID == "WorldPay") {
                    var $creditCard = $('[data-method="WorldPay"]');

                    $creditCard.find("input,select").val("");
                    $creditCard.find('input[name$="creditCard_owner"]').removeClass("required");
                    $creditCard.find('select[name$="_type"]').removeClass("required");
                    $creditCard.find('input[name*="_creditCard_number"]').removeClass("required");
                    $creditCard.find('[name$="_month"]').removeClass("required");
                    $creditCard.find('[name$="_year"]').removeClass("required");
                    $creditCard.find('input[name$="_cvn"]').removeClass("required");
                    $(".creditCardList").val("");
                    $creditCard.find('input[name$="_saveCard"]').val("true");
                    $(".card-block").hide();
                    $(".save-card-block").show();
                }

                var $form = $(".address");
                var countryCode = $('select[id$="_country"]', $form).val();

                fieldForBrazil(paymentMethodID, countryCode);

                // select prefered credit card from list
                $("#preferedCreditCardList").on("change", function (e) {
                    e.preventDefault();

                    var $preferedCreditCard = $('[data-method="WorldPay"]');

                    $preferedCreditCard.find('input[name$="creditCard_owner"]').removeClass("required");
                    $preferedCreditCard.find('input[name$="creditCard_owner"]').val("");
                    $preferedCreditCard.find('select[name$="_type"]').removeClass("required");
                    $preferedCreditCard.find('input[name*="_creditCard_number"]').removeClass("required");
                    $preferedCreditCard.find('input[name*="_creditCard_number"]').val("");
                    $preferedCreditCard.find('[name$="_month"]').removeClass("required");
                    $preferedCreditCard.find('[name$="_year"]').removeClass("required");
                    $preferedCreditCard.find('input[name$="_cvn"]').removeClass("required");
                    $(".creditCardList").val("");
                    $preferedCreditCard.find('input[name$="_saveCard"]').val("true");
                    $(".card-block").hide();
                    $(".save-card-block").show();
                    $("#Payment_Brazil").hide();
                });

                $("#clearpaymentform").on("click", function (e) {
                    e.preventDefault();

                    var $clearCreditCard = $('[data-method="WorldPay"]');

                    $clearCreditCard.find("input,select").val("");
                    $clearCreditCard.find('input[name$="creditCard_owner"]').removeClass("required");
                    $clearCreditCard.find('select[name$="_type"]').removeClass("required");
                    $clearCreditCard.find('input[name*="_creditCard_number"]').removeClass("required");
                    $clearCreditCard.find('[name$="_month"]').removeClass("required");
                    $clearCreditCard.find('[name$="_year"]').removeClass("required");
                    $clearCreditCard.find('input[name$="_cvn"]').removeClass("required");
                    $(".creditCardList").val("");
                    $clearCreditCard.find('input[name$="_saveCard"]').val("true");
                    $(".card-block").hide();
                    $(".save-card-block").show();
                    $("#Payment_Brazil").hide();
                });

                $("select").each((i, el) => {
                    const $this = $(el);
                    const selectorID = $this.attr("id");

                    if (selectorID && (selectorID.endsWith("_creditCard_expiration_year") || selectorID.endsWith("_creditCard_expiration_month"))) {
                        $this.selectBoxIt({
                            showFirstOption: false
                        });
                    } else {
                        $this.selectBoxIt();
                    }
                });

                $(".credit-back").on("click", ".tooltip", function (e) {
                    e.preventDefault();
                    dialog.open({
                        url: $(e.target).attr("href"),
                        options: {
                            width: 700,
                            dialogClass: "card-tooltip"
                        }
                    });
                });

                $("input, select").on("change", function () {
                    $(this).parent().siblings(".error-message").hide();
                });

                if (!Resources.SHOW_SAVE_CC) {
                    $("#dwfrm_billing_paymentMethods_creditCard_saveCard").attr("unchecked", "unchecked");
                }

                const selectedMethod = $(".c-btn__payment").find("input[checked]");

                $(".c-btn__payment").removeClass("c-btn__payment--selected");
                selectedMethod.parent().addClass("c-btn__payment--selected");

                // Tooltip: Move and init
                const $fieldTooltip = $(".c-field__tooltip");
                const $fieldLabel = $fieldTooltip.parent().find("label span");

                if ($fieldTooltip.length && $fieldLabel.length) {
                    $fieldTooltip.detach().insertAfter($fieldLabel);

                    initProductTooltip();
                }

                initEvents();

                if (paymentMethodID == "CREDIT_CARD" && displayNotification) {
                    $(".invalid-saved-cc-notication").removeClass("hide");
                }
            }
        }
    });

    var isWalletSufficient = isWalletSelected && ($(".payment-option.wallet").data("iswalletsufficient") || $(".opc-payment .order-subtotal").data("wallet-sufficient"));
    var selectedPaymentInput;

    // if wallet balance is not sufficient set second selected payment
    if ($cache.otherSelectedPaymentMethod.length && $cache.otherSelectedPaymentMethodID.length) {
        if (isWalletSelected && (!isWalletSufficient || $(".wallet-subscription").length)) {
            if (paymentMethodID != "WALLET_PAYMENT") {
                // set second selected payment as the payment of the wallet order
                if ($cache.selectedWalletCompletePaymentID.length) {
                    $cache.selectedWalletCompletePaymentID.val(paymentMethodID);
                    $cache.selectedWalletCompletePaymentID.removeAttr("checked");
                }

                const $walletTopupCode = $cache.otherSelectedPaymentMethod.find('input[name$="walletTopupPaymentCode"]');

                if ($walletTopupCode.length && selectionIdentifier) {
                    $walletTopupCode.val(selectionIdentifier);
                }

                if ($cache.otherSelectedPaymentMethodWalletBackupID.length) {
                    $cache.otherSelectedPaymentMethodWalletBackupID.val(paymentMethodID);
                    $cache.otherSelectedPaymentMethodWalletBackupID.removeAttr("checked");
                }

                selectedPaymentInput = $cache.otherSelectedPaymentMethod.find($("input[value=" + paymentMethodID + "]"));
            } else {
                // if the payment is wallet then set it to selectedPayment
                $cache.selectedPaymentMethodID.val(paymentMethodID);
                $cache.selectedPaymentMethodID.removeAttr("checked");
                selectedPaymentInput = $("input[value=" + paymentMethodID + "]");
            }
        } else if (!isWalletSelected) {
            // if wallet is not selected and the form is opened for other payment options, set the paymentID to otherSelectedPaymentMethodID
            $cache.otherSelectedPaymentMethodID.val(paymentMethodID);
            $cache.otherSelectedPaymentMethodID.removeAttr("checked");
            selectedPaymentInput = $cache.otherSelectedPaymentMethod.find($("input[value=" + paymentMethodID + "]"));
        } else {
            // case of wallet is selected and sufficient
            $cache.selectedPaymentMethodID.val(paymentMethodID);
            $cache.selectedPaymentMethodID.removeAttr("checked");
            selectedPaymentInput = $("input[value=" + paymentMethodID + "]");
        }
    } else {
        $('input[name$="_selectedPaymentMethodID"]').val(paymentMethodID);
        $('input[name$="_selectedPaymentMethodID"]').removeAttr("checked");
        selectedPaymentInput = $("input[value=" + paymentMethodID + "]");
    }

    if (selectedPaymentInput && selectedPaymentInput.length > 0) {
        selectedPaymentInput.prop("checked", "checked");
    }
}

/**
 * @function
 * @description Update encryptedData credit card form field with value received from worldpay if client side encryption enabled
 */
function setCCEncryptedData($cc) {
    $cc.find('[name$="_encryptedData"]').val("");
    $cc.find('[name$="_maskedCardNumber"]').val("");

    if (!window.SitePreferences.WP_ENABLE_CLIENT_SIDE_ENCRYPTION) {
        return;
    }

    var $creditCardNumber = $cc.find('input[name*="_creditCard_number"]');

    var data = {
        cvc: $cc.find('input[name*="_cvn"]').val(),
        cardHolderName: $cc.find('input[name$="creditCard_owner"]').val(),
        cardNumber: $creditCardNumber.length > 0 ? $creditCardNumber.val().replace(/\s/g, "") : "",
        expiryMonth: $cc.find('[name$="_month"]').val() < 10 ? "0" + $cc.find('[name$="_month"]').val() : $cc.find('[name$="_month"]').val(),
        expiryYear: $cc.find('[name$="_year"]').val()
    };

    if (data.cardNumber) {
        $cc.find('[name$="_BINNumber"]').val(data.cardNumber.substring(0, 6));
    }

    if (typeof Worldpay != "undefined") {
        Worldpay.setPublicKey(window.SitePreferences.WP_CSE_PUBLIC_KEY);

        var encryptedData = Worldpay.encrypt(data);

        if (encryptedData) {
            $cc.find('[name$="_BINNumber"]').val(data.cardNumber.substring(0, 6));
            $cc.find('[name$="_encryptedData"]').val(encryptedData);
            $cc.find('[name$="_maskedCardNumber"]').val(getMaskedCreditCardNumber(data.cardNumber));

            $cc.find('input[name*="_creditCard_number"]').removeAttr("name");
            $cc.find('input[name*="_cvn"]').removeAttr("name");
        }
    }
}

/**
 * @function
 * @description Returns masked credit card number
 */
function getMaskedCreditCardNumber(creditCardNumber) {
    var unmaskedCreditCardSection = creditCardNumber.slice(-4),
        maskedCreditCardSection = "";

    for (var i = (creditCardNumber.length) - 4; i > 0; i--) {
        maskedCreditCardSection += "*";
    }

    return maskedCreditCardSection + unmaskedCreditCardSection;
}

function populateApmMethods() {
    // load APM details
    var countryCode = $('select[id$="_country"]').val();

    if (countryCode == undefined || countryCode == "") {
        return;
    }

    var url = Urls.apmLookUp + "?billingCountry=" + countryCode;

    $.ajax({
        url: url,
        method: "POST",
        dataType: "json"
    }).done(function (data) {
        var result = new Array();

        for (var i = 0; i < data.paymentMethod.length; i++) {
            result.push(data.paymentMethod[i].apm);
        }

        var paymentMethods = $(".payment-method-options").find("input[type='radio']");

        $(paymentMethods).each(function (index, value) {
            if ($.inArray($(value).val(), result) == -1 && $(value).val() != "CREDIT_CARD" && $(value).val() != "WorldPay") {
                $(value).parent().parent("div").hide();
            } else {
                $(value).parent().parent("div").show();
            }
        });
    });
}

/**
 * @helper function for WorldPay integration
 */
function updateElvValue(elvValue) {
    $(".input-radio-elv").removeAttr("checked");
    $("input[value=" + elvValue + "]").attr("checked", "checked");
}

/**
 * @function
 * @description Make an AJAX request to the server to set parameters
 * for digital shipping and update cart summary with returned taxation value.
 * @param {String} customAction Identifies custom action used for tax calculation e.g precalculateWalletRewards or clickAtOtherBrick.
 */
function updateCartWithDigitalTax(customAction) {
    var precalculateWalletRewards = customAction && customAction === "precalculateWalletRewards";
    var url = getShippingMethodURL(Urls.updateCartTax, {
        customAction: customAction
    }, precalculateWalletRewards);

    const $walletBrick = $(".payment-option.wallet");
    const $opcPaymentItems = $(".js-payment-summary-item");

    // add a class for the wallet brick to update the wallet section only after the taxes are updated
    $walletBrick.addClass("pending-taxes");

    // add loading for summary section
    $opcPaymentItems.addClass("loader-section");

    ajaxLoad({
        url: url,
        callback: function (data) {
            $opcPaymentItems.removeClass("loader-section");
            $walletBrick.removeClass("pending-taxes");

            if (!customAction || (customAction !== "precalculateWalletRewards" && customAction !== "clickAtOtherBrick")) {
                updateSummary();
            } else {
                $opcPaymentItems.each((index, item) => {
                    const walletTotalTable = $(data).filter(".js-payment-summary-item")[index];

                    if (walletTotalTable) {
                        $(item).replaceWith(walletTotalTable);
                    }
                });

                // make sure the wallet is sufficient after updating of the totals
                var isWalletSufficient = $(".opc-payment .order-subtotal").data("wallet-sufficient");

                if (isWalletSufficient) {
                    $(".wallet-checkout-topup-wrapper").hide();
                }
            }

            initWallet();
        },
        onCancel: function () {
            // if the ajax call is triggered multiple times, the call is canceled
            $walletBrick.removeClass("pending-taxes");
            $opcPaymentItems.removeClass("loader-section");
        }
    });
}

/**
 * @function
 * @description Helper method which constructs a URL for an AJAX request using the
 * entered address information as URL request parameters.
 * @param {String} url URL of the ajax request.
 * @param {Array} extraParams Array of extra parameters for the ajax request..
 * @param {Boolean} precalculateWalletRewards Identifies if call is initiated from the click at wallet brick.
 */
function getShippingMethodURL(url, extraParams, skipAddress) {
    var $form = $(".address");
    var params = {};

    if (!skipAddress) {
        params = {
            address1: $form.find('input[name$="_address1"]').val(),
            address2: $form.find('input[name$="_address2"]').val(),
            countryCode: $form.find('select[id$="_country"]').val(),
            stateCode: $form.find('select[id$="_state"]').val(),
            postalCode: $form.find('input[name$="_postal"]').val(),
            city: $form.find('input[name$="_city"]').val()
        };
    }

    return util.appendParamsToUrl(url, $.extend(params, extraParams));
}

/**
 * @function
 * @description updates the order summary based on a possibly recalculated basket after a shipping promotion has been applied
 */
function updateSummary() {
    var $summary = $("#secondary.summary");

    // indicate progress
    progressShow($summary);

    // load the updated summary area
    $summary.load(Urls.summaryRefreshURL, function () {
        // hide edit shipping method link
        $summary.fadeIn("fast");
        orderSwitch();
        $summary.find(".checkout-minilineitems .minishipment .header a").hide();
        $summary.find(".order-totals-table .order-shipping .label a").hide();

        if ($(".minilineitem-product").length > 3 && $.isFunction($(".checkout-minilineitems").customScrollbar)) {
            $(".checkout-minilineitems").customScrollbar();
        }
    });
}

/**
 * @function
 * @description Completes the billing address in case the customer is returned to the billing by some error or he chooses to return to the billing page
 */
function completeBillingAddress() {
    if ($("#billingAddressObj").length > 0) {
        var $addressObj = $("#billingAddressObj").val();

        if ($addressObj != null && $addressObj != "null" && $addressObj != "") {
            var billingAddressObj = JSON.parse($addressObj),
                keys = Object.keys(billingAddressObj);

            if (keys != null && keys.length > 0) {
                for (var i = 0; i < keys.length; i++) {
                    var $addressField = $("#dwfrm_billing_billingAddress_addressFields_" + keys[i]);

                    if ($addressField.length > 0) {
                        if ($addressField.is("select")) {
                            if (billingAddressObj[keys[i]]) {
                                $addressField.val(billingAddressObj[keys[i]]);
                            }

                            var statesSelectInit = $addressField.selectBoxIt();

                            statesSelectInit.selectBoxIt("selectOption", $addressField[0].selectedIndex);
                        } else {
                            $addressField.val(billingAddressObj[keys[i]]);
                        }
                    }
                }

                if (isCheckoutRevampContext && $cache.checkoutForm.length > 0) {
                    $cache.checkoutForm.valid();
                }
            }
        }
    }
}

function orderSwitch() {
    $("#order-summary-switch").on("click", function () {
        var $this = $(this),
            $summary = $(".order-summary");

        $this.toggleClass("expanded");

        if ($this.hasClass("expanded")) {
            $summary.show();

            if ($(".minilineitem-product").length > 3 && $.isFunction($(".checkout-minilineitems").customScrollbar)) {
                $(".checkout-minilineitems").customScrollbar();
            }
        } else {
            $summary.hide();
        }
    });
}

/**
 * @function
 * @description Enables/Disables the legal section and the submit button for products non subscription orders
 */
function changeCheckoutSubmitState() {
    if (!$cache.checkoutForm.length || $cache.upcCheckoutSubmit.hasClass("subscription-checkout-button")) {
        return;
    }

    const $paymentMethods = $cache.checkoutForm.find(".payment-option");

    if (!$paymentMethods.length) {
        return;
    }

    const $selectedPaymentMethod = $paymentMethods.filter(".selected");
    const $opcSubtotal = $(".opc-payment .order-subtotal");

    // true if there's no payment method selected
    let disableFinalActions = !$selectedPaymentMethod.length;

    // if wallet is selected
    if (!disableFinalActions && $selectedPaymentMethod.hasClass("wallet")
        // wallet is not sufficient
        && ($selectedPaymentMethod.attr("data-iswalletsufficient") === "false" && !$opcSubtotal.data("wallet-sufficient"))
        // and threshold not selected
        && $selectedPaymentMethod.attr("data-isthresholdselected") === "false") {
        disableFinalActions = true;
    }

    // enable submit button
    $cache.upcCheckoutSubmit.prop("disabled", disableFinalActions)
        .toggleClass("disabled", disableFinalActions);

    // enable legal terms
    $cache.upcCheckoutLegalTerms.toggleClass("disabled", disableFinalActions);
}

/**
 * Hide/display warning message for Paysafecard if it is selected as payment method
 */
$(document).on("click", ".payment-method-options .input-radio, .pay-saved-credit .input-radio", function () {
    if ($(this).val() == Resources.PAYMENT_PAYSAFECARD_ID) {
        $(".paysafe_warning_message").removeClass("visually-hidden");
    } else {
        $(".paysafe_warning_message").addClass("visually-hidden");
    }
});

/**
 * @function
 * @description Function used to collect the card data at BD side and to generate the ddc iframe for uplay site
 * @param {jQuery} ccData Credit Card data DOM element
 */
function checkfor3ds(ccData) {
    var data = {};

    data.BINNumber = ccData.find('[name$="_BINNumber"]').val();
    data.creditCardType = ccData.find('[name$="_creditCard_type"]').val();
    data.maskedNumber = ccData.find('[name$="_maskedCardNumber"]').val();
    data.ccNumber = ccData.find('[name$="_creditCard_number"]').val();
    data.encryptedData = ccData.find('[name$="_encryptedData"]').val();
    threedsv2.checkfor3ds(data);
}

/**
 * @function
 * @description Function used for Boursorama Credit Card check
 */
function BoursoramaCheck() {
    var creditCardNumber = $(".cc-number").val(),
        boursoramaRegExp = new RegExp("^49765306[0-9]{8}$"),
        isBoursorama = boursoramaRegExp.test(creditCardNumber),
        submitButton = $(".button-fancy-large"),
        // load card details
        url = util.appendParamToURL(Urls.boursoramaCheck, "ajaxresponse", true);

    if (isBoursorama) {
        ajaxGetJson({
            url: url,
            callback: function (data) {
                // in the callback response process data json sent from the check
                if (!data) {
                    return false;
                }

                if (data.block) {
                    // if the CC was identified and the basket contains restrictions than shop error
                    // block the checkout and show the error message
                    if (!submitButton.hasClass("checkout-disabled")) {
                        $(".button-fancy-large").attr("disabled", "true").addClass("checkout-disabled");
                        $(".cc-number").addClass("boursorama-error").after('<span class="error boursorama-error">' + data.message + "</span>");
                    }
                }
            }
        });
    } else if (submitButton.hasClass("checkout-disabled")) {
        $(".button-fancy-large").removeAttr("disabled").removeClass("checkout-disabled");
        $(".cc-number").removeClass("boursorama-error");
        $(".boursorama-error").remove();
        $("#boursoramaCardError").remove();
    }
}

/**
 * @function
 * @description checks terms and conditions checkbox
 * @return {Boolean} identifies is terms and conditions checkbox is not checked
 */
const checkTos = () => {
    let isTOSNotChecked = false;

    $("#checkout-conditions input[type='checkbox']").each((index, element) => {
        const $element = $(element);

        if (!$element.prop("checked") && !$element.parent().hasClass("hide")) {
            isTOSNotChecked = true;
        }

        // Data-consent is not mandatory, set value to false to allow form submit.
        if (!$element.prop("checked") && $element.parent().hasClass("data-consent")) {
            isTOSNotChecked = false;
        }
    });

    return isTOSNotChecked;
};

/**
 * @function
 * @description Check visibilty of ToS checkboxes, and fill array
 */
const toggleSubmitButton = () => {
    const $tosCheckboxValidated = $("#checkout-conditions input[type='checkbox']").filter(":checked").not("#data-consent");

    if ($tosCheckboxValidated.length === tosCheckboxVisible.length) {
        $cache.upcCheckoutSubmit.attr("disabled", false);
    } else {
        $cache.upcCheckoutSubmit.prop("disabled", true);
    }
};

/**
 * @function
 * @description Update value of hidden input, in order to track "data consent" checkbox (Boolean)
 * @param {Event} e - event
 * @listens $cache.dataConsentCheckbox:change
 */
const updateConsentValue = e => {
    const inputValue = e.currentTarget.checked;
    const $dataConsentInput = $("input[id*=ibexDataConsent]");

    $dataConsentInput.prop("checked", inputValue);
};

/**
 * @function
 * @description loads billing address, Gift Certificates, Coupon and Payment methods
 */
function initBilling() {
    initCache();
    initEvents();
    ebanx.init();
    expand.init();
    savedAddress.init();

    initFormPrepare({
        formSelector: 'form[id$="billing"]',
        continueSelector: '[name$="billing_save"]'
    });

    // Select cc payment method if there are no saved credit cards
    if (!$(".app_uplay .pay-saved-credit").length && $(".app_uplay .payment-method-options").length && !$("#mps-error").length) {
        $('.payment-method-options input[value="CREDIT_CARD"]').click();
    }

    if ($('input[name$="_addressFields_postal"]').parent().siblings(".error-message").length > 0) {
        $('[name$="billing_save"]').attr("disabled", true);
    }

    $(document).on("submit", 'form[id$="billing"]', function () {
        $(this).find(".c-btn__submitbilling").addClass("loader-section");
    });

    $(document).on("change", ".cc-number", function () {
        // Boursurama check for France UPC/web sites
        if (Resources.IS_BOURSORAMA) {
            // Init of boursorama card check
            BoursoramaCheck();
        }
    });

    if (SitePreferences.ENABLE_3DSV2) {
        threedsv2.initializeThreeDSEvents();
    }

    // check if enabled and send device fingerprinting data to accertify
    if (SitePreferences.ENABLE_DEVICE_FINGERPRINTING) {
        let paymentCheckoutUplayForm = document.querySelector("#payment_checkout_uplay");

        if (paymentCheckoutUplayForm) {
            let basketID = paymentCheckoutUplayForm.dataset.basketid;

            if (basketID) {
                submitAccertifyData(basketID);
            }
        }
    }

    // Update the Mercury custom payment methods
    $cache.checkoutForm.on("change", 'select[name$="billing_billingAddress_addressFields_country"]', function (e) {
        e.preventDefault();

        var countryCode = GeoRestrictedResources.GEO_USER_COUNTRY_CODE ? GeoRestrictedResources.GEO_USER_COUNTRY_CODE.toUpperCase() : $(this).val().toUpperCase();

        $cache.selectedPaymentMethodID = $(".payment-method-options").find(":checked").val();
        ajaxLoad({
            url: util.appendParamToURL(Urls.blbPSPRankings, "countryCode", countryCode),
            callback: function (data) {
                initCache();

                if (data) {
                    $('.payment-method[data-method="Custom"]').html(data);
                    $("input[value='" + $cache.selectedPaymentMethodID + "']").prop("checked", "checked");
                }

                $(".payment-method-container").html("");

                $(".payment-method-options").on("click", 'input[type="radio"]', function () {
                    updatePaymentMethod($(this).data("id"));
                });
            }
        });
    });

    // On PM selection, check and pass xsolla email
    $cache.selectPaymentMethod.on("click", 'input[type="radio"]', function () {
        let $xsollaInput = $cache.paymentMethodContainer.find(".e-xsolla__input");
        let xsollaEmail = $xsollaInput.length ? $xsollaInput.val() : "";

        updatePaymentMethod($(this).data("id"), undefined, xsollaEmail, $(this).val());
    });

    // On init, pass default value based on DOM
    if ($('input[data-id="CREDIT_CARD"]').prop("checked")) {
        updatePaymentMethod("CREDIT_CARD");
    }

    if ($('input[data-id="EBANX-CC"]').prop("checked")) {
        updatePaymentMethod("EBANX-CC");
    }

    if ($('input[data-id="EBANX-DC"]').prop("checked")) {
        updatePaymentMethod("EBANX-DC");
    }

    if ($('input[data-id="XSOLLA-CC"]').prop("checked")) {
        updatePaymentMethod("XSOLLA-CC");
    }

    if ($('input[data-id="XSOLLA-PP"]').prop("checked")) {
        updatePaymentMethod("XSOLLA-PP");
    }

    // Removed as recommended in WorldPay integration
    // // select credit card from list
    // $('#creditCardList').on('change', function () {
    //     var cardUUID = $(this).val();
    //     if (!cardUUID) {return;}
    //     populateCreditCardForm(cardUUID);

    //     // remove server side error
    //     $('.required.error').removeClass('error');
    //     $('.error-message').remove();
    // });

    // Updates payment method, if wallet brick is selected and the balance is sufficient.
    $(document).on("click", ".payment-option.wallet", function (e) {
        e.preventDefault();

        var $checkoutWalletBrick = $(".payment-option.wallet");
        var isWalletSufficient = $checkoutWalletBrick.length && $checkoutWalletBrick.hasClass("selected") && ($checkoutWalletBrick.data("iswalletsufficient") || $(".opc-payment .order-subtotal").data("wallet-sufficient"));

        if (isWalletSufficient) {
            updatePaymentMethod("WALLET_PAYMENT");

            let $CCRadio = $("#is-CREDIT_CARD");

            if ($CCRadio.length && $CCRadio.is(":checked")) {
                $CCRadio.trigger("click");
            } else if ($(".payment-method-options").find(":checked").length && $(".payment-method-options").find(":checked").val() == "PayPal") {
                $("#is-PayPal").trigger("click");
            }
        }
    });

    if (SitePreferences.ENABLE_WALLET_FOR_UPLAY_PLUS) {
        $(document).on("click", ".payment-option.other", () => {
            const $ccRadio = $("#is-CREDIT_CARD");
            const $payPalRadio = $("#is-PayPal");

            if ($ccRadio.length && $ccRadio.is(":checked")) {
                $ccRadio.trigger("click");
            } else if ($payPalRadio.length && $payPalRadio.is(":checked")) {
                $payPalRadio.trigger("click");
            }
        });
    }

    $cache.upcCheckoutSubmit.on("click", function (e) {
        e.preventDefault();

        if ($("#checkout-conditions input[type='checkbox']").length) {
            if (checkTos()) {
                return false;
            }
        }

        if ($cache.upcCheckoutSubmit.hasClass("checkout-disabled-white")) {
            return false;
        }

        let $selectedPaymentMethodInput = $('[name$="_billing_paymentMethods_hostedPaymentMethodID"]:checked');
        const upcCheckoutForm = $("#payment_checkout_uplay");
        const isSubscriptionCheckout = upcCheckoutForm.length && upcCheckoutForm.hasClass("subscription-payment-content");
        const $paymentOptionWallet = $(".payment-option.wallet");
        const isWalletPaymentSelected = $paymentOptionWallet.length && $paymentOptionWallet.hasClass("selected");
        const isWalletSufficient = $paymentOptionWallet.length && $paymentOptionWallet.hasClass("selected") && ($paymentOptionWallet.data("iswalletsufficient") || $(".opc-payment .order-subtotal").data("wallet-sufficient"));

        if (isWalletPaymentSelected && isWalletSufficient && !isSubscriptionCheckout) {
            $(".payment-method-container").empty();
        }

        if (($selectedPaymentMethodInput.length || isSubscriptionCheckout) && $cache.checkoutForm.valid()) {
            if (isWalletPaymentSelected && (!isWalletSufficient || isSubscriptionCheckout)) {
                $("input[name$='paymentMethods_selectedPaymentMethodID']").val("WALLET_PAYMENT");
            }

            var isCreditCardPayment = $cache.selectPaymentMethod.find(":checked").val() == "CREDIT_CARD" || $cache.savedCC.find(":checked").val() == "CREDIT_CARD";

            if (isCreditCardPayment && $(this).valid()) {
                setCCEncryptedData($('[data-method="CREDIT_CARD"]'));
            }

            if (SitePreferences.ENABLE_3DSV2 && isCreditCardPayment) {
                checkfor3ds($('[data-method="CREDIT_CARD"]'));
            }

            var checkedSelectedPayment = $cache.selectPaymentMethod.find(":checked").val();
            var lowercaseCheckedSelectedPayment = checkedSelectedPayment ? checkedSelectedPayment.toLowerCase() : null;
            var isEbanxPaymentSelected = checkedSelectedPayment ? (lowercaseCheckedSelectedPayment === "ebanx-cc" || lowercaseCheckedSelectedPayment === "ebanx-dc") : false;
            var isXsollaPaymentSelected = checkedSelectedPayment ? (lowercaseCheckedSelectedPayment === "xsolla-cc" || lowercaseCheckedSelectedPayment === "xsolla-pp") : false;
            var EBANX = window.EBANX || {};

            if (isEbanxPaymentSelected && SitePreferences.ENABLE_EBANX && !$.isEmptyObject(EBANX)) {
                const tokenizeOptions = ebanx.getEbanxCCData();

                EBANX.card.createToken(tokenizeOptions, ebanxResponse => {
                    if (ebanxResponse && ebanxResponse.data && ebanxResponse.data.token) {
                        ebanx.processEbanxCreditCard(ebanxResponse.data.token);
                    } else {
                        $.ajax({
                            url: Urls.getEbanxTokenErrorMsg,
                            method: "GET"
                        }).done(data => {
                            if (data) {
                                let $errMsg = $(".order-failed-error-message");

                                if ($errMsg.length) {
                                    $errMsg.replaceWith(data);
                                } else {
                                    let $paymentCheckoutContainer = $("#payment_checkout_uplay");

                                    if ($paymentCheckoutContainer.length) {
                                        $paymentCheckoutContainer.prepend(data);
                                    }
                                }

                                $(this).removeClass("checkout-disabled-white").next().hide();

                                // scroll window to the error message
                                $("html, body").animate({
                                    scrollTop: 0
                                }, 500);
                            }
                        });

                        return;
                    }

                    $cache.upcCheckoutSubmit.addClass("checkout-disabled-white").next().show();
                    $cache.checkoutForm.submit();
                });
            } else if (isXsollaPaymentSelected) {
                // Dispatch submitOrder event - "flow" specific
                const submitOrderEvent = new CustomEvent("xsolla:submitOrder:" + window.xsollaFlow);

                document.dispatchEvent(submitOrderEvent);
            } else if (SitePreferences.ENABLE_3DSV2) {
                // the form should be submitted after we receive the DDC generated JWT
                $cache.upcCheckoutSubmit.addClass("checkout-disabled-white").next().show();
                setTimeout(function () {
                    $cache.checkoutForm.submit();
                }, isCreditCardPayment ? 4000 : 0);
            } else {
                $cache.upcCheckoutSubmit.addClass("checkout-disabled-white").next().show();
                $cache.checkoutForm.submit();
            }
        }
    });

    var $countrySelector = $('select[name$="_country"]');

    $countrySelector.on("change", function () {
        if (Constants.ENABLE_APMLOOKUP) {
            populateApmMethods();
        }

        var $form = $(".address");
        var countryCode = $('select[id$="_country"]', $form).val();

        fieldForBrazil(($cache.selectedPaymentMethod) ? $cache.selectedPaymentMethod : "CREDIT_CARD", countryCode);

        var url = util.appendParamToURL(Urls.checkProductRestrictionJSON, "countryCode", countryCode);

        $.ajax({
            url: url,
            method: "POST",
            dataType: "json"
        }).done(function (data) {
            if (data && data.hasRestrictions == true) {
                $("#restrictionMessage").html(Resources.RESTRICTED_PRODUCTS + data.restrictedProducts.join("<br />"));
            } else {
                $("#restrictionMessage").html("");
            }
        });
    });

    if (Constants.ENABLE_APMLOOKUP) {
        populateApmMethods();
    }

    $("#check-giftcert").on("click", function (e) {
        e.preventDefault();

        var $balance = $(".balance");

        if ($cache.giftCertCode.length === 0 || $cache.giftCertCode.val().length === 0) {
            var error = $balance.find("span.error");

            if (error.length === 0) {
                error = $("<span>").addClass("error").appendTo($balance);
            }

            error.html(Resources.GIFT_CERT_MISSING);

            return;
        }

        checkBalance($cache.giftCertCode.val(), function (data) {
            if (!data || !data.giftCertificate) {
                $balance.html(Resources.GIFT_CERT_INVALID).removeClass("success").addClass("error");

                return;
            }

            $balance.html(Resources.GIFT_CERT_BALANCE + " " + data.giftCertificate.balance).removeClass("error").addClass("success");
        });
    });

    $cache.addGiftCert.on("click", function (e) {
        e.preventDefault();

        var code = $cache.giftCertCode.val(),
            $error = $cache.checkoutForm.find(".giftcert-error");

        if (code.length === 0) {
            $error.html(Resources.GIFT_CERT_MISSING);

            return;
        }

        var url = util.appendParamsToUrl(Urls.redeemGiftCert, {
            giftCertCode: code,
            format: "ajax"
        });

        $.getJSON(url, function (data) {
            var fail = false;
            var msg = "";

            if (!data) {
                msg = Resources.BAD_RESPONSE;
                fail = true;
            } else if (!data.success) {
                msg = data.message.split("<").join("&lt;").split(">").join("&gt;");
                fail = true;
            }

            if (fail) {
                $error.html(msg);

                return;
            } else {
                window.location.assign(Urls.billing);
            }
        });
    });

    $cache.addCoupon.on("click", function (e) {
        e.preventDefault();

        var $error = $cache.checkoutForm.find(".coupon-error"),
            code = $cache.couponCode.val();

        if (code.length === 0) {
            $error.html(Resources.COUPON_CODE_MISSING);

            return;
        }

        var url = util.appendParamsToUrl(Urls.addCoupon, {
            couponCode: code,
            format: "ajax"
        });

        $.getJSON(url, function (data) {
            var fail = false;
            var msg = "";

            if (!data) {
                msg = Resources.BAD_RESPONSE;
                fail = true;
            } else if (!data.success) {
                msg = data.message.split("<").join("&lt;").split(">").join("&gt;");
                fail = true;
            }

            if (fail) {
                $error.html(msg);

                return;
            }

            // basket check for displaying the payment section, if the adjusted total of the basket is 0 after applying the coupon
            // this will force a page refresh to display the coupon message based on a parameter message
            if (data.success && data.baskettotal === 0) {
                window.location.assign(Urls.billing);
            }
        });
    });

    // trigger events on enter
    $cache.couponCode.on("keydown", function (e) {
        if (e.which === 13) {
            e.preventDefault();
            $cache.addCoupon.click();
        }
    });
    $cache.giftCertCode.on("keydown", function (e) {
        if (e.which === 13) {
            e.preventDefault();
            $cache.addGiftCert.click();
        }
    });

    // WorldPay integration
    var $elvRadioButton = $("#PaymentMethod_ELV-SSL");

    $elvRadioButton.on("click", 'input[type="radio"][class="input-radio-elv"]', function () {
        updateElvValue($(this).val());
    });

    $(".creditcard-payment").on("click", function () {
        var cardUUID = $(this).data("uuid");

        if (!cardUUID) {
            return;
        }

        var cvn = $(this).closest(".form-row").find($(".cvn .input-text")).val();
        var $opcPayment = $(".opc-other-payment-option");
        var $selectedPayment = $("input[name$='_selectedPaymentMethodID']");

        if ($opcPayment.length && $opcPayment.find($selectedPayment).length) {
            $opcPayment.find($selectedPayment).val("CREDIT_CARD");
        } else {
            $selectedPayment.val("CREDIT_CARD");
        }

        populateCheckoutCreditCardForm(cardUUID, cvn);
        $(".payment-method-container").html("");
        // validateCreditCard($('input[name*="_creditCard_number"]').val());
    });

    $(".cvn").on("change", function () {
        var $paymentCard = $(this).closest(".cc-saved");
        var cardUUID = $paymentCard.data("uuid");

        if (!cardUUID) {
            return;
        }

        var cvn = $paymentCard.closest(".form-row").find($(".cvn .input-text")).val();

        if (!$(".payment-method-worldpay").is(":visible")) {
            populateCheckoutCreditCardForm(cardUUID, cvn);
        }
    });

    var isWorldPaySupported = $(".payment-method").find("input[data-worldpay='true']");

    if (isWorldPaySupported.length > 0) {
        $(".pay-saved-credit").show();
    }

    $(".address").on("change", 'input[name$="_addressFields_postal"]', function () {
        $(this).parent().siblings(".error-message").remove();
    });

    $(".address").on("change", 'select[name$="_addressList"], input[name$="_addressFields_address1"], input[name$="_addressFields_address2"], select[name$="_addressFields_country"], select[name$="_addressFields_states_state"], input[name$="_addressFields_city"], input[name$="_addressFields_postal"]',
        updateCartWithDigitalTax
    );

    completeBillingAddress();

    /* Empty CVN fields to avoid showing "***"
    /  and for the user to re-input the code */
    $('input[name*="_cvn"]').val("");

    /* Init tooltips for saved cc CVN only on uPlay*/
    if ($(".app_uplay .pay-saved-credit").length) {
        $(".pay-saved-credit").on("click", ".tooltip", function (e) {
            e.preventDefault();
            dialog.open({
                url: $(e.target).attr("href"),
                options: {
                    width: 700,
                    dialogClass: "card-tooltip"
                }
            });
        });

        var $ccField = $(".cc-saved");

        // Change to select cc
        $ccField.on("click", ".field-wrapper label", function () {
            $ccField.removeClass("active");
            $(this).parents(".cc-saved").addClass("active");
        });
    }

    // Functions used to copy the coupon code to clipboard, coupon contributed in a content asset when Mercury checkout is down
    $(document).on("click", ".voucher_save_code", function () {
        var copyToClipboard = $(".copy_to_clipboard_voucher").text();

        if (copyToClipboard != "") {
            var $temp = $("<input>");

            $("body").append($temp);
            $temp.val(copyToClipboard).select();
            document.execCommand("copy");
            $temp.remove();
        }
    });
}

export { initBilling, updatePaymentMethod, updateCartWithDigitalTax };
