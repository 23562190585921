import { cookie } from "./cookie";
import { util } from "./util";
import { adobeEvents, adobePageEvents } from "../../../int_tagcommander/cartridge/js/adobe_analytics_events";
import { initDataDogRum } from "UbiComponentsJS/datadogrum";

var adsOptIn = false;
var analyticsOptIn = false;
var disableTracking = false;
let trackingEventHandlersAttached = false;

/**
 * @function
 * @description sets the appropiate setting for the current A/B Testing tracker
 * @param {Boolean} isABTestingOptOut - flag to opt out of A/B Testing trackers
 */
let setABTestTracking = isABTestingOptOut => {
    if (SitePreferences.ENABLE_OPTIMIZELY) {
        window["optimizely"] = window["optimizely"] || [];
        window["optimizely"].push({
            type: "optOut",
            isOptOut: isABTestingOptOut
        });
    }

    if (SitePreferences.ENABLE_QUBIT) {
        if (isABTestingOptOut) {
            cookie.create("qb_dnt", 2, 365);
        } else {
            cookie.delete("qb_dnt");
        }
    }
};

/**
 * @function
 * @description makes the ajax call to update the ADS cookies
 * @param {Object} data - opt in data
 */
let updateTrackingCookies = data => {
    if (data.consentTracking && SitePreferences.SEARCH_ENGINE_ID === "algolia") {
        // reinitialize algolia with userHasOptedOut flag set to false to create the algolia cookie right after tag commander opt in
        window.aa("init", {
            appId: SitePreferences.ALGOLIA_APP_ID,
            apiKey: SitePreferences.ALGOLIA_API_KEY,
            userHasOptedOut: false
        });
    }

    if (data.adsOptOut !== null && typeof data.adsOptOut !== "undefined") {
        setABTestTracking(data.adsOptOut);
    }

    $.ajax({
        url: Urls.updateTracking,
        type: "POST",
        data: data
    });
};

/**
 * @function
 * @description sets the Ads cookies after the cookie popin is submitted
 * @param {Boolean} resetConsentEvent - Check the submitted consent for the current request
 */
let setTrackingCookies = resetConsentEvent => {
    // if the tracking event handlers were already attached, do not attach them again
    if (trackingEventHandlersAttached) return;

    const ConsentResources = window.ConsentResources || {};

    if (!ConsentResources.trackingConsentDone || resetConsentEvent) {
        // Indicates that the tracking event handlers have been attached
        trackingEventHandlersAttached = true;
    }

    window.addEventListener("message", function (event) {
        // Run init DatadogRum
        if (event.data == "PRIVACY_POLICY_ANALYTICS_ON") {
            initDataDogRum(true);
        }

        if (!ConsentResources.trackingConsentDone || resetConsentEvent) {
            // load Adobe page events when cookies accepted
            if (event.data == "S_CODE_READY" && analyticsOptIn) {
                adobePageEvents();
            }

            // load Adobe events when cookies accepted
            if (event.data == "PAGEVIEW_EVENT_FIRED" && analyticsOptIn) {
                adobeEvents();
            }

            // isolated logic for the changes for single post message
            if (SitePreferences.ENABLE_TRACKING_SINGLE_POST_MESSAGE) {
                let eventData = typeof event.data === "string" ? util.parseJSON(event.data) : event.data;

                if (eventData && (eventData.message === "trackingConsent" || eventData.type === "PRIVACY_POLICY_PREFERENCES_UPDATED")) {
                    let data = {
                        consentTracking: true,
                        adsOptOut: false,
                        analyticsOptOut: true
                    };

                    data.analyticsOptIn = cookie.get("UBI_PRIVACY_AA_OPTOUT") === "false";
                    data.adsOptIn = cookie.get("UBI_PRIVACY_ADS_OPTOUT") === "false";
                    data.videoOptIn = cookie.get("UBI_PRIVACY_VIDEO_OPTOUT") === "false";
                    data.persoOptIn = cookie.get("UBI_PRIVACY_CUSTOMIZATION_OPTOUT") === "false";
                    window.ConsentResources.videoConsent = data.videoOptIn;
                    data.ubiPrivacyPolicyViewed = cookie.get("UBI_PRIVACY_POLICY_VIEWED");
                    data.ubiPrivacyPolicyAccepted = cookie.get("UBI_PRIVACY_POLICY_ACCEPTED");
                    data.analyticsOptOut = !data.analyticsOptIn;
                    analyticsOptIn = data.analyticsOptIn;
                    data.videoOptOut = !data.videoOptIn;

                    // delete youtube cookies in case video consent was not provided
                    if (!data.videoOptIn) {
                        cookie.delete("VISITOR_INFO1_LIVE");
                        cookie.delete("YSC");
                    }

                    if (data.analyticsOptIn && data.adsOptIn) {
                        data.adsOptOut = false;
                    } else {
                        data.adsOptOut = !data.adsOptIn;
                    }

                    if (data.analyticsOptOut || !data.persoOptIn) {
                        data.consentTracking = false;
                    }

                    updateTrackingCookies(data);
                }
            } else {
                let data = {
                    consentTracking: true,
                    adsOptOut: false,
                    analyticsOptOut: true
                };

                if (event.data === "adsOptIn") {
                    adsOptIn = true;
                    data.adsOptOut = false;
                    data.analyticsOptOut = false;

                    if (analyticsOptIn) {
                        updateTrackingCookies(data);
                    }
                }

                if (event.data === "analyticsOptIn") {
                    analyticsOptIn = true;
                    data.analyticsOptOut = false;

                    if (adsOptIn) {
                        data.adsOptOut = false;
                        updateTrackingCookies(data);
                    }
                }

                if (event.data == "PRIVACY_POLICY_ANALYTICS_ON") {
                    data.analyticsOptOut = false;
                    analyticsOptIn = true;
                    updateTrackingCookies(data);
                }

                if (!disableTracking) {
                    if (event.data === "adsOptOut" || event.data === "analyticsOptOut") {
                        disableTracking = true;
                        data.consentTracking = false;
                        // only disable ads if specifically stated
                        data.adsOptOut = event.data === "adsOptOut";
                        updateTrackingCookies(data);
                    }
                }
            }
        }
    });
};

/**
 * @function
 * @description calculate [data-order] attribute based on the DOM position of the element
 */
let calculateDataOrderAttr = () => {
    const $pushElement = $("[data-order]");

    $pushElement.each((i, item) => {
        const $currentItem = $(item);

        $currentItem.attr("data-order", i + 1);
    });
};

export { setTrackingCookies, calculateDataOrderAttr };
