/**
 * Сhecks if current date is between freeOfferStartDate and freeOfferEndDate
 * @input {String} freeOfferStartDate - Free offer start date
 * @input {String} FreeOfferEndDate - Free offer end date
 * @return {Boolean} boolean value
 */
let isValidFreeOffer = (freeOfferStartDate, freeOfferEndDate) => {
    let currentDate = new Date();

    if (freeOfferStartDate && freeOfferEndDate) {
        return new Date(freeOfferStartDate) < currentDate && new Date(freeOfferEndDate) > currentDate;
    }

    return false;
};

/**
 * Updates the price and DLC label with free offer and f2p labels
 * @input {HTMLElement} element - current product tile element
 */
let initFreeOffer = element => {
    let availabilityElement = element.find(".product-availability-label");

    if (availabilityElement.length) {
        let isComingSoon = availabilityElement.data("comingsoon");
        let freeOffer = availabilityElement.data("freeplay");
        let freeOfferStartDate = availabilityElement.data("freeofferstartdate");
        let freeOfferEndDate = availabilityElement.data("freeofferenddate");
        let cardPrice = availabilityElement.next(".card-price");
        let isValid = isValidFreeOffer(freeOfferStartDate, freeOfferEndDate);

        if (isComingSoon) {
            availabilityElement.html(Resources.COMING_SOON_ALGOLIA);

            if (cardPrice.length) {
                availabilityElement.next(".card-price").html("");
            }
        } else if (freeOffer && isValid) {
            availabilityElement.html(Resources.FREE);

            if (cardPrice) {
                cardPrice.html("");
            }
        }
    }
};

export { initFreeOffer };
