import {clickEvent} from "./adobe_analytics_events";

let iframeMouseOver = false;

const myBenefitsPageEvents = () => {
    $("#heroBannerLogIn").on("click", e => {
        tc_events_100(e.currentTarget, "clickevent", {
            ACTION: "hero banner 1 - login – sign in"
        });

        const eventParams = {
            location: "login banner",
            category: "action",
            action: "log in"
        };

        clickEvent(e, eventParams, null, null, false);
    });

    $("#heroBannerCreateAccount").on("click", e => {
        tc_events_100(e.currentTarget, "clickevent", {
            ACTION: "hero banner 1  - action – create an account"
        });

        const eventParams = {
            location: "login banner",
            category: "action",
            action: "create an account"
        };

        clickEvent(e, eventParams, null, null, false);
    });

    $("#seeRefunds").on("click", e => {
        const eventParams = {
            location: "exclusive benefits",
            category: "action",
            action: "simplified refund: learn  more"
        };

        clickEvent(e, eventParams, null, null, false);
    });

    $("#copyToClipboard").on("click", e => {
        tc_events_100(e.currentTarget, "clickevent", {
            ACTION: "benefits - interaction - my exclusive offer : copy to clipboard"
        });
    });

    $("#shopSales").on("click", e => {
        tc_events_100(e.currentTarget, "clickevent", {
            ACTION: "benefits - action - shop sales"
        });
    });

    $("#viewPromotion").on("click", e => {
        tc_events_100(e.currentTarget, "clickevent", {
            ACTION: "benefits - action - view promotion"
        });
    });

    $("#preOrderNow").on("click", e => {
        tc_events_100(e.currentTarget, "clickevent", {
            ACTION: "benefits - action - preorder now"
        });

        const eventParams = {
            location: "services",
            category: "action",
            action: "coming soon: see products list"
        };

        clickEvent(e, eventParams, null, null, false);
    });

    $("#shopSalesNow").on("click", e => {
        tc_events_100(e.currentTarget, "clickevent", {
            ACTION: "benefits - action - shop sales now"
        });

        const eventParams = {
            location: "services",
            category: "action",
            action: "deals: see products list"
        };

        clickEvent(e, eventParams, null, null, false);
    });

    $(".mybenefits__latestdeals-section .shop-now").on("click", e => {
        const eventParams = {
            location: "custom banner",
            locationDetail: "latest deals",
            category: "action",
            action: "deals: see products list"
        };

        clickEvent(e, eventParams, null, null, false);
    });

    $("#addGamesWishlist").on("click", e => {
        const eventParams = {
            location: "my wishlist",
            locationDetail: "empty wishlist",
            category: "action",
            action: "games: see products list"
        };

        clickEvent(e, eventParams, null, null, false);
    });

    $("#seeWishlist").on("click", e => {
        const eventParams = {
            location: "my wishlist",
            locationDetail: "product listing",
            category: "action",
            action: "wishlist: see my wishlist"
        };

        clickEvent(e, eventParams, null, null, false);
    });

    $("#seeExclusiveItems").on("click", e => {
        tc_events_100(e.currentTarget, "clickevent", {
            ACTION: "benefits - action - see exclusive items"
        });

        const eventParams = {
            location: "services",
            category: "action",
            action: "games: see products list"
        };

        clickEvent(e, eventParams, null, null, false);
    });

    $("#shopNow").on("click", e => {
        tc_events_100(e.currentTarget, "clickevent", {
            ACTION: "benefits - action - merch : shop now"
        });
    });

    $("#seeMyWallet").on("click", e => {
        tc_events_100(e.currentTarget, "clickevent", {
            ACTION: "benefits - action - see my wallet"
        });

        const eventParams = {
            location: "exclusive benefits",
            category: "action",
            action: "wallet: see my wallet"
        };

        clickEvent(e, eventParams, null, null, false);
    });

    $("#addWalletFunds").on("click", e => {
        const eventParams = {
            location: "my wallet",
            category: "action",
            action: "ubisoft wallet: add funds"
        };

        clickEvent(e, eventParams, null, null, false);
    });

    $("#learnMore").on("click", e => {
        tc_events_100(e.currentTarget, "clickevent", {
            ACTION: "benefits - action - shipping : learn more"
        });

        const eventParams = {
            location: "exclusive benefits",
            category: "action",
            action: "free shipping: learn  more"
        };

        clickEvent(e, eventParams, null, null, false);
    });

    $("#rewardsLogIn").on("click", e => {
        tc_events_100(e.currentTarget, "clickevent", {
            ACTION: "benefits - login - rewards : sign in"
        });

        const eventParams = {
            location: "rewards",
            category: "action",
            action: "log in"
        };

        clickEvent(e, eventParams, null, null, false);
    });

    window.addEventListener("blur", e => {
        if (iframeMouseOver) {
            tc_events_100(e.currentTarget, "clickevent", {
                ACTION: "hero banner 2 - video – watch the trailer"
            });

            const eventParams = {
                location: "benefits video",
                category: "video",
                action: "benefits video: watch video"
            };

            clickEvent(e, eventParams, null, null, false);
        }
    });
};

/**
 * Initialize my Benefits Page click events
 * @private
 */
const initMyBenefitsPageEvents = () => {
    // Iframe
    let iframe = document.querySelector("iframe.yt-video");

    if (iframe) {
        iframe.addEventListener("mouseover", () => {
            iframeMouseOver = true;
        });

        iframe.addEventListener("mouseout", () => {
            iframeMouseOver = false;
        });
    }

    myBenefitsPageEvents();
};

export { initMyBenefitsPageEvents };
