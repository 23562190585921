import "slick-carousel";
import "jquery-ui/ui/widgets/dialog";
import Lightbox from "lightbox2/dist/js/lightbox";
import { util } from "../../util";
import { dialog } from "../../dialog";
import { initAddToCart } from "./addToCart";
import { initTabs } from "./tabs";
import { initResponsiveImage } from "../../responsive-image";
import { initTooltip } from "../../tooltip";
import { initDlc } from "./dlc";
import { initVariant } from "./variant";
import { initRecommendations } from "./recommendations";
import { initProductSet } from "./productSet";
import { initProductNav } from "./productNav";
import { initImage } from "./image";
import { initAvailability } from "./availability";
import { productInventory } from "../../storeinventory/product";
import { initEinsteinWatcher } from "../../einsteinwatcher";
import { initializeWishlistEvents } from "../wishlist";
import { initFreeOffer } from "../../freeoffer";
import { initOfferPresentation } from "./offerPresentation";
import { initPdpFreeOffer } from "./pdp_freeOffer";
import { initIbexPDP } from "./ibexsubscription";

var isUPC = $(".app_uplay").length;

const data = {
    freeOfferHandler: "[data-open^=free-offers-overlay]",
    freeOfferPopupIdPrefix: "free-offers-overlay-"
};

let $cache = {};

/**
 * @function
 * @description Initializes the cache object
 */
const initializeCache = () => {
    $cache = {
        document: $(document)
    };
};

/**
* @function
* @description function to call for the Media Slider settings
*/
const returnMediaSliderSettings = () => {
    const slickMediaSliderSettings = {
        slidesToShow: 2,
        slidesToScroll: 2,
        dots: true,
        accessibility: false,
        lazyLoad: "progressive",
        infinite: false
    };

    return slickMediaSliderSettings;
};

/**
* @function
* @description function to handle logic on different events for the .more-languages-link link
* @param {event} event
*/
const handleMoreLanguagesLink = event => {
    event.preventDefault();
    $("#language-table").toggleClass("show-more-languages");
    $(event.currentTarget).hide();
};

/**
* @function
* @description function to load more languages in the language table
*/
const loadLanguages = () => {
    const $viewMoreLanguagesButton = $("#pdpMain").find(".more-languages-link");

    if (!$viewMoreLanguagesButton.length) return;

    $viewMoreLanguagesButton.on("keypress", event => {
        if (event.keyCode !== 13 || event.key !== "Enter") return;

        handleMoreLanguagesLink(event);
    });

    $viewMoreLanguagesButton.on("click", event => {
        handleMoreLanguagesLink(event);
    });
};

/**
 * @description Initialize product detail page with reviews, recommendation and product navigation.
 */
function initializeDom() {
    initProductNav();
    initRecommendations();
    initTooltip();
    initOfferPresentation();
    initPdpFreeOffer();
}

/**
 * @description Initialize event handlers on product detail page
 */
function initializeEvents() {
    var $pdpMain = $("#pdpMain");
    const $productRecommendations = $("#pdpMain .recommendations").not(".einstein-recommendations");
    var $disclaimerContentDLC;
    var $disclaimerHolderDLC;
    var disclaimerHtmlDLC;

    // Related to CAISHEN-921 - Requires approval and adjustments before go-live
    // loadFinished.init();

    initAddToCart();
    initAvailability();
    initVariant();
    initImage();
    initTabs();
    initDlc();
    initProductSet();
    initIbexPDP();

    if (SitePreferences.STORE_PICKUP) {
        productInventory.init();
    }

    // Add to Wishlist and Add to Gift Registry links behaviors
    $pdpMain.on("click", '[data-action="wishlist"], [data-action="gift-registry"]', function () {
        var params = util.getQueryStringParams($(".pdpForm").serialize());

        if (params.cartAction) {
            delete params.cartAction;
        }

        var url = util.appendParamsToUrl(this.href, params);

        this.setAttribute("href", url);
    });

    // view more link functionality
    $pdpMain.on("click", "button.discover", e => {
        const $this = $(e.currentTarget);

        $this.toggleClass("view-more-container").siblings(".view-more-wrapper").toggleClass("show-more-state");
    });

    // load more languages on PDP
    loadLanguages();

    // product options
    $pdpMain.on("change", ".product-options select", function () {
        var salesPrice = $pdpMain.find(".product-add-to-cart .price-sales"),
            selectedItem = $(this).children().filter(":selected").first();

        salesPrice.text(selectedItem.data("combined"));
    });

    $(".size-chart-link a").on("click", function (e) {
        e.preventDefault();
        dialog.open({
            url: $(e.target).attr("href")
        });
    });

    if ($(".loyalty-number").length > 0) {
        $("#product-content .units").remove();
    }

    if (isUPC) {
        $pdpMain.find(".media-items").slick(returnMediaSliderSettings());

        // Fix for lightbox in media section on pdp page
        Lightbox.option({
            positionFromTop: 150,
            disableScrolling: true
        });
    }

    $cache.document.on("click", ".activate-modal-link-close", e => {
        e.preventDefault();

        const $dialog = $(e.currentTarget).closest(".ui-dialog-content");

        if (!$dialog.length) {
            return;
        }

        $dialog.dialog("close");
    });

    $cache.document.on("click", ".js-pdp-secondary-cta", e => {
        e.preventDefault();

        const $targetButton = $(`.pdp-common-cta${data.freeOfferHandler}`);

        if ($targetButton.length) {
            $targetButton.trigger("click");
        }
    });

    $(window).on("load", function () {
        if ($(".dlc-variation").length > 0) {
            $(".dlc-variation.active-type-image").trigger("click");
        }
    });

    $cache.document.on("click", ".dlc-variation, .ibex-platform-list a, .ibex-plan-list a", function (e) {
        e.preventDefault();

        var $this = $(this);
        var isIbexProduct = $this.data("subscriptiontype") && $this.data("subscriptiontype").toLowerCase() === "ibex";
        var variationId = $this.data("variationid");
        var variationMasterId = $this.data("variationmasterid");
        var $loadSectionOverlay = $(".load-section-overlay");
        var getLoyalty = util.appendParamsToUrl(Urls.getLoyalty, {
            productID: variationId
        });
        var loyalty;

        if (isIbexProduct) {
            $loadSectionOverlay.addClass("show");

            $.ajax({
                url: util.appendParamToURL(Urls.renderSubscriptionVariationsListsPDP, "pid", variationId),
                success: function (response) {
                    if (response) {
                        $(".ibex-planscontainer").replaceWith(response);
                    }
                }
            });

            $.ajax({
                url: util.appendParamsToUrl(Urls.renderSubscriptionAddToCart, {
                    pid: variationId
                }),
                success: function (response) {
                    if (response) {
                        $(".ibex-purchase-area").replaceWith(response);
                    }
                }
            });
        }

        $.ajax({
            url: getLoyalty,
            success: function (response) {
                loyalty = $(response).find("span").html();
                $(".loyalty_units").html(loyalty);
            }
        });

        $.ajax({
            type: "POST",
            url: Urls.getProductInfoJson,
            data: {
                pid: variationId
            }
        }).done(function (response) {
            if (response) {
                var $summarySection = $(".summary-section-content article.description-content");

                $summarySection.html(response.longDescription);

                if (response.legalLineWW) {
                    $summarySection.append(response.legalLineWW);
                }

                var $productPrice = $(".pdp-cta .product-price");
                var $productBadge = $(".card-additional-details.card-labels-wrapper");

                if ($productBadge.length > 0 && response.productBadge) {
                    $productBadge.replaceWith(response.productBadge);
                }

                // update the short description text
                const $shortDescription = $("[data-shortdescription]");

                if ($shortDescription.length && response.shortDescription) {
                    // replaces html tags to have a plain text
                    $shortDescription.html(response.shortDescription.replace(/<\/?[^>]+(>|$)/g, ""));
                }

                if (!isIbexProduct) {
                    if ($productPrice.length > 0) {
                        $productPrice.replaceWith(response.pricing);
                    } else {
                        $(".product-add-to-cart .pdp-cta").prepend(response.pricing);
                    }
                }

                $("#add-to-cart")
                    .attr("data-pid", variationId)
                    .attr("title", response.name)
                    .attr("data-master-product-id", false);

                $("#add-to-cart").parents().find('.product-add-to-cart input[name="pid"]').val(variationId);

                $cache.document.trigger("availability.refresh", [{
                    pid: variationId
                }]);

                if (response.productURL) {
                    window.history.pushState(null, null, response.productURL);
                }
            }

            // Get whole product page via ajax
            $this.find(".dlc-variations-thumbs-image").addClass("load-section");
            $.ajax({
                type: "POST",
                url: Urls.getProductUrl,
                data: {
                    pid: variationMasterId
                }
            }).done(function (result) {
                if (result) {
                    var $response = $(result);
                    var $editionName = $response.find(".details-area .edition");
                    var $pdpTabs = $response.find(".pdp-section-main");
                    var $sectionNav = $response.find(".product-section-nav");
                    const $purchaseSection = $response.find(".details-area .purchase-area");

                    $(".details-area .edition").replaceWith($editionName);
                    $(".pdp-section-main").replaceWith($pdpTabs);
                    $(".product-section-nav").replaceWith($sectionNav);

                    if (isIbexProduct) {
                        var $packshot = $(".packshot");
                        var $headerImg = $response.find(".packshot");

                        if ($packshot.length > 0 && $headerImg) {
                            $packshot.replaceWith($headerImg);
                        }
                    } else {
                        $(".details-area .purchase-area").replaceWith($purchaseSection);
                    }

                    initAddToCart();
                    initResponsiveImage();
                    initEinsteinWatcher();
                    $cache.document.find(".product-section-nav .add-to-cart").off("click").on("click", initAddToCart());
                    $pdpMain.find(".media-items").slick(returnMediaSliderSettings());
                    initRecommendations();
                    initProductNav();
                    loadLanguages();

                    $this.find(".dlc-variations-thumbs-image").removeClass("load-section");

                    if (isIbexProduct && $loadSectionOverlay.hasClass("show")) {
                        $loadSectionOverlay.removeClass("show");
                    }

                    variationId = $("#add-to-cart").parents().find('.product-add-to-cart input[name="pid"]').val();

                    if (Resources.AGEGATE_VERSION !== "v1" && variationId != null && variationId != undefined) {
                        checkAgeRestriction(variationId);
                    }

                    $disclaimerContentDLC = $(".hidden-disclaimer-holder");
                    $disclaimerHolderDLC = $(".disclaimer-holder");
                    disclaimerHtmlDLC = $disclaimerContentDLC.html();

                    if ($disclaimerContentDLC.length) {
                        $disclaimerHolderDLC.html(disclaimerHtmlDLC);
                    }

                    initializeWishlistEvents();
                }
            });
        });
    });

    $(".dlc-variation.active-type-image").trigger("click");

    if ($productRecommendations.length > 0) {
        const $productTile = $productRecommendations.find(".product-tile");

        if ($productTile.length > 0) {
            $productTile.each((index, element) => {
                initFreeOffer($(element));
            });
        }
    }

    $cache.document.ready(function () {
        var variationId = $("#add-to-cart").parents().find('.product-add-to-cart input[name="pid"]').val();

        if (Resources.AGEGATE_VERSION !== "v1" && variationId != null && variationId != undefined) {
            checkAgeRestriction(variationId);
        }
    });

    $disclaimerContentDLC = $(".hidden-disclaimer-holder");
    $disclaimerHolderDLC = $(".disclaimer-holder");
    disclaimerHtmlDLC = $disclaimerContentDLC.html();

    if ($disclaimerContentDLC.length) {
        $disclaimerHolderDLC.html(disclaimerHtmlDLC);
    }
}

/**
 * @description checks age gate restrictions and sets visibility for CTA buttons and tooltips
 */
function checkAgeRestriction(productID) {
    if (Resources.ENABLE_AGE_RESTRICTION) {
        $.ajax({
            type: "GET",
            url: util.ajaxUrl(Urls.checkAgeRestrictionAjax),
            data: {
                pid: productID
            },
            success: function (response) {
                // receive response if the user should be blocked or allowed to add to cart
                if (response != null) {
                    if (response.blocked) {
                        $(".age-restriction-tooltip").show();
                        $(".pdp-common-cta").addClass("restricted-cta");
                        $(".pdp-sticky-cta").addClass("restricted-cta");
                        $(".restricted-cta").attr("disabled", "disabled");
                    } else {
                        $(".age-restriction-tooltip").hide();
                        $(".restricted-cta").removeClass("restricted-cta");
                        $(".restricted-cta").removeAttr("disabled", "disabled");
                    }

                    // toggle button on subscription box on PDP
                    if ("userAge" in response) {
                        document.dispatchEvent(new CustomEvent("AgeGate:ToggleSubscriptionButton", {
                            detail: response.userAge
                        }));
                    }
                }
            }
        });
    }
}

var product = {
    initializeEvents: initializeEvents,
    init: function () {
        initializeCache();
        initializeDom();
        initializeEvents();
        initializeWishlistEvents();
    }
};

export { product };
