import {clickEvent} from "./adobe_analytics_events";

const $document = $(document);

/**
 * @function
 * @description Initialize Subscription Cancellation click events
 */
const initializeSubscriptionCancellationEvents = () => {
    $document.on("cancelSubscriptionCTATracking", (e, $this) => {
        const $switchCard = $this.closest(".switch-subscription-card");
        let eventParams = {};

        if ($switchCard.length > 0) {
            const productId = $switchCard.data("subscriptionid");
            const productPlatform = $switchCard.data("subscriptionplatform");
            const productName = $switchCard.data("subscriptiontrackingname");

            eventParams = {
                productId: productId,
                productName: productName,
                productPlatform: productName === "ubisoft plus" ? productPlatform : "",
                productSalesType: "subscription",
                location: "available plans",
                category: "action",
                action: "cancel",
                isCartAdd: 1,
                isCartOpen: 1,
                productDiscount: "no"
            };
        } else {
            eventParams = {
                location: "page",
                category: "action",
                action: "cancel"
            };
        }

        clickEvent(e, eventParams, null, null, false);
    });

    $document.on("click", ".e-cancellation__faq--container a", e => {
        const eventParams = {
            location: "page",
            category: "action",
            action: "faq"
        };

        clickEvent(e, eventParams, null, null, false);
    });

    $document.on("click", ".e-cancellation__account-link a", e => {
        const eventParams = {
            location: "page",
            category: "action",
            action: "account page"
        };

        clickEvent(e, eventParams, null, null, false);
    });

    $document.on("click", ".cancellation-additional-text a", e => {
        const eventParams = {
            location: "page",
            category: "action",
            action: "faq"
        };

        clickEvent(e, eventParams, null, null, false);
    });
};

export { initializeSubscriptionCancellationEvents };
