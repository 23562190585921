import debounce from "lodash/debounce";

var _ = {
    debounce
};

function initResponsiveImage() {
    widthCheck();

    $(window).on("resize", _.debounce(function () {
        widthCheck();
    }, 100));

    function widthCheck() {
        $(".responsive_image, .swapped").each(function () {
            var element = $(this),
                windowSize = $(window).width(),
                currentBreakpoint;

            if (windowSize >= 1280) {
                currentBreakpoint = 1;
            } else if (windowSize > 767 && windowSize < 1280) {
                currentBreakpoint = 2;
            } else {
                currentBreakpoint = 3;
            }

            if (isRetina()) {
                currentBreakpoint = 4;
            }

            if ($(this).hasClass("lazy")) {
                element.attr("data-src", srcSwap(currentBreakpoint, element));
            } else {
                element.attr("src", srcSwap(currentBreakpoint, element));
            }

            loadingImg(element);
        });
    }

    function loadingImg(target) {
        target.on("load", function () {
            $(this).removeClass("responsive_image");
            $(this).addClass("swapped");
        });
    }

    function srcSwap(device, target) {
        switch (device) {
            case 1:
                return target.attr("data-desktop-src");
            case 2:
                return target.attr("data-tablet-src");
            case 3:
                return target.attr("data-mobile-src");
            case 4:
                return target.attr("data-retina-src");
        }
    }

    function isRetina() {
        return ((window.matchMedia && (window.matchMedia("only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx), only screen and (min-resolution: 75.6dpcm)").matches || window.matchMedia("only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2/1), only screen and (min--moz-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2)").matches)) || (window.devicePixelRatio && window.devicePixelRatio >= 2)) && /(iPad|iPhone|iPod)/g.test(navigator.userAgent);
    }
}

export { initResponsiveImage };
