import each from "lodash/each";
import isString from "lodash/isString";
import LazyLoad from "vanilla-lazyload/dist/lazyload";

var _ = {
        each,
        isString
    },
    $document = $(document);

var util = {
    /**
     * @function
     * @description appends the parameter with the given name and value to the given url and returns the changed url
     * @param {String} url the url to which the parameter will be added
     * @param {String} name the name of the parameter
     * @param {String} value the value of the parameter
     */
    appendParamToURL: function (url, name, value) {
        // quit if the param already exists
        if (url) {
            if (url.indexOf(name + "=") !== -1) {
                return url;
            }

            var separator = url.indexOf("?") !== -1 ? "&" : "?";

            return url + separator + name + "=" + encodeURIComponent(value);
        } else {
            return false;
        }
    },

    /**
     * @function
     * @description remove the parameter and its value from the given url and returns the changed url
     * @param {String} url the url from which the parameter will be removed
     * @param {String} name the name of parameter that will be removed from url
     */
    removeParamFromURL: function (url, name) {
        if (url.indexOf("?") === -1 || url.indexOf(name + "=") === -1) {
            return url;
        }

        var hash;
        var params;
        var domain = url.split("?")[0];
        var paramUrl = url.split("?")[1];
        var newParams = [];

        // if there is a hash at the end, store the hash
        if (paramUrl.indexOf("#") > -1) {
            hash = paramUrl.split("#")[1] || "";
            paramUrl = paramUrl.split("#")[0];
        }

        params = paramUrl.split("&");

        for (var i = 0; i < params.length; i++) {
            // put back param to newParams array if it is not the one to be removed
            if (params[i].split("=")[0] !== name) {
                newParams.push(params[i]);
            }
        }

        return domain + "?" + newParams.join("&") + (hash ? "#" + hash : "");
    },

    /**
     * @function
     * @description appends the parameters to the given url and returns the changed url
     * @param {String} url the url to which the parameters will be added
     * @param {Object} params
     */
    appendParamsToUrl: function (url, params) {
        var _url = url;

        _.each(params, function (value, name) {
            _url = this.appendParamToURL(_url, name, value);
        }.bind(this));

        return _url;
    },
    /**
     * @function
     * @description extract the query string from URL
     * @param {String} url the url to extra query string from
     **/
    getQueryString: function (url) {
        var qs;

        if (!_.isString(url)) { return; }

        var a = document.createElement("a");

        a.href = url;

        if (a.search) {
            qs = a.search.substr(1); // remove the leading ?
        }

        return qs;
    },
    /**
     * @function
     * @description
     * @param {String}
     * @param {String}
     */
    elementInViewport: function (el, offsetToTop) {
        var top = el.offsetTop,
            left = el.offsetLeft,
            width = el.offsetWidth,
            height = el.offsetHeight;

        while (el.offsetParent) {
            el = el.offsetParent;
            top += el.offsetTop;
            left += el.offsetLeft;
        }

        if (typeof(offsetToTop) !== "undefined") {
            top -= offsetToTop;
        }

        if (window.pageXOffset !== null) {
            return (
                top < (window.pageYOffset + window.innerHeight) &&
                left < (window.pageXOffset + window.innerWidth) &&
                (top + height) > window.pageYOffset &&
                (left + width) > window.pageXOffset
            );
        }

        if (document.compatMode === "CSS1Compat") {
            return (
                top < (window.document.documentElement.scrollTop + window.document.documentElement.clientHeight) &&
                left < (window.document.documentElement.scrollLeft + window.document.documentElement.clientWidth) &&
                (top + height) > window.document.documentElement.scrollTop &&
                (left + width) > window.document.documentElement.scrollLeft
            );
        }
    },

    /**
     * @function
     * @description Appends the parameter 'format=ajax' to a given path
     * @param {String} path the relative path
     */
    ajaxUrl: function (path) {
        return this.appendParamToURL(path, "format", "ajax");
    },

    /**
     * @function
     * @description
     * @param {String} url
     */
    toAbsoluteUrl: function (url) {
        if (url) {
            if (url.indexOf("http") !== 0 && url.charAt(0) !== "/") {
                url = "/" + url;
            }

            return url;
        } else {
            return false;
        }
    },
    /**
     * @function
     * @description Loads css dynamically from given urls
     * @param {Array} urls Array of urls from which css will be dynamically loaded.
     */
    loadDynamicCss: function (urls) {
        var i,
            len = urls.length;

        for (i = 0; i < len; i++) {
            this.loadedCssFiles.push(this.loadCssFile(urls[i]));
        }
    },

    /**
     * @function
     * @description Loads css file dynamically from given url
     * @param {String} url The url from which css file will be dynamically loaded.
     */
    loadCssFile: function (url) {
        return $("<link/>").appendTo($("head")).attr({
            type: "text/css",
            rel: "stylesheet"
        }).attr("href", url); // for i.e. <9, href must be added after link has been appended to head
    },
    // array to keep track of the dynamically loaded CSS files
    loadedCssFiles: [],

    /**
     * @function
     * @description Removes all css files which were dynamically loaded
     */
    clearDynamicCss: function () {
        var i = this.loadedCssFiles.length;

        while (i-- < 0) {
            $(this.loadedCssFiles[i]).remove();
        }

        this.loadedCssFiles = [];
    },
    /**
     * @function
     * @description Extracts all parameters from a given query string into an object
     * @param {String} qs The query string from which the parameters will be extracted
     */
    getQueryStringParams: function (qs) {
        if (!qs || qs.length === 0) { return {}; }

        var params = {},
            unescapedQS = decodeURIComponent(qs);

        // Use the String::replace method to iterate over each
        // name-value pair in the string.
        unescapedQS.replace(new RegExp("([^?=&]+)(=([^&]*))?", "g"),
            function ($0, $1, $2, $3) {
                params[$1] = $3;
            }
        );

        return params;
    },

    fillAddressFields: function (address, $form) {
        for (var field in address) {
            if (field === "ID" || field === "UUID" || field === "key") {
                continue;
            }

            // if the key in address object ends with 'Code', remove that suffix
            // keys that ends with 'Code' are postalCode, stateCode and countryCode
            $form.find('[name$="' + field.replace("Code", "") + '"]').val(address[field]);

            // update the state fields
            if (field === "countryCode") {
                $form.find('[name$="country"]').trigger("change");
                // retrigger state selection after country has changed
                // this results in duplication of the state code, but is a necessary evil
                // for now because sometimes countryCode comes after stateCode
                $form.find('[name$="state"]').val(address.stateCode);
            }
        }
    },
    /**
     * @function
     * @description Updates the number of the remaining character
     * based on the character limit in a text area
     */
    limitCharacters: function () {
        $("form").find("textarea[data-character-limit]").each(function () {
            var characterLimit = $(this).data("character-limit");
            var charCountHtml = String.format(Resources.CHAR_LIMIT_MSG,
                '<span class="char-remain-count">' + characterLimit + "</span>");
            var charCountContainer = $(this).next("div.char-count");

            if (charCountContainer.length === 0) {
                charCountContainer = $('<div class="char-count"/>').insertAfter($(this));
            }

            charCountContainer.html(charCountHtml);
            // trigger the keydown event so that any existing character data is calculated
            $(this).change();
        });
    },

    /**
     * @function
     * @description Outputs JSON object or null

     * @param {String} str JSON string
     * @returns {(Object|Null)} Parsed JSON object or null in case of the exception during parsing
     */
    parseJSON: function (str) {
        try {
            return JSON.parse(str);
        } catch (e) {
            return null;
        }
    },

    /**
     * @function
     * @description Binds the onclick-event to a delete button on a given container,
     * which opens a confirmation box with a given message
     * @param {String} container The name of element to which the function will be bind
     * @param {String} message The message the will be shown upon a click
     */
    setDeleteConfirmation: function (container, message) {
        $(container).on("click", ".delete", function () {
            return window.confirm(message); // NOSONAR
        });
    },
    /**
     * @function
     * @description Scrolls a browser window to a given x point
     * @param {String} The x coordinate
     * @param {function} callback function to be executed when animation is complete
     */
    scrollBrowser: function (xLocation, callback) {
        $("html, body").animate({
            scrollTop: xLocation
        }, 500, callback);
    },

    isMobile: function () {
        var mobileAgentHash = ["mobile", "tablet", "phone", "ipad", "ipod", "android", "blackberry", "windows ce", "opera mini", "palm"];
        var idx = 0;
        var isMobile = false;
        var userAgent = (navigator.userAgent).toLowerCase();

        while (mobileAgentHash[idx] && !isMobile) {
            isMobile = (userAgent.indexOf(mobileAgentHash[idx]) >= 0);
            idx++;
        }

        return isMobile;
    },

    updateStateOptions: function () {
        // function no longer needed
        return;
    },

    /**
     * @function
     * @description merge urls parameters and return new url
     * ...Product-Variation?pid=577bd61ca3be1616208b461c&dwvar_577bd61ca3be1616208b461c_Size=S
     * ...Product-Variation?pid=577bd61ca3be1616208b461c&dwvar_577bd61ca3be1616208b461c_Color=White
     * merged into ...Product-Variation?pid=577bd61ca3be1616208b461c&dwvar_577bd61ca3be1616208b461c_Size=S&dwvar_577bd61ca3be1616208b461c_Color=White
     * @param {Array} urls to merge parameters from and return new url
     */
    mergeUrlsParams: function (urls) {
        var newParams = [],
            domain = urls[0].split("?")[0];

        urls.forEach(function (url) {
            var paramsStr = url.split("?")[1];
            var params = paramsStr.split("&");

            params.forEach(function (param) {
                if (newParams.indexOf(param) == -1) {
                    newParams.push(param);
                }
            });
        });

        return domain + "?" + newParams.join("&");
    },

    /**
     * @function
     * @description Get URL parameter value specified when function called

     * @param {String} param for which to return value from query string url
     */
    getURLParamValue: function (key) {
        var param = {};

        location.search.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (s, key, value) {param[key] = value;}); // NOSONAR

        return key ? param[key] : param;
    },

    /**
    * @function
    * @description Close the opened reveal on custom button click
    */
    closeThisReveal: function () {
        $document.on("click", ".js-close-this-reveal", function () {
            if ($(this).hasClass("got-it-btn") && !$(this).hasClass("htp-got-it-button")) {
                $(this).closest(".ui-dialog").find(".ui-dialog-titlebar-close").addClass("htp-got-it-button");
            }

            $(this).closest(".ui-dialog").find(".ui-dialog-titlebar-close").click();
        });
    },

    // lazyload the adjacent images in the slider
    /**
    * @function
    * @description add lazyload to the previous and next image shown in the slider
    * @param {Object} $elem - jQuery element of the current slide
    */
    sliderAdjacentImagesShow: $elem => {
        const $prevSibling = $elem.prev(".slick-slide");
        const $nextSibling = $elem.next(".slick-slide");

        if ($prevSibling.length || $nextSibling.length) {
            const $prevImage = $prevSibling.find("img.lazy");
            const $nextImage = $nextSibling.find("img.lazy");

            if (!$prevImage.hasClass("loaded")) {
                $prevImage.addClass("force-loaded");
            }

            if (!$nextImage.hasClass("loaded")) {
                $nextImage.addClass("force-loaded");
            }

            if ($(".force-loaded").length) {
                var lazyLoadSliderInstance = new LazyLoad({
                    elements_selector: ".force-loaded"
                });

                lazyLoadSliderInstance.loadAll();
            }
        }
    },

    /**
    * @function
    * @description Format the prices from Algolia layer
    * @param {Number} price aloglia price what will be formated
    */
    formatAlgoliaPrice: function (price) {
        try {
            const currentSessionCurrency = GeoRestrictedResources.STOREFRONT_CURRENCY_CODE;

            if (typeof price === "object") {
                // price come in different format in algoliaV1 and algoliaV2
                price = price[0] ? price[0][currentSessionCurrency] : price[currentSessionCurrency];
            }

            if (price !== undefined && price !== "N/A") {
                const isAlgoliaRoundPrice = SitePreferences.ALGOLIA_ROUND_PRICE;
                // * 1 converts the value to a number (e.g for minimum_price sometimes is a string)
                const priceValue = price * 1;
                const currencyToBeRounded = this.currencyToBeRounded(currentSessionCurrency);

                // If "algoliaRoundPrice" site pref is enabled and "algoliaCurrenciesToRound" is empty or it has the same currency defined as in the session currency, the price will be rounded.
                if (isAlgoliaRoundPrice && (!currencyToBeRounded.currenciesToRound || currencyToBeRounded.isCurrencyToBeRounded)) {
                    price = Math.round(priceValue).toFixed();
                } else {
                    // the number (price * 1) is converted into a string, rounding to 2 decimals
                    price = (price * 1).toFixed(2);
                }

                const decimalSeparator = SitePreferences.ALGOLIA_PRICE_DECIMAL_SEPARATOR;
                const sectionSeparator = SitePreferences.ALGOLIA_PRICE_SECTION_SEPARATOR;

                if (decimalSeparator) {
                    price = price.replace(".", decimalSeparator);

                    if (sectionSeparator) {
                        price = this.separatorOverride(price, sectionSeparator, currentSessionCurrency, true);
                    }
                } else {
                    if (sectionSeparator) {
                        price = this.separatorOverride(price, sectionSeparator, currentSessionCurrency, false);
                    }
                }
            }

            return price;
        } catch (e) {
            return price;
        }
    },

    /**
    * @function
    * @description Replaces separator with the one from JSON if current currency/default value is present in JSON
    * @param {String} price - actual price of the product
    * @param {String} sectionSeparator - stringified JSON with section separators
    * @param {String} currentSessionCurrency - session currency code
    * @return {String} - section formatted price
    */
    separatorOverride: function (price, sectionSeparator, currentSessionCurrency, withDecimalOverride) {
        const parsedSeparator = JSON.parse(sectionSeparator);
        var currentSeparator = parsedSeparator[currentSessionCurrency] ?? parsedSeparator["default"];

        if (currentSeparator) {
            if (withDecimalOverride) {
                price = price.replace(/\d(?=(\d{3})+\D)/g, "$&" + currentSeparator);
            } else {
                price = price.replace(/\B(?=(\d{3})+(?!\d))/g, currentSeparator);
            }
        }

        return price;
    },

    /**
    * @function
    * @description Points if a currency from "algoliaCurrenciesToRound" site pref is needed to be rounded, if it matches the currency of the locale. It's used for Algolia CLP.
    * @param {String} currentSessionCurrency - The currency of the current locale.
    * @return {Object} - Returns an object with different info regarding currencies rounding.
    */
    currencyToBeRounded: function (currentSessionCurrency) {
        const currenciesToRound = SitePreferences.ALGOLIA_CURRENCIES_TO_ROUND;
        let isCurrencyToBeRounded = false;
        let currenciesToRoundArr = currenciesToRound ? currenciesToRound.split(",") : null;

        if (currenciesToRoundArr) {
            for (let i = 0; i < currenciesToRoundArr.length; i++) {
                const ucCurrencyToRound = currenciesToRoundArr[i].toUpperCase();

                if (ucCurrencyToRound === currentSessionCurrency) {
                    isCurrencyToBeRounded = true;

                    break;
                }
            }
        }

        return {
            currenciesToRound: currenciesToRound,
            isCurrencyToBeRounded: isCurrencyToBeRounded
        };
    },

    /**
    * @function
    * @description Function used to remove options with past year for credit card expiration
    */
    removeCreditCardExpPastYear: function () {
        const currentYear = new Date().getFullYear();

        $('select[name*="_expiration_year"] option').each(function () {
            if (this.value !== "" && this.value < currentYear) {
                $(this).remove();
            }
        });
    },

    /**
    * @function
    * @description Function used to add spaces for card number after each 4th digit
    */
    formatCardNumber: function (e) {
        const currentNumber = e.target.value;

        if (e.type === "paste" || e.type === "change") {
            setTimeout(() => {
                const formattedNumber = e.target.value;

                if (formattedNumber.length === 16) {
                    let formattedNumberArr = formattedNumber.split("");

                    if (formattedNumberArr.length) {
                        formattedNumberArr.splice(4, 0, " ");
                        formattedNumberArr.splice(9, 0, " ");
                        formattedNumberArr.splice(14, 0, " ");
                        e.target.value = formattedNumberArr.join("");
                    }
                }
            }, 0);
        } else {
            if (e.keyCode === 32) {
                if (currentNumber.length === 5 || currentNumber.length === 10 || currentNumber.length === 15 || currentNumber.length === 19) {
                    return;
                } else {
                    e.target.value = currentNumber.slice(0, currentNumber.length - 1);
                }
            } else if (e.keyCode !== 8) {
                if (currentNumber.length === 4 || currentNumber.length === 9 || currentNumber.length === 14) {
                    e.target.value = currentNumber + " ";
                }
            }
        }
    },

    /**
    * @function
    * @description Function used to check visibility of checkboxes in a container
    * @param {Object} $container, jQuery Object that contain checkboxes
    */
    tosCheckboxVisibleCount: function ($container) {
        if ($container.length) {
            let tosCheckboxVisible = [];
            const $checkboxes = $container.find("input[type='checkbox']");

            $checkboxes.each((index, element) => {
                const $element = $(element);

                if (!$element.parent().hasClass("hide") && !$element.parent().hasClass("data-consent")) {
                    tosCheckboxVisible.push(element);
                }
            });

            return tosCheckboxVisible;
        }
    },

    /**
    * @function
    * @description Function used to return fetch response
    * @param {String} url, the url to the resource used to fetch
    * @param {Object} params, the parameters of the resource you want to fetch
    * @param {Object} initParams
    * @returns {String|boolean} response of the fetch
    */
    getFetchResponse: function (url, params, initParams) {
        if (!url) {
            return false;
        }

        var init = {};
        var separator = url.indexOf("?") !== -1 ? "&" : "?";

        if ((initParams?.method !== "POST") && typeof params !== "undefined") {
            url += separator + new URLSearchParams(params);
        } else if (initParams?.method == "POST") {
            init = {
                body: typeof params !== "undefined" ? new URLSearchParams(params) : "",
                method: "POST",
                credentials: "same-origin"
            };
        }

        return fetch(url, init).then(response => {
            if (response.ok) {
                if (initParams?.response == "JSON" || (initParams?.response == "dynamic" && response.headers.get("content-type").includes("json"))) {
                    return response.json();
                } else {
                    return response.text();
                }
            } else {
                return false;
            }
        });
    },

    /**
    * @function
    * @description Function used to return the focusable elements
    * @param {String} $element, the container from which to extract the focusable elements
    * @returns {Object} jQuery Object that contain focusable elements
    */
    findFocusable: function ($element) {
        if (!$element) {
            return false;
        }

        return $element.find("a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, *[tabindex], *[contenteditable]").filter(function () {
            const $this = $(this);

            if (!$this.is(":visible") || $this.attr("tabindex") < 0) {
                return false;
            }

            // only have visible elements and those that have a tabindex greater or equal 0
            return true;
        });
    },

    /**
     * @function
     * @description enables/disables reactivation button based on the validated checkboxes
     * @param {Object} $button, jQuery Object that contains the button
     */
    enableSubsReactivationButton: function ($button) {
        const $container = $("#checkout-conditions");

        if (!$container.length) {
            return;
        }

        const $checkboxes = $container.find("input[type='checkbox']");
        const $tosCheckboxValidated = $checkboxes.filter(":checked");
        // run helper function to know how many checkboxes are displayed
        const tosCheckboxVisible = util.tosCheckboxVisibleCount($container);

        if (tosCheckboxVisible.length === $tosCheckboxValidated.length) {
            $button.prop("disabled", false);
        } else {
            $button.prop("disabled", true);
        }
    },

    /**
     * @function
     * @description initialize events used to update the aria-attributes for the selectboxit
     * without this change, the options will not be announced when focused
     */
    initializeSelectboxitADAEvents: () => {
        $(document).on("create.selectBoxIt", "select", (e, data) => {
            if (!data?.dropdown || !data?.dropdownOption) {
                return;
            }

            const $parentList = data.dropdownOption.closest("ul");
            const idPrefix = $(data.dropdown).attr("id") || "selectboxit";

            // add unique id to each option of the selectboxit
            $parentList.find("li").each((index, el) => {
                $(el).attr("id", idPrefix + "-option-" + index);
            });

            // if there's an label for the element, get the id or set one if it is missing
            const elemId = $(e.currentTarget).attr("id");
            let labelId = "";

            if (elemId) {
                const $label = $("label[for='" + elemId + "']");

                labelId = $label?.attr("id");

                if (!labelId) {
                    labelId = "selectboxit-label-" + Math.random().toString().substr(2, 8);
                    $label.attr("id", labelId);
                }
            }

            // use the select's label for the listbox and combobox
            if (labelId) {
                const $comboBox = data?.dropdown?.closest("[role=combobox]");

                if ($comboBox?.length) {
                    $comboBox.attr("aria-labelledby", labelId);

                    const $listbox = $comboBox.find("[role=listbox]");

                    if ($listbox.length) {
                        $listbox.attr("aria-labelledby", labelId);
                    }
                }
            }
        }).on("focusin.selectBoxIt", ".selectboxit-option", e => {
            // add aria-selected to the focused item and update the aria-activedescendant with the corresponding id
            $(e.currentTarget).attr("aria-selected", true)
                .siblings().attr("aria-selected", false);

            const id = $(e.currentTarget).attr("id");
            const $comboBox = $(e.currentTarget).closest("[role=combobox]");

            if (!id || !$comboBox.length) {
                return;
            }

            $comboBox.attr("aria-activedescendant", id);

            const $comboBpxBtn = $comboBox.find(".selectboxit-btn");

            // add aria-activedescendant to the focused element of the selectboxit, since the main element woth role combobox is not focusable
            if ($comboBpxBtn.length) {
                $comboBpxBtn.attr("aria-activedescendant", id);
            }
        }).on("blur.selectBoxIt", ".selectboxit-option", e => {
            $(e.currentTarget).attr("aria-selected", false);
        });
    }
};

/**
* @function
* @description Function used to call the functions, which are sent as parameter
* @param {Function/Object} function or object, which should be invoked
*/
const processInclude = include => {
    if (typeof include === "function") {
        include();
    } else if (typeof include === "object") {
        Object.keys(include).forEach(function (key) {
            if (typeof include[key] === "function") {
                include[key]();
            }
        });
    }
};

export { util, processInclude };
