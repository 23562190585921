var uskVideo = function (question, answer, video) {
    var expiresDate = new Date();

    expiresDate.setTime(expiresDate.getTime() + SitePreferences.COOKIE_LIFETIME * 60 * 1000);

    var cookieUSK16 = document.cookie.replace(/(?:(?:^|.*;\s*)usk16\s*=\s*([^;]*).*$)|^.*$/, "$1");
    var cookieUSK18 = document.cookie.replace(/(?:(?:^|.*;\s*)usk18\s*=\s*([^;]*).*$)|^.*$/, "$1");

    var twVideo = $(".dialog-video .twVideo").find("iframe"),
        ytVideo = $(".dialog-video").find("#ytplayer"),
        ytVideoMedia = $(".dialog-video").find(".video-iframe");

    if ((video.hasClass("usk18") && !cookieUSK18 && (!cookieUSK16 || (cookieUSK16 && cookieUSK16 == "yes"))) || (video.hasClass("usk16") && !cookieUSK16 && (!cookieUSK18 || (cookieUSK18 && cookieUSK18 == "no")))) {
        question.show();
        answer.hide();
        video.hide();
    } else if ((video.hasClass("usk18") && ((cookieUSK18 && cookieUSK18 == "no") || (!cookieUSK18 && (cookieUSK16 && cookieUSK16 == "no")))) || (video.hasClass("usk16") && ((cookieUSK16 && cookieUSK16 == "no")))) { // NOSONAR
        question.hide();
        answer.show();
        video.hide();
    } else {
        question.hide();
        answer.hide();
        setVideoSrc(twVideo, ytVideo, ytVideoMedia);
        video.show();
    }

    question.find("button.usk18-yes").on("click", function () {
        question.hide();
        answer.hide();
        setVideoSrc(twVideo, ytVideo, ytVideoMedia);
        video.show();
        document.cookie = "usk18=yes;path=/;expires=" + expiresDate.toUTCString();
    });
    question.find("button.usk18-no").on("click", function () {
        question.hide();
        answer.show();
        document.cookie = "usk18=no;path=/;expires=" + expiresDate.toUTCString();
    });

    question.find("button.usk16-yes").on("click", function () {
        question.hide();
        answer.hide();
        setVideoSrc(twVideo, ytVideo, ytVideoMedia);
        video.show();
        document.cookie = "usk16=yes;path=/;expires=" + expiresDate.toUTCString();
    });
    question.find("button.usk16-no").on("click", function () {
        question.hide();
        answer.show();
        document.cookie = "usk16=no;path=/;expires=" + expiresDate.toUTCString();
    });
};

var setVideoSrc = function (twVideo, ytVideo, ytVideoMedia) {
    if (twVideo.attr("src")) {
        twVideo.attr("src", twVideo.attr("src").replace("!autoplay", "autoplay"));
    }

    if (ytVideo.data("url")) {
        ytVideo.attr("src", ytVideo.data("url") + "&autoplay=1");
    }

    if (ytVideoMedia.data("url")) {
        ytVideoMedia.attr("src", ytVideoMedia.data("url") + "&autoplay=1");
    }
};

export { uskVideo };
