const data = {
    formItemTags: "input",
    formFocusClass: "focused",
    formParentFocusClass: "is-focused",
    formParentActiveClass: "is-active",
    formParentSelector: "[data-focus-within]",
    formFocusableSelector: "input:focus"
};

/**
 * @function
 * @description Detect :focus-visible support
 * @return {Boolean} isSupported - Returns true if the feature is supported
 */
const hasFocusVisible = () => {
    const style = document.createElement("style");
    let isSupported;

    try {
        document.head.appendChild(style);
        style.sheet.insertRule(":focus-visible { color: inherit }");
        isSupported = true;
    } catch {
        isSupported = false;
    } finally {
        style.remove();
    }

    return isSupported;
};

/**
 * @function
 * @description Remove classes added for focus and active status
 */
const formItemsRemoveHighlight = () => {
    $(`.${data.formFocusClass}:not(:focus)`).removeClass(data.formFocusClass)
        .parents(data.formParentSelector)
        .removeClass(data.formParentFocusClass + " " + data.formParentActiveClass);

    const $activeFormParent = $(data.formParentActiveClass);

    if (!$activeFormParent.length) {
        return;
    }

    const formItemValue = $activeFormParent.find(data.formItemTags).val();

    if (!formItemValue) {
        $activeFormParent.removeClass(data.formParentActiveClass);
    }
};

/**
 * @function
 * @description Add classes for focus and active status
 * @param {jQuery} $elem - The focused element
 */
const formItemsAddHighlight = $elem => {
    $elem.addClass(data.formFocusClass)
        .parents(data.formParentSelector)
        .addClass(data.formParentFocusClass);
};

/**
 * @function
 * @description Detect :focus-visible support
 * @return {Boolean} isSupported - Returns true if the feature is supported
 */

const formItemsFocus = () => {
    $(document).on("keyup", data.formItemTags, e => {
        const key = e.keyCode;
        const $focusedFormItem = $(`${data.formItemTags}:focus`);
        const $eventTarget = $(e.target);

        // the form element is focused from a triggered click event
        if (key === 13 && $eventTarget.length) {
            formItemsAddHighlight($eventTarget);

            return;
        }

        // add active class when the form focused element has value
        $(`.${data.formParentFocusClass}`).toggleClass(data.formParentActiveClass, $focusedFormItem?.val()?.length > 0);

        // if focus is lost, remove classes added for highlight
        if (!$focusedFormItem.length) {
            formItemsRemoveHighlight();

            return;
        }

        // add the focus highlight if the input is focused using Tab os Shift Tab
        if (key !== 9 && !e.shiftKey) {
            return;
        }

        formItemsAddHighlight($focusedFormItem);
        formItemsRemoveHighlight();
    }).on("blur click", data.formItemTags, () => {
        formItemsRemoveHighlight();
    });
};

/**
 * @function
 * @description Add the polyfill script if ":focus-visible" is not supported
 */
const initKeyboardFocus = () => {
    if (!hasFocusVisible()) {
        // Not supported
        import("focus-visible/dist/focus-visible.min.js");
    }

    formItemsFocus();
};

/**
 * @function
 * @description Handler for next focus target
 * @param {Object} $elem - trigger which has a next target
 */
const handleNextFocusTarget = $elem => {
    $elem.on("focusout", () => {
        const nextFocus = $elem.attr("data-next-focus");

        if (nextFocus) {
            focusTarget(nextFocus);
        }
    });
};

/**
 * @function
 * @description Handles target focus
 * @param {String} nextFocus - selector of the next focus element (example: ".add-to-wishlist[data-productid=5704fac688a7e32b078b4678]")
 */
const focusTarget = nextFocus => {
    const $nextFocusedElement = $(document).find(nextFocus);

    if ($nextFocusedElement.length > 0) {
        const $parentTile = $nextFocusedElement.parents(".grid-tile");
        const $parentPush = $nextFocusedElement.parents(".triple");

        if ($parentTile.length > 0) {
            $parentTile.addClass("tile-focus-active");
        }

        if ($parentPush.length > 0) {
            $parentPush.addClass("tile-focus-active");
        }

        $nextFocusedElement.trigger("focus");
    }
};

/**
 * @function
 * @description function to add specific class on select elements when they are accessed via Tab key
 * @param {String} selectContainer - the select element which will need o be highlighted
 */
const highlightOnFocus = selectContainer => {
    const $document = $(document);

    $document.on("keyup", selectContainer, e => {
        const $this = $(e.currentTarget);

        if (e.keyCode === 9) {
            $this.addClass("select-container-focused");
        }
    });

    $document.on("blur", selectContainer, e => {
        const $this = $(e.currentTarget);

        $this.removeClass("select-container-focused");
    });
};

export { initKeyboardFocus, handleNextFocusTarget, focusTarget, highlightOnFocus };
