import "jquery-validation";
import { util } from "../../util";
import { cartTrackingEvents } from "TagcommanderJS/cart_events";
import { initDateTransformation } from "../../dateTransformation";

/**
 * @description Handler to handle the add to cart event on a CLP tile
 */
var addToCartTile = function (e) {
    if ($(this).hasClass("giveaway")) {
        return;
    }

    e.preventDefault();

    var $tile = $(this);

    addItemToCart($tile);
};

/**
 * @description Handler for add to cart response
 */
function addItemToCartHandle(response, pid) {
    if (response.action) {
        return;
    }

    // add to cart tracking event
    if (typeof tc_events_100 === "function" && pid != null) {
        // call for product info
        $.ajax({
            url: Urls.infoForTC,
            data: {
                pid: pid
            },
            dataType: "json"
        }).done(function (data) {
            // if the response is an object with elements
            if (typeof data == "object" && Object.keys(data).length) {
                tc_events_100(this, "cartevent", data);
            }
        });
    }

    window.location.href = Urls.cartShow;

    if ($(".pass-digital").data("digitalcheck") === false) {
        $(".error-digital").removeClass("hidden");
    }

    cartTrackingEvents.updateCartData();
    initDateTransformation();
}

/**
 * @description Make the AJAX request to add an item to cart
 * @param {Element} form The form element that contains the item quantity and ID data
 * @returns {Promise}
 */
var addItemToCart = function ($tile) {
    var pid = $tile.data("gaLabel") || $tile.data("ga-label"),
        $form = {
            Quantity: 1,
            cartAction: $tile.data("gaAction") || $tile.data("ga-action"),
            pid: pid
        };

    $.ajax({
        type: "POST",
        url: util.ajaxUrl(Urls.addProduct),
        data: $form,
        success: function (response) {
            addItemToCartHandle(response, pid);
        }
    });
};

function initAddToCartTile() {
    var $addToCartWrapper = $(".addtocart-wrapper");

    // listener used on search pages
    if ((typeof pageContext != "undefined" && pageContext.ns === "search") ||
        ($addToCartWrapper.length > 0 && $addToCartWrapper.attr("data-is-addtocart") == "true") ||
        (pageContext.ns == "product" && $(".pdp-section .recommendations").length) ||
        (pageContext.algolia == "allow" && SitePreferences.SEARCH_ENGINE_ID === "algolia" && SitePreferences.ALGOLIA_ENABLED)) {
        $(document).on("click", ".add-to-cart-tile", addToCartTile);
    }
}

/**
 * @description Makes the AJAX request to add a prepaid card product to the cart
 * @param {Object} formData - contains the product information
 */
const addPPCToCart = async formData => {
    if (!formData) {
        return;
    }

    var addProductURL = util.appendParamsToUrl(Urls.addProduct, {
        format: "ajax"
    });
    const response = await util.getFetchResponse(addProductURL, formData, {
        response: "JSON"
    });

    if (response) {
        addItemToCartHandle(response);
        document.dispatchEvent(new CustomEvent("CART_PRODUCT_ADDED", {
            detail: {
                productId: formData.pid,
                quantity: 1
            }
        }));

        if ("redirectUrl" in response) {
            if (pageContext.ns !== "cartrevamp") {
                localStorage.setItem("lastAddedProducts", response.data.pid);
            }

            window.location.replace(response.redirectUrl);
        }
    }
};

/**
 * @description Makes an AJAX request to add a product to the cart, which is triggered by MFE event on PHP
 * @param {Object} formData - contains the product information
 */
const addProductToCartMfe = async formData => {
    if (!formData) {
        return;
    }

    const addProductURL = util.appendParamsToUrl(Urls.addProduct, {
        isPHP: true,
        format: "ajax"
    });
    const response = await util.getFetchResponse(addProductURL, {
        pid: formData.pid,
        Quantity: 1
    }, {
        response: "JSON"
    });

    if (response && response.success && response.redirectTo) {
        window.location.href = response.redirectTo;
    }
};

export { initAddToCartTile, addPPCToCart, addProductToCartMfe };
