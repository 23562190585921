import { util } from "./util";
import { initAlgoliaSearch } from "./algoliasearch";

/**
 * @private
 * @function
 * @description checks the URL's lang param value is changed manually from Browser.
 */
const checkLangParam = () => {
    var firsLang = sessionStorage.getItem("algoliaLang");
    var secondLang = util.getURLParamValue("lang");

    if (!secondLang || firsLang === secondLang) {
        sessionStorage.setItem("algoliaSearchValue", "");
        sessionStorage.setItem("algoliaLang", "");
    }
};

const initAlgolia = () => {
    if (SitePreferences.SEARCH_ENGINE_ID === "algolia" && SitePreferences.ALGOLIA_ENABLED) {
        window.addEventListener("beforeunload", function () {
            var currentLang = util.getURLParamValue("lang");

            if (currentLang != undefined) {
                sessionStorage.setItem("algoliaLang", currentLang);

                if ($("#searchbox input").val() != "") {
                    var searchValue = $("#searchbox input").val();

                    sessionStorage.setItem("algoliaSearchValue", searchValue);
                } else {
                    sessionStorage.setItem("algoliaSearchValue", "");
                }
            } else {
                sessionStorage.setItem("algoliaLang", "");
            }
        });

        if (document.readyState === "complete") {
            checkLangParam();
            initAlgoliaSearch();
        } else {
            window.addEventListener("load", () => {
                checkLangParam();
                initAlgoliaSearch();
            });
        }
    }
};

export { initAlgolia };
