import "slick-carousel";
import { symbols } from "../../symbols";

var $cache = {};

var initCache = function () {
    $cache = {
        window: $(window),
        dlcPlatformElement: $(".dlc-platform"),
        dlcTypesElement: $(".dlc-types"),
        dlcTypesActive: "active",
        dlcPlatformActive: "selected",
        dlcVariationItem: $(".dlc-variation"),
        hideDlcElement: "hide",
        activeDlcImage: "active-type-image"
    };
};

/**
* @function
* @description function to wrap dlc variations
*/
var wrapVariations = function () {
    $(".dlc-variation").each(function (index, element) {
        var $this = $(element),
            dataPlatform = $this.attr("data-platform"),
            dataType = $this.attr("data-type");

        if (!$this.parent().hasClass("variations-wrapper")) {
            $(".dlc-variation[data-platform='" + dataPlatform + "'][data-type='" + dataType + "']")
                .wrapAll('<div class="variations-wrapper" data-platform="' + dataPlatform + '" data-type="' + dataType + '">');
        }
    });

    // Hide the correct wrapper
    $(".variations-wrapper").each(function (index, element) {
        var $this = $(element);

        if ($this.find('.dlc-variation[data-selected="true"]').length == 0) {
            $this.addClass("hide");
        }
    });
};

/**
* @function
* @description function to change dcl platform
*/
var changeDlcPlatform = function () {
    if ($cache.dlcPlatformElement.length) {
        $cache.dlcPlatformElement.on("click", function (event) {
            event.preventDefault();

            var $this = $(this),
                dataPlatform = $this.data("platform");

            $this.addClass($cache.dlcPlatformActive).siblings().removeClass($cache.dlcPlatformActive);
            $cache.dlcTypesElement
                .removeClass($cache.hideDlcElement)
                .not($(".dlc-types[data-platform='" + dataPlatform + "']"))
                .addClass($cache.hideDlcElement);

            $(".dlc-types[data-platform='" + dataPlatform + "']").first().trigger("click");
        });
    }
};

/**
* @function
* @description function to change dlc type
*/
var changeDlcType = function () {
    if ($cache.dlcTypesElement.length) {
        $cache.dlcTypesElement.on("click", function (event) {
            event.preventDefault();

            changeDlcVariation();

            $(this).data("type-select-first", true);

            var $this = $(this),
                dataType = $this.data("type"),
                dataPlatform = $this.data("platform"),
                dataVariationActive = $(".variations-wrapper[data-type='" + dataType + "'][data-platform='" + dataPlatform + "']");

            $(".dlc-variations-dynamic-title").text(dataType);
            $this.addClass($cache.dlcTypesActive).siblings().removeClass($cache.dlcTypesActive);

            $(".variations-wrapper")
                .removeClass($cache.hideDlcElement)
                .not(dataVariationActive)
                .addClass($cache.hideDlcElement);

            $(".dlc-variation[data-platform='" + dataPlatform + "'][data-type='" + dataType + "']").first().trigger("click");
            initDlcSlider();
        });
    }
};

/**
* @function
* @description function to change dlc image on click
*/
var changeDlcVariation = function () {
    if ($cache.dlcVariationItem !== "undefined" && $cache.dlcVariationItem.length) {
        $cache.dlcVariationItem.on("click", function () {
            var $this = $(this),
                dataVariation = $this.data("variationid"),
                allArrows = $(".arrow-up"),
                arrow = $this.find(".arrow-up");

            $cache.dlcVariationItem.removeClass($cache.activeDlcImage).attr("data-selected", false);
            $this.addClass($cache.activeDlcImage).attr("data-selected", true);

            $(".dlc-variations-content .dlc-variations-content-element").addClass($cache.hideDlcElement);
            $(".dlc-variations-content").find("[data-variationid='" + dataVariation + "']").removeClass($cache.hideDlcElement);

            $(allArrows).addClass($cache.hideDlcElement);
            $(arrow).removeClass($cache.hideDlcElement);
        });

        $cache.dlcVariationItem.on("mouseenter mouseleave", function () {
            var $this = $(this),
                variationId = $this.data("variationid"),
                $contentVariationID = $(".dlc-variations-content").find("[data-variationid='" + variationId + "']"),
                activeImg = $(".active-type-image").data("variationid"),
                contentActiveImg = $(".dlc-variations-content").find("[data-variationid='" + activeImg + "']");

            if (!$this.hasClass($cache.activeDlcImage)) {
                $(contentActiveImg).toggleClass($cache.hideDlcElement);
                $($contentVariationID).toggleClass($cache.hideDlcElement);
            }
        });

        $cache.dlcVariationItem.on("mouseenter", function () {
            var $this = $(this),
                arrow = $this.find(".arrow-up"),
                activeImgArrow = $(".active-type-image .arrow-up");

            if (!$this.hasClass($cache.activeDlcImage)) {
                $(activeImgArrow).addClass($cache.hideDlcElement);
            }

            $(arrow).removeClass($cache.hideDlcElement);
        });

        $cache.dlcVariationItem.on("mouseleave", function () {
            var $this = $(this),
                arrow = $this.find(".arrow-up"),
                activeImgArrow = $(".active-type-image .arrow-up");

            $(activeImgArrow).removeClass($cache.hideDlcElement);

            if (!$this.hasClass($cache.activeDlcImage)) {
                $(arrow).addClass($cache.hideDlcElement);
            }
        });
    }
};

/**
* @function
* @description function to call for the Dlc Slider settings
*/
var returnDlcSliderSettings = function () {
    var slickSettingsDlcSlider = {
        dots: false,
        speed: 400,
        slidesToShow: 4,
        slidesToScroll: 4,
        autoplay: false,
        arrows: true,
        prevArrow: symbols.transparentPrevArrow(),
        nextArrow: symbols.transparentNextArrow(),
        cssEase: "ease",
        infinite: false,
        responsive: [
            {
                breakpoint: 1279,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            }
        ]
    };

    return slickSettingsDlcSlider;
};

/**
* @function
* @description function to init DLC Slider settings
*/
var initDlcSlider = function () {
    var minSlidesLength = 4;

    $(".variations-wrapper").each(function (index, element) {
        if ($(element).is(".slick-initialized") && $(element).not(":visible")) {
            $(element).slick("unslick");
        }

        if ($(element).find(".dlc-variation").length > minSlidesLength && $(element).is(":visible")) {
            var slideIndex = $(element).find(".dlc-variation").index($('.dlc-variation[data-selected="true"]'));

            $(element).not(".slick-initialized").slick(returnDlcSliderSettings()).slick("slickGoTo", parseInt(slideIndex, 10))
                .on("breakpoint", function () {
                    $(this).slick("refresh");
                    changeDlcVariation();
                })
                .on("beforeChange", function (event, slick, currentSlide, nextSlide) {
                    if (nextSlide == $(this).find(".slick-track").children().length - 1) {
                        $(this).find(".slick-list").addClass("dlc-slick-padding");
                        $(this).find(".dlc-variation").parent().addClass("left-auto-alignment");
                    } else {
                        $(this).find(".slick-list").removeClass("dlc-slick-padding");
                        $(this).find(".dlc-variation").parent().removeClass("left-auto-alignment");
                    }
                });
        }
    });
    changeDlcVariation();
};

var eventListeners = function () {
    wrapVariations();
    changeDlcPlatform();
    changeDlcType();
    initDlcSlider();
};

function initDlc() {
    initCache();
    eventListeners();
}

export { initDlc };
