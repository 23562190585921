import imagesLoaded from "imagesloaded";
import { util } from "./util";
import { initializeWishlistEvents } from "./pages/wishlist";
import { initFreeOffer } from "./freeoffer";

var isUPC = $(".app_uplay").length;

let $cache = {};

/**
 * @function
 * @description initialize cache object
 */
const initCache = () => {
    $cache = {
        categoryTitle: $(".category-title"),
        categoryResults: $(".categorypage-results")
    };

    $cache.targetScrollElement = $cache.categoryTitle.length ? $cache.categoryTitle : $cache.categoryResults;
};

function gridViewToggle() {
    $(".toggle-grid").on("click", function () {
        $(".search-result-content").toggleClass("wide-tiles");
        $(this).toggleClass("wide");
    });
}

/**
 * @private
 * @function
 * @description Initializes events on the product-tile for the following elements:
 * - swatches
 * - thumbnails
 */
function initializeEvents() {
    var isIOSDevice = false,
        userAgent = navigator.userAgent;

    if (userAgent.match(/iPod/i) || userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)) {
        isIOSDevice = true;
    }

    gridViewToggle();

    if (isIOSDevice) {
        $(document).on("touchstart", function () {});// fix for IOS CLP product link redirect issue please dont remove
        $(".product-tile platforms").css({
            visibility: "hidden"
        });
    }

    $(".swatch-list").on("mouseleave", function () {
        // Restore current thumb image
        var $tile = $(this).closest(".product-tile"),
            $thumb = $tile.find(".product-image .thumb-link img").eq(0),
            data = $thumb.data("current");

        $thumb.attr({
            src: data.src,
            alt: data.alt,
            title: data.title
        });
    });
    $(".swatch-list .swatch").on("click", function (e) {
        e.preventDefault();

        if ($(this).hasClass("selected")) { return; }

        var $tile = $(this).closest(".product-tile");

        $(this).closest(".swatch-list").find(".swatch.selected").removeClass("selected");
        $(this).addClass("selected");
        $tile.find(".thumb-link").attr("href", $(this).attr("href"));
        $tile.find("name-link").attr("href", $(this).attr("href"));

        var data = $(this).children("img").filter(":first").data("thumb");
        var $thumb = $tile.find(".product-image .thumb-link img").eq(0);
        var currentAttrs = {
            src: data.src,
            alt: data.alt,
            title: data.title
        };

        $thumb.attr(currentAttrs);
        $thumb.data("current", currentAttrs);
    }).on("mouseenter", function () {
        // get current thumb details
        var $tile = $(this).closest(".product-tile"),
            $thumb = $tile.find(".product-image .thumb-link img").eq(0),
            data = $(this).children("img").filter(":first").data("thumb"),
            current = $thumb.data("current");

        // If this is the first time, then record the current img
        if (!current) {
            $thumb.data("current", {
                src: $thumb[0].src,
                alt: $thumb[0].alt,
                title: $thumb[0].title
            });
        }

        // Set the tile image to the values provided on the swatch data attributes
        $thumb.attr({
            src: data.src,
            alt: data.alt,
            title: data.title
        });
    });

    // logic for free offer elements at template
    $(".product-tile").each(function () {
        initFreeOffer($(this));
    });
}

/**
 * @description Event handler added for home category section for load more products functionality
 */
$("div#main").on("click", ".load-more-products-button", function () {
    if ($(".avoid_click_events").length == 0) {
        var url = $(this).attr("data-url");

        $(".home-category-refinements").addClass("avoid_click_events");

        $.ajax({
            type: "GET",
            dataType: "html",
            url: url,
            success: function (response) {
                $(".home-category-refinements").removeClass("avoid_click_events");

                if (isNotEmpty(response)) {
                    var productItems = $($.parseHTML(response)).filter(".product_items_loaded"),
                        loadMoreContent = $($.parseHTML(response)).filter(".load-more-ajax"),
                        $primaryContent = $("#primary:not(.search-results)");

                    if ($primaryContent.length > 0) {
                        let $loadMoreWrapper = $primaryContent.find(".load-more-wrapper");

                        if ($loadMoreWrapper.length > 0) {
                            if (loadMoreContent != undefined && loadMoreContent.length > 0 && loadMoreContent.html().length > 0) {
                                $loadMoreWrapper.html(loadMoreContent.html());
                            } else {
                                $loadMoreWrapper.addClass("visually-hidden");
                            }
                        }

                        let $searchResultItems = $primaryContent.find("#search-result-items");

                        if ($searchResultItems.length > 0 && productItems != undefined && productItems.length > 0 && productItems.html().length > 0) {
                            $searchResultItems.append(productItems.html());
                            initProductTile();
                        }

                        let $loadMoreButton = $primaryContent.find(".load-more-button");

                        if ($loadMoreButton.length > 0 && isUPC) {
                            $loadMoreButton.removeClass("onLoad");
                        }
                    }
                }
            }
        });
    }
});

/**
 * @description Event handler added for home category section for UPC
 */
$("div#main").on("click", ".home-refinement-link, #homepage-sorting-options a", function (e) {
    e.preventDefault();

    if ($(this).closest(".avoid_click_events").length == 0) {
        var url = $(this).attr("href") || $(this).attr("data-href");

        $(this).closest(".home-category-refinements").addClass("avoid_click_events");
        $(".home-category-product-list .load-more-products-button").addClass("avoid_click_events");

        if (url != "") {
            $.ajax({
                type: "GET",
                dataType: "html",
                url: url,
                success: function (response) {
                    $(this).closest(".home-category-refinements").removeClass("avoid_click_events");
                    $(".home-category-product-list .load-more-products-button").removeClass("avoid_click_events");

                    if (isNotEmpty(response)) {
                        $(".home-category-product-list").html(response);
                        initProductTile();
                    }

                    if ($cache.targetScrollElement.length) {
                        util.scrollBrowser($cache.targetScrollElement.offset().top);
                    }

                    $(".sort-by-select select").selectBoxIt();
                    $(this).trigger("homeRefinementLinkClick");
                }
            });
        }
    }
});

$("div#main").on("click", "#clearHomeFilters", function (e) {
    e.preventDefault();
    window.location.reload();
});

$("div#main").on("click", ".breadcrumb-refinement .breadcrumb-relax", function (e) {
    e.preventDefault();

    let $this = $(e.target);
    let refinementName = $this.attr("data-refinement-name");
    let filtersWrapper = $this.closest(".refinements");

    if (refinementName && filtersWrapper) {
        if (refinementName.toLowerCase() === "price-slider") {
            let priceHref = $this.attr("href");
            let refinementsWrap = $this.closest(".refinements");

            if (priceHref && refinementsWrap) {
                let priceQuery = priceHref.split("?")[1];
                let filtersHeader = refinementsWrap.find(".filters-header");

                if (priceQuery && filtersHeader) {
                    let url = filtersHeader.attr("href");

                    if (url) {
                        let resultUrl = url + "&" + priceQuery;

                        $.ajax({
                            type: "GET",
                            dataType: "html",
                            url: resultUrl,
                            success: function (response) {
                                $(this).closest(".home-category-refinements").removeClass("avoid_click_events");
                                $(".home-category-product-list .load-more-products-button").removeClass("avoid_click_events");

                                if (isNotEmpty(response)) {
                                    $(".home-category-product-list").html(response);
                                    initProductTile();
                                }

                                if ($cache.targetScrollElement.length) {
                                    util.scrollBrowser($cache.targetScrollElement.offset().top);
                                }

                                $(".sort-by-select select").selectBoxIt();
                            }
                        });
                    }
                }
            }
        } else if (refinementName.toLowerCase() === "price") {
            let $priceRefinement = filtersWrapper.find(".price-refinement");

            if ($priceRefinement.length) {
                let $selectedPrice = $priceRefinement.find("li.selected .refinement-link");

                if ($selectedPrice.length) {
                    $selectedPrice.trigger("click");
                }
            }
        } else {
            let $appliedRefinement = filtersWrapper.find('[data-refinement-value="' + refinementName + '"]');

            if ($appliedRefinement) {
                $appliedRefinement.trigger("click");
            }
        }
    }
});

$(document).on("click keyup", ".home-category-refinements .view-more", function (e) {
    console.log(e);

    var $this = $(this);
    const isSpaceOrEnterPressed = e.type === "keyup" && (e.keyCode === 32 || e.keyCode === 13);

    if (e.type === "click" || isSpaceOrEnterPressed) {
        $this.hide().siblings().show();
    }

    if (isSpaceOrEnterPressed) {
        const $secondRefinementGroup = $this.siblings(".refinement-group:nth-child(2)");

        // move focus to the next checkbox, which became visible
        if ($secondRefinementGroup.length) {
            const $nextVisibleCheckbox = $secondRefinementGroup.find("li:first-child input");

            if ($nextVisibleCheckbox.length) {
                $nextVisibleCheckbox.trigger("focus");
            }
        }
    }
});

$(document).on("click keyup", ".home-category-refinements .view-less", function (e) {
    var $this = $(this);
    const isSpaceOrEnterPressed = e.type === "keyup" && (e.keyCode === 32 || e.keyCode === 13);

    if (e.type === "click" || isSpaceOrEnterPressed) {
        $this.hide().siblings(".refinement-group").not(":first-child").hide().siblings(".view-more").show();
    }

    if (isSpaceOrEnterPressed) {
        const $viewMore = $this.siblings(".view-more").find(".view-more-btn");

        // move focus to the View More button
        if ($viewMore.length) {
            $viewMore.trigger("focus");
        }
    }
});

/**
* @description Event handler added for product timer to be executed on ready and on ajax complete
*/
$(document).on("ready ajaxComplete", function () {
    // function added for product tile timer
    $(".product-timer").each(function () {
        var end = new Date($(this).attr("data-campaign-date"));
        var $this = $(this);
        var _second = 1000;
        var _minute = _second * 60;
        var _hour = _minute * 60;
        var _day = _hour * 24;
        var timer;

        function showRemaining() {
            var now = new Date();
            var distance = end - now;

            if (distance < 0) {
                clearInterval(timer);

                return;
            }

            var days = Math.floor(distance / _day);
            var hours = Math.floor((distance % _day) / _hour);
            var minutes = Math.floor((distance % _hour) / _minute);

            $this.text("" + days + "d " + hours + "h " + minutes + "m");
        }

        timer = setInterval(showRemaining, 1000);
    });
});

/**
 * Load home category include on ajax call when DOM ready
 */
$(document).ready(function () {
    if ($(".app_uplay").length && $(".home_products_load").length) {
        var url = $(".home_products_load").attr("data-url");

        if (url) {
            $.ajax({
                type: "GET",
                dataType: "html",
                url: url,
                success: function (response) {
                    $(".home_products_load").html(response);
                    selectGamesDLCFilter();
                }
            });
        }
    }
});

/**
 * Method created to replace an existing param value from the url with a new value
 */
function updateQueryStringParameter(uri, key, value) {
    var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
    var separator = uri.indexOf("?") !== -1 ? "&" : "?";

    if (uri.match(re)) {
        return uri.replace(re, "$1" + key + "=" + value + "$2");
    } else {
        return uri + separator + key + "=" + value;
    }
}

/**
 * Event added to automatically select the filters for Games/DLC
 */
$(document).on("uplayDataUpdated", function () {
    if (uplayData != undefined && uplayData["authenticated"] === "true") {
        selectGamesDLCFilter();
    }
});

function selectGamesDLCFilter() {
    if (isUPC) {
        var $productRefinement = $(".product-type-refinement");

        if ($productRefinement.length > 0 && $productRefinement.find("li").length > 0 && $productRefinement.find("li.selected").length == 0) {
            if ($productRefinement.find("li").length == 2) {
                var refineValue1 = $productRefinement.find("li:first a").attr("data-refinement-value"),
                    refineValue2 = $productRefinement.find("li:last a").attr("data-refinement-value");

                if (isNotEmpty(refineValue1) && isNotEmpty(refineValue2)) {
                    var currentURL = $productRefinement.find("li a").attr("href"),
                        newValue = refineValue1 + "|" + refineValue2,
                        newURL = updateQueryStringParameter(currentURL, "prefv1", newValue);

                    if (isNotEmpty(newURL)) {
                        $productRefinement.find("li a").attr("href", newURL);
                        $productRefinement.find("li a").first().trigger("click");
                    }
                }
            } else if ($productRefinement.find("li").length == 1) {
                $productRefinement.find("li a").first().trigger("click");
            }
        }
    }
}

/**
 * Checks if the response is empty or not
 */
function isNotEmpty(response) {
    return response != null && response != undefined && response != "" && response.length > 0;
}

/**
 * It shows on PDP page, a container with a preorder message that is contributed in a content asset, for products in preorder mode
 */
$(document).on("click", ".show-preorder-message-container", function (e) {
    e.preventDefault();
    $(".preorder-advantage-message").fadeIn("slow", function () {
        $(this).removeClass("visually-hidden");
    });
});

/**
 * Hide preorder message on PDP page, for prouducts in preorder mode
 */
$(document).on("click", ".hide-preorder-message-container", function () {
    $(".preorder-advantage-message").fadeOut("slow", function () {
        $(this).addClass("visually-hidden");
    });
});

/**
 *
 * Hides load more button if all products are already displayed
 */
$(document).ajaxComplete(function () {
    if ($(".pt_product-search-result").length) {
        var tilesNumber = $("div.search-result-content .grid-tile").length;
        var total = $(".category_load_more_products").attr("data-product-number");
        var totalCount = isNotEmpty(total) ? parseInt(total, 10) : 0;

        if (totalCount > 0 && totalCount == tilesNumber) {
            $(".category_load_more_products").addClass("visually-hidden");
        }
    }
});

function initProductTile() {
    initCache();

    var $tiles = $(".tiles-container .product-tile");

    if ($tiles.length === 0) { return; }

    imagesLoaded(".tiles-container").on("done", function () {
        // noop
    });

    initializeWishlistEvents();
    initializeEvents();
}

export { initProductTile };
