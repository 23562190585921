import { util } from "./util";

/**
 * @description subscription flow helper functions
 */
const subscriptions = {
    /**
     * @function
     * @description Creates triangle arrow for legal tooltip and sets correct position
    */
    createLegalTooltipArrow: () => {
        const $legalTooltip = $(".uplay-legal-content");
        const $tooltipTrigger = $("#uplay-legal-tooltip");

        if ($legalTooltip.length && $tooltipTrigger.length) {
            const triggerPosition = $tooltipTrigger.position().left;
            const triggerWidth = $tooltipTrigger.width();
            const $triangleArrow = $("<div/>", {
                class: "triangle-arrow"
            });

            $legalTooltip.css("left", (triggerPosition + (triggerWidth / 2) - ($legalTooltip.outerWidth() / 2)));
            $legalTooltip.append($triangleArrow);
        }
    },
    /**
     * @function
     * @param {Object} $submitButton - jquery object button to be enabled/ disabled
     * @param {Object} $tosConditions - jquery object TOS $("input[type='checkbox']") || TOS-container
     * @param {Object} $tosError - jquery object containing error message, to be removed when checkboxes selected
     * @description Enables submit buttons on subscription flows when all TOS checkboxes are checked
     */
    toggleStepBtn: ($submitButton, $tosConditions, $tosError = $(".reactivate-tos-error")) => {
        const $checkboxes = $tosConditions.is("input[type='checkbox']") ? $tosConditions.not(".data-consent") : $tosConditions.find("input[type='checkbox']").not("#data-consent");
        const $tosCheckboxValidated = $checkboxes.filter(":checked");
        // run helper function to know how many checkboxes are displayed
        const tosCheckboxVisible = $tosConditions.is("input[type='checkbox']") ?
            $tosConditions : (util.tosCheckboxVisibleCount($tosConditions) ? util.tosCheckboxVisibleCount($tosConditions) : "");

        // Check if button should be enabled on the review page with empty tosConditions
        // else enable it when all tosConditions are checked and mandatory data provided
        if (($tosCheckboxValidated.length === tosCheckboxVisible.length)) {
            // remove error message and enable button when all checkboxes are checked
            if ($tosError?.length) {
                $tosError.remove();
            }

            $submitButton.prop("disabled", false);
        } else {
            $submitButton.prop("disabled", true);
        }
    },
    /**
     * @function
     * @description trigger a cancellation success page or an error page based on ESS call response
     * @param {Object} data Object describing what modal window to trigger
     */
    showIbexCancellationSuccess: data => {
        if (data?.isSuccess) {
            window.location.href = util.appendParamToURL(Urls.showUbiSubscriptionCancellationSuccess, "subscription", data.subscriptionName);
        } else {
            window.location.href = Urls.showUbiSubscriptionCancellationError;
        }
    },
    /**
     * @function
     * @description function to adjust the position of the reactivation modal depending on the content
     */
    adjustReactivationModalPosition: () => {
        const $reactivationModal = $(".reuse-payment-modal");

        if ($reactivationModal.length) {
            $reactivationModal.css({
                top: (($(window).height() - $reactivationModal.outerHeight()) / 2) + "px"
            });
        }
    }
};

export { subscriptions };
