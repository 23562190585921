import { util } from "../../util";
import { dialog } from "../../dialog";

/**
 * @function
 * @description checks if the user is georestricted, if he hasn't any restrictions add the product to the cart
 * @param {String} pid product id
 */
async function ibexPDPEvents(pid) {
    const dialogOpenedClass = "dialog-opened";
    const $html = $("html");

    const geoRestriction = await fetch(Urls.geoSubscriptionRestriction)
        .then(response => response.text());

    if (geoRestriction !== "false") {
        dialog.open({
            html: geoRestriction,
            options: {
                draggable: false,
                close: () => {
                    $html.removeClass(dialogOpenedClass);
                }
            },
            callback: () => {
                $html.addClass(dialogOpenedClass);

                $("[data-dialog-close]").on("click", () => {
                    dialog.close();
                    $html.removeClass(dialogOpenedClass);
                });
            }
        });
    } else {
        const addProduct = util.appendParamsToUrl(Urls.addProduct, {
            format: "ajax",
            Quantity: 1,
            showPopup: true,
            pid: pid
        });

        await fetch(addProduct).then(response => response.text());

        const returnFromCheckout = window.location.href;

        window.location.href = util.appendParamToURL(Urls.subscriptionBillingStep, "returnFromCheckout", returnFromCheckout);
    }
}

/**
 * @function
 * @description Initializes events on Ibex PDP
 */
const initIbexPDP = () => {
    // Creating the "storecodeinitialized" event
    const event = new Event("storecodeinitialized");
    const $document = $(document);

    // click event on Subscribe CTA on IBEX PDP
    $document.on("click", ".ibex-subscribe", e => {
        e.preventDefault();

        const pid = $(e.currentTarget).data("subscriptionid");

        if (pid) {
            ibexPDPEvents(pid);
        }
    });

    $document.off("click", ".pdp-sticky-ibex").on("click", ".pdp-sticky-ibex", e => {
        e.preventDefault();

        // added parameter "true" to detect if the click event on the Subscribe now CTA was triggered or native (if true, then it's triggered)
        $(".ibex-subscribe").trigger("click", true);
    });

    // Dispatching the event
    document.dispatchEvent(event);
};

export { initIbexPDP };
