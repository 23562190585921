/**
 * @function
 * @description Initialize the events for push tiles
 */
const initPushTiles = () => {
    $(".pushes.double a.thumb-link").on("focusin", e => {
        const $doublePushPricingSection = $(e.currentTarget).parents(".double").find(".double-push-pricing-section");

        if ($doublePushPricingSection.length) {
            $doublePushPricingSection.addClass("push-tile-focused");
        }
    }).on("focusout", e => {
        const $doublePushPricingSection = $(e.currentTarget).parents(".double").find(".double-push-pricing-section");

        if ($doublePushPricingSection.length) {
            $doublePushPricingSection.removeClass("push-tile-focused");
        }
    });

    // redirect the user from product tile to PDP
    $(document).on("click keyup", ".product-tile, .product-tile .hover-card", e => {
        if ((e.type === "click" || e.key === "Enter") && (e.target.tagName !== "svg") && (e.target.tagName !== "path") && (e.target.tagName !== "BUTTON") && (e.target.tagName !== "A")) {
            const $this = $(e.currentTarget);
            let href = "";

            if ($this.hasClass("product-tile")) {
                href = $this.data("href");
            } else if ($this.hasClass("hover-card")) {
                href = $this.closest(".product-tile").data("href");
            }

            if (href) {
                window.location.href = href;
            }
        }
    });

    // correct the focus on back way - include the button to be focused inside the hover card of previous product tile
    $("#main").on("keydown", ".product-tile", e => {
        if (e.key === "Tab" && e.shiftKey) {
            const $this = $(e.target);

            // not apply to wishlist or push tiles since back focus order is working correctly there
            if ($this.closest(".wishlist-items-list").length || $this.closest(".push-product").length) {
                return;
            }

            if ($this.hasClass("product-tile")) {
                let $prevTile = $this.parents(".grid-tile").prev();

                if ($prevTile[0]?.tagName === "SCRIPT") {
                    $prevTile = $prevTile.prev();
                }

                const $prevSlide = $this.parents(".slick-slide").prev(".slick-slide");
                const $prevTilebutton = $prevSlide.length ? $prevSlide.find(".hover-card .button") : $prevTile.find(".hover-card .button");

                if ($prevTilebutton.length) {
                    // remove yellow focus before focusing the button to avoid flickering of yellow background of tile
                    let $keyBoardFocus = $this.closest(".keyboard-focus.keyboard-focus--yellow");

                    $keyBoardFocus.removeClass("keyboard-focus keyboard-focus--yellow");

                    setTimeout(() => {
                        $keyBoardFocus.addClass("keyboard-focus keyboard-focus--yellow");
                        $prevTilebutton.trigger("focus");
                    }, 50);
                }
            }
        }
    });
};

export { initPushTiles };
