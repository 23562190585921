import "masonry-layout";
import "jquery-ui/ui/widgets/dialog";
import { jRes } from "../../jRes";
import { uskVideo } from "../../usk18";
import { initPrivacyPopin } from "../../initPrivacyPopin";

var $cache = {};

var initCache = function () {
    $cache = {
        body: $("body, html"),
        document: $(document),
        readMore: $(".pt_product-details .read-more button"),
        productDescription: $("#summary-section .product-description"),
        productDescriptionContent: $(".description-content"),
        masonryContainer: $(".masonry-container"),
        masonryItems: $(".media-image"),
        keyFeaturesSection: $("#key-features-section"),
        sizeTabSection: $("#sizechart-section"),
        sizeChart: $("#size-chart"),
        pdpPlayVideo: $(".pt_product-details .video"),
        closedBetaDialog: $("#closedBetaConfirmation"),
        dialogBackButton: $(".closed-beta-confirmation .back-btn")
    };
};

var initMediaSection = function () {
    var $lastImageClone = $($cache.masonryItems[$cache.masonryItems.length - 1]).clone();

    $cache.masonryContainer.find("ul").prepend($lastImageClone);
    $lastImageClone.removeClass("masonry-img").addClass("masonry-grid-sizer");
    $cache.masonryContainer.masonry({
        itemSelector: ".masonry-img",
        columnWidth: ".masonry-grid-sizer",
        fitWidth: true
    });
    $lastImageClone.hide();
};

var mediaSectionListeners = function () {
    var videoHeight = 480,
        hostedVideo = $(".hosted-video");

    if (!$(".app_uplay").length) {
        $(window).load(function () {
            $cache.masonryItems.on("click", function () {
                var masonryImagesLength = $(".masonry-img").length;

                if (masonryImagesLength > 1 && $(".masonry-img").index(this) === masonryImagesLength - 1) {
                    $(this).insertBefore($($(".masonry-img")[masonryImagesLength - 2]));
                    $cache.masonryContainer.masonry("reloadItems");
                }

                if (masonryImagesLength > 3 && $(".masonry-img").index(this) === 0) {
                    $(this).insertAfter($($(".masonry-img")[1]));
                    $cache.masonryContainer.masonry("reloadItems");
                }

                if ($(this).hasClass("large-masonry-image")) {
                    $cache.masonryItems.removeClass("large-masonry-image");
                } else {
                    $cache.masonryItems.removeClass("large-masonry-image");
                    $(this).addClass("large-masonry-image");
                }

                $cache.masonryContainer.masonry("destroy");
                $cache.masonryContainer.masonry();
                $cache.masonryContainer.masonry("layout");
            });

            $(window).on("openMediaSection", function () {
                $cache.masonryContainer.masonry("destroy");
                $cache.masonryContainer.masonry();
                $cache.masonryContainer.masonry("layout");
            });
        });
    }

    $cache.document.on("click", ".pt_product-details .video, .c-pdp-media-slider__video-trigger", e => {
        var ytVideo = null,
            twVideo = null,
            ytVideoSrc = null,
            twVideoSrc = null;

        const $this = $(e.currentTarget);
        const ConsentResources = window.ConsentResources || {};
        const $ageClassificationPage = $(".age-classification-page.underaged-user");
        const checkVideoAccess = $ageClassificationPage.length > 0 && SitePreferences.ENABLE_AGE_GATE_FEATURE && SitePreferences.ENABLE_AGE_GATE_RESTRICTION && Resources.AGEGATE_VERSION === "v1";

        if (ConsentResources.videoConsent || !SitePreferences.ENABLE_COMPLIANCE_V2) {
            // check if video is restricted (if user is minor)
            if (checkVideoAccess) {
                document.dispatchEvent(new CustomEvent("AgeGate:CheckVideoAccess"));
            } else {
                if ($this.hasClass("c-pdp-media-slider__video-trigger")) {
                    document.dispatchEvent(new CustomEvent("PHP:VideoPlay"));
                } else {
                    // init YT video in case consent was accepted
                    $this.find(".section-video").dialog({
                        width: 640,
                        height: videoHeight,
                        dialogClass: "dialog-video keyboard-focus keyboard-focus--yellow",
                        modal: true,
                        open: function () {
                            var that = $(this),
                                ageQuestion = that.find(".age-question"),
                                ageAnswer = that.find(".age-answer"),
                                twVideoDialog = $(".dialog-video .twVideo");

                            ytVideo = $(".dialog-video").find(".video-iframe");
                            twVideo = $(".dialog-video .twVideo").find("iframe");

                            if (ytVideo.length) {
                                ytVideoSrc = ytVideo.data("url");

                                if (SitePreferences.ENABLE_USK) {
                                    uskVideo(ageQuestion, ageAnswer, ytVideo);
                                } else {
                                    ytVideo.attr("src", ytVideoSrc + "&autoplay=1");
                                }
                            }

                            if (twVideo.length) {
                                twVideoSrc = twVideo.attr("src");

                                if (SitePreferences.ENABLE_USK) {
                                    uskVideo(ageQuestion, ageAnswer, twVideoDialog);
                                } else {
                                    twVideo.attr("src", twVideoSrc.replace("!autoplay", "autoplay"));
                                }
                            }

                            $(".ui-widget-overlay").bind("click", function () {
                                if (ytVideo.length) {
                                    ytVideo.attr("src", ytVideoSrc);
                                }

                                if (twVideo.length) {
                                    twVideo.attr("src", twVideoSrc);
                                }

                                that.dialog("destroy");
                            });

                            if (ageQuestion.is(":visible")) {
                                $(".section-video").css({
                                    height: "auto"
                                });

                                $(".appropriate-age-yes").on("click", function () {
                                    $(".section-video").css({
                                        height: videoHeight
                                    });
                                });
                            }

                            if (hostedVideo.length > 0) {
                                hostedVideo.trigger("load");
                            }
                        },
                        close: function () {
                            if (ytVideo.length) {
                                ytVideo.attr("src", ytVideoSrc);
                            }

                            if (twVideo.length) {
                                twVideo.attr("src", twVideoSrc);
                            }

                            $(this).dialog("destroy");

                            if (hostedVideo.length > 0) {
                                hostedVideo.trigger("pause");
                            }
                        }
                    });
                }
            }
        } else {
            // display consent popin in case video was not accepted
            initPrivacyPopin();
        }
    });

    if (hostedVideo.length > 0) {
        hostedVideo.focusout(function () {
            hostedVideo.trigger("pause");
        });
    }
};

var keyFeaturesListeners = function () {
    var $accordionThumb = $(".key-features-section-accordion-thumb"),
        $tabThumb = $(".key-features-section-thumb"),
        $sizeTabThumb = $(".sizechart-section-thumb"),
        $sizeAccordionThumb = $(".sizechart-section-accordion-thumb");

    $("#product-content").on("click touchstart", ".size-chart-anchor", function (ev) {
        ev.preventDefault();

        if ($cache.keyFeaturesSection.is(":visible")) {
            $cache.body.animate({
                scrollTop: $cache.sizeChart.offset().top
            }, 500);
        } else {
            if (jRes.isMobilePortrait || jRes.isMobileLandscape) {
                $accordionThumb.click();
                setTimeout(function () {
                    $cache.body.animate({
                        scrollTop: $cache.sizeChart.offset().top
                    }, 500);
                }, 1000);
            } else {
                $tabThumb.click();
                $cache.body.animate({
                    scrollTop: $cache.sizeChart.offset().top
                }, 500);
            }
        }
    });

    $("#product-content").on("click touchstart", ".size-tab-link", function () {
        if ($cache.sizeTabSection.is(":visible")) {
            $cache.body.animate({
                scrollTop: $cache.sizeTabSection.offset().top
            }, 500);
        } else {
            if (jRes.isMobilePortrait || jRes.isMobileLandscape) {
                $sizeAccordionThumb.click();
                setTimeout(function () {
                    $cache.body.animate({
                        scrollTop: $cache.sizeTabSection.offset().top
                    }, 500);
                }, 1000);
            } else {
                $sizeTabThumb.click();
                $cache.body.animate({
                    scrollTop: $cache.sizeTabSection.offset().top
                }, 500);
            }
        }
    });
};

var initDOM = function () {
    if (!$(".app_uplay").length) {
        $(window).load(function () {
            initMediaSection();
        });
    }
};

var eventListeners = function () {
    mediaSectionListeners();
    keyFeaturesListeners();

    $cache.dialogBackButton.on("click", function () {
        $cache.closedBetaDialog.dialog("close");
    });
};

function initTabs() {
    initCache();
    initDOM();
    eventListeners();
}

export { initTabs };
