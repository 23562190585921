/**
 * @function
 * @description Removes slot container element if it's empty(asset is not configured for the slot)
 */
const removeEmptyPencilLineContainer = () => {
    const $pencilLineSlotContainer = $(".header-pencil-line").find(".html-slot-container");

    if ($pencilLineSlotContainer.length) {
        $pencilLineSlotContainer.each((index, element) => {
            if ($(element).children().length === 0) {
                $(element).remove();
            }
        });
    }
};

/**
 * @function
 * @description initializes header pencil line
 */
const initHeaderPencil = () => {
    removeEmptyPencilLineContainer();
};

export { initHeaderPencil };
