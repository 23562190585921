import "jquery-ui/ui/widgets/tooltip";

var $cache = {
    document: $(document)
};
var timer;

/**
 * @function
 * @description Initializes the tooltip-content and layout
 */

function initTooltip() {
    $cache.document.tooltip({
        items: ".tooltip",
        track: true,
        content: function () {
            return $(this).find(".tooltip-content").html();
        }
    });
}

// function used to animate tooltip show
function showTooltip($element) {
    if ($element) {
        const $uniqueTooltip = $(".unique-tooltip-content");

        if ($uniqueTooltip.length) {
            $uniqueTooltip.addClass("is-visible");
        } else {
            $element.addClass("is-visible");
        }

        $element.attr("aria-hidden", false);
    }
}

// function used to animate tooltip hide
function hideTooltip($element, delay) {
    if ($element) {
        if (delay != 0) {
            timer = setTimeout(() => {
                $element.removeClass("is-visible");
                $cache.tooltipTrigger.removeClass("active-trigger");
            }, delay);
        } else {
            $element.removeClass("is-visible");
            $cache.tooltipTrigger.removeClass("active-trigger");
        }

        $element.attr("aria-hidden", true);
    }
}

// function used to hide all tooltips
function hideAllTooltips() {
    $(".tooltip-content.is-visible").removeClass("is-visible");
    clearTimeout(timer);
}

// initialize cache object
function initializeCache() {
    $cache.tooltipTrigger = $(document).find(".tooltip-trigger");
    $cache.tooltipContent = $(document).find(".tooltip-content");
}

// initialize tooltip events
function initializeEvents() {
    $cache.document.off(".tooltipTrigger").on("mouseenter.tooltipTrigger focus.tooltipTrigger", ".tooltip-trigger", e => {
        const $this = $(e.currentTarget);
        let $element;

        if ($this.attr("data-productid")) {
            $element = $('.tooltip-content[data-pid="' + $this.attr("data-productid") + '"]').not('[data-block="true"]');
        } else {
            $element = $('.tooltip-content[data-tooltip-target="' + $this.attr("id") + '"]').not('[data-block="true"]');
            $this.find(".tooltip-content").not('[data-block="true"]').addClass("unique-tooltip-content");
        }

        hideAllTooltips();
        showTooltip($element);
        $this.addClass("active-trigger");
    }).on("mouseleave.tooltipTrigger blur.tooltipTrigger", ".tooltip-trigger", e => {
        const $this = $(e.currentTarget);
        let $element;

        if ($this.attr("data-productid")) {
            $element = $('.tooltip-content[data-pid="' + $this.attr("data-productid") + '"]');
        } else {
            $element = $('.tooltip-content[data-tooltip-target="' + $this.attr("id") + '"]');
            $this.find(".tooltip-content").removeClass("unique-tooltip-content");
        }

        var delay = $element.attr("data-hide-delay") ? $element.attr("data-hide-delay") : 0,
            $relatedTarget = $(e.relatedTarget);

        if (!$relatedTarget.hasClass("tooltip-content")) {
            if ($relatedTarget.parents(".tooltip-content").length == 0) {
                hideTooltip($element, delay);
            }
        }
    });

    $cache.document.off(".tooltipContent").on("mouseenter.tooltipContent focus.tooltipContent", ".tooltip-content", () => {
        clearTimeout(timer);
    }).on("mouseleave.tooltipContent blur.tooltipContent", ".tooltip-content", e => {
        const $this = $(e.currentTarget),
            delay = $this.attr("data-hide-delay") ? $this.attr("data-hide-delay") : 0;

        hideTooltip($this, delay);
    });

    // close the expandable section on ESC
    $cache.document.off("keydown.tooltip-close").on("keydown.tooltip-close", e => {
        const $activeTooltipContent = $(".tooltip-content.is-visible");

        if (e.key === "Escape" && $activeTooltipContent.length) {
            e.stopPropagation();

            const delay = $activeTooltipContent.attr("data-hide-delay") ? $activeTooltipContent.attr("data-hide-delay") : 0;

            hideTooltip($activeTooltipContent, delay);
        }
    });
}

function initProductTooltip() {
    initializeCache();
    initializeEvents();
}

export { initTooltip, initProductTooltip };
