import {initializeSubscriptionCancellationEvents} from "./subscriptioncancellation_events";
import {initializeAccountSubscriptionEvents} from "./accountsubscription_events";
import {initializeSubsCheckoutEvents} from "./subscriptioncheckout_events";
import {initializeSubSwitchEvents} from "./subscriptionswitch_events";
import {initializeSubNotificationsEvents} from "./subscriptionnotification_events";
import {initializePlanSelectionEvents} from "./subscriptionselection_events";
import {initializeAlgoliaEvents} from "./algolia_events";
import {isProductOwnership} from "./tagcommander_helper";

var IntersectionObserver = require("intersection-observer-polyfill/dist/IntersectionObserver");

var $document = $(document);
var enabledSubscription = SitePreferences.ENABLE_SUBSCRIPTION || false;
var enabledSubscriptionTagCommander = SitePreferences.ENABLE_SUBSCRIPTION_TAG_COMMANDER || false;
var enabledUPCSubscriptionTagCommander = SitePreferences.ENABLE_UPC_SUBSCRIPTION_TAG_COMMANDER || false;

/**
 * Executes adobe analytics click events with the given parameters
 * @param {Object} event object that create when event is triggered
 * @param {*} eventParams parameters to populate the click event data. usually placed in the DOM element
 * @param {String} sectionTitle title to use for click event action
 * @param {*} href link of click event
 * @param {*} productClick indicates if clicked on a product
 */
const clickEvent = (event, eventParams, sectionTitle, href, productClick) => {
    eventParams = replaceSpecialCharacters(JSON.stringify(checkParamsHasOwnerShip(eventParams)));

    if (adobeAnalytics) {
        if (productClick) {
            adobeAnalytics.track("clickevent", {
                location: eventParams.location,
                locationPosition: eventParams.locationPosition,
                locationDetail: eventParams.locationDetail,
                category: eventParams.category,
                slotNumber: eventParams.slotNumber,
                action: eventParams.action === undefined ? event.innerText || event.textContent || href : eventParams.action,
                adultGame: eventParams.productSalesType !== "subscription" ? eventParams.adultGame : "",
                productDiscountRate: eventParams.productSalesType !== "subscription" ? (eventParams.productDiscountRate || eventParams.discountRate || 0.0) : "",
                productID: eventParams.productID || eventParams.productId,
                productId: eventParams.productID || eventParams.productId,
                productName: eventParams.productName,
                productEdition: eventParams.productSalesType !== "subscription" ? eventParams.productEdition : "",
                productPlatform: eventParams.productSalesType !== "subscription" ? eventParams.productPlatform : "",
                productSalesType: eventParams.productSalesType,
                productIsWishlist: eventParams.productIsWishlist,
                productDiscountName: eventParams.productDiscountName,
                productDiscount: eventParams.productDiscount,
                isCartAdd: eventParams.isCartAdd,
                isCartRemove: eventParams.isCartRemove,
                isCartOpen: eventParams.isCartOpen,
                isProductClicked: eventParams.isProductClicked,
                isWishlistAdd: eventParams.isWishlistAdd,
                isWishlist: eventParams.productSalesType !== "subscription" ? eventParams.isWishlist : "",
                productOwnership: eventParams.productSalesType !== "subscription" ? eventParams.productOwnership : "",
                productDemo: eventParams.productSalesType !== "subscription" ? eventParams.productDemo : "",
                productFreeWeekEnd: eventParams.productSalesType !== "subscription" ? eventParams.productFreeWeekEnd : "",
                ...(eventParams?.totalResults) && {
                    totalResults: eventParams.totalResults
                },
                ...(eventParams?.searchKeyword) && {
                    searchKeyword: eventParams.searchKeyword
                },
                ...(eventParams?.pagination) && {
                    pagination: eventParams.pagination
                },
                ...(eventParams?.searchQueryId) && {
                    searchQueryId: eventParams.searchQueryId
                },
                ...(eventParams?.searchQueryLength) && {
                    searchQueryLength: eventParams.searchQueryLength
                },
                ...(eventParams?.searchOrigin) && {
                    searchOrigin: eventParams.searchOrigin
                },
                ...(eventParams?.searchAbTesting) && {
                    searchAbTesting: eventParams.searchAbTesting
                }
            });
        } else {
            adobeAnalytics.track("clickevent", {
                location: eventParams.location,
                ...(eventParams?.locationPosition) && {
                    locationPosition: eventParams.locationPosition
                },
                ...(eventParams?.locationDetail) && {
                    locationDetail: eventParams.locationDetail
                },
                category: eventParams.category,
                ...(eventParams?.slotNumber) && {
                    slotNumber: eventParams.slotNumber
                },
                ...(eventParams?.isCartAdd) && {
                    isCartAdd: eventParams.isCartAdd
                },
                ...(eventParams?.isCartOpen) && {
                    isCartOpen: eventParams.isCartOpen
                },
                ...(eventParams?.isProductClicked) && {
                    isProductClicked: eventParams.isProductClicked
                },
                ...(eventParams?.productId) && {
                    productId: eventParams.productId
                },
                ...(eventParams?.productDiscount) && {
                    productDiscount: eventParams.productDiscount
                },
                ...(eventParams?.productName) && {
                    productName: eventParams.productName
                },
                ...(eventParams?.productPlatform) && {
                    productPlatform: eventParams.productPlatform
                },
                ...(eventParams?.productSalesType) && {
                    productSalesType: eventParams.productSalesType
                },
                ...(eventParams?.totalResults) && {
                    totalResults: eventParams.totalResults
                },
                ...(eventParams?.searchKeyword) && {
                    searchKeyword: eventParams.searchKeyword
                },
                ...(eventParams?.pagination) && {
                    pagination: eventParams.pagination
                },
                ...(eventParams?.searchQueryId) && {
                    searchQueryId: eventParams.searchQueryId
                },
                ...(eventParams?.searchQueryLength) && {
                    searchQueryLength: eventParams.searchQueryLength
                },
                ...(eventParams?.searchOrigin) && {
                    searchOrigin: eventParams.searchOrigin
                },
                ...(eventParams?.searchAbTesting) && {
                    searchAbTesting: eventParams.searchAbTesting
                },
                action: eventParams.action === undefined ? event.innerText || event.textContent || sectionTitle || href : eventParams.action
            });
        }
    }
};

/**
 * Executes adobe analytics impressionevent events with the given parameters
 * @param {Object} event object that create when event is triggered
 * @param {*} eventParams parameters to populate the impressionevent event data. usually placed in the DOM element
 */
function impressionEvent(event, eventParams) {
    var action = event ? event.innerText || event.textContent : "";
    var eventsObj = {};

    eventParams = replaceSpecialCharacters(JSON.stringify(eventParams));

    if (eventParams) {
        for (var key in eventParams) {
            if (eventParams.action === undefined) {
                eventsObj["action"] = action;
            }

            eventsObj[key] = eventParams[key];
        }
    }

    if (typeof adobeAnalytics !== "undefined") {
        adobeAnalytics.track("impressionevent", eventsObj);
    }
}

/**
 * Executes adobe analytics pageEvent events with the given parameters
 * @param {Object} event object that create when event is triggered
 * @param {*} eventParams parameters to populate the pageEvent event data. usually placed in the DOM element
 */
const pageEvent = eventParams => {
    eventParams = checkParamsHasOwnerShip(eventParams);

    if (adobeAnalytics) {
        adobeAnalytics.track("pageevent", {
            ...(eventParams?.pageName) && {
                pageName: eventParams.pageName
            },
            ...(eventParams?.pageUrl) && {
                pageUrl: eventParams.pageUrl
            },
            ...(eventParams?.siteSection) && {
                siteSection: eventParams.siteSection
            },
            ...(eventParams?.siteSubSection) && {
                siteSubSection: eventParams.siteSubSection
            },
            ...(eventParams?.ubisoftId) && {
                ubisoftId: eventParams.ubisoftId
            },
            ...(eventParams?.brand) && {
                brand: eventParams.brand
            },
            ...(eventParams?.brandName) && {
                brandName: eventParams.brandName
            },
            ...(eventParams?.game) && {
                game: eventParams.game
            },
            ...(eventParams?.mdmBrand) && {
                mdmBrand: eventParams.mdmBrand
            },
            ...(eventParams?.mdmGame) && {
                mdmGame: eventParams.mdmGame
            },
            ...(eventParams?.mdmBID) && {
                mdmBID: eventParams.mdmBID
            },
            ...(eventParams?.mdmBrandId) && {
                mdmBrandId: eventParams.mdmBrandId
            },
            ...(eventParams?.mdmInstallmentId) && {
                mdmInstallmentId: eventParams.mdmInstallmentId
            },
            ...(eventParams?.installmentName) && {
                installmentName: eventParams.installmentName
            },
            ...(eventParams?.errorType) && {
                errorType: eventParams.errorType
            },
            ...(eventParams?.productId) && {
                productId: eventParams.productId
            },
            ...(eventParams?.productDiscountRate) && {
                productDiscountRate: eventParams.productDiscountRate
            },
            ...(eventParams?.productName) && {
                productName: eventParams.productName
            },
            ...(eventParams?.productEdition) && {
                productEdition: eventParams.productEdition
            },
            ...(eventParams?.productPlatform) && {
                productPlatform: eventParams.productPlatform
            },
            ...(eventParams?.productSalesType) && {
                productSalesType: eventParams.productSalesType
            },
            ...(eventParams?.isWishlist) && {
                isWishlist: eventParams.isWishlist
            },
            ...(eventParams?.productDiscountName) && {
                productDiscountName: eventParams.productDiscountName
            },
            ...(eventParams?.productDiscount) && {
                productDiscount: eventParams.productDiscount
            },
            ...(eventParams?.isProdView) && {
                isProdView: eventParams.isProdView
            },
            ...(eventParams?.productOwnership) && {
                productOwnership: eventParams.productOwnership
            },
            ...(eventParams?.adultGame) && {
                adultGame: eventParams.adultGame
            },
            ...(eventParams?.cartId) && {
                cartId: eventParams.cartId
            },
            ...(eventParams?.productCart) && {
                productCart: eventParams.productCart
            },
            ...(eventParams?.cartStatus) && {
                cartStatus: eventParams.cartStatus
            },
            ...(eventParams?.isCartView) && {
                isCartView: eventParams.isCartView
            },
            ...(eventParams?.orderItemNumber) && {
                orderItemNumber: eventParams.orderItemNumber
            },
            ...(eventParams?.orderUnitNumber) && {
                orderUnitNumber: eventParams.orderUnitNumber
            }
        });
    }
};

/**
 * @function
 * @description change special characters - single and double quote - on tracking params
 * @param { Object } trackingParams get default tracking params
 * @returns { Object } replaced characters and return an object
 */
const replaceSpecialCharacters = trackingParams => {
    var oldCharacters = [/&#34;/g, /&#39;/g];
    var newCharacters = ["'", '\\"'];

    for (var i = 0; i < oldCharacters.length; i++) {
        trackingParams = trackingParams.replace(oldCharacters[i], newCharacters[i]);
    }

    return JSON.parse(trackingParams);
};

/**
 * Initialize Adobe analytics page events function
 */
function initializeAdobeAnalyticsPageEvents() {
    adobeAnalyticsPageEvents();
}

/**
 * Initialize Adobe analytics events function
 */
function initializeAdobeAnalyticsEvents() {
    adobeAnalyticsEvents();
}

/**
 * events that used for adobe analytics. Registered after 'S_CODE_READY' message received.
 */
function adobeAnalyticsPageEvents() {
    // process if page has redeem gift card input
    if ($(".experience-component.experience-ubisoft-ubisoftredeemgiftcard").length > 0) {
        if (!$(".experience-component.experience-ubisoft-ubisoftredeemgiftcard").is(":empty")) {
            var redeemPageData = {
                pageName: "redeem page",
                siteSection: "gift card",
                siteSubSection: "logged in"
            };

            pageEvent(redeemPageData);
        }
    }

    // process if customer on prepaid landing page
    var $prepaidCardButton = $("a.prepaidcard__button");

    if ($prepaidCardButton.length > 0) {
        var landingParams = $prepaidCardButton.data("params");
        var landingPageData = {
            pageName: "landing page",
            siteSection: "gift card",
            ubisoftId: window.User?.ubisoftId,
            productID: landingParams.productID
        };

        pageEvent(landingPageData);
    }

    // process if subscription withdrawal error
    $document.on("withdrawalDenied", () => {
        const pageParam = $(".l-withdrawal-first-step").data("page-params");

        if (pageParam) {
            pageParam.pageName = "request failed";
            pageEvent(pageParam);
        }
    });

    // Fired at pageload, process if page has pageevent
    if ($("[data-analytics-page=adobeAnalytics]").length > 0) {
        getTrackingPageObject();
    }

    // page event for CLP page(WEB & UPC)
    const $searchResultContent = $(".search-result-content");

    if ($searchResultContent.length > 0) {
        const pageName = $searchResultContent[0].dataset.cid;
        const isLoggedIn = window?.User?.isLoggedIn;
        const pageParams = {
            pageName: pageName ? pageName.replace(/-/g, " ") : "",
            pageUrl: window.location.href,
            siteSection: "category pages",
            ubisoftId: isLoggedIn ? window?.User?.ubisoftId : ""
        };

        pageEvent(pageParams);
    }
}

const getTrackingPageObject = params => {
    if (params === undefined) {
        params = $("[data-analytics-page=adobeAnalytics]").data("page-params");
    }

    if (params && (params.siteSection === "product pages" || params.pageType === "generic layout page")) {
        delete params.pageType;
        params.ubisoftId = window?.User?.ubisoftId || "";
        params.pageUrl = window.location.href || params.pageUrl;

        // Get productOwnership
        const userOwnershipObj = window?.User?.ownership || null;
        const productLauncherID = $("[data-analytics-page=adobeAnalytics]").data("product-launcher-id") || "";

        if (params.siteSection === "product pages") {
            params.productOwnership = (isProductOwnership(userOwnershipObj, productLauncherID)) ? "yes" : "no";
        }

        adobeAnalytics.track("pageevent", params);
    } else if (params && params.productId && $(".subscription-tracking").length < 1) {
        getProductDataById(params, params.productId, null, "page");
    } else {
        pageEvent(params);
    }
};

/**
 * events that used for adobe analytics. Registered after 'PAGEVIEW_EVENT_FIRED' message received.
 */
function adobeAnalyticsEvents() {
    if (enabledSubscription && (enabledSubscriptionTagCommander || enabledUPCSubscriptionTagCommander)) {
        initializeSubscriptionCancellationEvents();
        initializeAccountSubscriptionEvents();
        initializeSubsCheckoutEvents();
        initializeSubSwitchEvents();
        initializeSubNotificationsEvents();
        initializePlanSelectionEvents();

        document.dispatchEvent(new Event("AdobeAnalytics:Initialized"));
    }

    $document.off(".adobeAnalytics");
    // process if click event has adobe analytics tag
    $document.on("click.adobeAnalytics", "[data-analytics-click=adobeAnalytics]", function (e) {
        const locationPosition = $(this).closest("[data-order]");
        const sendEvent = adobeAnalyticsToggle($(this));
        const eventParams = JSON.parse($(this).attr("data-params"));
        const href = $(this).attr("href");

        if (!eventParams || typeof eventParams !== "object" || eventParams === null || !sendEvent) {
            return;
        }

        const dataOrder = locationPosition.attr("data-order");

        if (dataOrder !== undefined && dataOrder !== null && dataOrder !== "" && (eventParams.locationPosition === "" || eventParams.locationPosition === undefined)) {
            eventParams.locationPosition = dataOrder;
        }

        if (locationPosition && eventParams.location === "") {
            eventParams.location = locationPosition.data("analytics-pdp-params").location;
        }

        if (eventParams.slotNumber === "replaceDynamically") {
            var paging = $(".c-pdp-media-slider__main-paging");

            if (paging[0] && paging[0].innerHTML) {
                eventParams.slotNumber = paging[0].innerHTML.split("/")[0];
            } else {
                eventParams.slotNumber = "";
            }
        }

        // not a product click and sectionTitle is not used for action
        clickEvent(e, eventParams, null, href, false);
    });

    // process if click event has adobe analytics tag
    $document.on("click.adobeAnalytics", "[data-analytics-section-click] a:not(.ui-tabs-anchor)", function (e) {
        var element = $(this);
        const locationPosition = element.closest("[data-order]");
        const href = element.attr("href");
        const sectionName = element.attr("data-section-name");
        const eventParams = element.closest("[data-analytics-section-click=adobeAnalytics]").data("params");
        const dataOrder = locationPosition.attr("data-order");

        if (eventParams && (dataOrder != null || dataOrder !== undefined)) {
            eventParams.locationPosition = dataOrder;
        }

        clickEvent(e, eventParams, sectionName, href);
    });

    // process if click event has adobe product click tag
    $document.on("click.adobeAnalytics", "[data-analytics-product-click]", function () {
        var element = $(this);
        const locationPosition = element.closest("[data-order]");
        const parentParams = element.closest("[data-track-impression]") || element.closest("[data-analytics-pdp-params]");
        const eventParams = element.closest("[data-analytics-product-click=adobeAnalytics]").data("product-params");
        const dataOrder = locationPosition.attr("data-order");

        if (eventParams && (dataOrder != null || dataOrder !== undefined)) {
            eventParams.locationPosition = eventParams.locationPosition === "" ? dataOrder : eventParams.locationPosition;

            if (eventParams.location === null || eventParams.location === undefined) {
                eventParams.location = parentParams.data("impression-params")?.location || parentParams.data("analytics-pdp-params")?.location;
            }
        }

        getProductDataById(eventParams, eventParams.productId, null, "click");
    });

    // process if click event has adobe product click tag
    $document.on("click.adobeAnalytics", "[data-analytics-product-section-click] a", function () {
        var element = $(this);
        const slotNumber = element.closest("[data-slick-index]").data("slick-index");
        const locationPosition = element.closest("[data-order]");
        const eventParams = element.closest("[data-product-params]").data("product-params");
        const productId = element.closest(".product-tile").attr("data-itemid");
        const dataOrder = locationPosition.attr("data-order");

        if (eventParams && (dataOrder != null || dataOrder !== undefined) && (slotNumber != null || slotNumber !== undefined)) {
            eventParams.slotNumber = slotNumber + 1;
            eventParams.locationPosition = dataOrder;
            eventParams.location = eventParams.location == "" ? locationPosition.data("analytics-pdp-params").location : eventParams.location;
        }

        getProductDataById(eventParams, productId, null, "click");
    });

    // process if click event has adobe product click tag
    $document.on("click.adobeAnalytics", "[data-analytics-product-wishlist-click=adobeAnalytics]", e => {
        e.stopPropagation();

        let element = $(e.target);

        if (element.closest("[data-analytics-product-wishlist-click=adobeAnalytics] button")) {
            // If click was on a different button within the product tile, don't send the event
            return;
        }

        const locationPosition = element.closest("[data-order]");
        const parentParams = element.closest("[data-track-impression]") || element.closest("[data-analytics-pdp-params]");
        const eventParams = element.closest("[data-analytics-product-wishlist-click=adobeAnalytics]").data("product-params");
        const dataOrder = locationPosition.attr("data-order");
        let slotNumber;

        if (eventParams && (dataOrder != null || dataOrder !== undefined)) {
            eventParams.locationPosition = eventParams.locationPosition === "" ? dataOrder : eventParams.locationPosition;
            eventParams.location = parentParams.data("impression-params")?.location || parentParams.data("analytics-pdp-params")?.location || eventParams.location;
        }

        if (eventParams.slotNumber === undefined && !eventParams.skipSlotNumber || eventParams.slotNumber === null) {
            if (Resources.IS_UPC_STORE) {
                const slotNumberList = element.closest(".c-tile--upc");
                const slotNumberString = slotNumberList.length > 0 ? element.closest(".c-tile--upc")[0].id : "";

                slotNumber = parseInt(slotNumberString.substring(slotNumberString.length - 2), 10) || undefined;

                // calculating slotNumber for producttile when data-slick-index value is not found
                if (slotNumber === undefined) {
                    let productIdList = [];
                    const myBenefitsWishlistProducts = $(".mybenefits__wishlist-products .c-tile--upc .product-tile");
                    const wishlistItemsCarousel = $(".wishlist-items-carousel .c-tile--upc .product-tile");
                    const wishlistItemsList = $(".wishlist-items-list .c-tile--upc .product-tile");
                    const wishlistProductList = wishlistItemsCarousel.length > 0 ? wishlistItemsCarousel : wishlistItemsList.length > 0 ? wishlistItemsList : myBenefitsWishlistProducts;

                    if (wishlistProductList.length > 0) {
                        wishlistProductList.toArray().forEach(function (w) {
                            productIdList.push($(w).data("itemid"));
                        });

                        const slotNumberIndex = productIdList.indexOf(eventParams.productId || eventParams.productID);

                        slotNumber = slotNumberIndex !== -1 ? slotNumberIndex + 1 : undefined;
                    }
                }
            } else {
                slotNumber = element.closest(".c-tile--web").data("slick-index");

                // calculating slotNumber for producttile when data-slick-index value is not found
                if (slotNumber === undefined) {
                    let productIdList = [];
                    const myBenefitsWishlistProducts = $(".mybenefits__wishlist-products .c-tile--web .product-tile");
                    const wishlistItemsCarousel = $(".wishlist-items-carousel .c-tile--web .product-tile");
                    const wishlistItemsList = $(".wishlist-items-list .c-tile--web .product-tile");
                    const wishlistProductList = wishlistItemsCarousel.length > 0 ? wishlistItemsCarousel : wishlistItemsList.length > 0 ? wishlistItemsList : myBenefitsWishlistProducts;

                    if (wishlistProductList.length > 0) {
                        wishlistProductList.toArray().forEach(function (w) {
                            productIdList.push($(w).data("itemid"));
                        });

                        const slotNumberIndex = productIdList.indexOf(eventParams.productId || eventParams.productID);

                        slotNumber = slotNumberIndex !== -1 ? slotNumberIndex + 1 : undefined;
                    }
                }
            }

            eventParams.slotNumber = slotNumber;
        }

        if (element.closest("p").context.classList.contains("see-more")) {
            eventParams.category = "action";
            eventParams.action = "product : see more";
            eventParams.isProductClicked = 1;
            eventParams.isCartAdd = undefined;
            eventParams.isCartOpen = undefined;
        }

        getProductDataById(eventParams, eventParams.productId, null, "click");
    });

    // process if click event has adobe product click tag
    $document.on("click.adobeAnalytics", "[data-analytics-wishlist-click=adobeAnalytics]", function () {
        let element = $(this);
        const locationPosition = element.closest("[data-order]");
        const parentParams = element.closest("[data-track-impression]") || element.closest("[data-analytics-pdp-params]");
        const eventParams = element.closest("[data-analytics-wishlist-click=adobeAnalytics]").data("params");
        const dataOrder = locationPosition.attr("data-order");

        if (eventParams && (dataOrder != null || dataOrder !== undefined)) {
            eventParams.locationPosition = eventParams.locationPosition === "" ? dataOrder : eventParams.locationPosition;
            eventParams.location = parentParams.data("impression-params")?.location || parentParams.data("analytics-pdp-params")?.location || eventParams.location;
        }
    });

    // get all instances of MFE product-tiles-product-slider
    const productSlidersMfe = $("[data-order] product-tiles-product-slider");

    if (productSlidersMfe.length) {
        $(productSlidersMfe).each((i, el) => {
            const $this = $(el);

            try {
                // update locationPosition value with actual position as appears on the page
                const params = {
                    ...JSON.parse($this.attr("click-event-params")),
                    locationPosition: $this.closest("[data-order]").attr("data-order")
                };

                $this.attr("click-event-params", JSON.stringify(params));
            } catch (error) {
                // nothing to catch
            }
        });
    }

    // process if page has impressionevent
    $document.on("mouseenter.adobeAnalytics", "[data-analytics-impression=adobeAnalytics]", function (e) {
        var eventParams = $(this).attr("data-impression-params");

        impressionEvent(e, JSON.parse(eventParams));

        if ($(".product-prepaidcard").length > 0) {
            eventParams = $(".product-prepaidcard").attr("data-impression-params");

            impressionEvent(e, JSON.parse(eventParams));
        }
    });

    $document.on("open.zf.reveal.adobeAnalytics dialogopen", "[data-analytics-impression-click=adobeAnalytics]", e => {
        var eventParams = $(e.currentTarget).data("impression-click-params");

        impressionEvent(e, eventParams);
    });

    $document.on("videoPlaying.adobeAnalytics", "[data-analytics-video-click=adobeAnalytics]", e => {
        var eventParams = $(e.currentTarget).data("analytics-video-playing-params");
        var paging = $(".c-pdp-media-slider__main-paging");

        if (eventParams.slotNumber === "replaceDynamically" && paging[0] && paging[0].innerHTML) {
            eventParams.slotNumber = paging[0].innerHTML.split("/")[0];
        }

        clickEvent(e, eventParams, null);
    });

    // click on product tile.
    $document.on("click.adobeAnalytics", "div.product-tile.card", function (event) {
        const $this = $(this);
        const $target = $(event.target);
        const $specialOfferSection = $target.parent().closest(".special-offer-navigation-section");

        // process if produc in offer section
        if ($specialOfferSection.length > 0) {
            var productId = $this.data("itemid");
            var eventParams = $specialOfferSection.data("params");

            getProductDataById(eventParams, productId, $target, "click");
            // ADULT GAME CHECK
        }
    });

    // click on product tile
    $document.on("click.adobeAnalytics", "div.product-tile", function (event) {
        const $this = $(this);
        const $target = $(event.target);
        var productId = $this.data("itemid");
        var eventParams;

        // process if product tile is in the menu item in inside the cart pop in bellow the cart itself with the message "people who ordered that also liked…"
        if ($target.parent().closest(".product-added-popup-offer").length > 0) {
            eventParams = $target.parent().closest(".product-added-popup-offer").data("params");

            getProductDataById(eventParams, productId, $target, "click");
        } else if ($target.parent().closest(".game-title-item").length > 0) { // process if product tile is in the menu item in UPC
            eventParams = $target.parent().closest(".game-title-item").data("params");
            eventParams.isProductClicked = 1;

            getProductDataById(eventParams, productId, $target, "click");
        }
    });

    $document.on("click.adobeAnalytics", ".wishlist-dialog .ui-dialog-titlebar-close", event => {
        if ($(event.currentTarget).hasClass("ui-dialog-titlebar-close")) {
            var eventParams = {
                location: "wishlist",
                locationDetail: "wishlist addition",
                category: "interaction",
                locationPosition: "pop in",
                action: "close pop in"
            };
        }

        clickEvent(event.target, eventParams, null);
    });

    $document.on("click.adobeAnalytics", ".wishlist-back-button, .wishlist-share-close-button, .see-my-wishlist-web, .see-my-wishlist-upc, .wishlist-share-popup .ui-dialog-titlebar-close, .wishlist-enforced-login", event => {
        const $this = $(event.currentTarget);
        let eventParams;

        if ($this.hasClass("wishlist-back-button")) {
            eventParams = {
                location: "wishlist",
                locationDetail: "share my wishlist",
                category: "interaction",
                locationPosition: "pop in",
                action: "back"
            };
        } else if ($this.hasClass("wishlist-share-close-button") || $this.hasClass("ui-dialog-titlebar-close")) {
            eventParams = {
                location: "wishlist",
                locationDetail: "share my wishlist",
                category: "interaction",
                locationPosition: "pop in",
                action: "close pop in"
            };
        } else if ($this.hasClass("see-my-wishlist-web")) {
            eventParams = {
                location: "wishlist",
                category: "action",
                locationPosition: "notification",
                action: "wishlist : see my wishlist"
            };
        } else if ($this.hasClass("see-my-wishlist-upc")) {
            eventParams = {
                location: "wishlist notification",
                category: "action",
                locationPosition: "notification",
                action: "wishlist : see my wishlist"
            };
        } else if ($this.hasClass("wishlist-enforced-login")) {
            eventParams = {
                location: "wishlist",
                category: "action",
                action: "log in"
            };
        }

        clickEvent(event.target, eventParams, null);
    });

    $("#notifications-switch").on("change", event => {
        let eventParams;

        if ($(event.currentTarget).prop("checked") === true) {
            eventParams = {
                location: "wishlist",
                locationDetail: "wishlist alerts",
                category: "interaction",
                action: "wishlist : activate alert"
            };
        } else {
            eventParams = {
                location: "wishlist",
                locationDetail: "wishlist alerts",
                category: "interaction",
                action: "wishlist : deactivate alert"
            };
        }

        clickEvent(event.target, eventParams, null);
    });

    // tabs change event on PPC landing page
    // WEB
    $("[data-analytics-section-click]").on("change.zf.tabs", changeTabsHandler);
    // UPC
    $("[data-tabs]").on("tabsactivate", changeTabsHandler);

    const $mediaSliderMain = $(".js-media-slider-main");

    // slider prev, next change on PHP
    $mediaSliderMain.on("click", ".slider-control", e => {
        const $currentSlide = $mediaSliderMain.find(".slick-current");

        if ($currentSlide.length) {
            const slideIndex = $currentSlide.attr("data-slick-index");
            const currentSlideNumber = slideIndex ? parseInt(slideIndex, 10) + 1 : null;
            const action = $(e.currentTarget).hasClass("slider-control-prev") ? " : previous" : " : next";

            var currentSlideNumberBeforeClick = null;

            if (slideIndex) {
                if (action.indexOf(": previous") > -1) {
                    currentSlideNumberBeforeClick = currentSlideNumber + 1;
                } else {
                    currentSlideNumberBeforeClick = currentSlideNumber - 1;
                }
            }

            const eventParams = {
                location: "media slider",
                locationDetail: "media slider details",
                category: "interaction",
                slotNumber: currentSlideNumberBeforeClick,
                locationPosition: "pop in",
                action: currentSlideNumber + action,
                component: "media slider"
            };

            clickEvent(e, eventParams, null);
        }
    });

    const $mediaSliderMainPdp = $(".media-slider");

    // slider prev, next change on legacy PDP
    $mediaSliderMainPdp.on("click", ".slider-control", e => {
        const closestDataOrder = e.currentTarget.closest("[data-order]").dataset.order;
        const $currentSlide = $mediaSliderMainPdp.find(".slick-current");

        if ($currentSlide.length) {
            const slideIndex = $currentSlide.attr("data-slick-index");
            const currentSlideNumber = slideIndex ? parseInt(slideIndex, 10) + 1 : null;
            const action = $(e.currentTarget).hasClass("slider-control-prev") ? " : previous" : " : next";

            const eventParams = {
                location: "media slider",
                locationDetail: "media slider details",
                category: "interaction",
                locationPosition: closestDataOrder,
                action: currentSlideNumber + action
            };

            clickEvent(e, eventParams, null);
        }
    });

    /**
 * @function
 * @description Initialize adobe tracking events for pdp media slider web
 */
    const initPdpMediaSliderReveal = () => {
        const pdpMediaSliderReveal = document.getElementById("media-images-reveal");

        if (pdpMediaSliderReveal) {
            pdpMediaSliderReveal.addEventListener("click", e => {
                // Check if the clicked element matches the target element because they're not in the dom at page load
                if (e.target.matches(".c-pointer")) {
                    const currSrc = document.querySelector("#media-images-reveal img").getAttribute("src");
                    const srcImg = document.querySelector("#medias-section img[src='" + currSrc + "']");
                    const eventParams = {
                        location: "media slider",
                        locationDetail: "media slider details",
                        category: "interaction",
                        slotNumber: srcImg.dataset.index,
                        locationPosition: "pop in",
                        action: `${srcImg.dataset.index} : ${e.target.classList.contains("bm-icon-arrow-right") ? "next" : "previous"}`
                    };

                    clickEvent(e, eventParams, null);
                }

                if (e.target.closest(".close-button")) {
                    const currSrc = document.querySelector("#media-images-reveal img").getAttribute("src");
                    const srcImg = document.querySelector("#medias-section img[src='" + currSrc + "']");
                    const eventParams = {
                        location: "media slider",
                        locationDetail: "media slider details",
                        category: "interaction",
                        slotNumber: srcImg.dataset.index,
                        locationPosition: "pop in",
                        action: "close pop in"
                    };

                    clickEvent(e, eventParams, null);
                }
            });
        }
    };

    /**
 * @function
 * @description Initialize adobe tracking events pdp dlc selector
 */
    const initDlcTypes = () => {
        const dlcTypes = document.querySelectorAll(".dlc-types");
        // dlc selector on PDP

        if (dlcTypes) {
            dlcTypes.forEach(el => {
                el.addEventListener("click", e => {
                    const dlcCategory = e.target.textContent;
                    const closestDataOrder = el.closest("[data-order]").dataset.order;
                    const eventParams = {
                        location: "hero banner",
                        locationDetail: "offer selector",
                        category: "interaction",
                        locationPosition: closestDataOrder,
                        action: `dlc : ${dlcCategory} : select`
                    };

                    clickEvent(e, eventParams, null);
                });
            });
        }
    };

    /**
 * @function
 * @description Initialize adobe tracking events pdp media container in UPC
 */
    const initMediaImageContainerUpc = () => {
        const mediaImageContainerUpc = document.querySelectorAll(".media-image-container");

        if (mediaImageContainerUpc) {
            mediaImageContainerUpc.forEach((el, index) => {
                el.addEventListener("click", e => {
                    const closestDataOrder = el.closest("[data-order]").dataset.order;
                    const eventParams = {
                        location: "media slider",
                        category: "interaction",
                        slotNumber: `${index + 1}`,
                        locationPosition: closestDataOrder,
                        action: `image-${index + 1} : see picture`
                    };

                    clickEvent(e, eventParams, null);
                });
            });
        }
    };

    /**
 * @function
 * @description Initialize adobe tracking pdp media slider in UPC
 */
    const initMediaSliderUpc = () => {
        const mediaSliderUpc = document.querySelector(".media-items");

        if (mediaSliderUpc) {
            mediaSliderUpc.addEventListener("click", e => {
                const closestDataOrder = e.target.closest("[data-order]").dataset.order;

                if (e.target.matches(".slick-arrow")) {
                    const currentSlide = mediaSliderUpc.querySelector(".slick-current");
                    const currentSlideIndex = currentSlide.dataset.slickIndex;
                    const eventParams = {
                        location: "media slider",
                        category: "interaction",
                        locationPosition: closestDataOrder,
                        action: `${currentSlideIndex} : ${e.target.classList.contains("slick-next") ? "next" : "previous"}`
                    };

                    clickEvent(e, eventParams, null);
                }
            });
        }
    };

    /**
 * @function
 * @description Initialize adobe tracking events for arrows pdp media slider upc
 */
    const initMediaSliderUpcLightbox = () => {
        const mediaSliderUpcLightbox = document.querySelector("#lightbox");
        const productPage = document.querySelector(".pt_product-details");

        function trackSliderArrowClick(event, direction) {
            const currSrc = document.querySelector("#lightbox img").getAttribute("src");
            const srcImg = document.querySelector(".media-image a[href='" + currSrc + "']");

            const eventParams = {
                location: "media slider",
                locationDetail: "media slider details",
                category: "interaction",
                slotNumber: srcImg.dataset.index,
                locationPosition: "pop in",
                action: `${srcImg.dataset.index} : ${direction}`
            };

            clickEvent(event, eventParams, null);
        }

        if (mediaSliderUpcLightbox && productPage) {
            document.querySelector(".lb-next").addEventListener("click", e => {
                trackSliderArrowClick(e, "next");
            });

            document.querySelector(".lb-prev").addEventListener("click", e => {
                trackSliderArrowClick(e, "prev");
            });
        }
    };

    initPdpMediaSliderReveal();
    initDlcTypes();
    initMediaImageContainerUpc();
    initMediaSliderUpc();
    initMediaSliderUpcLightbox();
    initializeHeroBannerSliderEvents();
    initializeCategoryProductSliderEvents();
    initializeDoublePushEvents();
    initializeTriplePushEvents();
    initWMPEvents();
    initializeAlgoliaEvents();

    // set order each component in PHP if exists
    if ($(".experience-component").length > 0) {
        $(".experience-component").not(".experience-pdp-systemRequirementsTab").not(".experience-pdp-mfeBuySubModal").each((index, element) => {
            $(element).attr("data-order", index + 1);
        });
    }

    // use observer to only init impression analytic events once per page load
    const observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                const element = entry.target;
                const locationPosition = $(element).attr("data-order") || $(element).closest("[data-order]").attr("data-order");
                const eventParams = $(element).data("analytics-pdp-params") || $(element).data("product-params") || $(element).data("impression-onload") || JSON.parse(element.dataset.impressionParams);

                // set locationPosition tracking parameters if available
                if (locationPosition) {
                    eventParams.locationPosition = locationPosition;
                }

                if (eventParams) {
                    // productImpression needs to be calculated for recommendation sliders
                    var calculateProductImpression = $(element).data("calculate-product-impression");

                    if (calculateProductImpression) {
                        const totalProductIdList = $(element).data("total-prod-id-list");
                        const inViewElements = $(element).find(".slick-active");
                        const startElement = inViewElements.first().data("slick-index");
                        const endElement = inViewElements.last().data("slick-index");
                        const productIdList = totalProductIdList.filter((element, index) => {
                            if (index >= startElement && index <= endElement) {
                                return element;
                            }
                        });

                        eventParams.productImpression = productIdList;
                    }

                    // track impression events
                    impressionEvent(null, eventParams);
                }

                // unobserve element, making sure we trigger the impression event tracking only once per page load.
                observer.unobserve(element);
            }
        });
    },
    { // observer options
        rootMargin: "0px",
        threshold: 0.5
    });

    const adobeIntersectionObserver = element => {
        const observableElement = element;
        const hasRecommendations = observableElement.hasAttribute("data-recommendations");
        const hasTrackedImpression = observableElement.hasAttribute("data-track-impression");
        const checkAnalyticsToggle = $(observableElement).data("check-analytics-toggle");
        let shouldObserveElement = true;

        // condition to check if recommendation section has no products in it. if not, then do not observe
        if (hasRecommendations) {
            shouldObserveElement = $(observableElement).find(".product-tile").length !== 0;
        }

        // condition to check if impression tracking params have not been provided
        if (hasTrackedImpression) {
            shouldObserveElement = observableElement.dataset.impressionParams?.length !== 0;
        }

        // if data attribute [check-analytics-toggle] is true, check adobe analytics toggle if is true in order to send event
        if (checkAnalyticsToggle) {
            shouldObserveElement = Resources.ADOBE_ANALYTICS_TRACKING;
        }

        if (!shouldObserveElement) {
            return;
        }

        observer.observe(observableElement);
        $(element).data("observable", false);
    };

    // track impression events on collection of elements below
    const trackedImpressionElementsCollection = "[data-analytics-pdp-params], [data-analytics-pdp-recommendation-params], [data-track-impression], [data-impression-onload]";

    document.querySelectorAll(trackedImpressionElementsCollection).forEach(element => {
        adobeIntersectionObserver(element);
    });

    document.addEventListener("Einstein:Mutated", e => {
        const element = e.detail;

        if (element.length && (element.data("observable") ?? true)) {
            adobeIntersectionObserver(element.get(0));
        }
    });

    const isShippingFree = document.querySelector(".c-shipping-message");

    if (isShippingFree !== null && isShippingFree !== undefined) {
        document.dispatchEvent(new CustomEvent("Cart:ShippingFree", {
            detail: isShippingFree.dataset?.type
        }));
    }

    $document.on("cartrevamp-AddItem", (e, params) => {
        getProductDataById(params.eventParams, params.productId, null, "click");
    });

    $document.on("click", ".menu-category .has-subcategories a", e => {
        const $this = $(e.currentTarget);
        const eventParams = $this.data("impression-params");

        if (eventParams) {
            impressionEvent(e, eventParams);
        }
    });

    $document.on("click", "[data-market-push]", e => {
        const $this = $(e.currentTarget);
        const eventParams = $this.data("params") || {};
        const locationDetail = [
            "main nav",
            $this.parents("[data-main-category-eng]")?.data("main-category-eng") || "",
            $this.parents("[data-sub-category-eng]")?.data("sub-category-eng") || "",
            "marketing push"
        ].filter(Boolean).join(" : ");

        clickEvent(e, {
            ...eventParams,
            locationDetail
        });
    });

    $document.on("franchiselink-item", (e, params) => {
        impressionEvent(e, params.eventParams);
    });

    $document.on("gamestitlelist-item", (e, params) => {
        impressionEvent(e, params.eventParams);
    });

    $document.on("navitemhassubmenu-click", (e, params) => {
        impressionEvent(e, params.eventParams);
    });
}

/**
 * searches product by id and runs click event if product is found
 * @param {Object} eventParams contains data about the event like location, action
 * @param {*} productId product id that was clicked
 * @param {*} $target event target
 */
function getProductDataById(eventParams, productId, $target, eventType) {
    $.ajax({
        type: "GET",
        url: Urls.tcGetProductById,
        data: "pid=" + productId,
        async: false
    }).done(function (response) {
        if (response !== "" || response != null || response != undefined) {
            const product = JSON.parse(response);

            if (product != "" || product != null || product != undefined) {
                var productOwnership = "";

                if (eventParams.productOwnership) {
                    productOwnership = eventParams.productOwnership;
                } else {
                    if (uplayData.authenticated) {
                        productOwnership = product.productOwnership || product.ownedOtherEdition ? "yes" : "no";
                    }
                }

                const subscriptionDetails = getSubscriptionDetails(eventParams);

                if (eventType == "click") {
                    const eventData = {
                        location: eventParams.location,
                        locationPosition: eventParams.locationPosition,
                        locationDetail: eventParams.locationDetail,
                        category: eventParams.category,
                        ...(eventParams?.slotNumber) && {
                            slotNumber: eventParams.slotNumber
                        },
                        action: eventParams.action,
                        productImpression: eventParams.productImpression,
                        isProductClicked: eventParams.isProductClicked,
                        adultGame: product.adultgame,
                        productID: product.productID || product.masterID,
                        productId: product.productID || product.masterID,
                        productDiscountRate: product.discountRate / 100 || subscriptionDetails.percentageOff / 100,
                        productName: product.simpleProductName,
                        productEdition: product.editionName,
                        productPlatform: product.platform,
                        productSalesType: eventParams.productSalesType || product.salesType,
                        productIsWishlist: product.isWishList,
                        productDiscountName: product.discountName || subscriptionDetails.name ? product.discountName || subscriptionDetails.name : "",
                        productDiscount: product.discountAmount || subscriptionDetails.discount ? "yes" : "no",
                        isCartAdd: product.isCartAdd || eventParams.isCartAdd,
                        isCartRemove: product.isCartRemove,
                        isCartOpen: product.isCartOpen || eventParams.isCartOpen,
                        isWishlistAdd: product.isWishlistAdd,
                        isWishlist: eventParams.isWishList ? eventParams.isWishList === "forceValue" ? eventParams.wishListForcedValue : eventParams.isWishList : product.isWishList,
                        productOwnership: productOwnership,
                        productDemo: product.productDemo,
                        productFreeWeekEnd: product.productFreeWeekEnd,
                        productType: product.productType,
                        pagination: eventParams.pagination,
                        totalResults: eventParams.totalResults
                    };

                    clickEvent($target, eventData, null, null, true);
                }

                if (eventType == "page" && adobeAnalytics) {
                    adobeAnalytics.track("pageevent", {
                        productId: product.productID || product.masterID ? product.productID || product.masterID : "",
                        productDiscountRate: product.discountRate / 100 || subscriptionDetails.percentageOff / 100 ? product.discountRate / 100 || subscriptionDetails.percentageOff / 100 : 0,
                        productName: product.productName ? product.productName : "",
                        productEdition: product.editionName ? product.editionName : "",
                        productPlatform: product.platform ? product.platform : "",
                        productSalesType: product.salesType ? product.salesType : "",
                        isWishlist: product.isWishList ? product.isWishList : "",
                        productDiscountName: product.discountName || subscriptionDetails.name ? product.discountName || subscriptionDetails.name : "",
                        productDiscount: (product.discountRate > 0 || subscriptionDetails.percentageOff > 0) ? "yes" : "no",
                        adultGame: product.adultgame ? product.adultgame : "",
                        productOwnership: eventParams.productOwnership ? eventParams.productOwnership : "",
                        pageName: eventParams.pageName ? eventParams.pageName : "",
                        pageUrl: window.location.href || eventParams.pageUrl ? window.location.href || eventParams.pageUrl : "",
                        siteSection: eventParams.siteSection ? eventParams.siteSection : "",
                        mdmBrandId: product.mdmBID || eventParams.mdmBrandId ? product.mdmBID || eventParams.mdmBrandId : "",
                        mdmInstallmentId: product.mdmIID || eventParams.mdmInstallmentId ? product.mdmIID || eventParams.mdmInstallmentId : "",
                        installmentName: eventParams.installmentName ? eventParams.installmentName : "",
                        brandName: product.brandName || eventParams.brand ? product.brandName || eventParams.brand : "",
                        isProdView: eventParams.isProdView ? eventParams.isProdView : "",
                        ubisoftId: eventParams.ubisoftId ? eventParams.ubisoftId : "",
                        productDemo: product.productDemo,
                        productFreeWeekEnd: product.productFreeWeekEnd
                    });
                }
            }
        }
    });
}

/**
 * @function
 * @description set tracking params have ownership to window.extendedProductModel.ownership.productOwnership
 * @returns {string} value of ownership yes or no if logged in otherwise returns empty string
 */
function getProductOwnership() {
    if (typeof window?.extendedProductModel !== "undefined") {
        if (window.User?.isLoggedIn && window?.extendedProductModel.ownership !== undefined) {
            const isBoughtGame = window.extendedProductModel.ownership.productOwnership;

            return isBoughtGame ? "yes" : "no";
        }

        return "";
    }

    return "undefined";
}

/**
 * @function
 * @description checks if tracking params have ownership property
 * @param {Object} eventParams contains data about the event like location, action
 * @returns {boolean} ownership property is owned or not
 */
function checkParamsHasOwnerShip(eventParams) {
    if (eventParams.productOwnership === "" && window?.extendedProductModel) {
        eventParams.productOwnership = getProductOwnership();
    }

    return eventParams;
}

/**
 * @function
 * @description gets subscription details for the events that has subscription id.
 * @param {Object} eventParams contains data about the event like location, action
 * @returns {Object} object containing subscription details
 */
function getSubscriptionDetails(eventParams) {
    if (eventParams && eventParams.subscriptionId !== undefined) {
        const subscriptionDetails = window.subscriptionPricingDetails?.subscriptions?.find(x => x.id === eventParams.subscriptionId);

        if (subscriptionDetails !== undefined) {
            const promotionExists = subscriptionDetails.promotions !== undefined && subscriptionDetails.promotions.length > 0;

            if (promotionExists) {
                subscriptionDetails.discount = subscriptionDetails.promotions[0].type === "discount" ? "yes" : "no";
                subscriptionDetails.name = subscriptionDetails.promotions[0].promotionName;
            }
        }

        return subscriptionDetails;
    }

    return {};
}

/**
 * @function
 * @description handler for sending an event on tab's changes
 * @param {Object} e event data
 */
const changeTabsHandler = e => {
    const $this = $(e.currentTarget);
    const isUpcTabs = $this.hasClass("ui-tabs");
    const $activeTab = isUpcTabs ? $this.find("li.ui-tabs-active") : $this.find("li.is-active");
    const $paramsContainer = isUpcTabs ? $this.find("[data-analytics-section-click]") : $this;
    let params = $paramsContainer.data("params") || {};

    if ($activeTab.length) {
        const eventParams = $.extend(params, $activeTab.first().data("params"));
        const locationPosition = $this.closest("[data-order]");
        const dataOrder = locationPosition.attr("data-order");

        if (typeof dataOrder !== undefined && dataOrder !== null) {
            eventParams.locationPosition = dataOrder;
        }

        clickEvent(e, eventParams);
    }
};

/**
 * @function
 * @description Initialize adobe tracking events for hero banner slider
 */
const initializeHeroBannerSliderEvents = () => {
    const $heroBannerSliderUPC = Resources.IS_UPC_STORE ? $(".homepage-slider") : $(".homepage-slider-hero");

    // initialize impression event for first slide
    generateFirstImpressionEventForHeroBanner($heroBannerSliderUPC);

    // impression events for rest of the slides
    generateRestImpressionEventsForHeroBanner($heroBannerSliderUPC);

    // click events/product click events for slides
    generateClickEventsForHeroBanner();
};

const generateParamsForHeroBannerEvents = (element, scope, locationPosition) => {
    var $impressionEvent = {
        location: "hero banner",
        locationPosition: locationPosition,
        category: "scroll"
    };
    var $clickEvent = {
        location: "hero banner",
        locationPosition: locationPosition,
        category: "action"
    };
    var $elementAttributes;

    if (scope === "impression") {
        $elementAttributes = element.find("[data-analytics-hp-hero-slide] a").first();
    } else {
        $elementAttributes = element.find("a").first();
    }

    const $type = $elementAttributes.attr("data-type");
    const $pid = $elementAttributes.attr("data-pid");
    const $bannerTitle = $elementAttributes.closest("[data-title]").attr("data-title");
    const productImpression = [];

    $impressionEvent.locationDetail = $bannerTitle;
    $clickEvent.locationDetail = $bannerTitle;

    switch ($type) {
        case "product": {
            $impressionEvent.action = "product";
            $clickEvent.action = "product : see more";
            $clickEvent.isProductClicked = "1";
            $clickEvent.productId = $pid;

            productImpression.push($pid);

            break;
        }

        case "category": {
            const $categoryPageName = $elementAttributes.attr("data-category-name");

            $impressionEvent.action = $categoryPageName;
            $clickEvent.action = $categoryPageName + " : see products list";

            break;
        }

        case "link": {
            const $subscriptionProductType = $elementAttributes.attr("data-subscription-product-type");
            const $ctaText = $elementAttributes.attr("data-link-page-name") || "";
            const $subProduct = $subscriptionProductType !== undefined ? $subscriptionProductType === "ibex" ? "rocksmith plus" : "ubisoft plus" : undefined;

            if ($subscriptionProductType !== undefined) {
                $impressionEvent.action = $subProduct;
                $clickEvent.action = $subProduct + " : see more";
                $clickEvent.isProductClicked = "1";
                $clickEvent.productId = $pid;

                productImpression.push($pid);
            } else {
                $impressionEvent.action = "visible";
                $clickEvent.action = $bannerTitle + " : " + $ctaText;
            }

            break;
        }

        default:
            break;
    }

    $impressionEvent = {
        ...$impressionEvent,
        ...(productImpression.length > 0) && {
            productImpression: productImpression
        }
    };

    return {
        impressionEvent: $impressionEvent,
        clickEvent: $clickEvent
    };
};

const generateFirstImpressionEventForHeroBanner = heroBannerSlider => {
    if (heroBannerSlider.length > 0) {
        const $locationPosition = heroBannerSlider.closest("[data-order]").attr("data-order");
        const $currentSlide = heroBannerSlider.find(".slick-current");
        const eventParams = generateParamsForHeroBannerEvents($currentSlide, "impression", $locationPosition);

        impressionEvent(null, eventParams.impressionEvent);

        $currentSlide.attr("data-impression-event-sent", true);
    }
};

const generateRestImpressionEventsForHeroBanner = heroBannerSlider => {
    heroBannerSlider.on("afterChange", function (event, slide, currentSlide) {
        const $this = $(event.currentTarget);
        const $currentSlide = $(slide.$slides[currentSlide]);

        if ($currentSlide.attr("data-impression-event-sent") === "true") {
            heroBannerSlider.off("afterChange");

            return;
        }

        const $locationPosition = $this.closest("[data-order]").attr("data-order");
        const eventParams = generateParamsForHeroBannerEvents($currentSlide, "impression", $locationPosition);

        impressionEvent(event, eventParams.impressionEvent);
        $currentSlide.attr("data-impression-event-sent", true);
    });
};

const generateClickEventsForHeroBanner = () => {
    $document.on("click", "[data-analytics-hp-hero-slide]", e => {
        const $this = $(e.currentTarget);
        const $parent = $this.closest("[data-track-impression]");
        const sendEvent = adobeAnalyticsToggle($parent);

        if (sendEvent) {
            const $locationPosition = $this.closest("[data-order]").attr("data-order");
            const eventParams = generateParamsForHeroBannerEvents($this, "click", $locationPosition);

            if (eventParams.clickEvent.productId !== undefined) {
                getProductDataById(eventParams.clickEvent, eventParams.clickEvent.productId, null, "click");
            } else {
                clickEvent(e, eventParams.clickEvent, null);
            }
        }
    });
};

/**
 * @function
 * @description Tracks a product list wishlist action
 * @param {Object} $el - jQuery element
*/
const trackProductsListWishlistAction = $el => {
    const $parent = $el.closest("[data-track-impression]");
    const sendEvent = adobeAnalyticsToggle($parent);

    if (sendEvent) {
        const inheritedParameters = $parent?.data("impression-params");
        const locationPosition = $el.attr("data-order") || $el.closest("[data-order]").attr("data-order");
        const slotNumber = $el.closest("[data-slick-index]").data("slick-index") + 1;
        const productId = $el.data("productid");
        const wishListAction = $el.hasClass("product-added") ? "remove from" : "add to";

        const eventParams = {
            location: inheritedParameters?.location,
            locationDetail: inheritedParameters?.locationDetail,
            category: "wishlist",
            locationPosition: locationPosition || "",
            action: `product : ${wishListAction} wishlist`,
            isWishlistAdd: "1",
            slotNumber: slotNumber
        };

        getProductDataById(eventParams, productId, null, "click");
    }
};

/**
 * @function
 * @description Initialize adobe tracking events for category product sliders
 */
const initializeCategoryProductSliderEvents = () => {
    /**
     * Get active product IDs for which impression event has not yet been triggered
     * @param {jQuery} $slider - jQuery element for the slider container (the element with the data-track-impression attribute)
     * @returns {string[]} productIdList - List of product IDs
     */
    const getActiveSliderProductIds = $slider => {
        const viewedProductIds = $slider.data("viewedProductIds") || [];
        const element = $slider.find(".slick-active");
        const totalProductIdList = $slider?.data("total-prod-id-list");
        const startElement = element.first().data("slick-index");
        const endElement = element.last().data("slick-index");
        const productIdList = totalProductIdList.filter((element, index) => {
            if (index >= startElement && index <= endElement && !viewedProductIds.includes(element)) {
                return element;
            }
        });

        return productIdList;
    };

    const listingProductSliders = "[data-track-impression][data-location='listing product']";
    const franchiseSliders = "[data-franchise-slider]";

    // Set initial active elements as viewed
    $(listingProductSliders).each((index, element) => {
        const $slider = $(element);

        $slider.data("viewedProductIds", getActiveSliderProductIds($slider));
    });

    $document.on("click", `
        ${listingProductSliders} .slider-control-next,
        ${franchiseSliders} .slider-control-next,
        ${listingProductSliders} .slider-control-prev,
        ${franchiseSliders} .slider-control-prev`,
    e => {
        const $this = $(e.currentTarget);
        const isProductListing = $this.parents(listingProductSliders).length;
        const $parent = isProductListing ? $this.closest("[data-track-impression]") : $this.closest("[data-franchise-slider]");
        const sendEvent = adobeAnalyticsToggle($parent);

        if (sendEvent) {
            const interactionType = $this.hasClass("slider-control-next") || $this.parents().hasClass("slider-control-next") ? "next" : "previous";
            const inheritedParameters = isProductListing ? $parent?.data("impression-params") : $parent?.data("product-params");
            const locationPosition = $this.attr("data-order") || $this.closest("[data-order]").attr("data-order");

            var eventParams = {
                location: inheritedParameters?.location,
                locationDetail: inheritedParameters?.locationDetail,
                category: "interaction",
                locationPosition: locationPosition || "",
                action: `${interactionType} ${isProductListing ? "products" : "categories"} : arrow`
            };

            clickEvent(e, eventParams, null);

            if (!isProductListing) return;

            const productIdList = getActiveSliderProductIds($parent);

            const viewedProductIds = $parent.data("viewedProductIds") || [];

            viewedProductIds.push(...productIdList);

            eventParams.category = "display";
            eventParams.action = interactionType + " products";
            eventParams.productImpression = productIdList;

            if (productIdList.length > 0) {
                impressionEvent(null, eventParams);
            }
        }
    });

    $document.on("click", ".category-product-slider .product-tile.card, .recommendations.cross-sell.einstein-recommendations .product-tile.card, .product-list-slider .see-more-btn-upc, .product-list-slider .product-tile", e => {
        const $this = $(e.currentTarget);
        const $parent = $this.closest("[data-track-impression]");
        const sendEvent = adobeAnalyticsToggle($parent);
        const productId = $this.attr("data-itemid") || $this.closest("[data-itemid]").attr("data-itemid");

        if (sendEvent) {
            const inheritedParameters = $parent?.data("impression-params");
            const locationPosition = $this.attr("data-order") || $this.closest("[data-order]").attr("data-order");
            const slotNumber = $this.closest("[data-slick-index]").data("slick-index") + 1;

            var eventParams = {
                location: inheritedParameters?.location,
                locationDetail: inheritedParameters?.locationDetail,
                category: "action",
                locationPosition: locationPosition || "",
                action: "product : see more",
                isProductClicked: "1",
                slotNumber: slotNumber
            };

            getProductDataById(eventParams, productId, null, "click");
        }
    });
};

/**
 * @function
 * @description Initialize adobe tracking events for double push slots
 */
const initializeDoublePushEvents = () => {
    $document.on("click", ".push-wrapper--double .double .product-tile", e => {
        const $this = $(e.currentTarget);
        const $parent = $this.closest("[data-track-impression]");
        const sendEvent = adobeAnalyticsToggle($parent);
        const productId = $this.data("pid");

        if (sendEvent) {
            const inheritedParameters = $this.data("params");
            const locationPosition = $this.data("order") || $this.closest("[data-order]").attr("data-order");
            const slotNumber = $this.closest("[data-index]").data("index") + 1;

            var eventParams = {
                ...inheritedParameters,
                locationPosition: locationPosition || "",
                slotNumber: slotNumber
            };

            switch ($this.data("banner-type")) {
                case "category":
                    eventParams.action = $this.data("category-page-name") + " : see products list";
                    break;
                case "custom-subscription":
                    eventParams.action = $this.data("sub-product") + " : see more";
                    break;
                case "custom":
                    eventParams.action = $this.data("link-page-name");
                    eventParams.locationDetail = $this.data("title");
                    break;
            }

            if (productId) {
                getProductDataById(eventParams, productId, null, "click");
            } else {
                clickEvent(e, eventParams, null, null, false);
            }
        }
    });
};

/**
 * @function
 * @description initialize tracking for greetings slider & open the popup
 */
const initWMPEvents = () => {
    // handle click event for greetings slider close button
    $(".js-wallet-greetings-reveal, .wallet-greetings__close").on("click", ".close-button, .ui-dialog-titlebar-close", () => {
        const defaultEventParams = {
            location: "welcome flow ubisoft wallet",
            category: "interaction",
            locationPosition: "pop in",
            action: "close pop in"
        };
        const locationDetailParams = [
            "welcome to your ubisoft wallet",
            "we reward your fidelity",
            "get refunded faster"
        ];
        const $currentSlide = $(".js-wallet-greetings-reveal").find(".slick-current");

        if ($currentSlide.length) {
            const $currentIndex = Number($currentSlide.data("slick-index"));
            const locationDetail = locationDetailParams[$currentIndex];
            const eventParams = {
                ...defaultEventParams,
                locationDetail
            };

            if (eventParams) {
                clickEvent(null, eventParams);
            }
        }
    });

    // handle click event for greetings slider next slide button
    $(".js-wallet-greetings-reveal").on("click", ".slider-control-next", () => {
        const defaultEventParams = {
            location: "welcome flow ubisoft wallet",
            category: "interaction",
            locationPosition: "pop in",
            action: "next"
        };
        const locationDetailParams = [
            "welcome to your ubisoft wallet",
            "we reward your fidelity"
        ];
        const $currentSlide = $(".js-wallet-greetings-reveal").find(".slick-current");

        if ($currentSlide.length) {
            const $currentIndex = Number($currentSlide.data("slick-index"));
            const locationDetail = locationDetailParams[$currentIndex - 1];
            const eventParams = {
                ...defaultEventParams,
                locationDetail
            };

            if (eventParams) {
                clickEvent(null, eventParams);
            }
        }
    });

    // Wallet Management Page - "FAQ Question" Items
    $(".wallet-faq-wrapper").on("click", "a.wallet-faq-content", function () {
        const $this = $(this);
        const $faqContent = $(this).parent().children(".wallet-faq-content");

        sentWMPClickEvent($faqContent, $this);
    });

    $(".wallet-faq__questions").on("click", ".wallet-faq__questions__item", function () {
        const $this = $(this);

        sentWMPClickEvent($(".wallet-faq__questions").children(), $this);
    });
};

/**
 * @function
 * @description Used for sendint click events for WMP - Wallet Management Page
 * @description iterate over parent element and find the index of current element and sent the event
 */
const sentWMPClickEvent = ($iterator, $this) => {
    let questionNumber;

    $iterator.each(function (index, element) {
        if ($this.is(element)) {
            questionNumber = index + 1;

            return false;
        }
    });

    const eventParams = {
        location: "learn more about your wallet",
        category: "action",
        action: questionNumber
    };

    clickEvent(null, eventParams);
};

/**
 * @function
 * @description Initialize adobe tracking events for triple push slots
 */
const initializeTriplePushEvents = () => {
    $document.on("click", ".push-wrapper--triple .triple .push-product .product-tile.card, .push-wrapper--triple .triple .category-asset-wrap", e => {
        const $this = $(e.currentTarget);
        const $parent = $this.closest("[data-track-impression]");
        const sendEvent = adobeAnalyticsToggle($parent);
        const productId = $this.attr("data-pid");

        if (sendEvent) {
            const inheritedParameters = $this.data("params");
            const locationPosition = $this.attr("data-order") || $this.closest("[data-order]").attr("data-order");

            var eventParams = {
                ...inheritedParameters,
                locationPosition: locationPosition || ""
            };

            if (productId !== undefined) {
                getProductDataById(eventParams, productId, null, "click");
            } else {
                clickEvent(e, eventParams, null, null, false);
            }
        }
    });
};

/**
 * If data attribute [check-analytics-toggle] is true, check adobe analytics toggle if is true in order to send event
 * @param {Object} element element where to check for attribute [check-analytics-toggle]
 */
const adobeAnalyticsToggle = element => {
    const checkAnalyticsToggle = element?.data("check-analytics-toggle");
    const sendEvent = !checkAnalyticsToggle || (checkAnalyticsToggle && Resources.ADOBE_ANALYTICS_TRACKING);

    return sendEvent;
};

// exported function for events
function adobeEvents() {
    initializeAdobeAnalyticsEvents();
}

function adobePageEvents() {
    initializeAdobeAnalyticsPageEvents();
    // attach custom event, so we know when analytics has been loaded
    $(document).trigger("adobeAnalyticsLoaded");
}

export { adobeEvents, adobePageEvents, clickEvent, impressionEvent, pageEvent, getTrackingPageObject, getProductDataById, adobeAnalyticsToggle, trackProductsListWishlistAction };
