/**
 * savedAddress component
 *
 * Address list system, to select, remove or add new address in checkout flow
 *
 * @module components/savedAddress
 * @see components/savedAddress
 */

import { initBilling } from "./../pages/checkout/billing";
import { dialog } from "../dialog";

/**
 * Component DOM selectors and CSS modifiers
 * @const {object}
 * @default
 */
const data = {
    root: '[data-component="billing"]',
    form: '[data-form="billing"]',
    addressList: '[data-container="savedAddress"]',
    item: '[data-item="addressItem"]',
    inputItem: "[data-addressID]",
    removeItem: '[data-trigger="removeAddressItem"]',
    addAddress: '[data-trigger="addAddress"]',
    editAddress: '[data-trigger="editAddress"]',
    saveAddress: '[data-trigger="saveAddress"]',
    errorElement: '[data-error="removeAddress"]',
    css: {
        itemSelected: "c-address__item--selected",
        itemHidden: "c-address__item--hidden",
        error: "e-address__error"
    },
    countryList: "select[id$='_addressFields_country']",
    stateList: "select[id$='_addressFields_states_state']"
};

let initialInputID = "";

const savedAddress = {
    /**
     * @function
     * @description get all component instances and bind events
     */
    init: () => {
        savedAddress.bindComponentEvents();
    },

    /**
     * @function
     * @description bind event on component
     */
    bindComponentEvents: () => {
        document.addEventListener("SavedAddress:GetInitialInputID", () => {
            const $initialSelectedAddress = $("." + data.css.itemSelected);

            if ($initialSelectedAddress.length > 0) {
                const $selectedInput = $initialSelectedAddress.find(data.inputItem);

                if ($selectedInput.length > 0) {
                    initialInputID = $selectedInput.attr("id");
                }
            }
        });

        $(document)
            .on("click", data.item, e => {
                e.preventDefault();

                const $el = $(e.currentTarget);
                const $currentRemoveItem = $el.find(data.removeItem);
                const $currentEditItem = $el.find(data.editAddress);

                if ($(e.target)[0] === $currentRemoveItem[0]) {
                    savedAddress.deleteAddress($el);
                } else if ($(e.target)[0] === $currentEditItem[0]) {
                    const $editLink = $(e.target);

                    savedAddress.editOrUpdateAction($editLink, "Edit");
                } else {
                    savedAddress.selectAddress($el);
                }
            })
            .on("click", data.addAddress, e => {
                e.preventDefault();

                const $addLink = $(e.target);

                if ($addLink.parents(".checkout-billing").length) {
                    savedAddress.showBillingForm();
                } else {
                    savedAddress.editOrUpdateAction($addLink, "Add");
                }
            })
            .on("click", data.saveAddress, e => {
                e.preventDefault();

                const $selectedAddress = $("." + data.css.itemSelected);

                if (initialInputID !== $selectedAddress.find(data.inputItem).attr("id")) {
                    savedAddress.saveAction($selectedAddress, "Save");
                } else {
                    dialog.close();
                }
            });
    },

    /**
     * @function
     * @description Update UI with selected address item, and check radio buttons
     * @param {jQuery} $addressItem selectedAddress element
     */
    selectAddress: $addressItem => {
        // Update radio button state
        $(data.item).find(data.inputItem).removeAttr("checked").prop("checked", false);
        $addressItem.find(data.inputItem).prop("checked", true);

        // Toggle classes
        $(data.item).removeClass(data.css.itemSelected);
        $addressItem.addClass(data.css.itemSelected);
    },

    /**
     * @function
     * @description Update UI by hiding and removing selected address item.
     *              Preselect first address if addressList is having more than 1 item, or display billingForm
     * @param {jQuery} $addressItem selectedAddress element
     */
    deleteAddress: $addressItem => {
        const addressID = $addressItem.find(data.inputItem).val();

        $(data.errorElement).remove();

        fetch(Urls.deleteAddress, {
            method: "POST",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            },
            body: new URLSearchParams({
                AddressID: addressID,
                format: "ajax"
            })
        })
            .then(response => response.json())
            .then(response => {
                if (response.status.toLowerCase() === "ok") {
                    $addressItem.addClass(data.css.itemHidden);

                    // Triggered at the end of CSS animation to be sure that we still have the removed item for logic
                    setTimeout(() => {
                        if ($addressItem.hasClass(data.css.itemSelected) && $(data.item).length > 1) {
                            savedAddress.setAfterRemove();
                        } else if ($(data.item).length === 1 && $addressItem.hasClass(data.css.itemHidden)) {
                            savedAddress.showBillingForm();
                        }

                        // Remove the targetted address item
                        $addressItem.remove();
                    }, 500);
                } else {
                    const $errorContainer = $("<div/>", {
                        "data-error": "removeAddress"
                    }).addClass(data.css.error);

                    $errorContainer.html(response.message);
                    $(data.addressList).append($errorContainer);
                }
            });
    },

    /**
     * @function
     * @description Dispatch an event for edit or add an address, throught an ajax call.
     *              Event is listened in account.js
     * @param {jQuery} $element button/link that contains Ajax URL
     * @param {String} action contains the name of action to dispatch
     */
    editOrUpdateAction: ($element, action) => {
        const payload = {
            url: $element.data("url")
        };
        const updateAddressEvent = new CustomEvent("address:" + action, {
            detail: payload
        });

        document.dispatchEvent(updateAddressEvent);
    },

    /**
     * @function
     * @description Dispatch an event for edit or add an address, throught an ajax call.
     *              Event is listened in account.js
     * @param {jQuery} $element address selected, to get addressID value
     * @param {String} action contains the name of action to dispatch
     */
    saveAction: ($element, action) => {
        const payload = {
            url: $(data.saveAddress).data("url"),
            addressID: $element.find(data.inputItem).val()
        };

        const updateAddressEvent = new CustomEvent("address:" + action, {
            detail: payload
        });

        document.dispatchEvent(updateAddressEvent);
    },

    /**
     * @function
     * @description Select first address after removing one
     */
    setAfterRemove: () => {
        const $firstAddressItem = $(data.item + ":not(." + data.css.itemSelected + ")").first();

        if ($firstAddressItem.length) {
            $firstAddressItem.trigger("click");
        }
    },

    /**
     * @function
     * @description Call and display billingForm to add/use a new address
     */
    showBillingForm: () => {
        fetch(Urls.addNewAddress, {
            method: "GET"
        })
            .then(response => response.text())
            .then(response => {
                $(data.form).remove();
                $(data.root).append(response);
                savedAddress.setDefaultCountry();
                $(data.countryList).selectBoxIt();
                $(data.stateList).selectBoxIt();

                // Force reinit
                initBilling();
            });
    },

    /**
     * @function
     * @description Sets the default selected country based on the geolocation country
     */
    setDefaultCountry: () => {
        const geolocationCountry = $(".geolocation_country").attr("data-country");

        if (data.countryList && geolocationCountry && geolocationCountry != null && geolocationCountry != "null") {
            $(data.countryList).find("option[value='" + geolocationCountry.toLowerCase() + "']").attr("selected", "selected");
        }
    }
};

export { savedAddress };
