let enabledSubscriptionTagCommander = SitePreferences.ENABLE_SUBSCRIPTION_TAG_COMMANDER ? true : false;
let isLegacy = SitePreferences.IS_LEGACY;
let enabledUPCSubscriptionTagCommander = SitePreferences.ENABLE_SUBSCRIPTION && SitePreferences.ENABLE_UPC_SUBSCRIPTION_TAG_COMMANDER ? true : false;
let isIbexTagCommanderEnabled = SitePreferences.ENABLE_IBEX_TAG_COMMANDER;

function InactiveUserCartUpdates() {
    if (typeof(Storage) !== "undefined") {
        var customerStatus = sessionStorage.getItem("customerStatus");

        if (customerStatus == "Inactive" && customerStatus != null && customerStatus != undefined) {
            for (var i = 0; i < wa_data.cart.content.length; i++) {
                if (wa_data.cart.content[i].productName.indexOf("uplay plus - monthly") > -1) {
                    wa_data.cart.content[i].productName = "uplay plus - renewal - monthly subscription";
                }
            }
        }
    }
}

let events = {
    // update global layer cart object with current information
    updateCartData: function (action) {
        if (enabledSubscriptionTagCommander && pageContext.ns == "orderconfirmation") {
            InactiveUserCartUpdates();
        }

        // UPC - CART
        if (enabledUPCSubscriptionTagCommander && isLegacy && pageContext.type == "Cart") {
            InactiveUserCartUpdates();
        }

        // UPC - ORDER CONFIRMATION
        if (enabledUPCSubscriptionTagCommander && isLegacy && pageContext.type == "orderconfirmation") {
            InactiveUserCartUpdates();
        }

        var activeCartWaDataPages = ["Cart", "orderconfirmation", "checkout"];

        if (activeCartWaDataPages.indexOf(pageContext.type) === -1) {
            return;
        }

        if (typeof tcCOToken === "string" || pageContext.type === "subscription_confirmation") {
            return;
        }

        $.ajax({
            type: "GET",
            url: Urls.tcDynamicCartData + "?d=" + (new Date()).getTime(),
            data: {},
            async: false,
            success: function (response) {
                wa_data.cart = JSON.parse(response);
                wa_data.cart.cartView = "0";

                if (action) {
                    if (action.cartAdd) {
                        wa_data.cart.cartAdd = "1";
                        wa_data.cart.cartRemove = "0";
                    } else if (action.cartRemove) {
                        wa_data.cart.cartAdd = "0";
                        wa_data.cart.cartRemove = "1";
                    }
                }

                if (isIbexTagCommanderEnabled && pageContext.ns === "ibexorderconfirmation") {
                    let $orderDetails = $(".order-information");
                    let orderNo = $orderDetails.length ? $orderDetails.data("order-number") : "";

                    if (orderNo) {
                        wa_data.cart.orderID = orderNo;
                    }
                }
            }
        });

        // UPC - CHECKOUT
        if (enabledUPCSubscriptionTagCommander && isLegacy && pageContext.ns == "checkout") {
            InactiveUserCartUpdates();
        }

        if (enabledSubscriptionTagCommander && pageContext.ns == "opc") {
            InactiveUserCartUpdates();
        }
    }
};

export { events as cartTrackingEvents };
