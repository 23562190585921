/**
 * @function
 * @description update ubisoftId from adobe tracking params after the refresh after the login
 */
const updateHomepageTrackingParams = () => {
    var homepageWrapper = $(".homepage-wrapper");

    if (homepageWrapper.length) {
        var pageParams = homepageWrapper.data("page-params");
        var ubisoftId = User.isLoggedIn ? User.ubisoftId : "";

        if (pageParams !== undefined) {
            pageParams.ubisoftId = ubisoftId;

            homepageWrapper[0].removeAttribute("data-page-params");
            homepageWrapper[0].setAttribute("data-page-params", JSON.stringify(pageParams));
        }
    }
};

/**
 * @function
 * @description initialize adobe tracking events on double push banner
 */
const initDoubleBannerAdobeTracking = () => {
    const trackedElementsCollection = document.getElementsByClassName("push-wrapper--double");

    if (trackedElementsCollection.length) {
        trackedElementsCollection.forEach(container => {
            setDoubleBannerImpressionTrackingParams(container);
        });
    }
};

/**
 * @function
 * @description sets adobe tracking params for impression events triggered in the adobe_analytics_events js file by the observer
 * @param { Object } container to set tracking params on
 */
const setDoubleBannerImpressionTrackingParams = container => {
    if (!container) return;

    const actionType = getActionTypeOnItemsContainedDouble(container);
    const trackingData = getDoubleBannerTrackingData(container, actionType);

    if (trackingData) {
        if (container.hasAttribute("data-track-impression")) {
            // set data attributes in order for observer to init the impression event with the given params
            container.setAttribute("data-impression-params", JSON.stringify(trackingData.impressionParams));
        }

        let $productTileContainersCollection = $(container).find(".double .push-product .product-tile");

        $.each($productTileContainersCollection, (index, element) => {
            element.setAttribute("data-params", JSON.stringify(trackingData.clickParams));
        });
    }
};

/**
 * @function
 * @description get tracking data, based on event type.
 * @param { Object } container to get default tracking params from and additional params needed to the specific tracking data.
 * @param { String } actionType to get the tracking data for
 * @returns { Object } event params
 */
const getDoubleBannerTrackingData = (container, actionType) => {
    if (!actionType || !container) return null;

    // get default tracking params
    const defaultEventParams = getDefaultTrackingParams(container);
    let productIdsArray = [];
    const $containerObj = $(container);
    let categoryPageName;
    let subProduct;

    // additional logic if container contains product links
    if (actionType === "action-product" || actionType === "action-custom-subscription") {
        const $productTileContainersCollection = $containerObj.find(".push-product .product-tile[data-pid]");

        // set array of product Ids to be passed in the tracking params
        productIdsArray = $productTileContainersCollection.length ?
            $.map($productTileContainersCollection, productTileElem => {
                return productTileElem.getAttribute("data-pid");
            }) :
            null;
    }

    switch (actionType) {
        case "action-product":
            return {
                impressionParams: {
                    ...defaultEventParams,
                    category: "scroll",
                    action: "product",
                    productImpression: productIdsArray
                },
                clickParams: {
                    ...defaultEventParams,
                    category: "action",
                    action: "product : see more",
                    isProductClicked: 1
                }
            };
        case "action-category":
            categoryPageName = $containerObj.find("[data-category-page-name]:eq(0)").data("category-page-name");

            return {
                impressionParams: {
                    ...defaultEventParams,
                    category: "scroll",
                    action: categoryPageName
                },
                clickParams: {
                    ...defaultEventParams,
                    category: "action"
                }
            };
        case "action-custom-subscription":
            subProduct = $containerObj.find("[data-sub-product]:eq(0)").data("sub-product");

            return {
                impressionParams: {
                    ...defaultEventParams,
                    category: "scroll",
                    action: subProduct,
                    productImpression: productIdsArray
                },
                clickParams: {
                    ...defaultEventParams,
                    category: "action",
                    isProductClicked: 1,
                    productSalesType: "subscription"
                }
            };
        case "action-custom":
            return {
                impressionParams: {
                    ...defaultEventParams,
                    category: "scroll",
                    action: "visible"
                },
                clickParams: {
                    ...defaultEventParams,
                    category: "action"
                }
            };
        default:
            return {
                impressionParams: {},
                clickParams: {}
            };
    }
};

/**
 * @function
 * @description get default tracking params
 * @param { Object } container to get default tracking params from
 * @returns { Object } default tracking params for the given element
 */
const getDefaultTrackingParams = container => {
    if (!container || (container && !container.hasAttribute("data-default-tracking-params"))) return null;

    return JSON.parse(container.dataset.defaultTrackingParams);
};

/**
 * @function
 * @description get action type based on main container child elements
 * @param { Object } container container object to be used for checks on child elements contained
 * @returns { String } action type
 */
const getActionTypeOnItemsContainedDouble = container => {
    if (!container) return "";

    const $container = $(container);
    let actionType = $container.find("[data-banner-type]:eq(0)").data("banner-type");

    return `action-${actionType}`;
};

/**
 * @function
 * @description initialize adobe tracking events on triple push banner
 */
const initTripleBannerAdobeTracking = () => {
    const trackedElementsCollection = document.getElementsByClassName("push-wrapper--triple");

    if (trackedElementsCollection.length) {
        trackedElementsCollection.forEach(container => {
            setTripleBannerImpressionTrackingParams(container);
        });
    }
};

/**
 * @function
 * @description sets adobe tracking params for impression events triggered in the adobe_analytics_events js file by the observer
 * @param { Object } container to set tracking params on
 */
const setTripleBannerImpressionTrackingParams = container => {
    if (!container) return;

    const actionType = getActionTypeOnItemsContainedTriple(container);
    const trackingData = getTripleBannerTrackingData(container, actionType);

    if (trackingData) {
        if (container.hasAttribute("data-track-impression")) {
            // set data attributes in order for observer to init the impression event with the given params
            container.setAttribute("data-impression-params", JSON.stringify(trackingData.impressionParams));
        }

        let $productTileContainersCollection = [];

        if (actionType === "action-product") {
            $productTileContainersCollection = $(container).find(".triple .push-product .product-tile.card");
        } else if (actionType === "action-category") {
            $productTileContainersCollection = $(container).find(".triple .category-asset-wrap");
        }

        $.each($productTileContainersCollection, (index, element) => {
            if (actionType === "action-category") {
                const categoryPageName = $(element).data("category-page-name");

                trackingData.clickParams.action = categoryPageName + " : see products list";
            }

            trackingData.clickParams.slotNumber = index + 1;
            element.setAttribute("data-params", JSON.stringify(trackingData.clickParams));
        });
    }
};

/**
 * @function
 * @description get tracking data, based on event type.
 * @param { Object } container to get default tracking params from and additional params needed to the specific tracking data.
 * @param { String } actionType to get the tracking data for
 * @returns { Object } event params
 */
const getTripleBannerTrackingData = (container, actionType) => {
    if (!actionType || !container) return null;

    // get default tracking params
    const defaultEventParams = getDefaultTrackingParams(container);
    let productIdsArray = [];
    const $containerObj = $(container);

    // additional logic if container contains product links
    if (actionType === "action-product") {
        const $productTileContainersCollection = $containerObj.find(".push-product .product-tile[data-pid]");

        // set array of product Ids to be passed in the tracking params
        productIdsArray = $productTileContainersCollection.length ?
            $.map($productTileContainersCollection, productTileElem => {
                return productTileElem.getAttribute("data-pid");
            }) :
            null;
    }

    switch (actionType) {
        case "action-product":
            return {
                impressionParams: {
                    ...defaultEventParams,
                    category: "scroll",
                    action: "product",
                    productImpression: productIdsArray
                },
                clickParams: {
                    ...defaultEventParams,
                    category: "action",
                    action: "product : see more"
                }
            };

        case "action-category": {
            const categoryPageName = $containerObj.find("[data-category-page-name]")?.data("category-page-name");

            return {
                impressionParams: {
                    ...defaultEventParams,
                    category: "scroll",
                    action: categoryPageName
                },
                clickParams: {
                    ...defaultEventParams,
                    category: "action"
                }
            };
        }

        default:
            return {
                impressionParams: {},
                clickParams: {}
            };
    }
};

/**
 * @function
 * @description get action type based on main container child elements
 * @param { Object } container container object to be used for checks on child elements contained
 * @returns { String } action type
 */
const getActionTypeOnItemsContainedTriple = container => {
    if (!container) return "";

    const $container = container instanceof jQuery ? container : $(container);
    let actionType = "";

    if ($container.find(".push-product").length) {
        actionType = "product";
    }

    if ($container.find(".category-asset-wrap").length) {
        actionType = "category";
    }

    return `action-${actionType}`;
};

export { updateHomepageTrackingParams, initDoubleBannerAdobeTracking, initTripleBannerAdobeTracking };
