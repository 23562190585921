var $document = $(document);
var isUPC = $(".app_uplay").length;
var isWalletOrder = $("#check-wallet-order").length ? $("#check-wallet-order").data("is-wallet-order") : false;
let $cache = {};

/**
 * @function
 * @description initialize cache object
 */
const initCache = () => {
    $cache = {
        document: $(document)
    };
};

/**
 * Initialize wallet click events
 * @private
 */
function initializeWalletClickEvents() {
    initCache();
    walletCheckoutEvents();
    walletManagementPageEvents();
    walletConfirmationPageEvents();
    walletTopupPageEvents();
    walletSearchRefinementEvent();
}

/**
 * Initialize wallet management page - click events
 * @private
 */
function walletManagementPageEvents() {
    $document.ready(function () {
        if (pageContext && pageContext.ns == "walletmanagementpage") {
            // Displaying "Wallet Welcome Banner on WMP"
            var $walletWelcomeBanner = $(".wallet-mp-greetings-banner");

            if ($walletWelcomeBanner && !$walletWelcomeBanner.hasClass("hide")) {
                // updateTcEventBuffer is not initialized yet and this is why I have added an event to be triggered after initialization
                $cache.document.on("tcEventsReady", function () {
                    $cache.document.trigger("updateTcEventBuffer", {
                        this: $walletWelcomeBanner,
                        eventType: "clickevent",
                        action: {
                            ACTION: "page - notification - wallet - welcome on your wallet"
                        }
                    });
                });
            }

            // Displaying "Wallet Advantages on WMP"
            var $walletAdvantagesBanner = $(".wallet-advantages-wrapper");

            if ($walletAdvantagesBanner) {
                // updateTcEventBuffer is not initialized yet and this is why I have added an event to be triggered after initialization
                $cache.document.on("tcEventsReady", function () {
                    $cache.document.trigger("updateTcEventBuffer", {
                        this: $walletWelcomeBanner,
                        eventType: "clickevent",
                        action: {
                            ACTION: "page - notification - wallet - wallet advantages"
                        }
                    });
                });
            }

            // events for greetings slider
            var $greetingsSection = $(".js-wallet-greetings-reveal");
            var $currentSlide = Number($greetingsSection.find(".slick-current").attr("data-slick-index"));

            if ($greetingsSection.length && !Number.isNaN($currentSlide)) {
                sendGreetingsTcEvent($currentSlide, "slide");

                $document.on("click", ".js-wallet-greetings-reveal .slider-control-next", function () {
                    var $currentSlide = Number($greetingsSection.find(".slick-current").attr("data-slick-index"));

                    sendGreetingsTcEvent($currentSlide, "next");
                    sendGreetingsTcEvent($currentSlide, "slide");
                });

                $document.on("click", ".js-wallet-greetings-reveal .slider-control-prev", function () {
                    var $currentSlide = Number($greetingsSection.find(".slick-current").attr("data-slick-index"));

                    sendGreetingsTcEvent($currentSlide, "previous");
                    sendGreetingsTcEvent($currentSlide, "slide");
                });
            }
        }
    });

    // Wallet Management Page - "See All Games" Button
    $document.on("click", "#wmp-see-more-button-desktop, #wmp-see-more-button-mobile", function () {
        tc_events_100(this, "clickevent", {
            ACTION: "wallet banner - go to listing page - all games"
        });
    });

    // Wallet Management Page - "See All FAQ" Button
    $document.on("click", "#wmp-faq-button", function () {
        tc_events_100(this, "clickevent", {
            ACTION: "wallet - navigation - read faq"
        });
    });

    // Wallet Management Page - "FAQ Question" Items
    $document.on("click", "a.wallet-faq-content", function () {
        var questionName = $(this).data("english-question-name") ? $(this).data("english-question-name") : "";
        var questionNumber = $(this).data("question-number") ? $(this).data("question-number") : "";
        var actionName;

        if (questionName) {
            actionName = "wallet - display faq - " + questionName;
            tc_events_100(this, "clickevent", {
                ACTION: actionName
            });
        } else if (questionNumber) {
            actionName = "wallet - display faq - " + questionNumber;
            tc_events_100(this, "clickevent", {
                ACTION: actionName
            });
        }
    });

    // Wallet Management Page - "Welcome Banner - Cancel Button"
    $document.on("click", ".js-greetings-banner-close-button", function () {
        tc_events_100(this, "clickevent", {
            ACTION: "page - notification - wallet - welcome on your wallet - close"
        });
    });

    // Wallet Management Page - "Welcome Banner - Learn More Button"
    $document.on("click", ".wallet-mp-greetings-banner__button", function () {
        tc_events_100(this, "clickevent", {
            ACTION: "page - notification - wallet - welcome on your wallet - learn more"
        });
    });

    // Wallet Management Page - "Wallet Advantages - Learn More Button"
    $document.on("click", "#wallet-advantages-learn-more", function () {
        tc_events_100(this, "clickevent", {
            ACTION: "page - notification - wallet - wallet advantages - learn more"
        });
    });
}

/**
 * Function used to send events regarding greetings slider to tracking
 */
function sendGreetingsTcEvent(currentSlide, type) {
    var action;

    if (type === "slide") {
        currentSlide += 1;
        action = "page - notification - wallet - welcome to your wallet step " + currentSlide;
    } else if (type === "next") {
        action = "page - notification - wallet - welcome to your wallet step " + currentSlide + " " + "next";
    } else if (type === "previous") {
        currentSlide += 2;
        action = "page - notification - wallet - welcome to your wallet step " + currentSlide + " " + "previous";
    }

    if (action !== undefined) {
        tc_events_100(this, "clickevent", {
            ACTION: action
        });
    }
}

/**
 * Initialize wallet order checkout page - click events
 * @private
 */
function walletCheckoutEvents() {
    // Triggers this click event only for UPC to simulate the edit button as clicked on cart step.
    $document.on("click", "#upc-wallet-edit-cart", function () {
        tc_events_100(this, "clickevent", {
            ACTION: "wallet cart - navigation - edit"
        });
    });

    if (isUPC && isWalletOrder && pageContext && pageContext.ns === "cart") {
        $document.on("click", "#cart-items-form", function () {
            tc_events_100(this, "clickevent", {
                ACTION: "wallet cart - navigation - edit"
            });
        });
    }

    if (isUPC && pageContext && pageContext.ns == "checkout") {
        // UPC Checkout - "Wallet Payment Method"
        $document.on("click", ".payment-option.wallet", function (e) {
            if (e.originalEvent) {
                tc_events_100(this, "clickevent", {
                    ACTION: "checkout - select payment method - wallet"
                });
            }
        });

        // UPC Checkout - "Other Payment Method"
        $document.on("click", ".payment-option.other", function (e) {
            if (e.originalEvent) {
                tc_events_100(this, "clickevent", {
                    ACTION: "checkout - select payment method - other payment method"
                });
            }
        });

        // UPC - "Credit Wallet" button on checkout step
        $document.on("click", ".checkout-last-step", function () {
            if (isWalletOrder) {
                tc_events_100(this, "clickevent", {
                    ACTION: "wallet checkout - navigation - credit wallet"
                });
            }
        });
    } else if (pageContext && pageContext.ns == "opc") {
        // OPC - "Wallet Payment Method"
        $document.on("click", ".opc-payment-option.wallet", function (e) {
            if (e.originalEvent) {
                tc_events_100(this, "clickevent", {
                    ACTION: "checkout - select payment method - wallet"
                });
            }
        });

        // OPC - "Other Payment Method"
        $document.on("click", ".opc-payment-option.other", function () {
            tc_events_100(this, "clickevent", {
                ACTION: "checkout - select payment method - other payment method"
            });
        });

        // OPC - "Edit" button on amount selected section of checkout page.
        $document.on("click", ".edit-amount.animated-underline", function () {
            tc_events_100(this, "clickevent", {
                ACTION: "wallet checkout - navigation - edit"
            });
        });

        // OPC - "Edit" button on payment step for saved payment methods.
        $document.on("click", ".edit-saved", function () {
            var savedAddress = $(".savedAddressList .saved_address_tile").parent().find(".edit-saved").length;

            if (savedAddress && isWalletOrder) {
                tc_events_100(this, "clickevent", {
                    ACTION: "wallet checkout - navigation - edit"
                });
            }
        });

        // OPC - "Credit Wallet" button
        if (isWalletOrder) {
            $document.on("click", "#opc-submit-order-button", function () {
                tc_events_100(this, "clickevent", {
                    ACTION: "wallet checkout - navigation - credit wallet"
                });
            });
        }
    }
}

/**
 * Initialize wallet confirmation page - click events
 * @private
 */
function walletConfirmationPageEvents() {
    // Confirmation page - "Browse Catalog" button
    $document.on("click", ".wallet-browse-catalog-button", function () {
        tc_events_100(this, "clickevent", {
            ACTION: "wallet thank you page - go to listing page - all games"
        });
    });
}

function walletTopupPageEvents() {
    // Wallet Topup Page - "Back to Wallet management page" link
    $document.on("click", "#back-to-wmp-link", function () {
        tc_events_100(this, "clickevent", {
            ACTION: "add funds to your wallet - navigation - my wallet"
        });
    });
}

/**
 * Initialize Wallet search refinement click event on CLPs.
 * @private
 */
function walletSearchRefinementEvent() {
    // CLP - Wallet Refinement checkbox
    $document.on("click", "#wallet-refinement", function () {
        let selectedInput = $(this).find("input");

        if (selectedInput) {
            if (selectedInput.prop("checked")) {
                tc_events_100(this, "clickevent", {
                    ACTION: "listing page - filter applied - my wallet"
                });
            } else {
                tc_events_100(this, "clickevent", {
                    ACTION: "listing page - filter removed - my wallet"
                });
            }
        }
    });
}

var walletTagCommanderEvents = {
    init: function () {
        initializeWalletClickEvents();
    }
};

module.exports = walletTagCommanderEvents;
