/**
 * @function
 * @description collects and submits data to accertify
 * @param {String} basketID - Id of the current basket
 */
function submitAccertifyData(basketID) {
    // configuration
    var currentDate = new Date();
    var tid = basketID + currentDate.getTime();
    // Your site key from your credentials document.
    var sid = SitePreferences.INBROWSER_SITE_KEY;
    // Environment URL.
    var cUrl = SitePreferences.INBROWSER_COLLECTOR_URL;

    var _cc = window._cc = window._cc || [];

    _cc.push(["ci",
        {
            sid: sid,
            tid: tid
        }]);
    _cc.push(["st", 500]);

    _cc.push(["sf", function () {
        // only set the transaction ID and send the transaction ID to your back end services if the callback was received.
        var formFieldTid = document.querySelector("#dwfrm_cart_tidAccertify");

        if (formFieldTid) {
            formFieldTid.value = tid;
        }
    }]);

    _cc.push(["run", cUrl]);

    // fetch the script
    (function () {
        var c = document.createElement("script");

        c.type = "text/javascript";
        c.async = false;
        c.src = cUrl + "/cc.js?sid=" + sid + "&ts=" + (new Date()).getTime() + "&tid=" + tid;

        var s = document.getElementsByTagName("script")[0];

        s.parentNode.insertBefore(c, s);
    })();
}

export { submitAccertifyData };
