/**
 * @description Creates product recommendation carousel using jQuery jcarousel plugin
 **/
import "jquery-ui/ui/widgets/tabs";
import "slick-carousel";
import { quickview } from "../../quickview";

const data = {
    recommendations: "[data-recommendations]",
    recommendationsWrapper: "[data-recommendations-wrapper]",
    anchorLinkWrapper: "[data-anchor-link-wrapper]",
    slotWrapper: "[data-predictive-intelligence]"
};

var $sliderSettings = {
        speed: 185,
        slide: "li",
        infinite: false,
        slidesToShow: 4,
        variableWidth: true,
        responsive: [
            {
                breakpoint: 767,
                settings: "unslick"
            }
        ]
    },
    $crossSellCarousel = $("#cross-sell-recommendations ul"),
    $upSellCarousell = $("#up-sell-recommendations ul"),
    $cache = {};

var initCache = function () {
    $cache.tabsBottomContainer = $(".pdp-bottom-tabs");
    $cache.firstTab = "";
    $cache.secondTab = $(".you-may-like-section-thumb");
    $cache.pdpQuickViewButton = $(".pdp-main .quick-view-button a");
    $cache.viewMoreButtonAdditionalContent = $("#additional-content-section .more-link span");
    $cache.viewMoreButtonRecommendation = $("#recommendation_section .more-link span, #recommendations-cross-sell .more-link span");
    $cache.slotWrapper = $(data.slotWrapper);

    // rule according to NCSA legislation
    if (Resources.IS_NCSA && $(".summary-section-thumb").length) {
        $cache.firstTab = $(".summary-section-thumb");
    } else if ($(".content-section-thumb").length) {
        $cache.firstTab = $(".content-section-thumb");
    } else if ($(".compare-section-thumb").length) {
        $cache.firstTab = $(".compare-section-thumb");
    } else if ($(".summary-section-thumb").length) {
        $cache.firstTab = $(".summary-section-thumb");
    } else if ($(".key-features-section-thumb").length) {
        $cache.firstTab = $(".summary-section-thumb");
    }
};

var initSlickCarousel = function () {
    $crossSellCarousel.slick($sliderSettings);
    $upSellCarousell.slick($sliderSettings);
};

var eventListeners = function () {
    $cache.pdpQuickViewButton.on("click", function (ev) {
        ev.preventDefault();

        quickview.show({
            url: $(this).attr("href"),
            source: "quickview"
        });
    });

    $cache.secondTab.on("click", function () {
        if ($(".cross-sell").find(".recommendation-item").length < 5) {
            $("#cross-sell-recommendations").find(".jcarousel-next").addClass("inactive");
        }
    });

    // load more products on PDP (UPC) on additional-content
    $cache.viewMoreButtonAdditionalContent.on("click", function () {
        $("#additional-content-section .grid-tile").toggleClass("show-more-products");
        $(this).hide();
    });

    // load more products on PDP (UPC) on recommendation section
    $cache.viewMoreButtonRecommendation.on("click", function () {
        $("#recommendation_section .grid-tile, #recommendations-cross-sell .grid-tile").toggleClass("show-more-products");
        $(this).hide();
    });
};

var initDOM = function () {
    $cache.tabsBottomContainer.tabs({
        activate: function () {}
    });
    initSlickCarousel();
};

/**
* @function
* @description function to make the recommendation visible if the corresponding slot is configured and content is added to the page
*/
const showRecommendation = () => {
    $cache.slotWrapper.each((index, element) => {
        const $this = $(element);

        if (!$this.children().length) {
            return;
        }

        const $recommendation = $this.closest(data.recommendations);

        $recommendation.removeClass("hide");
        showAnchorLink($recommendation);

        const $recommendationsWrapper = $recommendation.closest(data.recommendationsWrapper);

        if (!$recommendationsWrapper.length) {
            return;
        }

        const wrapperHasVisibleRecommendations = $recommendationsWrapper.find(data.recommendations + ":not(.hide)").length;

        if (wrapperHasVisibleRecommendations) {
            $recommendationsWrapper.removeClass("hide");
            showAnchorLink($recommendationsWrapper);
        }
    });
};

/**
* @function
* @description shows the title for predictive intelligence recommendations slot on PDP/PHP
* @param {object} $recommendationSlot - object - jquery selector of the targeting slot
*/
const showRecommendationTitle = $recommendationSlot => {
    const $slotTiles = $recommendationSlot.find(".product-tile");

    if ($recommendationSlot.closest("[data-predictive-intelligence]").length && $slotTiles.length) {
        const $sectionWrapper = $recommendationSlot.closest("[data-recommendations]");

        if ($sectionWrapper.length) {
            const $currentSlotTitle = $sectionWrapper.find(".pdp-section-title").length ? $sectionWrapper.find(".pdp-section-title") : $sectionWrapper.find(".pd-section__title");
            const $currentSlotDescription = $sectionWrapper.find(".pd-section__description");

            if ($currentSlotTitle.length) {
                $currentSlotTitle.removeClass("hide");
            }

            if ($currentSlotDescription.length) {
                $currentSlotDescription.removeClass("hide");
            }
        }
    }
};

/**
* @function
* @description function to show the anchor link if the targeting section is visible
* @param {object} $element - object - jquery selector of the targeting section
*/
const showAnchorLink = $element => {
    const id = $element.attr("id");

    if (!id || $element.hasClass("hide")) {
        return;
    }

    const $anchorLink = $(`[href="#${id}"]`);

    if (!$anchorLink.length) {
        return;
    }

    // show the anchor links targeting the hidden element
    $anchorLink.closest(data.anchorLinkWrapper).removeClass("hide");
};

function initRecommendations() {
    initCache();
    showRecommendation();
    initDOM();
    eventListeners();
}

export { initRecommendations, showRecommendationTitle };
