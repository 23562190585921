/**
 * Initialize events
 * @private
 */
function initializeEvents() {
    var $cache = $cache || {};

    // You may also like and bonuses for this game tiles click events
    $("#you-may-like-section, #additional-content-section, .order-confirmation-suggestions").on("click", ".card-overlay a.button", function () {
        if (pageContext.ns == "orderconfirmation") {
            var isSubscriptionConfirmationPage = $cache.document.find("div.subscription-product-details").length > 0 ? true : false;

            if (isSubscriptionConfirmationPage) {
                return;
            }
        }

        var $this = $(this);
        var action,
            edition,
            tabName,
            productName;
        var eventValue = $this.closest(".card-overlay").data("tc100");

        edition = eventValue.edition;
        productName = eventValue.productName;
        tabName = $this.closest("section.accordion-content-section").data("name");

        // in case 'Thank You' Page
        if (!tabName && $this.closest(".order-confirmation-suggestions")) {
            tabName = "you may also like";
        }

        if ($this.hasClass("preorder")) {
            action = "preorder";
        } else if ($this.hasClass("see-more-btn")) {
            action = "see more";
        } else {
            action = "buy now";
        }

        tc_events_100(this, "clickevent", {
            ACTION: tabName + " - go to product - " + action + " - " + productName.toLowerCase() + " - " + edition.toLowerCase()
        });
    });
}

var pdptabs = {
    init: function () {
        initializeEvents();
    }
};

module.exports = pdptabs;
