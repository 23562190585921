const data = {
    sessionReplaySampleRate: window.SitePreferences?.RUM_SESSION_REPLAY_SAMPLE_RATE || window.exposedData?.rum?.rumSessionReplaySampleRate,
    sessionSampleRate: window.SitePreferences?.RUM_SESSION_SAMPLE_RATE || window.exposedData?.rum?.rumSessionSampleRate,
    rumConfig: window.SitePreferences?.RUM_CONFIG || window.exposedData?.rum?.rumConfig,
    customerId: window.User?.ubisoftId || window.exposedData?.rum?.customerId || ""
};
const botPattern = "(googlebot|bot|Googlebot-Mobile|Googlebot-Image|Google favicon|Mediapartners-Google|bingbot|slurp|java|wget|curl|Commons-HttpClient|Python-urllib|libwww|httpunit|nutch|phpcrawl|msnbot|jyxobot|FAST-WebCrawler|FAST Enterprise Crawler|biglotron|teoma|convera|seekbot|gigablast|exabot|ngbot|ia_archiver|GingerCrawler|webmon |httrack|webcrawler|grub.org|UsineNouvelleCrawler|antibot|netresearchserver|speedy|fluffy|bibnum.bnf|findlink|msrbot|panscient|yacybot|AISearchBot|IOI|ips-agent|tagoobot|MJ12bot|dotbot|woriobot|yanga|buzzbot|mlbot|yandexbot|purebot|Linguee Bot|Voyager|CyberPatrol|voilabot|baiduspider|citeseerxbot|spbot|twengabot|postrank|turnitinbot|scribdbot|page2rss|sitebot|linkdex|Adidxbot|blekkobot|ezooms|dotbot|Mail.RU_Bot|discobot|heritrix|findthatfile|europarchive.org|NerdByNature.Bot|sistrix crawler|ahrefsbot|Aboundex|domaincrawler|wbsearchbot|summify|ccbot|edisterbot|seznambot|ec2linkfinder|gslfbot|aihitbot|intelium_bot|facebookexternalhit|yeti|RetrevoPageAnalyzer|lb-spider|sogou|lssbot|careerbot|wotbox|wocbot|ichiro|DuckDuckBot|lssrocketcrawler|drupact|webcompanycrawler|acoonbot|openindexspider|gnam gnam spider|web-archive-net.com.bot|backlinkcrawler|coccoc|integromedb|content crawler spider|toplistbot|seokicks-robot|it2media-domain-crawler|ip-web-crawler.com|siteexplorer.info|elisabot|proximic|changedetection|blexbot|arabot|WeSEE:Search|niki-bot|CrystalSemanticsBot|rogerbot|360Spider|psbot|InterfaxScanBot|Lipperhey SEO Service|CC Metadata Scaper|g00g1e.net|GrapeshotCrawler|urlappendbot|brainobot|fr-crawler|binlar|SimpleCrawler|Livelapbot|Twitterbot|cXensebot|smtbot|bnf.fr_bot|A6-Indexer|ADmantX|Facebot|Twitterbot|OrangeBot|memorybot|AdvBot|MegaIndex|SemanticScholarBot|ltx71|nerdybot|xovibot|BUbiNG|Qwantify|archive.org_bot|Applebot|TweetmemeBot|crawler4j|findxbot|SemrushBot|yoozBot|lipperhey|y!j-asr|Domain Re-Animator Bot|AddThis)";
const inGameUserAgent = "Mozilla/5.0 (Windows NT 6.2; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/91.0.4472.114 Safari/537.36";

window.dataDogRumStarted = false;

/**
 * @function
 * @description Push DataDog JS file
 */
const pushDatadogScript = () => {
    /* eslint-disable */
    (function (h, o, u, n, d) {
        h = h[d] = h[d] || { q: [], onReady: function (c) { h.q.push(c) } }
        d = o.createElement(u); d.async = 1; d.src = n
        n = o.getElementsByTagName(u)[0]; n.parentNode.insertBefore(d, n)
    })(window, document, "script", "https://www.datadoghq-browser-agent.com/us1/v5/datadog-rum.js", "DD_RUM");
    /* eslint-disable */
};

/**
 * @function
 * @description Determines if tracking is allowed based on user consent and the current page context.
 * @returns {boolean} - Returns `true` if analytics cookies were accepted, otherwise `false`.
 */
const isTrackingAllowed = () => {
    const isCheckout = window.exposedData !== undefined;
    const checkoutConsent = localStorage.ubiPrivacyConsent !== undefined && JSON.parse(localStorage.ubiPrivacyConsent).analyticsAccepted;
    const storeConsent = window.ConsentResources?.trackingConsentDone && !window.ConsentResources?.analyticsOptOut;

    return isCheckout ? checkoutConsent : storeConsent;
};

/**
 * @function
 * @description initialize Data Dog Rum plugin in order to track user's performances
 */
const setDataDogRum = () => {
    const rumEnabled = window.SitePreferences?.RUM_ENABLED || window.exposedData?.rum?.rumEnabled;
    const regex = new RegExp(botPattern, "i");
    const isBotClient = regex.test(navigator.userAgent); // test regex patterns to identify known bot instances

    if (rumEnabled && !window.dataDogRumStarted && window.DD_RUM && data.rumConfig && !isBotClient) {
        // Remove Datadog tracking for web storefronts that are opened from INGAME browser microapp
        const userNavigator = navigator.userAgent;
        const isWEB = !(window.exposedData?.context?.isUBC);

        if (isWEB && userNavigator === inGameUserAgent) {
            data.sessionSampleRate = 0;
        }

        window.DD_RUM.onReady(() => {
            window.DD_RUM.init({
                ...data.rumConfig,
                sessionSampleRate: data.sessionSampleRate,
                sessionReplaySampleRate: data.sessionReplaySampleRate,
                beforeSend: event => {
                    // discard "enforce" csp_violation errors on ingestion
                    if (event.type === "error" && event.error?.csp?.disposition === "report") {
                        return false;
                    }
                }
            });

            // Used to get dwsid_s cookie line
            const dwsCookie = document.cookie.match(new RegExp("(^| )" + "dwsid_s" + "=([^;]+)"));
            const userId = dwsCookie ? dwsCookie[2] : null;

            // Set Datadog's User if we have cookie
            if (userId != null && userId != "") {
                window.DD_RUM.setUser({
                    sfcc_session_id: userId
                });
            }

            if (data.customerId) {
                window.DD_RUM.setUserProperty("id", data.customerId);
            }

            // Push specific context for checkout flow
            if (window.exposedData !== undefined) {
                window.DD_RUM.setUserProperty("checkout_flow", window.exposedData?.rum?.context);
            }

            // Push specific data for confirmation page
            if (window.exposedData?.rum?.orderType) {
                window.DD_RUM.setUserProperty("order_type", window.exposedData?.rum?.orderType);
            }

            if (window.exposedData?.rum?.orderId) {
                window.DD_RUM.setUserProperty("order_id", window.exposedData?.rum?.orderId);
            }

            // add error in datadog if 404 not found template is displayed
            const errorPage = document.querySelector("[data-page='generalError']");

            if (errorPage) {
                window.DD_RUM.addError(new Error("page_not_found"), {
                    url: window.location.href
                });
            }

            // After RUM is initialized, add extra context to RUM events collected from the application with the setGlobalContextProperty(key: string, value: any) API
            document.addEventListener("DataDogRum:setGlobalContextProperty", e => window.DD_RUM.setGlobalContextProperty(e.detail.key, e.detail.value));
            window.dataDogRumStarted = true;
        });
    }
};

/**
 * @function
 * @description Entry point for DataDogRum init, check if it's already done to not loop on it
 * @param {boolean} cookiesAccepted - true if analytics cookies accepted, message "PRIVACY_POLICY_ADDED_ANALYTICS_ON" received
 *  and datadog rum needs to be initialized immediately
 */
const initDataDogRum = (cookiesAccepted = false) => {
    if (!window.dataDogRumStarted) {
        // Push DataDog script only if it doesn't exist on the page
        if (!document.querySelector("script[src*='datadog-rum']")) {
            pushDatadogScript();
        }

        const trackingAllowed = cookiesAccepted || isTrackingAllowed();

        if (trackingAllowed) {
            setDataDogRum();
        }
    }
};

export { initDataDogRum };
