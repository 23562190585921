/**
 * Initialize wishlist tracking events
 * @private
 */
function initializeWishListTrackingEvents() {
    $(".add-variation-to-wishlist").on("click", function () {
        $(this).data("event", "addToWishList");
        submitEvent($(this));
    });

    $(".wishlist-add-to-cart").on("click", function () {
        $(this).data("event", "addToCartWishlist");
        submitEvent($(this));
    });

    $(".wishlist-add-to-cart-uplay").on("click", function () {
        $(this).data("event", "addToCartWishlist");
        submitEvent($(this));
    });

    $(".edit-button").off("click").on("click", function () {
        $(this).data("event", "editWishList");
        $(this).data("action", "edit game");
        submitEvent($(this));
    });

    $(".remove-from-wishlist").off("click").on("click", function () {
        $(this).data("event", "editWishList");
        $(this).data("action", "delete from wishlist");
        submitEvent($(this));
    });

    $(".update-wishlist").off("click").on("click", function () {
        $(this).data("event", "editWishList");
        $(this).data("action", "update wishlist");
        submitEvent($(this));
    });
}

var events = {
    /**
     * @function
     * @description Returns action value for tc100 event based on the name of event
     * @param {String} eventValue - Event value
     * @param {Object} eventErrors - List of event errors
     * @return {String} Action value for the event
    */
    getProductActionValue: function (eventValue, eventErrors) {
        var productActionValue = "";
        var productName = eventValue && eventValue.productName ? eventValue.productName.split("-")[0].trim().toLowerCase() : "";
        var productType = eventValue && eventValue.productType ? eventValue.productType : "";
        var productSize = eventValue && eventValue.productSize ? eventValue.productSize : eventErrors.size;
        var productColor = eventValue && eventValue.productColor ? eventValue.productColor : eventErrors.color;
        var productPlatform = eventValue && eventValue.platform ? eventValue.platform.toLowerCase() : eventErrors.platform;
        var productEdition = eventValue && eventValue.edition ? eventValue.edition.toLowerCase() : eventErrors.edition;

        if (productType != "") {
            if (productType == "games" || productType == "dlc") {
                productActionValue = productPlatform + " - " + productName + " - " + productEdition;
            } else if (productType == "apparel") {
                if (productSize == eventErrors.size && productColor == eventErrors.color) {
                    productActionValue = "no size no color selected - " + productName;
                } else if (productSize != eventErrors.size) {
                    productActionValue = productSize + " - " + productName + " - " + productColor;
                } else if (productColor != eventErrors.color) {
                    productActionValue = productSize + " - " + productName + " - " + productColor;
                }
            } else if (productType == "collectible") {
                productActionValue = productName;
            }
        }

        return productActionValue;
    },
    /**
     * @function
     * @description Pushes the add to cart event for wishlist
     * @param {Object} selector The html selector that triggered the event
     * @param {Object} eventValue - Event data
    */
    addToCartWishlist: function (_this, eventValue) {
        var actionValue = eventValue.action || "";
        var eventErrors = {
            size: "no size selected",
            color: "no color selected",
            platform: "no platform selected",
            edition: "no edition selected"
        };
        var productActionValue = this.getProductActionValue(eventValue, eventErrors);
        var action = $(_this).hasClass("friends-wishlist") ? "my friend wishlist" : "my wishlist";

        // If add to cart button is clicked from tab section on pdp.
        if ($(_this).data("from") === "tab") {
            $(_this).data("from", "");
            actionValue = "product tabs - " + action + " - " + productActionValue;
            $(_this).data("from", "");

            tc_events_100(_this, "clickevent", {
                ACTION: actionValue,
                PRODUCTID: eventValue.pid,
                PRODUCTNAME: eventValue.productName
            });
        } else {
            actionValue = action + " - " + actionValue + " - " + productActionValue;
            tc_events_100(_this, "clickevent", {
                ACTION: actionValue,
                PRODUCTID: eventValue.pid,
                PRODUCTNAME: eventValue.productName
            });
        }
    },
    /**
     * @function
     * @description Pushes the add to wishlist event for wishlist
     * @param {Object} selector The html selector that triggered the event
     * @param {Object} eventValue - Event data
     * @param {String} eventSource - Event source of the data
    */
    addToWishList: function (_this, eventValue, eventSource) {
        var actionValue = "";
        var eventErrors = {
            size: "size missing",
            color: "color missing",
            platform: "platform missing",
            edition: "edition missing"
        };
        var productActionValue = this.getProductActionValue(eventValue, eventErrors);
        var action = "add to wishlist";

        actionValue = eventSource + " - " + action + " - " + productActionValue;
        tc_events_100(_this, "clickevent", {
            ACTION: actionValue
        });
    },
    /**
     * @function
     * @description Pushes the edit wishlist event for wishlist
     * @param {Object} selector The html selector that triggered the event
     * @param {Object} eventValue - Event data
     * @param {String} eventSource - Event source of the data
    */
    editWishList: function (_this, eventValue, eventSource, eventAction) {
        var actionValue = "";
        var eventErrors = {
            size: "size missing",
            color: "color missing",
            platform: "platform missing",
            edition: "edition missing"
        };
        var productActionValue = this.getProductActionValue(eventValue, eventErrors);

        actionValue = eventSource + " - " + eventAction + " - " + productActionValue;
        tc_events_100(_this, "clickevent", {
            ACTION: actionValue
        });
    }
};

/**
 * Triggers a tagcommander "clickevent"
 * @param {Object} selector The html selector that triggered the event
 * @param {Object} data The type of action on the slider
 */
function submitEvent(selector) {
    var eventName = selector.data("event"),
        eventSource = selector.data("source"),
        eventAction = selector.data("action"),
        eventValue = selector.data("tc100");

    if (eventName != undefined && eventName != "" && eventSource != undefined && eventSource != "" && eventAction != undefined && eventAction != "" && eventValue != undefined && eventValue != "") {
        events[eventName](selector[0], eventValue, eventSource, eventAction);
    }
}

export { initializeWishListTrackingEvents, submitEvent };
