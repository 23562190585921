import { util } from "../../util";
import { ajaxGetJson } from "../../ajax";

var updateContainer = function (data) {
    var $availabilityMsg = $("#pdpMain .quantity .availability-msg"),
        message;

    if (!data) {
        $availabilityMsg.show().html(Resources.ITEM_STATUS_NOTAVAILABLE);

        return;
    }

    $availabilityMsg.empty();

    // Look through levels ... if msg is not empty, then create span el
    if (data.levels.IN_STOCK > 0) {
        if (data.levels.PREORDER === 0 && data.levels.BACKORDER === 0 && data.levels.NOT_AVAILABLE === 0) {
            // Just in stock
            message = Resources.IN_STOCK;
        } else {
            // In stock with conditions ...
            message = data.inStockMsg;
        }

        $availabilityMsg.show().append('<p class="in-stock-msg">' + message + "</p>");
    }

    if (data.levels.PREORDER > 0) {
        if (data.levels.IN_STOCK === 0 && data.levels.BACKORDER === 0 && data.levels.NOT_AVAILABLE === 0) {
            message = Resources.PREORDER;
        } else {
            message = data.preOrderMsg;
        }

        $availabilityMsg.show().append('<p class="preorder-msg">' + message + "</p>");
    }

    if (data.levels.BACKORDER > 0) {
        if (data.levels.IN_STOCK === 0 && data.levels.PREORDER === 0 && data.levels.NOT_AVAILABLE === 0) {
            message = Resources.BACKORDER;
        } else {
            message = data.backOrderMsg;
        }

        $availabilityMsg.show().append('<p class="backorder-msg">' + message + "</p>");
    }

    if (data.inStockDate !== "") {
        $availabilityMsg.show().append('<p class="in-stock-date-msg">' + String.format(Resources.IN_STOCK_DATE, data.inStockDate) + "</p>");
    }

    if (data.levels.NOT_AVAILABLE > 0) {
        var isRemainNotAvailable = false;

        if (data.levels.PREORDER === 0 && data.levels.BACKORDER === 0 && data.levels.IN_STOCK === 0) {
            message = Resources.NOT_AVAILABLE;
        } else {
            isRemainNotAvailable = true;
            message = Resources.REMAIN_NOT_AVAILABLE;
        }

        if (isRemainNotAvailable) {
            $availabilityMsg.show().append('<p class="not-available-msg remaining-not-available"><i class="error-icon"></i>' + message + "</p>");
        } else {
            $availabilityMsg.show().append('<p class="not-available-msg"><i class="error-icon"></i>' + message + "</p>");
        }
    }

    // Check for add to cart eligibility
    if (data.AddToCart !== null && data.AddToCart === false && data.MaxQuantity !== undefined) {
        // check if AddToCart is false and total quantity exceeds stock
        if (Number(data.ItemsRequested) + data.ItemsInCart < data.MaxQuantity) {
            // empty
        } else if (data.ItemsInCart === 0) {
            $availabilityMsg.show().html('<p class="not-available-msg"><i class="error-icon"></i>' + Resources.ITEM_STATUS_MAXREACHED + " " + data.MaxQuantity + ".</p>");
        } else {
            $availabilityMsg.show().html('<p class="not-available-msg"><i class="error-icon"></i>' + data.ItemsInCart + " " + Resources.ITEM_STATUS_NOTALLOWED + " " + data.MaxQuantity + ".</p>");
        }
    }
};

var getAvailability = function () {
    ajaxGetJson({
        url: util.appendParamsToUrl(Urls.getAvailability, {
            pid: $("#pid").val(),
            Quantity: $('.pdpForm input[name="Quantity"]').length == 0 ? 1 : $('.pdpForm input[name="Quantity"]').val()
        }),
        callback: updateContainer
    });
};

/**
 * @description Handler function to handle the add to cart button disable functionality when caching enabled
 */
var enableAddToCart = function () {
    ajaxGetJson({
        url: util.appendParamsToUrl(Urls.getAvailability, {
            pid: $(".add-to-cart-pdp").attr("data-pid") || $("#pid").val(),
            Quantity: $('.pdpForm input[name="Quantity"]').length == 0 ? 1 : $('.pdpForm input[name="Quantity"]').val()
        }),
        callback: function (response) {
            var addToCart = $(".add-to-cart-pdp");

            // if the product is in stock and the add to bag button is disabled
            // enabled the button and change it's text
            if (response && response.availableForSale && addToCart.prop("disabled")) {
                if ($(".platforms-list").length) {
                    // remove div.platform-availability-msg
                    $(".platforms-list .active .platform-availability-msg").remove();
                    // remove not-available
                    $(".platforms-list .active").removeClass("not-available");
                    // put selected
                    $(".platforms-list .active").addClass("selected");
                }

                // parent closes "product-add-to-cart" remove classes out-of-stock and disabled
                addToCart.closest(".pdp-cta").removeClass("out-of-stock");
                addToCart.closest(".pdp-cta").removeClass("disabled");
                // #add-to-cart button, remove disabled attr
                addToCart.prop("disabled", false);
                // change the text
                addToCart.html(Resources.ITEM_STATUS_AVAILABLE);
            } else if (response && !response.availableForSale && !addToCart.prop("disabled") && !$(".product-add-to-cart .pdp-cta").hasClass("no-platform-selected")) {
                if ($(".platforms-list").length) {
                    // remove not-available
                    $(".platforms-list .active").addClass("not-available");
                    // put selected
                    $(".platforms-list .active").removeClass("selected");
                }

                // parent closes "product-add-to-cart" remove classes out-of-stock and disabled
                addToCart.closest(".pdp-cta").addClass("out-of-stock");
                addToCart.closest(".pdp-cta").addClass("disabled");
                // #add-to-cart button, remove disabled attr
                addToCart.prop("disabled", true);
                // change the text
                addToCart.html(Resources.ITEM_STATUS_NOTAVAILABLE);
            }

            // check for repurchase flag
            if (response.DenyRepurchase) {
                // display repurchase error message
                $("#error-repurchase").removeClass("hidden");
                // disable the add to cart
                addToCart.closest(".pdp-cta").addClass("disabled");
            }
        }
    });
};

function initAvailability() {
    $("#pdpMain").on("change", '.pdpForm input[name="Quantity"]', getAvailability);
    $("#pdpMain").on("click", ".pdpForm .increment-quantity", getAvailability);
    $(document).off("availability.refresh").on("availability.refresh", enableAddToCart);
    $(document).ready(function () {
        enableAddToCart();
    });
}

export { initAvailability };
