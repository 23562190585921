import "jquery-match-height";
import { initializePriorityNavigation } from "./priorityNavigation";
import SimpleScrollbar from "simple-scrollbar/simple-scrollbar";

const data = {
    menuToggleBtn: "[data-cta=toggle-visibility]",
    menuToggleBtnActive: "[data-cta=toggle-visibility][aria-expanded=true]",
    languageWrapperSelector: ".language-selector-wrapper",
    mainMenuItem: ".top-level-uplay > li.has-subcategories:not(.more) > a",
    gameTitleMenuItem: ".game-title-item",
    menuItem: ".top-level-uplay > li",
    menuItemToggle: ".top-level-uplay > li.has-subcategories .menu-item-toggle",
    menuProductItem: ".menu_product_item"
};

let $cache = {};

/**
 * @function
 * @description Remove the active class for the expanded menu
 * */
const removeActiveMenuItem = $menuItem => {
    if (!$menuItem.length) {
        return;
    }

    // remove the active class
    $menuItem.removeClass("active");

    // remove active class from child elements
    $menuItem.find(".active").removeClass("active");
};

/**
 * @function
 * @description Toggles the active class for the expanded menu
 */
const toggleActiveMenuItem = $menuItem => {
    if (!$menuItem.length) {
        return;
    }

    const status = $menuItem.hasClass("active");

    // add the active class
    $menuItem.toggleClass("active");

    // remove active class from child elements of the siblings
    $menuItem.siblings(".active").find(".active").removeClass("active");

    // remove active class from siblings
    $menuItem.siblings(".active").removeClass("active").find("button").attr("aria-expanded", !status);

    if (status) {
        $menuItem.find(".active").removeClass("active");
    }
};

/**
 * @function
 * @description Initializes the cache object
 */
const initializeCache = () => {
    $cache = {
        document: $(document),
        menuContainer: $(".menu-wrapper"),
        menuCategory: $(".menu-category")
    };
};

/**
 * @function
 * @description function to check the menu items url and set the active one
 * @param {element} navigation menu item
 */
const checkMenuUrl = element => {
    $(element).each((i, el) => {
        const $this = $(el);
        const elementPath = $this.attr("href") && new URL($this.attr("href")).pathname.replace(/\/$/, "");
        const windowPath = window.location.pathname.replace(/\/$/, "");

        if (typeof elementPath !== "undefined" && (elementPath === windowPath || windowPath.startsWith(`${elementPath}/`))) {
            $this.addClass("selected");
        }
    });
};

/**
 * @function
 * @description Initializes events
 */
const initializeEvents = () => {
    $cache.document.on("click", data.mainMenuItem, e => {
        e.preventDefault();

        const $target = $(e.target).parent("li");

        if ($target.length) {
            toggleActiveMenuItem($target);
        }
    });

    $cache.document.on("click", data.menuItemToggle, e => {
        const $target = $(e.target).closest("li");

        if ($target.length) {
            toggleActiveMenuItem($target);
        }
    });

    // expand/collapse submenu for screen readers
    $cache.document.on("click", data.menuToggleBtn, e => {
        const $this = $(e.currentTarget);
        const $menuItem = $this.parent("li");
        const status = $menuItem.hasClass("active");

        // update aria expanded
        $this.parent().find(data.menuToggleBtnActive).attr("aria-expanded", "false");
        $this.attr("aria-expanded", !status);

        if (status) {
            removeActiveMenuItem($menuItem);
            $this.trigger("focus");
        }

        $menuItem.trigger(status ? "mouseleave" : "mouseenter", [{
            stopPropagation: true
        }]);
    });

    // close submenus on ESC
    $cache.document.on("keydown", e => {
        const $activeMenuItem = $cache.menuContainer.find(".active");

        if (e.key === "Escape" && $activeMenuItem.length) {
            e.stopPropagation();

            const $collapseMenuItem = $activeMenuItem.last();

            $collapseMenuItem.find(data.menuToggleBtnActive).trigger("click");
        }
    });

    // close menu item on focusout
    $cache.document.on("focusout", data.mainMenuItem, e => {
        if (e.relatedTarget && $(e.currentTarget).parent().has(e.relatedTarget).length) {
            return;
        }

        const $activeMenuBtn = $cache.menuContainer.find(data.menuToggleBtnActive);
        const $activeSubmenus = $cache.menuContainer.find(".active");

        if ($activeMenuBtn.length || $activeSubmenus.length) {
            $activeMenuBtn.attr("aria-expanded", "false");
            $activeSubmenus.removeClass("active");
        }
    });

    $cache.document.on("click", ".menu_product_item", e => {
        const $this = $(e.currentTarget);

        window.location.href = $this.find(".edition-link").attr("href");
        $this.find(".edition-link").trigger("menuClick");
    });

    checkMenuUrl(data.menuItem);

    $cache.document.on("mouseenter", data.gameTitleMenuItem, e => {
        const $this = $(e.target);
        const eventParams = $this.data("impression-params");

        if (eventParams) {
            $(document).trigger("gamestitlelist-item", {
                eventParams: eventParams
            });
        }
    });

    // tracking for menu items with submenus
    const clickableSubCategories = $cache.menuCategory.find(".has-subcategories");

    clickableSubCategories.on("click", e => {
        let $this = $(e.currentTarget);
        const categoryName = $this.data("category");
        let offerProductTilesIDs = [];

        const marketingPushes = e.currentTarget.querySelectorAll(".marketing-push__wrapper");

        marketingPushes.forEach(el => {
            const marketingTiles = el.querySelectorAll("[data-item-id]");

            marketingTiles.forEach(tile => {
                if (tile.dataset.itemId.length) {
                    // check if productID is not in the array to avoid dupplication with mobile node
                    if (!offerProductTilesIDs.includes(tile.dataset.itemId)) {
                        offerProductTilesIDs.push(tile.dataset.itemId);
                    }
                }
            });
        });

        const eventParams = {
            location: "store nav",
            locationDetail: "main nav : " + categoryName + "",
            category: "display",
            action: categoryName,
            productImpression: offerProductTilesIDs
        };

        $(document).trigger("navitemhassubmenu-click", {
            eventParams: eventParams
        });
    });
};

/**
 * @function
 * @description Initializes the navigation scrollbar
*/
const initializeNavigationSS = () => {
    $("[data-ss-container]").each(function () {
        SimpleScrollbar.initEl(this);
    });
};

var uplaynavigation = {
    init: () => {
        initializeCache();
        initializeEvents();
        initializePriorityNavigation();
        initializeNavigationSS();
    }
};

/**
 * @function
 * @description Initializes the functions used for uplay checkout revamp
 */
export { uplaynavigation };
