import { util } from "./util";

let $cache = {};

/**
 * @function
 * @description initialize cache object
 */
const initCache = () => {
    $cache = {
        storeAvailableTitle: $(".available-games-title"),
        storeAvailableGames: $(".store-available-games"),
        document: $(document)
    };
};

/**
 * @function
 * @description function to init the events for the store available games overlay
 */
const initGameListEvents = () => {
    initCache();

    // click event to display gamelist info
    $cache.document.off("click.storeAvailableGames").on("click.storeAvailableGames", ".available-games-title", event => {
        const $thisTitle = $(event.target);
        const $availableGamesElements = $(".platform-available-games");
        const platform = $(event.currentTarget).data("gameslist");

        if (platform) {
            const $gamelistElements = $(`.${platform}-gamelist`);

            if ($cache.storeAvailableTitle.hasClass("active")) {
                $cache.storeAvailableTitle.removeClass("active");
            }

            if (!$thisTitle.hasClass("active")) {
                $thisTitle.addClass("active");
            }

            $availableGamesElements.addClass("hide");

            if ($cache.storeAvailableGames.length && $gamelistElements.length) {
                $gamelistElements.removeClass("hide");
            }
        }
    });

    // click event to close gamelist info
    $cache.document.on("click", ".close-button-gamelist", () => {
        if ($cache.storeAvailableGames.hasClass("show")) {
            $cache.storeAvailableGames.remove();

            $(".plan-section-footer").removeAttr("aria-hidden inert");
            $(".plan-selection-buttons").removeAttr("aria-hidden inert");
            $(".gamelist-anchor").focus();
        }
    });

    document.addEventListener("Button:GamingList", e => {
        showStoreAvailableGames($(e.detail.button));
    });
};

/**
 * Get the store games modal
 * @param {JQuery} $anchor - The anchor element
 */
const getStoreGamesModal = async $anchor => {
    if ($(".store-available-games").length > 0) {
        return;
    } else {
        const gamingList = await util.getFetchResponse(Urls.gamingListPopin, undefined, {
            response: "JSON"
        });

        $anchor.after(gamingList.storeAvailableGames);
    }
};

/**
 * @function
 * @description function to show the store available games overlay
 * @param {Object} $anchor - jQuery selector which triggers the overlay
 */
const showStoreAvailableGames = async $anchor => {
    await getStoreGamesModal($anchor);
    initCache();

    const $anchorParent = $anchor.closest(".ubisoft-pass-choice-wrapper");
    const $anchorAttribute = $anchor.attr("data-gameslist");

    if ($anchorParent.length) {
        $cache.storeAvailableTitle = $anchorParent.find(".available-games-title");
        $cache.storeAvailableGames = $anchorParent.find(".store-available-games");
    }

    if ($cache.storeAvailableTitle.length > 0 && $cache.storeAvailableGames.length > 0) {
        $cache.storeAvailableGames.removeClass("hide").addClass("show");
        $(".titles-list").attr("tabindex", "0");
        $(".plan-section-footer").attr("aria-hidden", true).attr("inert", true);
        $(".plan-selection-buttons").attr("aria-hidden", true).attr("inert", true);

        let isSelectedPlatform = false;

        $cache.storeAvailableTitle.each((i, el) => {
            const $this = $(el);
            const $elementDataGamelist = $this.attr("data-gameslist");

            if (isSelectedPlatform) {
                return;
            }

            if (typeof $elementDataGamelist !== "undefined" && typeof $anchorAttribute !== "undefined" && $elementDataGamelist === $anchorAttribute && !isSelectedPlatform) {
                $this.trigger("click");
                isSelectedPlatform = true;
            } else {
                $cache.storeAvailableTitle.first().trigger("click");
            }
        });
    }
};

export { initGameListEvents };
