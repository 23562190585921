import "selectboxit/src/javascripts/jquery.selectBoxIt";
import { progressShow } from "../../progress";
import { util } from "../../util";
import { ajaxLoad } from "../../ajax";
import { initAddToCart } from "./addToCart";
import { initTabs } from "./tabs";
import { initResponsiveImage } from "../../responsive-image";
import { initTooltip } from "../../tooltip";
import { initRating } from "../../rating";
import { initProductNav } from "./productNav";
import { productInventory } from "../../storeinventory/product";
import { setMainImage, replaceImages, initImageThumbsSlider, eventListeners } from "./image";

var errorType = null,
    wrapperClass,
    wrapperErrorClass,
    wrapperErrorWrapper,
    elementErrorSubClass;

/**
 * @description update product content with new variant from href, load new content to #product-content panel
 * @param {String} href - url of the new product variant
 **/
var updateContent = function (href) {
    var $pdpForm = $(".pdpForm"),
        qty = $pdpForm.find('input[name="Quantity"]').first().val(),
        params = {
            Quantity: isNaN(qty) ? "1" : qty,
            format: "ajax",
            productlistid: $pdpForm.find('input[name="productlistid"]').first().val(),
            pageTemplateType: $pdpForm.find("#pageTemplateType").first().val(),
            uuid: $pdpForm.find('input[name="uuid"]').length > 0 ? $pdpForm.find('input[name="uuid"]').first().val() : ""
        };

    progressShow($("#pdpMain"));

    ajaxLoad({
        url: util.appendParamsToUrl(href, params),
        target: $("#product-content"),
        callback: function () {
            if (SitePreferences.STORE_PICKUP) {
                productInventory.init();
            }

            replaceImages();
            initTooltip();
            initRating();
            $("select").selectBoxIt();
            initResponsiveImage();
            initImageThumbsSlider();
            eventListeners();
            initTabs();
        }
    });
};

var updateDetail = function (href) {
    var $pdpForm = $(".pdpForm");
    var qty = $pdpForm.find('input[name="Quantity"]').first().val();
    var params = {
        Quantity: isNaN(qty) ? "1" : qty,
        format: "details",
        productlistid: $pdpForm.find('input[name="productlistid"]').first().val(),
        pageTemplateType: $pdpForm.find("#pageTemplateType").first().val(),
        uuid: $pdpForm.find('input[name="uuid"]').length > 0 ? $pdpForm.find('input[name="uuid"]').first().val() : ""
    };

    progressShow($("#pdpMain"));

    ajaxLoad({
        url: util.appendParamsToUrl(href, params),
        target: $("#pdp-tabs"),
        callback: function () {
            if (SitePreferences.STORE_PICKUP) {
                productInventory.init();
            }

            replaceImages();
            initTooltip();
            initRating();
            $("select").selectBoxIt();
            initResponsiveImage();
            initProductNav();
        }
    });
};

var changeEdition = function (href, container) {
    var isCartPage = $(".pt_cart").length ? true : false;
    var params = {
        format: "ajax",
        source: isCartPage ? "cart" : "quickview"
    };

    if (isCartPage) {
        $.extend(params, {
            uuid: $("#uuid").val()
        });
    }

    ajaxLoad({
        url: util.appendParamsToUrl(href, params),
        callback: function (data) {
            container.html(data);
            initResponsiveImage();
            initImageThumbsSlider();
            eventListeners();
            initAddToCart();
        }
    });
};

function initVariant() {
    var $pdpMain = $("#pdpMain"),
        $swatchesContainer = $(".pdp-apparel .swatches");

    // age restriction module
    document.dispatchEvent(new CustomEvent("AgeGate:InitEvents", {
        detail: $(".php-age-gate-trigger").length > 0 ? "PHP" : "PDP"
    }));

    // check at least one swatches container exist on apprarel product details page
    if ($swatchesContainer.length > 0) {
        var swatchesUrls = [],
            swatchUrl = "";

        // check each swatches container for single color or size and push its swatch url to an array
        $swatchesContainer.each(function () {
            var $swatches = $(this).find(".swatchanchor");

            if ($swatches.length == 1) {
                swatchUrl = $swatches.data("selected-url");
                swatchesUrls.push(swatchUrl);
                initTabs();
            }
        });

        // in case there are one single color and size swatch on pdp merge their url parameters into one url
        if (swatchesUrls.length > 1) {
            swatchUrl = util.mergeUrlsParams(swatchesUrls);
        }

        // update pdp with proper color/size swatch state
        if (swatchUrl !== "") {
            updateContent(swatchUrl);
        }
    }

    // hover on swatch - should update main image with swatch image
    $pdpMain.on("mouseenter mouseleave", ".productcolorstring .swatchanchor", function () {
        var largeImg = $(this).data("lgimg"),
            $imgZoom = $pdpMain.find(".main-image"),
            $mainImage = $pdpMain.find(".primary-image");

        if (!largeImg) { return; }

        // store the old data from main image for mouseleave handler
        $(this).data("lgimg", {
            hires: $imgZoom.attr("href"),
            url: $mainImage.attr("src"),
            alt: $mainImage.attr("alt"),
            title: $mainImage.attr("title")
        });
        // set the main image
        setMainImage(largeImg);
    });

    // click on swatch - should replace product content with new variant
    $pdpMain.on("click", ".product-detail .swatchanchor", function (e) {
        e.preventDefault();

        if ($(this).parents("li").hasClass("unselectable")) { return; }

        updateContent(this.href);
    });

    // change drop down variation attribute - should replace product content with new variant
    $(document).on("change", ".variation-select", function () {
        if ($(this).val().length === 0) { return; }

        updateContent($(this).val());

        if ($("#QuickViewDialog").length == 0) {
            updateDetail($(this).val());
        } else {
            // first unbind addToCart for quickview to prevent the addition of multiple items
            $(".product-detail").off("click", ".add-to-cart");
            initAddToCart();
        }
    });

    $(document).on("click", ".platforms-list a", function (event) {
        event.preventDefault();

        if ($("#QuickViewDialog").length == 0 && !$(this).hasClass("not-available")) {
            var platformName = $(this).text(),
                platformUrl = $(".variation-select option:containsExact(" + platformName + ")").val();

            if (platformUrl) {
                window.location = platformUrl;
            }
        } else if (!$(this).hasClass("not-available")) {
            var index = $(this).parent().index();

            $("#va-productPlatformString option").eq(index).prop("selected", true);
            $("#va-productPlatformString").trigger("change");
        }
    });

    $(document).on("click", ".editions-list a", function (event) {
        if ($(this).closest("li").hasClass("outOfStock") || $(this).closest("li").hasClass("not-available")) {
            event.preventDefault();

            return;
        } else if (pageContext.ns !== "product" && $("#QuickViewDialog").length > 0) {
            event.preventDefault();
            changeEdition(this.href, $(this).closest("#QuickViewDialog"));
        }
    });

    $("#product-content").on("keypress keydown", ".quantity input", function (ev) {
        if (ev.which == 13) {
            if (!$(".color .selectable.selected").length || !$(".size .selectable.selected").length) {
                ev.preventDefault();

                return false;
            }
        }
    });
}

/**
 * @function
 * @description Checks if product has platform
 * @param {Object} element - jQuery object to check
 * @returns {Boolean} Returns true or false if has platform
*/
const hasPlatform = elem => elem.length > 0 && elem.find(".selected").length == 0;

/**
 * @function
 * @description Checks if product has variation
 * @param {Object} element - jQuery object to check
 * @returns {Boolean} Returns true or false if has variation
*/
const hasVariation = elem => (elem.length > 0 && (elem.find(".selectable").length == 0 && elem.closest("#pdpMain").length > 0) || (elem.find(".selected").length == 0));

/**
 * @function
 * @description Checks if the product is master
 * @param {Object} element - jQuery object to check
 * @returns {Boolean} Returns true or false if is master
*/
const isMasterProduct = elem => elem.data("master-product-id") !== undefined;

/**
 * @function
 * @description Checks variation
 * @returns {Boolean} Returns true if variation doesn't have any errors
*/
function checkVariation($wrapper) {
    let platformList = $wrapper.find(".platforms-list"),
        colorList = $wrapper.find(".Color-list"),
        sizeList = $wrapper.find(".Size-list"),
        $addToCartButton = $wrapper.find(".pdp-cta .add-to-cart .add-to-cart-compare");

    if (platformList.length) {
        if (isMasterProduct($addToCartButton) || hasPlatform(platformList)) {
            errorType = "PLATFORM_UNSELECTED";
            wrapperClass = ".platform-wrapper";
            wrapperErrorClass = ".platform-error";
            wrapperErrorWrapper = ".platform-container";
            elementErrorSubClass = "";

            return false;
        }
    }

    if (colorList.length) {
        if (hasVariation(colorList)) {
            errorType = "COLOR_UNSELECTED";
            wrapperClass = ".attribute.color";
            wrapperErrorClass = ".color-error";
            wrapperErrorWrapper = ".attribute.color";
            elementErrorSubClass = ".color";

            return false;
        }
    }

    if (sizeList.length) {
        if (hasVariation(sizeList)) {
            errorType = "SIZE_UNSELECTED";
            wrapperClass = ".attribute.size";
            wrapperErrorClass = ".size-error";
            wrapperErrorWrapper = ".attribute.size";
            elementErrorSubClass = ".size";

            return false;
        }
    }

    return true;
}

/**
 * @function
 * @description Checks whether the product variation is selected. If not, display the corresponding error message on PDP.
 * @param {jQuery} $button
 * @param {jQuery} $$wrapper
 * @param {MyEvent} event
 * @returns {Boolean} Results if product variation attributes are selected
 */
function isProductVariationSelected($button, $wrapper, event) {
    if (!checkVariation($wrapper) && !$button.hasClass("global-add-to-cart")) {
        event.stopPropagation();

        var $scrollTo = "";

        if ($wrapper.find(wrapperClass).length) {
            $wrapper.find(wrapperErrorWrapper).addClass(wrapperErrorClass);

            if ($wrapper.hasClass("wishlist-variations-popup")) {
                $wrapper.find(".wishlist_error_message" + elementErrorSubClass)
                    .html(Resources[errorType])
                    .parents(".wishlist-error")
                    .show();
                $scrollTo = $wrapper.find(".wishlist_error_message" + elementErrorSubClass);
            } else {
                $wrapper.find(".error_message" + elementErrorSubClass).show().html(Resources[errorType]);
                $scrollTo = $wrapper.find(".error_message" + elementErrorSubClass);
            }
        }

        if ($scrollTo != "" && !$button.parents("#wishlist-variations-popup").length) {
            $("html, body").animate({
                scrollTop: $scrollTo.offset().top - ($(window).height() / 2)
            });
        }

        return false;
    }

    return true;
}

export { initVariant, isProductVariationSelected };
