var $cache = {};

/**
 * @function
 * @description initialize cache object
 */
const initCache = () => {
    $cache = {
        document: $(document)
    };
};

/**
 * Initialize events
 * @private
 */
function initializeEvents() {
    initCache();
    $cache.document.off(".tagcommander");

    $cache.document.on("click.tagcommander", ".pdp-sticky-cta", function () {
        var $this = $("#add-to-cart");

        $this.data("from", "tab");

        if ($(".dlcmatrix").length) {
            var dlcSelector = $(".dlc-variation[data-selected='true']");

            dlcSelector.data("event", "addToCartEventPdp");
            submitEvent(dlcSelector);
        } else {
            $this.data("event", "addToCartEventPdp");
            submitEvent($this);
        }
    });

    $cache.document.on("click.tagcommander", "#add-to-cart", function () {
        if ($(".dlcmatrix").length) {
            var dlcSelector = $(".dlc-variation[data-selected='true']");

            dlcSelector.data("event", "addToCartEventPdp");
            submitEvent(dlcSelector);
        } else {
            $(this).data("event", "addToCartEventPdp");
            submitEvent($(this));
        }
    });

    $cache.document.on("tcTabScroll.tagcommander", "#pdpMain .sticky-navigation a.sticky-navigation-anchor", function () {
        var $this = $(this);

        if (!$this.data("tagging-triggered")) {
            $this.data("event", "scrollEvent");
            submitEvent($this);
            $this.data("tagging-triggered", true);
        }
    });

    // Register click event on specific tab on PDP
    $cache.document.on("click.tagcommander", "#pdpMain .sticky-navigation a.sticky-navigation-anchor", function () {
        var $this = $(this);

        if (!$this.data("tagging-triggered")) {
            var dataInfo = $this.data("tc100");
            var dataInfoArray = dataInfo != null || dataInfo != undefined ? dataInfo.split("-") : "";

            if (dataInfoArray !== "" && dataInfoArray.length > 0) {
                tc_events_100(this, "clickevent", {
                    ACTION: dataInfoArray[0].trim() + " - tab displayed - click"
                });
                $this.data("tagging-triggered", true);
            }
        }
    });

    // Register click event on the redirect versions Buy now CT for the main and the sticky one
    $cache.document.on("click.tagcommander", ".js-redirect-version, .js-sticky-redirect-version", function () {
        var tc100Value = $(this).data("tc_100");

        tc_events_100(this, "clickevent", {
            ACTION: tc100Value
        });
    });
}

var events = {
    addToCartEventPdp: function (_this, eventValue) {
        if ($(_this).hasClass("giveaway")) {
            tc_events_100(_this, "clickevent", {
                ACTION: "product info - action - get the giveaway"
            });

            return;
        }

        var actionValue = "";
        var productActionValue = "";
        var productName = eventValue && eventValue.productName ? eventValue.productName.split("-")[0].trim().toLowerCase() : "";
        var productType = eventValue && eventValue.productType ? eventValue.productType : "";
        var productSize = eventValue && eventValue.productSize ? eventValue.productSize : "no size selected";
        var productColor = eventValue && eventValue.productColor ? eventValue.productColor : "no color selected";
        var productPlatform = eventValue && eventValue.platform ? eventValue.platform.toLowerCase() : "no platform selected";
        var productEdition = eventValue && eventValue.edition ? eventValue.edition.toLowerCase() : "no edition selected";
        var action = $(_this).hasClass("preorder") ? "preorder" : "add to cart";

        if (productType != "") {
            if (productType == "games" || productType == "dlc") {
                productActionValue = productPlatform + " - " + productName + " - " + productEdition;
            } else if (productType == "apparel") {
                if (productSize == "no size selected" && productColor == "no color selected") {
                    productActionValue = "no size no color selected - " + productName;
                } else if (productSize != "no size selected") {
                    productActionValue = productSize + " - " + productName + " - " + productColor;
                } else if (productColor != "no color selected") {
                    productActionValue = productSize + " - " + productName + " - " + productColor;
                }
            } else if (productType == "collectible") {
                productActionValue = productName;
            }
        }

        // If add to cart button is clicked from tab section on pdp.
        if ($(_this).data("from") === "tab") {
            $(_this).data("from", "");
            actionValue = "product tabs - " + action + " - " + productActionValue;
            $(_this).data("from", "");

            tc_events_100(_this, "clickevent", {
                ACTION: actionValue
            });
        } else {
            actionValue = "product info - " + action + " - " + productActionValue;
            tc_events_100(_this, "clickevent", {
                ACTION: actionValue
            });
        }
    },

    // Scroll event tracking
    scrollEvent: function (_this, eventValue) {
        var actionValue = "";
        var eventValueTextArray = eventValue != null || eventValue != undefined ? eventValue.split("-") : "";
        var firstText = eventValueTextArray != "" && typeof eventValueTextArray === "object" && eventValueTextArray.length > 0 ? eventValueTextArray[0].trim() : "";

        if (firstText != "") {
            actionValue = firstText + " - tab displayed - scroll";
        } else {
            actionValue = $.trim($(_this).text()).toLowerCase() + " - tab displayed - scroll";
        }

        $cache.document.trigger("updateTcEventBuffer", {
            this: _this,
            eventType: "clickevent",
            action: {
                ACTION: actionValue
            }
        });
    }
};

/**
 * Triggers a tagcommander "clickevent"
 * @param {Object} selector The html selector that triggered the event
 * @param {Object} data The type of action on the slider
 */
function submitEvent(selector, data) {
    var eventName = selector.data("event"),
        eventValue = selector.data("tc100"),
        gridUrl = selector.data("gridUrl"),
        mdmbid = selector.data("mdmbid"),
        mdmiid = selector.data("mdmiid"),
        searchkeyword = selector.attr("data-searchkeyword"),
        clickType = data ? data.clickType : "clicked";

    if (eventName != undefined && eventName != "") {
        events[eventName](selector[0], eventValue, gridUrl, clickType, mdmbid, mdmiid, searchkeyword);
    }
}

var productdetail = {
    init: function () {
        initializeEvents();
    }
};

module.exports = productdetail;
