import { util } from "./util";
import { ajaxGetJson } from "./ajax";

/**
 * @function
 * @description Load details to a given gift certificate
 * @param {String} id The ID of the gift certificate
 * @param {Function} callback A function to called
 */
function checkBalance(id, callback) {
    // load gift certificate details
    var url = util.appendParamToURL(Urls.giftCardCheckBalance, "giftCertificateID", id);

    ajaxGetJson({
        url: url,
        callback: callback
    });
}

export { checkBalance };
