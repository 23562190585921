import "selectboxit/src/javascripts/jquery.selectBoxIt";
import "jquery-ui/ui/widgets/slider";
import { progressShow, progressHide } from "../progress";
import { util } from "../util";
import { initAddToCartTile } from "./product/addToCartTile";
import { initProductTile } from "../product-tile";
import { initResponsiveImage } from "../responsive-image";
import { refinements } from "../refinements";
import { initCompareWidget, addProduct, removeProduct } from "../compare-widget";
import { initProductTooltip } from "../tooltip";
import { clickEvent, impressionEvent, getProductDataById } from "TagcommanderJS/adobe_analytics_events";
import { calculateDataOrderAttr } from "../tracking";

var activeRefinementIndex,
    stopInfiniteScroll = false,
    loadMorePushed = false,
    isUPC = $(".app_uplay").length,
    queryStringURL = "";

let $cache = {};

const data = {
    // view more button which expands a list of refinement items
    viewMoreToggleBtn: "[data-view-more-toggle-btn]",
    // the title of the refinement group, controls the group's state(expanded/collapse)
    refinementSectionToggleBtn: "[data-refinement-toggle-btn]",
    loadMoreBtn: ".load-more-button",
    loadedResults: ".loaded-results",
    spinner: ".loading-spinner"
};

/**
 * @function
 * @description initialize cache object
 */
const initCache = () => {
    $cache = {
        categoryTitle: $(".category-title"),
        categoryResults: $(".categorypage-results"),
        document: $(document)
    };

    $cache.targetScrollElement = $cache.categoryTitle.length ? $cache.categoryTitle : $cache.categoryResults;
};

/**
 * @function
 * @description - updates text displayed below "Load More" button ('Showing X of Y items')
 */
const initShownResultsMention = () => {
    const loadedResults = $("#main .loaded-results");

    if (!loadedResults.length) return;

    let shownResultsNumber = $("div.search-result-content li.grid-tile").length;
    let totalResultsNumber = $(".search-result-content").data("product-count");

    loadedResults.find("[data-results-total]").text(totalResultsNumber);
    loadedResults.find("[data-results-shown]").text(shownResultsNumber);
    loadedResults.removeClass("hide");
};

function infiniteScroll() {
    // getting the hidden div, which is the placeholder for the next page
    var loadingPlaceHolder = $('.infinite-scroll-placeholder[data-loading-state="unloaded"]'),
        // get url hidden in DOM
        gridUrl = loadingPlaceHolder.attr("data-grid-url"),
        isInViewport = SitePreferences.LISTING_INFINITE_SCROLL && loadingPlaceHolder.length ? util.elementInViewport(loadingPlaceHolder.get(0), 250) : true;

    if (loadingPlaceHolder.length === 1 && isInViewport) {
        // switch state to 'loading'
        // - switches state, so the above selector is only matching once
        // - shows loading indicator
        loadingPlaceHolder.attr("data-loading-state", "loading");

        if (isUPC) {
            $(data.loadMoreBtn).addClass("hide");
            $(data.spinner).removeClass("hide");
        } else {
            loadingPlaceHolder.addClass("infinite-scroll-loading");
        }

        /**
         * named wrapper function, which can either be called, if cache is hit, or ajax repsonse is received
         */
        var fillEndlessScrollChunk = function (html) {
            loadingPlaceHolder.removeClass("infinite-scroll-loading");
            loadingPlaceHolder.attr("data-loading-state", "loaded");

            const $loadedProductList = $(html).find(".loaded_products_list");

            $loadedProductList.find(".product-tile").first().addClass("first-tile-loaded");

            if (isUPC) {
                $("ul#search-result-items:not([data-algolia-container] ul#search-result-items)").append($loadedProductList.html());
                $("div.search-result-content").append($(".load-more-button-wrapper").html($(html).find(".load-more-button-wrapper").html()));
                $(data.loadMoreBtn).removeClass("hide");
                $(data.spinner).addClass("hide");
                $("div.search-result-content").append($("#main .loaded-results"));
            } else {
                $(".load-more-wrapper").hide();
                $("div.search-result-content").append(html);
            }

            loadingPlaceHolder.prev(".search-result-items").removeClass("clearfix");

            $cache.document.trigger("impressionEventInfiniteLoading", {
                loadedProductsList: $(html).find(".loaded_products_list")
            });
        };

        // old condition for caching was `'sessionStorage' in window && sessionStorage["scroll-cache_" + gridUrl]`
        // it was removed to temporarily address RAP-2649
        var startPosition = $("div.search-result-content li.grid-tile").length;

        gridUrl = util.removeParamFromURL(gridUrl, "start");
        gridUrl = util.appendParamToURL(gridUrl, "start", startPosition);

        if (SitePreferences.LISTING_INFINITE_SCROLL) {
            if ((startPosition + 3) % 30 == 0) {
                gridUrl = util.removeParamFromURL(gridUrl, "sz");
                gridUrl = util.appendParamToURL(gridUrl, "sz", "3");
            }

            if (startPosition % 30 == 0) {
                gridUrl = util.removeParamFromURL(gridUrl, "sz");
                gridUrl = util.appendParamToURL(gridUrl, "sz", "9");

                if (!loadMorePushed) {
                    stopInfiniteScroll = true;
                }
            }
        }

        // else do query via ajax
        if (!stopInfiniteScroll || loadMorePushed) {
            $.ajax({
                type: "GET",
                dataType: "html",
                url: gridUrl,
                success: function (response) {
                    // put response into cache
                    try {
                        sessionStorage["scroll-cache_" + gridUrl] = response;
                    } catch (e) {
                        // nothing to catch in case of out of memory of session storage
                        // it will fall back to load via ajax
                    }

                    // update UI
                    fillEndlessScrollChunk(response);
                    initProductTile();
                    initShownResultsMention();
                    initProductTooltip();

                    if ($(".first-tile-loaded").length) {
                        $(".first-tile-loaded").trigger("focus").removeClass(".first-tile-loaded");
                    }
                }
            });
            loadMorePushed = false;
        } else {
            loadingPlaceHolder.hide();
            loadingPlaceHolder.next(".load-more-wrapper").css("display", "block");

            if (isUPC) {
                $(data.loadMoreBtn).removeClass("hide");
            }
        }
    }
}

/**
 * @private
 * @function
 * @description replaces breadcrumbs, lefthand nav and product listing with ajax and puts a loading indicator over the product listing
 */
function updateProductListing() {
    var hash = location.href.split("#")[1];
    var impressionAlreadyTriggered = false;
    const $containerUwrapper = $(".container.uwrapper");
    const $loadSection = $containerUwrapper.length ? $containerUwrapper : $("#main");

    if (hash === "results-content" || hash === "results-products") { return; }

    var refineUrl;

    if (hash.length > 0) {
        refineUrl = window.location.pathname + "?" + hash;
    } else {
        return;
    }

    progressShow($(".search-result-content"));

    $loadSection.load(util.appendParamToURL(refineUrl, "format", "ajax"), function () {
        initCompareWidget();
        initProductTile();
        initShownResultsMention();
        initResponsiveImage();
        progressHide();
        initProductTooltip();
        refinements.init(activeRefinementIndex);
        $("select").selectBoxIt();

        if (SitePreferences.ENABLE_WALLET && window.location.href.indexOf("isWalletSearch") > -1) {
            selectWalletRefinement();
        }

        // .load is executing two times($loadSection has two elements) in some scenarios(selecting 2 refinements, selecting price slider + refinements)
        if (!impressionAlreadyTriggered) {
            $cache.document.trigger("impressionEventCLP");
            impressionAlreadyTriggered = true;
        }
    });
}

/**
 * @private
 * @function
 * @description opens all refinement tabs, which are not empty
 */
function openAllRefinementTabs() {
    $(data.refinementSectionToggleBtn + "[aria-expanded=false]").each((index, element) => {
        const $this = $(element);

        const expandableSectionId = $this.attr("aria-controls");
        const $expandableSection = $("#" + expandableSectionId);

        if ($expandableSection.length) {
            $this.trigger("click");
        }
    });
}

/**
 * @function makeTooltipPosition put price tooltips for price slider in the correct position
 */
function makeTooltipPosition() {
    let $sliderRange = $("#slider-range");

    if ($sliderRange) {
        let sliderHandles = $sliderRange.find(".ui-slider-handle");

        if (sliderHandles.length > 1) {
            let leftHandle = $(sliderHandles[0]);
            let rightHandle = $(sliderHandles[1]);

            if (leftHandle && rightHandle) {
                let leftHandlePosition = leftHandle.css("left");
                let rightHandlePosition = rightHandle.css("left");

                if (leftHandlePosition && rightHandlePosition) {
                    $(".min-amount").css("left", leftHandlePosition);
                    // position of the max price tag is the same as max price handler minus 20px, which is used to not have overflow
                    $(".max-amount").css("left", parseInt(rightHandlePosition, 10) - 20 + "px");
                }
            }
        }
    }
}

/**
 * @function initializePriceSlider initializes price range slider and set proper query params if slider values are changed
 */
function initializePriceSlider() {
    let slider = $(".price-range-slider");
    let isHomepage = slider.closest(".home-category-product-list").length;
    const $minAmount = $(".min-amount");
    const $maxAmount = $(".max-amount");
    const $sliderRange = $("#slider-range");

    if (slider.length && $minAmount.length && $maxAmount.length && $sliderRange.length) {
        var minPrice = slider.data("minvalue");
        var maxPrice = slider.data("maxvalue");
        var cgid = slider.data("cgid");
        var currencySymbol = GeoRestrictedResources.STOREFRONT_CURRENCY_SYMBOL;
        let pmin;
        let pmax;

        // sets price slider values
        if (isHomepage) {
            pmin = slider.data("currentmin");
            pmax = slider.data("currentmax");
        } else {
            let currentUrl = window.location.href.replace("#", "?");
            let url = new URL(currentUrl);

            if (url.searchParams.has("pmin") || url.searchParams.has("pmax")) {
                pmin = parseInt(formatPriceSliderValue(url.searchParams.get("pmin")), 10);
                pmax = parseInt(formatPriceSliderValue(url.searchParams.get("pmax")), 10);
            }
        }

        $sliderRange.slider({
            range: true,
            min: minPrice,
            max: maxPrice,
            values: [
                pmin ? pmin : minPrice,
                pmax ? pmax : maxPrice
            ],
            create: () => {
                const uiSlider = $sliderRange.data("ui-slider");
                const titleId = slider.data("titleid");

                uiSlider.handles.each((index, item) => {
                    $(item).attr({
                        role: "slider",
                        "aria-valuemin": uiSlider.options.min,
                        "aria-valuemax": uiSlider.options.max,
                        "aria-valuenow": uiSlider.options.values[index],
                        "aria-valuetext": uiSlider.options.values[index] + currencySymbol,
                        "aria-orientation": uiSlider.options.orientation,
                        "aria-labelledby": titleId
                    });
                });
            },
            slide: (event, ui) => {
                const handleValue = ui.values[ui.handleIndex];

                $(ui.handle).attr({
                    "aria-valuenow": handleValue,
                    "aria-valuetext": handleValue + currencySymbol
                });

                $minAmount.html(ui.values[0] + currencySymbol);
                $maxAmount.html(ui.values[1] + currencySymbol);

                makeTooltipPosition();
            },
            stop: (event, ui) => {
                if (isHomepage) {
                    let homepageUrl = slider.attr("href");

                    if (homepageUrl != "") {
                        let priceUrl = new URL(homepageUrl);

                        priceUrl.searchParams.set("pmin", ui.values[0]);
                        priceUrl.searchParams.set("pmax", ui.values[1]);

                        $.ajax({
                            type: "GET",
                            dataType: "html",
                            url: priceUrl,
                            success: function (response) {
                                $(this).closest(".home-category-refinements").removeClass("avoid_click_events");
                                $(".home-category-product-list .load-more-products-button").removeClass("avoid_click_events");

                                if (isNotEmpty(response)) {
                                    $(".home-category-product-list").html(response);
                                    initProductTile();
                                    initShownResultsMention();

                                    $cache.document.trigger("impressionEventCLP");
                                }

                                if ($cache.targetScrollElement.length) {
                                    util.scrollBrowser($cache.targetScrollElement.offset().top);
                                }

                                $(".sort-by-select select").selectBoxIt();
                            }
                        });
                    }
                } else {
                    let queryString;
                    let resultQuery;
                    let baseUrl = window.location.href;

                    if (SitePreferences.ENABLE_WALLET && baseUrl.indexOf("isWalletSearch") > -1) {
                        removeWalletRefinement(null);
                    }

                    if (baseUrl.indexOf("?") > -1 && baseUrl.indexOf("#") > -1) {
                        queryString = new URL(baseUrl.split("?")[0] + "?" + baseUrl.split("#")[1]);
                    } else {
                        queryString = new URL(baseUrl.replace("#", "?"));
                    }

                    if (!new URL(baseUrl).searchParams.has("cgid")) {
                        queryString.searchParams.set("cgid", cgid);
                    }

                    queryString.searchParams.set("pmin", ui.values[0]);
                    queryString.searchParams.set("pmax", ui.values[1]);
                    resultQuery = decodeURIComponent(queryString.search.slice(1));

                    window.location.hash = resultQuery;
                }

                tc_events_100(this, "clickevent", {
                    ACTION: "listing page - filter applied - gauge price"
                });
            }
        });

        makeTooltipPosition();

        $minAmount.html($sliderRange.slider("values", 0) + currencySymbol);
        $maxAmount.html($sliderRange.slider("values", 1) + currencySymbol);
    }
}

/**
 * Check if price slider values are separated by dot or comma and format it to be used
 * @param {String} price - string to be formatted
 * @returns {String} price - the updated string
 */
function formatPriceSliderValue(price) {
    if (price && typeof price === "string") {
        const dotSeparatorIndex = price.indexOf(".");
        const commaSeparatorIndex = price.indexOf(",");

        if (dotSeparatorIndex > -1 && commaSeparatorIndex > -1) {
            if (commaSeparatorIndex > dotSeparatorIndex) {
                price = price.replace(/\./g, "");
            } else {
                price = price.replace(/,/g, "");
            }
        }
    }

    return price;
}

/**
 * Checks if the response is empty or not
 */
function isNotEmpty(response) {
    return response != null && response != undefined && response != "" && response.length > 0;
}

/**
 * @private
 * @function
 * @description Initializes events for the following elements:<br/>
 * <p>refinement blocks</p>
 * <p>updating grid: refinements, pagination, breadcrumb</p>
 * <p>item click</p>
 * <p>sorting changes</p>
 */
function initializeEvents() {
    var $main = $("#main");

    // compare checked
    $main.on("click", 'input[type="checkbox"].compare-check', function () {
        var cb = $(this);
        var tile = cb.closest(".product-tile");

        var func = this.checked ? addProduct : removeProduct;
        var itemImg = tile.find(".product-image a img").first();

        func({
            itemid: tile.data("itemid"),
            uuid: tile[0].id,
            img: itemImg,
            cb: cb
        });
    });

    // close the view more panel when the status of the parent refinement section is changed
    $main.on("click", data.refinementSectionToggleBtn, function () {
        const $this = $(this);

        const expandableSectionId = $this.attr("aria-controls");
        const $expandableSection = $("#" + expandableSectionId);

        if ($expandableSection.length && $this.attr("aria-expanded") === "true") {
            const $childDisclosureComponentBtn = $expandableSection.find(data.viewMoreToggleBtn);

            if ($childDisclosureComponentBtn.attr("aria-expanded") === "true") {
                $childDisclosureComponentBtn.trigger("click");
            }
        }
    });

    // Fix for when a filter is selected for the included category from slot and the content is updated,
    // to add back the classes for selection and expand where a filter is selected
    $(window).on("ajaxComplete load", function () {
        initCache();
        openAllRefinementTabs();
        initializePriceSlider();
    });

    // Wallet Refinement Click Event
    if (SitePreferences.ENABLE_WALLET) {
        let URLBuilder = new URL(window.location.href.replace("#", "?"));
        let pmin = URLBuilder.searchParams.get("pmin");
        let pmax = URLBuilder.searchParams.get("pmax");
        let walletBrick = $("#wallet-refinement");

        if (pmin && pmax && walletBrick.length && parseInt(pmin, 10) == walletBrick.data("refvaluemin") && parseInt(pmax, 10) == walletBrick.data("refvaluemax")) {
            sessionStorage.setItem("searchedByWallet", true);
        }

        $(document).on("click", "#wallet-refinement", function () {
            let selectedInput = $(this).find("input");
            let minPrice = $(this).data("refvaluemin");
            let maxPrice = $(this).data("refvaluemax");
            let cgid = $(this).data("cgid");
            let queryString = new URL(window.location.href.replace("#", "?"));
            let resultQuery;

            if (selectedInput) {
                if (selectedInput.prop("checked") && sessionStorage.getItem("searchedByWallet") == "true") {
                    sessionStorage.setItem("searchedByWallet", false);

                    var selectedFilter = $(".breadcrumb").find('a[data-refinement-name="price-slider"]');
                    var selectedPriceRange;

                    if (selectedFilter && selectedFilter.length) {
                        if (selectedFilter.length == 2) {
                            var currentSelectedPriceFilter = $($(".breadcrumb").find('a[data-refinement-name="price-slider"]'))[0];

                            if (currentSelectedPriceFilter) {
                                selectedPriceRange = $(currentSelectedPriceFilter);
                            }
                        } else if (selectedFilter.length == 1) {
                            selectedPriceRange = $(".breadcrumb").find('a[data-refinement-name="price-slider"]');
                        }
                    } else {
                        selectedPriceRange = $(".breadcrumb").find('a[data-refinement-name="Price"]');
                    }

                    if (selectedPriceRange) {
                        selectedInput.prop("checked", false);
                        selectedPriceRange.trigger("click");

                        return;
                    }
                } else {
                    // Selects wallet refinement checkbox
                    sessionStorage.setItem("searchedByWallet", true);
                    selectedInput.prop("checked", true);

                    if (!queryString.searchParams.has("cgid")) {
                        queryString.searchParams.set("cgid", cgid);
                    }

                    queryString.searchParams.set("pmin", minPrice);
                    queryString.searchParams.set("pmax", maxPrice);
                    resultQuery = decodeURIComponent(queryString.search.slice(1));

                    if (window.location.href.indexOf("isWalletSearch") === -1) {
                        resultQuery = decodeURIComponent(queryString.search.slice(1) + "&isWalletSearch=true");
                    }

                    let searchPath = queryString.href.replace(queryString.search, "").replace(queryString.hash, "");

                    window.location.href = searchPath + "?" + resultQuery;
                }
            }
        });
    }

    // handle events for updating grid
    $main.on("click", ".refinements a, .pagination a, .breadcrumb-refinement-value a, .refinements [data-href]", function () {
        const $categoryWrapper = $(".js_include_category_wrapper");

        if ($categoryWrapper.length > 0 && $categoryWrapper.attr("data-is-category-include") == "true") {
            return;
        }

        const $this = $(this);
        const href = $this.attr("href") || $this.attr("data-href");

        if ($this.parent().hasClass("unselectable")) { return; }

        var folderparent = $this.parents(".folder-refinement");
        var categoryRefinements = $this.parents(".category-refinement");

        if ($cache.targetScrollElement.length) {
            util.scrollBrowser($cache.targetScrollElement.offset().top);
        }

        // if the anchor tag is uunderneath a div with the class names & , prevent the double encoding of the url
        // else handle the encoding for the url
        if (folderparent.length > 0 || categoryRefinements.length > 0) {
            return true;
        } else {
            activeRefinementIndex = $this.parents(".refinement").index();

            var oldQuery = util.getQueryString(window.location.href);
            var newQuery = util.getQueryString(href);
            var currentURL = window.location.href.split("?")[0];

            currentURL = currentURL.substr(0, currentURL.indexOf("#"));

            var newURL = href.split("?")[0];
            var triggerQuery = false;
            var isQueryEqual = false;

            if (currentURL == newURL) {
                triggerQuery = true;
            } else if (newQuery == oldQuery) {
                isQueryEqual = true;
            }

            let refinementName = $this.attr("data-refinement-name");

            if (refinementName && refinementName.toLowerCase() === "price-slider") {
                if (SitePreferences.ENABLE_WALLET && window.location.href.indexOf("isWalletSearch") > -1) {
                    removeWalletRefinement($this);
                }
            }

            if (SitePreferences.ENABLE_WALLET && window.location.href.indexOf("isWalletSearch") > -1) {
                var isWalletSearch = util.getURLParamValue("isWalletSearch");

                if (isWalletSearch != undefined && newQuery != undefined) {
                    newQuery += "&isWalletSearch=true";

                    if ($this.hasClass("clear-all-filters")) {
                        removeWalletRefinement($this);
                    }
                }
            }

            if (newQuery != undefined && newQuery != "undefined" && newQuery.length > 1 && queryStringURL != newQuery && !isQueryEqual || triggerQuery) {
                if (newQuery) {
                    queryStringURL = newQuery;
                    window.location.hash = newQuery;
                } else {
                    window.location.href = href;
                }
            } else {
                if (SitePreferences.ENABLE_WALLET && window.location.href.indexOf("isWalletSearch") > -1 && $this.hasClass("clear-all-filters")) {
                    var walletURL = util.appendParamToURL(href, "isWalletSearch", "true");

                    sessionStorage.setItem("searchedByWallet", "false");
                    window.location.href = walletURL;
                } else {
                    let url = href;

                    if (window.location.href.indexOf("isWalletSearch") > -1) {
                        url += "&isWalletSearch=true";
                    }

                    window.location.href = url;
                }
            }

            return false;
        }
    });

    $main.on("change", ".sort-by-select select", function (e) {
        let $selectContainer = $(this).siblings(".selectboxit-container");
        let isHomepage = $(e.target).closest(".home-category-product-list").length;

        if ($selectContainer) {
            let $selectedOption = $selectContainer.find(".selectboxit-enabled .selectboxit-text");

            if ($selectedOption) {
                let url = $selectedOption.data("val");
                let query = url.split("?")[1];
                let currentUrl = new URL(window.location.href);

                if (currentUrl.searchParams.has("lang")) {
                    query += "&lang=" + currentUrl.searchParams.get("lang");
                }

                if (isHomepage) {
                    let homeUrl = $(".home-page-listing .filters-header").attr("href");

                    if (homeUrl) {
                        let searchUrl = homeUrl.split("&")[0];
                        let resultUrl = searchUrl + "&" + query;

                        $.ajax({
                            type: "GET",
                            dataType: "html",
                            url: resultUrl,
                            success: function (response) {
                                if (isNotEmpty(response)) {
                                    $(".home-category-product-list").html(response);
                                }

                                $("#grid-sort-header").selectBoxIt();
                            }
                        });
                    }
                } else {
                    window.location.hash = decodeURIComponent(query);
                }

                $cache.document.trigger("impressionEventCLP");
            }
        }
    });

    // handle sorting change
    $main.on("change", ".sort-by select", function () {
        var refineUrl = $(this).find("option:selected").val();
        var queryString = util.getQueryString(refineUrl);

        window.location.hash = queryString;

        return false;
    })
        .on("change", ".items-per-page select", function () {
            var refineUrl = $(this).find("option:selected").val();
            var queryString = util.getQueryString(refineUrl);

            if (refineUrl === "INFINITE_SCROLL") {
                $("html").addClass("infinite-scroll").removeClass("disable-infinite-scroll");
            } else {
                $("html").addClass("disable-infinite-scroll").removeClass("infinite-scroll");
                window.location.hash = queryString;
            }

            return false;
        });

    $(document).on("click", ".load-more-button", function (e) {
        e.preventDefault();
        stopInfiniteScroll = false;
        loadMorePushed = true;

        if (isUPC) {
            $(this).addClass("onLoad");
            $(".loaded-results").addClass("hide");
        } else {
            $(this).hide();
        }

        $(this).parent().prev(".infinite-scroll-placeholder").attr("data-loading-state", "unloaded").show();
        infiniteScroll();
    });

    openAllRefinementTabs();

    // handle hash change
    $(document).ready(function () {
        var ubiPassCgid = $(".js-category-plp").attr("data-ubi-pass-cgid");
        var universalCat = $(".js-category-plp").attr("data-universal-cgid");

        if (!(ubiPassCgid || universalCat)) {
            window.onhashchange = updateProductListing;
        }

        if (SitePreferences.ENABLE_WALLET && window.location.href.indexOf("isWalletSearch") > -1) {
            initWalletRefinement();
        }
    });
}

/**
 * @function initWalletRefinement Selects wallet refinement checkbox when the page is loaded, once customer reaches CLP with wallet refinement flow.
 */
function initWalletRefinement() {
    var isWalletSearch = util.getURLParamValue("isWalletSearch");

    if (isWalletSearch != undefined) {
        var searchedByWallet = sessionStorage.getItem("searchedByWallet");

        if (searchedByWallet == null || searchedByWallet == "true") {
            sessionStorage.setItem("searchedByWallet", true);
            $("#wallet-refinement").find("input[type='checkbox']").attr("checked", "checked");
        }
    }
}

/**
 * @function selectWalletRefinement Selects wallet refinement
 */
function selectWalletRefinement() {
    var searchedByWallet = sessionStorage.getItem("searchedByWallet");

    if (searchedByWallet == "true") {
        sessionStorage.setItem("searchedByWallet", true);
        $("#wallet-refinement").find("input[type='checkbox']").attr("checked", "checked");
    }
}

/**
 * @function removeWalletRefinement Removes the selection for wallet refinement
 * @param {Object} $this - selected price range filter element
 */
function removeWalletRefinement($this) {
    var searchedByWallet = sessionStorage.getItem("searchedByWallet");
    var refinementName = $this && $this.attr("data-refinement-name");

    if ($this && $this.hasClass("clear-all-filters")) {
        sessionStorage.setItem("searchedByWallet", false);
        window.history.pushState({}, document.title, util.removeParamFromURL(window.location.href, "pmin"));
        window.history.pushState({}, document.title, util.removeParamFromURL(window.location.href, "pmax"));
    } else if ($this != null && refinementName && refinementName.toLowerCase() != "price-slider") {
        return;
    }

    if (searchedByWallet) {
        sessionStorage.setItem("searchedByWallet", false);
        window.history.pushState({}, document.title, util.removeParamFromURL(window.location.href, "pmin"));
        window.history.pushState({}, document.title, util.removeParamFromURL(window.location.href, "pmax"));
    }
}

/**
 * Initialize tracking events for the search results page
 */
function initTrackingEvents() {
    var $main = $("#main");

    // accordion events
    $main.on("click", ".refinement .toggle", function (e) {
        const $this = $(e.currentTarget);
        const $filterCategory = $this.data("refdefname") || "";
        const $expanded = $this.attr("aria-expanded") === "true";
        const clickEventParams = {
            location: "filters",
            category: "filter",
            action: $expanded ? $filterCategory + " : hide filters" : $filterCategory + " : see filters"
        };

        clickEvent(e, clickEventParams);
    });

    // selecting / unselecting a filter
    $main.on("click", "li.refinement-link", function (e) {
        const $this = $(e.currentTarget);
        const $filterCategory = $this.data("refinement-name");
        const $filterName = $this.data("refinement-value");
        const $activeFilter = $this.hasClass("selected");
        const clickEventParams = {
            location: "filters",
            category: "filter",
            action: $activeFilter ? $filterCategory + " | " + $filterName + " : filter removed" : $filterCategory + " | " + $filterName + " : filter used"
        };

        clickEvent(e, clickEventParams);
    });

    // clear all filters
    $main.on("click", "#clearAllFilters, #clearHomeFilters", function (e) {
        const clickEventParams = {
            location: "filters",
            category: "filter",
            action: "all filters removed"
        };

        clickEvent(e, clickEventParams);
    });

    // view more events from a ref
    $main.on("click", "[data-view-more-toggle-btn]", function (e) {
        const $this = $(e.currentTarget);
        const $activeAccordion = $this.attr("aria-expanded") === "false";

        if ($activeAccordion) {
            const $filterCategory = $this.data("refdefname");
            const clickEventParams = {
                location: "filters",
                category: "filter",
                action: $filterCategory + " : see more"
            };

            clickEvent(e, clickEventParams);
        }
    });

    // sorting events
    $main.on("change", "#grid-sort-header", function (e) {
        const $selectedOption = $(this).find("option:selected");
        const $srule = $selectedOption.data("srule");
        const $sortingName = $srule ? $srule.replace(/-/g, " ") : $srule;
        const clickEventParams = {
            location: "sorting",
            category: "sorting",
            action: $sortingName + " : sorting used"
        };

        clickEvent(e, clickEventParams);
    });

    sendImpressionEvent();

    $main.on("click", ".search-result-content .add-to-wishlist", e => {
        const trackingData = getCLPTrackingData(e);
        const wishListAction = $(e.currentTarget).hasClass("product-added") ? "remove from" : "add to";
        const eventParams = {
            location: "results list",
            category: "wishlist",
            action: `product : ${wishListAction} wishlist`,
            locationPosition: trackingData.locationPosition || "",
            totalResults: trackingData.totalResults,
            pagination: trackingData.pagination,
            slotNumber: trackingData.slotNumber
        };

        getProductDataById(eventParams, trackingData.productId, null, "click");
    });

    $main.on("click", ".search-result-content .product-tile", e => {
        const $target = $(e.target);

        // .product-tile contains .add-to-wishlist class and when clicking addToWishlist CTA will trigger this also
        if ($target.hasClass("add-to-wishlist") || $target.parents().hasClass("add-to-wishlist")) {
            return;
        }

        const trackingData = getCLPTrackingData(e);
        const eventParams = {
            location: "results list",
            category: "action",
            locationPosition: trackingData.locationPosition || "",
            action: "product : see more",
            totalResults: trackingData.totalResults,
            pagination: trackingData.pagination,
            slotNumber: trackingData.slotNumber
        };

        getProductDataById(eventParams, trackingData.productId, null, "click");
    });

    // This event is triggered when a user clicks on a refinement link on the custom CLP(homepage)
    $.event.special.homeRefinementLinkClick = {
        trigger: function () {
            sendImpressionEvent();
        }
    };

    $cache.document.on("impressionEventInfiniteLoading", function (e, params) {
        sendImpressionEventInfiniteLoading(params.loadedProductsList);
    });

    $cache.document.on("impressionEventCLP", function () {
        sendImpressionEvent();
    });
}

/**
 * @function getCLPTrackingData
 * @description Get the tracking data for CLP click event
 * @param {Event} e - The event object
 * @returns {Object} - The tracking data
 */
function getCLPTrackingData(e) {
    const $this = $(e.currentTarget);
    const $locationPosition = $this.attr("data-order") || $this.closest("[data-order]").attr("data-order");
    const $totalResults = $this.closest("[data-product-count]").data("product-count");
    const $productId = $this.data("productid") || $this.data("itemid");

    var $slotNumber;

    $(".search-result-content .search-result-items .product-tile").each((index, element) => {
        if (element.contains(e.currentTarget)) {
            $slotNumber = index + 1;

            return false;
        }
    });

    const $pageSize = $this.closest("[data-page-size]").data("page-size");
    const $pagination = Math.ceil($slotNumber / $pageSize);

    return {
        locationPosition: $locationPosition,
        totalResults: $totalResults,
        productId: $productId,
        slotNumber: $slotNumber,
        pagination: $pagination
    };
}

/**
 * Send impression event for initial page load
 */
function sendImpressionEvent() {
    const $searchResultContent = $(".search-result-content");
    const $noResults = $(".no-results");

    var $productsListPosition = $noResults.data("order") || $searchResultContent.data("order");
    var clickEventParams = {};

    // search impression event for initial page load
    if ($searchResultContent.length > 0) {
        const productIdList = [];
        const $totalResults = $searchResultContent.data("product-count");
        const $pagination = $searchResultContent.data("page") + 1;

        $(".search-result-content .search-result-items .product-tile").each((index, element) => {
            productIdList.push(element?.dataset?.itemid);
        });

        clickEventParams = {
            location: "results list",
            category: "scroll",
            locationPosition: $productsListPosition,
            action: "results",
            totalResults: $totalResults,
            pagination: $pagination,
            productImpression: productIdList
        };

        impressionEvent(null, clickEventParams);

        return;
    }

    if ($noResults.length > 0) {
        // recalculate data-order attribute because initially this section is not in the DOM
        calculateDataOrderAttr();

        $productsListPosition = $noResults[0].dataset.order;
        clickEventParams = {
            location: "results list",
            category: "scroll",
            locationPosition: $productsListPosition,
            action: "no results"
        };

        impressionEvent(null, clickEventParams);
    }
}

/**
 * Send impression event on infinite load functionality
 *
 * @param {JQuery} $loadingElement
 */
function sendImpressionEventInfiniteLoading($loadingElement) {
    const productIdList = [];
    const $pagination = $loadingElement.data("page") + 1;
    const $productsListPosition = $(".search-result-content").data("order");
    var $totalResults = 0;

    $loadingElement.find(".product-tile").each((index, element) => {
        productIdList.push(element?.dataset?.itemid);
        $totalResults++;
    });

    const clickEventParams = {
        location: "results list",
        category: "scroll",
        locationPosition: $productsListPosition,
        action: "results",
        totalResults: $totalResults,
        pagination: $pagination,
        productImpression: productIdList
    };

    impressionEvent(null, clickEventParams);
}

function initSearch() {
    initCache();
    initCompareWidget();

    if (SitePreferences.LISTING_INFINITE_SCROLL) {
        $(window).on("scroll", infiniteScroll);
    }

    initProductTile();
    initShownResultsMention();
    initializeEvents();
    initAddToCartTile();

    document.addEventListener("AdobeAnalytics:Initialized", () => initTrackingEvents());
}

export { initSearch };
