import TomSelect from "tom-select/dist/js/tom-select.complete.js";

const data = {
    root: "[data-component='select']"
};

/**
 * @function
 * @description Mount TomSelect Instance
 *              Will run at pageInit, and can be call for specific select
 * @param {HTMLElement} select - select element - Optional
 */
const initSelect = select => {
    if (select !== undefined && select !== null) {
        mountSelect(select);
    } else {
        const select = document.querySelectorAll(data.root);

        select.forEach.call(select, currentSelect => {
            mountSelect(currentSelect);
        });
    }
};

/**
 * @function
 * @description Mount TomSelect Instance
 * @param {HTMLElement} select - select element
 */
const mountSelect = select => {
    const actionName = select.dataset.action;
    // render -> "option" is used to pass JSON print on native select option
    const selectInstance = new TomSelect(select, {
        create: false,
        controlInput: null,
        selectOnTab: true,
        render: {
            option: function (data) {
                if (data.attr !== null || data.attr !== undefined) {
                    return `<div data-attr='${data.attr}'>${data.text}</div>`;
                }
            }
        }
    });

    selectInstance.on("dropdown_open", dropdown => {
        const getAttribute = dropdown.querySelector(".selected").dataset?.attr;
        const payload = getAttribute ? JSON.parse(getAttribute) : "";
        const event = new CustomEvent("SelectOpen:" + actionName, {
            detail: payload
        });

        // Dispatch Event at opening - SelectOpen:ActionName
        document.dispatchEvent(event);
    }, false);

    // Native select binding
    select.addEventListener("change", e => {
        const select = e.currentTarget;
        const options = select.options;
        const currentOption = options[select.selectedIndex];

        // Get all specific attributes as JSON
        const attributes = JSON.parse(currentOption.dataset.attr);

        // Dispatch Event at selection - Select:ActionName
        const selectEventName = "Select:" + actionName;
        const event = new CustomEvent(selectEventName, {
            detail: {
                target: select,
                params: attributes
            }
        });

        document.dispatchEvent(event);
    }, false);
};

export default initSelect;
