import { clickEvent, impressionEvent } from "./adobe_analytics_events";

const $document = $(document);

/**
 * @function
 * @description Initialize subscription switch click events
 */
const initializeSubSwitchEvents = () => {
    $document.on("click", ".plan-switch-wrapper .c-navigation__step", e => {
        if ($(e.currentTarget).children("[data-step-id='1']").length) {
            const eventParams = {
                location: "page",
                category: "action",
                action: "change plan"
            };

            clickEvent(e, eventParams, null, null, false);
        }
    });

    $document.on("click", ".plan-switch-container .plan-section-footer a", e => {
        const eventParams = {
            location: "page",
            category: "action",
            action: "faq"
        };

        clickEvent(e, eventParams, null, null, false);
    });

    $document.on("click", ".plan-switch-wrapper .help-banner a", e => {
        const eventParams = {
            location: "page",
            category: "action",
            action: "faq"
        };

        clickEvent(e, eventParams, null, null, false);
    });

    $document.on("click", ".plan-details-faq a", e => {
        const eventParams = {
            location: "page",
            category: "action",
            action: "faq"
        };

        clickEvent(e, eventParams, null, null, false);
    });

    document.addEventListener("Button:GamingList", e => {
        const $target = $(e.detail.button);
        const $switchCard = $target.closest(".switch-subscription-card");

        if ($switchCard.length > 0) {
            const productId = $switchCard.data("subscriptionid");
            const productPlatform = $switchCard.data("subscriptionplatform");
            const action = `available games : ${$target.data("gameslist")}`;

            const eventParams = {
                productId: productId,
                productName: "ubisoft plus",
                productPlatform: productPlatform,
                productSalesType: "subscription",
                location: "available plans",
                category: "interaction",
                action: action,
                productDiscount: "no"
            };

            clickEvent(e, eventParams, null, null, false);
        }
    });

    document.addEventListener("Subscription:RecurrencyToggleEvent", e => {
        const recurrency = e.detail?.recurrency;
        const availablePlans = e.detail?.availablePlans;
        const location = "available plans";

        if (recurrency) {
            const eventParams = {
                location: location,
                category: "interaction",
                action: "Ubisoft+ " + recurrency + " : select"
            };

            clickEvent(e, eventParams, null, null, false);
        }

        if (availablePlans) {
            const impressionEventParams = {
                location: location,
                category: "scroll",
                action: "visible",
                productImpression: availablePlans
            };

            impressionEvent(null, impressionEventParams);
        }
    });
};

export { initializeSubSwitchEvents };
