/**
 * @function cookie Used to cookies helper contains add,remove and delete methods
 **/

var cookie = {
    /**
     * @function
     * @description Creates a new cookie
     * @param {String} name Cookie name
     * @param {String} value Cookie value
     * @param {String} days Number of days until expiration
     * @param {Boolean} isAnySubdomainCookie - Boolean value to point if the cookie exists on any subdomain. It has a "." in front of the domain.
     */
    create: function (name, value, days, isAnySubdomainCookie) {
        var expires;

        if (days) {
            var date = new Date();

            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toGMTString();
        } else {
            expires = "";
        }

        if (isAnySubdomainCookie) {
            const httpHost = window.location.host;

            document.cookie = name + "=" + encodeURIComponent(value) + expires + "; path=/; domain=." + httpHost;
        } else {
            document.cookie = name + "=" + encodeURIComponent(value) + expires + "; path=/";
        }
    },

    /**
     * @function
     * @description Gets cookie value
     * @param {String} name Cookie name
     */
    get: function (name) {
        var nameCK = name + "=";
        var ca = document.cookie.split(";");

        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];

            while (c.charAt(0) == " ") c = c.substring(1, c.length);
            if (c.indexOf(nameCK) == 0) return decodeURIComponent(c.substring(nameCK.length, c.length));
        }

        return null;
    },

    /**
     * @function
     * @description Deletes cookie
     * @param {String} name Cookie name
     * @param {Boolean} isAnySubdomainCookie - Boolean value to point if the cookie exists on any subdomain. It has a "." in front of the domain.
     */
    delete: function (name, isAnySubdomainCookie) {
        this.create(name, "", -1, isAnySubdomainCookie);
    }
};

export { cookie };
