import debounce from "lodash/debounce";
import { util } from "./util";
import { jRes } from "./jRes";

var _ = {
    debounce
};

var suggestionOptions = {
    delay: 1000,
    minCharacters: 3
};

var qlen = 0,
    listTotal = -1,
    listCurrent = -1,
    fieldDefault = null,
    searhPlaceholderText = Resources.SIMPLE_SEARCH,
    searhPlaceholderTextMobile = Resources.SIMPLE_SEARCH_MOBILE,
    $searchForm,
    $searchField,
    $searchContainer,
    $resultsContainer,
    $searchWrapper,
    $searchFormSubmit;

/**
 * @function
 * @description Handles keyboard's arrow keys
 * @param keyCode Code of an arrow key to be handled
 */
function handleArrowKeys(keyCode) {
    if ($resultsContainer.is(":visible")) {
        switch (keyCode) {
            case 38:
                // keyUp
                listCurrent = (listCurrent <= 0) ? (listTotal - 1) : (listCurrent - 1);
                break;
            case 40:
                // keyDown
                listCurrent = (listCurrent >= listTotal - 1) ? 0 : listCurrent + 1;
                break;
            default:
                // reset
                listCurrent = -1;

                return false;
        }

        $resultsContainer.children().removeClass("selected").eq(listCurrent).addClass("selected");
        $searchField.val($resultsContainer.find(".selected .suggestionterm").first().text());

        return true;
    } else {
        return false;
    }
}

var searchsuggest = {
    /**
     * @function
     * @description Configures parameters and required object instances
     */
    init: function (container, defaultValue) {
        var that = this;

        // initialize vars
        $searchContainer = $(container);
        $searchForm = $searchContainer.find('form[name="simpleSearch"]');
        $searchField = $searchForm.find('input[name="q"]');
        fieldDefault = defaultValue;
        $searchWrapper = $(".header-search");
        $searchFormSubmit = $searchForm.find("button[type='submit']");

        // disable browser auto complete
        $searchField.attr("autocomplete", "off");

        // on focus listener (clear default value)
        $searchField.focus(function () {
            if (!$resultsContainer) {
                // create results container if needed
                $resultsContainer = $("<div/>").attr("id", "suggestions").appendTo($searchContainer.children("form")).hide();
            }

            if ($searchField.val() === fieldDefault) {
                $searchField.val("");
            }

            this.activateSearch();
        }.bind(this));
        // on blur listener
        $searchField.on("blur", _.debounce(function () {
            that.clearResults();
        }).bind(this));
        // on key up listener
        $searchField.keyup(function (e) {
            // get keyCode (window.event is for IE)
            var keyCode = e.keyCode || window.event.keyCode;

            // check and treat up and down arrows
            if (handleArrowKeys(keyCode)) {
                return;
            }

            // check for an ENTER or ESC
            if (keyCode === 13 || keyCode === 27) {
                this.clearResults();

                return;
            }

            this.activateSearch();

            var lastVal = $searchField.val();

            // if is text, call with delay
            setTimeout(function () {
                this.suggest(lastVal);
            }.bind(this), suggestionOptions.delay);
        }.bind(this));
        // on submit we do not submit the form, but change the window location
        // in order to avoid https to http warnings in the browser
        // only if it's not the default value and it's not empty
        $searchForm.submit(function (e) {
            e.preventDefault();

            var searchTerm = $searchField.val();

            if (searchTerm === fieldDefault || searchTerm.length === 0) {
                return false;
            }

            window.location = util.appendParamToURL($(this).attr("action"), "q", searchTerm);
        });

        that.setPlaceholder();
    },

    /**
     * @function
     * @description trigger suggest action
     * @param lastValue
     */
    suggest: function (lastValue) {
        // get the field value
        var part = $searchField.val();

        // if it's empty clear the resuts box and return
        if (part.length === 0) {
            this.clearResults();

            return;
        }

        // if part is not equal to the value from the initiated call,
        // or there were no results in the last call and the query length
        // is longer than the last query length, return
        // #TODO: improve this to look at the query value and length
        if ((lastValue !== part) || (listTotal === 0 && part.length > qlen)) {
            return;
        }

        qlen = part.length;

        if (qlen < suggestionOptions.minCharacters) {
            return;
        }

        // build the request url
        var reqUrl = util.appendParamToURL(Urls.searchsuggest, "q", part);

        reqUrl = util.appendParamToURL(reqUrl, "legacy", "true");

        // get remote data as JSON
        $.getJSON(reqUrl, function (data) {
            // get the total of results
            var suggestions = data,
                ansLength = suggestions.length;

            // Set global suggestions length
            listTotal = ansLength;

            // if there are results populate the results div
            if (ansLength === 0) {
                this.clearResults();
                this.activateSearch();

                return;
            }

            var html = "";

            for (var i = 0; i < ansLength; i++) {
                html += '<div class="suggestion-wrapper"><div class="suggestionterm">' + suggestions[i].suggestion + '</div><span class="hits">' + suggestions[i].hits + "</span></div>";
            }

            // update the results div
            var $selectedSuggestion;

            $resultsContainer.html(html).show().on("mouseenter", "div", function (ev) {
                $(".suggestion-wrapper").removeClass("selected");
                $selectedSuggestion = $(this).closest(".suggestion-wrapper").addClass("selected");
                listCurrent = $(".suggestion-wrapper").index($selectedSuggestion);
                $searchField.val($(ev.target).closest(".suggestion-wrapper").children(".suggestionterm").text());
            }).on("click", "div", function (ev) {
                // on click copy suggestion to search field, hide the list and submit the search
                $searchField.val($(ev.target).closest(".suggestion-wrapper").children(".suggestionterm").text());
                this.clearResults();
                $searchForm.trigger("submit");
            }.bind(this));
        }.bind(this));
    },
    /**
     * @function
     * @description
     */
    clearResults: function () {
        if (!$resultsContainer) { return; }

        $resultsContainer.empty().hide();
        listCurrent = -1;
        this.disableSearch();
    },

    activateSearch: function () {
        $searchWrapper.addClass("active");
        $searchWrapper.addClass("no-placeholder");
        $searchFormSubmit.attr("disabled", false);
    },

    disableSearch: function () {
        $searchWrapper.removeClass("active");

        if ($searchField.val() === $searchField.attr("placeholder")) {
            $searchWrapper.removeClass("no-placeholder");
            $searchFormSubmit.attr("disabled", "disabled");
        }
    },

    setPlaceholder: function () {
        var originalValue = $searchField.val();

        $searchField.val("");

        if (jRes.isMobilePortrait) {
            $searchField.attr("placeholder", searhPlaceholderTextMobile);

            if (originalValue != searhPlaceholderText) {
                $searchField.val(originalValue);
            }
        } else {
            $searchField.attr("placeholder", searhPlaceholderText);

            if (originalValue != searhPlaceholderTextMobile) {
                $searchField.val(originalValue);
            }
        }

        // $searchField.val(originalValue);
    },

    setMobilePlaceholder: function () {
        $searchField.val("");
        $searchField.attr("placeholder", searhPlaceholderTextMobile);
    }
};

export { searchsuggest };
