const data = {
    root: "[page-component='topNotifications']"
};

/**
 * @function
 * @description Creates a node collection of elements. For each element, that is not stored in session storage per index, removes the hide class and add click event listener to that element.
 * @param {HTMLElement} containerToUpdate - container containing close button element to add the logic to
 */
const handleContainersOnClose = containerToUpdate => {
    const removeNotificationBtnCollection = containerToUpdate.querySelectorAll('[data-cta="closeTopNotifications"]');

    if (!removeNotificationBtnCollection.length) return;

    removeNotificationBtnCollection.forEach((element, index) => {
        const container = element.closest(".c-topnotification");
        const storedEl = `closed-promo-banner-${index}`;

        if (!sessionStorage.getItem(storedEl)) {
            container?.classList.remove("hide");
        }

        element.addEventListener("click", () => {
            container?.classList.add("hide");
            sessionStorage.setItem(storedEl, true);
        }, {
            once: true
        });
    });
};

/**
 * @function
 * @description Toggles classes for the top notifications
 * @param {HTMLElement} updatedContent - block with the new info
 * @param {HTMLElement} existingContent - block on the page to update
 */
const toggleTopNotificationClasses = (updatedContent, existingContent) => {
    const topNotificationsWrapper = document.querySelector(data.root);

    if (!topNotificationsWrapper) return;

    let newInfoBlock = updatedContent;
    let blockToUpdate = existingContent;
    const visibleClassName = "l-cart__topnotifications--not-empty";

    if (!newInfoBlock && !blockToUpdate) {
        newInfoBlock = topNotificationsWrapper;
        blockToUpdate = topNotificationsWrapper;
    }

    if (newInfoBlock.textContent.trim() === "") {
        blockToUpdate.classList.remove(visibleClassName);
    } else {
        blockToUpdate.classList.add(visibleClassName);
        handleContainersOnClose(blockToUpdate);
    }
};

export default toggleTopNotificationClasses;
