import "slick-carousel";
import LazyLoad from "vanilla-lazyload/dist/lazyload";
import debounce from "lodash/debounce";
import { symbols } from "./symbols";
import { initFreeOffer } from "./freeoffer";
import { showRecommendationTitle } from "./pages/product/recommendations";
import { calculateDataOrderAttr } from "./tracking";

var $cache = {};

var initCache = function () {
    $cache = {
        window: $(window),
        enhancedEinsteinSlider: $(".enhanced-slider-einstein")
    };
};

/**
* @function
* @description function to call for the Einstein Slider settings
* @param {object} $selector - object - jquery selector of the slider to get params from
*/
function returnEinsteinSliderSettings($selector) {
    var slickEinsteinSlider = {
        dots: false,
        infinite: false,
        speed: 400,
        slidesToShow: 5,
        slidesToScroll: 5,
        autoplay: false,
        arrows: true,
        autoplaySpeed: ($selector && $selector.data("autoplay-time")) ? $selector.data("autoplay-time") : 5000,
        prevArrow: symbols.transparentPrevArrow(),
        nextArrow: symbols.transparentNextArrow(),
        cssEase: "ease",
        responsive: [
            {
                breakpoint: 1920,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 5
                }
            },
            {
                breakpoint: 1469,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 4
                }
            },
            {
                breakpoint: 959,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3
                }
            }
        ]
    };

    return slickEinsteinSlider;
}

/**
* @function
* @description function to call for the Einstein Product Slider settings depending on the page
*/
function initEinsteinSlider() {
    $cache.enhancedEinsteinSlider = $(".enhanced-slider-einstein");

    $cache.enhancedEinsteinSlider.each(function (index, element) {
        $(element).not(".slick-initialized").slick(returnEinsteinSliderSettings($(element)));
    });
}

/**
* @function
* @description observer used to find the Einstein template in the DOM and apply the necessary logic of products sliders
*/
function initObserver() {
    var observerTargets = document.querySelectorAll('[id^="cq_recomm_slot-"]');

    // Callback function to execute when mutations are observed
    var callback = function (mutationsList) {
        mutationsList.forEach(function (mutation) {
            if (mutation.type === "childList") {
                let $productTile = $('[id^="cq_recomm_slot-"]').find(".product-tile");

                var lazyLoadInstance = new LazyLoad({
                    elements_selector: ".lazy"
                });

                if (lazyLoadInstance) {
                    lazyLoadInstance.update();
                }

                initEinsteinSlider();
                calculateDataOrderAttr();

                $cache.window.on("changed.zf.mediaquery", debounce(function () {
                    initEinsteinSlider();
                }, 300));

                if ($productTile.length > 0) {
                    $productTile.each((index, element) => {
                        initFreeOffer($(element));
                    });
                }

                const $currentSlot = $(mutation.target);

                // to trigger IntersectionObserver in the adobe_analytics_events
                document.dispatchEvent(new CustomEvent("Einstein:Mutated", {
                    detail: $currentSlot.find(".recommendations.einstein-recommendations")
                }));

                if ($currentSlot.length) {
                    showRecommendationTitle($currentSlot);
                }
            }
        });
    };

    // Observer init
    var observer = new MutationObserver(callback);

    // Start the monitoring if there are targets to look for
    if (observerTargets.length) {
        observerTargets.forEach(element => {
            observer.observe(element, {
                childList: true
            });
        });
    }
}

function initEinsteinWatcher() {
    initCache();
    initObserver();
}

export { initEinsteinWatcher };
