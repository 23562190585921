import { setTrackingCookies } from "./tracking";

/*
 *  Initializes footer events and functionality
 */
const initFooter = () => {
    const $setCookiesLink = $(".js-set-cookies");

    if (SitePreferences.ENABLE_COMPLIANCE_V2) {
        $setCookiesLink.off().on("click", e => {
            e.preventDefault();
            // set events to track the window messages for cookies
            setTrackingCookies(true);

            if (adobeAnalytics && adobeAnalytics.displayPrivacySettings) {
                adobeAnalytics.displayPrivacySettings();
            }
        });
    } else {
        $setCookiesLink.hide();
    }
};

export { initFooter };
