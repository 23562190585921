import { util } from "../util";

let $cache = {};

/**
 * @function
 * @description function to init cache
 */
const initCache = () => {
    const parsedProtocolData = util.parseJSON(Resources.CONNECT_PROTOCOL_JSON);

    $cache = {
        parentWindow: window.parent,
        document: $(document),
        subscriptionAppName: parsedProtocolData?.subscriptionAppName,
        libraryAppName: parsedProtocolData?.libraryAppName,
        hostDomain: "ubiconnectpc",
        targetOrigin: parsedProtocolData?.targetOrigin ?? "*"
    };
};

/**
 * @function
 * @description function to send the ready state to PC client app
 */
const sendReadyState = () => {
    if ($cache.parentWindow) {
        const messageDetails = JSON.stringify({
            storeCodeReady: true
        });

        if (window.__browserViewBridge && window.__browserViewBridge.isAvailable()) {
            window.__browserViewBridge.sendMessage(messageDetails);
        }
    }
};

/**
 * @function
 * @description Set Connect protocol on the new version, POST on server side
 */
const setConnectProtocol = () => {
    sendReadyState();

    const hostDomain = $cache.hostDomain;

    // set sessions variables used in different flows to the new connect version
    sessionStorage.setItem("hostDomain", hostDomain);
    window.newPcClient = true;

    // post value to server
    fetch(Urls.connectProtocolProcessData, {
        method: "POST",
        body: new URLSearchParams({
            hostDomain: hostDomain
        })
    });

    // dispatch event to update the DataDogRum global context
    document.dispatchEvent(new CustomEvent("DataDogRum:setGlobalContextProperty", {
        detail: {
            key: "hostDomain",
            value: hostDomain
        }
    }));

    // bind buttons
    $cache.document.on("click", ".js-connect-protocol", e => {
        e.preventDefault();

        if (window.__browserViewBridge && window.__browserViewBridge.isAvailable()) {
            const $this = $(e.currentTarget);
            const isActiveSubscriber = $this.attr("data-active-subscriber") === "true" || uplayData["subscription-active"];
            let redirectMsg = JSON.stringify({
                appName: $cache.libraryAppName
            });

            if (!isActiveSubscriber) {
                redirectMsg = JSON.stringify({
                    appName: $cache.subscriptionAppName,
                    applicationData: {
                        category: "subscription"
                    }
                });
            }

            window.__browserViewBridge.sendMessage(redirectMsg);
        }
    });
};

/**
 * @function
 * @description function to init the functionality for the connect protocol
 */
const initConnectProtocol = () => {
    initCache();
    setConnectProtocol();
};

export { initConnectProtocol };
