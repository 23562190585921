import { setTrackingCookies } from "./tracking";
import { dialog } from "./dialog";

/**
 * @function
 * @description Initialize the privacy popin event to accept or to set the cookies
 */
const initPrivacyPopin = () => {
    if (SitePreferences.ENABLE_COMPLIANCE_V2) {
        const $sectionPrivacyPopin = $("#section-privacy-popin");

        if ($sectionPrivacyPopin.length) {
            dialog.open({
                html: $sectionPrivacyPopin.html(),
                options: {
                    dialogClass: "section-privacy-popin",
                    width: 650
                }
            });

            $(".privacy-policy-accepted").off("click").on("click", e => {
                e.preventDefault();

                const isOneTrustAnalyticsVersion = window.OneTrust && window.OnetrustActiveGroups;

                const updateConsentPromise = new Promise(resolve => {
                    if (isOneTrustAnalyticsVersion) {
                        // update the multimedia consent
                        window.OneTrust.UpdateConsent("Category", "C0005:1");
                    }

                    resolve();
                });

                updateConsentPromise.then(() => {
                    const data = {
                        videoConsentTracking: true,
                        isOneTrustAnalyticsVersion: isOneTrustAnalyticsVersion
                    };

                    return $.ajax({
                        url: Urls.updateVideoTracking,
                        type: "POST",
                        data: data
                    });
                }).then(() => {
                    window.ConsentResources.videoConsent = true;

                    dialog.close();
                }).catch(error => {
                    console.error("An error occurred:", error);
                });
            });

            $(".privacy-policy-set-cookies").off("click").on("click", e => {
                e.preventDefault();
                // set events to track the window messages for cookies
                setTrackingCookies(true);
                // close the dialog window
                dialog.close();
                // call adobe api to display cookies popin
                setTimeout(() => {
                    // display privacy popin to update cookies status
                    adobeAnalytics.displayPrivacySettings();
                }, 1000);
            });
        }
    }
};

export { initPrivacyPopin };
