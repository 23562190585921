// Accordion component

var data = {
    root: "[data-component='accordion']",
    content: "[data-ui='accordionContent']",
    trigger: "[data-action='accordionToggle']",
    cssModifier: "c-accordion__item--active"
};

const accordion = {
    /**
     * @function
     * @description get all components instances and bind events
     */
    init: () => {
        const components = document.querySelectorAll(data.root);

        components.forEach.call(components, component => {
            accordion.bindComponentEvents(component);
            accordion.setAccordionFocusability(component);
        });
    },

    /**
     * @function
     * @description bind event on component
     * @param {HTMLElement} component - Accordion DOM node
     */
    bindComponentEvents: component => {
        const triggers = component.querySelectorAll(data.trigger);

        triggers.forEach.call(triggers, trigger => {
            trigger?.addEventListener("click", e => {
                e.preventDefault();
                e.stopImmediatePropagation();

                const el = e.currentTarget;
                const collapse = component.dataset.collapse || "single";

                accordion.toggleAccordion(component, el, collapse);
            }, false);
        });
    },

    /**
     * @function
     * @description toggle the Accordion
     * @param {HTMLElement} component - component DOM node
     * @param {HTMLElement} el - triggered element DOM node
     * @param {string} autocollapse - Collapse option mode
     */
    toggleAccordion: (component, el, autocollapse) => {
        const content = el.closest(data.content);
        const accordionName = component.getAttribute("data-name");
        const sectionName = content.getAttribute("data-section-name") || el.textContent.replace(/(\r\n|\n|\r)/gm, "").trim();

        if (typeof content === undefined || content === null) {
            return;
        }

        if (autocollapse === "auto") {
            if (content.classList.contains(data.cssModifier)) {
                content.classList.remove(data.cssModifier);

                // Set ADA value
                el.setAttribute("aria-expanded", "false");

                // dispatch event
                const event = new CustomEvent("AccordionClose:" + accordionName, {
                    detail: sectionName
                });

                document.dispatchEvent(event);
            } else {
                const contents = component.querySelectorAll(data.content);

                // Collapse all other contents
                contents.forEach.call(contents, content => {
                    const trigger = content.parentNode.querySelector(data.trigger);

                    // Hide other contents and set ADA
                    content.classList.remove(data.cssModifier);
                    trigger.setAttribute("aria-expanded", "false");
                });

                // Show current content and set ADA
                content.classList.add(data.cssModifier);
                el.setAttribute("aria-expanded", "true");

                // dispatch event
                const event = new CustomEvent("AccordionOpen:" + accordionName, {
                    detail: sectionName
                });

                document.dispatchEvent(event);
            }
        } else {
            content.classList.toggle(data.cssModifier);

            // Toggle ADA value
            el.setAttribute("aria-expanded", value => {
                // dispatch event
                const eventName = value === "true" ? "AccordionOpen:" + accordionName : "AccordionClose:" + accordionName;
                const event = new Event(eventName);

                document.dispatchEvent(event);

                return value === "true" ? "false" : "true";
            });
        }

        accordion.setAccordionFocusability(component);
    },

    /**
     * @function
     * @description Makes all focusable elements non-focusable inside the accordion dropdown if it's not active.
     *              It prevents from focusing on the buttons, links, inputs, etc. inside the dropdown when the dropdown is closed.
     * @param {HTMLElement} component - the accordion component
     */
    setAccordionFocusability: component => {
        const content = component?.querySelector(".c-accordion__content");
        const focusableElements = content?.querySelectorAll("button, [href], input, select, textarea, [tabindex]:not([tabindex='-1'])");

        if (!focusableElements) return;

        const isAccordionOpened = component?.querySelector(".c-accordion__item--active");

        focusableElements.forEach(focusableElement => {
            if (isAccordionOpened) {
                focusableElement.setAttribute("tabindex", "0");
            } else {
                focusableElement.setAttribute("tabindex", "-1");
            }
        });
    }
};

export default accordion;
